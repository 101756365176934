//#region IMPORT

import { BaseModel } from "./bases/base.model";
import { StoredClaimDetailPayment } from "./storedclaimdetailpayment.model";

//#ednregion


//#region CLASS

export class StoredClaimDetailModel extends BaseModel
{
	listPayment?: Array<StoredClaimDetailPayment>;
}

//#endregion
//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { RequestModel } from "../models/request.model";
import { BranchModel } from "../models/branch.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class BranchService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectBranch(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCH);
	}

	selectBranchForTicketByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFORTICKETBYATTRIBUTES);
	}

	selectBranchForSurvey(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFORSURVEY);
	}

	selectBranchByLocation(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHBYLOCATION);
	}

	selectBranchByLocationForSurvey(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHBYLOCATIONFORSURVEY);
	}

	selectBranchForEBiddingBranchOfficeMaker(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGBRANCHOFFICEMAKER);
	}

	selectBranchForEBiddingHeadOfficeMaker(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGHEADOFFICEMAKER);
	}

	selectBranchForEBiddingHeadOfficeChecker(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGHEADOFFICECHECKER);
	}

	selectBranchForEBiddingHeadOfficeFirstSigner(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGHEADOFFICEFIRSTSIGNER);
	}

	selectBranchForEBiddingHeadOfficeSecondSigner(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGHEADOFFICESECONDSIGNER);
	}

	selectBranchForSupplier(interfaceGeneralService: GeneralServiceInterface, modelRequest: RequestModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelRequest), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFORSUPPLIER);
	}

	selectBranchForPartnershipWorkshop(interfaceGeneralService: GeneralServiceInterface, modelrequest: RequestModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelrequest), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFORPARTNERSHIPWORKSHOP);
	}

	selectBranchConventionalWithHeadOffice(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHCONVENTIONALWITHHEADOFFICE);
	}

	//#endregion
}

//#endregion

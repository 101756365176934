<div id="divBodyClaimProposal" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Report</h2>
		<p>All report of claims</p>
    </header>

	<div id="divMainClaimProposalListMonitoring" class="DivMainAsideSearch">
		<form>
			<div class="DivForm">
				<h3>Search</h3>
				<fieldset>
					<label for="textTicketNumber">Ticket Number</label>
                    <input type="text" name="modelClaimProposalTicketNumber" id="textTicketNumber" placeholder="input ticket number" [(ngModel)]="_modelClaimProposal.TicketNumber">

					<label for="textPolicyNumber">Policy Number</label>
                    <input type="text" name="modelClaimProposalPolicyNumber" id="textPolicyNumber" placeholder="input policy number" [(ngModel)]="_modelClaimProposal.PolicyNumber" (keypress)="this._functionRegex.regexNumericOnly($event)">

					<label for="stringReporterFirstName">Reporter Name / Insured Name</label>
                    <input type="text" name="modelClaimProposalReporterFirstName" id="stringReporterFirstName" placeholder="input Name" [(ngModel)]="_modelClaimProposal.InsuredName">

					<label for="textLicensePlate">License Plate</label>
					<div class="DivInputHorizontalContainer">
						<select name="modelLicensePlatePrefix" id="selectLicensePlatePrefix" class="InputLicensePlatePrefix" [(ngModel)]="_modelClaimProposal.LicensePlatePrefixCode">
						<ng-container *ngFor="let arrayModelLicensePlatePrefix of _arrayModelLicensePlatePrefix">
							<option [ngValue] ="arrayModelLicensePlatePrefix.Code"> {{ arrayModelLicensePlatePrefix.Code }} </option>
						</ng-container>
					</select>
						<input type="number" name="modelClaimProposalLicensePlate" id="numberLicensePlate" class="InputLicensePlateInfix" [(ngModel)]="_modelClaimProposal.LicensePlateInfix" placeholder="1234">
						<input type="text" name="modelClaimProposalLicensePlate" id="textLicensePlateEnd" class="InputLicensePlateSuffix" [(ngModel)]="_modelClaimProposal.LicensePlateSuffix" placeholder="AB">
					</div>

					<label for="dateReporterDate">Repoted Date</label>
					<fieldset>
						<mat-form-field appearance="fill">
							<mat-label>Enter a date range</mat-label>
							<mat-date-range-input [rangePicker]="picker">
								<input matStartDate placeholder="Start date" (dateChange)="getReportedStartDate($event)">
								<input matEndDate placeholder="End date" (dateChange)="getReportedEndDate($event)">
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
					</fieldset>

					<label for="selectStatus">Status</label>
					<select name="selectStatus" id="selectStatus" [(ngModel)]="_numberClaimProposalSurveyStatus" >
						<option [ngValue]="null">ALL</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.Verified">Found | Pending Verification Branch</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.NotVerified">Not Found | Pending Verification {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.RejectedByBranch">Rejected By Branch Branch</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.PendingForHeadOffice">Pending Verification Adjustment {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.PendingForCheckerHeadOffice">Pending Verification Supervisor {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.PendingForSignerHeadOffice">Pending Verification Manager {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.PendingForApprove">Pending For Approve Deputy Branch Head</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.RejectedByHeadOffice">Rejected By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.PendingForSurvey">Pending For Survey Branch</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.CompletedWithSurvey">Waiting For Approval By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.CompletedWithoutSurvey">Waiting For Approval By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.Approved">Approved By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.Rejected">Rejected By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.PendingForCorrection">Pending For Correction</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.PendingForCorrectionChecker">Pending For Correction Checker</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.PendingForCorrectionSigner">Pending For Correction Signer</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.Submitted">Submitted {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.WaitingApproveByAdjustment">Waiting For Approval By Adjustment</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.WaitingApproveBySupervisor">Waiting For Approval By Supervisor</option>
						<option [ngValue]="_enumClaimProposalSurveyStatus.WaitingApproveByManager">Waiting For Approval By Manager</option>
					</select>
				</fieldset>
			</div>
			<input *ngIf="_modelUserSignIn.BranchCode === _stringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE" type="button" name="buttonExportClaimProposal" value="Search" (click)="callSelectClaimProposalForReport(this)">
			<input *ngIf="_modelUserSignIn.BranchCode === _stringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE" type="button" name="buttonExportClaimProposal" value="Export" (click)="callSelectClaimProposalForDownloadReport(this)">
		</form>

		<div class="DivTableContainer">
			<app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>

			<table>
				<thead>
					<tr>
						<th>Ticket Number</th>
						<th>Product</th>
						<th>Privilege</th>
						<th>Reporter Name</th>
						<th>Reporter Date</th>
						<th>Status</th>
						<th>Created</th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let modelClaimProposal of _arrayModelClaimProposal" (click)="goToDetail(modelClaimProposal.Token)">
                        <td>{{ modelClaimProposal.TicketNumber }}</td>
                        <td>{{ modelClaimProposal.ProductName }}</td>
						<td>{{ modelClaimProposal.PolicyPrivilegeType | convertEmptyToDash }}</td>
						<td>{{ modelClaimProposal.ReporterFirstName }} {{ modelClaimProposal.ReporterMiddleName }} {{ modelClaimProposal.ReporterLastName }}</td>
						<td>{{ modelClaimProposal.CreatedOn | initiateWithoutUTC | convertToDateShort }}</td>
						<td>{{ modelClaimProposal.getClaimProposalStatus() }}</td>
                        <td *ngIf="!modelClaimProposal.checkChannelFromCustomer()">{{ modelClaimProposal.modelUser?.getName() | convertEmptyToDash }} | {{ modelClaimProposal.BranchCreatorName | convertEmptyToDash }}</td>
                        <td *ngIf="modelClaimProposal.checkChannelFromCustomer()">{{ modelClaimProposal.modelUser?.getName() | convertEmptyToDash }} | {{ modelClaimProposal.getClaimProposalChannel() }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
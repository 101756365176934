//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_REGISTRATION_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class CustomerModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for customer model, used for link to user model.
		Author: Ibrahim Aziz.
		Created on : Thursday, 1 April 2021. 				Updated on : Thursday, 1 April 2021.
		Created by : Ibrahim Aziz.							Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;

	CoreID?: string;
	LeadID?: number;
	UserID?: number;
	OwnerID?: number;
	ReferenceID?: string;
	ReferenceName?: string;
	DeviceID?: string;
	Culture?: string;
	Token?: string;

	FirstName?: string;
	MiddleName?: string;
	LastName?: string;
	BirthDate?: Date;
	BirthPlace?: string;
	Gender?: ENUM_GENDER_TYPE;
	ReligionName?: string;
	Address?: string;
	CityName?: string;
	PostalCodeName?: string;
	Fax?: string;
	MobilePhone?: string;
	MobilePhoneState?: ENUM_REGISTRATION_STATE;

	Email?: string;
	EmailState?: ENUM_REGISTRATION_STATE;

	PhotoURL?: string;
	PhotoFileName?: string;
	PhotoOrder?: number;
	PhotoWidth?: number;
	PhotoHeight?: number;
	PhotoFormat?: string;
	PhotoNote?: string;
	ProfileID?: string;

	modelUser?: UserModel;

	/* Attribute - END */
}

//#endregion
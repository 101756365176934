//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class SalvageItemModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for salvage added arrayModelSalvageItem.
		Author: Ibrahim Aziz.
		Created on : Monday, 28 August 2023. 				Updated on : Monday, 28 August 2023.
		Created by : Ibrahim Aiz.							Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	ID?: number;
	SalvageID?: number;

	Token?: string;
	Name?: string;
	EstimatedSalvageValue?: number;

	/* Attribute - END */

	constructor()
	{
    	super();
	}
}

//#endregion
//#region IMPORT

import { ENUM_MANTLE, ENUM_PKS, ENUM_RESPONSE_STATE, ENUM_WORKSHOP_TYPE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class PartnershipWorkshopModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for position model, used for link to division.
		Author: Eka Saputra.
		Created on : Saturday, 3 October 2021. 				Updated on : Saturday, 3 October 2021.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/


	ID?: number;
	Name?: string;
	Address?: string;
	PostalCode?: string;
	BranchCode?: string;
	BranchName?: string;
	NPWP?: string;
	Telephone?: number;
	Fax?: string;
	Longitude?: any;
	Latitude?: any;
	Email?: string;
	MobilePhone?: string;
	Type?: ENUM_WORKSHOP_TYPE;

	Token?: string;
	NpwpName?: string;
	MantleType?: ENUM_MANTLE;
	PKSType?: ENUM_PKS;
	ProvinceName?: string;
	PICNumber?: string;
	PICName?: string;
	HasTowing?: boolean;
	PKSNumber?: string;
	PKSPeriod?: Date;
	WorkshopClass?: string;
	IDProfileCare?: string;
	NameProfileCare?: string;
	IDProfileMantle?: string;
	NameProfileMantle?: string;
	AccountNumber?: string;
	AccountName?: string;
	PPN?: boolean;
	PPH?: boolean;
	PPHFinal?: boolean;
	SKPP?: boolean;
	Note?: string;
	PKSPeriodStart?: Date;
	PKSPeriodEnd?: Date;

	/* Attribute - END */


	constructor()
	{
		super();
	}

	validateUpdateWorkshop(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.Name === null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Workshop name cannot be empty";
		}
		else if (this.UpdatedOn === null || this.UpdatedOn === undefined)
		{
			modelResponse.MessageContent = "Workshop visit date cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}


	validateInsertPartnershipWorkshop(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		const regExpEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		modelResponse.MessageTitle = "Partnership Workshop";

		if(this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Workshop Name can't be empty";
		}
		else if(this.Address === null || this.Address === undefined || this.Address === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Address can't be empty";
		}
		else if(this.PostalCode === null || this.PostalCode === undefined || this.PostalCode === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Zip Code can't be empty";
		}
		else if(this.BranchName === null || this.BranchName === undefined || this.BranchName === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Managing Branch Name can't be empty";
		}
		else if(this.BranchCode === null || this.BranchCode === undefined || this.BranchCode === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Managing Branch Code can't be empty";
		}
		else if(this.Npwp === null || this.Npwp === undefined || this.Npwp === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Workshop NPWP Number can't be empty";
		}
		else if(this.NpwpName === null || this.NpwpName === undefined || this.NpwpName === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "NPWP Account Name can't be empty";
		}
		else if(this.Telephone === null || this.Telephone === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Phone Number can't be empty";
		}
		else if(this.Fax === null || this.Fax === undefined || this.Fax === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Fax Number can't be empty";
		}
		else if(this.Email === null || this.Email === undefined || this.Email === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Workshop Email can't be empty";
		}
		else if(!(regExpEmail.test(this.Email)))
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Format Email incorrect";
		}
		else if(this.PICNumber === null || this.PICNumber === undefined || this.PICNumber === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "PIC Number can't be empty";
		}
		else if(this.Type === null || this.Type === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Authorize can't be empty";
		}
		else if(this.MantleType === null || this.MantleType === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Mantle can't be empty";
		}
		else if(this.PKSType === null || this.PKSType === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "PKS Status can't be empty";
		}
		else if(this.ProvinceName === null || this.ProvinceName === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Province Name can't be empty";
		}
		else if(this.PICName === null || this.PICName === undefined || this.PICName === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Workshop PIC Name can't be empty";
		}
		else if(this.HasTowing === null || this.HasTowing === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Has Towing can't be empty";
		}
		else if(this.PKSNumber === null || this.PKSNumber === undefined || this.PKSNumber === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "PKS Number can't be empty";
		}
		else if(this.PKSPeriodStart === null || this.PKSPeriodStart === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "PKS Period Start can't be empty";
		}
		else if(this.PKSPeriodEnd === null || this.PKSPeriodEnd === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "PKS Period End can't be empty";
		}
		else if(this.WorkshopClass === null || this.WorkshopClass === undefined || this.WorkshopClass === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Workshop Class can't be empty";
		}
		else if(this.IDProfileCare === null || this.IDProfileCare === undefined || this.IDProfileCare === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "ID Profile Care can't be empty";
		}
		else if(this.NameProfileCare === null || this.NameProfileCare === undefined || this.NameProfileCare === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Workshop Care Name can't b empty";
		}
		else if(this.IDProfileMantle === null || this.IDProfileMantle === undefined || this.IDProfileMantle === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "ID Profile Mantle can't be empty";
		}
		else if(this.NameProfileMantle === null || this.NameProfileMantle === undefined || this.NameProfileMantle === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Workshop Mantle Name can't be empty";
		}
		else if(this.AccountNumber === null || this.AccountNumber === undefined || this.AccountNumber === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Workshop Account Number can't be empty";
		}
		else if(this.AccountName === null || this.AccountName === undefined || this.AccountName === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Claim Payment Rekening can't be empty";
		}
		else if(this.PPN === null || this.PPN === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "PPN 11% can't be empty";
		}
		else if(this.PPH === null || this.PPH === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "PPH 2 can't be empty";
		}
		else if(this.PPHFinal === null || this.PPHFinal === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "PPH 0.5% can't be empty";
		}
		else if(this.SKPP === null || this.SKPP === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Surat KET PP23 can't be empty";
		}
		else if(this.Note === null || this.Note === undefined || this.Note === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Note can't be empty";
		}
		else if(this.Latitude === null || this.Latitude === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Latitude can't be empty";
		}
		else if(this.Longitude === null || this.Longitude === undefined)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Longitude can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

}

//#endregion
//#region CLASS

export class NumberConstant
{
	//#region USER INTERFACE

	static NUMBER_PRODUCTASRI_GALLERY_SIZE: number = 5;
	static NUMBER_PRODUCTOTO_GALLERY_SIZE: number = 9;

	static NUMBER_VALUE_THEME_SECONDARY_DARK_RED: number = 242;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_GREEN: number = 111;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_BLUE: number = 33;

	static NUMBER_VALUE_THEME_PRIMARY_DARK_RED: number = 40;
	static NUMBER_VALUE_THEME_PRIMARY_DARK_GREEN: number = 118;
	static NUMBER_VALUE_THEME_PRIMARY_DARK_BLUE: number = 188;

	static NUMBER_VALUE_THEME_CHART_STEP_RED: number = 20;
	static NUMBER_VALUE_THEME_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_CHART_STEP_BLUE: number = 3;

	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_RED: number = 5;
	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_BLUE: number = 15;

	static NUMBER_VALUE_THEME_PREMIUM_DARK_RED: number = 193;
	static NUMBER_VALUE_THEME_PREMIUM_DARK_GREEN: number = 89;
	static NUMBER_VALUE_THEME_PREMIUM_DARK_BLUE: number = 29;

	static NUMBER_VALUE_THEME_CHART_APPROVED_RED: number = 52;
	static NUMBER_VALUE_THEME_CHART_APPROVED_GREEN: number = 155;
	static NUMBER_VALUE_THEME_CHART_APPROVED_BLUE: number = 112;

	static NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_RED: number = 0;
	static NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_GREEN: number = 93;
	static NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_BLUE: number = 163;

	static NUMBER_VALUE_THEME_CHART_REJECTED_RED: number = 242;
	static NUMBER_VALUE_THEME_CHART_REJECTED_GREEN: number = 111;
	static NUMBER_VALUE_THEME_CHART_REJECTED_BLUE: number = 33;

	static NUMBER_VALUE_DECIMAL_PREVIEW: number = 2;

	static NUMBER_VALUE_TEN_PERCENT: number = 0.1;

	static NUMBER_VALUE_ROWPERPAGE_FOR_SELECTPARTNERSHIPWORKSHOP: number = 250;
	static NUMBER_VALUE_ROWPERPAGE_FOR_SELECTLICENSEPLATEPREFIX: number = 100;
	static NUMBER_VALUE_ROWPERPAGE_FOR_SELECTBRANCH: number = 100;
	static NUMBER_VALUE_ROWPERPAGE_FOR_LISTTABLE_GENERAL: number = 20;
	static NUMBER_VALUE_ROWPERPAGE_FOR_DIVISION: number = 100;
	static NUMBER_VALUE_ROWPERPAGE_FOR_PARTNERSHIPWORKSHOP: number = 9999;


	static NUMBER_VALUE_GENERAL_ZERO: number = 0;
	static NUMBER_VALUE_GENERAL_ONE: number = 1;
	static NUMBER_VALUE_GENERAL_TWO: number = 2;
	static NUMBER_VALUE_GENERAL_THREE: number = 3;
	static NUMBER_VALUE_GENERAL_FOUR: number = 4;
	static NUMBER_VALUE_GENERAL_FIVE: number = 5;
	static NUMBER_VALUE_GENERAL_SIX: number = 6;
	static NUMBER_VALUE_GENERAL_SEVEN: number = 7;
	static NUMBER_VALUE_GENERAL_EIGHT: number = 8;
	static NUMBER_VALUE_GENERAL_NINE: number = 9;
	static NUMBER_VALUE_GENERAL_TEN: number = 10;
	static NUMBER_VALUE_GENERAL_ONE_HUNDRED: number = 100;

	static NUMBER_VALUE_ZERO: number = 0;
	static NUMBER_VALUE_ONE: number = 1;

	static NUMBER_RISKLIBRARY_ACTIVITY_COLUMN_POSITION: number = 0;
	static NUMBER_RISKLIBRARY_SUBACTIVITY_COLUMN_POSITION: number = 1;
	static NUMBER_RISKLIBRARY_RISKISSUE_COLUMN_POSITION: number = 2;


	//#region COMPARE

	static NUMBER_COMPARE_VALUE_NEGATIVE_ONE: number = -1;
	static NUMBER_COMPARE_VALUE_ZERO: number = 0;
	static NUMBER_COMPARE_VALUE_ONE: number = 1;
	static NUMBER_COMPARE_VALUE_TWO: number = 2;
	static NUMBER_COMPARE_VALUE_THREE: number = 3;
	static NUMBER_COMPARE_VALUE_FOUR: number = 4;
	static NUMBER_COMPARE_VALUE_FIVE: number = 5;
	static NUMBER_COMPARE_VALUE_SIX: number = 6;
	static NUMBER_COMPARE_VALUE_SEVEN: number = 7;
	static NUMBER_COMPARE_VALUE_EIGHT: number = 8;
	static NUMBER_COMPARE_VALUE_NINE: number = 9;
	static NUMBER_COMPARE_VALUE_TEN: number = 10;
	static NUMBER_COMPARE_VALUE_ELEVEN: number = 11;
	static NUMBER_COMPARE_VALUE_TWELVE: number = 11;

	//#endregion


	//#region TABLE

	static NUMBER_TABLE_ROW_MINIMAL: number = 2000;
	static NUMBER_TABLE_ROW_TWOTHOUSAND: number = 2000;

	//#endregion


	//#region CHART

	static NUMBER_VALUE_THEME_CHART_FIRST: number = 207;
	static NUMBER_VALUE_THEME_CHART_SECOND: number = 124;
	static NUMBER_VALUE_THEME_CHART_THIRD: number = 51;
	static NUMBER_VALUE_THEME_CHART_FIRST_STEP: number = 12;
	static NUMBER_VALUE_THEME_CHART_SECOND_STEP: number = 7;
	static NUMBER_VALUE_THEME_CHART_THIRD_STEP: number = 3;
	static NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP: number = 40;
	static NUMBER_VALUE_THEME_CHART_SECOND_VARIATION_STEP: number = 100;
	static NUMBER_VALUE_CHART_LENGTH_FIRST: number = 27;
	static NUMBER_VALUE_CHART_LENGTH_SECOND: number = 36;

	static NUMBER_VALUE_THEME_CHART_PALETTE_FIRST: number = 190;
	static NUMBER_VALUE_THEME_CHART_PALETTE_SECOND: number = 58;
	static NUMBER_VALUE_CHART_PALETTE_LENGTH_FIRST: number = 6;
	static NUMBER_VALUE_CHART_PALETTE_LENGTH_SECOND: number = 12;
	static NUMBER_VALUE_CHART_PALETTE_LENGTH_THIRD: number = 18;

	static NUMBER_VALUE_MARGIN_BAR_CHART: number = 3;
	static NUMBER_VALUE_MARGIN_VERTICAL_BAR_CHART: number = 30;
	static NUMBER_VALUE_GAP_BAR_CHART: number = 15;
	static NUMBER_VALUE_BOUNDARY_GAP_BAR_CHART: number = 0.01;
	static NUMBER_VALUE_CATEGORY_GAP_BAR_CHART: number = 10;

	static NUMBER_VALUE_RADIUS_DOUGHNUT_PIE_CHART: number = 70;
	static NUMBER_VALUE_RADIUS_PIE_CHART: number = 110;
	static NUMBER_VALUE_CENTER_VERTICAL_PIE_CHART: number = 120;
	static NUMBER_VALUE_CENTER_HORIZONTAL_PIE_CHART: number = 50;
	static NUMBER_VALUE_PIE_CHART_LABEL_ITEM_WIDTH: number = 19;
	static NUMBER_VALUE_LEGEND_TITLE_ADDITIONAL: number = 40;
	static NUMBER_VALUE_LEGEND_ADDITIONAL: number = 24;
	static NUMBER_VALUE_WIDTH_PIE_CHART_ADDITIONAL: number = 300;

	static NUMBER_VALUE_CHART_LABEL_ITEM_WIDTH: number = 15;
	static NUMBER_VALUE_CHART_LABEL_ADDITIOANAL_TOP: number = 25;
	static NUMBER_VALUE_CHART_LABEL_HEIGHT: number = 90;
	static NUMBER_VALUE_CHART_LABEL_FONT_SIZE: number = 15;
	static NUMBER_VALUE_CHART_XAXIS_LABEL_FONT_SIZE: number = 16;
	static NUMBER_VALUE_CHART_LEGEND_HEIGHT: number = 135;
	static NUMBER_VALUE_CHART_LEGEND_TITLE_LEFT: number = 50;
	static NUMBER_VALUE_FIRST_ADDITIONAL_HEIGHT: number = 40;
	static NUMBER_VALUE_FIRST_LEGEND_ADDITIONAL: number = 30;
	static NUMBER_VALUE_FIRST_TITLE_ADDITIONAL: number = 25;
	static NUMBER_VALUE_FIRST_XAXIS_LABEL_HEIGHT: number = 35;
	static NUMBER_VALUE_XAXIS_LABEL_HEIGHT: number = 20;
	static NUMBER_VALUE_ROTATION_XAXIS_LABEL: number = 45;
	static NUMBER_VALUE_LITTLE_ROTATION_XAXIS_LABEL: number = 30;
	static NUMBER_VALUE_MEDIUM_ROTATION_XAXIS_LABEL: number = 40;
	static NUMBER_VALUE_ROTATION_ADDITIONAL_HEIGHT: number = 50;
	static NUMBER_VALUE_LITTLE_ROTATION_ADDITIONAL_HEIGHT: number = 40;
	static NUMBER_VALUE_MEDIUM_ROTATION_ADDITIONAL_HEIGHT: number = 45;
	static NUMBER_VALUE_NAME_GAP_YAXIS_LABEL_FIRST: number = 28;
	static NUMBER_VALUE_NAME_GAP_YAXIS_LABEL: number = 8;
	static NUMBER_VALUE_LEFT_GRID_ADDITIONAL_HORIZONTAL: number = 130;
	static NUMBER_VALUE_LEFT_GRID_ADDITIONAL_LABEL: number = 20;
	static NUMBER_VALUE_LEFT_GRID_ADDITIONAL_VERTICAL: number = 50;
	static NUMBER_VALUE_LEGEND_ADDITIONAL_HEIGHT: number = 15;
	static NUMBER_VALUE_DESCRIPTION_MAX_WIDTH: number = 80;
	static NUMBER_VALUE_FIRST_GROUP_ADDITIONAL_HEIGHT: number = 30;
	static NUMBER_VALUE_FIRST_GROUP_LEGEND_TITLE: number = 20;
	static NUMBER_VALUE_NAME_GAP_GROUP_LABEL: number = 30;
	static NUMBER_VALUE_NAME_GAP_GROUP_XAXIS_LABEL: number = 42;

	//#endregion

	//#endregion
}

//#endregion
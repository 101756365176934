//#region IMPORT

import { ENUM_COMMISION_TARGET, ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_MARITAL_STATUS, ENUM_POLICYHOLDER_CLASS_TYPE, ENUM_POLICYHOLDER_GROUP_TYPE, ENUM_POLICYHOLDER_TYPE, ENUM_PROFILE_TYPE, ENUM_RENEWAL_STATUS, ENUM_RESPONSE_STATE, ENUM_REVIEW_STATE, ENUM_SPPA_STATUS, ENUM_UNDERWRITING_STATUS } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ProductAsriModel } from "./productasri.model";
import { ProductAsriSyariahModel } from "./productasrisyariah.model";
import { ProductIndonesianMotorVehicleInsuranceStandardPolicyModel } from "./productindonesianmotorvehicleinsurancestandardpolicy.model";
import { ProductIndonesianMotorVehicleInsuranceStandardPolicySyariahModel } from "./productindonesianmotorvehicleinsurancestandardpolicysyariah.model";
import { ProductMotorcycleModel } from "./productmotorcycle.model";
import { ProductMotorcycleSyariahModel } from "./productmotorcyclesyariah.model";
import { ProductMotorVehicleCreditModel } from "./productmotorvehiclecredit.model";
import { ProductMotorVehicleCreditSyariahModel } from "./productmotorvehiclecreditsyariah.model";
import { ProductOtoModel } from "./productoto.model";
import { ProductOtoMaximumProtectionModel } from "./productotomaximumprotection.model";
import { ProductOtoSyariahModel } from "./productotosyariah.model";
import { ProductPersonalAccidentModel } from "./productpersonalaccident.model";
import { ProductPersonalAccidentSyariahModel } from "./produtpersonalaccidentsyariah.model";
import { ResponseModel } from "./response.model";
import { UserModel } from "./user.model";

//#endregion

//#region CLASS

export class ProductGeneralModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for Product General.
		Author: Frank.
		Created on : Thursday, 21 Maret 2024.			Updated on : Wednesday, 08 June 2022.
		Created by : Rizqi Alfiyati.						Updated by : Bondhan Jantri Ramadhan.
		Version : 1.0:2.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductCode?: string;
	ProductName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	PolicyID?: string;
	CustomerID?: string;
	CustomerSysUserID?: string;
	RelationshipManagerID?: number | null;
	RelationshipManagerName?: string;
	RelationshipManagerEmail?: string;
	RelationshipManagerMobilePhone?: string;
	RelationshipManagerCoreID?: string;
	Disclaimer?: boolean | null;
	SurveyID?: number | null;
	Note?: string;
	CheckerNote?: string;
	CheckerStatus?: ENUM_REVIEW_STATE;
	SignerStatus?: ENUM_REVIEW_STATE;
	SignerNote?: string;
	CheckerBy?: number | null;
	CheckerOn?: Date | null;
	SignerBy?: number | null;
	SignerOn?: Date | null;
	Token?: string;
	LeadToken?: string;
	Status?: ENUM_SPPA_STATUS;
	UnderwritingStatus?: ENUM_UNDERWRITING_STATUS;
	RenewalStatus?: ENUM_RENEWAL_STATUS;
	UnderwrittingHeadOffice?: boolean;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */
	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderFullName?: string;
	PolicyholderBirthDate?: Date | null;
	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;
	PolicyStartDate?: Date | null;
	PolicyEndDate?: Date | null;
	PolicyholderAddress?: string;
	PolicyholderCountryID?: number | null;
	PolicyholderCountryCode?: string;
	PolicyholderCountryName?: string;
	PolicyholderProvinceID?: number | null;
	PolicyholderProvinceCode?: string;
	PolicyholderProvinceName?: string;
	PolicyholderCityID?: number | null;
	PolicyholderCityCode?: string;
	PolicyholderCityName?: string;
	PolicyholderSubDistrictID?: number | null;
	PolicyholderVillageID?: number | null;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number | null;
	PolicyholderPostalCode?: string;
	PolicyholderPostalName?: string;
	PolicyholderLatitude?: number | null;
	PolicyholderLongitude?: number | null;
	PolicyholderBranchID?: number;
	PolicyholderBranchCode?: string;
	PolicyholderBranchName?: string;
	PolicyholderOccupationName?: string;
	PolicyholderOccupationCode?: string;
	PolicyholderMothersMaidenName?: string;
	PolicyholderSourceOfIncome?: string;
	PolicyholderAverageIncomePerYear?: string;
	PolicyholderInsurancePurpose?: string;
	PolicyholderWorkplaceAddress?: string;
	PolicyholderWorkplaceTelephoneNumber?: string;
	PolicyholderBusinessLicenseNumber?: string;
	PolicyholderFieldOfBusiness?: string;
	PolicyholderPositionAddress?: string;
	PolicyholderFormOfLegalBusinessEntity?: string;
	PolicyholderIdentityOfBeneficiary?: string;
	PolicyholderNickname?: string;
	PolicyholderSecondAddress?: string;
	PolicyholderBooleanNickname?: boolean | null;
	PolicyholderBooleanSecondAddress?: boolean | null;
	PolicyholderMaritalStatus?: ENUM_MARITAL_STATUS;
	PolicyholderCitizenshipStatus?: string;
	PolicyholderType?: ENUM_POLICYHOLDER_TYPE;
	PolicyholderClassType?: ENUM_POLICYHOLDER_CLASS_TYPE;
	PolicyholderGroupType?: ENUM_POLICYHOLDER_GROUP_TYPE;
	PolicyholderProfileType?: ENUM_PROFILE_TYPE;

	Pstatus?: boolean;
	PolicyholderPotentialAmount?: number;
	/* POLICYHOLDER - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: number | null;
	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: number | null;
	DiscountAmount?: number | null;
	CommissionCode?: string;
	CommissionPercent?: number | null;
	CommissionCurrency?: string;
	CommissionAmount?: number | null;
	CommissionTarget?: ENUM_COMMISION_TARGET;
	CommissionTargetName?: string;
	AdministrationAmount?: number | null;
	StampAmount?: number | null;
	PremiumTotalAmount?: number | null;
	TotalSumInsured?: number | null;
	InvoiceURL?: string;
	InvoiceExpiredDate?: Date | null;
	InvoiceID?: string;
	PaymentAccountID?: number | null;

	/* PAYMENT - END */

	/* TRACKING - START */
	SubmittedBy?: number;
	SubmittedOn?: Date;
	SavedBy?: number;
	SavedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;
	ApprovedBy?: number;
	ApprovedOn?: Date;
	ConfirmedBy?: number;
	ConfirmedOn?: Date;
	RejectedBy?: number;
	RejectedOn?: Date;
	Total?: number;
	TaxNumber?: string;
	/* TRACKING - END */

	ReferralCode?: string;
	ReferralPurchasingToken?: string;
	PolicyPeriod?: number;
	PolicyPeriodYear?: number;
	PolicyPeriodMonth?: number;
	PolicyPeriodDay?: number;
	// PolicyPeriodType: ENUM_PERIOD_TYPE;
	AgreedOn?: Date;
	MarketingOfficer?: string;
	CreditCardNumber?: string;
	CreditCardExpiredMonth?: string;
	CreditCardExpiredYear?: string;
	CreditCardCVV?: string;
	ReferencePolicyNumber?: string;
	ReferenceANO?: string;
	ANO?: string;
	INO?: string;
	SourceID?: string;
	Description?: string;
	Remarks?: string;
	Information?: string;
	SendSPPAOn?: Date;
	SendConfirmation?: boolean;
	BusinessSourceID?: string;
	CompanyShare?: number;
	PremiumBRINS?: number;
	BusinessSourceType?: string;
	BusinessSourceName?: string;
	listModelUser?: Array<UserModel>;
	// modelProductAsri?: ProductAsriModel;
	// modelProductAsriSyariah?: ProductAsriSyariahModel;
	// modelProductGeneralConfirmation?: ProductGeneralConfirmationModel;

	// listModelProductGeneralConfirmation?: Array<ProductGeneralConfirmationModel>;
	modelProductOto?: ProductOtoModel;
	modelProductMotorVehicleCredit?: ProductMotorVehicleCreditModel;
	modelProductMotorVehicleCreditSyariah?: ProductMotorVehicleCreditSyariahModel;
	modelProductOtoMaximumProtection?: ProductOtoMaximumProtectionModel;
	// modelProductGriyaMaximumProtection?: ProductGriyaMaximumProtectionModel;
	modelProductMotorcycleSyariah?: ProductMotorcycleSyariahModel;
	modelProductOtoSyariah?: ProductOtoSyariahModel;

	modelUserChecker?: UserModel;
	modelUserSigner?: UserModel;
	// modelProductPersonalAccident?: ProductPersonalAccidentModel;
	// modelProductPersonalAccidentSyariah?: ProductPersonalAccidentSyariahModel;
	// modelVirtualAccount?: VirtualAccountModel;
	// modelThirdPartyPayment?: ThirdPartyPaymentModel;

	// modelProductConversation?: ProductConversationModel;
	// listModelProductConversation?: Array<ProductConversationModel>;
	// listModelProductDepreciation?: Arrray<ProductDepreciationModel>;
	modelUserRelationshipManager?: UserModel;
	// listModelProductDocument?: Array<ProductDocumentModel>;

	modelProductMotorcycle?: ProductMotorcycleModel;
	modelProductIndonesianMotorVehicleInsuranceStandardPolicy?: ProductIndonesianMotorVehicleInsuranceStandardPolicyModel;
	modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah?: ProductIndonesianMotorVehicleInsuranceStandardPolicySyariahModel;
	// modelProductDamageOfBusinessPlace?: ProductDamageOfBusinessPlaceModel;
	// modelProductDamageOfBusinessPlaceSyariah?: ProductDamageOfBusinessPlaceSyariahModel;
	// modelProductMyHouse?: ProductMyHouseModel;
	// modelProductMyHouseSyariah?: ProductMyHouseSyariahModel;
	// modelProductMyMotorcycle?: ProductMyMotorcycleModel;
	// modelProductBicycle?: ProductBicycleModel;
	// modelProductMarineCargo?: ProductMarineCargoModel;
	// modelProductAirCargo?: ProductAirCargoModel;
	// modelProductInlandCargo?: ProductInlandCargoModel;
	// listModelProductGeneralPremiumCalculation?: Array<ProductGeneralPremiumCalculationModel>;
	/* NOT MAPPED - START */

	modelUser?: UserModel;
	modelProductAsri?: ProductAsriModel;
	modelProductAsriSyariah?: ProductAsriSyariahModel;
	modelProductPersonalAccident?: ProductPersonalAccidentModel;
	modelProductPersonalAccidentSyariah?: ProductPersonalAccidentSyariahModel;

	stringBirthDate?: string;

	/* NOT MAPPED - END */

	constructor()
	{
		super();

		this.modelProductAsri = new ProductAsriModel();
		this.modelProductAsriSyariah = new ProductAsriSyariahModel();
		this.modelProductOto = new ProductOtoModel();
		this.modelProductOtoSyariah = new ProductOtoSyariahModel();
		this.modelProductPersonalAccident = new ProductPersonalAccidentModel();
		this.modelProductPersonalAccidentSyariah = new ProductPersonalAccidentSyariahModel();
	}



	// FUNCTION

	//#region FUNCTION

	getClearCalculate(): void
	{
		this.PremiumAmount = 0;
		this.AdministrationAmount = 0;
		this.StampAmount = 0;
		this.PremiumTotalAmount = 0;
	}

	validatePolicyholderEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined || this.PolicyholderEmail === "")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}

	premiumTotalAmount(): void
	{
		if (this.AdministrationAmount !== undefined && this.StampAmount !== undefined && this.PremiumAmount !== undefined && this.AdministrationAmount !== null && this.StampAmount !== null && this.PremiumAmount !== null)
		{
			this.PremiumTotalAmount = this.AdministrationAmount + this.StampAmount + this.PremiumAmount;
		}
		else
		{

		}
	}


}

//#endregion
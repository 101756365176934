<div id="divBodyDashboard" class="DivBodyGeneral">
	<header class="HeaderGeneral">
		<h2>Branch Location</h2>
		<p>Select Branch and Service Center Location</p>
	</header>

	<div id="divMainDashboard" class="DivMainAsideSearch">
		<form>
			<div  class="DivForm">
				<div class="DivFormSingleDropdown">
					<ng-select class="custom" id="selectBranch" [(ngModel)]="_modelBranch" name="modelBranchCode"
						(ngModelChange)="selectBranch(_modelBranch.Latitude,
						_modelBranch.Longitude,
						_modelBranch.Name,
						_modelBranch.Code)" >
						<ng-option class="custom" *ngFor="let modelBranch of _arrayModelBranch" [value]="modelBranch">
							{{modelBranch.Name}}</ng-option>
					</ng-select>
				</div>
			</div>
			<ng-container *ngIf="stringBranchName !== ''">
				<div class="DivForm">
					<fieldset>
						<label for="branchName">
							<p>Branch</p>{{stringBranchName}}
						</label>
						<label for="branchCode">
							<p>Code</p>{{numberBranchCode}}
						</label>
					</fieldset>
				</div>
			</ng-container>
		</form>
		<div class="DivDynamicContainer">
			<form>
				<div class="DivForm">
					<google-map [options]="mapOptions" height="500px" width="100%">
						<map-marker #markerElem="mapMarker" 
							*ngFor="let marker of _arrayModelBranch; let numberIndex = index"
							[position]="{ lat: marker.Latitude, lng: marker.Longitude }" 
							[title]="marker.title"
							[options]="marker.options" 
							(mapClick)="openInfoWindow(markerElem, numberIndex)">

							<map-info-window>
								<a> CABANG {{ marker.Name | convertEmptyToDash}}</a>
								<p>{{ marker.Address | convertEmptyToDash}}</p>
							</map-info-window>
						</map-marker>
					</google-map>
				</div>
			</form>
		</div>
	</div>
</div>
<div id="divBodyHome" appCheckDevTools>
	<nav [ngClass]="{ 'NavShow' : _booleanNavigation, 'NavHide' : !_booleanNavigation, 'NavNone' : _booleanNavigation === null }">
		<figure>
			<div>
				<img *ngIf="_modelClaimSignIn.PhotoURL" id="imageProfilePicture">
				<span *ngIf="!_modelClaimSignIn.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
			</div>

			<figcaption>
				<span id="spanProfileName">{{ _modelUserSignIn.getName() }}</span>
				<span id="spanProfilePosition">{{ _modelClaimSignIn.getDivisionName() }}</span>
			</figcaption>

			<!-- <a class="Underline" (click)="goToUserVerifier();">Profile</a> |  -->
			<a class="Underline SignOut" (click)="callSignOut();">Sign out</a>
		</figure>

		<ul class="ListNavigation">
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerDashboard">Dashboard</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerDashboard" [checked]="this._booleanNavigationDashboard" [(ngModel)]="_booleanNavigationDashboard">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="
					_modelUserSignIn.checkGroupAccessDashboardMv(_enumUserGroupID.ClaimMaker)
					|| _modelUserSignIn.checkGroupAccessDashboardMv(_enumUserGroupID.ClaimChecker)
					|| _modelUserSignIn.checkGroupAccessDashboardMv(_enumUserGroupID.ClaimCheckerSigner)
					|| _modelUserSignIn.checkGroupAccessDashboardMv(_enumUserGroupID.ClaimSigner)">
						<a (click)="goToDashboardMv();">Motor Vehicle</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerTicket">Ticket</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerTicket" [checked]="this._booleanNavigationItem3" [(ngModel)]="_booleanNavigationItem3">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReceived();">Received</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSent();">Create</a>
					</li>
					<li *ngIf="_modelUserSignIn.checkGroupAccessGeneral([_userGroupGeneral.JLO])" class="ListItemSubNavigation">
						<a (click)="goToTicketServices();">Services</a>
					</li>
					<li *ngIf="_modelUserSignIn.checkGroupAccessGeneral([_userGroupGeneral.JLO])" class="ListItemSubNavigation">
						<a (click)="goToTicketServiceAgents();">Service Agents</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReport();">Report Ticket</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSatisfactionReport();">Report  Satisfaction Ticket</a>
					</li>
				</ul>
			</li>
			<li *ngIf="!_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) && !_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)" class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerSubmission">Submission</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerSubmission" [checked]="this._booleanNavigationItem" [(ngModel)]="_booleanNavigationItem">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToSubmissionSubmitList();">Submit Report</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToSubmissionSubmittedList();">Submitted List</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationEmeterei">e-Meterai</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationEmeterei" [checked]="this._booleanNavigationEmaterai" [(ngModel)]="_booleanNavigationEmaterai">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToEmateraiForMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToEmateraiForChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToEmateraiForSigner();">Signer</a>
					</li>
				</ul>
			</li> -->
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkUserGroupIDClaimMV() || (_modelUserSignIn.checkUserGroupIDClaimNonMV() && _modelUserSignIn.BranchCode !== _stringHeadOffice)">
				<label for="checkBoxNavigationTriggerApproval">Approval</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApproval" [checked]="this._booleanNavigationItem2" [(ngModel)]="_booleanNavigationItem2">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToConfirmationList();">Confirmation List</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToConfirmedList();">Confirmed List</a>
					</li>
					<li *ngIf="_modelUserSignIn.BranchCode === _stringHeadOffice && _modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)" class="ListItemSubNavigation">
						<a (click)="goToApprovalNotVerified();">Not Verified List</a>
					</li>
					<li *ngIf="_modelUserSignIn.BranchCode === _stringHeadOffice" class="ListItemSubNavigation">
						<a (click)="goToApproval();">Approval</a>
					</li>
					<li *ngIf="_modelUserSignIn.BranchCode === _stringHeadOffice" class="ListItemSubNavigation">
						<a (click)="goToApproved();">Approved</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.BranchCode !== _stringHeadOffice && _modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) && this._modelUserSignIn.checkUserGroupIDClaimNonMV()">
				<label for="checkBoxNavigationTriggeCorrection">Correction</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggeCorrection" [checked]="this._booleanNavigationCorrection" [(ngModel)]="_booleanNavigationCorrection">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToCorrectionChecker();">Approval Correction Checker</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<a (click)="goToHistory();" >History</a>
			</li> -->
			<li class="ListItemNavigation">
				<a (click)="goToMonitoring();">Monitoring</a>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerAdjuster">Adjuster</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerAdjuster" [checked]="this._booleanNavigationAdjuster" [(ngModel)]="_booleanNavigationAdjuster">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) && _modelUserSignIn.BranchCode === _stringHeadOffice">
						<a (click)="goToAdjusterForHeadOffice();">Adjust</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) && _modelUserSignIn.BranchCode === _stringHeadOffice">
						<a (click)="goToAdjustedForHeadOffice();">Adjusted</a>
					</li>
					<li *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker) && _modelUserSignIn.BranchCode !== _stringHeadOffice" class="ListItemSubNavigation">
						<a (click)="goToAdjusterMakerForBranch();">Adjuster Branch</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToAdjusterForCheckerList();">Approval Adjuster Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToApprovedCheckerAdjuster();">Approved Adjuster Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToAdjusterForSignerList();">Approval Adjuster Signer</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToApprovedSignerAdjuster();">Approved Adjuster Signer</a>
					</li>
				</ul>
			</li> -->
			<!-- <li class="ListItemNavigation">
				<a (click)="goToSurveyOnline();">Survey Online</a>
			</li> -->
			<li class="ListItemNavigation" *ngIf=" _modelUserSignIn.BranchCode === _stringHeadOffice && this._modelUserSignIn.checkUserGroupIDClaimNonMV()">
				<label for="checkBoxNotification">Notification Loss Advice</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNotification" [checked]="this._booleanNavigationItem7" [(ngModel)]="_booleanNavigationItem7">
				<ul class="ListSubNavigation">
					<li  class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goNotificationLossAdvice();">Submit NLA</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goHistoryNotificationLossAdvice();">Submitted NLA</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="this._modelUserSignIn.checkLetterRole(this._enumLetterRole.Checker)">
						<a (click)="goToNotifactionLossAdivceForCheckerList();">NLA for Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="this._modelUserSignIn.checkLetterRole(this._enumLetterRole.Signer)">
						<a (click)="goToNotifactionLossAdivceForSignerList();">NLA for Signer</a>
					</li>
				</ul>
			</li>
			<li *ngIf="_modelUserSignIn.BranchCode === _stringHeadOffice && (_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)) && _modelUserSignIn.checkUserGroupIDClaimMV()" class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerSurvey">Edit</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerSurvey" [checked]="this._booleanNavigationItem4" [(ngModel)]="_booleanNavigationItem4">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToSurveyor();">Surveyor</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToWorkshop();">Workshop</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation" *ngIf=" !_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)" >
				<label for="checkBoxNavigationTriggerEndorse">Endorse</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerEndorse" [checked]="this._booleanNavigationItem5" [(ngModel)]="_booleanNavigationItem5">
				<ul class="ListSubNavigation">
					<li *ngIf="_modelUserSignIn.BranchCode === _stringMainBranchOffice" class="ListItemSubNavigation">
						<a (click)="goToRequestEndorse();">Request List</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToApprovedEndorse();">Approval List</a>
					</li>
				</ul>
			</li> -->
			<!-- <li class="ListItemNavigation">
				<a (click)="goToBranch();">Branch</a>
			</li> -->
			<li class="ListItemNavigation">
				<a (click)="goToReport();">Report</a>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerPartnershipWorkshop">Partnership Workshop</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerPartnershipWorkshop" [checked]="this._booleanNavigationPartnershipWorkshop" [(ngModel)]="_booleanNavigationPartnershipWorkshop">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToSupplier();">Supplier</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToPartnershipWorkshop();">Workshop</a>
					</li>
					<!-- <li class="ListItemSubNavigation">
						<a (click)="goToPartnership();">Partnership Workshop</a>
					</li> -->
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerEWS">Early Warning Signal</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerEWS" [checked]="this._booleanNavigationEWS" [(ngModel)]="_booleanNavigationEWS">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToEWSDashboard();">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerEWSFollowUp">Follow Up Plan</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerEWSFollowUp" [checked]="this._booleanNavigationEWSFollowUp" [(ngModel)]="_booleanNavigationEWSFollowUp">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToEWSFollowUpPlan();">List</a>
							</li>
							<li class="ListItemSubNavigation">
								<a (click)="goToEWSFollowUpPlanMonitoring();">Monitoring</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToEWSReport();">Report</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<a (click)="goToPartnershipWorkshop();">Partnership Workshop</a>
			</li> -->
			<!-- <ng-container *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerReimbursement">Reimbursement</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerReimbursement" [checked]="this._booleanNavigationReimbursement" [(ngModel)]="_booleanNavigationReimbursement">
					<ul class="ListSubNavigation" style="font-size: 15px;">
						<li class="ListItemNavigation">
							<label for="checkBoxNavigationTriggerApprovalReimbursement">Approval</label>
							<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApprovalReimbursement" [checked]="this._booleanNavigationApproval" [(ngModel)]="_booleanNavigationApproval">
							<ul class="ListSubNavigation">
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
									<a (click)="goToReimbursementGeneralChecker();">Checker</a>
								</li>
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
									<a (click)="goToReimbursementGeneralSigner();">Signer</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ng-container> -->
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerApuPPT">APU PPT</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApuPPT" [checked]="this._booleanNavigationApuPPT" [(ngModel)]="_booleanNavigationApuPPT">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToAPUPPTCustomer();">Customer Data</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToRiskBasedAssessment();">Risk Based Assessment (RBA)</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTransaction();">Transaction</a>
					</li>
				</ul>
			</li> -->
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
				<label for="checkBoxNavigationTriggerRcsa">RCSA</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRcsa" [checked]="this._booleanNavigationItemRCSA" [(ngModel)]="_booleanNavigationItemRCSA">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
						<a (click)="goToAssessment();">Assessment</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER);">
						<a (click)="goToApprovalRCSA();">Approval</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToReportRCSA();">Report</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRiskIssueRequest();">Risk Issue Request</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation" *ngIf="(_modelUserSignIn.BranchID === undefined && (_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_MANAGEMENTRISK) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER))) || (_modelUserSignIn.BranchID === null && (_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_MANAGEMENTRISK) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)))">
				<label for="checkBoxNavigationTriggerRAS">RAS</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRAS" [checked]="this._booleanNavigationItemRAS" [(ngModel)]="_booleanNavigationItemRAS">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessComplienceAndRiskManagement(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRASDashboard();">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
						<a (click)="goToRASFollowUpPlan();">Follow Up Plan</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerGCGOnline">GCG Online</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerGCGOnline" [checked]="this._booleanNavigationItemGCGOnline" [(ngModel)]="_booleanNavigationItemGCGOnline">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="!_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGADMIN);">
						<a (click)="goToGCGOnlineProfile();">Profile</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineGratification();">Gratification</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineIntegrityPact();">Integrity Pact</a>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerCodeOfEthics">Code of Ethics</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerCodeOfEthics" [checked]="this._booleanNavigationCodeOfEthics" [(ngModel)]="_booleanNavigationCodeOfEthics">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineCodeOfEthics();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineCodeOfEthicsApproval();">Approval</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerAnnualDisclosure">Annual Disclosure</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerAnnualDisclosure" [checked]="this._booleanNavigationAnnualDisclosure" [(ngModel)]="_booleanNavigationAnnualDisclosure">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineAnnualDisclosure();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineAnnualDisclosureApproval();">Approval</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerLegalDocumentRequest">Legal Document Request</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerLegalDocumentRequest" [checked]="this._booleanNavigationItemDocumentRequest" [(ngModel)]="_booleanNavigationItemDocumentRequest">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation"*ngIf="!_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer) && !_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDigitalizationMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDigitalizationChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToDigitalizationSigner();">Signer</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
			<!-- <li class="ListItemNavigation" (click)="callSelectAnomalyNotificationCount(this)"> -->
				<label for="checkBoxNavigationTriggerPROTECT">Protect</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerPROTECT" [checked]="this._booleanNavigationPROTECT" [(ngModel)]="_booleanNavigationPROTECT">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToAnomaly();">Anomaly</a>
					</li>
					<!-- <li class="ListItemSubNavigation" [ngClass]="{'ListItemSubNavigationRelative' : _numberAnomalyNotification }">
						<a (click)="goToAnomalyNotification();"> Notification </a>
						<div *ngIf="_numberAnomalyNotification" class="DivNotificationAlert">
							<p>{{ _numberAnomalyNotification }}</p>
						</div>
					</li> -->
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerSalvage">E-Bidding</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerSalvage" [checked]="this._booleanNavigationSalvage" [(ngModel)]="_booleanNavigationSalvage">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingBranchOfficeMaker)">
						<a (click)="goToSubmissionSalvageList()">Submission Salvage</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingHeadOfficeMaker) || _modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingHeadOfficeFirstSigner) || _modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingHeadOfficeSecondSigner)" >
						<a (click)="goToSalvageBuyerList()">Salvage Buyer</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingBranchOfficeMaker) || _modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingHeadOfficeMaker)">
						<a (click)="goToEbiddingSubmissionList()">E-Bidding List</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingHeadOfficeFirstSigner) || _modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingHeadOfficeSecondSigner) ">
						<a (click)="goToApprovalSalvageList()">Approval</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToMonitoringSalvageList()">Monitoring</a>
					</li>
				</ul>
			</li>

			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerBrinspro">BRINSPRO</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerBrinspro" [checked]="_booleanNavigationBrinspro" [(ngModel)]="_booleanNavigationBrinspro">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goProjectManagementDashboard()">Dashboard</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProjectManagementProject()">Project</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToProjectManagementDocument()">Document</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationReport">BRINSTax</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationReport" [checked]="this._booleanNavigationBrinsTax" [(ngModel)]="_booleanNavigationBrinsTax">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToTaxRegulation();">Tax Regulation</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTaxReport();">Tax Report</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTaxRequest();">Tax Request</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerBrinsprime">BRINSPRIME</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerBrinsprime" [checked]="_booleanNavigationBrinsprime" [(ngModel)]="_booleanNavigationBrinsprime">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerKeyPerformanceBranch">KPI Kantor Cabang</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerKeyPerformanceBranch" [checked]="_booleanNavigationKeyPerformanceBranch" [(ngModel)]="_booleanNavigationKeyPerformanceBranch">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupID.BrinsPrimeHeadOfficeMaker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupID.BrinsPrimeHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupID.BrinsPrimeHeadOfficeSigner) ">
								<a (click)="goToKeyPerformanceApproval()">Approval</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupID.BrinsPrimeHeadOfficeMaker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupID.BrinsPrimeHeadOfficeChecker) || _modelUserSignIn.checkGroupAccessBrinsprime(_enumUserGroupID.BrinsPrimeHeadOfficeSigner) ">
								<a (click)="goToKeyPerformanceMonitoring()">Reporting</a>
							</li>
						</ul>
					</li>
				</ul>
			</li> -->
			<li class="ListItemNavigation">
				<a (click)="goToAbout();">About</a>
			</li>

			<div id="divNotificationInstallList" *ngIf="!this._servicePWA._booleanInstalled">
				<div>
					<span>Klaim</span>
					<p class="ParagraphNotificationInstallOthers" *ngIf="!this._servicePWA._booleanIOS">Avoid any hassle to return this app and gives you better experience!</p>
					<p class="ParagraphNotificationInstallIOS" *ngIf="this._servicePWA._booleanIOS">To install this web app on your device tap the 'Share' button and then 'Add to Home Screen' button.</p>
				</div>

				<input type="button" value="Install" id="buttonNotificationInstallList" *ngIf="!this._servicePWA._booleanIOS" (click)="this._servicePWA.installWebsite();">
			</div>
		</ul>

	</nav>

	<main [ngClass]="{ 'MainHide' : _booleanNavigation, 'MainShow' : !_booleanNavigation }">
		<router-outlet></router-outlet>
	</main>

	<input type="button" (click)="toggleNavigation();" id="buttonNavigation">

	<img id="imageLogoHeader" [ngClass]="{'DisplayNone': this._booleanNavigation}" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">
</div>


<!-- Online Survey Notification - Start -->

<ng-container *ngIf="_booleanShowNotificationSurveyOnline">
	<div id="divNotificationOnlineSurvey">
		<div class="divNotificationOnlineSurveyBackground"></div>
		<div class="DivNotificationOnlineSurveyCard">
			<div *ngIf="_modelCloudMessagingSurveyOnline.data?.SCHEDULED_TIME">
				<input value="X" type="button" id="buttonCloseNotificationSurveyOnlineModal" (click)="closeSurveyOnlineModal()">
			</div>
			<form>
				<div class="DivNotificationOnlineSurveyContent">
					<label id="labelNotificationOnlineSurvey"></label>
					<div class="DivNotificationOnlineSurveyDescription">
						<p>Ini merupakan ALERT CALL</p>
						<p id="paragraphNotificationOnlineSurvey"></p>
						<p>Mohon untuk segera ditindaklanjuti.</p>
					</div>
					<p>Terimakasih</p>
					<ng-container *ngIf="_modelCloudMessagingSurveyOnline.data?.SCHEDULED_TIME">
						<a (click)="closeSurveyOnlineModal()">
							<input type="button" value="Lanjutkan" id="buttonNextSurveyOnline">
						</a>
					</ng-container>
					<ng-container *ngIf="!_modelCloudMessagingSurveyOnline.data?.SCHEDULED_TIME">
						<a id="anchorLinkSurveyOnline" target="_blank" (click)="goToVideoCallSurveyOnline()">
							<input type="button" value="Lanjutkan" id="buttonNextSurveyOnline">
						</a>
					</ng-container>
				</div>
			</form>
		</div>
	</div>
</ng-container>

<!-- Online Survey Notification - End -->

//#region IMPORT

import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Router } from "@angular/router";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { DashboardBaseModel } from "src/app/models/bases/dashboard.base.model";
import { ChartItemModel } from "src/app/models/chartitem.model";
import { ChartItemSingleModel } from "src/app/models/libraries/chartitemsingle.model";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "./baseauthourize.component";

//#endregion


//#region CLASS

export class BaseAuthourizeNewChartComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanLegendShow: boolean = true;
	public _booleanLabelShow: boolean = false;

	public _arraySizeChartPieMedium: [number, number] = [240, 240];
	public _arraySizeChartPieSmall: [number, number] = [120, 120];
	public _arraySizeChartPieLarge: [number, number] = [480, 480];
	public _arraySizeChartBarLarge: [number, number] = [900, 360];
	public _arraySizeChartBarSmall: [number, number] = [600, 240];
	public _arraySizeChartBarMedium: [number, number] = [300, 120];

	public _arraySizeChartBarTicketDashboard: [number, number] = [450, 240];

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, protected _breakpointObserver: BreakpointObserver)
	{
		super(serviceSession, router);

		this._breakpointObserver.observe(["(max-width: 950px)", "(max-width: 650px)"]).subscribe((stateBreakpoint: BreakpointState) =>
		{
			if (stateBreakpoint.breakpoints["(max-width: 650px)"])
			{
				this._arraySizeChartBarLarge = [300, 360];
				this._arraySizeChartBarSmall = [300, 360];
				this._arraySizeChartBarMedium = [300, 360];
				this._arraySizeChartBarTicketDashboard = [300, 360];
			}
			else if (stateBreakpoint.breakpoints["(max-width: 950px)"])
			{
				this._arraySizeChartBarLarge = [600, 360];
				this._arraySizeChartBarSmall = [600, 360];
				this._arraySizeChartBarMedium = [600, 360];
				this._arraySizeChartBarTicketDashboard = [600, 360];
			}
			else
			{
				this._arraySizeChartBarLarge = [900, 360];
				this._arraySizeChartBarSmall = [600, 240];
				this._arraySizeChartBarMedium = [300, 120];
				this._arraySizeChartBarTicketDashboard = [450, 240];
			}
		});
	}

	//#endregion


	//#region FUNCTION

	generateChartColor(arrayChartItem?: Array<ChartItemModel>): Array<string>
	{
		const arrayChartColor: Array<string> = [];

		if (arrayChartItem !== undefined && arrayChartItem != null)
		{
			let numberOfVariation: number;
			let numberRed: number;
			let numberGreen: number;
			let numberBlue: number;
			let numberRedStep: number;
			let numberGreenStep: number;
			let numberBlueStep: number;

			if (arrayChartItem.length <= NumberConstant.NUMBER_VALUE_CHART_LENGTH_FIRST)
			{
				numberOfVariation = NumberConstant.NUMBER_VALUE_GENERAL_THREE;
			}
			else if (arrayChartItem.length > NumberConstant.NUMBER_VALUE_CHART_LENGTH_FIRST && arrayChartItem.length <= NumberConstant.NUMBER_VALUE_CHART_LENGTH_SECOND)
			{
				numberOfVariation = NumberConstant.NUMBER_VALUE_GENERAL_FOUR;
			}
			else
			{
				numberOfVariation = NumberConstant.NUMBER_VALUE_GENERAL_FIVE;
			}

			for (let numberIndexChart = 0; numberIndexChart < Math.ceil(arrayChartItem.length / numberOfVariation); numberIndexChart++)
			{

				if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE > NumberConstant.NUMBER_VALUE_GENERAL_FIVE)
				{

					numberGreenStep = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_STEP;
					numberRedStep = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND_STEP;
					numberBlueStep = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD_STEP;
				}
				else if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE < NumberConstant.NUMBER_VALUE_GENERAL_THREE)
				{
					numberBlueStep = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_STEP;
					numberGreenStep = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND_STEP;
					numberRedStep = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD_STEP;
				}
				else
				{
					numberRedStep = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_STEP;
					numberBlueStep = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND_STEP;
					numberGreenStep = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD_STEP;
				}

				if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE === NumberConstant.NUMBER_VALUE_GENERAL_EIGHT)
				{
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND + NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD + NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
				}
				else if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE === NumberConstant.NUMBER_VALUE_GENERAL_SEVEN)
				{
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST;
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD;
				}
				else if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE === NumberConstant.NUMBER_VALUE_GENERAL_SIX)
				{
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND + NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND_VARIATION_STEP;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD;
				}
				else if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE === NumberConstant.NUMBER_VALUE_GENERAL_FIVE)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND + NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD + NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
				}
				else if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE === NumberConstant.NUMBER_VALUE_GENERAL_FOUR)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD;
				}
				else if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE === NumberConstant.NUMBER_VALUE_GENERAL_THREE)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND + NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND_VARIATION_STEP;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD;
				}
				else if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE === NumberConstant.NUMBER_VALUE_GENERAL_TWO)
				{
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND + NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD + NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
				}
				else if (numberIndexChart % NumberConstant.NUMBER_VALUE_GENERAL_NINE === NumberConstant.NUMBER_VALUE_GENERAL_ONE)
				{
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND;
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD;
				}
				else
				{
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_FIRST_VARIATION_STEP;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND + NumberConstant.NUMBER_VALUE_THEME_CHART_SECOND_VARIATION_STEP;
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_THIRD;
				}

				let numberOfLooping: number;

				if ((numberIndexChart + 1) * numberOfVariation > arrayChartItem.length)
				{
					numberOfLooping = arrayChartItem.length - (numberIndexChart * numberOfVariation);
				}
				else
				{
					numberOfLooping = numberOfVariation;
				}

				for (let numberIndex = 0; numberIndex < numberOfLooping; numberIndex++)
				{
					numberRed = (numberRed - (numberIndex * numberRedStep));
					numberGreen = (numberGreen - (numberIndex * numberGreenStep));
					numberBlue = (numberBlue - (numberIndex * numberBlueStep));
					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
		}
		else
		{

		}

		return arrayChartColor;
	}

	generateChartColorPie(arrayChartItem?: Array<ChartItemModel>): Array<string>
	{
		const arrayChartColor: Array<string> = [];

		if (arrayChartItem !== undefined && arrayChartItem != null)
		{
			// rule :
			// NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND + (n * numberStep) = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST
			// 0 < (NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST && NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND) < 255

			let numberStep: number;

			if (arrayChartItem.length <= NumberConstant.NUMBER_VALUE_CHART_PALETTE_LENGTH_FIRST)
			{
				numberStep = (NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND);
			}
			else if (arrayChartItem.length <= NumberConstant.NUMBER_VALUE_CHART_PALETTE_LENGTH_SECOND)
			{
				numberStep = (NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND) / NumberConstant.NUMBER_VALUE_GENERAL_TWO;
			}
			else if (arrayChartItem.length <= NumberConstant.NUMBER_VALUE_CHART_PALETTE_LENGTH_THIRD)
			{
				numberStep = (NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND) / NumberConstant.NUMBER_VALUE_GENERAL_THREE;
			}
			else
			{
				numberStep = (NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST - NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND) / NumberConstant.NUMBER_VALUE_GENERAL_FOUR;
			}

			let numberRed: number = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND;
			let numberGreen: number = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND;
			let numberBlue: number = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST;
			let numberOfIndexBefore: number = 0;

			for (let numberIndexChart = 0; numberIndexChart < arrayChartItem.length; numberIndexChart++)
			{
				if (numberRed === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST && numberGreen < NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST && numberBlue === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND)
				{
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND + ((numberIndexChart - numberOfIndexBefore) * numberStep );
					if (numberGreen === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST)
					{
						numberOfIndexBefore = numberIndexChart;
					}
					else
					{

					}
				}
				else if (numberRed > NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND && numberGreen === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST && numberBlue === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST - ((numberIndexChart - numberOfIndexBefore) * numberStep );
					if (numberRed === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND)
					{
						numberOfIndexBefore = numberIndexChart;
					}
					else
					{

					}
				}
				else if (numberRed === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND && numberGreen === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST && numberBlue < NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST)
				{
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND + ((numberIndexChart - numberOfIndexBefore) * numberStep );
					if (numberBlue === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST)
					{
						numberOfIndexBefore = numberIndexChart;
					}
					else
					{

					}
				}
				else if (numberRed === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND && numberGreen > NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND && numberBlue === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST)
				{
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST - ((numberIndexChart - numberOfIndexBefore) * numberStep );
					if (numberGreen === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND)
					{
						numberOfIndexBefore = numberIndexChart;
					}
					else
					{

					}
				}
				else if (numberRed < NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST && numberGreen === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND && numberBlue === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND + ((numberIndexChart - numberOfIndexBefore) * numberStep );
					if (numberRed === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST)
					{
						numberOfIndexBefore = numberIndexChart;
					}
					else
					{

					}
				}
				else
				{
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_FIRST - ((numberIndexChart - numberOfIndexBefore) * numberStep );
					if (numberBlue === NumberConstant.NUMBER_VALUE_THEME_CHART_PALETTE_SECOND)
					{
						numberOfIndexBefore = numberIndexChart;
					}
					else
					{

					}
				}
				arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
			}
		}
		else
		{

		}

		return arrayChartColor;
	}

	generateChartColorTypeStatus(arrayChartItem?: Array<ChartItemModel>): Array<string>
	{
		const arrayChartColor: Array<string> = [];

		if (arrayChartItem !== undefined && arrayChartItem != null)
		{
			arrayChartItem.forEach(modelChartItem =>
			{
				if (modelChartItem.name === "Approved")
				{
					arrayChartColor.push(StringConstant.STRING_FIELD_THEME_APPROVED);
				}
				else if (modelChartItem.name === "Waiting for approval")
				{
					arrayChartColor.push(StringConstant.STRING_FIELD_THEME_WAITINGFORAPPROVAL);
				}
				else if (modelChartItem.name === "Rejected")
				{
					arrayChartColor.push(StringConstant.STRING_FIELD_THEME_REJECTED);
				}
				else
				{

				}
			});
		}
		else
		{

		}

		return arrayChartColor;
	}

	generateChartColorPremiumAndPolicy(arrayChartItem?: Array<ChartItemModel>): Array<string>
	{
		const arrayChartColor: Array<string> = [];

		if (arrayChartItem !== undefined && arrayChartItem != null)
		{
			if (arrayChartItem.length < 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = (NumberConstant.NUMBER_VALUE_THEME_PREMIUM_DARK_RED - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_RED));
					numberGreen = (NumberConstant.NUMBER_VALUE_THEME_PREMIUM_DARK_GREEN - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_GREEN));
					numberBlue = (NumberConstant.NUMBER_VALUE_THEME_PREMIUM_DARK_BLUE - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_BLUE));

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else if (arrayChartItem.length > 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_PREMIUM_DARK_RED;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_PREMIUM_DARK_GREEN;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_PREMIUM_DARK_BLUE;

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else
			{

			}
		}
		else
		{

		}

		return arrayChartColor;
	}

	generateChartColorApproved(arrayChartItem?: Array<ChartItemModel>): Array<string>
	{
		const arrayChartColor: Array<string> = [];

		if (arrayChartItem !== undefined && arrayChartItem != null)
		{
			if (arrayChartItem.length < 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = (NumberConstant.NUMBER_VALUE_THEME_CHART_APPROVED_RED - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_RED));
					numberGreen = (NumberConstant.NUMBER_VALUE_THEME_CHART_APPROVED_GREEN - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_GREEN));
					numberBlue = (NumberConstant.NUMBER_VALUE_THEME_CHART_APPROVED_BLUE - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_BLUE));

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else if (arrayChartItem.length > 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_APPROVED_RED;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_APPROVED_GREEN;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_APPROVED_BLUE;

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else
			{

			}
		}
		else
		{

		}

		return arrayChartColor;
	}

	generateChartColorWaitingForApproval(arrayChartItem?: Array<ChartItemModel>): Array<string>
	{
		const arrayChartColor: Array<string> = [];

		if (arrayChartItem !== undefined && arrayChartItem != null)
		{
			if (arrayChartItem.length < 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = (NumberConstant.NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_RED - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_RED));
					numberGreen = (NumberConstant.NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_GREEN - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_GREEN));
					numberBlue = (NumberConstant.NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_BLUE - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_BLUE));

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else if (arrayChartItem.length > 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_RED;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_GREEN;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_WAITINGFORAPPROVAL_BLUE;

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else
			{

			}
		}
		else
		{

		}

		return arrayChartColor;
	}

	generateChartColorRejected(arrayChartItem?: Array<ChartItemModel>): Array<string>
	{
		const arrayChartColor: Array<string> = [];

		if (arrayChartItem !== undefined && arrayChartItem != null)
		{
			if (arrayChartItem.length < 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = (NumberConstant.NUMBER_VALUE_THEME_CHART_REJECTED_RED - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_RED));
					numberGreen = (NumberConstant.NUMBER_VALUE_THEME_CHART_REJECTED_GREEN - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_GREEN));
					numberBlue = (NumberConstant.NUMBER_VALUE_THEME_CHART_REJECTED_BLUE - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_BLUE));

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else if (arrayChartItem.length > 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_CHART_REJECTED_RED;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_CHART_REJECTED_GREEN;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_CHART_REJECTED_BLUE;

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else
			{

			}
		}
		else
		{

		}

		return arrayChartColor;
	}

	convertChartItemServerToChartItemSingleLibrary(arrayChartItemServer?: Array<ChartItemModel> | null): Array<ChartItemSingleModel>
	{
		const arrayChartItemSingleLibrary: Array<ChartItemSingleModel> = [];

		if (arrayChartItemServer != null && arrayChartItemServer !== undefined)
		{
			if (arrayChartItemServer.length > 0)
			{
				let modelChartItem: ChartItemSingleModel;

				for (const modelChartItemServer of arrayChartItemServer)
				{
					modelChartItem = new ChartItemSingleModel();
					modelChartItem.name = modelChartItemServer.Label === undefined ? "" : modelChartItemServer.Label;
					modelChartItem.value = modelChartItemServer.Value === undefined ? 0 : modelChartItemServer.Value;
					arrayChartItemSingleLibrary.push(modelChartItem);
				}
			}
			else
			{

			}
		}
		else
		{

		}

		return arrayChartItemSingleLibrary;
	}

	protected generatePeriodWeekly(dateToday: Date): DashboardBaseModel
	{
		const dateStart: Date = new Date();
		dateStart.setDate(dateToday.getDate() - (dateToday.getDay() - 1));
		const dateEnd: Date = new Date();
		dateEnd.setDate(dateToday.getDate() + (7 - dateToday.getDay()));
		const modelDashboardBase: DashboardBaseModel = new DashboardBaseModel();
		modelDashboardBase.StartDate = dateStart;
		modelDashboardBase.EndDate = dateEnd;

		return modelDashboardBase;
	}

	//endregion
}

//#endregion
//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class PaymentModel extends BaseModel
{
	Ano?: number;
	PolicyNo?: string;
	LastPayment?: Date;
	PremiumAmountDue?: number;
	Outstanding?: number;
	Installment?: number;
	ProfileID?: string;
}


//#endregion
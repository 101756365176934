//#region IMPORT

import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, QueryList, ViewChildren } from "@angular/core";
import { MapInfoWindow, MapMarker, } from "@angular/google-maps";
import { Router } from "@angular/router";
import { NumberConstant } from "src/app/constants/number.constant";
import { ENUM_BRANCH_TYPE } from "../../constants/enum.constant";
import { ResponseCodeConstant } from "../../constants/responsecode.constant";
import { StringConstant } from "../../constants/string.constant";
import { TableModel } from "../../models/bases/table.model";
import { BranchModel } from "../../models/branch.model";
import { ResponseModel } from "../../models/response.model";
import { BranchService } from "../../services/branch.service";
import { SessionService } from "../../services/session.service";
import { BaseAuthourizeNewChartComponent } from "../bases/baseauthourizenewchart.component";

//#endregion

@Component
(
	{
		selector: "app-branch",
		templateUrl: "./branch.component.html",
		styleUrls: ["./branch.component.sass"]
	}
)

//#region CLASS

export class BranchComponent extends BaseAuthourizeNewChartComponent
{
	//#region DECLARATION

	@ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow> | undefined;

	latitude: number = -1.605328;
	longitude: number = 117.451067;
	zoom: number = 4;

	mapOptions: google.maps.MapOptions =
		{
			center: { lat: this.latitude, lng: this.longitude },
			zoom : this.zoom,
		};

	public stringBranchName = "";
	public numberBranchCode = 0;
	public allBranch: any;

	public _modelBranch: BranchModel;
	public _arrayModelBranch: Array<BranchModel>;
	public _enumBranchType = ENUM_BRANCH_TYPE;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceBranch: BranchService, serviceSession: SessionService, router: Router, breakpointObserver: BreakpointObserver)
	{
		super(serviceSession, router, breakpointObserver);

		this._arrayModelBranch = [];
		this._modelBranch = new BranchModel();
	}

	//#endregion


	//#region INITIALIZATION

	ngOnInit(): void
	{
		this.callSelectBranch(this);
	}

	//#endregion


	//#region WEB SERVICE

	callSelectBranch(componentCurrent: BranchComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTBRANCH;

		this._serviceBranch.selectBranch
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);

						if (modelTableResponse.Result !== undefined)
						{
							componentCurrent._arrayModelBranch = JSON.parse(modelTableResponse.Result);
							if (componentCurrent._arrayModelBranch.length >= 1)
							{
								componentCurrent._modelBranch.Code = StringConstant.STRING_FIELD_BRANCHCODE_HEADQUARTER;
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBranch(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region FUNCTION

	selectBranch(numberLatitude: number, numberLongitude: number, stringBranchName: any, numberBranchCode: any)
	{
		if (numberLatitude == null || numberLatitude === undefined || numberLatitude === 0)
		{
		}
		else
		{
			// this.latitude = numberLatitude;
			// this.longitude = numberLongitude;
			// this.zoom = 12;
			this.stringBranchName = stringBranchName;
			this.numberBranchCode = numberBranchCode;
		}
	}

	openInfoWindow(marker: MapMarker, numberWindowIndex: number)
	{
		let numberCurrentIndex = 0;
		if ( this.infoWindowsView !== undefined)
		{
			this.infoWindowsView.forEach((window: MapInfoWindow) =>
			{
				if (numberWindowIndex === numberCurrentIndex)
				{
					window.open(marker);
					numberCurrentIndex++;
				}
				else
				{
					numberCurrentIndex++;
				}
			});
		}
		else
		{
		}
	}

	//#endregion
}

//#endregion

//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ClaimProposalModel } from "./claimproposal.model";
import { ResponseModel } from "./response.model";
import { ClaimProposalSatisfactionQuestionModel } from "./claimproposalsatisfactionquestion.model";

//#endregion


//#region CLASS

export class ClaimProposalSatisfactionModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for claim satisfaction survey, used for satisfaction survey.
		Author: Anjar Kusworo.
		Created on : Monday, 27 February 2023. 			Updated on : -.
		Created by : Andryana Baros.					Updated by : -.
		Version : 1.0:3.
	*/

	ID?: number;
	ClaimProposalID?: number;
	Token?: string;
	ClaimCenterService?: number;
	ClaimCenterServiceNotes?: string;
	WorkshopService?: number;
	WorkshopServiceNotes?: string;
	UserClaimID?: number;
	CustomerID?: number;
	Rating?: number;
	Feedback?: string;

	ClaimProposalToken?: string;
	ClaimProposalSatisfactionQuestionToken?: string;
	modelClaimProposal?: ClaimProposalModel;
	modelClaimProposalSatisfactionQuestion?: ClaimProposalSatisfactionQuestionModel;


	/* Attribute - END */


	//#region  VALIDATE

	setValidateSatisfaction(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		if (this.ClaimCenterService === null || this.ClaimCenterService === undefined)
		{
			modelResponse.MessageContent = "Penilaian claim center tidak boleh kosong";
		}
		else if (this.WorkshopService === null || this.WorkshopService === undefined)
		{
			modelResponse.MessageContent = "Penilaian bengkel tidak boleh kosong";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	//#endregion
}

//#endregion

//#region IMPORT

import { ENUM_CLAIMPROPOSAL_TRACKING_PHASE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ClaimProposalModel } from "./claimproposal.model";
import { ClaimProposalTrackingGalleryModel } from "./claimproposaltrackinggallery.model";
import { ClaimProposalSatisfactionModel } from "./claimproposalsatisfaction.model";
import { ResponseModel } from "./response.model";



//#endregion


//#region CLASS

export class ClaimProposalTrackingModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for tracking claim, used for tracking claim.
		Author: Anjar Kusworo.
		Created on : Monday, 27 February 2023. 			Updated on : -.
		Created by : Andryana Baros.					Updated by : -.
		Version : 1.0:2.
	*/

	ID?: number;
	Token?: string;
	ClaimProposalID?: number;
	ClaimProposalToken?: string;
	TicketNumber?: string;
	Phase?: ENUM_CLAIMPROPOSAL_TRACKING_PHASE;
	Action?: string;
	SubAction?: string;
	AID?: string;
	ActionDate?: string;
	ActionTime?: Date;
	ActionUser?: string;
	TotalJobEstPrice?: number;
	TotalJobProPrice?: number;
	TotalJobPrice?: number;
	TotalPartExtPrice?: number;
	TotalPartProPrice?: number;
	TotalPartPrice?: number;
	modelClaimProposal?: ClaimProposalModel;
	modelClaimProposalSatisfaction?: ClaimProposalSatisfactionModel;
	listModelClaimProposalTrackingGallery?: Array<ClaimProposalTrackingGalleryModel>;

	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.listModelClaimProposalTrackingGallery = [];
	}

	//#endregion


	//#region VALIDATION

	validationUploadDate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Validasi Input Waktu");

		const dateNow: Date = new Date();
		if(this.Token === undefined || this.Token === null || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong";
		}
		else if(this.Action === undefined || this.Action === null || this.Action === "")
		{
			modelResponse.MessageContent = "Action tidak boleh kososng";
		}
		else if(this.Phase === undefined || this.Phase === null)
		{
			modelResponse.MessageContent = "Phase tidak boleh kosong";
		}
		else if(this.ClaimProposalToken === undefined || this.ClaimProposalToken === null || this.ClaimProposalToken === "")
		{
			modelResponse.MessageContent = "Claim proposal token tidak boleh kosong";
		}
		else if(this.ActionDate === undefined || this.ActionDate === null)
		{
			modelResponse.MessageContent = "Tanggal tidak boleh kosong";
		}
		else if (this.ActionDate !== undefined && this.ActionDate !== null && this.ActionDate !== "")
		{
			const dateInput: Date = new Date(this.ActionDate);
			if(dateInput > dateNow)
			{
				modelResponse.MessageContent = "Waktu yang dimasukkan tidak boleh melebihi waktu saat ini";
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		return modelResponse;
	}


	validationInsertDate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Validasi Input Waktu");

		const dateNow: Date = new Date();
		if(this.Action === undefined || this.Action === null || this.Action === "")
		{
			modelResponse.MessageContent = "Action tidak boleh kososng";
		}
		else if(this.Phase === undefined || this.Phase === null)
		{
			modelResponse.MessageContent = "Phase tidak boleh kosong";
		}
		else if(this.ClaimProposalToken === undefined || this.ClaimProposalToken === null || this.ClaimProposalToken === "")
		{
			modelResponse.MessageContent = "Claim proposal token tidak boleh kosong";
		}
		else if(this.ActionDate === undefined || this.ActionDate === null)
		{
			modelResponse.MessageContent = "Tanggal tidak boleh kosong";
		}
		else if (this.ActionDate !== undefined && this.ActionDate !== null && this.ActionDate !== "")
		{
			const dateInput: Date = new Date(this.ActionDate);
			if(dateInput > dateNow)
			{
				modelResponse.MessageContent = "Waktu yang dimasukkan tidak boleh melebihi waktu saat ini";
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		return modelResponse;
	}

	//#endregion
}

//#endregion

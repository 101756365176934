import { ENUM_CLAIMPROPOSALSALVAGEWINNERDOCUMENT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

export class ClaimProposalSalvageWinnerSupportingDocumentModel extends BaseModel
{
	/*
        Attributes
        Description: Attributes for ClaimProposalSalvageWinnerSupportingDocumentModel
        Author: Muhammad Nur Fauzi
        Created on: 20231206            Updated on: 20231206
        Created by: Muhammad Nur Fauzi              Updated by: Muhammad Nur Fauzi
        Version: 1.0:0
    */

	Type?: ENUM_CLAIMPROPOSALSALVAGEWINNERDOCUMENT_TYPE;
	Name?: string;
	Format?: string;
	Extension?: string;
	Size?: number;
	Data?: string;

	//#region FUNCTION

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regexDot: RegExp = /^[^:;\/."<>|*]*$/g;
		modelResponse.setForValidation("Ebidding Upload");

		modelResponse.State = ENUM_RESPONSE_STATE.Fail;

		if(this.Data === null || this.Data === undefined || this.Data === "")
		{
			modelResponse.MessageContent = "Attachment Can't be Empty ";
		}
		else if(this.Extension === null || this.Extension === undefined || this.Extension === "")
		{
			modelResponse.MessageContent = "Attachment Extension Can't be Empty";
		}
		else if(this.Format === null || this.Format === undefined || this.Format === "")
		{
			modelResponse.MessageContent = "Attachment Format Can't be Empty";
		}
		else if(this.Name === null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "File Name Can't be Empty";
		}
		else if(!regexDot.test(this.Name))
		{
			modelResponse.MessageContent = "File Name Not Allowed";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if(this.Extension === StringConstant.STRING_FORMAT_DOCUMENT_PDF)
			{
				if(this.Size !== null && this.Size !== undefined)
				{
					if(this.Size > 10000000)
					{
						modelResponse.MessageContent = "File Too Large";
					}
					else
					{
						modelResponse.State = ENUM_RESPONSE_STATE.Success;
					}
				}
			}
			else
			{
				modelResponse.MessageContent = "Format File Must Be PDF";
			}
		}


		return modelResponse;
	}

	//#endregion
}

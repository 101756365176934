//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class ReinsuranceFacultativePaymentModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	ANO?: number;
	PolicyNumber?: string;
	CertificateNumber?: string;
	DirectPremium?: number;
	DirectPremiumPaid?: number;
	DirectPremiumPaidDate?: Date;
	DirectPremiumOutstanding?: number;
	FacultativePremium?: number;
	FacultativePremiumPaid?: number;
	FacultativePremiumPaidDate?: Date;
	FacultativePremiumOutstanding?: number;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	ProcessDate?: Date;
	RelationshipManager?: string;
	Information?: string;
	ReinsuranceOutstandingPremium?: number;
	AcceptanceDate?: Date;
	FacultativeID?: string;
	FacultativeName?: string;
	ShareReassurancePercentage?: number;
	DirectPremiumRecieved?: number;
	FacultativeReassuranceRecieved?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
		// this.modelAuditFindingWorkPaper = undefined;
	}

	cleanList(): void
	{
		this.AuditFindingWorkPaperCriteriaTableModel = undefined;
	}

	//#endregion
}

//#endregion
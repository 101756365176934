//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_LETTER_ROLE, ENUM_USERGROUPID } from "src/app/constants/enum.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { ClaimProposalModel } from "src/app/models/claimproposal.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { ClaimService } from "src/app/services/claim.service";
import { SessionService } from "src/app/services/session.service";
import { PwaService } from "src/app/services/pwa.service";
import { TableModel } from "src/app/models/bases/table.model";
import { ResponseModel } from "src/app/models/response.model";
import { AnomalyNotificationService } from "src/app/services/anomalynotification.service";
import { NumberConstant } from "src/app/constants/number.constant";
import { messaging } from "src/firebase.config";
import { CloudMessagingModel } from "src/app/models/libraries/cloudmessaging.model";
import { ConvertToDateShort, ConvertToTime } from "src/app/functions/pipes/converter.pipe";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanNavigation: boolean | null;
	public _booleanNavigationItem: boolean | null;
	public _booleanNavigationItem2: boolean | null;
	public _booleanNavigationItem3: boolean | null;
	public _booleanNavigationItem4: boolean | null;
	public _booleanNavigationItem5: boolean | null;
	public _booleanNavigationItem6: boolean | null;
	public _booleanNavigationItem7: boolean | null;
	public _booleanNavigationDashboard: boolean | null;
	public _booleanNavigationEmaterai: boolean | null;
	public _booleanNavigationAdjuster: boolean | null;
	public _booleanNavigationAdjusterHeadOffice: boolean | null;
	public _booleanNavigationCorrection: boolean | null;
	public _booleanNavigationReimbursement: boolean | null;
	public _booleanNavigationApproval: boolean | null;
	public _booleanNavigationItemRCSA: boolean | null;
	public _booleanNavigationItemDocumentRequest: boolean | null;
	public _booleanEMaterai: boolean | null;
	public _booleanNavigationPROTECT: boolean | null;
	public _booleanNavigationSalvage: boolean | null;
	public _booleanNavigationItemRAS: boolean | null;
	public _booleanNavigationItemGCGOnline: boolean | null;
	public _booleanNavigationCodeOfEthics: boolean | null;
	public _booleanNavigationAnnualDisclosure: boolean | null;
	public _booleanNavigationPartnershipWorkshop: boolean | null;
	public _booleanNavigationApuPPT: boolean | null;
	public _booleanNavigationBrinsTax: boolean | null;
	public _booleanNavigationBrinspro: boolean | null;
	public _booleanNavigationEWS: boolean | null;
	public _booleanNavigationEWSFollowUp: boolean | null;
	public _booleanNavigationBrinsprime: boolean | null;
	public _booleanNavigationKeyPerformanceBranch: boolean | null;
	public _booleanNavigationKeyPerformanceCompany: boolean | null;
	public _booleanNavigationKeyPerformanceDirector: boolean | null;
	public _booleanNavigationKeyPerformanceCorporateSubmission: boolean | null;
	public _booleanNavigationInformation: boolean | null;

	public _numberConstant = NumberConstant;
	public _numberAnomalyNotification: number;
	public _enumLetterRole = ENUM_LETTER_ROLE;
	public _stringHeadOffice = StringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE;
	public _stringMainBranchOffice = StringConstant.STRING_FIELD_BRANCHCODE_MAINBRANCHOFFICE;
	public _stringConstant = StringConstant;
	public _enumUserGroupID = ENUM_USERGROUPID;

	public _elementHTMLAnchorLinkSurveyOnline: HTMLElement | any;
	public _elementHTMLDivNotificationSurveyOnline: HTMLElement | any;
	public _elementHTMLLabelNotificationSurveyOnline: HTMLElement | any;
	public _elementHTMLParagraphNotificationSurveyOnline: HTMLElement | any;
	public _elementHTMLAudioNotificationSurveyOnline: HTMLElement | any;
	public _modelCloudMessagingSurveyOnline: CloudMessagingModel;
	public _booleanShowNotificationSurveyOnline: boolean;

	public _pipeConvertToDateShort: ConvertToDateShort;
	public _pipeConvertToTime: ConvertToTime;

	public _userGroupGeneral = ENUM_USERGROUPID;

	private _audioRingtoneSurveyOnline = new Audio ("../../../assets/musics/ringtone.mp3");


	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceClaim: ClaimService, serviceSession: SessionService, router: Router, public _servicePWA: PwaService, private _serviceAnomalyNotification: AnomalyNotificationService)
	{
		super(serviceSession, router);

		this._booleanNavigation = null;
		this._booleanNavigationItem = null;
		this._booleanNavigationItem2 = null;
		this._booleanNavigationItem3 = null;
		this._booleanNavigationItem4 = null;
		this._booleanNavigationItem5 = null;
		this._booleanNavigationItem6 = null;
		this._booleanNavigationItem7 = null;
		this._booleanNavigationDashboard = null;
		this._booleanNavigationEmaterai = null;
		this._booleanNavigationReimbursement = null;
		this._booleanNavigationApproval = null;
		this._booleanNavigationAdjuster = null;
		this._booleanNavigationAdjusterHeadOffice = null;
		this._booleanNavigationCorrection = null;
		this._booleanNavigationItemRCSA = null;
		this._modelClaimSignIn.PhotoURL = undefined;
		this._booleanNavigationItemDocumentRequest = null;
		this._booleanEMaterai = null;
		this._booleanNavigationPROTECT = null;
		this._booleanNavigationSalvage = null;
		this._booleanNavigationItemRAS = null;
		this._booleanNavigationPartnershipWorkshop = null;
		this._numberAnomalyNotification = 0;
		this._booleanNavigationItemGCGOnline = null;
		this._booleanNavigationCodeOfEthics = null;
		this._booleanNavigationAnnualDisclosure = null;
		this._booleanNavigationApuPPT = null;
		this._booleanNavigationBrinsTax = null;
		this._booleanNavigationApuPPT = null;
		this._booleanNavigationBrinspro = null;
		this._booleanNavigationEWS = null;
		this._booleanNavigationEWSFollowUp = null;
		this._booleanNavigationBrinsprime = null;
		this._booleanNavigationKeyPerformanceBranch = null;
		this._booleanNavigationKeyPerformanceCompany = null;
		this._booleanNavigationKeyPerformanceDirector = null;
		this._booleanNavigationKeyPerformanceCorporateSubmission = null;
		this._booleanNavigationInformation = null;

		this._booleanShowNotificationSurveyOnline = true;
		this._modelCloudMessagingSurveyOnline = new CloudMessagingModel();
		this._pipeConvertToDateShort = new ConvertToDateShort();
		this._pipeConvertToTime = new ConvertToTime();
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
		// this.listenCloudMessagingSurveyOnline();
	}

	//#endregion


	//#region FUNCTION

	toggleNavigation(): void
	{
		this._booleanNavigation = !this._booleanNavigation;
		this._booleanNavigationItem = false;
		this._booleanNavigationItem2 = false;
		this._booleanNavigationItem3 = false;
		this._booleanNavigationItem4 = false;
		this._booleanNavigationItem5 = false;
		this._booleanNavigationItem6 = false;
		this._booleanNavigationItem7 = false;
		this._booleanNavigationDashboard = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationAdjuster = false;
		this._booleanNavigationAdjusterHeadOffice = false;
		this._booleanNavigationCorrection = false;
		this._booleanNavigationReimbursement = false;
		this._booleanNavigationApproval = false;
		this._booleanNavigationItemDocumentRequest = false;
		this._booleanEMaterai = false;
		this._booleanNavigationPROTECT = false;
		this._booleanNavigationItemGCGOnline = false;
		this._booleanNavigationCodeOfEthics = false;
		this._booleanNavigationAnnualDisclosure = false;
		this._booleanNavigationDashboard= false;
		this._booleanNavigationApuPPT = false;
		this._booleanNavigationEWS = false;
		this._booleanNavigationEWSFollowUp = false;
	}

	toggleNavItem(): void
	{
		this._booleanNavigationItem = false;
		this._booleanNavigationItem2 = false;
		this._booleanNavigationItem3 = false;
		this._booleanNavigationItem4 = false;
		this._booleanNavigationItem5 = false;
		this._booleanNavigationItem6 = false;
		this._booleanNavigationItem7 = false;
		this._booleanNavigationDashboard = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationAdjuster = false;
		this._booleanNavigationAdjusterHeadOffice = false;
		this._booleanNavigationCorrection = false;
		this._booleanNavigationItemDocumentRequest = false;
		this._booleanEMaterai = false;
		this._booleanNavigationPROTECT = false;
		this._booleanNavigationDashboard= false;
		this._booleanNavigationApuPPT = false;
	}

	//#endregion


	//#region WEB SERVICE

	callSignOut(): void
	{
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			this._serviceClaim.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new ClaimProposalModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	public callSelectAnomalyNotificationCount(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(this._numberConstant.NUMBER_COMPARE_VALUE_ONE);

		const modelTable: TableModel = new TableModel();

		this._serviceAnomalyNotification.selectAnomalyNotificationCount
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if(modelResponse.Data != null || modelResponse.Data !== undefined)
					{
						componentCurrent._numberAnomalyNotification = Number(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				}
				else
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAnomalyNotificationCount(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region NAVIGATION

	goToDashboard(): void
	{
		this._router.navigate(["home", "dashboard"]);
		this.toggleNavigation();
	}

	goToDashboardSmart(): void
	{
		this._router.navigate(["home", "dashboard", "smart"]);
		this.toggleNavigation();
	}

	goToDashboardTicket(): void
	{
		this._router.navigate(["home", "dashboard", "ticket"]);
		this.toggleNavigation();
	}

	goToAbout(): void
	{
		this._router.navigate(["home", "about"]);
		this.toggleNavigation();
	}

	goToSubmissionSubmitList(): void
	{
		this._router.navigate(["home", "submission", "submit", "list"]);
		this.toggleNavigation();
	}

	goToSubmissionSubmittedList(): void
	{
		this._router.navigate(["home", "submission", "submitted", "list"]);
		this.toggleNavigation();
	}

	goToConfirmationList(): void
	{
		this._router.navigate(["home", "approval", "confirmation", "list"]);
		this.toggleNavigation();
	}

	goToConfirmedList(): void
	{
		this._router.navigate(["home", "approval", "confirmed", "list"]);
		this.toggleNavigation();
	}

	goToApprovalNotVerified(): void
	{
		this._router.navigate(["home", "approval", "notverified", "list"]);
		this.toggleNavigation();
	}

	goToApprovalConfirmedNotVerified(): void
	{
		this._router.navigate(["home", "approval", "confirmednotverified", "list"]);
		this.toggleNavigation();
	}

	goToCorrectionChecker(): void
	{
		this._router.navigate(["home", "correction", "approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToApproval(): void
	{
		this._router.navigate(["home", "approval", "approval", "list"]);
		this.toggleNavigation();
	}

	goToApproved(): void
	{
		this._router.navigate(["home", "approval", "approved", "list"]);
		this.toggleNavigation();
	}

	goToNotifactionLossAdivceForCheckerList(): void
	{
		this._router.navigate(["home", "notification", "notificationlossadviceforchecker", "list"]);
		this.toggleNavigation();
	}

	goToNotifactionLossAdivceForSignerList(): void
	{
		this._router.navigate(["home", "notification", "notificationlossadviceforsigner", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForMaker(): void
	{
		this._router.navigate(["home", "ematerai", "maker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForChecker(): void
	{
		this._router.navigate(["home", "ematerai", "checker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForSigner(): void
	{
		this._router.navigate(["home", "ematerai", "signer", "list"]);
		this.toggleNavigation();
	}

	goToAdjusterForCheckerList(): void
	{
		this._router.navigate(["home", "adjuster", "adjusterforchecker", "list"]);
		this.toggleNavigation();
	}

	goToAdjusterForSignerList(): void
	{
		this._router.navigate(["home", "adjuster", "adjusterforsigner", "list"]);
		this.toggleNavigation();
	}

	goToMonitoring(): void
	{
		this._router.navigate(["home", "monitoring", "list"]);
		this.toggleNavigation();
	}

	goToSurveyOnline(): void
	{
		this._router.navigate(["home", "surveyonline"]);
		this.toggleNavigation();
	}

	goToAdjusterForHeadOffice(): void
	{
		this._router.navigate(["home", "adjuster", "headoffice", "adjuster", "list"]);
		this.toggleNavigation();
	}

	goToAdjustedForHeadOffice(): void
	{
		this._router.navigate(["home", "adjuster", "headoffice", "adjusted", "list"]);
		this.toggleNavigation();
	}

	goToAdjusterMakerForBranch(): void
	{
		this._router.navigate(["home", "adjuster", "branch", "list"]);
		this.toggleNavigation();
	}

	goToSurveyor(): void
	{
		this._router.navigate(["home", "survey", "surveyor", "list"]);
		this.toggleNavigation();
	}

	goToWorkshop(): void
	{
		this._router.navigate(["home", "survey", "workshop", "list"]);
		this.toggleNavigation();
	}

	goToRequestEndorse(): void
	{
		this._router.navigate(["home", "certificate", "request", "list"]);
		this.toggleNavigation();
	}

	goToApprovedEndorse(): void
	{
		this._router.navigate(["home", "certificate", "approved", "list"]);
		this.toggleNavigation();
	}

	goToBranch(): void
	{
		this._router.navigate(["home", "branch"]);
		this.toggleNavigation();
	}

	// goToPartnershipWorkshop(): void
	// {
	// 	this._router.navigate(["home", "partnershipworkshop"]);
	// 	this.toggleNavigation();
	// }

	goToPartnership(): void
	{
		this._router.navigate(["home", "partnershipworkshop"]);
		this.toggleNavigation();
	}

	goToUserVerifier(): void
	{
		this._router.navigate(["verify", "user", this._modelUserSignIn.Token]);
		this.toggleNavigation();
	}

	goToTicketReceived(): void
	{
		this._router.navigate(["home", "ticket", "received", "list"]);
		this.toggleNavigation();
	}

	goToTicketSent(): void
	{
		this._router.navigate(["home", "ticket", "sent", "list"]);
		this.toggleNavigation();
	}

	goToTicketServices(): void
	{
		this._router.navigate(["home", "ticket","service", "list"]);
		this.toggleNavigation();
	}

	goToTicketServiceAgents(): void
	{
		this._router.navigate(["home", "ticket","serviceagent", "list"]);
		this.toggleNavigation();
	}

	goToTicketReport(): void
	{
		this._router.navigate(["home", "ticket", "report"]);
		this.toggleNavigation();
	}

	goToTicketSatisfactionReport(): void
	{
		this._router.navigate(["home", "ticket", "satisfactionreport"]);
		this.toggleNavigation();
	}

	goNotificationLossAdvice(): void
	{
		this._router.navigate(["home", "notification", "submit", "list"]);
		this.toggleNavigation();
	}

	goHistoryNotificationLossAdvice(): void
	{
		this._router.navigate(["home", "notification", "history", "list"]);
		this.toggleNavigation();
	}

	goToReport(): void
	{
		this._router.navigate(["home", "reporting", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralChecker(): void
	{
		this._router.navigate(["home", "reimbursement" ,"approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralSigner(): void
	{
		this._router.navigate(["home", "reimbursement", "approval", "signer", "list"]);
		this.toggleNavigation();
	}

	goToAssessment(): void
	{
		this._router.navigate(["home", "rcsa", "assessment", "list"]);
		this.toggleNavigation();
	}

	goToApprovalRCSA(): void
	{
		this._router.navigate(["home", "rcsa", "approval", "list"]);
		this.toggleNavigation();
	}

	goToReportRCSA(): void
	{
		this._router.navigate(["home", "rcsa", "report", "list"]);
		this.toggleNavigation();
	}

	goToRiskIssueRequest(): void
	{
		this._router.navigate(["home", "rcsa", "riskissuerequest", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationChecker(): void
	{
		this._router.navigate(["home", "digitalization", "checker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationMaker(): void
	{
		this._router.navigate(["home", "digitalization", "maker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationSigner(): void
	{
		this._router.navigate(["home", "digitalization", "signer", "list"]);
		this.toggleNavigation();
	}

	goToDashboardStamp(): void
	{
		this._router.navigate(["home", "dashboard", "stamp"]);
		this.toggleNavigation();
	}

	goToEmaterai(): void
	{
		this._router.navigate(["home", "ematerai", "list"]);
		this.toggleNavigation();
	}

	goToAnomaly(): void
	{
		this._router.navigate(["home", "protect", "anomaly", "list"]);
		this.toggleNavigation();
	}

	goToAnomalyNotification(): void
	{
		this._router.navigate(["home", "protect", "anomaly", "notification"]);
		this.toggleNavigation();
	}

	goToSalvageList(): void
	{
		this._router.navigate(["home", "salvage", "list"]);
		this.toggleNavigation();
	}

	goToSubmissionSalvageList(): void
	{
		this._router.navigate(["home", "ebidding", "submission", "salvage", "list"]);
		this.toggleNavigation();
	}

	goToEbiddingSubmissionList(): void
	{
		this._router.navigate(["home", "ebidding", "submission", "list"]);
		this.toggleNavigation();
	}

	goToMonitoringSalvageList(): void
	{
		this._router.navigate(["home", "ebidding", "monitoring", "list"]);
		this.toggleNavigation();
	}

	goToApprovalSalvageList(): void
	{
		this._router.navigate(["home", "ebidding", "approval", "list"]);
		this.toggleNavigation();
	}

	goToSalvageBuyerList(): void
	{
		this._router.navigate(["home", "ebidding", "buyer", "list"]);
		this.toggleNavigation();
	}

	goToSalvageInsert(): void
	{
		this._router.navigate(["home", "salvage", "insert"]);
		this.toggleNavigation();
	}

	goToRASFollowUpPlan(): void
	{
		this._router.navigate(["home", "ras", "followupplan", "list"]);
		this.toggleNavigation();
	}

	goToRASDashboard(): void
	{
		this._router.navigate(["home", "ras", "dashboard", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineProfile(): void
	{
		this._router.navigate(["home", "gcgonline", "profile"]);
		this.toggleNavigation();
	}

	goToGCGOnlineGratification(): void
	{
		this._router.navigate(["home", "gcgonline", "gratification", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineIntegrityPact(): void
	{
		this._router.navigate(["home", "gcgonline", "integritypact", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthics(): void
	{
		this._router.navigate(["home", "gcgonline", "codeofethics", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthicsApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToGCGOnlineAnnualDisclosure(): void
	{
		this._router.navigate(["home", "gcgonline", "annualdisclosure", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineAnnualDisclosureApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{

		}
	}

	goToSupplier(): void
	{
		this._router.navigate(["home", "partnershipworkshop", "supplier", "list"]);
		this.toggleNavigation();
	}

	goToPartnershipWorkshop(): void
	{
		this._router.navigate(["home", "partnershipworkshop", "workshop", "list"]);
		this.toggleNavigation();
	}

	goToVideoCallSurveyOnline(): void
	{
		this._elementHTMLAnchorLinkSurveyOnline = document.getElementById("anchorLinkSurveyOnline");
		this._elementHTMLAnchorLinkSurveyOnline.href = this._modelCloudMessagingSurveyOnline.data?.LINK_AGENT;
		this.closeSurveyOnlineModal();
	}

	goToAPUPPTCustomer(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_CUSTOMERDATA]);
		this.toggleNavigation();
	}

	goToRiskBasedAssessment(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_RISKBASEDASSESSMENT]);
		this.toggleNavigation();
	}

	goToTransaction(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_TRANSACTION, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToTaxRegulation(): void
	{
		this._router.navigate(["home", "brinstax", "taxregulation", "list"]);
		this.toggleNavigation();
	}

	goToTaxReport(): void
	{
		this._router.navigate(["home", "brinstax", "taxreport", "list"]);
		this.toggleNavigation();
	}

	goToTaxRequest(): void
	{
		this._router.navigate(["home", "brinstax", "taxrequest", "list"]);
		this.toggleNavigation();
	}
	// goToKeyPerformanceApproval(): void
	// {

	// }
	goToDashboardMv(): void
	{
		this._router.navigate(["home", "dashboard", "motor-vehicle"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceMonitoring(): void
	{
		this._router.navigate(["home", "brinsprime","branch", "monitoring", "list"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceApprovalCompany(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "approval"]);
		this.toggleNavigation();
	}

	goProjectManagementDashboard(): void
	{
		this._router.navigate(["home", "brinspro", "dashboard"]);
		this.toggleNavigation();
	}

	goToProjectManagementProject(): void
	{
		this._router.navigate(["home", "brinspro", "project", "list"]);
		this.toggleNavigation();
	}

	goToProjectManagementDocument(): void
	{
		this._router.navigate(["home", "brinspro", "document", "list"]);
		this.toggleNavigation();
	}

	goToEWSDashboard(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_EWS, StringConstant.STRING_PATH_ROUTING_DASHBOARD, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToEWSFollowUpPlan(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_EWS, StringConstant.STRING_PATH_ROUTING_FOLLOWUPPLAN, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToEWSFollowUpPlanMonitoring(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_EWS, StringConstant.STRING_PATH_ROUTING_FOLLOWUPPLAN, StringConstant.STRING_PATH_ROUTING_MONITORING, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToEWSReport(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_EWS, StringConstant.STRING_PATH_ROUTING_REPORT]);
	}
	goToRelationshipManager(): void
	{
		this._router.navigate(["home", "relationshipmanager", "list"]);
		this.toggleNavigation();
	}

	goToCustomerInformation(): void
	{
		this._router.navigate(["home", "customerinformation", "list"]);
		this.toggleNavigation();
	}

	goToProductInformation(): void
	{
		this._router.navigate(["home", "productinformation"]);
	}

	goToKeyPerformanceApproval(): void
	{
		this._router.navigate(["home", "brinsprime", "branch", "approval", "list"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceCorporateReporting(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "monitoring"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceCorporateSubmission(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "submission", "list"]);
		this.toggleNavigation();
	}

	goToKeyPerformanceCorporateSubmited(): void
	{
		this._router.navigate(["home", "brinsprime", "corporate", "submited", "list"]);
		this.toggleNavigation();
	}

	//#endregion


	//#region FUNCTION

	listenCloudMessagingSurveyOnline(): void
	{
		messaging.onMessage(( modelCloudMessagingSurveyOnline: CloudMessagingModel ) =>
		{
			this._elementHTMLDivNotificationSurveyOnline = document.getElementById("divNotificationOnlineSurvey") as HTMLElement;
			this._elementHTMLLabelNotificationSurveyOnline = document.getElementById("labelNotificationOnlineSurvey") as HTMLElement;
			this._elementHTMLParagraphNotificationSurveyOnline = document.getElementById("paragraphNotificationOnlineSurvey") as HTMLElement;

			this._modelCloudMessagingSurveyOnline = modelCloudMessagingSurveyOnline;
			this._booleanShowNotificationSurveyOnline = true;
			this._elementHTMLDivNotificationSurveyOnline.style.disable = "block";
			this._elementHTMLDivNotificationSurveyOnline.style.visibility = "visible";
			this._elementHTMLLabelNotificationSurveyOnline.textContent = `Halo Surveyor Agent ${ modelCloudMessagingSurveyOnline.data?.NUMBER_AGENT }`;

			if (modelCloudMessagingSurveyOnline.data?.SCHEDULED_TIME)
			{
				const dateDateSurveyOnline: Date = new Date(modelCloudMessagingSurveyOnline.data?.SCHEDULED_TIME).initiateWithoutUTC();
				const stringDateShortSurveyOnline = this._pipeConvertToDateShort.transform(dateDateSurveyOnline);
				const stringTimeSurveyOnline = this._pipeConvertToTime.transform(dateDateSurveyOnline);
				this._elementHTMLParagraphNotificationSurveyOnline.textContent = `Anda memiliki pelaporan klaim  yang perlu dilakukan survey Online secara Jadwal ${ stringDateShortSurveyOnline } Jam ${ stringTimeSurveyOnline } dengan no pelaporan ${ modelCloudMessagingSurveyOnline.data?.TICKET_NUMBER }.`;
			}
			else
			{
				this._elementHTMLParagraphNotificationSurveyOnline.textContent = `Anda memiliki pelaporan klaim yang perlu dilakukan survey Online secara LIVE dengan no pelaporan ${ modelCloudMessagingSurveyOnline.data?.TICKET_NUMBER }.`;
			}

			this._audioRingtoneSurveyOnline.play();
			this._audioRingtoneSurveyOnline.loop = true;
		});
	}

	closeSurveyOnlineModal(): void
	{
		this._booleanShowNotificationSurveyOnline = false;
		this._elementHTMLDivNotificationSurveyOnline = document.getElementById("divNotificationOnlineSurvey");
		this._elementHTMLDivNotificationSurveyOnline.style.disable = "none";
		this._elementHTMLDivNotificationSurveyOnline.style.visibility = "hidden";
		this._modelCloudMessagingSurveyOnline = new CloudMessagingModel();
		this._audioRingtoneSurveyOnline.pause();
		setTimeout(() =>
		{
			this._booleanShowNotificationSurveyOnline = true;
		}, 1000);
	}

	//#endregion
}

//#endregion

//#region IMPORT

import { ENUM_ANOMALY_ANALYSISMETHOD, ENUM_ANOMALY_APPROVALSTATUS, ENUM_ANOMALY_CATEGORY, ENUM_ANOMALY_QUARTER, ENUM_ANOMALY_TRACKING, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertToDateShort} from "../functions/pipes/converter.pipe";
import { AnomalyAuditorModel } from "./anomalyauditor.model";
import { AnomalyEvaluationModel } from "./anomalyevaluation.model";
import { AnomalyGeneralModel } from "./anomalygeneral.model";
import { AnomalyApprovalHistoryModel } from "./anomalyapprovalhistory.model";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class AnomalyBatchModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	Token?: string;
	BranchCode?: string;
	BranchName?: string;
	AnomalyAuditorTeam?: string;
	CategoryTotal?: number;
	DataTotal?: number;
	CompleteGenerated?: boolean;
	Year?: number;
	Quarter?: ENUM_ANOMALY_QUARTER;
	AnalysisMethod?: ENUM_ANOMALY_ANALYSISMETHOD;
	ApprovalStatus?: ENUM_ANOMALY_APPROVALSTATUS;
	Tracking?: ENUM_ANOMALY_TRACKING;

	AuditeeBranchCode?: string;
	AuditeeBranchName?: string;
	AuditeeDivisionCode?: string;
	AuditeeDivisionName?: string;

	MakerASQBy?: number;
	MakerASQNote?: string;
	MakerASQName?: string;
	MakerASQOn?: Date;
	CheckerASQBy?: number;
	CheckerASQNote?: string;
	CheckerASQName?: string;
	CheckerASQOn?: Date;
	SignerASQBy?: number;
	SignerASQNote?: string;
	SignerASQName?: string;
	SignerASQOn?: Date;

	MakerAuditorBy?: number;
	MakerAuditorNote?: string;
	MakerAuditorName?: string;
	MakerAuditorOn?: Date;
	CheckerAuditorBy?: number;
	CheckerAuditorNote?: string;
	CheckerAuditorName?: string;
	CheckerAuditorOn?: Date;
	SignerAuditorBy?: number;
	SignerAuditorNote?: string;
	SignerAuditorName?: string;
	SignerAuditorOn?: Date;

	MakerAuditeeBy?: number;
	MakerAuditeeNote?: string;
	MakerAuditeeName?: string;
	MakerAuditeeOn?: Date;
	CheckerAuditeeBy?: number;
	CheckerAuditeeNote?: string;
	CheckerAuditeeName?: string;
	CheckerAuditeeOn?: Date;
	SignerAuditeeBy?: number;
	SignerAuditeeNote?: string;
	SignerAuditeeName?: string;
	SignerAuditeeOn?: Date;

	Adequate?: number;
	UnderMonitoring?: number;
	NotYetFollowedUp?: number;
	AdequatePercent?: number;
	UnderMonitoringPercent?: number;
	NotYetFollowedUpPercent?: number;

	listModelAnomalyGeneral?: Array<AnomalyGeneralModel>;
	listModelAnomalyEvaluation?: Array<AnomalyEvaluationModel>;
	listModelAnomalyAuditor?: Array<AnomalyAuditorModel>;
	listModelAnomalyApprovalHistory?: Array<AnomalyApprovalHistoryModel>;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.listModelAnomalyEvaluation = [];
		this.listModelAnomalyGeneral = [];
		this.listModelAnomalyAuditor = [];
		this.listModelAnomalyApprovalHistory = [];
	}

	//#endregion

	//#region VALIDATION

	validateInsert(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.BranchCode) || this.validateEmpty(this.BranchName))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_WORKUNIT;
		}
		else if(this.validateEmpty(this.Quarter))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_QUARTER;
		}
		else if(this.validateEmpty(this.Year))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_YEAR;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if((this.validateArray(this.listModelAnomalyAuditor)) || (this.validateEmpty(this.AnomalyAuditorTeam)))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_AUDITORTEAM;
		}
		else if(this.listModelAnomalyAuditor && this.validateEmpty(this.listModelAnomalyAuditor[0].Name))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_AUDITORTEAMLEAD;
		}
		else if(this.listModelAnomalyAuditor && this.validateEmpty(this.listModelAnomalyAuditor[1].Name))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_AUDITORTEAMMEMBER;
		}
		// else if(
		// 	(this.listModelAnomalyAuditor && this.listModelAnomalyAuditor[1].UserID && this.listModelAnomalyAuditor[2].UserID && this.listModelAnomalyAuditor[3].UserID)
		// 	&& (this.listModelAnomalyAuditor[1].UserID === this.listModelAnomalyAuditor[2].UserID || this.listModelAnomalyAuditor[2].UserID === this.listModelAnomalyAuditor[3].UserID || this.listModelAnomalyAuditor[1].UserID === this.listModelAnomalyAuditor[3].UserID)
		// )
		// {
		// 	modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_AUDITORTEAMMEMBERNOTEQUAL;
		// }
		else if(this.validateEmpty(this.AnalysisMethod))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_ANALYSISMETHOD;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpdateForAuditor(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if((this.validateArray(this.listModelAnomalyEvaluation)) || (this.listModelAnomalyEvaluation && this.listModelAnomalyEvaluation[0] && this.validateEmpty(this.listModelAnomalyEvaluation[0].AuditorFeedback)))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_AUDITORFEEDBACK;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpdateForAuditee(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if(this.validateArray(this.listModelAnomalyEvaluation))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PROTECT_AUDITORFEEDBACK;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateApprovalForCheckerASQ(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if(this.validateEmpty(this.CheckerASQNote))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_DESCRIPTION;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateApprovalForSignerASQ(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if(this.validateEmpty(this.SignerASQNote))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_DESCRIPTION;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateApprovalForCheckerAuditor(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if(this.validateEmpty(this.CheckerAuditorNote))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_DESCRIPTION;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateApprovalForSignerAuditor(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if(this.validateEmpty(this.SignerAuditorNote))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_DESCRIPTION;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateApprovalForCheckerAuditee(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if(this.validateEmpty(this.CheckerAuditeeNote))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_DESCRIPTION;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateApprovalForSignerAuditee(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else if(this.validateEmpty(this.SignerAuditeeNote))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_DESCRIPTION;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region FUNCTION

	convertCategoryToString(enumAnomalyCategory: ENUM_ANOMALY_CATEGORY): string
	{
		if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.CustomerDoubleIndividual)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_CUSTOMERDOUBLEPOLICYINDIVIDUAL;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.CustomerDoubleCorporate)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_CUSTOMERDOUBLEPOLICYCORPORATE;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.CustomerNotValid)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_CUSTOMERNOTVALID;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.PolicyDoubleFire)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_POLICYDOUBLEFIRE;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.PolicyDoubleMarineCargo)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_POLICYDOUBLEMARINECARGO;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.PolicyDoubleMotorVehicle)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_POLICYDOUBLEMOTORVEHICLE;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.ClaimAfterPolicyOver)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_CLAIMAFTERPOLICYOVER;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.ClaimBeforePremiumPaid)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_CLAIMBEFOREPREMIUMPAID;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.CommissionBiggerThanPremium)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_COMMISSIONBIGGERTHANPREMIUM;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.ReinsuranceFacultativePayment)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_REINSURANCEFACULTATIVEPAYMENT;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.MemberDebt)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_MEMBERDEBT;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.ClaimTemporaryPost)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_CLAIMTEMPORARYPOST;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.PremiumTemporaryPost)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_PREMIUMTEMPORARYPOST;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.NonStandardCommissionRate)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_NONSTANDARDCOMMISSIONRATE;
		}
		else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.OutstandingHandling)
		{
			return StringConstant.STRING_ANOMALYBATCH_CATEGORY_OUTSTANDINGHANDLING;
		}
		// else if(enumAnomalyCategory === ENUM_ANOMALY_CATEGORY.PolicyWriteOff)
		// {
		// 	return StringConstant.STRING_ANOMALYBATCH_CATEGORY_POLICYWRITEOFF;
		// }
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion


	//#region GETTER

	getAnomalyApprovalStatus(): string
	{
		if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.ReviewASQ)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Assign)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_REVIEWASSIGN;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_REVIEWCOMPLETE;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.RejectASQ)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Assign)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_REJECTASSIGN;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_REJECTCOMPLETE;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.DraftASQ)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Assign)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_DRAFTASSIGN;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_DRAFTCOMPLETE;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.PendingCheckerASQ)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Assign)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKERASQ;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKERCOMPLETE;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.PendingSignerASQ)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Assign)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNERASQ;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNERCOMPLETE;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.ReviewAuditor)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Analysis)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_REVIEWANOMALY;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_REVIEWEVALUATION;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.RejectAuditor)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_REJECTANOMALY;
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.DraftAuditor)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Analysis)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_DRAFTAUDITOR;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_DRAFTEVALUATION;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.PendingCheckerAuditor)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Analysis)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKERAUDITOR;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKEREVALUATION;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.PendingSignerAuditor)
		{
			if(this.Tracking === ENUM_ANOMALY_TRACKING.Analysis)
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNERAUDITOR;
			}
			else
			{
				return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNEREVALUATION;
			}
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.ReviewAuditee)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_REVIEWFOLLOWUP;
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.RejectAuditee)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_REJECTFOLLOWUP;
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.DraftAuditee)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_DRAFTFOLLOWUP;
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.PendingCheckerAuditee)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKERFOLLOWUP;
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.PendingSignerAuditee)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNERFOLLOWUP;
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.Complete)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_COMPLETE;
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.CompleteCarryOver)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_COMPLETECARRYOVER;
		}
		else if(this.ApprovalStatus === ENUM_ANOMALY_APPROVALSTATUS.CompleteWithNotes)
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_COMPLETEWITHNOTES;
		}
		else
		{
			return StringConstant.STRING_APPROVALSTATUS_PROTECT_REVIEWASSIGN;
		}
	}

	getAnomalyTracking(): string
	{
		if(this.Tracking === ENUM_ANOMALY_TRACKING.Assign)
		{
			return StringConstant.STRING_TRACKING_PROTECT_ASSIGN;
		}
		else if(this.Tracking === ENUM_ANOMALY_TRACKING.Analysis)
		{
			return StringConstant.STRING_TRACKING_PROTECT_ANALYSIS;
		}
		else if(this.Tracking === ENUM_ANOMALY_TRACKING.FollowUpAnalysis)
		{
			return StringConstant.STRING_TRACKING_PROTECT_FOLLOWUPANALYSIS;
		}
		else if(this.Tracking === ENUM_ANOMALY_TRACKING.Evaluation)
		{
			return StringConstant.STRING_TRACKING_PROTECT_EVALUATION;
		}
		else if(this.Tracking === ENUM_ANOMALY_TRACKING.Complete)
		{
			return StringConstant.STRING_TRACKING_PROTECT_COMPLETE;
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	getAnomalyApprovalStatusAssign(): Array<AnomalyApprovalHistoryModel>
	{
		if(!this.validateArray(this.listModelAnomalyApprovalHistory))
		{
			return this.listModelAnomalyApprovalHistory?.filter(modelAnomalyApprovalHistoryFilter => (modelAnomalyApprovalHistoryFilter.Tracking === ENUM_ANOMALY_TRACKING.Assign || modelAnomalyApprovalHistoryFilter.Tracking === ENUM_ANOMALY_TRACKING.Complete)) || [];
		}
		else
		{
			return [];
		}
	}

	getAnomalyApprovalStatusAuditor(): Array<AnomalyApprovalHistoryModel>
	{
		if(!this.validateArray(this.listModelAnomalyApprovalHistory))
		{
			return this.listModelAnomalyApprovalHistory?.filter(modelAnomalyApprovalHistoryFilter => (modelAnomalyApprovalHistoryFilter.Tracking === ENUM_ANOMALY_TRACKING.Analysis || modelAnomalyApprovalHistoryFilter.Tracking === ENUM_ANOMALY_TRACKING.Evaluation)) || [];
		}
		else
		{
			return [];
		}
	}

	getAnomalyApprovalStatusAuditee(): Array<AnomalyApprovalHistoryModel>
	{
		if(!this.validateArray(this.listModelAnomalyApprovalHistory))
		{
			return this.listModelAnomalyApprovalHistory?.filter(modelAnomalyApprovalHistoryFilter => modelAnomalyApprovalHistoryFilter.Tracking === ENUM_ANOMALY_TRACKING.FollowUpAnalysis) || [];
		}
		else
		{
			return [];
		}
	}

	validateExportForSubmit(arrayModelAnomalyGeneral: Array<AnomalyGeneralModel>): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Export";
		modelResponse.State = ENUM_RESPONSE_STATE.Success;

		if(arrayModelAnomalyGeneral == null || arrayModelAnomalyGeneral === undefined || arrayModelAnomalyGeneral.length === 0)
		{
			modelResponse.MessageContent = "Export semua anomaly sebelum melanjutkan.";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			return modelResponse;
		}
		else if(this.listModelAnomalyGeneral)
		{
			this.listModelAnomalyGeneral.forEach(modelAnomalyGeneralLoop =>
			{
				const modelAnomalyGeneralTemp: AnomalyGeneralModel | undefined = arrayModelAnomalyGeneral?.find(modelAnomalyGeneralFind => modelAnomalyGeneralFind.Category === modelAnomalyGeneralLoop.Category);
				if (modelAnomalyGeneralTemp)
				{

				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					modelResponse.MessageContent = "Export anomaly "+ this.convertCategoryToString(modelAnomalyGeneralLoop.Category || 0) +".";
				}
			});

			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			return modelResponse;
		}
	}

	convertToDate(date: Date | undefined): any
	{
		if(date)
		{
			date = new Date(date);

			if(date.getFullYear() === 1900)
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
			else
			{
				const pipeDate: ConvertToDateShort = new ConvertToDateShort();
				return pipeDate.transform(date);
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{

	}

	cleanList(): void
	{
		this.listModelAnomalyGeneral = undefined;
		this.listModelAnomalyEvaluation = undefined;
		this.listModelAnomalyAuditor = undefined;
		this.listModelAnomalyApprovalHistory = undefined;
	}

	//#endregion
}

//#endregion
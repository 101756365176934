//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ClaimProposalTrackingGalleryModel } from "../models/claimproposaltrackinggallery.model";
import { ClaimProposalModel } from "../models/claimproposal.model";
import { ClaimProposalTrackingModel } from "../models/claimproposaltracking.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE
@Injectable
(
	{
		providedIn: "root"
	}
)

//#region CLASS

export class ClaimproposaltrackingService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectClaimProposalTrackingByClaimProposalToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALTRACKINGBYCLAIMPROPOSALTOKEN);
	}

	selectClaimProposalQualityControlByClaimProposalToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALQUALITYCONTROLBYCLAIMPROPOSALTOKEN);
	}

	selectClaimProposalTrackingPayment(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALTRACKINGPAYMENT);
	}

	insertClaimProposalTracking(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalTrackingModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_TRACKING_INSERTCLAIMPROPOSALTRACKING);
	}

	updateClaimProposalTrackingByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalTrackingModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_TRACKING_UPDATE_CLAIM_PROPOSAL_TRACKING_BYTOKEN);
	}

	uploadClaimProposalTracking(interfaceGeneralService: GeneralServiceInterface, modelClaimProposalTracking: ClaimProposalTrackingModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposalTracking), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_TRACKING_UPLOADPROPOSALTRACKING);
	}

	downloadClaimProposalTrackingByClaimProposalTokenForClaim(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_TRACKING_DOWNLOAD_CLAIM_PROPOSAL_TRACKING_BYTOKEN);
	}

	deleteGalleryByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposalTrackingGallery: ClaimProposalTrackingGalleryModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposalTrackingGallery), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_TRACKING_DELETEGALERRYBYTOKEN);
	}

}
//#endregion

//#region IMPORT

import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { AuthorizationSurveyNetModel } from "src/app/models/thirdparties/primasis/authorizationsurveynet.model";
import { ResponseModel } from "src/app/models/response.model";
import { Location } from "@angular/common";
import { AuthorizationSurveyNetResponseModel } from "src/app/models/thirdparties/primasis/authorizationsurveynetresponse.model";
import { UserSurveyNetModel } from "src/app/models/thirdparties/primasis/usersurveynet.model";
import { SessionService } from "src/app/services/session.service";
import { PrimasissurveynetService } from "src/app/services/thirdparties/primasis/primasissurveynet.service";
import { UserSessionModel } from "src/app/models/usersession.model";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-surveyonline",
		templateUrl: "./surveyonline.component.html",
		styleUrls: ["./surveyonline.component.sass"]
	}
)

//#endregion


//#region CLASS

export class SurveyonlineComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _elementHTMLIframeSurveyNetWebsite: HTMLElement | any;

	private _stringTokenAuth: string;
	private _modelUserSurveyNet: UserSurveyNetModel;

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router, private _servicePrimasissurveynet: PrimasissurveynetService)
	{
		super(routeActivated, location, serviceSession, router);

		this._stringTokenAuth = "";
		this._modelUserSurveyNet = new UserSurveyNetModel();
	}

	//#endregion


	//#region IMPLEMENTATION

	ngOnInit(): void
	{
		this.callSelectTokenAuthorization(this);
		this.setUserSurveyNetModel(this);
	}

	//#endregion


	//#region SETTER

	private setUserSurveyNetModel(componentCurrent: SurveyonlineComponent): void
	{
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		componentCurrent._modelUserSurveyNet.PhoneNumber = componentCurrent._modelUserSignIn.getName();
		componentCurrent._modelUserSurveyNet.Password = modelUserSession?.UserToken;
		componentCurrent._modelUserSurveyNet.UserName = componentCurrent._modelUserSignIn.getName();
		componentCurrent._modelUserSurveyNet.Email = componentCurrent._modelUserSignIn.getName();
	}


	//#endregion


	//#region WEB SERVICE

	callSelectTokenAuthorization(componentCurrent: SurveyonlineComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);
		const modelResponse = new ResponseModel();

		lastValueFrom(componentCurrent._servicePrimasissurveynet.selectTokenAuthorization())
			.then((modelAuthorizationSurveyNet: AuthorizationSurveyNetModel) =>
			{
				componentCurrent._stringTokenAuth = modelAuthorizationSurveyNet.data?.token ?? "";
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent.callSelectDomainSurveyNetAutoLogin(componentCurrent);
			})
			.catch(err =>
			{
				modelResponse.MessageTitle = err.statusText;
				modelResponse.MessageContent = err.error.error;
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectTokenAuthorization(componentCurrent); });
			});
	}

	callSelectDomainSurveyNetAutoLogin(componentCurrent: SurveyonlineComponent): void
	{
		if (componentCurrent._stringTokenAuth)
		{
			componentCurrent._functionUserInterface.setLoadingProgress(1);
			const modelResponse = new ResponseModel();

			lastValueFrom(componentCurrent._servicePrimasissurveynet.selectDomainSurveyNetAutoLogin(componentCurrent._modelUserSurveyNet, componentCurrent._stringTokenAuth))
				.then((modelAuthorizationSurveyNetResponse: AuthorizationSurveyNetResponseModel) =>
				{
					window.open(modelAuthorizationSurveyNetResponse.link_login);
					componentCurrent.goToBack();
					componentCurrent._functionUserInterface.updateLoadingProgress();

				})
				.catch(err =>
				{
					modelResponse.MessageTitle = err.statusText;
					modelResponse.MessageContent = err.error.message;
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectDomainSurveyNetAutoLogin(componentCurrent); });
				});
		}
		else
		{

		}
	}

	//#endregion

}

//#endregion
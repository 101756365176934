//#region  IMPORT

import * as xlsx from "xlsx";

//#endregion


//#region  CLASS

export class ExportFunction
{
	/*
		Attribute - START
		Description : Export Function.
		Author: Andryana Baros.
		Created on : Wednesday, 25 August 2021. 			Updated on : -.
		Created by : Andryana Baros							Updated by : -.
		Version : 1.2.
	*/

	toExcell(elementNative)
	{
		const workSheetExcell: xlsx.WorkSheet =
		xlsx.utils.table_to_sheet(elementNative.nativeElement);
		const workBookExcell: xlsx.WorkBook = xlsx.utils.book_new();
		xlsx.utils.book_append_sheet(workBookExcell, workSheetExcell, "Sheet1");
		xlsx.writeFile(workBookExcell, "export.xlsx");
	}

	/* Attribute - END */
}

//#endregion
//#region IMPORT

import { Router } from "@angular/router";
import { CryptographyFunction } from "src/app/functions/cryptography.function";
import { UserInterfaceFunction } from "src/app/functions/userinterface.function";
import { SessionService } from "src/app/services/session.service";
// import { StringConstant } from "src/app/constants/string.constant";
// import { environment } from "src/environments/environment";
// import { messaging } from "src/firebase.config";

//#endregion


//#region CLASS

export class BasePublicComponent
{
	//#region DECLARATION

	protected _functionUserInterface: UserInterfaceFunction;
	protected _functionCryptography: CryptographyFunction;
	// protected _stringClientWebsiteToken: string | null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceSession: SessionService, protected _router: Router)
	{
		this._functionUserInterface = new UserInterfaceFunction();
		this._functionCryptography = new CryptographyFunction();

		// this._stringClientWebsiteToken = localStorage.getItem(StringConstant.STRING_LOCALSTORAGE_KEY_CLIENTWEBSITETOKEN);
		// if (this._stringClientWebsiteToken)
		// {

		// }
		// else
		// {
		// 	messaging.getToken({vapidKey: environment.firebaseConfig.vapidKey})
		// 		.then(( stringCurrentToken: string ) =>
		// 		{
		// 			if ( stringCurrentToken )
		// 			{
		// 				this._stringClientWebsiteToken = stringCurrentToken;
		// 				localStorage.setItem(StringConstant.STRING_LOCALSTORAGE_KEY_CLIENTWEBSITETOKEN, stringCurrentToken);
		// 			}
		// 			else
		// 			{

		// 			}
		//   	})
		// 		.catch(() =>
		// 		{

		// 		});
		// }
	}

	//#endregion


	//#region FUNCTION

	protected goToSignInWithClearSession(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();

		this._router.navigate(["signin"]);
	}

	getTokenFromURLParamter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];
		return stringToken;
	}

	getCodeBranchFromURLParamter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringBranchCode: string = arraySplitURL[(arraySplitURL.length - 1)];
		return stringBranchCode;
	}

	//#endregion
}

//#endregion
//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class MemberDebtModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	ANO?: number;
	PolicyNumber?: string;
	CertificateNumber?: string;
	DirectPremium?: number;
	DirectPremiumPaid?: number;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	MemberPremium?: number;
	DirectPremiumPaidDate?: Date;
	MemberPremiumPaidDate?: Date;
	Overdue?: number;
	OutstandingDirect?: number;
	OutstandingMember?: number;
	CreateUser?: string;
	RelationshipManager?: string;
	Information?: string;
	AcceptanceDate?: Date;
	MemberPremiumPaid?: number;
	MemberName?: string;
	ProductCode?: string;
	ShareMemberPercentage?: number;
	DirectPremiumRecieved?: number;
	MemberRecieved?: number;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
	}

	cleanList(): void
	{
	}

	//#endregion
}

//#endregion


<div id="divBodyClaimProposal" class="DivBodyGeneral">
	<header class="HeaderGeneral">
		<h2>Salvage - Insert</h2>
		<p>Insert your salvage</p>
	</header>

	<div id="divMainClaimProposalDetailSubmitted" class="DivMainGeneral">
		<form>
			<div class="DivForm">
				<H3>Search</H3>
				<fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<label for="numberTicketNumber">Ticket number</label>
							<div class="DivInputHorizontalContainer">
								<label class="LabelPrefix" for="numberTicketNumber">BNCL-</label>
								<input type="number" id="numberTicketNumber" name="modelSalvageTicketNumber">
							</div>
							<label for="textClaimNumber">Claim number</label>
							<input type="text" id="textClaimNumber" name="modelSalvageClaimNumber">
						</div>
						<div class="DivForm2Column">
							<label for="textPolicyNumber">Policy number</label>
							<input type="text" id="textPolicyNumber" name="modelSalvagePolicyNumber">
						</div>
					</div>
				</fieldset>
			</div>

			<div class="DivFormButtonContainer">
				<input type="button" value="Cancel">
				<input type="button" value="Search" (click)="searchClaimInformation();">
			</div>
		</form>

		<ng-container *ngIf="_booleanSearch">
			<form>
				<div class="DivForm">
					<H3>Claim Information</H3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="spanPolicyholderName">Policyholder name</label>
								<span id="spanPolicyholderName">{{ _modelPolicyGeneral.PolicyholderFullName }}</span>

								<label for="spanPolicynumber">Policy number</label>
								<span id="spanPolicynumber">{{ _modelPolicyGeneral.PolicyNumber }}</span>

								<label for="spanProduct">Product</label>
								<span id="spanProduct">{{ _modelPolicyGeneral.ProductName }}</span>

								<label for="spanBranch">Branch</label>
								<span id="spanBranch">{{ _modelPolicyGeneral.BranchName }}</span>

								<label for="spanRelationshipManager">Relationship manager</label>
								<span id="spanRelationshipManager>">{{ _modelPolicyGeneral.RelationshipManagerFirstName }} {{ _modelPolicyGeneral.RelationshipManagerMiddleName }} {{ _modelPolicyGeneral.PolicyholderLastName }}</span>

								<label for="spanStartDate">Start date</label>
								<span id="spanStartDate">{{ _modelPolicyGeneral.PolicyStartDate }}</span>

								<label for="spanEndDate">End date</label>
								<span id="spanTotal">{{ _modelPolicyGeneral.PolicyEndDate }}</span>

								<label for="spanPolicyPaymentDate">Policy payment date</label>
								<span id="spanPolicyPaymentDate">{{ _modelPolicyGeneral.PolicyPaymentDate }}</span>
							</div>
							<div class="DivForm2Column">
								<label for="spanHistoryPolicy">History policy</label>
								<span id="spanHistoryPolicy">{{ _modelPolicyGeneral.Status }}</span>

								<label for="spanStatus">Status</label>
								<span id="spanStatus" class="SpanActiveStatus StatusNotActive">{{ _modelPolicyGeneral.Status }}</span>

								<label for="spanPolicyActiveStatus">Policy active status</label>
								<span id="spanPolicyActiveStatus" class="SpanActiveStatus StatusNotActive">{{ _modelPolicyGeneral.PolicyActiveStatus }}</span>

								<label for="spanPolicyPeriodStatus">Policy period status</label>
								<span id="spanPolicyPeriodStatus" class="SpanActiveStatus StatusActive">{{ _modelPolicyGeneral.PolicyPeriodeStatus }}</span>

								<label for="spanPolicyPaymentStatus">Policy payment status</label>
								<span id="spanPolicyPaymentStatus" class="SpanActiveStatus StatusActive">{{ _modelPolicyGeneral.PolicyPaymentStatus }}</span>

								<label for="spanPolicyGracePeriodStatus">Policy grace period status</label>
								<span id="spanPolicyGracePeriodStatus" class="SpanActiveStatus StatusNotActive">{{ _modelPolicyGeneral.PolicyGracePeriodeStatus }}</span>

								<label for="spanPremium">Premium</label>
								<span id="spanPremium">{{ _modelPolicyGeneral.PremiumAmount }}</span>

								<label for="spanClaimPaymentDate">Claim payment date</label>
								<span id="spanClaimPaymentDate">{{ _modelPolicyGeneral.PolicyPaymentDate }}</span>
							</div>
						</div>
					</fieldset>
				</div>
			</form>

			<form>
				<div class="DivForm">
					<H3>Form submission</H3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="numberTicketNumber">Salvage location</label>
								<input type="number" id="numberTicketNumber" name="modelSalvageTicketNumber">
								<label for="radioButtonBiddingTypeMotorVehicle">Bidding type</label>
								<div class="DivRadioButtonContainer">
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonBiddingTypeMotorVehicle" name="modelSalvageBiddingType" [(ngModel)]="this._modelSalvage.BiddingType" [value]="this._enumBiddingType.MotorVehicle" (change)="setArrayModelSalvageGallery();">
										<label for="radioButtonBiddingTypeMotorVehicle"></label>
									</div>
									<label for="radioButtonBiddingTypeMotorVehicle">MV</label>
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonBiddingTypeNonMotorVehicle" name="modelSalvageBiddingType" [(ngModel)]="this._modelSalvage.BiddingType" [value]="this._enumBiddingType.NonMotorVehicle" (change)="setArrayModelSalvageGallery();">
										<label for="radioButtonBiddingTypeNonMotorVehicle"></label>
									</div>
									<label for="radioButtonBiddingTypeNonMotorVehicle">Non MV</label>
								</div>
								<label for="radioButtonClaimPaidStatusPaid">Claim paid status</label>
								<div class="DivRadioButtonContainer">
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonClaimPaidStatusPaid" name="modelSalvageClaimPaidStatus">
										<label for="radioButtonClaimPaidStatusPaid"></label>
									</div>
									<label for="radioButtonClaimPaidStatusPaid">Paid</label>
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonClaimPaidStatusUnpaid" name="modelSalvageClaimPaidStatus">
										<label for="radioButtonClaimPaidStatusUnPaid"></label>
									</div>
									<label for="radioButtonClaimPaidStatusUnpaid">Unpaid</label>
								</div>
							</div>

							<div class="DivForm2Column">
								<label for="datePickerDateOfLoss">Claim paid date</label>
								<mat-form-field appearance="fill">
									<mat-label>Date</mat-label>
									<input matInput [matDatepicker]="datePickerDateOfLoss" [value]="_modelSalvage.ClaimPaidDate" (dateChange)="getClaimPaidDate($event)">
									<mat-datepicker-toggle matSuffix [for]="datePickerDateOfLoss"></mat-datepicker-toggle>
									<mat-datepicker #datePickerDateOfLoss startView="month" panelClass="month-picker">
									</mat-datepicker>
								</mat-form-field>

								<label for="textAdditionalCost">Additional cost</label>
								<div class="DivInputHorizontalContainer">
									<label class="LabelPrefix" for="textAdditionalCost">IDR</label>
									<input type="text" id="textAdditionalCost" name="modelSalvageAdditionalCost">
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			</form>

			<div class="DivTableContainer">
				<h3>Table supporting document</h3>
				<table>
					<tbody>
						<tr *ngFor="let modelSalvageGallery of this._modelSalvage.arrayModelSalvageGallery; index as numberIndex">
							<td>{{ _enumPhotoType[numberIndex + 142] }}</td>
							<td>Upload file maksimal 10 MB</td>
							<td class="DivButton"><input type="button" class="ButtonAttachment"></td>
						</tr>

						<tr *ngFor="let modelSalvageGallery of this._arrayModelSalvageGallery; index as numberIndex">
							<td>{{ _enumPhotoType[150] }}</td>
							<td>Upload file maksimal 10 MB</td>
							<td class="DivButton"><input type="button" class="ButtonAttachment"></td>
						</tr>

						<tr *ngIf="_modelSalvage.BiddingType !== null && _modelSalvage.BiddingType !== undefined">
							<td><input type="button" class="ButtonAdd" (click)="addModelSalvageGallery();">Add more document</td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>

			<form>
				<div class="DivForm">
					<H3>Item</H3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="textItemName">Item name</label>
								<input type="text" id="textItemName" [(ngModel)]="this._modelSalvageItem.Name" name="modelSalvageItemName">
							</div>
							<div class="DivForm2Column">
								<label for="textEstimatedSalvageValue">Estimated salvage value</label>
								<div class="DivInputHorizontalContainer">
									<label class="LabelPrefix" for="textEstimatedSalvageValue">IDR</label>
									<input type="text" id="textEstimatedSalvageValue" [(ngModel)]="this._modelSalvageItem.EstimatedSalvageValue" name="modelSalvageItemEstimatedSalvageValueazzazzazazazazaazazaaaaa">
								</div>
							</div>
						</div>
					</fieldset>
				</div>

				<div class="DivFormButtonContainer">
					<input type="button" value="Add" (click)="addModelSalvageItem();">
				</div>
			</form>

			<div class="DivTableContainer">
				<h3>Table item</h3>
				<table>
					<thead>
						<tr>
							<th>No</th>
							<th>Item</th>
							<th>Estimated salvage value</th>
							<th>Action</th>
						</tr>
					</thead>

					<tbody>
						<tr *ngFor="let modelSalvageItem of this._modelSalvage.arrayModelSalvageItem; index as numberIndex">
							<td>{{ numberIndex + 1 }}</td>
							<td>{{ modelSalvageItem.Name }}</td>
							<td>{{ modelSalvageItem.EstimatedSalvageValue }}</td>
							<td><input type="button" class="ButtonRemove" (click)="removeModelSalvageItem(numberIndex);"></td>
						</tr>
					</tbody>

					<tfoot>
						<tr>
							<td></td>
							<td>Total</td>
							<td>{{ this._modelSalvage.getArrayModelSalvageItemEstimatedSalvageValue() }}</td>
							<td></td>
						</tr>
					</tfoot>
				</table>
			</div>

			<form >
				<div class="DivFormButtonContainer">
					<input type="button" value="Submit" (click)="searchClaimInformation();">
				</div>
			</form>
		</ng-container>
	</div>
</div>
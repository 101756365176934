//#region IMPORT

import { BaseModel } from "./bases/base.model";
import { ClaimProposalModel } from "./claimproposal.model";
import { ClaimProposalQualityControlGalleryModel } from "./claimproposalqualitycontrolgallery.model";
import { UserModel } from "./user.model";



//#endregion

//#region CLASS

export class ClaimProposalQualityControlModel extends BaseModel
{
	ID?: number;
	Token?: string;
	ClaimProposalID?: number;
	TicketNumber?: string;
	Action?: string;
	ControlDate?: Date;
	Note?: string;
	ClaimProposalToken?: string;

	modelClaimProposal?: ClaimProposalModel;
	modelUserSurveyor?: UserModel;

	listModelClaimProposalQualityControlGallery?: Array<ClaimProposalQualityControlGalleryModel>;

	constructor()
	{
		super();
		this.modelUserSurveyor = new UserModel();
		this.modelClaimProposal = new ClaimProposalModel();
	}


}

//#endregion
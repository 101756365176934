//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TableModel } from "src/app/models/bases/table.model";
import { SalvageModel } from "src/app/models/salvage.model";
import { SalvageGalleryModel } from "src/app/models/salvagegallery.model";
import { SessionService } from "src/app/services/session.service";
import { ENUM_BIDDING_TYPE, ENUM_CLAIMPAID_STATUS, ENUM_PHOTO_TYPE, ENUM_SPPA_STATUS } from "src/app/constants/enum.constant";
import { PolicyGeneralModel } from "src/app/models/policygeneral.model";
import { SalvageItemModel } from "src/app/models/salvageitem.model";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";
//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-salvage.insert",
		templateUrl: "./salvage.insert.component.html",
		styleUrls: ["./salvage.insert.component.sass"]
	}
)

//#endregion


//#region CLASS

export class SalvageInsertComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelSalvage: SalvageModel;
	public _modelSalvageItem: SalvageItemModel;
	public _modelTable: TableModel;
	public _modelPolicyGeneral: PolicyGeneralModel;

	public _arrayModelSalvageGallery: Array<SalvageGalleryModel>;

	public _enumPhotoType = ENUM_PHOTO_TYPE;
	public _enumBiddingType = ENUM_BIDDING_TYPE;
	public _enumClaimPaidStatus = ENUM_CLAIMPAID_STATUS;

	public _booleanSearch: boolean = false;

	//#endregion


	//#region  CONSTRUCTOR

	constructor(serviceSession: SessionService, public router: Router)
	{
		super(serviceSession, router);

		this._arrayModelSalvageGallery = [];

		this._modelTable = new TableModel();
		this._modelSalvageItem = new SalvageItemModel();
		this._modelPolicyGeneral = new PolicyGeneralModel();
		this._modelPolicyGeneral.PolicyholderFullName = "Alvin";
		this._modelPolicyGeneral.PolicyNumber = "1234567890";
		this._modelPolicyGeneral.ProductName = "Oto";
		this._modelPolicyGeneral.BranchName = "Jakarta 1";
		this._modelPolicyGeneral.RelationshipManagerFirstName = "Febriyanto";
		this._modelPolicyGeneral.RelationshipManagerMiddleName = "Rahman";
		this._modelPolicyGeneral.RelationshipManagerLastName = "";
		this._modelPolicyGeneral.PolicyStartDate = new Date();
		this._modelPolicyGeneral.PolicyEndDate = new Date();
		this._modelPolicyGeneral.PolicyPaymentDate = new Date();
		this._modelPolicyGeneral.Status = ENUM_SPPA_STATUS.Approved;
		this._modelPolicyGeneral.PolicyActiveStatus = true;
		this._modelPolicyGeneral.PolicyPeriodeStatus = true;
		this._modelPolicyGeneral.PolicyPaymentStatus = true;
		this._modelPolicyGeneral.PolicyGracePeriodeStatus = false;
		this._modelPolicyGeneral.PremiumAmount = 500000;
		this._modelPolicyGeneral.PolicyPaymentDate = new Date();

		this._modelSalvage = new SalvageModel();
		this._modelSalvage.ClaimPaidStatus = ENUM_CLAIMPAID_STATUS.Paid;
		this._modelSalvage.SalvageLocation = "Jakarta utara";
		this._modelSalvage.BiddingType = ENUM_BIDDING_TYPE.MotorVehicle;
		this._modelSalvage.ClaimPaidDate = new Date();
		this._modelSalvage.AdditionalCost = 0;

		this._modelSalvage.arrayModelSalvageItem = [];
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{

	}

	//#endregion


	//#REGION FUNCTION

	setArrayModelSalvageGallery(): void
	{
		this._arrayModelSalvageGallery = [];
		this._modelSalvage.arrayModelSalvageGallery = [];

		let modelSalvageGallery: SalvageGalleryModel = new SalvageGalleryModel();
		modelSalvageGallery.Type = ENUM_PHOTO_TYPE.SalvageReport;
		modelSalvageGallery.Mandatory = true;
		modelSalvageGallery.Name = "Salvage report.pdf";
		this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

		modelSalvageGallery = new SalvageGalleryModel();
		modelSalvageGallery.Type = ENUM_PHOTO_TYPE.SalvageHandoverLetter;
		modelSalvageGallery.Mandatory = true;
		modelSalvageGallery.Name = "Salvage handover letter.pdf";
		this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

		if (this._modelSalvage.BiddingType === ENUM_BIDDING_TYPE.MotorVehicle)
		{
			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.BPKB;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "BPKB.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.VehicleKey;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "Vehicle key.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.ReleaseRightLetter;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "Release right letter.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.KwitansiTripleStamp;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "Kwitansi tripple stamp.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.VehicleReceipt;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "Vehicle receipt.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);
		}
		else
		{

		}

		if (this._modelSalvage.ClaimPaidDate == null)
		{
			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.FormSalvageBidding;
			modelSalvageGallery.Mandatory = false;
			modelSalvageGallery.Name = "Form salvage bidding.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);
		}
		else
		{

		}

		modelSalvageGallery = new SalvageGalleryModel();
		modelSalvageGallery.Type = ENUM_PHOTO_TYPE.SalvagePhoto;
		modelSalvageGallery.Mandatory = false;
		modelSalvageGallery.Name = "Salvage photo";
		this._arrayModelSalvageGallery.push(modelSalvageGallery);
	}

	addModelSalvageGallery(): void
	{
		const modelSalvageGallery: SalvageGalleryModel = new SalvageGalleryModel();
		modelSalvageGallery.Type = ENUM_PHOTO_TYPE.SalvagePhoto;
		modelSalvageGallery.Mandatory = false;
		this._arrayModelSalvageGallery.push(modelSalvageGallery);
	}

	addModelSalvageItem(): void
	{
		const modelSalvageItem: SalvageItemModel = new SalvageItemModel();
		modelSalvageItem.Name = this._modelSalvageItem.Name;
		modelSalvageItem.EstimatedSalvageValue = this._modelSalvageItem.EstimatedSalvageValue;
		this._modelSalvage.arrayModelSalvageItem?.push(modelSalvageItem);
		this._modelSalvageItem = new SalvageItemModel();
	}

	searchClaimInformation(): void
	{
		this._booleanSearch = true;
	}

	removeModelSalvageItem(numberIndex: number): void
	{
		this._modelSalvage.arrayModelSalvageItem?.splice(numberIndex, 1);
	}

	//#endregion


	//#region SETTER

	getClaimPaidDate(event: any): void
	{
		this.setClaimPaidDate(event.value);
		this.setArrayModelSalvageGallery();
	}

	private setClaimPaidDate(dateStart?: Date): void
	{
		if (dateStart !== undefined)
		{
			this._modelSalvage.ClaimPaidDate = dateStart.clearUTC();
		}
		else
		{

		}
	}

	//#endregion
}
//#region IMPORT

import { ENUM_CLAIMITEM_ACTIONSTATUS, ENUM_CLAIMITEM_TYPE, ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE, ENUM_SURVEY_STATUS } from "../constants/enum.constant";
import { NumberConstant } from "../constants/number.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { PartnershipWorkshopModel } from "./partnershipworkshop.model";
import { ResponseModel } from "./response.model";
import { SurveyThirdPartyLiabilityGalleryModel } from "./surveythirdpartyliabilitygallery.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class SurveyThirdPartyLiabilityModel extends BaseModel
{

	//#region ATTRIBUTES

	ID?: number;
	Token?: string;
	Status?: ENUM_SURVEY_STATUS;

	ContactPersonAddress?: string;
	ContactPersonEmail?: string;
	ContactPersonName?: string;
	ContactPersonMobile?: string;
	ContactPersonMobilePhone?: string;

	InsuredSurveyLocation?: string;
	LegalResponsbilityLiability?: ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE;

	ClaimProposalID?: number;
	SurveyID?: number;

	VehicleBrandID?: number;
	VehicleBrandCode?: number;
	VehicleBrandName?: string;

	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: string;

	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;

	WorkshopID?: string;
	WorkshopName?: string;
	WorkshopAddress?: string;
	WorkshopEmail?: string;
	WorkshopMobilePhone?: string;
	WorkshopNote?: string;
	WorkshopVisitOn?: Date;

	OwnRetention?: string;
	OwnRetentionAmount?: string;

	Note?: string;
	NotedOn?: Date;
	NotedBy?: number;
	modelUserNoted?: UserModel;

	ApprovedNote?: string;
	ApprovedOn?: Date;
	ApprovedBy?: number;
	modelUserApproved?: UserModel;

	RejectedNote?: string;
	RejectedOn?: Date;
	RejectedBy?: number;
	modelUserRejected?: UserModel;

	ResurveyNote?: string;
	ResurveyedOn?: Date;
	ResurveyedBy?: number;
	modelUserResurveyed?: UserModel;

	VerifyNote?: string;
	VerifiedOn?: Date;
	VerifiedBy?: number;
	modelUserVerified?: UserModel;

	ClaimLetterConfirmation?: boolean;
	ClaimLetterToken?: string;
	ClaimLetterTokenExpired?: Date;
	ClaimLetterTokenFailure?: number;
	ClaimLetterTokenResendOn?: Date;
	ClaimLetterTokenSubmitOn?: Date;
	ChronologyConfirmation?: boolean;
	ChronologyToken?: string;
	ChronologyTokenExpired?: Date;
	ChronologyTokenFailure?: number;
	ChronologyTokenResendOn?: Date;
	ChronologyTokenSubmitOn?: Date;

	ServiceAmount?: number;
	SalvageAmount?: number;
	TowingAmount?: number;
	VehicleReplacementAmount?: number;
	PersonalAccidentDriverAmount?: number;
	PersonalAccidentPassengerAmount?: number;
	TaxAmount?: number;
	TaxPercentage?: number;
	PartAmount?: number;
	TPLAmount?: number;

	LicensePlatePrefixID?: string;
	LicensePlatePrefixName?: string;
	LicensePlatePrefixCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;
	DamageDescription?: string;
	VehicleEngineNumber?: string;
	VehicleChasisNumber?: string;

	AdditionalCost?: number;

	modelPartnershipWorkshop?: PartnershipWorkshopModel;
	listModelSurveyThirdPartyLiabilityGallery?: Array<SurveyThirdPartyLiabilityGalleryModel>;

	//#endregion ATTRIBUTES


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.listModelSurveyThirdPartyLiabilityGallery = [];
		this.modelPartnershipWorkshop = new PartnershipWorkshopModel();
	}

	//#endregion CONSTRUCTOR


	//#region FUNCTION

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateVerifySurvey(modelSurveyThirdPartyLiability: SurveyThirdPartyLiabilityModel): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		let booleanActionType: boolean = false;
		let numberItems: number = 1;

		if(modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery)
		{
			for(const modelSurveyThirdPartyLiabilityGallery of modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery)
			{
				if(modelSurveyThirdPartyLiabilityGallery.Type && (modelSurveyThirdPartyLiabilityGallery.Type >= ENUM_PHOTO_TYPE.FrontBumperLeft && modelSurveyThirdPartyLiabilityGallery.Type <= ENUM_PHOTO_TYPE.VelFrontRight || modelSurveyThirdPartyLiabilityGallery.Type >= ENUM_PHOTO_TYPE.EngineHood ) || modelSurveyThirdPartyLiabilityGallery.Type === ENUM_PHOTO_TYPE.Other)
				{
					if(modelSurveyThirdPartyLiabilityGallery.ActionType !== null && modelSurveyThirdPartyLiabilityGallery.ActionType !== undefined)
					{
						booleanActionType = true;
					}
					else
					{
						booleanActionType = false;
						break;
					}
					numberItems++;
				}
			}
		}
		else
		{
		}

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(booleanActionType === false)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Photos number " +numberItems +" must be approved or rejected";
		}
		else if(this.VerifyNote == null || this.VerifyNote === undefined || this.VerifyNote === "")
		{
			modelResponse.MessageContent = "Note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateApproveSurvey(modelSurveyThirdPartyLiability: SurveyThirdPartyLiabilityModel): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		let booleanActionType: boolean = false;
		let numberItems: number = 1;

		if(modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery)
		{
			for(const modelSurveyThirdPartyLiabilityGallery of modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery)
			{
				if(modelSurveyThirdPartyLiabilityGallery.Type && (modelSurveyThirdPartyLiabilityGallery.Type >= ENUM_PHOTO_TYPE.FrontBumperLeft && modelSurveyThirdPartyLiabilityGallery.Type <= ENUM_PHOTO_TYPE.VelFrontRight || modelSurveyThirdPartyLiabilityGallery.Type >= ENUM_PHOTO_TYPE.EngineHood ) || modelSurveyThirdPartyLiabilityGallery.Type === ENUM_PHOTO_TYPE.Other)
				{
					if(modelSurveyThirdPartyLiabilityGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Supervisor)
					{
						booleanActionType = true;
					}
					else
					{
						booleanActionType = false;
						break;
					}
					numberItems++;
				}
			}
		}
		else
		{
		}



		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(booleanActionType === false)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Photos number " +numberItems +" must be approved or rejected";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateResurvey(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(this.ResurveyNote == null || this.ResurveyNote === undefined || this.ResurveyNote === "")
		{
			modelResponse.MessageContent = "Note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateRejectSurvey(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(this.RejectedNote == null || this.RejectedNote === undefined || this.RejectedNote === "")
		{
			modelResponse.MessageContent = "Note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	getTPLAmount(numberTaxPercent: number): void
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const enumClaimItemType = ENUM_CLAIMITEM_TYPE;
		this.PartAmount = NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		this.ServiceAmount = NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		this.SalvageAmount = NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		this.TaxPercent = numberTaxPercent;

		this.listModelSurveyThirdPartyLiabilityGallery?.forEach(modelSurveyThirdPartyLiabilityGallery =>
		{
			if(modelSurveyThirdPartyLiabilityGallery.Type !== undefined && modelSurveyThirdPartyLiabilityGallery.CostEstimationAmount !== undefined && this.ServiceAmount !== undefined && this.PartAmount !== undefined)
			{
				if(modelSurveyThirdPartyLiabilityGallery.Type >= enumPhotoType.FrontBumperLeft && modelSurveyThirdPartyLiabilityGallery.Type <= enumPhotoType.VelFrontRight || modelSurveyThirdPartyLiabilityGallery.Type >= enumPhotoType.EngineHood || modelSurveyThirdPartyLiabilityGallery.Type === enumPhotoType.Other)
				{
					if(modelSurveyThirdPartyLiabilityGallery.ClaimItemType === enumClaimItemType.Repair && modelSurveyThirdPartyLiabilityGallery.CostEstimationAmount !== undefined)
					{
						this.ServiceAmount = this.ServiceAmount + modelSurveyThirdPartyLiabilityGallery.CostEstimationAmount;
					}
					else if(modelSurveyThirdPartyLiabilityGallery.ClaimItemType === enumClaimItemType.Replace && modelSurveyThirdPartyLiabilityGallery.CostEstimationAmount !== undefined)
					{
						this.PartAmount = this.PartAmount + modelSurveyThirdPartyLiabilityGallery.CostEstimationAmount;
					}
					else
					{
						this.PartAmount = this.PartAmount + modelSurveyThirdPartyLiabilityGallery.CostEstimationAmount;

						if(modelSurveyThirdPartyLiabilityGallery.SalvageEstimationAmount && this.SalvageAmount != null)
						{
							this.SalvageAmount = this.SalvageAmount + modelSurveyThirdPartyLiabilityGallery.SalvageEstimationAmount;
						}
					}
				}
			}
		});

		this.TaxAmount = (this.ServiceAmount + this.PartAmount) * numberTaxPercent;
		this.TPLAmount = (this.ServiceAmount + this.PartAmount + this.TaxAmount) - this.SalvageAmount;
	}

	setNumberToPercentage(numberTaxPercent: number): number
	{
		if(numberTaxPercent == null || numberTaxPercent === undefined)
		{
			return NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		}
		else
		{
			return numberTaxPercent * NumberConstant.NUMBER_VALUE_GENERAL_ONE_HUNDRED;
		}
	}


	//#endregion FUNCTION

}

//#endregion
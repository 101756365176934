//#region IMPORT

import { environment as environmentGeneral } from "./environment.general";

//#endregion


//#region ENVIRONMENT

export const environment =
{
	...environmentGeneral,


	//#region CONFIGURATION

	production: true,
	enableDebug: false,
	serviceWorker: false,

	//#endregion


	//#region BUILD

	BUILD: "Staging",

	//#endregion


	//#region API

	URL_USER_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_USER_API_STAGING + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_STAGING_PUBLIC,
	URL_PRODUCT_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_PRODUCT_API_STAGING + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_STAGING_PUBLIC,
	URL_POLICY_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_POLICY_API_STAGING + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_STAGING_PUBLIC,
	URL_BROADCAST_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_BRODCAST_API_STAGING + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_STAGING_PUBLIC,
	URL_CLAIM_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_CLAIM_API_STAGING + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_STAGING_PUBLIC,
	URL_INTERNAL_WEB: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_INTERNAL_WEB_STAGING + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_STAGING_PUBLIC,

	//#endregion


	//#region FIREBASE CONFIGURATION

	firebaseConfig:
	{
		apiKey: environmentGeneral.CONFIG_FIREBASE_ACCESSAPI,
		authDomain: environmentGeneral.CONFIG_FIREBASE_AUTHDOMAIN,
		projectId: environmentGeneral.CONFIG_FIREBASE_PROJECTID,
		storageBucket: environmentGeneral.CONFIG_FIREBASE_STORAGEBUCKET,
		messagingSenderId: environmentGeneral.CONFIG_FIREBASE_MESSAGINGSENDERID,
		appId: environmentGeneral.CONFIG_FIREBASE_APPID,
		measurementId: environmentGeneral.CONFIG_FIREBASE_MEASUREMENTID,
		vapidKey: environmentGeneral.CONFIG_FIREBASE_ACCESSVAPID,
	},

	//#endregion
};

//#endregion
//#region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TableModel } from "src/app/models/bases/table.model";
import { ClaimProposalModel } from "src/app/models/claimproposal.model";
import { ClaimProposalService } from "src/app/services/claimproposal.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { TableControlIncludeComponent } from "src/app/components/includes/tablecontrol.include/tablecontrol.include.component";
import { ENUM_CLAIMPROPOSAL_STATUS, ENUM_CLAIM_CHANNEL } from "src/app/constants/enum.constant";
// import * as XLSX from "xlsx";
import { StringConstant } from "src/app/constants/string.constant";
import { LicensePlatePrefixModel } from "src/app/models/licenseplateprefix.model";
import { ExportFunction } from "src/app/functions/export.function";
import { ClaimProposalReportModel } from "src/app/models/dashboardreportclaimproposal.model";
import { DashboardClaimProposalModel } from "src/app/models/dashboardclaimproposal.model";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-salvage.list",
		templateUrl: "./salvage.list.component.html",
		styleUrls: ["./salvage.list.component.sass"]
	}
)

//#endregion

export class SalvageListComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;
	public _modelClaimProposal: ClaimProposalModel;
	public _modelTable: TableModel = new TableModel();
	public _modelClaimProposalReport: ClaimProposalReportModel;
	public _modelDashboardClaimProposal: DashboardClaimProposalModel;

	public _arrayModelClaimProposal: Array<ClaimProposalModel>;
	public _arrayModelLicensePlatePrefix: Array<LicensePlatePrefixModel>;

	public _enumClaimProposalStatus = ENUM_CLAIMPROPOSAL_STATUS;
	public _enumClaimProposalChannel = ENUM_CLAIM_CHANNEL;

	public _stringConstant = StringConstant;

	public _exportToExcell: ExportFunction;
	public _matrixExportExcel: any = [];

	//#endregion


	//#region  CONSTRUCTOR

	constructor(private _serviceClaimProposal: ClaimProposalService, serviceSession: SessionService, public router: Router)
	{
		super(serviceSession, router);
		this._modelClaimProposal = new ClaimProposalModel();
		this._modelClaimProposalReport = new ClaimProposalReportModel();
		this._modelDashboardClaimProposal = new DashboardClaimProposalModel();
		this._arrayModelClaimProposal = [];
		this._arrayModelLicensePlatePrefix = [];
		this._exportToExcell = new ExportFunction();
	}

	//#endregion


	//#region NAVIGATION

	goToDetail(stringToken?: string | null): void
	{
		if (stringToken == null || stringToken === undefined || stringToken === "")
		{
			this._router.navigate(["home", "salvage", "list"]);
		}
		else
		{
			this._router.navigate(["home", "salvage", "detail", stringToken]);
		}
	}

	//#endregion


	//#region SETTER

	getStartDateLoss(event: any): void
	{
		this.setStartDateOfLoss(event.value);
	}

	private setStartDateOfLoss(dateStart?: Date): void
	{
		if (dateStart !== undefined)
		{
			this._modelClaimProposal.IncidentDate = dateStart.clearUTC();
		}
		else
		{

		}
	}

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;

		if (this._modelUserSignIn.BranchCode === this._stringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE)
		{
			this.callSelectSalvage(this);
		}
		else
		{

		}
	}

	//#endregion


	//#region WEBSERVICE

	public callSelectSalvage(componentCurrent: this): void
	{
		/* componentCurrent._functionUserInterface.setLoadingProgress(1);
		componentCurrent._modelTable.Search = JSON.stringify(componentCurrent._modelClaimProposal);
		componentCurrent._arrayModelClaimProposal = [];

		const modelResponseValidation = componentCurrent._modelClaimProposal.validateSearchClaimProposalAttributes();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceClaimProposal.selectClaimProposalForMonitoring
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTable.setModelFromString(modelResponse.Data);

							if (componentCurrent._modelTable.Result !== undefined)
							{
								let arrayModelClaimProposal: Array<ClaimProposalModel> = JSON.parse(componentCurrent._modelTable.Result);

								arrayModelClaimProposal.forEach(modelClaimProposalLoop =>
								{
									const stringModelClaimProposal: string = JSON.stringify(modelClaimProposalLoop);
									const modelClaimProposalTemp: ClaimProposalModel = new ClaimProposalModel();
									modelClaimProposalTemp.setModelFromString(stringModelClaimProposal);

									componentCurrent._arrayModelClaimProposal.push(modelClaimProposalTemp);
								});

								arrayModelClaimProposal = [];

								componentCurrent._modelTable.Result = undefined;
								componentCurrent._componentTableControlInclude.setButtonState();
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectSalvage(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, componentCurrent._modelTable);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		} */

		let modelClaimProposalTemporary: ClaimProposalModel = new ClaimProposalModel();
		modelClaimProposalTemporary.TicketNumber = "BNCL-1234";
		modelClaimProposalTemporary.ReporterFirstName = "Valerie";
		modelClaimProposalTemporary.ReporterMiddleName = "Anne";
		modelClaimProposalTemporary.ReporterLastName = "Poxleitner";
		modelClaimProposalTemporary.ProductName = "Oto";
		modelClaimProposalTemporary.IncidentDate = new Date();
		modelClaimProposalTemporary.Token = "abcd";

		componentCurrent._arrayModelClaimProposal.push(modelClaimProposalTemporary);
		modelClaimProposalTemporary = new ClaimProposalModel();
		modelClaimProposalTemporary.TicketNumber = "BNCL-2345";
		modelClaimProposalTemporary.ReporterFirstName = "Florence";
		modelClaimProposalTemporary.ReporterLastName = "Welch";
		modelClaimProposalTemporary.ProductName = "Oto";
		modelClaimProposalTemporary.IncidentDate = new Date();
		modelClaimProposalTemporary.Token = "bcde";
		componentCurrent._arrayModelClaimProposal.push(modelClaimProposalTemporary);
	}

	//#endregion
}

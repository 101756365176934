/* eslint-disable no-shadow */
//#region ENUM

//#region STATE

/*
	ENUM_REGISTRATION_STATE - START
	Description : Set of constants for registration state.
	Author :  Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_REGISTRATION_STATE
{
	Success = 0,
	Verified = 1,
	NotVerified = 2
}

/* ENUM_REGISTRATION_STATE - END */

/*
		ENUM_VERIFICATION_STATE - START
		Description : Set of constants for registration state.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_VERIFICATION_STATE
	{
		Verified = 0,
		OnProcess = 1,
		NotVerified = 2,
		Rejected = 3
	}

/* ENUM_VERIFICATION_STATE - END */

/*
		ENUM_GENERAL_STATE - START
		Description : Set of constants for state general.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_GENERAL_STATE
	{
		Active = 0,
		Inactive = 1
	}

/* ENUM_GENERAL_STATE - END */

/*
		ENUM_RESPONSE_STATE - START
		Description : Enum for response state, this is used for validation response mostly, it will trigger the icon shown in the user interface.
		Author: Andri Septiawan.
		Created on :  Monday, 28 December 2020.             Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RESPONSE_STATE
	{
		Success = 0,
		Warning = 1,
		Fail = 2,
		information = 3,
		Nothing = 4
	}

/* ENUM_RESPONSE_STATE - END */

//#endregion


//#region TYPE

/*
		ENUM_GENDER_TYPE - START
		Description : Set of constants for gender type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_GENDER_TYPE
	{
		Female = 0,
		Male = 1
	}

/* ENUM_GENDER_TYPE - END */

/*
		ENUM_RELIGION_TYPE - START
		Description : Enum for religion state, these religion is based on Indonesia law.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RELIGION_TYPE
	{
		Islam = 0,
		Catholicism = 1,
		Christianity = 2,
		Hinduism = 3,
		Buddhism = 4,
		Confucianism = 5,

	}

/* ENUM_RELIGION_FOLLOWER - END */

/*
		ENUM_RELIGION_FOLLOWER - START
		Description : Enum for religion state, these religion is based on Indonesia law.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RELIGION_FOLLOWER
	{
		Moslem = 0,
		Catholic = 1,
		Christian = 2,
		Hindu = 3,
		Buddhist = 4,
		Confucianist = 5,
	}

/* ENUM_RELIGION_FOLLOWER - END */

/*
		ENUM_IDENTIFICATION_TYPE - START
		Description : Enum for the identification type this will trigger how you treat the identification number.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_IDENTIFICATION_TYPE
	{
		IdentityCard = 0,
		DrivingLicense = 1,
		TemporaryStayCard = 2,
		PermanentStayCard = 3,
		Passport = 5
	}

/* ENUM_IDENTIFICATION_TYPE - END */

/*
		ENUM_EDUCATION_TYPE - START
		Description : Enum for education type, these are the main education in Indonesia.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EDUCATION_TYPE
	{
		ElementarySchool = 0,
		JuniorHighSchool = 1,
		SeniorHighSchool = 2,
		AssociateDegree = 3,
		BachelorDegree = 4,
		MasterDegree = 5,
		DoctoralDegree = 6
	}

/* ENUM_EDUCATION_TYPE - END */

/*
		ENUM_EMPLOYMENT_TYPE - START
		Description : Enum for employee type, these are the main employement in company.
		Author: Andri Septiawan.
		Created on : Monday, 11 January 2021.          Updated on : Monday, 11 January 2021.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EMPLOYMENT_TYPE
	{
		Volunteer = 0,
		Internship = 1,
		Freelance = 2,
		PartTime = 3,
		OutSource = 4,
		Contract = 5,
		FullTime = 6
	}

/* ENUM_EMPLOYMENT_TYPE - END */

/*
		ENUM_EMPLOYMENT_TYPE - START
		Description : Enum for employee Grade, these are the main employement in company.
		Author: Andri Septiawan.
		Created on : Thursday, 18 February 2021.          Updated on : Thursday, 18 February 2021.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EMPLOYMENT_GRADE
	{
		Zero = 0,
		One = 1,
		Two = 2,
		Three = 3,
		Four = 4,
		Five = 5,
		Six = 6,
		Seven = 7,
		Eight = 8,
		Nine = 9,
		Ten = 10,
		Eleven = 11,
		Twelve = 12,
		Thirteen = 13,
		Fourteen = 14,
		Fifteen = 15
	}

/* ENUM_EMPLOYMENT_TYPE - END */


/*
		ENUM_SERVER_TYPE - START
		Description : Enum for server name, this will be use for micro service architecure, to know where the latest token is.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SERVER_TYPE
	{
		user,
		product,
		Policy
	}

/* ENUM_SERVER_TYPE - END */

/*
		ENUM_REGISTER_TYPE - START
		Description : Enum for server name, this will be use for micro service architecure, to know where the latest token is.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_REGISTER_TYPE
	{
		KenalanMobilePhone = 0,
		Facebook = 1,
		Google = 2,
		Linkedin = 3,
		Apple = 4,
		Twitter = 5,
		Line = 6,
		KakaoTalk = 7,
		KenalanEmail = 8,
	}

/* ENUM_REGISTER_TYPE - END */

/*
		ENUM_SALUTATION_TYPE - START
		Description : Set of constants for salutation type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SALUTATION_TYPE
	{
		Miss,
		Mr,
		Mrs
	}

/* ENUM_SALUTATION_TYPE - END */

/*
		ENUM_PHOTO_TYPE - START
		Description : Set of constants for photo type accross product.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PHOTO_TYPE
	{
		BuildingFront = 0,
		BuildingLeft = 1,
		BuildingRight = 2,
		BuildingBack = 3,
		BuildingInterior = 4,
		VehicleFront = 5,
		VehicleLeft = 6,
		VehicleRight = 7,
		VehicleBack = 8,
		VehicleInterior = 9,
		STNK = 10,
		KTP = 11,
		ClientApproval = 12,
		PaymentReceipt = 13,
		Visit =14,
		PoliceReportLetter = 15,
		KIRReport = 16,
		DriverLicense = 17,
		ClaimThirdPartyLetter = 18,
		IdentityThirdParty = 19,
		PeaceAgreementLetter = 20,
		FrontBumperLeft = 21,
		FrontBumperRight = 22,
		FrontGrillLeft = 23,
		FrontGrillRight = 24,
		FrontPanel = 25,
		HeadLampLeft = 26,
		HeadLampRight = 27,
		FrontCombLampLeft = 28,
		FrontCombLampRight = 29,
		PanelBullheadLamp = 30,
		FrontBumperSignLeft = 31,
		FrontBumperSignRight = 32,
		FrontFenderLeft = 33,
		FrontFenderRight = 34,
		FrontDoorLeft = 35,
		FrontDoorRight = 36,
		RearDoorLeft = 37,
		RearDoorRight = 38,
		LinearFrontFenderLeft = 39,
		LinearFrontFenderRight = 40,
		LinearReadFenderLeft = 41,
		LinearReadFenderRight = 42,
		BaggageOrTrunkCover = 43,
		RadiatorPanelUpper = 44,
		RadiatorPanelLower = 45,
		AprontPanelAssyLeft = 46,
		AprontPanelAssyRight = 47,
		OilPan = 48,
		ReservoirTank = 49,
		Accu = 50,
		FrontGlassPillarLeft = 51,
		FrontGlassPillarRight = 52,
		FrontGlass = 53,
		FrontGlassRubber = 54,
		FrontGlassWindowLeft = 55,
		FrontGlassWindowRight = 56,
		RearGlassWindowLeft = 57,
		RearGlassWindowRight = 58,
		ReadOrBackGlass = 59,
		SpionOrOuterMirrorLeft = 60,
		SpionOrOuterMirrorRight = 61,
		StopLampLeft = 62,
		StopLampRight = 63,
		RearCombLampLeft = 64,
		RearCombLampRight = 65,
		RearBumper = 66,
		QuarterPanelLeft = 67,
		QuarterPanelRight = 68,
		Condensor = 69,
		Compressor = 70,
		Pipes = 71,
		RefillFreon = 72,
		Dryer = 73,
		EngineMounting = 74,
		Radiator = 75,
		VelRearLeft = 76,
		VelRearRight = 77,
		VelFrontLeft = 78,
		VelFrontRight = 79,
		LegalResponsibilityDocument = 80,
		ClaimLetter = 81,
		IncidentChronologyDocumentPageOne = 82,
		IncidentChronologyDocumentPageTwo = 83,
		SurveyVideo = 84,
		Other = 85,
		Form10B = 86,
		VehicleDataExcelTemplate = 87,
		ApealLetter = 88,
		SupportingDocument = 89,
		EngineHood = 90,
		TriplangRight = 91,
		TriplangLeft = 92,
		Roof = 93,
		SideRoofRight = 94,
		SideRoofLeft = 95,
		Crossmember = 96,
		SpoilerFrontBumper = 97,
		ObjectOfLoss = 98,
		SupportingDocument2 = 99,
		SupportingDocument3 = 100,
		SupportingDocument4 = 101,
		SupportingDocument5 = 102,
		SpoilerRearBumper = 103,
		SpoilerTriplang = 104,
		RightCoverTriplang = 105,
		LeftCoverTriplang = 106,
		FrontGrill = 107,
		RightDoorHandle1 = 108,
		LeftDoorHandle1 = 109,
		RightDoorHandle2 = 110,
		LeftDoorHandle2 = 111,
		RearDoorTrimRightSide = 112,
		RearDoorTrimLeftSide = 113,
		FrontDoorTrimRightSide = 114,
		FrontDoorTrimLeftSide = 115,
		FrontMoldingBumperRightSide = 116,
		RearMoldingBumperRightSide = 117,
		FrontMoldingBumperLeftSide = 118,
		RearMoldingBumperLeftSide = 119,
		FrontMoldingFenderRightSide = 120,
		FrontMoldingFenderLeftSide = 121,
		MoldingDoorTrunk = 122,
		MoldingQPanelRightSide = 123,
		MoldingQPanelLeftSide = 124,
		SpoilerTrunk = 125,
		PilarARightSide = 126,
		PilarALeftSide = 127,
		PilarBRightSide = 128,
		PilarBLeftSide = 129,
		PilarCRightSide = 130,
		PilarCLeftSide = 131,
		BicycleFront = 132,
		BicycleBack = 133,
		BicycleRight = 134,
		BicycleLeft = 135,
		BicycleInvoice = 136,
		AuditProfile = 137,
		ControlEnvironmentAssesment = 138,
		AnomalyAuditorFeedback = 139,
		AnomalyAuditeeFollowUp = 140,
		AnomalyASQComplete = 141,
		SalvageReport = 142,
		SalvageHandoverLetter = 143,
		BPKB = 144,
		VehicleKey = 145,
		ReleaseRightLetter = 146,
		KwitansiTripleStamp = 147,
		VehicleReceipt = 148,
		FormSalvageBidding = 149,
		SalvagePhoto = 150
	}

/* ENUM_PHOTO_TYPE - END */

/*
		ENUM_ENVIRONMENT_TYPE - START
		Description : Set of constants for environment type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_ENVIRONMENT_TYPE
	{
		Local = 0,
		Development = 1,
		Staging = 2,
		Production = 3,
	}

/* ENUM_ENVIRONMENT_TYPE - END */

/*
		ENUM_PROJECT_TYPE - START
		Description : Set of constants for project type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PROJECT_TYPE
	{
		Anjani,
		Bromo,
		Staging,
		Production
	}

/* ENUM_PROJECT_TYPE - END */

/*
		ENUM_VIRTUALACCOUNT_TYPE - START
		Description : Set of constants for virtual account type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_VIRTUALACCOUNT_TYPE
	{
		Briva = 0
	}

/* ENUM_VIRTUALACCOUNT_TYPE - END */

/*
		ENUM_PDFPREVIEW_TYPE - START
		Description : Set of constants for pdf preview type, to define what model to open from product.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PDFPREVIEW_TYPE
	{
		ProductAsriCalculatePremium = 0,
		ProductAsriSPPA = 1,
		ProductOtoCalculatePremium = 2,
		ProductOtoSPPA = 3,
		ProductPersonalAccidentCalculatePremium = 4,
		ProductPersonalAccidentSPPA = 5,
		PolicyOutstanding = 6,
		PolicyRenewal = 7
	}

/* ENUM_PDFPREVIEW_TYPE - END */

/*
		ENUM_LETTER_TYPE - START
		Description : Set of constants for letter type, to define what serial number need to be generated.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          	Updated on : Friday, 16 April 2021.
		Created by : Andri Septiawan.						Updated by : Billy Johannes.
		Version : 1.1:0.
	*/

export enum ENUM_LETTER_TYPE
	{
		DeclarationPayment = 1,
		DeclarationReimburse = 2,
		DeclarationRealizationDownPayment = 3,
		DeclarationRealizationDebt = 4,
		SubmissionDownPayment = 5,
		SubmissionDebt = 6,
		OfficialMemo = 7,
		LetterOut = 8,
		LetterIn = 9,
		Outstanding = 10,
		Renewal = 11
	}

/* ENUM_LETTER_TYPE - END */

/*
		ENUM_BRANCH_TYPE - START
		Description : Set of constants for branch type, to define what branch type included in.
		Author : Billy Johannes.
		Created on : Sunday, 02 May 2020.          	Updated on : Sunday, 02 May 2020.
		Created by : Billy Johannes.				Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_BRANCH_TYPE
	{
		Headquarters = 0,
		Branch = 1,
		MRO = 2,
		ROS = 3
	}

/* ENUM_BRANCH_TYPE - END */

/*
	ENUM_PRODUCT_TYPE - START
	Description : Set of constants for product type, to use in search by attributes.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.   Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.      Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_PRODUCT_TYPE
{
	Conventional = 0,
	Special = 1,
	Syariah = 2,
	Customize = 3
}

/* ENUM_PRODUCT_TYPE - END */

/*
	ENUM_ISSUE_TYPE - START
	Description : Set of constants for issue type, to define what issue type included in.
	Author : Billy Johannes.
	Created on : Wednesday, 14 September 2022.      Updated on : Wednesday, 14 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_ISSUE_TYPE
{
	Mandatory = 1,
	Optional = 2
}

/* ENUM_ISSUE_TYPE - END */

/*
	ENUM_BIDDING_TYPE - START
	Description : Set of constants for bidding type, to define what bidding type included in salvage.
	Author : Ibrahim Aziz.
	Created on : Wednesday, 30 August 2023.		Updated on : Wednesday, 30 August 2023.
	Created by : Ibrahim Aziz.					Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_BIDDING_TYPE
{
	MotorVehicle = 1,
	NonMotorVehicle = 2
}

/* ENUM_BIDDING_TYPE - END */


/*
	ENUM_RISKASSIGNCONTENT_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Monday, 26 September 2022.			Updated on : Monday, 26 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_RISKASSIGNCONTENT_STATUS
{
	NotComplete = 1,
	Completed = 2,
}

/* ENUM_RISKASSIGNCONTENT_STATUS - END */

/*
		ENUM_MARITAL_STATUS - START
		Description : Set of constants for marital status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_MARITAL_STATUS
	{
		Single = 0,
		Married = 1,
		Divorce = 2,
		Widow = 3,
	}

/* ENUM_MARITAL_STATUS - END */

/*
		ENUM_HTTP_STATUS - START
		Description : List of HTTP response code this for the history and response to our service.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.				    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_HTTP_STATUS
	{
		/* INFORMATIONAL - START*/

		Continue = 100,
		SwitchingProtocol = 101,
		Processing = 102,

		/* INFORMATIONAL - END */

		/* SUCCESS - START */

		Ok = 200,
		Created = 201,
		Accepted = 202,
		NonAuthorativeInformation = 203,
		NoContent = 204,
		ResetContent = 205,
		PartialContent = 206,
		MultiStatus = 207,
		AlreadyReported = 208,
		IMUsed = 226,

		/* SUCCESS - END */

		/* REDIRECTION - START */

		MultipleChoices = 300,
		MovedPermanently = 301,
		Found = 302,
		SeeOther = 303,
		NotModified = 304,
		UseProxy = 305,
		Unused = 306,
		TemporaryRedirect = 307,
		PermanentRedirection = 308,

		/* REDIRECTION - END */

		/* CLIENT ERROR - START */

		BadRequest = 400,
		Unauthorized = 401,
		PaymentRequired = 402,
		Forbidden = 403,
		NotFound = 404,
		MethodNotAllowed = 405,
		NotAcceptable = 406,
		ProxyAuthenticationRequired = 407,
		RequestTimeout = 408,
		Conflict = 409,
		Gone = 410,
		LengthRequired = 411,
		PreconditionFailed = 412,
		RequestEntityTooLarge = 413,
		RequestURITooLong = 414,
		UnsupportedMediaType = 415,
		RequestedRangeNotSatisfiable = 416,
		ExpectationFailed = 417,
		ImATeapot = 418,
		EnhanceYourCalm = 420,
		UnprocessableEntity = 422,
		Locked = 423,
		FailedDependency = 424,
		ReservedForWebDAV = 425,
		UpgradeRequired = 426,
		PreconditionRequires = 428,
		TooManyRequest = 429,
		RequestHeaderFieldsTooLarge = 431,
		NoResponse = 444,
		RetryWith = 449,
		BlockedByWindowsParentalControls = 450,
		UnavailableForLegalReasons = 451,
		ClientClosedRequest = 499,
		BlockedByCORS = 0,

		/* CLIENT ERROR - END */

		/* SERVER ERROR - START */

		InternalServerError = 500,
		NotImplemented = 501,
		BadGateway = 502,
		ServiceUnavailable = 503,
		GatewayTimeout = 504,
		HTTPVersionNotSupported = 501,
		VariantAlsoNegotiates = 506,
		InsufficientStorage = 507,
		LoopDetected = 508,
		BandwithLimitExceeded = 509,
		NotExtended = 510,
		NetworkAuthenticationRequired = 511,
		NetworkReadTimeoutError = 598,
		NetworkConnectTimeoutError = 599

		/* SERVER ERROR - END */
	}

/* ENUM_HTTP_STATUS - END */

/*
		ENUM_UNDERWRITING_STATUS - START
		Description : Set of constants for underwriting status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_UNDERWRITING_STATUS
	{
		Pending = 0,
		Approved = 1,
		Rejected = 2,
	}

/* ENUM_UNDERWRITING_STATUS - END */

/*
		ENUM_SPPA_STATUS - START
		Description : Set of constants for sppa status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SPPA_STATUS
	{
		OnProgress = 0,
		ReadyForSave = 1,
		Saved = 2,
		Approved = 3,
		PendingForSurvey = 4,
		RejectedSurvey = 5,
		PendingForUnderwriting = 6,
		RejectedUnderwritting = 7,
		PendingForPayment = 8,
		Paid = 9,
		Completed = 10
	}

/* ENUM_SPPA_STATUS - END */

/*
		ENUM_POLICY_STATUS - START
		Description : Set of constants for policy status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_POLICY_STATUS
	{
		Active = 1,
		Outstanding = 2,
		Inactive = 3,
		Expired = 4,
		Terminated = 5,
		Canceled = 6,
		Pending = 7
	}

/* POLICY_STATUS - END */


/*
	ENUM_DOCUMENTSTAMPCOLLECTORAPPROVAL_STATUS - START
	Description : Set of document stamp collector approval status
	Created by : Andri Septiawan.										Created by : Friday, 14 April 2023.
	Updated by : Andri Septiawan.										Updated by : Friday, 14 April 2023.
	Version : 1.1:5.
*/

export enum ENUM_DOCUMENTSTAMPCOLLECTORAPPROVAL_STATUS
{
	SubmittedByMaker = 0,
	RejectedByChecker = 1,
	ApprovedByChecker = 2,
	RejectedBySigner = 3,
	ApprovedBySigner = 4,
}

/* ENUM_DOCUMENTSTAMPCOLLECTORAPPROVAL_STATUS - END */


/*
	ENUM_DOCUMENTSTAMPCOLLECTOR_OVERALLSTATUS - START
	Description : Set of document stamp collector overall status
	Created by : Andri Septiawan.										Created by : Friday, 14 April 2023.
	Updated by : Andri Septiawan.										Updated by : Friday, 14 April 2023.
	Version : 1.1:5.
*/

export enum ENUM_DOCUMENTSTAMPCOLLECTOR_OVERALLSTATUS
{
	SubmittedByMaker = 0,
	RejectedByChecker = 1,
	ApprovedByChecker = 2,
	RejectedBySigner = 3,
	ApprovedBySigner = 4,
	StampingInProgress = 5,
	StampingSuccess = 6,
}

/* ENUM_DOCUMENTSTAMPCOLLECTOR_OVERALLSTATUS - END */

//#endregion


//#region MISELANIOUS

/*
		ENUM_FONT_FAMILY - START
		Description : This will be tight to user model, to set up the font family preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_FONT_FAMILY
	{
		Primary = 0,
		Second = 1
	}

/* ENUM_FONT_FAMILY - END */

/*
		ENUM_FONT_SIZE - START
		Description : This will be tight to user model, to set up the font size preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_FONT_SIZE
	{
		Small = 0,
		Medium = 1,
		Large = 2
	}

/* ENUM_FONT_SIZE - END */

/*
		ENUM_THEME_COLOUR - START
		Description : This will be tight to user model, to set up the theme color preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_THEME_COLOUR
	{
		Fioralba = 0,
		Aurora = 1
	}

/* ENUM_THEME_COLOUR - END */

/*
		ENUM_DATE_MONTH - START
		Description : This will be used for spinner date.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_DATE_MONTH
	{
		January = 1,
		February = 2,
		March = 3,
		April = 4,
		May = 5,
		June = 6,
		July = 7,
		August = 8,
		September = 9,
		October = 10,
		November = 11,
		December = 12
	}

/* ENUM_DATE_MONTH - END */

/*
		ENUM_LETTER_STATUS - START
		Description : This role user in kartini website.
		Author: Billy Johannes.
		Created on : Friday, 19 March 2021.        		Updated on : Friday, 16 April 2021..
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.1:1.
	*/

export enum ENUM_LETTER_STATUS
	{
		OnProgress = 0,
		PendingForCheck = 1,
		PendingForSign = 2,
		Rejected = 3,
		Signed = 4,
		Printed = 5,
		Sent = 6
	}

/* ENUM_LETTER_STATUS - END */

/*
		ENUM_LETTER_APPROVAL - START
		Description : This role user in kartini website.
		Author: Billy Johannes.
		Created on : Tuesday, 04 May 2021.        	Updated on : Tuesday, 04 May 2021.
		Created by : Billy Johannes.				Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_LETTER_APPROVAL
	{
		Pending = 1,
		Approve = 2,
		Reject = 3,
	}

/*  ENUM_LETTER_APPROVAL - END */

/*
		ENUM_LETTERROLE_TYPE - START
		Description : For categorized and role user in kartini website.
		Author: Billy Johannes.
		Created on : Friday, 16 April 2021.        		Updated on : Friday, 16 April 2021.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_LETTER_ROLE
{
	Maker = 0,
	Checker = 1,
	Signer = 2,
	Associate = 3,
}

/* ENUM_LETTERROLE_TYPE - END */

/*
	ENUM_DIVISION_ID - START
	Description : This division id used accross website to determine division validation process.
	Author: Billy Johannes.
	Created on : Friday, 28 May 2021.        		Updated on : Friday, 28 May 2021.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_DIVISION_ID
{
	NoDivision = 0,
	InternalAuditDivision = 1,
	CorporateSecretaryDivision = 2,
	WholesaleBusinessDivision = 3,
	ShariaDivision = 4,
	BusinessDevelopmentAndPolicyDesk = 5,
	RetailAndMicroBusinessDivision = 6,
	DigitalBusinessDivision = 7,
	UnderwritingAndReinsuranceDivision = 8,
	ClaimDivision = 9,
	TreatyAndPortfolioManagementDivision = 10,
	ActuaryGroup = 11,
	AccountingAndFinanceDivision =12,
	LogisticDivision = 13,
	TechnologyAndInformationSystemDivisionNew = 14,
	OperationAndSupport = 15,
	ComplianceAndRiskManagementDivision = 16,
	CorporatePlanningandStrategyDivision = 17,
	HumanCapitalDivision = 18,
	LegalGroup = 19,
}

/* ENUM_DIVISION_ID - END */

/*
	ENUM_TICKET_STATUS - START
	Description : Set of constants for policy status.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.           Updated on : Monday, 28 December 2020.
	Created by : Ibrahim Aziz.      Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_TICKET_STATUS
{
	Pending = 0,
	InProgress = 1,
	ClosedWithConfirmation = 2,
	ClosedWithoutConfirmation = 3,
	NotClosedWithConfirmation = 4,
	NotClosedWithoutConfirmation = 5,
	Canceled = 6,
	WaitingForResponse = 7
}

/* ENUM_TICKET_STATUS - END */

/*
	ENUM_RISKACTIVITY_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 1 September 2022.        Updated on : Thursday, 1 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISKACTIVITY_STATUS
{
	All = 0,
	Active = 1,
	Inactive = 2
}

/* ENUM_RISKACTIVITY_STATUS - END */

/*
		ENUM_RISKACTION_STATUS - START
		Description : Set of constants for state general.
		Author : Billy Johannes.
		Created on : Thursday, 1 September 2022.        Updated on : Thursday, 1 September 2022.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:1.
	*/

export enum ENUM_RISKACTION_STATUS
{
	Active = 0,
	Inactive = 1
}

/* ENUM_RISKACTION_STATUS - END */

/*
		ENUM_RISKCONTROL_STATUS - START
		Description : Set of constants for state general.
		Author : Billy Johannes.
		Created on : Thursday, 1 September 2022.        Updated on : Thursday, 1 September 2022.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:1.
	*/

export enum ENUM_RISKCONTROL_STATUS
{
	All = 0,
	Active = 1,
	Inactive = 2
}

/* ENUM_RISKCONTROL_STATUS - END */

/*
	ENUM_RISKISSUE_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 1 September 2022.        Updated on : Thursday, 1 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISKISSUE_STATUS
{
	All = 0,
	Active = 1,
	Inactive = 2
}

/* ENUM_RISKISSUE_STATUS - END */


/*
	ENUM_RISKASSIGN_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 15 September 2022.        Updated on : Thursday, 15 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISKASSIGN_STATUS
{
	NotComplete = 1,
	Completed = 2,
	PendingForApproval = 3,
	ApprovedByWorkingUnit = 4,
	RejectedByWorkingUnit = 5,
	ApprovedByComplianceAndRiskManagement = 6,
	RejectedByComplianceAndRiskManagement = 7
}

/* ENUM_RISKASSIGN_STATUS - END */


/*
	ENUM_RISK_SCORE - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 15 September 2022.        Updated on : Thursday, 15 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISK_SCORE
{
	Low = 1,
	LowToModerate = 2,
	Moderate = 3,
	ModerateToHigh = 4,
	High = 5
}

/* ENUM_RISK_SCORE - END */

/*
		ENUM_PAYMENT_TYPE - START
		Description : For payment type in BRINESIA environment.
		Author: Billy Johannes.
		Created on : Tuesday, 23 March 2021.        		Updated on : Tuesday, 23 March 2021.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_PAYMENT_TYPE
	{
		Cash = 0,
		Transfer = 1
	}

/* ENUM_PAYMENT_TYPE - END */

/*
		ENUM_PAYMENTPRIORITY_TYPE - START
		Description : For payment priority type in BRINESIA environment.
		Author: Billy Johannes.
		Created on : Tuesday, 23 March 2021.        		Updated on : Tuesday, 23 March 2021.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_PAYMENTPRIORITY_TYPE
	{
		Normal = 0,
		Important = 1,
		VeryImportant = 2
	}

/* ENUM_PAYMENTPRIORITY_TYPE - END */

/*
    ENUM_PLATFORM_TYPE - START
    Description : Set of constants for platform type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Friday, 15 January 2021.   Updated on : Friday, 15 January 2021.
    Created by : Andri Septiawan.          	Updated by : Andri Septiawan.
    Version : 1.0:1.
	*/

export enum ENUM_PLATFORM_TYPE
	{
		Windows = 0,
		Mac = 1,
		Linux = 2,
		Android = 3,
		IOS = 4
	}

/* ENUM_PLATFORM_TYPE - END */

/*
    ENUM_DEVICE_TYPE - START
    Description : Set of constants for device type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Friday, 15 January 2021.   Updated on : Friday, 15 January 2021.
    Created by : Andri Septiawan.          	Updated by : Andri Septiawan.
    Version : 1.0:1.
	*/

export enum ENUM_DEVICE_TYPE
	{
		Desktop = 0,
		Website = 1,
		Handphone = 2,
		Tablet = 3
	}

/* ENUM_DEVICE_TYPE - END */

/*
    ENUM_SORT_TYPE - START
    Description : Set of constants for device type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Thursday, 18 February 2021.   	Updated on : Thursday, 18 February 2021.
    Created by : Andri Septiawan.          		Updated by : Andri Septiawan.
    Version : 1.0:1.
	*/

export enum ENUM_SORT_TYPE
	{
		Ascending = 0,
		Descending = 1,
	}

/* ENUM_SORT_TYPE - END */


/*
	ENUM_TOPIC_TYPE - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Billy Johannes.
	Created on : Monday, 24 May 2021.          	Updated on : Monday, 24 May 2021.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_TOPIC_TYPE
{
	SignIn = 0,
	PremiumCalculation = 1,
	Payment = 2,
	Claim = 3,
	Policy = 4,
	ProductInsurance = 5,
	BRINSSMART = 6,
	Other = 7
}

/* ENUM_TOPIC_TYPE - END */

/*
	ENUM_SEVERITY_TYPE - START
	Description : Set of constants for severity status for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_SEVERITY_TYPE
{
	Low = 0,
	Medium = 1,
	High = 2,
	Urgent = 3
}

/* ENUM_SEVERITY_TYPE - END */

/*
	ENUM_TICKETCHAT_TYPE - START
	Description : Set of constants for ticket chat type for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_TICKETCHAT_TYPE
{
	ChatPrivacy = -1,
	InternalBRINS = 0,
	General = 1
}

/* ENUM_TICKETCHAT_TYPE - END */

/*
	ENUM_INCIDENT_TYPE - START
	Description : Set of constants for Accident type claim.
	Author : Eka Saputra.
	Created on : Tuesday, 15 Juli 2021.          	Updated on : Tuesday, 15 Juli 2021.
	Created by : Eka Saputra.						Updated by : Eka Saputra.
	Version : 1.0:1.
*/

export enum ENUM_INCIDENT_TYPE
{
	Crash = 0,
	ReverseOrFell = 1,
	CriminalOrCrime = 2,
	Fire = 3,
	FloodOrNaturalDisaster = 4,
	Riot = 5,
	Terrorism = 6,
	HitByATreeOrBuilding = 7,
	Lost = 8
}

/* ENUM_TICKETCHAT_TYPE - END */


/*
	ENUM_INCIDENT_TYPE - START
	Description : Set of constants for policy status claim.
	Author : Eka Saputra.
	Created on : Tuesday, 22 Juli 2021.          	Updated on : Tuesday, 22 Juli 2021.
	Created by : Eka Saputra.						Updated by : Eka Saputra.
	Version : 1.0:1.
*/


export enum ENUM_POLICY_STATUS
{
    NotFound = 0,
    Found = 1
}

/* ENUM_TICKETCHAT_TYPE - END */

/*
		ENUM_REVIEW_STATE - START
		Description : Set of constants for survey status.
		Author : Eka Saputra.
		Created on : Friday, 20 August 2021.			Updated on : Friday, 20 August 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_REVIEW_STATE
	{
		Pending = 0,
		Verified = 1,
		Approved = 2,
		Rejected = 3,
		Appeal = 4
	}

/* ENUM_REVIEW_STATE - END */

/*
		ENUM_SURVEY_STATE - START
		Description : Set of constants for survey status.
		Author : Eka Saputra.
		Created on : Friday, 20 August 2021.			Updated on : Friday, 20 August 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_SURVEY_STATUS
{
	Pending = 0,
    Surveyed = 1,
    Canceled = 2,
    Verify = 3,
    Approved = 4,
    Rejected = 5,
    Resurvey = 6,
    Saved = 7,
    Submitted = 8
}

/* ENUM_SURVEY_STATE - END */

/*
		ENUM_CLAIMPROPOSAL_STATUS - START
		Description : Set of constants for survey status.
		Author : Eka Saputra.
		Created on : Friday, 20 August 2021.			Updated on : Thursday, 9 June 2022.
		Created by : Eka Saputra.						Updated by : Bondhan Jantri Ramadhan.
		Version : 1.0:3.
*/

export enum ENUM_CLAIMPROPOSAL_STATUS
	{
		Verified = 0,
		NotVerified = 1,
		RejectedByBranch = 2,
		PendingForHeadOffice = 3,
		PendingForCheckerHeadOffice = 4,
		PendingForSignerHeadOffice = 5,
		RejectedByHeadOffice = 6,
		PendingForSurvey = 7,
		CompletedWithoutSurvey = 8,
		CompletedWithSurvey = 9,
		Approved = 10,
		Rejected = 11,
		PendingForCorrection = 12,
		Submitted = 13,
		PendingForApprove = 14,
		PendingForCorrectionChecker = 15,
		PendingForCorrectionSigner = 16,
		Direct = 17,
		ApproveNotVerified = 18,
        RejectNotVerified = 19,
	}

/* ENUM_CLAIMPROPOSAL_STATUS - END */

/*
		ENUM_CLAIM_CHANNEL - START
		Description : Set of constants for survey status.
		Author : Eka Saputra.
		Created on : Friday, 20 August 2021.			Updated on : Monday, 20 September 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:2.
*/

export enum ENUM_CLAIM_CHANNEL
{
	Claim = 0,
	OperationAndSupport = 1,
	CustomerWebsite = 2,
	RelationshipManager = 3,
	BranchAdmin = 4,
	CustomerMobile = 5,
	CustomerSelfServiceMonitor = 6,
}

/* ENUM_CLAIM_CHANNEL - END */

//#endregion

/*
		ENUM_CLAIMPROPOSAL_SURVEYADDRESS - START
		Description : Set of constants for insured location.
		Author : Eka Saputra.
		Created on : Friday, 10 September 2021.			Updated on : Friday, 10 September 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_CLAIMPROPOSAL_SURVEYADDRESS
{
	SameAsPolicyHolder = 0,
	Branch = 1,
	BranchCoverage = 2,
	UserLocation = 3,
	InputText = 4,
}

/* ENUM_THIRDPARTYLIABILATION_TYPE - END */

/*
		ENUM_THIRDPARTYLIABILATION_TYPE - START
		Description : Set of constants for TJH.
		Author : Eka Saputra.
		Created on : Friday, 24 September 2021.			Updated on : Friday, 21 September 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_THIRDPARTYLIABILATION_TYPE
{
	Car = 0,
	TruckOrVan = 1,
	MotorcycleOrOtherVehicles = 2,
	Fatalities = 3,
	Other = 4,
}

/* ENUM_THIRDPARTYLIABILATION_TYPE - END */

/*
		ENUM_CLAIMITEM_TYPE - START
		Description : Set of constants for item survey.
		Author : Eka Saputra.
		Created on : Saturday, 3 October 2021.			Updated on : Saturday, 3 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_CLAIMITEM_TYPE
{
	Repair = 0,
	Replace = 1,
	Salvage = 2,
}

/* ENUM_CLAIMITEM_TYPE - END */

//#endregion

/* ENUM_WORKSHOP_TYPE - END */

/*
		ENUM_WORKSHOP_TYPE - START
		Description : Set of constants for item type workshop.
		Author : Eka Saputra.
		Created on : Monday, 11 October 2021.			Updated on : Monday, 11 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_WORKSHOP_TYPE
{
	Authorized = 0,
	NonAuthorized = 1,
}

/* ENUM_WORKSHOP_TYPE - END */

/* ENUM_POLICY_TYPE - END */

/*
		ENUM_POLICY_TYPE - START
		Description : Set of constants for item type policy.
		Author : Eka Saputra.
		Created on : Wednesday, 13 October 2021.			Updated on : Wednesday, 13 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_POLICY_TYPE
{
	Fire = 0,
	Engineering = 1,
	MotorVehicle = 2,
	Varia = 3,
	FinansialLine = 4
}

/* ENUM_POLICY_TYPE - END */

/*
		ENUM_APPROVAL_CONDITION_CLAIMPROPOSAL_TYPE - START
		Description : Set of constants for approval condition type claim proposal.
		Author : Eka Saputra.
		Created on : Thursday, 14 October 2021.			Updated on : Thursday, 14 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_APPROVAL_CONDITION_CLAIMPROPOSAL_TYPE
	{
		Approve = 0,
		Reject = 1,
	}

/* ENUM_APPROVAL_CONDITION_CLAIMPROPOSAL_TYPE - END */

/*
		ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE - START
		Description : Set of constants for approval condition type claim proposal.
		Author : Eka Saputra.
		Created on : Thursday, 14 October 2021.			Updated on : Thursday, 14 October 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE
{
	MotorVehicle,
	Personal,
	Other
}

/* ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE - END */

/*
		ENUM_BRIPOLICYENDORSEMENT_STATUS - START
		Description : Set of constants for status condition type bri policy endorsement.
		Author : Eka Saputra.
		Created on : Thursday, 4 November 2021.			Updated on : Thursday, 4 November 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_BRIPOLICYENDORSEMENT_STATUS
{
	Send = 0,
	Verified = 1,
	NotVerified = 2,
	Endorsed = 3,
	Rejected = 4,
}

/* ENUM_BRIPOLICYENDORSEMENT_STATUS - END */

/*
		ENUM_CLAIM_COVERAGE - START
		Description : Set of constants for status condition type bri policy endorsement.
		Author : Eka Saputra.
		Created on : Monday, 22 November 2021.			Updated on : Monday, 22 November 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_CLAIM_COVERAGE
	{
		Rcover = 0,
		Icover = 1,
		Acccla = 2,
		Accded = 3,
	}

/* ENUM_CLAIMPROPOSAL_SURVEY_TYPE - END */

/*
		ENUM_CLAIMPROPOSAL_SURVEY_TYPE - START
		Description : Set of constants for status condition type bri policy endorsement.
		Author : Eka Saputra.
		Created on : Monday, 22 November 2021.			Updated on : Monday, 22 November 2021.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_CLAIMPROPOSAL_SURVEY_TYPE
{
	Casco = 0,
	TJH = 1,
}

/* ENUM_CLAIMPROPOSAL_SURVEY_TYPE - END */

/*
    ENUM_CLAIMPROPOSAL_STATUS_ADJUSTER - START
    Description : Set of constants for status type adjuster.
    Author : Bondhan Jantri Ramadhan.
    Created on : Thursday, 30 June 2022.      Updated on : Thursday, 30 June 2022.
    Created by : Bondhan Jantri Ramadhan.      Updated by : Bondhan Jantri Ramadhan.
    Version : 1.0:0
*/

export enum ENUM_CLAIMPROPOSAL_STATUS_ADJUSTER
{
	PendingForMakerBranch = 0,
	PendingForChecker = 1,
	PendingForSigner = 2,
	Completed = 3,
	PendingForMaker = 4,
	PendingForMakerHeadOffice = 5,
}

/* ENUM_CLAIMPROPOSAL_STATUS_ADJUSTER - END */


/*
    ENUM_CLAIMPROPOSAL_STATUS_NOTIFICATIONLOSSADVICE - START
    Description : Set of constants for status type notification.
    Author : Bondhan Jantri Ramadhan.
    Created on : Thursday, 30 June 2022.      Updated on : Thursday, 30 June 2022.
    Created by : Bondhan Jantri Ramadhan.      Updated by : Bondhan Jantri Ramadhan.
    Version : 1.0:0
*/

export enum ENUM_CLAIMPROPOSAL_STATUS_NOTIFICATIONLOSSADVICE
{
	PendingForMaker = 0,
	PendingForChecker = 1,
	PendingForSigner = 2,
	Completed = 3
}

/* ENUM_CLAIMPROPOSAL_STATUS_NOTIFICATIONLOSSADVICE - END */

/*
    ENUM_CLAIMPROPOSAL_NOTIFICATION_TYPE - START
    Description : Set of constants for type of notification.
    Author : Bondhan Jantri Ramadhan.
    Created on : Thursday, 30 June 2022.      Updated on : Thursday, 30 June 2022.
    Created by : Bondhan Jantri Ramadhan.      Updated by : Bondhan Jantri Ramadhan.
    Version : 1.0:0
*/

export enum ENUM_CLAIMPROPOSAL_NOTIFICATION_TYPE
{
	NotificationLossAdvice = 0,
}

/* ENUM_CLAIMPROPOSAL_NOTIFICATION_TYPE - END */

/*
    ENUM_PROJECT_CODE - START
    Description : Set of constants for status type adjuster.
    Author : Bondhan Jantri Ramadhan.
    Created on : Sunday, 17 July 2022.      	Updated on : Sunday, 17 July 2022.
    Created by : Eka Saputra					Updated by : Eka Saputra.
    Version : 1.0:0
*/

export enum ENUM_PROJECT_CODE
{
	Anjani = 1,
	Bromo = 2,
	Citrasena = 3,
	Dharmawangsa = 4,
	Eboni = 5,
	Foja = 6,
	Gili = 7,
	Halimun = 8,
	Ismaya = 9,
	Joho = 10,
	Kartini = 11,
	Legian = 12,
	Maninjau = 13,
	Natuna = 14,
	Opak = 15,
	Patma = 16,
	Quartz = 17,
	Rinjani = 18,
	Sentani = 19,
	Trijata = 20,
	Ungaran = 21,
	VanDaalen = 22,
	Widura = 23,
	Xanthite = 24,
	Yaro = 25,
	Zircon = 26,
	Arjuna = 27,
	Bima = 28,
	Ciremai = 29,
	Diponegoro = 30,
	Ekalawya = 31,
	Fatmawati = 32,
	Gamalama = 33,
	Halmahera = 34,
	Intata = 35,
	Jayawijaya = 36,
	Karimun = 37,
	Laksmana = 38,
}

/* ENUM_PROJECT_CODE - END */


/*
		ENUM_REIMBURSEMENT_TYPE - START
		Description : Set of constants for type reimbursement.
		Author : Andryana Baros.
		Created on : Friday, 05 November 2021.			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.0:1.
	*/

export enum ENUM_REIMBURSEMENT_TYPE
	{
		DentalReimbursement = 1,
		OpticReimbursement = 2,
		PreMaternityReimbursement = 3,
		MaternityReimbursement = 4,
		PostMaternityReimbursement = 5,
		PaidLeaveReimbursement = 6
	}

/* ENUM_REIMBURSEMENT_TYPE - END */


/*
		ENUM_REIMBURSEMENT_STATUS - START
		Description : Set of constants for status reimbursement.
		Author : Andryana Baros.
		Created on : Friday, 05 November 2021.			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.0:1.
	*/

export enum ENUM_REIMBURSEMENT_STATUS
	{
		OnProgress = 0,
		PendingForCheck = 1,
		PendingForSign = 2,
		Rejected = 3,
		Sign = 4,
		Approved = 5
	}

/* ENUM_REIMBURSEMENT_STATUS - END */


/*
		ENUM_COVERED_PERSON - START
		Description : Set of constants for status covered reimbursement.
		Author : Andryana Baros.
		Created on : Friday, 05 November 2021.			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.0:1.
	*/

export enum ENUM_COVERED_PERSON
	{
		Self = 0,
		Husband = 1,
		Wife = 2,
		FirstChild = 3,
		SecondChild = 4,
		ThirdChild = 5
	}

/* ENUM_CHILD_NUMBER - END */


/*
		ENUM_CHILD_NUMBER - START
		Description : Set of constants for child number.
		Author : Andryana Baros.
		Created on : Monday, 22 November 2021.			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.1:2.
	*/

export enum ENUM_CHILD_NUMBER
	{
		FirstChild = 0,
		SecondChild = 1,
		ThirdChild = 2
	}

/* ENUM_CHILD_NUMBER - END */


/*
		ENUM_USG_TYPE - START
		Description : Set of constants for USG Type			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.1:2.
	*/

export enum ENUM_USG_TYPE
	{
		TwoDimension = 0,
		ThreeDimension = 1
	}

/* ENUM_USG_TYPE - END */


/*
		ENUM_MATERNITY_TYPE - START
		Description : Set of constants for Maternity Type			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.1:2.
	*/

export enum ENUM_MATERNITY_TYPE
	{
		Normal = 0,
		Caesarean = 1,
		Premature = 2
	}

/* ENUM_MATERNITY_TYPE - END */


/*
	ENUM_REALIZATION_TYPE - START
	Description : Set of constants for realization sub type for parameter.
	Author : Billy Johannes.
	Created on : Tuesday, 20 June 2023.        Updated on : Tuesday, 20 June 2023.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_REALIZATION_TYPE
{
	Other = 0,
	Parameter = 1,
	SubParameter = 2,
	SubSubParameter = 3,
}

/* ENUM_REALIZATION_TYPE - END */

/*
	ENUM_RAS_LIMITTYPE - START
	Description : Set of constants for RAS Operator Type	Updated on : .
	Created by : Billy Johannes.							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RAS_LIMITTYPE
{
	Internal = 0,
	Industry = 1,
}

/* ENUM_RAS_LIMITTYPE - END */

/*
	ENUM_RAS_REPORTTYPE - START
	Description : Set of constants for RAS Operator Type	Updated on : .
	Created by : Billy Johannes.							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RAS_REPORTTYPE
{
	Bulletin = 0,
	Annual = 1,
}

/* ENUM_RAS_REPORTTYPE - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETER_UNIT - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETER_UNIT
{
	Percentage = 0,
	Value = 1,
}


/* ENUM_RISKAPPETITESTATEMENTPARAMETER_UNIT - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBPARAMETER_UNIT - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBPARAMETER_UNIT
{
	Percentage = 0,
	Value = 1,
}


/* ENUM_RISKAPPETITESTATEMENTSUBPARAMETER_UNIT - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETER_UNIT - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETER_UNIT
{
	Percentage = 0,
	Value = 1,
}


/* ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETER_UNIT - END */

/*
	ENUM_RISKAPPETITESTATEMENTINTERNAL_ORDER - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTINTERNAL_ORDER
{
    Ascending = 0,
    Descending = 1
}

/* ENUM_RISKAPPETITESTATEMENTINTERNAL_ORDER - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_INTERNALLIMIT - START
	Description : Set of constants for RAS Internal Limit Type	Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_INTERNALLIMIT
{
	Low = 1,
	LowToModerate = 2,
	Moderate = 3,
	ModerateToHigh = 4,
	High = 5,
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_INTERNALLIMIT - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_STATUS
{
    Created = 0,
    ProvidedByDataManagement = 1,
    SubmittedByMaker = 2,
    RejectedBySigner = 3,
    ApprovedBySigner = 4,
	RejectedByConfirmer = 5,
	ApprovedByConfirmer = 6,
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL_STATUS
{
    SubmittedByMaker = 0,
    RejectedBySigner = 1,
    ApprovedBySigner = 2,
	RejectedByConfirmer = 3,
	ApprovedByConfirmer = 4,
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN_STATUS
{
    Created = 0,
    SubmittedByMaker = 1,
    RejectedBySigner = 2,
    ApprovedBySigner = 3
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL_STATUS
{
    SubmittedByMaker = 0,
    RejectedBySigner = 1,
    ApprovedBySigner = 2
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION_STATUS
{
    Created = 0,
    ProvidedByDataManagement = 1,
    SubmittedByMaker = 2,
    RejectedBySigner = 3,
    ApprovedBySigner = 4,
	RejectedByConfirmer = 5,
	ApprovedByConfirmer = 6,
}

/* ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL_STATUS
{
    SubmittedByMaker = 0,
    RejectedBySigner = 1,
    ApprovedBySigner = 2,
	RejectedByConfirmer = 3,
	ApprovedByConfirmer = 4,
}

/* ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION_STATUS
{
    Created = 0,
    ProvidedByDataManagement = 1,
    SubmittedByMaker = 2,
    RejectedBySigner = 3,
    ApprovedBySigner = 4,
	RejectedByConfirmer = 5,
	ApprovedByConfirmer = 6,
}

/* ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL_STATUS
{
    SubmittedByMaker = 0,
    RejectedBySigner = 1,
    ApprovedBySigner = 2,
	RejectedByConfirmer = 3,
	ApprovedByConfirmer = 4,
}

/* ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL_STATUS - END */

//#endregion


/*
		ENUM_USERGROUPID - START
		Description : Set of constants for Maternity Type			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.1:2.
	*/

export enum ENUM_USERGROUPID
{
	Administrator = 1,
	RelationshipManager = 2,
	LogisticMaker = 3,
	LogisticChecker = 4,
	LogisticSigner = 5,
	LogisticCheckerSigner = 6,
	JLO = 7,
	AKUCheckerSigner = 8,
	Bisnis = 9,
	ThirdParty = 10,
	Customer = 11,
	BranchSyariah = 12,
	JamkrindoSyariah = 13,
	ClaimMaker = 14,
	ClaimChecker = 15,
	ClaimCheckerSigner = 16,
	ClaimSigner = 17,
	SyariahMaker = 18,
	SyariahChecker = 19,
	SyariahSigner = 20,
	SyariahCheckerSigner = 21,
	Director = 22,
	KPRSBRI = 23,
	InternalAuditMember = 24,
	HumanResource = 25,
	CompanySecretariat = 26,
	ComplianceAndRiskManagement = 27,
	Legal = 28,
	StrategicAndPlanning = 29,
	Actuarial = 30,
	PolicyAndBusinessDevelopment = 31,
	TreatyAndPortfolioAnalysis = 32,
	UnderwriterAndReassurance = 33,
	BranchManager = 34,
	Surveyor = 35,
	DigitalMicroAndAgency = 36,
	Employee = 37,
	AKUMaker = 38,
	AKUChecker = 39,
	AKUSigner = 40,
	BranchBusiness = 41,
	BranchAdmin = 42,
	TechnologyAndInformationSystemMaker = 43,
	TechnologyAndInformationSystemChecker = 44,
	TechnologyAndInformationSystemSigner = 45,
	TechnologyAndInformationSystemCheckerSigner = 46,
	BSI = 47,
	BranchSyariahAsmik = 48,
	BranchSyariahAsmikJamsyar = 49,
	BranchSyariahAccounting = 50,
	DeputyBranchManager = 51,
	ClaimNonMotorvehicleMaker = 52,
	InternalAuditLead = 53,
	InternalAuditManager = 54,
	InternalAuditAdmin = 55,
	InternalAuditHead = 56,
	ClaimNonMotorvehicleChecker = 57,
	ClaimNonMotorvehicleSigner = 58,
	ClaimNonMotorvehicleCheckerSigner = 59,
	RiskChampion = 60,
	RiskApprover = 61,
	ComplienceAndRiskManagementMaker = 62,
	InternalAuditCheckerForProjectAudit = 63,
	InternalAuditSignerForProjectAudit = 64,
	InternalAuditMakerForProjectAudit = 65,
	InternalAuditCheckerForManagementImprovementDevelopment = 66,
	InternalAuditSignerForManagementImprovementDevelopment = 67,
	InternalAuditMakerForManagementImprovementDevelopment = 68,
	InternalAuditCheckerForAuditPlanMemorandum = 69,
	InternalAuditSignerForAuditPlanMemorandum = 70,
	InternalAuditMakerForAuditPlanMemorandum = 71,
	TravelSyariah = 72,
	TravelMaker = 73,
	TravelChecker = 74,
	InternalAuditHeadInCharge = 75,
	CompanySecretariatChecker = 76,
	CompanySecretariatSigner = 77,
	CompanySecretariatCheckerSigner = 78,
	HumanResourceChecker = 79,
	HumanResourceSigner = 80,
	HumanResourceCheckerSigner = 81,
	SyariahTravelAdmin = 82,
	InternalAuditStandardQualityDevelopment = 83,
	BranchUnderwriterAndReassurance = 84,
	InternalAuditMakerForProtectASQ = 85,
	InternalAuditCheckerForProtectASQ = 86,
	InternalAuditSignerForProtectASQ = 87,
	InternalAuditMakerForProtectAuditor = 88,
	InternalAuditCheckerForProtectAuditor = 89,
	InternalAuditSignerForProtectAuditor = 90,
	ClaimTrackingChecker = 91,
	ClaimTrackingSigner = 92,
	EBiddingBranchOfficeMaker = 97,
	EBiddingBranchOfficeChecker = 98,
	EBiddingBranchOfficeSigner = 99,
	EBiddingHeadOfficeMaker = 100,
	EBiddingHeadOfficeChecker = 101,
	EBiddingHeadOfficeFirstSigner = 102,
	EBiddingHeadOfficeSecondSigner = 103,
	GCGAdmin = 110,
	GCGChecker = 111,
	GCGSigner = 112,
	PriorityRelationshipManager = 113,
	EBudgetingAFNMaker = 114,
	EBudgetingAFNChecker = 115,
	EBudgetingAFNSigner = 116,
	EBudgetingWholesaleMaker = 117,
	EBudgetingWholesaleChecker = 118,
	EBudgetingWholesaleSigner = 119,
	EBudgetingRitmikMaker = 120,
	EBudgetingRitmikChecker = 121,
	EBudgetingRitmikSigner = 122,
	EBudgetingDBDMaker = 123,
	EBudgetingDBDChecker = 124,
	EBudgetingDBDSigner = 125,
	EBudgetingDKPBMaker = 126,
	EBudgetingDKPBChecker = 127,
	EBudgetingDKPBSigner = 128,
	BrinsPrimeCPS = 129,
	BrinsPrimeBranchMaker = 130,
	BrinsPrimeBranchSigner = 131,
	BrinsPrimeHeadOfficeMaker = 132,
	BrinsPrimeHeadOfficeChecker = 133,
	BrinsPrimeHeadOfficeSigner = 134,
	TechnologyAndInformationSystemOperational = 135,
	BrinsTaxReportMaker = 163,
	BrinsTaxReportChecker = 164,
	BrinsTaxReportSigner = 165,
   	BrinsTaxReportPIC = 166,
	BrinsTaxRequestPIC = 177,
	BrinsTaxRequestAFN = 178,
	BrinsPrimeRegionalOffice = 136,
	BrinsaveLogisticMaker = 137,
	BrinsaveLogisticChecker = 138,
	BrinsaveLogisticSigner = 139,
	BrinsaveWorkingUnitMaker = 140,
	BrinsaveWorkingUnitChecker = 141,
	BrinsaveWorkingUnitSigner = 142,
	ClaimNonMotorvehicleProposalAdjuster = 143,
	ClaimAgent = 144,
	ClaimNonMotorvehicleProposalAdjusterBranchMaker = 145,
	ClaimNonMotorvehicleProposalAdjusterBranchChecker = 146,
	ClaimNonMotorvehicleProposalAdjusterBranchSigner = 147,
	ClaimNonMotorvehicleProposalAdjusterHeadOfficeMaker = 148,
	ClaimNonMotorvehicleProposalAdjusterHeadOfficeChecker = 149,
	ClaimNonMotorvehicleProposalAdjusterHeadOfficeSigner = 150,
	PolicyAndTechnicalOperation = 151,
	RegionalOffice = 152,
	SubrogationAndClaimRecoveryDesk = 153,
	CustomerCare = 154,
	DevelopmentAndOperationalBusinessModel = 155,
	CorporateTransformationAndChangeManagementDesk = 156,
	DivisionUnderwriting = 157,
	DivisionReinsurance = 158,
	AKUTaxRegulation = 159,
	BrinsproMaker = 160,
	BrinsproChecker = 161,
	BrinsproSigner = 162,
}

/* ENUM_USERGROUPID - END */

/*
	ENUM_GRATIFICATION_STATUS - START
	Description : Set of constants for status GCG Gratification			Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_GRATIFICATION_STATUS
{
	Created = 0,
	InReview = 1,
	Reviewed = 2,
}

/* ENUM_GRATIFICATION_STATUS - END */

/*
	ENUM_CODEOFETHICS_STATUS - START
	Description : Set of constants for status GCG Code Of Ethics		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_CODEOFETHICS_STATUS
{
	SubmittedByEmployee = 0,
	RejectedByGCGChecker = 1,
	ApprovedByGCGChecker = 2,
	RejectedByGCGSigner = 3,
	ApprovedByGCGSigner = 4,
}

/* ENUM_CODEOFETHICS_STATUS - END */

/*
	ENUM_CODEOFETHICSAPPROVAL_STATUS - START
	Description : Set of constants for status GCG Code Of Ethics		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_CODEOFETHICSAPPROVAL_STATUS
{
	SubmittedByEmployee = 0,
	RejectedByGCGChecker = 1,
	ApprovedByGCGChecker = 2,
	RejectedByGCGSigner = 3,
	ApprovedByGCGSigner = 4,
}

/* ENUM_CODEOFETHICSAPPROVAL_STATUS - END */

/*
	ENUM_ANNUALDISCLOSURE_STATUS - START
	Description : Set of constants for status GCG Annual Disclosure		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_ANNUALDISCLOSURE_STATUS
{
	SubmittedByEmployee = 0,
	RejectedByGCGChecker = 1,
	ApprovedByGCGChecker = 2,
	RejectedByGCGSigner = 3,
	ApprovedByGCGSigner = 4,
}

/* ENUM_ANNUALDISCLOSURE_STATUS - END */

/*
	ENUM_ANNUALDISCLOSUREAPPROVAL_STATUS - START
	Description : Set of constants for status GCG Annual Disclosure		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_ANNUALDISCLOSUREAPPROVAL_STATUS
{
	SubmittedByEmployee = 0,
	RejectedByGCGChecker = 1,
	ApprovedByGCGChecker = 2,
	RejectedByGCGSigner = 3,
	ApprovedByGCGSigner = 4,
}

/* ENUM_ANNUALDISCLOSUREAPPROVAL_STATUS - END */

/*
	ENUM_ANNUALDISCLOSUREQUESTION_ANSWER - START
	Description : Set of constants for status GCG Annual Disclosure		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_ANNUALDISCLOSUREQUESTION_ANSWER
{
	Yes = 0,
	No = 1
}

/* ENUM_ANNUALDISCLOSUREQUESTION_ANSWER - END */

/*
	ENUM_RISKCONTROL_STATUS - START
	Description : Set of constants for state general.
	Author : Rizqi.
	Created on : Monday, 05 Desember 2022.        Updated on :
	Created by : Rizqi.			Updated by :
	Version : 1.0.0.
*/

export enum ENUM_RISKREQUEST_STATUS
{
	All = 0,
	Draft = 1,
	Sent = 2,
}

//#endregion


/*
	ENUM_FAMILY_STATUS - START
	Description : Set of constants for status family			Updated on : .
	Created by : Andryana Baros.								Updated by : .
	Version : 1.1:5.
*/

export enum ENUM_FAMILY_STATUS
{
	Wife = 0,
	Husband = 1,
	Child = 2,
	Father = 3,
	Mother = 4,
	YoungerSibling = 5,
	OlderSibling = 6,
}

/* ENUM_FAMILY_STATUS - END */


/*
	ENUM_DOCUMENT_STATUS - START
	Description : Set of constants for status document					Updated on : .
	Created by : Abdullah Fahmi.										Updated by : .
	Version : 1.1:5.
*/

export enum ENUM_DOCUMENT_STATUS
{
	Draft = 0,
	Revise = 1,
	PendingCheckerDivision = 2,
	PendingSignerDivision = 3,
	PendingConfirmation = 4,
	PendingMaker = 5,
	PendingChecker = 6,
	PendingSigner = 7,
	Rejected = 8,
	Approved = 9
}

/* ENUM_DOCUMENT_STATUS - END */


/*
	ENUM_DOCUMENT_CATEGORY - START
	Description : Set of constants for document category 		Updated on : .
	Created by : Abdullah Fahmi.								Updated by : .
	Version : 1.1:5.
*/

export enum ENUM_DOCUMENT_CATEGORY
{
	CompanyDeed = 0,
	Others = 1
}

/* ENUM_DOCUMENT_CATEGORY - END */


/*
	ENUM_DOCUMENTSTAMPCOLLECTOR_TYPE - START
	Description : This is used for stamp collector document type
	Author: Andryana Baros.
	Created on : Monday, 07 March 2022.        		Updated on : -.
	Created by : Andryana Baros.					Updated by : -.
	Version : 1.1:32.
*/

export enum ENUM_DOCUMENTSTAMPCOLLECTOR_TYPE
{
	Security = 0,
	TransactionDocument = 1,
	MiscellaneousDocument = 2,
	MikAmountOfMoneyMoreThanFiveMillionStatementDocumentro = 3,
}

/* ENUM_DOCUMENTSTAMPCOLLECTOR_TYPE - END */


/*
	ENUM_DOCUMENTSTAMPCOLLECTOR_STATUS - START
	Description : This is used for stamp collector status
	Author: Andryana Baros.
	Created on : Monday, 07 March 2022.        		Updated on : -.
	Created by : Andryana Baros.					Updated by : -.
	Version : 1.1:32.
*/

export enum ENUM_DOCUMENTSTAMPCOLLECTOR_STATUS
{
	Success = 0,
	Fail = 1,
	OnProcess = 2,
	Cancel = 3,
}

/* ENUM_DOCUMENTSTAMPCOLLECTOR_STATUS - END */


/*
	ENUM_TICKET_TOPIC_TYPE - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Egar Prasetya.
	Created on : Monday, 9 January 2023.          	Updated on : -
	Created by : Rizqi Alfiyati.				Updated by : -
	Version : 1.5:1.
*/

export enum ENUM_TICKET_TOPIC_TYPE
{
	Information = 0,
	Claim = 1,
	Complaint = 2,
	BRI = 3,
	Others = 4,
	Renewal = 5
}

/* ENUM_TICKET_TOPIC_TYPE - END */


/*
	ENUM_CLAIMPROPOSAL_TRACKING_PHASE - START
	Description : Set of constants for tracking pahse on tracking claim.
	Author : Anjar Kusworo.
	Created on : Monday, 26 February 2023.          	Updated on : -
	Created by : Andryana Baros.						Updated by : -
	Version : 1.5:1.
*/

export enum ENUM_CLAIMPROPOSAL_TRACKING_PHASE
{
	Reporting = 0,
	Mantle = 1,
	Processing = 2,
	Finishing = 3,
	ClaimAdministration = 4,
	PaymentAKU = 5,
	PaymentWorkshop = 6,
	PaymentInsured = 7,
	Completed = 8
}

/* ENUM_CLAIMPROPOSAL_TRACKING_PHASE - END */


/*
	ENUM_QUESTION_FOR - START
	Description : Set of constants for enum question satisfaction survey.
	Author : Anjar Kusworo.
	Created on : Monday, 04 April 2023.          	Updated on : -
	Created by : Andryana Baros.						Updated by : -
	Version : 1.5:1.
*/

export enum ENUM_QUESTION_FOR
{
	BRINS = 0,
	Workshop = 1,
	FeedbackBRINS = 2,
	FeedbackWorkshop = 3,
	Recommendation = 4,
	RecommendationItems = 5
}

/* ENUM_CLAIMPROPOSAL_TRACKING_PHASE - END */

/*
	ENUM_AUDITTEAMMEMBER_ROLE - START
	Description : This is used for audit team member role
	Author: Eka Saputra.
	Created on : Selasa, 06 September 2022.                Updated on : -.
	Created by : Eka Saputra.											Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_AUDITTEAMMEMBER_ROLE
{
	TeamLeader = 0,
	Member = 1
}

/* ENUM_AUDITTEAMMEMBER_ROLE - END */

/*
    ENUM_ANOMALY_STATUS - START
    Description : List of anomaly status.
    Author :  Eka Saputra.
    Created on : Sunday, 11 June 2023.           Updated on : Sunday, 11 June 2023.
    Created by : Eka Saputra.            			Updated by : Eka Saputra.
    Version : 1.0:1.
    */

export enum ENUM_ANOMALY_STATUS
{
	DraftASQ = 0,
	Rejected = 1,
	DraftAuditor = 2,
	AssignToAuditor = 3,
	DraftAuditee = 4,
	AssignToAuditee = 5,
	CompleteAdequate = 6,
	Complete = 7
}

/* ENUM_ANOMALY_STATUS - END */

/*
    ENUM_ANOMALY_APPROVALSTATUS - START
    Description : List of anomaly approval status.
    Author :  Eka Saputra.
    Created on : Sunday, 11 June 2023.           Updated on : Sunday, 11 June 2023.
    Created by : Eka Saputra.            			Updated by : Eka Saputra.
    Version : 1.0:1.
*/

export enum ENUM_ANOMALY_APPROVALSTATUS
{
	// ASQ
	ReviewASQ,
	RejectASQ,
	DraftASQ,
	PendingCheckerASQ,
	PendingSignerASQ,

	// AUDITOR
	ReviewAuditor,
	RejectAuditor,
	DraftAuditor,
	PendingCheckerAuditor,
	PendingSignerAuditor,

	// AUDITEE
	ReviewAuditee,
	RejectAuditee,
	DraftAuditee,
	PendingCheckerAuditee,
	PendingSignerAuditee,

	// COMPLETE
	Complete,
	CompleteCarryOver,
	CompleteWithNotes
}

/* ENUM_ANOMALY_APPROVALSTATUS - END */

/*
    ENUM_CLAIMPAID_STATUS - START
    Description : List of claim paid status.
    Author :  Ibrhim Aziz.
    Created on : Wednesday, 30 August 2023.			Updated on : Wednesday, 30 August 2023.
    Created by : Ibrahim Aziz.            			Updated by : Ibrahim Aziz.
    Version : 1.0:1.
*/

export enum ENUM_CLAIMPAID_STATUS
{
	Paid,
	Unpaid
}

/* ENUM_CLAIMPAID_STATUS - END */

/*
    ENUM_ANOMALY_CATEGORY - START
    Description : List of anomaly category.
    Author :  Eka Saputra.
    Created on : Sunday, 11 June 2023.           Updated on : Sunday, 11 June 2023.
    Created by : Eka Saputra.            			Updated by : Eka Saputra.
    Version : 1.0:1.
    */

export enum ENUM_ANOMALY_CATEGORY
{
	CustomerDoubleIndividual = 0,
	CustomerDoubleCorporate = 1,
	CustomerNotValid = 2,
	PolicyDoubleFire = 3,
	PolicyDoubleMarineCargo = 4,
	PolicyDoubleMotorVehicle = 5,
	ClaimAfterPolicyOver = 6,
	ClaimBeforePremiumPaid = 7,
	CommissionBiggerThanPremium = 8,
	ReinsuranceFacultativePayment = 9,
	MemberDebt = 10,
	ClaimTemporaryPost = 11,
	PremiumTemporaryPost = 12,
	NonStandardCommissionRate = 13,
	OutstandingHandling = 14,
	// PolicyWriteOff = 15,
}

/* ENUM_ANOMALY_CATEGORY - END */

/*
    ENUM_ANOMALY_TRACKING - START
    Description : List of anomaly status.
    Author :  Eka Saputra.
    Created on : Sunday, 11 June 2023.           Updated on : Sunday, 11 June 2023.
    Created by : Eka Saputra.            			Updated by : Eka Saputra.
    Version : 1.0:1.
    */

export enum ENUM_ANOMALY_TRACKING
{
	Assign = 0,
	Analysis = 1,
	FollowUpAnalysis = 2,
	Evaluation = 3,
	Complete = 4,
}

/* ENUM_ANOMALY_TRACKING - END */

/*
    ENUM_ANOMALY_ANALYSISMETHOD - START
    Description : List of anomaly analysis method.
    Author :  Eka Saputra.
    Created on : Sunday, 11 June 2023.           Updated on : Sunday, 11 June 2023.
    Created by : Eka Saputra.            			Updated by : Eka Saputra.
    Version : 1.0:1.
    */

export enum ENUM_ANOMALY_ANALYSISMETHOD
{
	Monitoring = 0,
	ObjectAudit = 1
}

/* ENUM_ANOMALY_ANALYSISMETHOD - END */

/*
    ENUM_ANOMALYGENERAL_STATUS - START
    Description : List of anomaly general status.
    Author :  Eka Saputra.
    Created on : Sunday, 11 June 2023.           Updated on : Sunday, 11 June 2023.
    Created by : Eka Saputra.            			Updated by : Eka Saputra.
    Version : 1.0:1.
    */

export enum ENUM_ANOMALYGENERAL_STATUS
{
    Adequate = 0,
    UnderMonitoring = 1,
    NotYetFollowUp = 2
}

/* ENUM_ANOMALYGENERAL_STATUS - END */

/*
    ENUM_ANOMALY_QUARTER - START
    Description : List of anomaly quarter.
    Author :  Eka Saputra.
    Created on : Monday, 12 June 2023.           	Updated on : Monday, 11 June 2023.
    Created by : Eka Saputra.            			Updated by : Eka Saputra.
    Version : 1.0:1.
    */

export enum ENUM_ANOMALY_QUARTER
{
	Q1 = 0,
	Q2 = 1,
	Q3 = 2,
	Q4 = 3
}

/* ENUM_ANOMALY_QUARTER - END */

/*
	ENUM_AUDITTEAM_ROLE - START
	Description : This is used for audit team role
	Author: Eka Saputra.
	Created on : Selasa, 06 September 2022.        		Updated on : -.
	Created by : Eka Saputra.						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_AUDITTEAM_ROLE
{
	AuditDepartment = 0,
	AuditGroup = 1
}

/* ENUM_AUDITTEAM_ROLE - END */


/*
	ENUM_CLAIMPROPOSALSALVAGE_BIDDINGTYPE - START
	Description : This is used for claim proposal salvage submission
	Author: Muhammad Nur Fauzi
	Created on : Selasa, 03 Oktober 2023.        		Updated on : -.
	Created by : Muhammad Nur Fauzi						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGE_BIDDINGTYPE
{
	MotorVehicle = 0,
    NonMotorVehicle = 1,
}

/* ENUM_CLAIMPROPOSALSALVAGE_BIDDINGTYPE - END */


/*
	ENUM_CLAIMPROPOSALSALVAGE_CLAIMPAYMENTSTATUS - START
	Description : This is used for claim proposal salvage submission
	Author: Muhammad Nur Fauzi
	Created on : Selasa, 03 Oktober 2023.        		Updated on : -.
	Created by : Muhammad Nur Fauzi						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGE_CLAIMPAYMENTSTATUS
{
	Paid = 0,
	Unpaid = 1,
}

/* ENUM_CLAIMPROPOSALSALVAGE_CLAIMPAYMENTSTATUS - END */


/*
	ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE - START
	Description : This is used for claim proposal salvage submission
	Author: Muhammad Nur Fauzi
	Created on : Selasa, 03 Oktober 2023.        		Updated on : -.
	Created by : Muhammad Nur Fauzi						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE
{
	SalvageReport = 0,
	SalvageCustodyLetter = 1,
	OriginalBPKB = 2,
	OriginalVehicleKey = 3,
	ReleaseOfRightsLetter = 4,
	TripleStampedReceipt = 5,
	OriginalVehicleInvoice = 6,
	SalvageBiddingApplicationFormFromTheInsured = 7,
	SalvagePhoto = 8,
	MiscellaneousDocument = 9,
}

/* ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE - END */


/*
	ENUM_CLAIMPROPOSALSALVAGE_STATUS - START
	Description : This is used for claim proposal salvage submission
	Author: Muhammad Nur Fauzi
	Created on : Kamis, 12 Oktober 2023.        		Updated on :  21 Februari 2024.
	Created by : Muhammad Nur Fauzi						Updated by : Rizqi Alfiyati.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGE_STATUS
{
	SalvageSubmittedByEBiddingBranchOfficeMaker = 0,
	SalvageRejectedByEBiddingBranchOfficeChecker = 1,
	SalvageApprovedByEBiddingBranchOfficeChecker = 2,
	SalvageRejectedByEBiddingBranchOfficeSigner = 3,
	SalvageApprovedByEBiddingBranchOfficeSignerForBranchOffice = 4,
	SalvageApprovedByEBiddingBranchOfficeSignerForHeadOffice = 5,
	OperationalGuidelinesSubmittedByEBiddingHeadOfficeMaker = 6,
	OperationalGuidelinesRejectedByEBiddingHeadOfficeChecker = 7,
	OperationalGuidelinesApprovedByEBiddingHeadOfficeChecker = 8,
	OperationalGuidelinesRejectedByEBiddingHeadOfficeFirstSigner = 9,
	OperationalGuidelinesApprovedByEBiddingHeadOfficeFirstSigner = 10,
	OperationalGuidelinesRejectedByEBiddingHeadOfficeSecondSigner = 11,
	OperationalGuidelinesApprovedByEBiddingHeadOfficeSecondSigner = 12,
	InvitationSubmittedByEBiddingBranchOfficeMaker = 13,
	InvitationRejectedByEBiddingBranchOfficeChecker = 14,
	InvitationApprovedByEBiddingBranchOfficeChecker = 15,
	InvitationRejectedByEBiddingBranchOfficeSigner = 16,
	InvitationApprovedByEBiddingBranchOfficeSignerForBranchOffice = 17,
	InvitationApprovedByEBiddingBranchOfficeSignerForHeadOffice = 18,
	WinnerDeterminationSubmittedByEBiddingHeadOfficeMaker = 19,
	WinnerDeterminationRejectedByEBiddingHeadOfficeChecker = 20,
	WinnerDeterminationApprovedByEBiddingHeadOfficeChecker = 21,
	WinnerDeterminationRejectedByEBiddingHeadOfficeFirstSigner = 22,
	WinnerDeterminationApprovedByEBiddingHeadOfficeFirstSigner = 23,
	WinnerDeterminationRejectedByEBiddingHeadOfficeSecondSigner = 24,
	WinnerDeterminationApprovedByEBiddingHeadOfficeSecondSigner = 25,
	WinnerLetterSubmittedByEBiddingBranchOfficeMaker = 26,
	WinnerLetterRejectedByEBiddingBranchOfficeChecker = 27,
	WinnerLetterApprovedByEBiddingBranchOfficeChecker = 28,
	WinnerLetterRejectedByEBiddingBranchOfficeSigner = 29,
	WinnerLetterApprovedByEBiddingBranchOfficeSigner = 30,
	SalvageCancelledByEBiddingHeadOfficeMaker = 31,
	WinnerDeterminationCancelledByEBiddingHeadOfficeMaker = 32,
	SalvageCancelledByEBiddingBranchOfficeMaker = 33,
	WinnerDeterminationCancelledByEBiddingBranchOfficeMaker = 34,
	OperationalGuidelinesRejectedByEBiddingHeadOfficeMaker = 35,
	SalvageInputDLABranchOfficeMaker = 36,
	InvitationAndAuctionTermsSubmittedByEBiddingBranchOfficeMaker = 37,
	InvitationAndAuctionTermsApprovedByEBiddingBranchOfficeChecker = 38


}


/* ENUM_CLAIMPROPOSALSALVAGE_STATUS - END */


/*
	ENUM_CLAIMPROPOSALSALVAGEBUYER_STATUS - START
	Description : This is used for claim proposal salvage buyer
	Author: Muhammad Nur Fauzi
	Created on : Jum'at, 03 November 2023.        		Updated on : -.
	Created by : Muhammad Nur Fauzi						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGEBUYER_STATUS
{
    SubmittedByEBiddingHeadOfficeMaker = 0,
    RejectedByEBiddingHeadOfficeChecker = 1,
    ApprovedByEBiddingHeadOfficeChecker = 2,
    RejectedByEBiddingHeadOfficeFirstSigner = 3,
    ApprovedByEBiddingHeadOfficeFirstSigner = 4,
    RejectedByEBiddingHeadOfficeSecondSigner = 5,
    ApprovedByEBiddingHeadOfficeSecondSigner = 6,
}


/* ENUM_CLAIMPROPOSALSALVAGEBUYER_STATUS - END */


/*
	ENUM_CLAIMPROPOSALSALVAGEBUYERAPPROVAL_STATUS - START
	Description : This is used for claim proposal salvage buyer
	Author: Muhammad Nur Fauzi
	Created on : Jum'at, 03 November 2023.        		Updated on : -.
	Created by : Muhammad Nur Fauzi						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGEBUYERAPPROVAL_STATUS
{
    SubmittedByEBiddingHeadOfficeMaker = 0,
    RejectedByEBiddingHeadOfficeChecker = 1,
    ApprovedByEBiddingHeadOfficeChecker = 2,
    RejectedByEBiddingHeadOfficeFirstSigner = 3,
    ApprovedByEBiddingHeadOfficeFirstSigner = 4,
    RejectedByEBiddingHeadOfficeSecondSigner = 5,
    ApprovedByEBiddingHeadOfficeSecondSigner = 6,
}

/* ENUM_CLAIMPROPOSALSALVAGEBUYERAPPROVAL_STATUS - END */


/*
	ENUM_CLAIMPROPOSALSALVAGEBUYERDOCUMENT_TYPE - START
	Description : This is used for claim proposal salvage buyer
	Author: Muhammad Nur Fauzi
	Created on : Jum'at, 03 November 2023.        		Updated on : -.
	Created by : Muhammad Nur Fauzi						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGEBUYERDOCUMENT_TYPE
{
    ApprovalDocument = 0,
}

/*
	ENUM_HISTORYSURVEY_STATUS - START
	Description : This is used for history survey status
	Author : Yozi Maulana
	Created on : Tuesday,05 Desember 2023.				Updated on : 26 Desember 2023.
	Created by : Rizqi Alfiyati							Updated by : Rizqi Alfiyati.
	Version : 1.0:0.
*/

export enum ENUM_HISTORYSURVEY_STATUS
{
	Pending = 0,
    Surveyed = 1,
    Canceled = 2,
    Verified = 3,
    ApprovedChecker = 4,
    Rejected = 5,
    Resurvey = 6,
    Saved = 7,
    Submitted = 8,
    Resurveyed = 9,
    ApprovedSigner = 10,
	VerifiedBranch = 11,
    VerifiedStaff = 12,
    ReviewSPV = 13,
    ApproveManager = 14,
    RejectManager = 15,
}

/* ENUM_CLAIMPROPOSALSALVAGEBUYERDOCUMENT_TYPE - END */


/*
	ENUM_CLAIMPROPOSALSALVAGEWINNERDOCUMENT_TYPE - START
	Description : This is used for claim proposal salvage winner determination & letter
	Author: Muhammad Nur Fauzi
	Created on : Monday, 04 Desember 2023.        		Updated on : -.
	Created by : Muhammad Nur Fauzi						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGEWINNERDOCUMENT_TYPE
{
    WinnerLetter = 0,
	EventNews = 1,
    MiscellaneousDocument = 2,
}

/* ENUM_CLAIMPROPOSALSALVAGEWINNERDOCUMENT_TYPE - END */


/*
	ENUM_CLAIMPROPOSALSALVAGEDOCUMENTDLA_TYPE - START
	Description : This is used for claim proposal salvage document dla
	Author: Rizqi Alfiyati
	Created on : Thursday, 13 Februari 2024.        		Updated on : -.
	Created by : Rizqi Alfiyati						Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSALVAGEDOCUMENTDLA_TYPE
{
	Payment = 0,
	Appointment = 1
}

/* ENUM_CLAIMPROPOSALSALVAGEDOCUMENTDLA_TYPE - END */


/*
	ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - START
	Description : This is used for satisfaction ticket
	Author : Yozi Maulana
	Created on : Monday, 12 February 2024.					Updated on : Monday, 12 February 2024.
	Created by : Eka Saputra								Updated by : Eka Saputra
	Version : 1.0:0.
*/

export enum ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE
{
	VeryDissatisfied = 0,
	NotSatisfied= 1,
	Satisfied= 2,
	VerySatisfied= 3,
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_CLAIMPROPOSALSURVEYSTATUS - START
	Description : This is used for filter search by status monitoring and report
	Author : Rizqi Alfiyati
	Created on : Monday, 04 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/

export enum ENUM_CLAIMPROPOSALSURVEYSTATUS
{
	Verified = 0,
	NotVerified = 1,
	RejectedByBranch = 2,
	PendingForHeadOffice = 3,
	PendingForCheckerHeadOffice = 4,
	PendingForSignerHeadOffice = 5,
	RejectedByHeadOffice = 6,
	PendingForSurvey = 7,
	CompletedWithoutSurvey = 8,
	CompletedWithSurvey = 9,
	Approved = 10,
	Rejected = 11,
	PendingForCorrection = 12,
	Submitted = 13,
	PendingForApprove = 14,
	PendingForCorrectionChecker = 15,
	PendingForCorrectionSigner = 16,
	Direct = 17,
	ApproveNotVerified = 18,
	RejectNotVerified = 19,
	WaitingApproveByAdjustment = 20,
	WaitingApproveBySupervisor = 21,
	WaitingApproveByManager = 22
}

/* ENUM_CLAIMPROPOSALSURVEYSTATUS - END */


/*
	ENUM_CLAIMITEM_ACTIONTYPE - START
	Description : This is used for type image items
	Author : Rizqi Alfiyati
	Created on : Monday, 04 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/

export enum ENUM_CLAIMITEM_ACTIONTYPE
{
	Approve = 0,
    Reject = 1,
}

/* ENUM_CLAIMITEM_ACTIONTYPE - END */


/*
	ENUM_CLAIMITEM_ACTIONSTATUS - START
	Description : This is used for action status item claim
	Author : Rizqi Alfiyati
	Created on : Monday, 04 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/

export enum ENUM_CLAIMITEM_ACTIONSTATUS
{
	Adjuster = 0,
    Supervisor = 1,
    Manager = 2,
}

/* ENUM_CLAIMITEM_ACTIONSTATUS - END */


/*
	ENUM_RENEWAL_STATUS - START
	Description : This is used for enum renewal status
	Author : Rizqi Alfiyati
	Created on : Thurday, 21 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/
export enum ENUM_RENEWAL_STATUS
{
	PendingForRenewalChecker = 0,
	PendingForRenewalSigner = 1,
	ApprovedForRenewal = 2,
	RejectedForRenewal = 3,
	Inforce = 4
}

/* ENUM_RENEWAL_STATUS - END */


/*
	ENUM_POLICYHOLDER_TYPE - START
	Description : This is used for enum policyholder type
	Author : Rizqi Alfiyati
	Created on : Thurday, 25 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/
export enum ENUM_POLICYHOLDER_TYPE
{
	Personal = 0,
	Corporate = 1
}

/* ENUM_POLICYHOLDER_TYPE - END */


/*
	ENUM_POLICYHOLDER_CLASS_TYPE - START
	Description : This is used for enum policyholder class type
	Author : Rizqi Alfiyati
	Created on : Thurday, 25 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/
export enum ENUM_POLICYHOLDER_CLASS_TYPE
{
	General = 0,
	VeryImportantPerson = 1,
	VeryVeryImportantPerson = 2
}

/* ENUM_POLICYHOLDER_CLASS_TYPE - END */


/*
	ENUM_POLICYHOLDER_GROUP_TYPE - START
	Description : This is used for enum policyholder group type
	Author : Rizqi Alfiyati
	Created on : Thurday, 25 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/
export enum ENUM_POLICYHOLDER_GROUP_TYPE
{
	General = 0,
    BRIGroup = 1
}

/* ENUM_POLICYHOLDER_GROUP_TYPE - END */


/*
	ENUM_PROFILE_TYPE - START
	Description : This is used for enum profile type
	Author : Rizqi Alfiyati
	Created on : Thurday, 25 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/
export enum ENUM_PROFILE_TYPE
{
	Captive = 0,
    NonCaptive = 1,
}

/* ENUM_PROFILE_TYPE - END */


/*
	ENUM_COMMISION_TARGET - START
	Description : This is used for enum commision target
	Author : Rizqi Alfiyati
	Created on : Thurday, 25 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/
export enum ENUM_COMMISION_TARGET
{
	Agent = 0,
	Broker = 1,
	BUSB = 2,
	Direct = 3
}

/* ENUM_COMMISION_TARGET - END */


/*
	ENUM_MANTLE - START
	Description : This is used for enum mantle
	Author : Rizqi Alfiyati
	Created on : Thurday, 27 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/
export enum ENUM_MANTLE
{
	Mantle = 0,
	NonMantle = 1
}

/* ENUM_MANTLE - END */


/*
	ENUM_PKS - START
	Description : This is used for enum pks
	Author : Rizqi Alfiyati
	Created on : Thurday, 27 March 2024.					Updated on : -
	Created by : Rizqi Alfiyati								Updated by : -
	Version : 1.0:0.
*/
export enum ENUM_PKS
{
	Active = 0,
    NonActive = 1
}

/* ENUM_PKS - END */

/*
	ENUM_KEYPERFORMANCE_STATUS - START
	Description : This is used for status key performance
	Author : Yozi Maulana
	Created on : Friday, 03 May 2024.					Updated on : Friday, 03 May 2024.
	Created by : Rizqi Alfiyati							Updated by : Eka Saputra
	Version : 1.0:0.
*/

export enum ENUM_KEYPERFORMANCE_STATUS
{
	Input = 0,
	SecondInput = 1,
	Submitted = 2,
	ApproveBranchManager = 3,
	RejectBranchManager = 4,
	ApproveRegionalOffice = 5,
	RejectRegionalOffice = 6,
	ApproveAdjuster = 7,
	RejectAdjuster = 8,
	ApproveChecker = 9,
	RejectAChecker = 10,
	ApproveSigner = 11,
	RejectSigner = 12,
	Revisi = 13,
}

/* ENUM_KEYPERFORMANCE_STATUS - END */


/*
	ENUM_RATIOHIGHLIGHT_POLICYTYPE - START
	Description : This is used for master key performance input
	Author: yozi Maulana
	Created on : Monday, 29 April 2024.        				Updated on : -.
	Created by : Rizqi Alfiyati				Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_MASTERKEYPERFORMANCE_INPUT
{
	Percentage = 0,
    Nominal = 1,
    Score = 2,
	Narrative = 3,
	MonthRealisasi = 4
}

/* ENUM_RATIOHIGHLIGHT_POLICYTYPE - END */


/*
	ENUM_MASTERKEYPERFORMANCE_TYPE - START
	Description : This is used for master key performance type
	Author: yozi Maulana
	Created on : Monday, 08 May 2024.        				Updated on : -.
	Created by : Rizqi Alfiyati				Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_MASTERKEYPERFORMANCE_TYPE
{
	Syariah = 0,
	Conventional = 1,
}

/* ENUM_MASTERKEYPERFORMANCE_TYPE - END */


/*
	ENUM_KEYPERFORMANCEAPPROVAL_STATUS - START
	Description : This is used for master key performance approval type
	Author: yozi Maulana
	Created on : Monday, 08 May 2024.        				Updated on : -.
	Created by : Rizqi Alfiyati				Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_KEYPERFORMANCEAPPROVAL_STATUS
{
	ApproveBranchManager = 0,
	RejectBranchManager = 1,
	ApproveAdjuster = 2,
	RejectAdjuster = 3,
	ApproveChecker = 4,
	RejectAChecker = 5,
	ApproveSigner = 6,
	RejectSigner = 7,
	ApproveRegionalOffice = 8,
	RejectRegionalOffice = 9,
}

/* ENUM_KEYPERFORMANCEAPPROVAL_STATUS - END */


/*
	ENUM_MASTERDATAAPUPPT_RISKLEVEL - START
	Description : Set of constants for Risk Level Based on Score.
	Author : Eka Saputra.
	Created on : Monday, 18 March 2024.          	Updated on : Monday, 18 March 2024
	Created by : Eka Saputra.				Updated by : Eka Saputra
	Version : 1.0:0.
*/

export enum ENUM_MASTERDATAAPUPPT_RISKLEVEL
{
	Low = 0,
	Medium = 1,
	High = 2,
}

/* ENUM_MASTERDATAAPUPPT_RISKLEVEL - END */


/*
	ENUM_COMPLIANCE_PARAMETER - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Eka Saputra.
	Created on : Thursday, 14 March 2024.          	Updated on : Thursday, 14 March 2024
	Created by : Eka Saputra.				Updated by : Eka Saputra
	Version : 1.0:0.
*/

export enum ENUM_COMPLIANCE_PARAMETER
{
	PEP = 0,
	DTTOT = 1,
	DPPSPM = 2
}

/* ENUM_COMPLIANCE_PARAMETER - END */


/*
	ENUM_CLAIM_TYPE - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Andri Septiawan.
	Created on : Wednesday, 24 June 2021.     	Updated on : Wednesday, 24 June 2021.
	Created by : Andri Septiawan.				Updated by : Andri Septiawan.
	Version : 1.0:0.
*/

export enum ENUM_CLAIM_STATE
{
	PendingForSurvey = 0,
	Adjustment = 1,
	Approved = 2,
	Rejected = 3,
	Paid = 4
}

/* ENUM_TICKET_TOPIC_TYPE - END */

/*
ENUM_PROJECTMANAGEMENT_STATUS - START
Description : This is used for project management
Author: yozi Maulana
Created on : Thursday, 29 July 2024.        				Updated on : -.
Created by : Rizqi Alfiyati				Updated by : -.
Version : 1.0:0.
*/

export enum ENUM_PROJECTMANAGEMENT_STATUS
{
Active = 0,
OnHold = 1,
Completed = 2,
Terminated = 3,
Canceled = 4,
PendingApproval = 5,
Closed = 6,
}

/* ENUM_PROJECTMANAGEMENT_STATUS - END */


/*
ENUM_PROJECTMANAGEMENT_STATUAPPROVAL - START
Description : This is used for project management status approval
Author: yozi Maulana
Created on : Thursday, 29 July 2024.        				Updated on : -.
Created by : Rizqi Alfiyati				Updated by : -.
Version : 1.0:0.
*/

export enum ENUM_PROJECTMANAGEMENT_STATUAPPROVAL
{
Submit = 0,
ApproveProjectChecker = 1,
ReviseProjectChecker = 2,
ApprovedProject = 3,
ReviseProjectSigner = 4,
SubmitProgress = 5,
ApproveProgressChecker = 6,
ReviseProgressChecker = 7,
ApprovedProgress = 8,
ReviseProgressSigner = 9,
}

/* ENUM_PROJECTMANAGEMENT_STATUAPPROVAL - END */


/*
ENUM_PROJECTMANAGEMENT_STATUSPROGRESS - START
Description : This is used for project management status progress
Author: yozi Maulana
Created on : Thursday, 29 July 2024.        				Updated on : -.
Created by : Rizqi Alfiyati				Updated by : -.
Version : 1.0:0.
*/

export enum ENUM_PROJECTMANAGEMENT_STATUSPROGRESS
{
OnTrack = 0,
IndicationofDelayed = 1,
SpecialMention = 2
}


/* ENUM_PROJECTMANAGEMENT_STATUSPROGRESS - END */


/*
ENUM_PROJECTMANAGEMENTTIMELINE_STATUS - START
Description : This is used for project management timeline
Author: yozi Maulana
Created on : Thursday, 29 July 2024.        				Updated on : -.
Created by : Rizqi Alfiyati				Updated by : -.
Version : 1.0:0.
*/

export enum ENUM_PROJECTMANAGEMENTTIMELINE_STATUS
{
Done = 0,
Delayed = 1,
InProgress = 2,
Notyet = 3,
IndicationofDelay = 4
}

/* ENUM_PROJECTMANAGEMENTTIMELINE_STATUS - END */


/*
ENUM_PROJECTMANAGEMENT_PROJECTTYPE - START
Description : This is used for project management timeline
Author: yozi Maulana
Created on : Thursday, 29 July 2024.        				Updated on : -.
Created by : Rizqi Alfiyati				Updated by : -.
Version : 1.0:0.
*/

export enum ENUM_PROJECTMANAGEMENT_PROJECTTYPE
{
StrategicInitiatives = 0,
CorporateInitiatives = 1,
BoardofDirectorsDirectives = 2
}

/* ENUM_PROJECTMANAGEMENT_PROJECTTYPE - END */


/*
ENUM_PROJECTMANAGEMENTDOCUMENT_TYPE - START
Description : This is used for project management document type
Author: yozi Maulana
Created on : Thursday, 29 July 2024.        				Updated on : -.
Created by : Rizqi Alfiyati				Updated by : -.
Version : 1.0:0.
*/

export enum ENUM_PROJECTMANAGEMENTDOCUMENT_TYPE
{
ProjectCharter = 0,
ValueTracking = 1,
FeasibilityStudy = 2,
WorkBreakdownStructure = 4,
ChangeRequest = 5,
IzinPrinsip = 6,
BRS = 7,
BAST = 8,
PMOSC = 9,
Notulen = 10,
PostImplementationReview = 11,
Others = 12
}

/* ENUM_PROJECTMANAGEMENTDOCUMENT_TYPE - END */


/*
ENUM_PROJECTMANAGEMENTDOCUMENT_STATUS - START
Description : This is used for project management document status
Author: yozi Maulana
Created on : Thursday, 29 July 2024.        				Updated on : -.
Created by : Rizqi Alfiyati				Updated by : -.
Version : 1.0:0.
*/

export enum ENUM_PROJECTMANAGEMENTDOCUMENT_STATUS
{
Submit = 0,
Updated = 1,
}

/* ENUM_PROJECTMANAGEMENTDOCUMENT_STATUS - END */


/*

ENUM_EARLYWARNINGSIGNAL_PARAMETERTYPE - START
Description : This is used for parameter early warning signal risk
Author : Eka Saputra
Created on : Monday, 12 February 2024.					Updated on : Monday, 12 February 2024.
Created by : Eka Saputra								Updated by : Eka Saputra
Version : 1.0:0.
*/

export enum ENUM_EARLYWARNINGSIGNAL_PARAMETERTYPE
{
HeadOffice = 0,
BranchOffice= 1,
HeadAndBranchOffice = 2,
}

/* ENUM_EARLYWARNINGSIGNAL_PARAMETERTYPE - END */


/*
ENUM_EARLYWARNINGSIGNAL_FOLLOWUPAPPROVALSTATUS - START
Description : This is used for parameter early warning signal status
Author : Eka Saputra
Created on : Monday, 12 February 2024.					Updated on : Monday, 12 February 2024.
Created by : Eka Saputra								Updated by : Eka Saputra
Version : 1.0:0.
*/

export enum ENUM_EARLYWARNINGSIGNAL_FOLLOWUPAPPROVALSTATUS
{
SubmittedByMaker = 0,
RejectedBySigner = 1,
ApprovedBySigner = 2
}

/* ENUM_EARLYWARNINGSIGNAL_FOLLOWUPAPPROVALSTATUS - END */


/*
ENUM_EARLYWARNINGSIGNAL_FOLLOWUPSTATUS - START
Description : This is used for parameter early warning signal approval status
Author : Eka Saputra
Created on : Monday, 12 February 2024.					Updated on : Monday, 12 February 2024.
Created by : Eka Saputra								Updated by : Eka Saputra
Version : 1.0:0.
*/

export enum ENUM_EARLYWARNINGSIGNAL_FOLLOWUPSTATUS
{
Created = 0,
SubmittedByMaker = 1,
RejectedBySigner = 2,
ApprovedBySigner = 3
}

/* ENUM_EARLYWARNINGSIGNAL_FOLLOWUPSTATUS - END */


/*
ENUM_EARLYWARNINGSIGNAL_PARAMETERUNIT - START
Description : This is used for parameter early warning signal parameter unit
Author : Eka Saputra
Created on : Monday, 12 February 2024.					Updated on : Monday, 12 February 2024.
Created by : Eka Saputra								Updated by : Eka Saputra
Version : 1.0:0.
*/

export enum ENUM_EARLYWARNINGSIGNAL_PARAMETERUNIT
{
Percentage = 0,
Value = 1,
}

/* ENUM_EARLYWARNINGSIGNAL_PARAMETERUNIT - END */


/*
ENUM_EARLYWARNINGSIGNAL_PARAMETERORDER - START
Description : This is used for parameter early warning signal parameter order
Author : Eka Saputra
Created on : Monday, 12 February 2024.					Updated on : Monday, 12 February 2024.
Created by : Eka Saputra								Updated by : Eka Saputra
Version : 1.0:0.
*/

export enum ENUM_EARLYWARNINGSIGNAL_PARAMETERORDER
{
Ascending = 0,
Descending = 1
}

/* ENUM_EARLYWARNINGSIGNAL_PARAMETERORDER - END */


/*
	ENUM_CATEGORY_TYPE - START
	Description : Set of constants for Brins TAX CATEGORY.
	Author : Rafi Indrajati.
	Created on : Wed, 24 July 2024.          	Updated on : Wed, 24 July 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/

export enum ENUM_CATEGORY_TYPE
{
	BM = 0, // Bea Materai
	BPHTB = 1, // Bea Perolehan Hak atas Tanah dan Bangunan
	BPHTB_Lainnya = 2, // Bea Perolehan Hak atas Tanah dan Bangunan Lainnya
	KUP = 3, // Ketentuan Umum Perpajakan Lainnya
	PBB = 4, // Pajak Bumi dan Bangunan
	PPH = 5, // Pajak Penghasilan
	PPN = 6 // Pajak Pertambahan Nilai
}

/* 	ENUM_CATEGORY_TYPE - END */


/*
	ENUM_DOCUMENT_TYPE - START
	Description : Set of constants for Brins TAX DOCUMENT.
	Author : Rafi Indrajati.
	Created on : Wed, 24 July 2024.          	Updated on : Wed, 24 July 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/

export enum ENUM_DOCUMENT_TYPE
{
	NotaDinasDirekturJenderalPajak = 0,
	PeraturanDirjenBeaDanCukai = 1,
	SuratDirjenAnggaran = 2,
	SuratDirjenBeaDanCukai = 3,
	SuratDirjenPerbendaharaan = 4,
	InstruksiDirjenPajak = 5,
	PeraturanMenteriPerdagangan = 6,
	PeraturanMenteriPerindustrian = 7,
	PeraturanPemerintah = 8,
	PeraturanPresiden = 9,
	Perpu = 10,
	PeraturanMenteriDalamNegeri = 11,
	UndangUndangDasar = 12,
	PeraturanMenteriKeuangan = 13,
	PeraturanLainnya = 14,
	InstruksiMenteriKeuangan = 15,
	KeputusanMenteriPerdagangan = 16,
	InstruksiPresiden = 17,
	KeputusanBersamaDirjen = 18,
	KeputusanBersamaMenteri = 19,
	KeputusanDirjenBeaDanCukai = 20,
	KeputusanDirjenPajak = 21,
	KeputusanDirjenPerbendaharaan = 22,
	KeputusanKetuaPengadilanPajak = 23,
	KeputusanMenteriDalamNegeri = 24,
	KeputusanMenteriKeuangan = 25,
	KeputusanMenteriPerindustrian = 26,
	PeraturanDirjenPerdaganganLuarNegeri = 27,
	KeputusanMenteriTenagaKerja = 28,
	KeputusanPresiden = 29,
	Pengumuman = 30,
	PeraturanBadanKoordinasiDanPenanamanModal = 31,
	PeraturanBersamaDirjen = 32,
	PeraturanBersamaMenteri = 33,
	PeraturanDaerah = 34,
	PeraturanDirjenPajak = 35,
	PeraturanDirjenPerbendaharaan = 36,
	UndangUndang = 37
}

/* 	ENUM_DOCUMENT_TYPE - END */

/*
    ENUM_TAXREPORT_APPROVAL - START
    Description : Set of constants for Brins TAX REPORT APPROVAL.
    Author : Rafi Indrajati.
    Created on : Thu, 7 August 2024.              Updated on : Thu, 7 August 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/

export enum ENUM_TAXREPORT_APPROVAL
{
	CheckerPending = 0,
	ApproveChecker = 1,
	RejectChecker = 2,
	ApproveSigner = 3,
	RejectSigner = 4,
}

/* ENUM_TAXREPORT_APPROVAL - END


/*
    ENUM_TAXREPORT_RECEIVER - START
    Description : Set of constants for Brins TAX REPORT RECEIVER.
    Author : Rafi Indrajati.
    Created on : Thu, 7 August 2024.              Updated on : Thu, 7 August 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/
export enum ENUM_TAXREPORT_RECEIVER
{
	Agent = 0,
	Broker = 1,
	Workshop = 2,
	Tender = 3,
	Vendor = 4,
}

/* ENUM_TAXREPORT_RECEIVER - END


/*
     ENUM_TAXDOCUMENT_TYPE - START
    Description : Set of constants for Brins TAX DOCUMENT TYPE.
    Author : Rafi Indrajati.
    Created on : Thu, 7 August 2024.              Updated on : Thu, 7 August 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/

export enum ENUM_TAXDOCUMENT_TYPE
{
	BuktiPotong = 0,
	BuktiLapor = 1,
	SPT = 2,
	SuratKeteranganFiskal = 3
}

/*  ENUM_TAXDOCUMENT_TYPE - END

/*
     ENUM_MONTH_TYPE - START
    Description : Set of constants for Brins TAX MONTH TYPE.
    Author : Rafi Indrajati.
    Created on : Thu, 15 August 2024.              Updated on : Thu, 15 August 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/

export enum ENUM_MONTH_TYPE
{
	January = 1,
	February = 2,
	March = 3,
	April = 4,
	May = 5,
	June = 6,
	July = 7,
	August = 8,
	September = 9,
	October = 10,
	November = 11,
	December = 12
}


/*  ENUM_MONTH_TYPE - END

/*
     ENUM_YEAR_TYPE - START
    Description : Set of constants for Brins TAX YEAR TYPE.
    Author : Rafi Indrajati.
    Created on : Thu, 15 August 2024.              Updated on : Thu, 15 August 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/

export enum ENUM_YEAR_TYPE
{
    TA_2022 = 2022,
    TA_2023 = 2023,
	TA_2024 = 2024
}

/*  ENUM_YEAR_TYPE - END

/*
     ENUM_PIC_TYPE - START
    Description : Set of constants for Brins TAX PIC TYPE.
    Author : Rafi Indrajati.
    Created on : Thu, 15 August 2024.              Updated on : Thu, 15 August 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/

export enum ENUM_PIC_TYPE
	{
		CLAIM = "KLAIM",
		DOB = "DOB",
		LOGISTIK = "LOGISTIK",
		SKP = "SKP",
		WHOLESALE = "BISNIS1",
		HC = "HC",
	}

/*  ENUM_PIC_TYPE - END

/*
     ENUM_TAXREQUEST_STATUS - START
    Description : Set of constants for Brins TAX STATUS TYPE.
    Author : Rafi Indrajati.
    Created on : Thu, 15 August 2024.              Updated on : Thu, 15 August 2024
	Created by : Rafi Indrajati.				Updated by : Rafi Indrajati
	Version : 1.0:0.
*/

export enum ENUM_TAXREQUEST_STATUS
{
    OnRequest = 0,
	ReadyToDownload = 1
}

/*  ENUM_TAXREQUEST_STATUS - END

 - END */



//#endregion
//#region IMPORT

import { ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE, ENUM_SURVEY_STATUS, ENUM_CLAIMITEM_TYPE, ENUM_CLAIMITEM_ACTIONSTATUS } from "../constants/enum.constant";
import { NumberConstant } from "../constants/number.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { PartnershipWorkshopModel } from "./partnershipworkshop.model";
import { ResponseModel } from "./response.model";
import { SurveyGalleryModel } from "./surveygallery.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class SurveyModel extends BaseModel
{
	ID?: number;
	Token?: string;
	INO?: number;
	Status?: ENUM_SURVEY_STATUS;

	ContactPersonAddress?: string;
	ContactPersonEmail?: string;
	ContactPersonName?: string;
	ContactPersonMobile?: string;
	ContactPersonMobilePhone?: string;

	InsuredSurveyLocation?: string;
	LegalResponsibilityLiability?: ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE;

	ClaimProposalID?: number;

	VehicleBrandID?: number;
	VehicleBrandCode?: number;
	VehicleBrandName?: string;

	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: string;

	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;

	WorkshopID?: number;
	WorkshopName?: string;
	WorkshopAddress?: string;
	WorkshopEmail?: string;
	WorkshopMobilePhone?: string;
	WorkshopNote?: string;
	WorkshopVisitOn?: Date;

	OwnRetention?: number;
	OwnRetentionAmount?: number;
	OwnRetentionHeadOffice?: number;
	OwnRetentionAmountHeadOffice?: number;
	OwnRetentionHeadOfficeBy?: number;
	OwnRetentionHeadOfficeOn?: number;

	Note?: string;
	NotedOn?: Date;
	NotedBy?: number;
	modelUserNoted?: UserModel;

	ApprovedNote?: string;
	ApprovedOn?: string;
	ApprovedBy?: number;
	modelUserApproved?: UserModel;

	RejectedNote?: string;
	RejectedOn?: string;
	RejectedBy?: number;
	modelUserRejected?: UserModel;

	ResurveyNote?: string;
	ResurveyedOn?: string;
	ResurveyedBy?: number;
	modelUserResurveyed?: UserModel;

	VerifyNote?: string;
	VerifiedOn?: string;
	VerifiedBy?: number;
	modelUserVerified?: UserModel;

	MakerHeadOfficeReviewedNote?: string;
	MakerHeadOfficeReviewedBy?: number;
	MakerHeadOfficeReviewedOn?: string;
	MakerHeadOfficeReviewedStatus?: ENUM_SURVEY_STATUS;

	CheckerHeadOfficeReviewedNote?: string;
	CheckerHeadOfficeReviewedBy?: number;
	CheckerHeadOfficeReviewedOn?: string;
	CheckerHeadOfficeReviewedStatus?: ENUM_SURVEY_STATUS;

	HeadOfficeReviewedNote?: string;
	HeadOfficeReviewedBy?: number;
	HeadOfficeReviewedOn?: string;
	HeadOfficeReviewedStatus?: ENUM_SURVEY_STATUS;

	ClaimLetterConfirmation?: boolean;
	ClaimLetterToken?: string;
	ClaimLetterTokenExpired?: Date;
	ClaimLetterTokenFailure?: number;
	ClaimLetterTokenResendOn?: Date;
	ClaimLetterTokenSubmitOn?: Date;
	ChronologyConfirmation?: boolean;
	ChronologyToken?: string;
	ChronologyTokenExpired?: Date;
	ChronologyTokenFailure?: number;
	ChronologyTokenResendOn?: Date;
	ChronologyTokenSubmitOn?: Date;

	ServiceAmount?: number;
	SalvageAmount?: number;
	TowingAmount?: number;
	VehicleReplacementAmount?: number;
	PersonalAccidentDriverAmount?: number;
	PersonalAccidentPassengerAmount?: number;
	TaxAmount?: number;
	TaxPercentage?: number;
	PartAmount?: number;
	TotalService?: number;
	CascoAmount?: number;
	TotalClaimAmount?: number;

	ApprovalNote?: string;

	AdditionalCost?: number;

	modelPartnershipWorkshop?: PartnershipWorkshopModel;
	listModelSurveyGallery?: Array<SurveyGalleryModel>;

	constructor()
	{
		super();
		this.listModelSurveyGallery = [];
		this.modelPartnershipWorkshop = new PartnershipWorkshopModel();
	}

	//#region FUNCTION


	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateApproveSurvey(modelListSurvey: SurveyModel): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		let booleanActionType: boolean = false;
		let numberItems: number = 1;

		if(modelListSurvey.listModelSurveyGallery)
		{
			for(const modelSurveyGallery of modelListSurvey.listModelSurveyGallery)
			{
				if (modelSurveyGallery.Type && ( modelSurveyGallery.Type >= ENUM_PHOTO_TYPE.FrontBumperLeft && modelSurveyGallery.Type <= ENUM_PHOTO_TYPE.VelFrontRight || modelSurveyGallery.Type >= ENUM_PHOTO_TYPE.EngineHood ) || modelSurveyGallery.Type === ENUM_PHOTO_TYPE.Other)
				{
					if(modelSurveyGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Supervisor || modelSurveyGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Manager)
					{
						booleanActionType = true;
					}
					else
					{
						booleanActionType = false;
						break;
					}
					numberItems++;
				}
			}
		}
		else
		{
		}

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(booleanActionType === false)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Photos number " +numberItems +" must be approved or rejected";
		}
		else if(this.ApprovedNote === undefined || this.ApprovedNote === null || this.ApprovedNote === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateResurvey(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(this.ResurveyNote == null || this.ResurveyNote === undefined || this.ResurveyNote === "")
		{
			modelResponse.MessageContent = "Note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateRejectSurvey(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(this.RejectedNote == null || this.RejectedNote === undefined || this.RejectedNote === "")
		{
			modelResponse.MessageContent = "Note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateVerifySurvey(modelListSurvey: SurveyModel): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		let booleanActionType: boolean = false;
		let numberItem: number = 1;

		if(modelListSurvey.listModelSurveyGallery)
		{
			for(const modelSurveyGallery of modelListSurvey.listModelSurveyGallery)
			{
				if (modelSurveyGallery.Type && ( modelSurveyGallery.Type >= ENUM_PHOTO_TYPE.FrontBumperLeft && modelSurveyGallery.Type <= ENUM_PHOTO_TYPE.VelFrontRight || modelSurveyGallery.Type >= ENUM_PHOTO_TYPE.EngineHood ) || modelSurveyGallery.Type === ENUM_PHOTO_TYPE.Other)
				{
					if(modelSurveyGallery.ActionType !== null && modelSurveyGallery.ActionType !== undefined)
					{
						booleanActionType = true;
					}
					else
					{
						booleanActionType = false;
						break;
					}
					numberItem++;
				}
			}
		}
		else
		{
		}

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(modelListSurvey.OwnRetentionHeadOffice === undefined || modelListSurvey.OwnRetentionHeadOffice === null )
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Own Retention Head Office value can’t be empty";
		}
		else if(this.VerifyNote == null || this.VerifyNote === undefined || this.VerifyNote === "")
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Note cannot be empty.";
		}
		else if(booleanActionType === false)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Photos number "+numberItem+" must be approved or rejected";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateforEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Workshop");
		const regExpEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.WorkshopEmail == null && this.WorkshopEmail === undefined && this.WorkshopEmail !== "")
		{
			modelResponse.MessageContent = "Workshop\'s email cannot be empty";
		}
		else if (this.WorkshopEmail && !regExpEmail.test(this.WorkshopEmail))
		{
			modelResponse.MessageContent = "Workshop\'s email format is incorrect.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateOwnRetentionHeadOffice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Own Retention Head Office";

		if(this.Token === null || this.Token === undefined)
		{
			modelResponse.MessageContent = "Token tidak boleh kosong";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else if(this.OwnRetentionHeadOffice === null || this.OwnRetentionHeadOffice === undefined)
		{
			modelResponse.MessageContent = "Own Retention Head Office tidak boleh kosong";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else if(this.OwnRetentionAmountHeadOffice === null || this.OwnRetentionAmountHeadOffice === undefined)
		{
			modelResponse.MessageContent = "Own Retention Amount Head Office";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	getCascoAmount(numberTaxPercent: number): void
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const enumClaimItemType = ENUM_CLAIMITEM_TYPE;
		this.PartAmount = NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		this.ServiceAmount = NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		this.SalvageAmount = NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		this.TaxPercent = numberTaxPercent;

		this.listModelSurveyGallery?.forEach(modelSurveyGallery =>
		{
			if(modelSurveyGallery.Type !== undefined && modelSurveyGallery.CostEstimationAmount !== undefined && this.ServiceAmount !== undefined && this.PartAmount !== undefined)
			{
				if(modelSurveyGallery.Type >= enumPhotoType.FrontBumperLeft && modelSurveyGallery.Type <= enumPhotoType.VelFrontRight || modelSurveyGallery.Type >= enumPhotoType.EngineHood || modelSurveyGallery.Type === enumPhotoType.Other)
				{
					if(modelSurveyGallery.ClaimItemType === enumClaimItemType.Repair && modelSurveyGallery.CostEstimationAmount !== undefined)
					{
						this.ServiceAmount = this.ServiceAmount + modelSurveyGallery.CostEstimationAmount;
					}
					else if(modelSurveyGallery.ClaimItemType === enumClaimItemType.Replace && modelSurveyGallery.CostEstimationAmount !== undefined)
					{
						this.PartAmount = this.PartAmount + modelSurveyGallery.CostEstimationAmount;
					}
					else
					{
						this.PartAmount = this.PartAmount + modelSurveyGallery.CostEstimationAmount;

						if(modelSurveyGallery.SalvageEstimationAmount && this.SalvageAmount != null)
						{
							this.SalvageAmount = this.SalvageAmount + modelSurveyGallery.SalvageEstimationAmount;
						}
					}
				}
			}
		});

		this.TaxAmount = (this.ServiceAmount + this.PartAmount) * numberTaxPercent;
		this.CascoAmount = (this.ServiceAmount + this.PartAmount + this.TaxAmount) - this.SalvageAmount;

	}

	validateUpdateWorkshop(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.Token === null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty";
		}
		else if (this.WorkshopName === null || this.WorkshopName === undefined || this.WorkshopName === "")
		{
			modelResponse.MessageContent = "Workshop name cannot be empty.";
		}
		else if (this.WorkshopVisitOn === null || this.WorkshopVisitOn === undefined)
		{
			modelResponse.MessageContent = "Workshop date cannot be empty.";
		}
		else if (this.WorkshopNote === null || this.WorkshopNote === undefined || this.WorkshopNote === "")
		{
			modelResponse.MessageContent = "Workshop note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	setNumberToPercentage(numberTaxPercent: number): number
	{
		if(numberTaxPercent == null || numberTaxPercent === undefined)
		{
			return NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		}
		else
		{
			return numberTaxPercent * NumberConstant.NUMBER_VALUE_GENERAL_ONE_HUNDRED;
		}
	}

	setOwnRetentionValue(): number
	{
		let numberOwnRetention: number;
		let numberOwnRetentionAmount: number;
		if(this.OwnRetention)
		{
			numberOwnRetention = this.OwnRetention;
		}
		else
		{
			numberOwnRetention = 1;
		}

		if(this.OwnRetentionAmount)
		{
			numberOwnRetentionAmount = this.OwnRetentionAmount;
		}
		else
		{
			numberOwnRetentionAmount = 0;
		}

		return (numberOwnRetentionAmount/numberOwnRetention);
	}

	setRetentionValueHeadOffice(): number
	{

		let numberOwnRetention: number;
		let numberOwnRetentionAmountHeadOffice: number;
		if(this.OwnRetention)
		{
			numberOwnRetention = this.OwnRetention;
		}
		else
		{
			numberOwnRetention = 1;
		}

		if(this.OwnRetentionAmountHeadOffice)
		{
			numberOwnRetentionAmountHeadOffice = this.OwnRetentionAmountHeadOffice;
		}
		else
		{
			numberOwnRetentionAmountHeadOffice = 0;
		}

		return (numberOwnRetentionAmountHeadOffice/numberOwnRetention);
	}

	//#endregion FUNCTION

}

//#endregion
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

//#region IMPORT

// import { MatDialog} from "@angular/material/dialog";
import { Location } from "@angular/common";
import { Component, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseAuthourizeDetailComponent } from "src/app/components/bases/baseauthourizedetail.component";
import { environment } from "src/environments/environment";
import { ENUM_CLAIMITEM_ACTIONTYPE, ENUM_CLAIMITEM_TYPE, ENUM_CLAIMPROPOSAL_STATUS, ENUM_CLAIMPROPOSAL_STATUS_ADJUSTER, ENUM_CLAIMPROPOSAL_STATUS_NOTIFICATIONLOSSADVICE, ENUM_CLAIMPROPOSAL_TRACKING_PHASE, ENUM_CLAIM_CHANNEL, ENUM_CLAIM_COVERAGE, ENUM_GENDER_TYPE, ENUM_HISTORYSURVEY_STATUS, ENUM_INCIDENT_TYPE, ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE, ENUM_REVIEW_STATE, ENUM_SURVEY_STATUS } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { RuleConstant } from "src/app/constants/rule.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToClaimURL, ConvertToProductURL } from "src/app/functions/pipes/hyperlink.pipe";
import { TableModel } from "src/app/models/bases/table.model";
import { ClaimProposalModel } from "src/app/models/claimproposal.model";
import { ClaimProposalAppealGalleryModel } from "src/app/models/claimproposalappealgallery.model";
import { ClaimProposalGalleryModel } from "src/app/models/claimproposalgallery.model";
import { CustomerModel } from "src/app/models/customer.model";
import { DivisionModel } from "src/app/models/division.model";
import { PaymentModel } from "src/app/models/payment.model";
import { PolicyGeneralModel } from "src/app/models/policygeneral.model";
import { ResponseModel } from "src/app/models/response.model";
import { SurveyModel } from "src/app/models/survey.model";
import { SurveyGalleryModel } from "src/app/models/surveygallery.model";
import { SurveyThirdPartyLiabilityModel } from "src/app/models/surveythirdpartyliability.model";
import { SurveyThirdPartyLiabilityGalleryModel } from "src/app/models/surveythirdpartyliabilitygallery.model";
import { ClaimProposalService } from "src/app/services/claimproposal.service";
import { CustomerService } from "src/app/services/customer.service";
import { PaymentService } from "src/app/services/payment.service";
import { PolicyGeneralService } from "src/app/services/policygeneral.service";
import { SessionService } from "src/app/services/session.service";
import { ClaimProposalQualityControlModel } from "src/app/models/claimproposalqualitycontrol.model";
import { UserModel } from "src/app/models/user.model";
import { ProductGeneralService } from "src/app/services/productgeneral.service";
import { ClaimProposalQualityControlGalleryModel } from "src/app/models/claimproposalqualitycontrolgallery.model";
import { ProductOtoGalleryModel } from "src/app/models/productotogallery.model";
import { ProductOtoSyariahGalleryModel } from "src/app/models/productotosyariahgallery.model";
import { ProductIndonesianMotorVehicleInsuranceStandardPolicyGalleryModel } from "src/app/models/productindonesianmotorvehicleinsurancestandardpolicygallery.model";
import { ProductIndonesianMotorVehicleInsuranceStandardPolicySyariahGalleryModel } from "src/app/models/productindonesianmotorvehicleinsurancestandardpolicysyariahgallery.model";
import { ProductMotorcycleGalleryModel } from "src/app/models/productmotorcyclegallery.model";
import { StoredClaimDetailModel } from "src/app/models/storedclaimdetail.model";
import { ProductMotorcycleSyariahGalleryModel } from "src/app/models/productmotorcyclesyariahgallery.model";
import { ProductMotorVehicleCreditGalleryModel } from "src/app/models/productmotorvehiclecreditgallery.model";
import { ProductMotorVehicleCreditSyariahGalleryModel } from "src/app/models/productmotorvehiclecreditsyariahgallery.model";
import { ProductOtoMaximumProtectionGalleryModel } from "src/app/models/productotomaximumprotectiongallery.model";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { ENUM_LETTER_ROLE, ENUM_QUESTION_FOR, ENUM_WORKSHOP_TYPE } from "../../../constants/enum.constant";
import { ClaimProposalSatisfactionModel } from "../../../models/claimproposalsatisfaction.model";
import { ClaimProposalSatisfactionQuestionModel } from "../../../models/claimproposalsatisfactionquestion.model";
import { ClaimProposalTrackingModel } from "../../../models/claimproposaltracking.model";
import { ClaimProposalTrackingGalleryModel } from "../../../models/claimproposaltrackinggallery.model";
import { ClaimproposaltrackingService } from "../../../services/claimproposaltracking.service";
import { SatisfactionsurveyquestionService } from "../../../services/satisfactionsurveyquestion.service";
import { SatisfactionsurveyService } from "../../../services/satisfactionsurvey.service";
import { TableControlIncludeComponent } from "../../includes/tablecontrol.include/tablecontrol.include.component";


//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-monitoringdetail",
		templateUrl: "./monitoringdetail.component.html",
		styleUrls: ["./monitoringdetail.component.sass"]
	}
)

//#endregion


//#region CLASS

export class MonitoringDetailComponent extends BaseAuthourizeDetailComponent
{

	//#region DECLARATION

	@ViewChildren("fileAttachment") fileAttachment;
	@ViewChild(TableControlIncludeComponent) private _componentTableControlIncludePayment!: TableControlIncludeComponent;
	public _modelPolicyGeneral: PolicyGeneralModel;
	public _modelPolicyGeneralRequest: PolicyGeneralModel;
	public _modelClaimProposal: ClaimProposalModel;
	public _modelClaimProposalRequest: ClaimProposalModel;
	public _modelClaimProposalForDetailSatisfaction: ClaimProposalModel;
	public _modelClaimProposalTracking: ClaimProposalTrackingModel;
	public _modelClaimProposalTrackingUpload: ClaimProposalTrackingModel;
	public _modelClaimProposalTrackingForInsert: ClaimProposalTrackingModel;
	public _modelClaimProposalTrackingPhaseThree: ClaimProposalTrackingModel;
	public _modelClaimProposalTrackingPhaseThreeForSatisfactionLetter: ClaimProposalTrackingModel;
	public _modelClaimProposalTrackingPhaseThreeForVehicleOut: ClaimProposalTrackingModel;
	public _modelClaimProposalTrackingPhaseTwo: ClaimProposalTrackingModel;
	public _modelClaimProposalSatisfactionSurvey: ClaimProposalSatisfactionModel;
	public _modelClaimProposalTrackingGallery: ClaimProposalTrackingGalleryModel;
	public _modelCustomer: CustomerModel;
	public _modelSurvey: SurveyModel;
	public _modelSurveyForNonAuthorize: SurveyModel;
	public _modelPayment: PaymentModel;
	public _modelSurveyForEmail: SurveyModel;
	public _modelProductGeneral: ProductGeneralModel;

	public _modelClaimProposalSatisfactionSurveyForBRINSFeedbackRequest: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyForWorkshopFeedbackRequest: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyForRecomendationItemFastService: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyForRecomendationItemOther: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyBRINSFeedback: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyWorkshopFeedback: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyBRINSQuestionOne: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyBRINSQuestionTwo: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyBRINSQuestionThree: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyWorkshopQuestionOne: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyWorkshopQuestionTwo: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyWorkshopQuestionThree: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyWorkshopQuestionFour: ClaimProposalSatisfactionModel;
	public _modelClaimProposalSatisfactionSurveyRecomendationFeedback: ClaimProposalSatisfactionModel;

	public _modelClaimProposalSatisfactionQuestionForRecomendationFeedback: ClaimProposalSatisfactionQuestionModel;
	public _modelClaimProposalQualityControl: ClaimProposalQualityControlModel;
	public _modelUserSurveyor: UserModel;
	public _modelStoredClaimDetail: StoredClaimDetailModel;

	public _enumGender = ENUM_GENDER_TYPE;
	public _enumIncidentType = ENUM_INCIDENT_TYPE;
	public _enumClaimProposalStatus = ENUM_CLAIMPROPOSAL_STATUS;
	public _enumPhotoType = ENUM_PHOTO_TYPE;
	public _enumReviewState = ENUM_REVIEW_STATE;
	public _enumLegalResponsibilityLiabilityType = ENUM_LEGALRESPONSIBILITYLIABILITY_TYPE;
	public _enumClaimProposalStatusNotificationLossAdvice = ENUM_CLAIMPROPOSAL_STATUS_NOTIFICATIONLOSSADVICE;
	public _enumClaimProposalAdjusterStatus = ENUM_CLAIMPROPOSAL_STATUS_ADJUSTER;
	public _enumClaimChannel = ENUM_CLAIM_CHANNEL;
	public _enumLetterRole = ENUM_LETTER_ROLE;
	public _enumHistorySurvey = ENUM_HISTORYSURVEY_STATUS;
	public _enumClaimItemActionType = ENUM_CLAIMITEM_ACTIONTYPE;

	public _booleanForm: boolean;
	public _booleanReadOnly: boolean;

	public _booleanCustomer: boolean;
	public _booleanPolicyHolder: boolean;
	public _booleanSubmission: boolean;
	public _booleanDocument: boolean;
	public _booleanApproval: boolean;
	public _booleanSurvey: boolean;
	public _booleanHistory: boolean;
	public _booleanTracking: boolean;
	public _booleanSatisfactionSurvey: boolean;
	public _booleanFormSatisfactionSurveyTrackingClaim: boolean;
	public _booleanFormSatisfactionForShowButton: boolean;
	public _booleanMV: boolean;
	public _booleanStepperPhaseOne: boolean;
	public _booleanStepperPhaseTwo: boolean;
	public _booleanStepperPhaseThree: boolean;
	public _booleanStepperPhaseFour: boolean;
	public _booleanStepperPhaseFive: boolean;
	public _booleanStepperPhaseSix: boolean;
	public _booleanStepperPhaseSeven: boolean;
	public _booleanMantle: boolean;
	public _booleanNonMantle: boolean;
	public _booleanTLO: boolean;
	public _booleanUpdateForProcessRepair: boolean;
	public _booleanUpdateDatetime: boolean;
	public _booleanUpdateDatetimeForVehicleOut: boolean;
	public _booleanUpdateDatetimeForPaymentAKU: boolean;
	public _booleanUpdateDatetimeForPaymentWorkshop: boolean;
	public _booleanUpdateDatetimeForPaymentInsured: boolean;
	public _booleanForInsertSatisfactionSurvey: boolean;
	public _booleanCanEditForTrackingClaim: boolean | null;
	public _booleanCanEditForTrackingClaimPhaseTwo: boolean | null;
	public _booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey: boolean | null;
	public _booleanCanEditForTrackingClaimPhaseThreeVehicleIn: boolean | null;
	public _booleanCanEditForTrackingClaimPhaseThreeVehicleOut: boolean | null;
	public _booleanCanEditForTrackingClaimPhaseFive: boolean | null;
	public _booleanCanEditForTrackingClaimPhaseSix: boolean | null;
	public _booleanCanEditForTrackingClaimPhaseSeven: boolean | null;
	public _booleanCheckerAndSignerLetterRole: boolean | null;
	public _booleanAfterSubmitTrackingClaim: boolean | null;
	public _booleanRatingForSatisfactionSurvey: boolean | null;
	public _booleanDisableAttachmentVehicleIn: boolean | null;
	public _booleanDisableAttachmentSatisfactionLetter: boolean | null;
	public _booleanDisableAttachmentVehicleOut: boolean | null;
	public _booleanDisable: boolean | null;
	public _booleanHeadOffice: boolean;
	public _booleanRecomendation: boolean | null;
	public _booleanNotesForMore: boolean;
	public _booleanTextareaForOther: boolean;
	public _booleanRecomendationItem: boolean;

	public _dateTimeForStepper?: Date;

	public _modelTablePayment: TableModel;
	public _stringFileTypeAllowedImage: string = RuleConstant.STRING_FILE_TYPE_ALOOWED_ONLY_IMAGE_PDF;

	//#region DECLARATIONAPPROVAL

	public _modelTable: TableModel = new TableModel();

	public _enumClaimItemType = ENUM_CLAIMITEM_TYPE;
	public _enumSurveyStatus = ENUM_SURVEY_STATUS;
	public _enumClaimCoverage = ENUM_CLAIM_COVERAGE;
	public _enumClaimProposalTrackingPhase = ENUM_CLAIMPROPOSAL_TRACKING_PHASE;

	public _stringFormatDocumentMP4 = StringConstant.STRING_FORMAT_DOCUMENT_MP4;
	public _stringFormatDocumentJPG = StringConstant.STRING_FORMAT_IMAGE_JPG;
	public _stringFormatDocumentJPEG = StringConstant.STRING_FORMAT_IMAGE_JPEG;
	public _stringFormatDocumentPNG = StringConstant.STRING_FORMAT_IMAGE_PNG;
	public _stringDateMediumFormat = StringConstant.STRING_DATE_MEDIUM_FORMAT;
	public _stringCustomerUserName: string = "";
	public _stringConstantInsert = StringConstant.STRING_URL_PATH_INSERT;
	public _stringKilometersPerHour = StringConstant.STRING_CURRENCY_KILOMETERSPERHOUR;
	public _stringApprovalMessageType: string = "";
	public _stringFileTypeAllowed: string = RuleConstant.STRING_FILE_TYPE_ALLOWED;
	public _stringCountryCodeSelected: string = "";
	public _stringPercentage = StringConstant.STRING_CHARACTER_SEPARATOR_PERCENTAGE;
	public _stringWorkshop: string;
	public _stringSaveOrUpdatePhaseTwo: string;
	public _stringSaveOrUpdatePhaseThree: string;
	public _stringSaveOrUpdatePhaseFive: string;
	public _stringSaveOrUpdatePhaseSix: string;
	public _stringHeadOffice = StringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE;

	public _numberClaimCoverage?: number;
	public _numberOwnRetentionValue?: number;

	public _arrayModelDivision: Array<DivisionModel>;
	public _arrayCountryCode: Array<string> = StringConstant.ARRAY_VALUE_COUNTRYCODE;
	public _arrayNumberTypeClaimLetter: Array<number>;
	public _arrayNumberTypeClaimLetterThirdPartyLiability: Array<number>;
	public _arrayNumberPolicyYear: Array<number>;
	public _arrayModelPayment: Array<PaymentModel>;
	public _arrayClaimProposalTrackingModel: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseOne: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseTwo: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseTwoForRepairProcess: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseTwoNonMantle: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseThree: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseFour: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseFive: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseSix: Array<ClaimProposalTrackingModel>;
	public _arrayClaimProposalTrackingModelPhaseSeven: Array<ClaimProposalTrackingModel>;
	public _arrayModelClaimProposalTrackingGallery?: Array<ClaimProposalTrackingGalleryModel>;
	public _arrayModelClaimProposalTrackingGalleryForVehicleOut?: Array<ClaimProposalTrackingGalleryModel>;
	public _arrayModelClaimProposalTrackingGalleryForVehicleIn?: Array<ClaimProposalTrackingGalleryModel>;
	public _arrayModelClaimProposalTrackingGalleryForSatisfactionLetter?: Array<ClaimProposalTrackingGalleryModel>;
	public _arrayModelClaimProposalTrackingListGallery?: Array<ClaimProposalTrackingGalleryModel>;
	public _arrayModelClaimProposalSatisfactionModel?: Array<ClaimProposalSatisfactionModel>;
	public _arrayModelClaimProposalSatisfactionQuestionForWorkshop: Array<ClaimProposalSatisfactionQuestionModel>;
	public _arrayModelClaimProposalSatisfactionQuestionForBRINS: Array<ClaimProposalSatisfactionQuestionModel>;
	public _arrayModelClaimProposalSatisfactionQuestionModel: Array<ClaimProposalSatisfactionQuestionModel>;
	public _arrayModelClaimProposalSatisfactionQuestionForWorkshopFeedback: Array<ClaimProposalSatisfactionQuestionModel>;
	public _arrayModelClaimProposalSatisfactionQuestionForBRINSFeedback: Array<ClaimProposalSatisfactionQuestionModel>;
	public _arrayModelClaimProposalSatisfactionQuestionForRecomendationItem: Array<ClaimProposalSatisfactionQuestionModel>;
	public _arrayModelClaimProposalQualityControl: Array<ClaimProposalQualityControlModel>;
	public _arrayModelClaimProposalQualityControlGalery: Array<ClaimProposalQualityControlGalleryModel> | undefined;
	public _arrayModelClaimProposalSatisfactionSurveyRecomendationItem: Array<ClaimProposalSatisfactionModel>;


	public _stringConstant = StringConstant;
	public _stringTimePrefix?: string;
	public _stringTimePrefixPhase2?: string;
	public _stringTimePrefixPhase3?: string;
	public _stringTimePrefixPhase5?: string;
	public _stringTimePrefixPhase6?: string;
	public _stringTimePrefixPhase7?: string;
	public _stringTimeSuffix?: string;
	public _stringTimeSuffixPhase2?: string;
	public _stringTimeSuffixPhase3?: string;
	public _stringTimeSuffixPhase5?: string;
	public _stringTimeSuffixPhase6?: string;
	public _stringTimeSuffixPhase7?: string;
	public _stringDateTemp: Date | undefined;
	public _booleanLastItemForNgClass?: any = false;

	public _dateDateNow: Date;

	public _numberServiceAmountCasco?: number;
	public _numberServiceAmountTJH?: number;
	public _numberPartAmountCasco?: number;
	public _numberPartAmountTJH?: number;
	public _numberCascoAmount?: number;
	public _numberTotalCasco?: number;
	public _numberTotalTPL?: number;
	public _numberTowingAmount?: number;
	public _numberVehicleReplacementAmount?: number;
	public _numberPersonalAccidentDriverAmount?: number;
	public _numberPersonalAccidentPassengerAmount?: number;
	public _numberOwnRetention?: number;
	public _numberOwnRetentionAmount?: number;
	public _numberTaxAmount?: number;
	public _numberTaxPercentage?: number;

	public _numberBRINSQuestionOne?: number | undefined;
	public _numberBRINSQuestionTwo?: number | undefined;
	public _numberBRINSQuestionThree?: number | undefined;
	public _numberWorkshopQuestionOne?: number | undefined;
	public _numberWorkshopQuestionTwo?: number | undefined;
	public _numberWorkshopQuestionThree?: number | undefined;
	public _numberWorkshopQuestionFour?: number | undefined;
	public _numberWorkshopQuestionFive?: number | undefined;
	public _numberTotalRatingForBRINS: any = 0;
	public _numberTotalRatingForWorkshop: any = 0;

	public _stringTitle: string;

	//#endregion

	public _pipeConvertToClaimURL: ConvertToClaimURL;
	public _pipeConvertToProductURL: ConvertToProductURL;

	//#endregion


	//#region  CONSTRUCTOR

	// eslint-disable-next-line max-len
	constructor(routeActivated: ActivatedRoute, location: Location, private _serviceClaimProposal: ClaimProposalService, private _serviceClaimProposalTracking: ClaimproposaltrackingService,private _serviceSatisfactionSurvey: SatisfactionsurveyService, private _servicePolicyGeneral: PolicyGeneralService, serviceSession: SessionService, private _serviceCustomer: CustomerService, public router: Router, private _servicePayment: PaymentService, private _serviceSatisfactionSurveyQuestion: SatisfactionsurveyquestionService, private _serviceProductGeneral: ProductGeneralService)
	{
		super(routeActivated, location, serviceSession, router);

		this._modelPolicyGeneral = new PolicyGeneralModel();
		this._modelPolicyGeneralRequest = new PolicyGeneralModel();
		this._modelClaimProposal = new ClaimProposalModel();
		this._modelClaimProposalRequest = new ClaimProposalModel();
		this._modelClaimProposalForDetailSatisfaction = new ClaimProposalModel();
		this._modelClaimProposalTracking = new ClaimProposalTrackingModel();
		this._modelClaimProposalTrackingUpload = new ClaimProposalTrackingModel();
		this._modelClaimProposalTrackingForInsert = new ClaimProposalTrackingModel();
		this._modelClaimProposalTrackingPhaseThree = new ClaimProposalTrackingModel();
		this._modelClaimProposalTrackingPhaseThreeForSatisfactionLetter = new ClaimProposalTrackingModel();
		this._modelClaimProposalTrackingPhaseThreeForVehicleOut = new ClaimProposalTrackingModel();
		this._modelClaimProposalTrackingPhaseTwo = new ClaimProposalTrackingModel();
		this._modelClaimProposalSatisfactionSurvey = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalTrackingGallery = new ClaimProposalTrackingGalleryModel();
		this._modelCustomer = new CustomerModel();
		this._modelSurvey = new SurveyModel();
		this._modelSurveyForNonAuthorize = new SurveyModel();
		this._modelPayment = new PaymentModel();
		this._modelSurveyForEmail = new SurveyModel();

		this._modelClaimProposalSatisfactionSurveyForBRINSFeedbackRequest = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyForWorkshopFeedbackRequest = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyBRINSFeedback = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyWorkshopFeedback = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyBRINSQuestionOne = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyBRINSQuestionTwo = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyBRINSQuestionThree = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyWorkshopQuestionOne = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyWorkshopQuestionTwo = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyWorkshopQuestionThree = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyWorkshopQuestionFour = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyRecomendationFeedback = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastService = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality = new ClaimProposalSatisfactionModel();
		this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther = new ClaimProposalSatisfactionModel();

		this._modelClaimProposalSatisfactionQuestionForRecomendationFeedback = new ClaimProposalSatisfactionQuestionModel();

		this._modelClaimProposalQualityControl = new ClaimProposalQualityControlModel();
		this._modelUserSurveyor = new UserModel();
		this._modelProductGeneral = new ProductGeneralModel();
		this._modelStoredClaimDetail = new StoredClaimDetailModel();


		this._booleanMV = false;
		this._booleanForm = false;
		this._booleanReadOnly = true;

		this._booleanPolicyHolder = true;
		this._booleanCustomer = false;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = false;
		this._booleanFormSatisfactionSurveyTrackingClaim = false;
		this._booleanFormSatisfactionForShowButton = false;
		this._booleanStepperPhaseOne = false;
		this._booleanStepperPhaseTwo = false;
		this._booleanStepperPhaseThree = false;
		this._booleanStepperPhaseFour = false;
		this._booleanStepperPhaseFive = false;
		this._booleanStepperPhaseSix = false;
		this._booleanStepperPhaseSeven = false;
		this._booleanMantle = false;
		this._booleanNonMantle = false;
		this._booleanTLO = true;
		this._booleanUpdateDatetime = true;
		this._booleanUpdateDatetimeForVehicleOut = true;
		this._booleanUpdateForProcessRepair = false;
		this._booleanUpdateDatetimeForPaymentAKU = true;
		this._booleanUpdateDatetimeForPaymentWorkshop = true;
		this._booleanUpdateDatetimeForPaymentInsured = true;
		this._booleanForInsertSatisfactionSurvey = false;
		this._booleanCanEditForTrackingClaim = false;
		this._booleanCanEditForTrackingClaimPhaseTwo = false;
		this._booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey = false;
		this._booleanCanEditForTrackingClaimPhaseThreeVehicleIn = false;
		this._booleanCanEditForTrackingClaimPhaseThreeVehicleOut = false;
		this._booleanCanEditForTrackingClaimPhaseFive = true;
		this._booleanCanEditForTrackingClaimPhaseSix = true;
		this._booleanCanEditForTrackingClaimPhaseSeven = true;
		this._booleanCheckerAndSignerLetterRole = null;
		this._booleanAfterSubmitTrackingClaim = null;
		this._booleanRatingForSatisfactionSurvey = true;
		this._booleanDisableAttachmentSatisfactionLetter = true;
		this._booleanDisableAttachmentVehicleIn = true;
		this._booleanDisableAttachmentVehicleOut = true;
		this._booleanDisable = true;
		this._booleanHeadOffice = false;
		this._booleanRecomendation = null;
		this._booleanNotesForMore = false;
		this._booleanRecomendationItem = false;
		this._booleanTextareaForOther = false;

		this._stringTimePrefix = "00";
		this._stringTimeSuffix = "00";
		this._stringTimePrefixPhase2 = "00";
		this._stringTimePrefixPhase3 = "00";
		this._stringTimePrefixPhase5 = "00";
		this._stringTimePrefixPhase6 = "00";
		this._stringTimePrefixPhase7 = "00";

		this._stringTimeSuffixPhase2 = "00";
		this._stringTimeSuffixPhase3 = "00";
		this._stringTimeSuffixPhase5 = "00";
		this._stringTimeSuffixPhase6 = "00";
		this._stringTimeSuffixPhase7 = "00";
		this._stringWorkshop = "Bengkel";
		this._stringSaveOrUpdatePhaseTwo = "Simpan";
		this._stringSaveOrUpdatePhaseThree = "Simpan";
		this._stringSaveOrUpdatePhaseFive = "Simpan";
		this._stringSaveOrUpdatePhaseSix = "Simpan";
		this._stringDateTemp = new Date();

		this._pipeConvertToClaimURL = new ConvertToClaimURL();
		this._pipeConvertToProductURL = new ConvertToProductURL();

		this._arrayModelDivision = [];
		this._arrayClaimProposalTrackingModel = [];
		this._arrayClaimProposalTrackingModelPhaseOne = [];
		this._arrayClaimProposalTrackingModelPhaseTwo = [];
		this._arrayClaimProposalTrackingModelPhaseTwoForRepairProcess = [];
		this._arrayClaimProposalTrackingModelPhaseTwoNonMantle = [];
		this._arrayClaimProposalTrackingModelPhaseThree = [];
		this._arrayClaimProposalTrackingModelPhaseFour = [];
		this._arrayClaimProposalTrackingModelPhaseFive = [];
		this._arrayClaimProposalTrackingModelPhaseSix = [];
		this._arrayClaimProposalTrackingModelPhaseSeven = [];
		this._arrayModelClaimProposalTrackingGallery = [];
		this._arrayModelClaimProposalTrackingGalleryForVehicleOut = [];
		this._arrayModelClaimProposalTrackingGalleryForVehicleIn = [];
		this._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter = [];
		this._arrayModelClaimProposalSatisfactionModel = [];
		this._arrayModelClaimProposalSatisfactionQuestionForWorkshop = [];
		this._arrayModelClaimProposalSatisfactionQuestionForBRINS = [];
		this._arrayModelClaimProposalSatisfactionQuestionModel = [];
		this._arrayModelClaimProposalSatisfactionQuestionForWorkshopFeedback = [];
		this._arrayModelClaimProposalSatisfactionQuestionForBRINSFeedback = [];
		this._arrayModelClaimProposalQualityControl = [];
		this._arrayModelClaimProposalQualityControlGalery = [];
		this._arrayModelClaimProposalSatisfactionQuestionForRecomendationItem = [];
		this._arrayModelClaimProposalSatisfactionSurveyRecomendationItem = [];

		this._dateDateNow = new Date();
		this._arrayNumberTypeClaimLetter =
		[
			this._enumPhotoType.PoliceReportLetter,
			this._enumPhotoType.ClaimLetter,
			this._enumPhotoType.IncidentChronologyDocumentPageOne,
			this._enumPhotoType.IncidentChronologyDocumentPageTwo
		];
		this._arrayNumberTypeClaimLetterThirdPartyLiability =
		[
			this._enumPhotoType.PeaceAgreementLetter,
			this._enumPhotoType.IdentityThirdParty,
			this._enumPhotoType.ClaimThirdPartyLetter
		];

		this._arrayNumberPolicyYear = [];
		this._arrayModelPayment = [];

		this._numberClaimCoverage = 0;

		this._modelTablePayment = new TableModel();

		if (window.location.href.indexOf("monitoring") > -1)
		{
			this._stringTitle = "Monitoring";
		}
		else
		{
			this._stringTitle = "Reporting";
		}

	}


	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringToken != null)
		{
			this._modelClaimProposal.Token = stringToken;
			this._modelClaimProposalRequest.Token = stringToken;

			this.callSelectClaimProposalByToken(this, stringToken);
		}
		else
		{
			this.goToList();
		}

		if (this._modelUserSignIn.BranchCode === this._stringHeadOffice)
		{
			this._booleanHeadOffice = true;
		}
		else
		{
		}

		this._booleanUpdateDatetime = true;
		this._booleanUpdateDatetimeForVehicleOut = false;


	}

	//#endregion


	//#region FUNCTION

	checkSalvageValue(claimItemType: ENUM_CLAIMITEM_TYPE, enumClaimItemType: ENUM_CLAIMITEM_TYPE): boolean
	{
		if( claimItemType > enumClaimItemType)
		{
			return true;
		}
		else
		{
			return false;
		}
	}


	showImage(numberUploadType: number)
	{

		if(this._modelClaimProposal.listModelClaimProposalGallery !== undefined)
		{
			for (const modelClaimProposalGallery of this._modelClaimProposal.listModelClaimProposalGallery)
			{
				if (modelClaimProposalGallery.Type === numberUploadType)
				{
					this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelClaimProposalGallery.URL), modelClaimProposalGallery.Name + "." + modelClaimProposalGallery.Extension);
				}
			}
		}

	}


	showImageListSurveyGallery(modelListSurveyGallery: SurveyGalleryModel)
	{
		if(modelListSurveyGallery !== undefined)
		{
			if (modelListSurveyGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelListSurveyGallery.URL), modelListSurveyGallery.Name + "." + modelListSurveyGallery.Extension);
			}
		}
	}


	showImageListClaimProposalGallery(modelClaimProposalGallery: ClaimProposalGalleryModel)
	{

		if(modelClaimProposalGallery !== undefined)
		{
			if (modelClaimProposalGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelClaimProposalGallery.URL), modelClaimProposalGallery.Name + "." + modelClaimProposalGallery.Extension);
			}
		}
	}


	showImageListTrackingGallery(modelListTrackingGalleryModel: ClaimProposalTrackingGalleryModel)
	{
		if(modelListTrackingGalleryModel !== undefined)
		{
			this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelListTrackingGalleryModel.URL), modelListTrackingGalleryModel.Name + "." + modelListTrackingGalleryModel.Extension);
		}
	}

	showImageListQualityQontrolGallery(modelClaimProposalQualityControlGallery: ClaimProposalQualityControlGalleryModel)
	{
		if(modelClaimProposalQualityControlGallery !== undefined)
		{
			this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelClaimProposalQualityControlGallery.URL), modelClaimProposalQualityControlGallery.Name + "." + modelClaimProposalQualityControlGallery.Extension);
		}
	}


	showImageListSurveyThirdPartyLiabilityModel(arrayModelSurveyThirdPartyLiabilityGallery: Array<SurveyThirdPartyLiabilityGalleryModel>, numberType: number)
	{
		const modelSurveyThirdPartyLiabilityGallery = arrayModelSurveyThirdPartyLiabilityGallery.find(e => e.Type === numberType) || new SurveyThirdPartyLiabilityGalleryModel();
		if(modelSurveyThirdPartyLiabilityGallery.Type !== undefined)
		{
			this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelSurveyThirdPartyLiabilityGallery.URL), modelSurveyThirdPartyLiabilityGallery.Name + "." + modelSurveyThirdPartyLiabilityGallery.Extension);
		}
	}


	showImageListSurveyGalleryModel(arrayModelListSurveyGallery: Array<SurveyGalleryModel>, numberType: number)
	{
		if(arrayModelListSurveyGallery !== undefined)
		{
			for (const modelSurveyGallery of arrayModelListSurveyGallery)
			{
				if (modelSurveyGallery.Type === numberType)
				{
					this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelSurveyGallery.URL), modelSurveyGallery.Name + "." + modelSurveyGallery.Extension);
				}
			}
		}
	}


	showImageListSurveyThirdPartyLiabilityGalleryModel(modelListSurveyThirdPartyLiabilityModel: SurveyThirdPartyLiabilityGalleryModel)
	{
		if(modelListSurveyThirdPartyLiabilityModel !== undefined)
		{
			if (modelListSurveyThirdPartyLiabilityModel.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelListSurveyThirdPartyLiabilityModel.URL), modelListSurveyThirdPartyLiabilityModel.Name + "." + modelListSurveyThirdPartyLiabilityModel.Extension);
			}
		}
	}


	showImageSupportingDocument(numberUploadType: number)
	{

		if(this._modelClaimProposal.listModelClaimProposalSupportingGallery !== undefined)
		{
			for (const modelClaimProposalSupportingGallery of this._modelClaimProposal.listModelClaimProposalSupportingGallery)
			{
				if (modelClaimProposalSupportingGallery.Type === numberUploadType)
				{
					this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelClaimProposalSupportingGallery.URL), modelClaimProposalSupportingGallery.Name + "." + modelClaimProposalSupportingGallery.Extension);
				}
			}
		}

	}


	showImageListProductOtoModel(modelProductOtoGallery: ProductOtoGalleryModel)
	{

		if(modelProductOtoGallery !== undefined)
		{
			if (modelProductOtoGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProductOtoGallery.URL), modelProductOtoGallery.Name + "." + modelProductOtoGallery.Extension);
			}
		}
	}

	showImageListProductOtoSyariahModel(modelProductOtoSyariahGallery: ProductOtoSyariahGalleryModel)
	{

		if(modelProductOtoSyariahGallery !== undefined)
		{
			if (modelProductOtoSyariahGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProductOtoSyariahGallery.URL), modelProductOtoSyariahGallery.Name + "." + modelProductOtoSyariahGallery.Extension);
			}
		}
	}


	showImageListProductIndonesianMotorVehicleInsuranceStandardPolicyModel(modelProductIndonesianMotorVehicleInsuranceStandardPolicy: ProductIndonesianMotorVehicleInsuranceStandardPolicyGalleryModel)
	{

		if(modelProductIndonesianMotorVehicleInsuranceStandardPolicy !== undefined)
		{
			if (modelProductIndonesianMotorVehicleInsuranceStandardPolicy.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProductIndonesianMotorVehicleInsuranceStandardPolicy.URL), modelProductIndonesianMotorVehicleInsuranceStandardPolicy.Name + "." + modelProductIndonesianMotorVehicleInsuranceStandardPolicy.Extension);
			}
		}
	}

	showImageListProductIndonesianMotorVehicleInsuranceStandardPolicySyariahModel(modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah: ProductIndonesianMotorVehicleInsuranceStandardPolicySyariahGalleryModel)
	{

		if(modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah !== undefined)
		{
			if (modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah.URL), modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah.Name + "." + modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah.Extension);
			}
		}
	}

	showImageListProductMotorcycle(modelProductMotorcycleGallery: ProductMotorcycleGalleryModel)
	{

		if(modelProductMotorcycleGallery !== undefined)
		{
			if (modelProductMotorcycleGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProductMotorcycleGallery.URL), modelProductMotorcycleGallery.Name + "." + modelProductMotorcycleGallery.Extension);
			}
		}
	}

	showImageListProductMotorcycleSyariah(modelProductMotorcycleSyariahGallery: ProductMotorcycleSyariahGalleryModel)
	{

		if(modelProductMotorcycleSyariahGallery !== undefined)
		{
			if (modelProductMotorcycleSyariahGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProductMotorcycleSyariahGallery.URL), modelProductMotorcycleSyariahGallery.Name + "." + modelProductMotorcycleSyariahGallery.Extension);
			}
		}
	}

	showImageListProductMotorVehicleCredit(modelProductMotorVehicleCreditGallery: ProductMotorVehicleCreditGalleryModel)
	{

		if(modelProductMotorVehicleCreditGallery !== undefined)
		{
			if (modelProductMotorVehicleCreditGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProductMotorVehicleCreditGallery.URL), modelProductMotorVehicleCreditGallery.Name + "." + modelProductMotorVehicleCreditGallery.Extension);
			}
		}
	}

	showImageListProductMotorVehicleCreditSyariah(modelProductMotorVehicleCreditSyariahGallery: ProductMotorVehicleCreditSyariahGalleryModel)
	{

		if(modelProductMotorVehicleCreditSyariahGallery !== undefined)
		{
			if (modelProductMotorVehicleCreditSyariahGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProductMotorVehicleCreditSyariahGallery.URL), modelProductMotorVehicleCreditSyariahGallery.Name + "." + modelProductMotorVehicleCreditSyariahGallery.Extension);
			}
		}
	}

	showImageListProductOtoMaximumProtection(modelProdductOtoMaximumProtectionGallery: ProductOtoMaximumProtectionGalleryModel)
	{

		if(modelProdductOtoMaximumProtectionGallery !== undefined)
		{
			if (modelProdductOtoMaximumProtectionGallery.Type !== undefined)
			{
				this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelProdductOtoMaximumProtectionGallery.URL), modelProdductOtoMaximumProtectionGallery.Name + "." + modelProdductOtoMaximumProtectionGallery.Extension);
			}
		}
	}


	getImage(stringURL): string
	{
		return this._pipeConvertToClaimURL.transform(stringURL);
	}

	getImageProduct(stringURL): string
	{
		return this._pipeConvertToProductURL.transform(stringURL);
	}


	showImageForAppeal(modelClaimProposalAppeal: ClaimProposalAppealGalleryModel)
	{
		this._functionUserInterface.downloadFromURL(this._pipeConvertToClaimURL.transform(modelClaimProposalAppeal.URL), modelClaimProposalAppeal.Name + "." + modelClaimProposalAppeal.Extension);
	}


	setCountSumEstimation(): number
	{
		let numberSumEstimation: number = 0;
		this._modelClaimProposal.listModelSurvey?.forEach
		(
			listModelSurvey =>
			{
				listModelSurvey.listModelSurveyGallery?.forEach
				(
					listModelSurveyGallery =>
					{
						// eslint-disable-next-line max-len
						if((listModelSurveyGallery.Type && listModelSurveyGallery.Type >= this._enumPhotoType.FrontBumperLeft && listModelSurveyGallery.Type <= this._enumPhotoType.VelFrontRight || listModelSurveyGallery.Type && listModelSurveyGallery.Type >= this._enumPhotoType.EngineHood || listModelSurveyGallery.Type && listModelSurveyGallery.Type === this._enumPhotoType.Other) && listModelSurveyGallery.CostEstimationAmount)
						{
							numberSumEstimation = listModelSurveyGallery.CostEstimationAmount + numberSumEstimation;
						}
					}
				);
			}
		);
		return numberSumEstimation;
	}


	setCountSumEstimationForThirdParty(): number
	{
		let numberSumEstimation: number = 0;
		this._modelClaimProposal.listModelSurveyThirdPartyLiability?.forEach
		(
			listModelSurveyThirdPartyLiability =>
			{
				listModelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery?.forEach
				(
					listModelSurveyThirdPartyLiabilityGallery =>
					{
						// eslint-disable-next-line max-len
						if((listModelSurveyThirdPartyLiabilityGallery.Type && listModelSurveyThirdPartyLiabilityGallery.Type >= this._enumPhotoType.FrontBumperLeft && listModelSurveyThirdPartyLiabilityGallery.Type <= this._enumPhotoType.VelFrontRight || listModelSurveyThirdPartyLiabilityGallery.Type && listModelSurveyThirdPartyLiabilityGallery.Type >= this._enumPhotoType.EngineHood ||listModelSurveyThirdPartyLiabilityGallery.Type && listModelSurveyThirdPartyLiabilityGallery.Type === 85) && listModelSurveyThirdPartyLiabilityGallery.CostEstimationAmount)
						{
							numberSumEstimation = listModelSurveyThirdPartyLiabilityGallery.CostEstimationAmount + numberSumEstimation;
						}
					}
				);
			}
		);
		return numberSumEstimation;
	}

	setBooleanForNotes(): void
	{
		if(this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback !== undefined && this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback !== null && this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback !== "")
		{
			this._booleanNotesForMore = true;
		}

		this.validateButtonSubmit();
	}

	validateSurveyForApproval(): boolean
	{
		let booleanValidate: boolean = true;
		let numberIndexSurvey: number = 0;

		this._modelClaimProposal.listModelSurvey?.forEach
		(
			element =>
			{
				if(element.Status !== undefined)
				{
					numberIndexSurvey = element.Status;
				}
			}
		);

		this._modelClaimProposal.listModelSurveyThirdPartyLiability = [];

		if(this._modelClaimProposal.listModelSurveyThirdPartyLiability !== null && this._modelClaimProposal.listModelSurveyThirdPartyLiability !== undefined)
		{
			if(numberIndexSurvey !== 0)
			{
				this._modelClaimProposal.listModelSurveyThirdPartyLiability.forEach
				(
					element =>
					{
						if(element.Status === ENUM_SURVEY_STATUS.Pending)
						{
							booleanValidate = false;
						}
					}
				);
			}
			else
			{
				booleanValidate = false;
			}
		}

		return booleanValidate;
	}


	getSumOwnRetention(modelSurvey: SurveyModel): number
	{
		const numberSumOwnRetention: number = Number(modelSurvey.OwnRetention) * Number(modelSurvey.OwnRetentionAmount);

		return numberSumOwnRetention;
	}


	getClaimProposalGalleryModelClaimLetter(arrayModelSurveyGallery: Array<SurveyGalleryModel>, numberType: number): string
	{
		const modelSurveyGallery = arrayModelSurveyGallery.find(e => e.Type === numberType) || new SurveyGalleryModel();
		if(modelSurveyGallery.Type !== undefined)
		{
			return "Verified";
		}
		else
		{
			return "NotVerified";
		}
	}


	getClaimProposalGalleryModelClaimLetterThirdParty(arrayModelSurveyThirdPartyLiabilityGallery: Array<SurveyThirdPartyLiabilityGalleryModel>, numberType: number): string
	{
		const modelSurveyThirdPartyLiabilityGallery = arrayModelSurveyThirdPartyLiabilityGallery.find(e => e.Type === numberType) || new SurveyThirdPartyLiabilityGalleryModel();
		if(modelSurveyThirdPartyLiabilityGallery.Type !== undefined)
		{
			return "Verified";
		}
		else
		{
			return "NotVerified";
		}
	}


	getCalculateSumEstimation(numberRetention): number
	{
		if(this.setCountSumEstimation() !== null || this.setCountSumEstimation() !== undefined && numberRetention !== null || numberRetention !== undefined)
		{
			return 0;
		}
		else
		{
			return Number(numberRetention)-Number(this.setCountSumEstimation());
		}

	}


	getSumCostEstimation(numberEstimation: number, numberOwnRetention: number): number
	{
		if(numberEstimation == null || numberEstimation === undefined)
		{
			return 0 - numberOwnRetention;
		}
		else if(numberOwnRetention == null || numberOwnRetention === undefined)
		{
			return numberEstimation - 0;
		}
		else
		{
			return numberEstimation - numberOwnRetention;
		}
	}


	getCascoAmount(componentCurrent: this): void
	{
		if(componentCurrent._modelClaimProposal.listModelSurvey !== undefined)
		{
			const arrayModelSurvey: Array<SurveyModel> = componentCurrent._modelClaimProposal.listModelSurvey;
			componentCurrent._modelClaimProposal.listModelSurvey = [];
			let modelSurvey: SurveyModel;
			for (const modelSurveyTemp of arrayModelSurvey)
			{
				modelSurvey = new SurveyModel();
				modelSurvey.setModelFromObject(modelSurveyTemp);
				componentCurrent._modelClaimProposal.listModelSurvey.push(modelSurvey);
				componentCurrent._modelClaimProposal.listModelSurvey.forEach(modelSurveyCount =>
				{
					modelSurveyCount.getCascoAmount(modelSurveyCount.TaxPercentage!);
					componentCurrent._numberTotalCasco = modelSurvey.CascoAmount;
					componentCurrent._numberTowingAmount = modelSurvey.TowingAmount;
					componentCurrent._numberVehicleReplacementAmount = modelSurvey.VehicleReplacementAmount;
					componentCurrent._numberPersonalAccidentDriverAmount = modelSurvey.PersonalAccidentDriverAmount;
					componentCurrent._numberPersonalAccidentPassengerAmount = modelSurvey.PersonalAccidentPassengerAmount;
					componentCurrent._numberOwnRetentionAmount = modelSurvey.OwnRetentionAmount;
					componentCurrent._numberOwnRetention = modelSurvey.OwnRetention;
				});
			}
		}

		if(componentCurrent._modelClaimProposal.listModelSurveyThirdPartyLiability !== undefined)
		{
			componentCurrent.getTPLAmount(componentCurrent);
		}
	}


	getTPLAmount(componentCurrent: this): void
	{
		if(componentCurrent._modelClaimProposal.listModelSurveyThirdPartyLiability !== undefined)
		{
			const arrayModelSurveyThirdPartyLiability: Array<SurveyThirdPartyLiabilityModel> = componentCurrent._modelClaimProposal.listModelSurveyThirdPartyLiability;
			componentCurrent._modelClaimProposal.listModelSurveyThirdPartyLiability = [];
			let modelSurveyThirdPartyLiability: SurveyThirdPartyLiabilityModel;
			componentCurrent._numberTotalTPL = 0;

			for (const modelSurveyThirdPartyLiabilityTemp of arrayModelSurveyThirdPartyLiability)
			{
				modelSurveyThirdPartyLiability = new SurveyThirdPartyLiabilityModel();
				modelSurveyThirdPartyLiability.setModelFromObject(modelSurveyThirdPartyLiabilityTemp);
				componentCurrent._modelClaimProposal.listModelSurveyThirdPartyLiability.push(modelSurveyThirdPartyLiability);
			}

			componentCurrent._modelClaimProposal.listModelSurveyThirdPartyLiability.forEach(modelSurveyThirdPartyLiabilityCount =>
			{
				modelSurveyThirdPartyLiabilityCount.getTPLAmount(modelSurveyThirdPartyLiabilityCount.TaxPercentage!);

				if(modelSurveyThirdPartyLiabilityCount.TPLAmount !== undefined && componentCurrent._numberTotalTPL !== undefined)
				{
					componentCurrent._numberTotalTPL = componentCurrent._numberTotalTPL+modelSurveyThirdPartyLiabilityCount.TPLAmount;
				}
			});
		}
	}


	getTotalCost(): number
	{
		if(this._numberTotalCasco !== undefined)
		{

		}
		else
		{
			this._numberTotalCasco = 0;
		}

		if(this._numberTowingAmount !== undefined)
		{

		}
		else
		{
			this._numberTowingAmount = 0;
		}

		if(this._numberVehicleReplacementAmount !== undefined)
		{

		}
		else
		{
			this._numberVehicleReplacementAmount = 0;
		}

		if(this._numberPersonalAccidentDriverAmount !== undefined)
		{

		}
		else
		{
			this._numberPersonalAccidentDriverAmount = 0;
		}

		if(this._numberPersonalAccidentPassengerAmount !== undefined)
		{

		}
		else
		{
			this._numberPersonalAccidentPassengerAmount = 0;
		}

		if(this._numberTotalTPL !== undefined)
		{

		}
		else
		{
			this._numberTotalTPL = 0;
		}

		if(this._numberOwnRetention !== undefined)
		{

		}
		else
		{
			this._numberOwnRetention = 0;
		}

		if (this._numberOwnRetentionAmount !== undefined)
		{

		}
		else
		{
			this._numberOwnRetentionAmount = 0;
		}

		const numberTotalCosts: number = this._numberTotalCasco + this._numberTotalTPL + this._numberTowingAmount + this._numberVehicleReplacementAmount + this._numberPersonalAccidentDriverAmount + this._numberPersonalAccidentPassengerAmount - this._numberOwnRetentionAmount;
		return numberTotalCosts;
	}


	getArrayNumberPolicyYear(modelPolicyGeneral: PolicyGeneralModel): Array<number>
	{
		const numberStartDateYear: number | undefined = modelPolicyGeneral.PolicyStartDate?.getFullYear();
		const numberEndDateYear: number | undefined = modelPolicyGeneral.PolicyEndDate?.getFullYear();
		if(numberEndDateYear !== undefined && numberStartDateYear !== undefined)
		{
			const arrayNumberPolicyYear: Array<number> = [];
			for (let numberYear: number = numberStartDateYear; numberYear<= numberEndDateYear; numberYear++ )
			{
				arrayNumberPolicyYear.push(numberYear);
			}
			return arrayNumberPolicyYear;
		}
		else
		{
			return [];
		}
	}


	// .Delete picture on preview for tracking claim
	deleteModelClaimProposalTrackingGallery(numberIndex: number, modelClaimProposalTracking: ClaimProposalTrackingModel)
	{
		if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_START_REPAIR_VEHICLE)
		{
			this._arrayModelClaimProposalTrackingGalleryForVehicleIn?.splice(numberIndex, 1);
			if(this._arrayModelClaimProposalTrackingGalleryForVehicleIn && this._arrayModelClaimProposalTrackingGalleryForVehicleIn?.length <= 4)
			{
				this._booleanDisableAttachmentVehicleIn = true;
			}
		}
		else if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_SATISFACTION_LETTER)
		{
			this._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter?.splice(numberIndex, 1);
			if(this._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter && this._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter.length <=4)
			{
				this._booleanDisableAttachmentSatisfactionLetter = true;
			}
		}
		else if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_VEHICLE_OUT)
		{
			this._arrayModelClaimProposalTrackingGalleryForVehicleOut?.splice(numberIndex, 1);
			if(this._arrayModelClaimProposalTrackingGalleryForVehicleOut && this._arrayModelClaimProposalTrackingGalleryForVehicleOut.length <= 4)
			{
				this._booleanDisableAttachmentVehicleOut = true;
			}
		}
	}


	// .Get rating satisfaction survey
	getRating(modelClaimProposalQuestion: ClaimProposalSatisfactionQuestionModel)
	{
		if(modelClaimProposalQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_EASYREPORT)
		{

			this._modelClaimProposalSatisfactionSurveyBRINSQuestionOne.Rating = this._numberBRINSQuestionOne;
			this._modelClaimProposalSatisfactionSurveyBRINSQuestionOne.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
			this._modelClaimProposalSatisfactionSurveyBRINSQuestionOne.ClaimProposalToken = this._modelClaimProposal.Token;

		}
		else if(modelClaimProposalQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_SPEED_OF_RESPONSE)
		{
			this._modelClaimProposalSatisfactionSurveyBRINSQuestionTwo.Rating = this._numberBRINSQuestionTwo;
			this._modelClaimProposalSatisfactionSurveyBRINSQuestionTwo.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
			this._modelClaimProposalSatisfactionSurveyBRINSQuestionTwo.ClaimProposalToken = this._modelClaimProposal.Token;
		}
		else if(modelClaimProposalQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_CLAIM_SERVICE)
		{
			this._modelClaimProposalSatisfactionSurveyBRINSQuestionThree.Rating = this._numberBRINSQuestionThree;
			this._modelClaimProposalSatisfactionSurveyBRINSQuestionThree.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
			this._modelClaimProposalSatisfactionSurveyBRINSQuestionThree.ClaimProposalToken = this._modelClaimProposal.Token;

		}
		else if(modelClaimProposalQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_REPAIR_QUALITY)
		{
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionOne.Rating = this._numberWorkshopQuestionOne;
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionOne.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionOne.ClaimProposalToken = this._modelClaimProposal.Token;

		}
		else if(modelClaimProposalQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_SPEED_OF_REPAIR)
		{
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionTwo.Rating = this._numberWorkshopQuestionTwo;
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionTwo.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionTwo.ClaimProposalToken = this._modelClaimProposal.Token;

		}
		else if(modelClaimProposalQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKUNG_CLAIM_SERVICE_QUALITY)
		{
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionThree.Rating = this._numberWorkshopQuestionThree;
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionThree.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionThree.ClaimProposalToken = this._modelClaimProposal.Token;

		}
		else if(modelClaimProposalQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_WORKSHOP_QUALITY)
		{
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionFour.Rating = this._numberWorkshopQuestionFour;
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionFour.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
			this._modelClaimProposalSatisfactionSurveyWorkshopQuestionFour.ClaimProposalToken = this._modelClaimProposal.Token;
		}


		this.validateButtonSubmit();
	}

	getFeedbackRecomendation(modelClaimProposalQuestion: ClaimProposalSatisfactionQuestionModel, booleanFeedback: boolean | null)
	{
		if(modelClaimProposalQuestion.QuestionBahasa !== undefined && booleanFeedback !== null)
		{
			if(booleanFeedback === true)
			{
				this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest.ClaimProposalToken = this._modelClaimProposal.Token;
				this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
				this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest.Feedback = "YES";
			}
			else
			{
				this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest.ClaimProposalToken = this._modelClaimProposal.Token;
				this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest.ClaimProposalSatisfactionQuestionToken = modelClaimProposalQuestion.Token;
				this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest.Feedback = "NO";
			}
		}
		else
		{
			this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest = new ClaimProposalSatisfactionModel();
		}

		this._booleanRecomendationItem = true;

		this.validateButtonSubmit();
	}

	getFeedbackRecomendationItem(modelClaimProposalSatisfactionQuestion: ClaimProposalSatisfactionQuestionModel, event): void
	{
		let booleanRecomendationItem: boolean = false;
		booleanRecomendationItem = event.checked;

		if(modelClaimProposalSatisfactionQuestion.QuestionBahasa !== undefined)
		{
			if(modelClaimProposalSatisfactionQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_EASY_REPORTING)
			{
				if(booleanRecomendationItem === true)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting.Feedback = "YES";
				}
				else
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting.Feedback = undefined;
				}
			}

			if(modelClaimProposalSatisfactionQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_FAST_RESPONSE)
			{
				if(booleanRecomendationItem === true)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.Feedback = "YES";
				}
				else
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.Feedback = undefined;
				}
			}

			if(modelClaimProposalSatisfactionQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_SERVICE)
			{
				if(booleanRecomendationItem === true)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastService.Feedback = "YES";
				}
				else
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastService.Feedback = undefined;
				}
			}

			if(modelClaimProposalSatisfactionQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_SPEED_OF_REPAIR)
			{
				if(booleanRecomendationItem === true)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair.Feedback = "YES";
				}
				else
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair.Feedback = undefined;
				}
			}

			if(modelClaimProposalSatisfactionQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_REPAIR_QUALITY)
			{
				if(booleanRecomendationItem === true)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality.Feedback = "YES";
				}
				else
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality.Feedback = undefined;
				}
			}

			if(modelClaimProposalSatisfactionQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_FAST_RESPONSE)
			{
				if(booleanRecomendationItem === true)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.Feedback = "YES";
				}
				else
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.Feedback = undefined;
				}
			}

			if(modelClaimProposalSatisfactionQuestion.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_MORE)
			{
				if(booleanRecomendationItem === true)
				{
					this._booleanNotesForMore = true;
					this._booleanTextareaForOther = true;
				}
				else
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback = undefined;
					this._booleanNotesForMore = false;
					this._booleanTextareaForOther = false;
				}
			}
		}

		this.validateButtonSubmit();

	}

	getQuestionRecomendationItem(): void
	{
		if(this._arrayModelClaimProposalSatisfactionQuestionForRecomendationItem.length !== 0)
		{
			for(const modelClaimProposalSatisfactionQuestionForRecomendationItem of this._arrayModelClaimProposalSatisfactionQuestionForRecomendationItem)
			{
				if(modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_EASY_REPORTING)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionQuestionForRecomendationItem.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting.ClaimProposalToken = this._modelClaimProposal.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting.Feedback = undefined;
				}
				else if(modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_FAST_RESPONSE)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionQuestionForRecomendationItem.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.ClaimProposalToken = this._modelClaimProposal.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.Feedback = undefined;
				}
				else if(modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_SERVICE)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastService.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionQuestionForRecomendationItem.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastService.ClaimProposalToken = this._modelClaimProposal.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastService.Feedback = undefined;
				}
				else if(modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_SPEED_OF_REPAIR)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionQuestionForRecomendationItem.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair.ClaimProposalToken = this._modelClaimProposal.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair.Feedback = undefined;
				}
				else if(modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_REPAIR_QUALITY)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionQuestionForRecomendationItem.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality.ClaimProposalToken = this._modelClaimProposal.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality.Feedback = undefined;
				}
				else if(modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_FAST_RESPONSE)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionQuestionForRecomendationItem.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.ClaimProposalToken = this._modelClaimProposal.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.Feedback = undefined;
				}
				else if(modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa?.toUpperCase() === this._stringConstant.STRING_TRACKING_CLAIM_MORE)
				{
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionQuestionForRecomendationItem.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.ClaimProposalToken = this._modelClaimProposal.Token;
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback = undefined;
				}
			}
		}
	}


	// getNotesForRecomendationItemMore()


	// .Get feedback satisfaction survey
	getRatingForSurveySatisfaction()
	{
		for(const modelClaimProposalSatisfactionForBRINS of this._arrayModelClaimProposalSatisfactionQuestionForBRINSFeedback)
		{
			if(this._modelClaimProposalSatisfactionSurveyBRINSFeedback.Feedback !== undefined)
			{
				this._modelClaimProposalSatisfactionSurveyForBRINSFeedbackRequest.Feedback = this._modelClaimProposalSatisfactionSurveyBRINSFeedback.Feedback;
				this._modelClaimProposalSatisfactionSurveyForBRINSFeedbackRequest.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionForBRINS.Token;
				this._modelClaimProposalSatisfactionSurveyForBRINSFeedbackRequest.ClaimProposalToken = this._modelClaimProposal.Token;
			}
			else
			{
				this._modelClaimProposalSatisfactionSurveyForBRINSFeedbackRequest = new ClaimProposalSatisfactionModel();
			}
		}

		for(const modelClaimProposalSatisfactionForWorkshop of this._arrayModelClaimProposalSatisfactionQuestionForWorkshopFeedback)
		{
			if(this._modelClaimProposalSatisfactionSurveyWorkshopFeedback.Feedback !== undefined)
			{
				this._modelClaimProposalSatisfactionSurveyForWorkshopFeedbackRequest.Feedback = this._modelClaimProposalSatisfactionSurveyWorkshopFeedback.Feedback;
				this._modelClaimProposalSatisfactionSurveyForWorkshopFeedbackRequest.ClaimProposalSatisfactionQuestionToken = modelClaimProposalSatisfactionForWorkshop.Token;
				this._modelClaimProposalSatisfactionSurveyForWorkshopFeedbackRequest.ClaimProposalToken = this._modelClaimProposal.Token;
			}
			else
			{
				this._modelClaimProposalSatisfactionSurveyForWorkshopFeedbackRequest = new ClaimProposalSatisfactionModel();
			}
		}



		if(this._arrayModelClaimProposalSatisfactionModel !== undefined)
		{

			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyBRINSQuestionTwo);
			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyBRINSQuestionOne);
			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyBRINSQuestionThree);

			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting);
			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair);
			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse);
			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastService);
			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality);
			this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther);

			if(this._booleanTLO === true)
			{
				this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyWorkshopQuestionOne);
				this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyWorkshopQuestionTwo);
				this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyWorkshopQuestionThree);
				this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyWorkshopQuestionFour);
			}

			if(this._modelClaimProposalSatisfactionSurveyForBRINSFeedbackRequest.Feedback !== undefined)
			{
				this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForBRINSFeedbackRequest);
			}

			if(this._modelClaimProposalSatisfactionSurveyForWorkshopFeedbackRequest.Feedback !== undefined)
			{
				this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForWorkshopFeedbackRequest);
			}

			if(this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest.Feedback !== undefined)
			{
				this._arrayModelClaimProposalSatisfactionModel.push(this._modelClaimProposalSatisfactionSurveyForRecomendationFeedbackRequest);
			}

		}
	}

	// .Function for upload picture for tracking claim
	listenFileUpload(event, modelClaimProposalTracking: ClaimProposalTrackingModel): void
	{
		const arrayFileUpload: Array<File> = event.target.files;

		if(arrayFileUpload.length > 4)
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Unggah";
			modelResponse.MessageContent = "Maksimal upload hanya 4 file";

			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
		}
		else
		{
			if (arrayFileUpload != null && arrayFileUpload !== undefined && arrayFileUpload.length > 0)
			{
				let modelClaimProposalTrackingGallery: ClaimProposalTrackingGalleryModel;

				for (const fileUpload of arrayFileUpload)
				{
					fileUpload.convertToBase64(
						(stringBase64) =>
						{
							modelClaimProposalTrackingGallery = new ClaimProposalTrackingGalleryModel();
							modelClaimProposalTrackingGallery.Format = fileUpload.type;
							modelClaimProposalTrackingGallery.Size = fileUpload.size;

							let fileName: any = fileUpload.name;
							fileName = fileName.substring(0, fileName.lastIndexOf("."));
							modelClaimProposalTrackingGallery.Name = fileName;

							modelClaimProposalTrackingGallery.Data = stringBase64;
							modelClaimProposalTrackingGallery.Extension = fileUpload.name.getFileExtension();

							const modelResponseValidation: ResponseModel = modelClaimProposalTrackingGallery.validateAdd();

							if(modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
							{
								if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_START_REPAIR_VEHICLE)
								{
									this.validateFile(this._arrayModelClaimProposalTrackingGalleryForVehicleIn, modelClaimProposalTrackingGallery, modelClaimProposalTracking);
									this._booleanDisableAttachmentVehicleIn = this._booleanDisable;
								}
								else if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_SATISFACTION_LETTER)
								{
									this.validateFile(this._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter, modelClaimProposalTrackingGallery, modelClaimProposalTracking);
									this._booleanDisableAttachmentSatisfactionLetter = this._booleanDisable;
								}
								else if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_VEHICLE_OUT)
								{
									this.validateFile(this._arrayModelClaimProposalTrackingGalleryForVehicleOut, modelClaimProposalTrackingGallery, modelClaimProposalTracking);
									this._booleanDisableAttachmentVehicleOut = this._booleanDisable;
								}
							}
							else
							{
								this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
							}

						},
						() =>
						{

						}
					);
				}
			}
			else
			{
			}
		}

		for (const attachment of this.fileAttachment.toArray())
		{
			attachment.nativeElement.value = "";
		}
	}

	private validateFile(arrayModelClaimProposalTracking: Array<ClaimProposalTrackingGalleryModel> | undefined, modelClaimProposalTrackingGallery: ClaimProposalTrackingGalleryModel, modelClaimProposalTracking: ClaimProposalTrackingModel): void
	{
		const numberTotalImage: number | undefined = modelClaimProposalTracking.listModelClaimProposalTrackingGallery!.length;
		const numberTotalImageInArray: number | undefined = arrayModelClaimProposalTracking?.length;

		if(numberTotalImageInArray && numberTotalImage !== undefined && numberTotalImageInArray >= (4 - numberTotalImage))
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Unggah";
			modelResponse.MessageContent = "Maksimal upload hanya 4 file";

			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			this._booleanDisable = false;
		}
		else
		{
			arrayModelClaimProposalTracking?.push(modelClaimProposalTrackingGallery);
			this._booleanDisable = true;
		}
	}


	// .Function disable button submit for satisfaction survey
	disabledButtonSubmit()
	{
		if(this._numberBRINSQuestionOne === undefined)
		{
			this._booleanReadOnly = true;
		}
		else
		{
			this._booleanReadOnly = false;
		}
	}

	downloadFile(stringURL: string): void
	{
		if(stringURL == null || stringURL === undefined || stringURL === "")
		{

		}
		else
		{
			stringURL = stringURL.replace("../..", environment.URL_CLAIM_API);
			window.open(stringURL, "_blank");
		}
	}

	private setModelSurveyForOwnRetention(): void
	{
		if(this._modelClaimProposal.listModelSurvey)
		{
			for(const modelSurvey of this._modelClaimProposal.listModelSurvey)
			{
				this._modelSurvey.setModelFromObject(modelSurvey);
			}
		}
		this.setRetentionValueHeadOffice();
	}

	setRetentionValueHeadOffice(): void
	{

		let numberOwnRetention: number = 0;
		let numberOwnRetentionAmount: number = 0;
		if(this._modelClaimProposal.listModelSurvey?.length !== 0 && this._modelClaimProposal.listModelSurvey !== undefined)
		{


			if(this._modelSurvey.OwnRetention)
			{
				numberOwnRetention = this._modelSurvey.OwnRetention;
			}
			else
			{
				numberOwnRetention = 1;
			}
			if(this._modelSurvey.OwnRetentionAmount)
			{
				numberOwnRetentionAmount = this._modelSurvey.OwnRetentionAmount;
			}
			else
			{
				numberOwnRetentionAmount = 0;
			}

			// this._modelSurvey.OwnRetentionHeadOffice = modelSurvey.OwnRetention;
			if(!this._modelSurvey.OwnRetentionAmountHeadOffice)
			{
				this._modelSurvey.OwnRetentionAmountHeadOffice = this._modelSurvey.OwnRetentionAmount;
			}
		}

		this._numberOwnRetentionValue = numberOwnRetentionAmount/numberOwnRetention;

	}

	//#endregion


	//#region WEBSERVICE

	private callSelectClaimProposalByToken(componentCurrent: this, stringToken: any)
	{
		componentCurrent._functionUserInterface.setLoadingProgress(3);
		this._modelClaimProposal = new ClaimProposalModel();

		this._modelClaimProposalRequest.Token = stringToken;

		componentCurrent._serviceClaimProposal.selectClaimProposalByToken
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._modelClaimProposal.setModelFromString(modelResponse.Data);

						componentCurrent._modelClaimProposalRequest.Token = componentCurrent._modelClaimProposal.Token;

						const modelUserMakerHeadOfficeReviewed: UserModel = new UserModel();
						modelUserMakerHeadOfficeReviewed.setModelFromObject(componentCurrent._modelClaimProposal.modelUserMakerHeadOfficeReviewed!);
						componentCurrent._modelClaimProposal.modelUserMakerHeadOfficeReviewed = modelUserMakerHeadOfficeReviewed;

						const modelUserCheckerHeadOfficeReviewed: UserModel = new UserModel();
						modelUserCheckerHeadOfficeReviewed.setModelFromObject(componentCurrent._modelClaimProposal.modelUserCheckerHeadOfficeReviewed!);
						componentCurrent._modelClaimProposal.modelUserCheckerHeadOfficeReviewed = modelUserCheckerHeadOfficeReviewed;

						const modelUserHeadOfficeReviewed: UserModel = new UserModel();
						modelUserHeadOfficeReviewed.setModelFromObject(componentCurrent._modelClaimProposal.modelUserHeadOfficeReviewed!);
						componentCurrent._modelClaimProposal.modelUserHeadOfficeReviewed = modelUserHeadOfficeReviewed;

						const modelUserBranchReviewed: UserModel = new UserModel();
						modelUserBranchReviewed.setModelFromObject(componentCurrent._modelClaimProposal.modelUserBranchReviewed!);
						componentCurrent._modelClaimProposal.modelUserBranchReviewed = modelUserBranchReviewed;

						if(componentCurrent._modelClaimProposal && componentCurrent._modelClaimProposal.listModelSurvey)
						{
							componentCurrent._modelSurveyForEmail = componentCurrent._modelClaimProposal.listModelSurvey[0];
						}

						let numberType: number = 0;
						if(componentCurrent._modelClaimProposal.ProductCode !== undefined)
						{
							componentCurrent.getCascoAmount(componentCurrent);
							numberType = componentCurrent._modelClaimProposal.checkTypePolicyNumber(componentCurrent._modelClaimProposal.ProductCode);
						}
						else
						{
							numberType = componentCurrent._modelClaimProposal.checkTypePolicyNumber("0202");
						}
						componentCurrent.setModelSurveyForOwnRetention();

						componentCurrent._booleanMV = componentCurrent._modelClaimProposal.setInterface(numberType);

						if(componentCurrent._modelClaimProposal.ANO !== undefined && componentCurrent._modelClaimProposal.ANO !== null && componentCurrent._modelClaimProposal.ANO !== "")
						{
							componentCurrent._modelPolicyGeneralRequest.ANO = componentCurrent._modelClaimProposal.ANO;

							componentCurrent.callSelectPolicyGeneralByAcceptanceNumber(componentCurrent);
						}
						else if(componentCurrent._modelClaimProposal.PolicyNumber !== undefined && componentCurrent._modelClaimProposal.PolicyNumber !== null && componentCurrent._modelClaimProposal.PolicyNumber !== "")
						{
							componentCurrent._modelPolicyGeneral.PolicyNumber = componentCurrent._modelClaimProposal.PolicyNumber;

							componentCurrent.callSelectPolicyGeneralByPolicyNumberForClaim(componentCurrent);
						}
						else if(componentCurrent._modelClaimProposal.ReferenceNumber !== undefined && componentCurrent._modelClaimProposal.ReferenceNumber !== null && componentCurrent._modelClaimProposal.ReferenceNumber !== "")
						{
							componentCurrent._modelPolicyGeneral.ReferenceNumber = componentCurrent._modelClaimProposal.ReferenceNumber;

							componentCurrent.callSelectPolicyGeneralByReferenceNumberForClaim(componentCurrent);
						}
						else
						{
							modelResponse.MessageTitle = "Token Empty";
							modelResponse.MessageContent = "Acceptance Number, Policy Number, or Reference Number is empty!";
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						// componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent);

						if(componentCurrent._modelClaimProposal.listModelSurvey)
						{
							for(const modelSurvey of componentCurrent._modelClaimProposal.listModelSurvey)
							{
								if((modelSurvey.WorkshopID !== null || modelSurvey.WorkshopID !== undefined) && modelSurvey?.modelPartnershipWorkshop?.Type === ENUM_WORKSHOP_TYPE.NonAuthorized)
								{
									//mentel
									componentCurrent._booleanNonMantle = false;
									componentCurrent._booleanCanEditForTrackingClaim = true;
									componentCurrent._booleanCanEditForTrackingClaimPhaseTwo = true;
									componentCurrent._booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey = true;
									componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleIn = true;
								}
								else if((modelSurvey.WorkshopID !== null || modelSurvey.WorkshopID !== undefined )&& modelSurvey.modelPartnershipWorkshop?.Type === ENUM_WORKSHOP_TYPE.Authorized)
								{
									//non mentel
									componentCurrent._booleanNonMantle = true;
								}
								else if(modelSurvey.WorkshopID === null || modelSurvey.WorkshopID === undefined)
								{
									componentCurrent._booleanNonMantle = false;
									componentCurrent._booleanCanEditForTrackingClaim = true;
									componentCurrent._booleanCanEditForTrackingClaimPhaseTwo = true;
									componentCurrent._booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey = true;
									componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleIn = true;
								}
								else
								{
								}
							}
						}

						if(componentCurrent._modelClaimProposal.IncidentDamageType === componentCurrent._stringConstant.STRING_TRACKING_CLAIM_DAMAGETYPE_TOTALLOSSONLY)
						{
							componentCurrent._booleanTLO = false;
							componentCurrent._stringWorkshop = "Tertanggung";
						}

						if(componentCurrent._modelClaimProposal.listModelClaimProposalSatisfaction && (componentCurrent._modelClaimProposal.listModelClaimProposalSatisfaction?.length > 0))
						{
							componentCurrent._booleanForInsertSatisfactionSurvey = true;
						}
						else
						{
							componentCurrent._booleanForInsertSatisfactionSurvey = false;
						}

						if(componentCurrent._modelClaimProposal.listModelClaimProposalQualityControl && componentCurrent._modelClaimProposal.listModelClaimProposalQualityControl?.length !== 0)
						{
							for(const modelClaimProposalQualityControl of componentCurrent._modelClaimProposal.listModelClaimProposalQualityControl)
							{
								componentCurrent._modelClaimProposalQualityControl = modelClaimProposalQualityControl;
								componentCurrent._arrayModelClaimProposalQualityControlGalery = modelClaimProposalQualityControl.listModelClaimProposalQualityControlGallery;

								const stringModelUserSurveyor: string = JSON.stringify(modelClaimProposalQualityControl.modelUserSurveyor);
								const modelUserSurveyorTemp: UserModel = new UserModel();
								modelUserSurveyorTemp.setModelFromString(stringModelUserSurveyor);
								componentCurrent._modelClaimProposalQualityControl.modelUserSurveyor = modelUserSurveyorTemp;
							}
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectClaimProposalByToken(componentCurrent, stringToken); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelClaimProposalRequest);
	}


	// .Get stepper for mantle on tracking claim
	private callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent: this)
	{
		this._stringTimePrefixPhase2 = "00";
		this._stringTimeSuffixPhase2 = "00";
		this._stringTimePrefixPhase3 = "00";
		this._stringTimeSuffixPhase3 = "00";
		this._stringTimePrefixPhase5 = "00";
		this._stringTimeSuffixPhase5 = "00";
		this._stringTimePrefixPhase6 = "00";
		this._stringTimeSuffixPhase6 = "00";

		this._stringDateTemp = undefined;
		// componentCurrent._functionUserInterface.setLoadingProgress(2);

		componentCurrent._serviceClaimProposalTracking.selectClaimProposalTrackingByClaimProposalToken
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._arrayClaimProposalTrackingModel = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseOne = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseTwo = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseThree = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseFour = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseFive = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseSix = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseSeven = [];
						componentCurrent._modelClaimProposalTrackingUpload.listModelClaimProposalTrackingGallery = [];
						componentCurrent._arrayModelClaimProposalTrackingGalleryForVehicleIn = [];
						componentCurrent._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter = [];
						componentCurrent._arrayModelClaimProposalTrackingGalleryForVehicleOut = [];

						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._arrayClaimProposalTrackingModel = JSON.parse(modelResponse.Data);

						for(const modelClaimProposalTracking of componentCurrent._arrayClaimProposalTrackingModel)
						{
							componentCurrent._modelClaimProposalTrackingForInsert = modelClaimProposalTracking;

							if(modelClaimProposalTracking.Phase === 1)
							{
								componentCurrent._arrayClaimProposalTrackingModelPhaseOne.push(modelClaimProposalTracking);
								componentCurrent._booleanStepperPhaseOne = true;
							}
							else if(modelClaimProposalTracking.Phase === 2)
							{
								componentCurrent._arrayClaimProposalTrackingModelPhaseTwo.push(modelClaimProposalTracking);

								for(const modelClaimProposalTrackingPhaseTwo of componentCurrent._arrayClaimProposalTrackingModelPhaseTwo)
								{
									if(modelClaimProposalTrackingPhaseTwo.listModelClaimProposalTrackingGallery)
									{
										if((modelClaimProposalTrackingPhaseTwo.listModelClaimProposalTrackingGallery.length > 0 &&componentCurrent._modelUserSignIn.checkLetterRole(componentCurrent._enumLetterRole.Checker)) || (modelClaimProposalTrackingPhaseTwo.listModelClaimProposalTrackingGallery.length > 0 && componentCurrent._modelUserSignIn.checkLetterRole(componentCurrent._enumLetterRole.Signer)))
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseTwo = true;
										}
										else if(modelClaimProposalTrackingPhaseTwo.listModelClaimProposalTrackingGallery.length > 0 && componentCurrent._modelUserSignIn.checkLetterRole(componentCurrent._enumLetterRole.Maker))
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseTwo = false;
										}
										else if(modelClaimProposalTrackingPhaseTwo.listModelClaimProposalTrackingGallery.length <= 0)
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseTwo = true;
										}
										else
										{
										}

										if(modelClaimProposalTrackingPhaseTwo.Action?.toUpperCase() === "MULAI PERBAIKAN KENDARAAN")
										{
											if(modelClaimProposalTrackingPhaseTwo.UpdatedBy !== 0 && componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseTwo = true;
											}
											else if(modelClaimProposalTrackingPhaseTwo.UpdatedBy !== 0 && !componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseTwo = false;
											}
											else if(modelClaimProposalTrackingPhaseTwo.UpdatedBy === 0)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseTwo = true;
											}
										}

										if(modelClaimProposalTrackingPhaseTwo.listModelClaimProposalTrackingGallery.length >= 4)
										{
											componentCurrent._booleanDisableAttachmentVehicleIn = false;
										}

										if(modelClaimProposalTrackingPhaseTwo.UpdatedBy !== 0)
										{
											componentCurrent._stringSaveOrUpdatePhaseTwo = "Ubah";
										}
									}
								}
							}
							else if(modelClaimProposalTracking.Phase === 3)
							{
								componentCurrent._arrayClaimProposalTrackingModelPhaseThree.push(modelClaimProposalTracking);
								componentCurrent._booleanStepperPhaseThree = true;

								for(const modelClaimProposalTrackingPhaseThree of componentCurrent._arrayClaimProposalTrackingModelPhaseThree)
								{
									if(modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery)
									{
										if(modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery.length > 0 &&componentCurrent._modelUserSignIn.checkLetterRole(componentCurrent._enumLetterRole.Checker))
										{
											if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_VEHICLE_OUT)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleIn = true;
											}
											else if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_SATISFACTION_LETTER)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey = true;
											}
											else
											{
											}
										}
										else if(modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery.length > 0 && componentCurrent._modelUserSignIn.checkLetterRole(componentCurrent._enumLetterRole.Signer))
										{
											if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_VEHICLE_OUT)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleIn = true;
											}
											else if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_SATISFACTION_LETTER)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey = true;
											}
										}
										else if(modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery.length > 0 && componentCurrent._modelUserSignIn.checkLetterRole(componentCurrent._enumLetterRole.Maker))
										{
											if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_VEHICLE_OUT)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleIn = false;
											}
											else if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_SATISFACTION_LETTER)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey = false;
											}
										}
										else if(modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery.length <= 0)
										{
											if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_VEHICLE_OUT)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleIn = true;
											}
											else if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_SATISFACTION_LETTER)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey = true;
											}
										}
										else
										{
										}

										if(modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery.length >=4 && modelClaimProposalTrackingPhaseThree.Action === "Kendaraan keluar")
										{
											componentCurrent._booleanDisableAttachmentVehicleOut = false;
										}
										else if(modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery.length >=4 && modelClaimProposalTrackingPhaseThree.Action === "Surat puas")
										{
											componentCurrent._booleanDisableAttachmentSatisfactionLetter = false;
										}

										if(modelClaimProposalTrackingPhaseThree.Action?.toUpperCase() === "KENDARAAN KELUAR")
										{
											if(modelClaimProposalTrackingPhaseThree.UpdatedBy !== 0 && componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleOut = true;
											}
											else if(modelClaimProposalTrackingPhaseThree.UpdatedBy !== 0 && !componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleOut = false;
											}
											else if(modelClaimProposalTrackingPhaseThree.UpdatedBy === 0)
											{
												componentCurrent._booleanCanEditForTrackingClaimPhaseThreeVehicleOut = true;
											}
										}

										if(modelClaimProposalTrackingPhaseThree.UpdatedBy !== 0)
										{
											componentCurrent._stringSaveOrUpdatePhaseThree = "Ubah";
										}
									}
								}

							}
							else if(modelClaimProposalTracking.Phase === 4)
							{
								componentCurrent._arrayClaimProposalTrackingModelPhaseFour.push(modelClaimProposalTracking);
								componentCurrent._booleanStepperPhaseFour = true;
							}
							else if(modelClaimProposalTracking.Phase === 5)
							{
								componentCurrent._arrayClaimProposalTrackingModelPhaseFive.push(modelClaimProposalTracking);
								componentCurrent._booleanStepperPhaseFive = true;

								for(const modelClaimProposalTrackingPhaseFive of componentCurrent._arrayClaimProposalTrackingModelPhaseFive)
								{
									if(modelClaimProposalTrackingPhaseFive.listModelClaimProposalTrackingGallery)
									{
										if(modelClaimProposalTrackingPhaseFive.CreatedBy !== 0 && componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseFive = true;
										}
										else if(modelClaimProposalTrackingPhaseFive.CreatedBy !== 0 && !componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseFive = false;
										}
										else if(modelClaimProposalTrackingPhaseFive.CreatedBy === 0)
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseFive = true;
										}
										else
										{
										}
									}
								}
							}
							else if(modelClaimProposalTracking.Phase === 6)
							{
								componentCurrent._arrayClaimProposalTrackingModelPhaseSix.push(modelClaimProposalTracking);
								componentCurrent._booleanStepperPhaseSix = true;

								for(const modelClaimProposalTrackingPhaseSix of componentCurrent._arrayClaimProposalTrackingModelPhaseSix)
								{
									if(modelClaimProposalTrackingPhaseSix.listModelClaimProposalTrackingGallery)
									{
										if(modelClaimProposalTrackingPhaseSix.CreatedBy !== 0 && componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseSix = true;
										}
										else if(modelClaimProposalTrackingPhaseSix.CreatedBy !== 0 && !componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseSix = false;
										}
										else if(modelClaimProposalTrackingPhaseSix.CreatedBy === 0)
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseSix = true;
										}
										else
										{
										}
									}
								}
							}
							else if(modelClaimProposalTracking.Phase === 7)
							{
								componentCurrent._arrayClaimProposalTrackingModelPhaseSeven.push(modelClaimProposalTracking);
								componentCurrent._booleanStepperPhaseSeven = true;

								for(const modelClaimProposalTrackingPhaseSeven of componentCurrent._arrayClaimProposalTrackingModelPhaseSeven)
								{
									if(modelClaimProposalTrackingPhaseSeven.listModelClaimProposalTrackingGallery)
									{
										if(modelClaimProposalTrackingPhaseSeven.CreatedBy !== 0 && componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseSeven = true;
										}
										else if(modelClaimProposalTrackingPhaseSeven.CreatedBy !== 0 && !componentCurrent._modelUserSignIn.checkUserGroupSignerForClaimTracking())
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseSeven = false;
										}
										else if(modelClaimProposalTrackingPhaseSeven.CreatedBy === 0)
										{
											componentCurrent._booleanCanEditForTrackingClaimPhaseSeven = true;
										}
										else
										{
										}
									}
								}
							}
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.updateLoadingProgress();

						// componentCurrent.callSelectClaimProposalQualityControlByClaimProposalToken(componentCurrent);

						componentCurrent.callSelectClaimProposalSatisfactionByClaimProposalToken(componentCurrent);
					}
					else
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.updateLoadingProgress();
						// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelClaimProposalRequest);
	}


	// .Update action date for tracking claim
	public callUpdateClaimProposalTrackingForPayment(modelClaimProposalTracking: ClaimProposalTrackingModel)
	{
		const componentCurrent: MonitoringDetailComponent = this;

		let numberHourse: number = 0;
		let numberMinutes: number = 0;

		componentCurrent._modelClaimProposalTracking = new ClaimProposalTrackingModel();

		if(modelClaimProposalTracking.Phase === ENUM_CLAIMPROPOSAL_TRACKING_PHASE.Processing)
		{
			numberHourse = Number(this._stringTimePrefixPhase2);
			numberMinutes = Number(this._stringTimeSuffixPhase2);

		}
		else if(modelClaimProposalTracking.Phase === ENUM_CLAIMPROPOSAL_TRACKING_PHASE.Finishing)
		{
			numberHourse = Number(this._stringTimePrefixPhase3);
			numberMinutes = Number(this._stringTimeSuffixPhase3);
		}
		else if(modelClaimProposalTracking.Phase === ENUM_CLAIMPROPOSAL_TRACKING_PHASE.PaymentAKU)
		{
			numberHourse = Number(this._stringTimePrefixPhase5);
			numberMinutes = Number(this._stringTimeSuffixPhase5);
		}
		else if(modelClaimProposalTracking.Phase === ENUM_CLAIMPROPOSAL_TRACKING_PHASE.PaymentWorkshop)
		{
			numberHourse = Number(this._stringTimePrefixPhase6);
			numberMinutes = Number(this._stringTimeSuffixPhase6);
		}
		else if(modelClaimProposalTracking.Phase === ENUM_CLAIMPROPOSAL_TRACKING_PHASE.PaymentInsured)
		{
			numberHourse = Number(this._stringTimePrefixPhase7);
			numberMinutes = Number(this._stringTimeSuffixPhase7);
		}
		else
		{
		}

		if(this._stringDateTemp !== undefined && this._stringDateTemp !== null)
		{
			this._dateTimeForStepper = this._stringDateTemp;
			this._dateTimeForStepper.setHours(numberHourse, numberMinutes, numberMinutes);
		}
		componentCurrent._functionUserInterface.setLoadingProgress(4);

		this._modelClaimProposalTracking.ActionDate = this._dateTimeForStepper?.toLocaleString();
		this._modelClaimProposalTracking.Token = modelClaimProposalTracking.Token;
		this._modelClaimProposalTracking.Action = modelClaimProposalTracking.Action;
		this._modelClaimProposalTracking.Phase = modelClaimProposalTracking.Phase;
		this._modelClaimProposalTracking.ClaimProposalToken = this._modelClaimProposal.Token;
		const modelResponseValidation: ResponseModel = this._modelClaimProposalTracking.validationUploadDate();
		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceClaimProposalTracking.updateClaimProposalTrackingByToken
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						componentCurrent._stringDateTemp = undefined;
						componentCurrent._dateTimeForStepper = undefined;
						componentCurrent._arrayClaimProposalTrackingModelPhaseOne = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseTwo = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseThree = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseFour = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseFive = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseSix = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseSeven = [];

						if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_SATISFACTION_LETTER)
						{
							componentCurrent._booleanUpdateDatetime = false;
						}
						else if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_VEHICLE_IN)
						{
							componentCurrent._booleanUpdateDatetimeForVehicleOut = false;
						}
						else if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_OFFICIAL_MEMO_AKU)
						{
							componentCurrent._booleanUpdateDatetimeForPaymentAKU = false;
						}
						else if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_AKU_PAYMENT_REPAIRSHOP)
						{
							componentCurrent._booleanUpdateDatetimeForPaymentWorkshop = false;
						}
						else if(modelClaimProposalTracking.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_AKU_PAYMENT_INSURED)
						{
							componentCurrent._booleanUpdateDatetimeForPaymentInsured = false;
						}

						componentCurrent._modelClaimProposal = new ClaimProposalModel();
						componentCurrent.callSelectClaimProposalByToken(componentCurrent, componentCurrent._modelClaimProposalTracking.ClaimProposalToken);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelClaimProposalTracking);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	}

	// .Insert for claim proposal tracking non mantle
	public callInsertClaimProposalTracking( enumClaimProposalTrackingPhase: ENUM_CLAIMPROPOSAL_TRACKING_PHASE)
	{
		const componentCurrent: MonitoringDetailComponent = this;
		let numberHourse: number = 0;
		let numberMinutes: number = 0;

		this._modelClaimProposalTracking = new ClaimProposalTrackingModel();

		if(enumClaimProposalTrackingPhase === ENUM_CLAIMPROPOSAL_TRACKING_PHASE.PaymentAKU)
		{
			numberHourse = Number(this._stringTimePrefixPhase5);
			numberMinutes = Number(this._stringTimeSuffixPhase5);
			this._modelClaimProposalTracking.Action = "Surat Permintaan Pembayaran";
			this._modelClaimProposalTracking.Phase = 5;
		}
		else if(enumClaimProposalTrackingPhase === ENUM_CLAIMPROPOSAL_TRACKING_PHASE.PaymentWorkshop)
		{
			numberHourse = Number(this._stringTimePrefixPhase6);
			numberMinutes = Number(this._stringTimeSuffixPhase6);
			this._modelClaimProposalTracking.Action = "Bagian Keuangan Melakukan Pembayaran ke Bengkel";
			this._modelClaimProposalTracking.Phase = 6;
		}
		else if(enumClaimProposalTrackingPhase === ENUM_CLAIMPROPOSAL_TRACKING_PHASE.PaymentInsured)
		{
			numberHourse = Number(this._stringTimePrefixPhase7);
			numberMinutes = Number(this._stringTimeSuffixPhase7);
			this._modelClaimProposalTracking.Action = "Bagian Keuangan Melakukan Pembayaran ke Tertanggung";
			this._modelClaimProposalTracking.Phase = 7;
		}
		else
		{
		}

		if(this._stringDateTemp !== undefined)
		{
			this._dateTimeForStepper = this._stringDateTemp;
			this._dateTimeForStepper.setHours(numberHourse, numberMinutes, numberMinutes);
		}

		this._modelClaimProposalTracking.ActionDate = this._dateTimeForStepper?.toLocaleString();
		this._modelClaimProposalTracking.ClaimProposalToken = this._modelClaimProposal.Token;

		componentCurrent._functionUserInterface.setLoadingProgress(4);


		const modelResponseValidation: ResponseModel = this._modelClaimProposalTracking.validationInsertDate();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceClaimProposalTracking.insertClaimProposalTracking
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						componentCurrent._dateTimeForStepper = undefined;
						componentCurrent._stringDateTemp = undefined;
						componentCurrent.callSelectClaimProposalByToken(componentCurrent, componentCurrent._modelClaimProposal.Token);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelClaimProposalTracking);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	}


	// .Insert satisfaction survey
	public callInsertClaimProposalSatisfaction(componentCurrent: MonitoringDetailComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		componentCurrent._arrayModelClaimProposalSatisfactionModel = [];

		this.getRatingForSurveySatisfaction();


		componentCurrent._serviceSatisfactionSurvey.insertClaimProposalSatisfactionForWebsite
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();

					componentCurrent.booleanTrackingAfterSatisfaction(true);
				}
				else
				{
					componentCurrent._arrayModelClaimProposalSatisfactionModel = [];
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._arrayModelClaimProposalSatisfactionModel);
	}


	// .Get detail for satisfaction survey
	public callSelectClaimProposalSatisfactionByClaimProposalToken(componentCurrent: MonitoringDetailComponent): void
	{
		componentCurrent._numberBRINSQuestionOne = undefined;
		componentCurrent._numberBRINSQuestionTwo = undefined;
		componentCurrent._numberBRINSQuestionThree = undefined;
		componentCurrent._numberWorkshopQuestionOne = undefined;
		componentCurrent._numberWorkshopQuestionTwo = undefined;
		componentCurrent._numberWorkshopQuestionThree = undefined;
		componentCurrent._numberWorkshopQuestionFour = undefined;
		componentCurrent._modelClaimProposalSatisfactionSurveyWorkshopFeedback.Feedback = undefined;
		componentCurrent._modelClaimProposalSatisfactionSurveyBRINSFeedback.Feedback = undefined;
		componentCurrent._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback = undefined;
		componentCurrent._booleanRecomendation = null;

		componentCurrent._arrayModelClaimProposalSatisfactionQuestionForBRINS = [];
		componentCurrent._arrayModelClaimProposalSatisfactionQuestionForWorkshop = [];
		componentCurrent._arrayModelClaimProposalSatisfactionSurveyRecomendationItem = [];
		this._modelClaimProposalForDetailSatisfaction.Token = this._modelClaimProposalRequest.Token;


		componentCurrent._serviceSatisfactionSurvey.selectClaimProposalSatisfactionByClaimProposalToken
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._booleanFormSatisfactionSurveyTrackingClaim = true;

						componentCurrent._arrayModelClaimProposalSatisfactionModel = JSON.parse(modelResponse.Data);
						if(componentCurrent._arrayModelClaimProposalSatisfactionModel !== undefined)
						{
							for(const modelClaimProposalSatisfaction of componentCurrent._arrayModelClaimProposalSatisfactionModel)
							{
								if(componentCurrent._booleanRatingForSatisfactionSurvey === true)
								{
									if(modelClaimProposalSatisfaction.modelClaimProposalSatisfactionQuestion?.QuestionFor === ENUM_QUESTION_FOR.BRINS)
									{
										componentCurrent._arrayModelClaimProposalSatisfactionQuestionForBRINS.push(modelClaimProposalSatisfaction);

										let numberTotalRating = 0;
										let numberRateStars = 0;

										// eslint-disable-next-line @typescript-eslint/prefer-for-of
										for (let numberIndex = 0; numberIndex < componentCurrent._arrayModelClaimProposalSatisfactionQuestionForBRINS.length; numberIndex ++)
										{
											const numberRating = componentCurrent._arrayModelClaimProposalSatisfactionQuestionForBRINS[numberIndex].Rating;
											if(numberRating)
											{
												numberTotalRating += numberRating;
												numberRateStars = numberTotalRating / 3;
												if (numberRateStars % 1 === 0)
												{
													componentCurrent._numberTotalRatingForBRINS = numberRateStars;
												}
												else
												{
													componentCurrent._numberTotalRatingForBRINS = numberRateStars.toFixed(1);
												}
											}
										}
									}
									else if(modelClaimProposalSatisfaction.modelClaimProposalSatisfactionQuestion?.QuestionFor === ENUM_QUESTION_FOR.Workshop)
									{
										componentCurrent._arrayModelClaimProposalSatisfactionQuestionForWorkshop.push(modelClaimProposalSatisfaction);

										let numberTotalRating = 0;
										let numberRateStars = 0;

										// eslint-disable-next-line @typescript-eslint/prefer-for-of
										for (let numberIndex = 0; numberIndex < componentCurrent._arrayModelClaimProposalSatisfactionQuestionForWorkshop.length; numberIndex ++)
										{
											const numberRating = componentCurrent._arrayModelClaimProposalSatisfactionQuestionForWorkshop[numberIndex].Rating;
											if(numberRating)
											{
												numberTotalRating += numberRating;
												numberRateStars = numberTotalRating / 4;
												if (numberRateStars % 1 === 0)
												{
													componentCurrent._numberTotalRatingForWorkshop = numberRateStars;
												}
												else
												{
													componentCurrent._numberTotalRatingForWorkshop = numberRateStars.toFixed(1);
												}
											}
										}
									}
									else if(modelClaimProposalSatisfaction.modelClaimProposalSatisfactionQuestion?.QuestionFor === ENUM_QUESTION_FOR.FeedbackBRINS)
									{
										componentCurrent._modelClaimProposalSatisfactionSurveyBRINSFeedback.Feedback = modelClaimProposalSatisfaction.Feedback;

									}
									else if(modelClaimProposalSatisfaction.modelClaimProposalSatisfactionQuestion?.QuestionFor === ENUM_QUESTION_FOR.FeedbackWorkshop)
									{
										componentCurrent._modelClaimProposalSatisfactionSurveyWorkshopFeedback.Feedback = modelClaimProposalSatisfaction.Feedback;

									}
									else if(modelClaimProposalSatisfaction.modelClaimProposalSatisfactionQuestion?.QuestionFor === ENUM_QUESTION_FOR.Recommendation)
									{
										componentCurrent._modelClaimProposalSatisfactionSurveyRecomendationFeedback = modelClaimProposalSatisfaction;
									}
									else if(modelClaimProposalSatisfaction.modelClaimProposalSatisfactionQuestion?.QuestionFor === ENUM_QUESTION_FOR.RecommendationItems)
									{
										componentCurrent._arrayModelClaimProposalSatisfactionSurveyRecomendationItem.push(modelClaimProposalSatisfaction);
									}
								}

							}

						}
						componentCurrent._booleanReadOnly = true;

						if(componentCurrent._modelClaimProposal.ClaimNumber !== undefined && componentCurrent._modelClaimProposal.ClaimNumber !== null && componentCurrent._modelClaimProposal.ClaimNumber !== "")
						{
							componentCurrent.callSelectClaimProposalTrackingPayment(componentCurrent);
						}

					}
					else
					{
						if(componentCurrent._modelClaimProposal.ClaimNumber !== undefined && componentCurrent._modelClaimProposal.ClaimNumber !== null && componentCurrent._modelClaimProposal.ClaimNumber !== "")
						{
							componentCurrent.callSelectClaimProposalTrackingPayment(componentCurrent);
						}
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					if(componentCurrent._modelClaimProposal.ClaimNumber !== undefined && componentCurrent._modelClaimProposal.ClaimNumber !== null && componentCurrent._modelClaimProposal.ClaimNumber !== "")
					{
						componentCurrent.callSelectClaimProposalTrackingPayment(componentCurrent);
					}
					componentCurrent._booleanReadOnly = false;
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelClaimProposalForDetailSatisfaction);
	}

	// .Get Question for satisfaction survey
	public callSelectClaimProposalSatisfactionQuestion(componentCurrent: MonitoringDetailComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._arrayModelClaimProposalSatisfactionQuestionForBRINS = [];
		this._arrayModelClaimProposalSatisfactionQuestionForWorkshop = [];
		this._arrayModelClaimProposalSatisfactionQuestionForRecomendationItem = [];

		this._modelTable.RowPerPage = 100;

		componentCurrent._serviceSatisfactionSurveyQuestion.selectClaimProposalSatisfactionQuestion
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);

						if(componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelClaimProposalSatisfactionQuestionModel = JSON.parse(componentCurrent._modelTable.Result);

							for(const modelClaimProposalSatisfactionQuestion of componentCurrent._arrayModelClaimProposalSatisfactionQuestionModel)
							{
								if(modelClaimProposalSatisfactionQuestion.QuestionFor === ENUM_QUESTION_FOR.BRINS)
								{
									componentCurrent._arrayModelClaimProposalSatisfactionQuestionForBRINS.push(modelClaimProposalSatisfactionQuestion);
								}
								else if(modelClaimProposalSatisfactionQuestion.QuestionFor === ENUM_QUESTION_FOR.Workshop)
								{
									componentCurrent._arrayModelClaimProposalSatisfactionQuestionForWorkshop.push(modelClaimProposalSatisfactionQuestion);
								}
								else if(modelClaimProposalSatisfactionQuestion.QuestionFor === ENUM_QUESTION_FOR.FeedbackBRINS)
								{
									componentCurrent._arrayModelClaimProposalSatisfactionQuestionForBRINSFeedback.push(modelClaimProposalSatisfactionQuestion);
								}
								else if(modelClaimProposalSatisfactionQuestion.QuestionFor === ENUM_QUESTION_FOR.FeedbackWorkshop)
								{
									componentCurrent._arrayModelClaimProposalSatisfactionQuestionForWorkshopFeedback.push(modelClaimProposalSatisfactionQuestion);
								}
								else if(modelClaimProposalSatisfactionQuestion.QuestionFor === ENUM_QUESTION_FOR.Recommendation)
								{
									componentCurrent._modelClaimProposalSatisfactionQuestionForRecomendationFeedback = modelClaimProposalSatisfactionQuestion;
								}
								else if(modelClaimProposalSatisfactionQuestion.QuestionFor === ENUM_QUESTION_FOR.RecommendationItems)
								{
									componentCurrent._arrayModelClaimProposalSatisfactionQuestionForRecomendationItem.push(modelClaimProposalSatisfactionQuestion);
								}
								else
								{
								}
							}
							componentCurrent.getQuestionRecomendationItem();

							componentCurrent._modelTable.Result = undefined;
						}
						else
						{
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	//. Get control quality
	public callSelectClaimProposalQualityControlByClaimProposalToken(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelClaimProposalRequest: ClaimProposalModel = new ClaimProposalModel();
		modelClaimProposalRequest.Token = this._modelClaimProposal.Token;

		componentCurrent._serviceClaimProposalTracking.selectClaimProposalQualityControlByClaimProposalToken
		({
			success(modelResponse: ResponseModel): void
			{
				if(modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent._arrayModelClaimProposalQualityControl = [];

					if(modelResponse.Data !== undefined)
					{
						componentCurrent._arrayModelClaimProposalQualityControl = JSON.parse(modelResponse.Data);
					}
					else
					{
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelClaimProposalRequest);
	}


	// .Export pdf for tracking claim
	public callDownloadClaimProposalTrackingByClaimProposalTokenForClaim(componentCurrent: this)
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		this._modelClaimProposalRequest.Token = this._modelClaimProposal.Token;

		componentCurrent._serviceClaimProposalTracking.downloadClaimProposalTrackingByClaimProposalTokenForClaim
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelClaimProposalTrackingDownloadResponse: ClaimProposalTrackingGalleryModel = new ClaimProposalTrackingGalleryModel();
						modelClaimProposalTrackingDownloadResponse.setModelFromString(modelResponse.Data);

						modelClaimProposalTrackingDownloadResponse.Name = "Claim Tracking " + componentCurrent._modelClaimProposal.TicketNumber;
						modelClaimProposalTrackingDownloadResponse.Format = "pdf";
						modelClaimProposalTrackingDownloadResponse.Extension = "pdf";

						if (componentCurrent._modelClaimProposal !== undefined)
						{

							componentCurrent._functionUserInterface.downloadFileBlob(modelClaimProposalTrackingDownloadResponse.Name,modelClaimProposalTrackingDownloadResponse.Format, modelClaimProposalTrackingDownloadResponse.Extension, modelClaimProposalTrackingDownloadResponse.Data);

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadClaimProposalTrackingByClaimProposalTokenForClaim(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelClaimProposalRequest);
	}

	public callDeleteGalleryByToken(componentCurrent: this, stringToken: string): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		const modelClaimProposalTrackingGallery: ClaimProposalTrackingGalleryModel = new ClaimProposalTrackingGalleryModel();
		modelClaimProposalTrackingGallery.Token = stringToken;

		const modelResponseValidation: ResponseModel = modelClaimProposalTrackingGallery.validateToken();

		if(modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceClaimProposalTracking.deleteGalleryByToken
			({
				success(modelResponse: ResponseModel): void
				{
					if(modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{

					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDeleteGalleryByToken(componentCurrent, stringToken); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut();});
				}
			}, modelClaimProposalTrackingGallery);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}


	}


	private callSelectPolicyGeneralByAcceptanceNumber(componentCurrent: this): void
	{
		componentCurrent._servicePolicyGeneral.selectPolicyGeneralByAcceptanceNumber
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyGeneral.setModelFromString(modelResponse.Data);
						if(!componentCurrent._booleanMV)
						{
							if (componentCurrent._modelPolicyGeneral.PolicyNumber)
							{
								componentCurrent._modelPayment.PolicyNumber = componentCurrent._modelPolicyGeneral.PolicyNumber;
								componentCurrent.callSelectPaymentByPolicyNumber(componentCurrent);
							}
							else
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}

						if(componentCurrent._modelPolicyGeneral.ProfileID !== undefined && componentCurrent._modelPolicyGeneral.ProfileID !== null && componentCurrent._modelPolicyGeneral.ProfileID !== "")
						{
							componentCurrent.callselectPolicyGeneralByProfileID(componentCurrent);
						}
						else
						{
							if(componentCurrent._booleanMV)
							{
								componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent);
							}
							else
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
					}
					if(componentCurrent._modelPolicyGeneral.PolicyStartDate !== undefined)
					{
						const datePolicyStart: Date = new Date(componentCurrent._modelPolicyGeneral.PolicyStartDate);
						componentCurrent._modelPolicyGeneral.PolicyStartDate = datePolicyStart;
					}
					if(componentCurrent._modelPolicyGeneral.PolicyEndDate !== undefined)
					{
						const datePolicyEnd: Date = new Date(componentCurrent._modelPolicyGeneral.PolicyEndDate);
						componentCurrent._modelPolicyGeneral.PolicyEndDate = datePolicyEnd;
					}
					componentCurrent._arrayNumberPolicyYear = componentCurrent.getArrayNumberPolicyYear(componentCurrent._modelPolicyGeneral);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			// eslint-disable-next-line no-unused-vars
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPolicyGeneralByAcceptanceNumber(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyGeneralRequest);
	};

	private callSelectPolicyGeneralByPolicyNumberForClaim(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.Search = JSON.stringify(componentCurrent._modelPolicyGeneral);

		componentCurrent._servicePolicyGeneral.selectPolicyGeneralByPolicyNumberForClaim
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						modelTable.setModelFromString(modelResponse.Data);

						if (modelTable.Result !== undefined)
						{
							const arrayModelPolicyGeneralTemp = JSON.parse(modelTable.Result);
							modelTable.Result = undefined;
							componentCurrent._modelPolicyGeneral = new PolicyGeneralModel();
							componentCurrent._modelPolicyGeneral.setModelFromObject(arrayModelPolicyGeneralTemp[0]);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						if(!componentCurrent._booleanMV)
						{
							if (componentCurrent._modelPolicyGeneral.PolicyNumber)
							{
								componentCurrent._modelPayment.PolicyNumber = componentCurrent._modelPolicyGeneral.PolicyNumber;
								componentCurrent.callSelectPaymentByPolicyNumber(componentCurrent);
							}
							else
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}

						if(componentCurrent._modelPolicyGeneral.ProfileID !== undefined && componentCurrent._modelPolicyGeneral.ProfileID !== null && componentCurrent._modelPolicyGeneral.ProfileID !== "")
						{
							componentCurrent.callselectPolicyGeneralByProfileID(componentCurrent);
						}
						else
						{
							if(componentCurrent._booleanMV)
							{
								componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent);
							}
							else
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
					}
					if(componentCurrent._modelPolicyGeneral.PolicyStartDate !== undefined)
					{
						const datePolicyStart: Date = new Date(componentCurrent._modelPolicyGeneral.PolicyStartDate);
						componentCurrent._modelPolicyGeneral.PolicyStartDate = datePolicyStart;
					}
					if(componentCurrent._modelPolicyGeneral.PolicyEndDate !== undefined)
					{
						const datePolicyEnd: Date = new Date(componentCurrent._modelPolicyGeneral.PolicyEndDate);
						componentCurrent._modelPolicyGeneral.PolicyEndDate = datePolicyEnd;
					}
					componentCurrent._arrayNumberPolicyYear = componentCurrent.getArrayNumberPolicyYear(componentCurrent._modelPolicyGeneral);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			// eslint-disable-next-line no-unused-vars
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPolicyGeneralByPolicyNumberForClaim(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	};

	private callSelectPolicyGeneralByReferenceNumberForClaim(componentCurrent: this): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.Search = JSON.stringify(componentCurrent._modelPolicyGeneral);

		componentCurrent._servicePolicyGeneral.selectPolicyGeneralByReferenceNumberForClaim
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						modelTable.setModelFromString(modelResponse.Data);

						if (modelTable.Result !== undefined)
						{
							const modelPolicyGeneralTemp: PolicyGeneralModel = JSON.parse(modelTable.Result);
							modelTable.Result = undefined;
							componentCurrent._modelPolicyGeneral = new PolicyGeneralModel();
							componentCurrent._modelPolicyGeneral.setModelFromObject(modelPolicyGeneralTemp);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}

						if(!componentCurrent._booleanMV)
						{
							if (componentCurrent._modelPolicyGeneral.PolicyNumber)
							{
								componentCurrent._modelPayment.PolicyNumber = componentCurrent._modelPolicyGeneral.PolicyNumber;
								componentCurrent.callSelectPaymentByPolicyNumber(componentCurrent);
							}
							else
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}

						if(componentCurrent._modelPolicyGeneral.ProfileID !== undefined && componentCurrent._modelPolicyGeneral.ProfileID !== null && componentCurrent._modelPolicyGeneral.ProfileID !== "")
						{
							componentCurrent.callselectPolicyGeneralByProfileID(componentCurrent);
						}
						else
						{
							if(componentCurrent._booleanMV)
							{
								componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent);
							}
							else
							{
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
					}
					if(componentCurrent._modelPolicyGeneral.PolicyStartDate !== undefined)
					{
						const datePolicyStart: Date = new Date(componentCurrent._modelPolicyGeneral.PolicyStartDate);
						componentCurrent._modelPolicyGeneral.PolicyStartDate = datePolicyStart;
					}
					if(componentCurrent._modelPolicyGeneral.PolicyEndDate !== undefined)
					{
						const datePolicyEnd: Date = new Date(componentCurrent._modelPolicyGeneral.PolicyEndDate);
						componentCurrent._modelPolicyGeneral.PolicyEndDate = datePolicyEnd;
					}
					componentCurrent._arrayNumberPolicyYear = componentCurrent.getArrayNumberPolicyYear(componentCurrent._modelPolicyGeneral);
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			// eslint-disable-next-line no-unused-vars
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPolicyGeneralByReferenceNumberForClaim(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	};

	private callselectPolicyGeneralByProfileID(componentCurrent: this): void
	{
		componentCurrent._modelCustomer.ProfileID = componentCurrent._modelPolicyGeneral.ProfileID;

		componentCurrent._serviceCustomer.selectCustomerByProfileID
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{

						componentCurrent._modelCustomer.setModelFromString(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();


						if(componentCurrent._booleanMV)
						{
							componentCurrent.callSelectProductGeneralByPolicy(componentCurrent);
						}

					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyGeneralByProfileID(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelCustomer);
	};


	public callSendEmailClaimProposalByClaimCenter(componentCurrent: this): void
	{
		const modelClaimProposal: ClaimProposalModel = new ClaimProposalModel();
		modelClaimProposal.Token = componentCurrent._modelClaimProposal.Token;
		modelClaimProposal.listModelSurvey?.push(componentCurrent._modelSurvey);

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelResponseValidation: ResponseModel = componentCurrent._modelSurvey.validateforEmail();
		if(modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{

			componentCurrent._serviceClaimProposal.sendEmailClaimProposalByClaimCenter
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSendEmailClaimProposalByClaimCenter(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelClaimProposal);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}


	private callSelectPaymentByPolicyNumber(componentCurrent: this): void
	{
		this._modelTablePayment.Search = JSON.stringify(this._modelPayment);
		this._servicePayment.selectPaymentByPolicyNumber
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePayment.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePayment.Result !== undefined)
						{
							componentCurrent._arrayModelPayment = JSON.parse(componentCurrent._modelTablePayment.Result);
							componentCurrent._modelTablePayment.Result = undefined;
							if(componentCurrent._arrayModelPayment.length > 0)
							{
								componentCurrent._componentTableControlIncludePayment.setButtonState();
							}
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPaymentByPolicyNumber(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelTablePayment);
	};

	// .Upload picture for tracking claim
	public callUploadClaimProposalTracking(claimProposalModelTrackingModel: ClaimProposalTrackingModel)
	{
		const componentCurrent: MonitoringDetailComponent = this;

		componentCurrent._functionUserInterface.setLoadingProgress(4);

		if(claimProposalModelTrackingModel.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_START_REPAIR_VEHICLE)
		{
			this._modelClaimProposalTrackingUpload.listModelClaimProposalTrackingGallery = this._arrayModelClaimProposalTrackingGalleryForVehicleIn;
		}
		else if(claimProposalModelTrackingModel.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_SATISFACTION_LETTER)
		{
			this._modelClaimProposalTrackingUpload.listModelClaimProposalTrackingGallery = this._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter;
		}
		else if(claimProposalModelTrackingModel.Action?.toUpperCase() === StringConstant.STRING_TRACKING_CLAIM_VEHICLE_OUT)
		{
			this._modelClaimProposalTrackingUpload.listModelClaimProposalTrackingGallery = this._arrayModelClaimProposalTrackingGalleryForVehicleOut;
		}

		this._modelClaimProposalTrackingUpload.Token = claimProposalModelTrackingModel.Token;

		componentCurrent._serviceClaimProposalTracking.uploadClaimProposalTracking
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._booleanCanEditForTrackingClaim = false;

						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._modelClaimProposalTracking.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.updateLoadingProgress();

						componentCurrent._modelClaimProposal = new ClaimProposalModel();

						componentCurrent._arrayClaimProposalTrackingModelPhaseTwo = [];
						componentCurrent._arrayClaimProposalTrackingModelPhaseThree = [];
						componentCurrent._arrayModelClaimProposalTrackingGalleryForVehicleOut = [];
						componentCurrent._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter = [];

						const stringToken: any = claimProposalModelTrackingModel.ClaimProposalToken;
						componentCurrent.callSelectClaimProposalByToken(componentCurrent,stringToken);
					}
					else
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelClaimProposalTrackingUpload);
	}


	private callSelectProductGeneralByPolicy(componentCurrent: this): void
	{
		// componentCurrent._functionUserInterface.setLoadingProgress(2);
		const modelProductGeneral: ProductGeneralModel = new ProductGeneralModel();
		modelProductGeneral.PolicyNumber = componentCurrent._modelPolicyGeneral.PolicyNumber;

		componentCurrent._serviceProductGeneral.selectProductGeneralByPolicy
		(
			{
				success(modelResponse: ResponseModel): void
				{
					if(modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{

						if(modelResponse.Data !== undefined)
						{
							componentCurrent._modelProductGeneral.setModelFromString(modelResponse.Data);

							componentCurrent._functionUserInterface.updateLoadingProgress();

						}
						else
						{
							// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});
						}

						if(componentCurrent._booleanMV)
						{
							componentCurrent.callSelectClaimProposalTrackingByClaimProposalToken(componentCurrent);
						}
						else
						{
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, ()=> { componentCurrent.callSelectProductGeneralByPolicy(componentCurrent);});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut();});
				}
			}, modelProductGeneral);
	}

	private callSelectClaimProposalTrackingPayment(componentCurrent: this): void
	{

		const modelClaimProposalReq: ClaimProposalModel = new ClaimProposalModel();
		modelClaimProposalReq.Token = componentCurrent._modelClaimProposal.Token;

		componentCurrent._serviceClaimProposalTracking.selectClaimProposalTrackingPayment
		({
			success(modelResponse: ResponseModel): void
			{
				if(modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					if(modelResponse.Data !== undefined)
					{
						componentCurrent._modelStoredClaimDetail.setModelFromString(modelResponse.Data);
					}
					else
					{
						// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, ()=> { componentCurrent.callSelectClaimProposalTrackingPayment(componentCurrent);});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut();});
			}
		}, modelClaimProposalReq);
	}

	//#endregion


	//#region NAVIGATION

	goToList(): void
	{
		this._router.navigate(["home", "monitoring", "list"]);
	}

	goToDetail(): void
	{
		this._router.navigate(["home", "monitoring", "detail", this._modelClaimProposalTrackingPhaseThree.Token]);
		this.booleanTracking(true);
	}

	//#endregion


	//#region VALIDATION

	public validateButtonSubmit(): void
	{

		if(this._booleanTLO === true)
		{
			if(this._numberBRINSQuestionOne && this._numberBRINSQuestionTwo && this._numberBRINSQuestionThree && this._numberWorkshopQuestionOne && this._numberWorkshopQuestionTwo && this._numberWorkshopQuestionThree && this._numberWorkshopQuestionFour && this._booleanRecomendation !== null)
			{
				if(this._booleanNotesForMore === true)
				{
					if(this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback !== undefined && this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback !== null && this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback !== "")
					{
						this._booleanReadOnly = false;
					}
					else
					{
						this._booleanReadOnly = true;
					}

				}
				else
				{
					if((this._modelClaimProposalSatisfactionSurveyForRecomendationItemEasyReporting.Feedback ||
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastRepair.Feedback ||
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastResponse.Feedback ||
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemFastService.Feedback ||
					this._modelClaimProposalSatisfactionSurveyForRecomendationItemRepairQuality.Feedback))
					{
						this._booleanReadOnly = false;
					}
					else
					{
						this._booleanReadOnly = true;
					}
				}

			}
		}
		else
		{
			if(this._numberBRINSQuestionThree !== undefined && this._numberBRINSQuestionTwo !== undefined && this._numberBRINSQuestionOne !== undefined)
			{
				this._booleanReadOnly = false;
			}
		}

	}

	//#endregion

	//#region SETTER

	private setActionDate(dateStart?: Date): void
	{
		if (dateStart !== undefined && dateStart !== null)
		{
			// this._modelClaimProposalTrackingForInsert.ActionDate = dateStart.toString();
			this._stringDateTemp = dateStart;
		}
		else
		{

		}
	}

	private setStartDate(dateStart?: Date): void
	{
		if (dateStart !== undefined)
		{
			this._modelClaimProposal.IncidentDate = dateStart;
		}
		else
		{

		}
	}

	setEventEmitterSelectPayment(modelTableUpdate: TableModel): void
	{
		this._modelTablePayment = modelTableUpdate;
		this._functionUserInterface.setLoadingProgress(1);
		this.callSelectPaymentByPolicyNumber(this);
	}

	//#endregion


	//#region GETTER

	getStartDate(event: any): void
	{
		this.setStartDate(event.value);
	};

	getActionDate(event: any): void
	{
		this.setActionDate(event.value);
	}

	getTimeHours(): Array<string>
	{
		const arrayNumberTimeHours: Array<string> = [];
		const numberMaxTimeHours: number = 24;

		for (let numberIndex = 0; numberIndex < numberMaxTimeHours; numberIndex++)
		{
			if(numberIndex <= 9)
			{
				const stringHour = "0"+numberIndex.toString();
				arrayNumberTimeHours.push(stringHour);
			}
			else
			{
				arrayNumberTimeHours.push(numberIndex.toString());
			}
		}

		return arrayNumberTimeHours;
	}

	getTimeMinutes(): Array<string>
	{
		const arrayNumberTimeMinutes: Array<string> = [];
		const numberMaxTimeMinutes: number = 60;

		for (let numberIndex = 0; numberIndex < numberMaxTimeMinutes; numberIndex++)
		{
			if(numberIndex <= 9)
			{
				const stringHour = "0"+numberIndex.toString();
				arrayNumberTimeMinutes.push(stringHour);
			}
			else
			{
				arrayNumberTimeMinutes.push(numberIndex.toString());
			}
		}

		return arrayNumberTimeMinutes;
	}


	getListSurveyThirdPartyLiabilitySupportingDocument(modelSurveyThirdPartyLiability: SurveyThirdPartyLiabilityModel): Array<SurveyThirdPartyLiabilityGalleryModel>
	{
		const arrayModelThirfPartyLiabilityGallerySupportingDocument: Array<SurveyThirdPartyLiabilityGalleryModel> = [];

		if(modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery && modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery?.length !== 0)
		{
			for(const modelThirdPartyLiabilitySupportingDocumentGallery of modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery)
			{
				if(modelThirdPartyLiabilitySupportingDocumentGallery.Type === this._enumPhotoType.SupportingDocument)
				{
					arrayModelThirfPartyLiabilityGallerySupportingDocument.push(modelThirdPartyLiabilitySupportingDocumentGallery);
				}
			}
		}

		return arrayModelThirfPartyLiabilityGallerySupportingDocument;
	}

	getListModelSurveySupportingDocument(modelSurvey: SurveyModel): Array<SurveyGalleryModel>
	{
		const arrayModelSurveyGallerySupportingDocument: Array<SurveyGalleryModel> = [];

		if(modelSurvey.listModelSurveyGallery && modelSurvey.listModelSurveyGallery?.length !== 0)
		{
			for(const modelThirdPartyLiabilitySupportingDocumentGallery of modelSurvey.listModelSurveyGallery)
			{
				if(modelThirdPartyLiabilitySupportingDocumentGallery.Type === this._enumPhotoType.SupportingDocument)
				{
					arrayModelSurveyGallerySupportingDocument.push(modelThirdPartyLiabilitySupportingDocumentGallery);
				}
			}
		}

		return arrayModelSurveyGallerySupportingDocument;
	}


	booleanCustomer(booleanB: boolean)
	{
		this._booleanCustomer = booleanB;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = false;
	}

	booleanPolicyHolder(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = booleanB;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = false;
	}

	booleanSubmission(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = booleanB;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = false;
	}

	booleanDocument(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = false;
		this._booleanDocument = booleanB;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = false;
	}

	booleanApproval(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = booleanB;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = false;
	}

	booleanSurvey(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = booleanB;
		this._booleanHistory = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = false;
	}

	booleanHistory(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = false;
		this._booleanHistory = booleanB;
	}

	booleanTracking(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanSatisfactionSurvey = false;
		this._booleanTracking = booleanB;
	}

	booleanTrackingAfterSatisfaction(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanSatisfactionSurvey = false;
		this._booleanTracking = booleanB;
		this._booleanRatingForSatisfactionSurvey = true;

		this.callSelectClaimProposalByToken(this, this._modelClaimProposal.Token);

	}

	booleanSatisfactionSurvey(booleanB: boolean)
	{
		this._booleanCustomer = false;
		this._booleanPolicyHolder = false;
		this._booleanSubmission = false;
		this._booleanDocument = false;
		this._booleanApproval = false;
		this._booleanSurvey = false;
		this._booleanHistory = false;
		this._booleanTracking = false;
		this._booleanSatisfactionSurvey = booleanB;

		this._booleanRatingForSatisfactionSurvey = false;

		if(this._booleanForInsertSatisfactionSurvey === true)
		{
		}
		else
		{
			this.callSelectClaimProposalSatisfactionQuestion(this);
		}

		this.disabledButtonSubmit();
	}

	//#endregion
}

//#endregion

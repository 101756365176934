//#region IMPORT

import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_BIDDING_TYPE, ENUM_CLAIMPAID_STATUS, ENUM_PHOTO_TYPE, ENUM_SPPA_STATUS } from "src/app/constants/enum.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { PolicyGeneralModel } from "src/app/models/policygeneral.model";
import { PolicyGeneralService } from "src/app/services/policygeneral.service";
import { SessionService } from "src/app/services/session.service";
import { SalvageModel } from "src/app/models/salvage.model";
import { SalvageGalleryModel } from "src/app/models/salvagegallery.model";
import { SalvageItemModel } from "src/app/models/salvageitem.model";
import { BaseAuthourizeDetailComponent } from "../../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-salvage.detail",
		templateUrl: "./salvage.detail.component.html",
		styleUrls: ["./salvage.detail.component.sass"]
	}
)

//#endregion


//#region CLASS

export class SalvageDetailComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _modelPolicyGeneral: PolicyGeneralModel;
	public _modelSalvage: SalvageModel;
	public _modelTable: TableModel = new TableModel();

	public _arrayModelSalvageGallery: Array<SalvageGalleryModel>;

	public _enumPhotoType = ENUM_PHOTO_TYPE;
	public _enumBiddingType = ENUM_BIDDING_TYPE;
	public _enumClaimPaidStatus = ENUM_CLAIMPAID_STATUS;

	//#endregion


	//#region  CONSTRUCTOR

	// eslint-disable-next-line max-len
	constructor(routeActivated: ActivatedRoute, location: Location, private _servicePolicyGeneral: PolicyGeneralService, serviceSession: SessionService, public router: Router)
	{
		super(routeActivated, location, serviceSession, router);

		this._arrayModelSalvageGallery = [];

		this._modelPolicyGeneral = new PolicyGeneralModel();
		this._modelPolicyGeneral.PolicyholderFullName = "Alvin";
		this._modelPolicyGeneral.PolicyNumber = "1234567890";
		this._modelPolicyGeneral.ProductName = "Oto";
		this._modelPolicyGeneral.BranchName = "Jakarta 1";
		this._modelPolicyGeneral.RelationshipManagerFirstName = "Febriyanto";
		this._modelPolicyGeneral.RelationshipManagerMiddleName = "Rahman";
		this._modelPolicyGeneral.RelationshipManagerLastName = "";
		this._modelPolicyGeneral.PolicyStartDate = new Date();
		this._modelPolicyGeneral.PolicyEndDate = new Date();
		this._modelPolicyGeneral.PolicyPaymentDate = new Date();
		this._modelPolicyGeneral.Status = ENUM_SPPA_STATUS.Approved;
		this._modelPolicyGeneral.PolicyActiveStatus = true;
		this._modelPolicyGeneral.PolicyPeriodeStatus = true;
		this._modelPolicyGeneral.PolicyPaymentStatus = true;
		this._modelPolicyGeneral.PolicyGracePeriodeStatus = false;
		this._modelPolicyGeneral.PremiumAmount = 500000;
		this._modelPolicyGeneral.PolicyPaymentDate = new Date();

		this._modelSalvage = new SalvageModel();
		this._modelSalvage.ClaimPaidStatus = ENUM_CLAIMPAID_STATUS.Paid;
		this._modelSalvage.SalvageLocation = "Jakarta utara";
		this._modelSalvage.BiddingType = ENUM_BIDDING_TYPE.MotorVehicle;
		this._modelSalvage.ClaimPaidDate = new Date();
		this._modelSalvage.AdditionalCost = 0;

		this._modelSalvage.arrayModelSalvageItem = [];
	}

	//#endregion


	//#region ON INITIALIZATION

	ngOnInit(): void
	{
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringToken != null)
		{
			this._modelSalvage.Token = stringToken;

			// this.callSelectClaimProposalByToken(this, stringToken);
			this.setArrayModelSalvageGallery();
			this.setArrayModelSalvageItem();
		}
		else
		{
			this.goToList();
		}
	}

	//#endregion


	//#region FUNCTION

	setArrayModelSalvageGallery(): void
	{
		this._arrayModelSalvageGallery = [];
		this._modelSalvage.arrayModelSalvageGallery = [];

		let modelSalvageGallery: SalvageGalleryModel = new SalvageGalleryModel();
		modelSalvageGallery.Type = ENUM_PHOTO_TYPE.SalvageReport;
		modelSalvageGallery.Mandatory = true;
		modelSalvageGallery.Name = "Salvage report.pdf";
		this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

		modelSalvageGallery = new SalvageGalleryModel();
		modelSalvageGallery.Type = ENUM_PHOTO_TYPE.SalvageHandoverLetter;
		modelSalvageGallery.Mandatory = true;
		modelSalvageGallery.Name = "Salvage handover letter.pdf";
		this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

		if (this._modelSalvage.BiddingType === ENUM_BIDDING_TYPE.MotorVehicle)
		{
			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.BPKB;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "BPKB.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.VehicleKey;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "Vehicle key.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.ReleaseRightLetter;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "Release right letter.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.KwitansiTripleStamp;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "Kwitansi tripple stamp.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);

			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.VehicleReceipt;
			modelSalvageGallery.Mandatory = true;
			modelSalvageGallery.Name = "Vehicle receipt.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);
		}
		else
		{

		}

		if (this._modelSalvage.ClaimPaidDate == null)
		{
			modelSalvageGallery = new SalvageGalleryModel();
			modelSalvageGallery.Type = ENUM_PHOTO_TYPE.FormSalvageBidding;
			modelSalvageGallery.Mandatory = false;
			modelSalvageGallery.Name = "Form salvage bidding.pdf";
			this._modelSalvage.arrayModelSalvageGallery.push(modelSalvageGallery);
		}
		else
		{

		}

		modelSalvageGallery = new SalvageGalleryModel();
		modelSalvageGallery.Type = ENUM_PHOTO_TYPE.SalvagePhoto;
		modelSalvageGallery.Mandatory = false;
		modelSalvageGallery.Name = "Salvage photo";
		this._arrayModelSalvageGallery.push(modelSalvageGallery);
	}

	setArrayModelSalvageItem(): void
	{
		this._modelSalvage.arrayModelSalvageItem = [];

		let modelSalvageItem: SalvageItemModel = new SalvageItemModel();
		modelSalvageItem.EstimatedSalvageValue = 100000;
		modelSalvageItem.Name = "Kaca spion Honda Brio.";
		this._modelSalvage.arrayModelSalvageItem.push(modelSalvageItem);

		modelSalvageItem = new SalvageItemModel();
		modelSalvageItem.EstimatedSalvageValue = 150000;
		modelSalvageItem.Name = "Lampu belakang Honda Brio.";
		this._modelSalvage.arrayModelSalvageItem.push(modelSalvageItem);
	}

	//#endregion


	//#region NAVIGATION

	goToList(): void
	{
		this._router.navigate(["home", "salvage", "list"]);
	}

	//#endregion
}

//#endregion

//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AboutComponent } from "./components/about/about.component";
import { HomeComponent } from "./components/home/home.component";
import { SigninComponent } from "./components/signin/signin.component";
import { PublicGuardService } from "./services/guards/public.guard.service";
import { DownloadComponent } from "./components/download/download.component";
import { StringConstant } from "./constants/string.constant";
import { GeneralAuthorizedGuardService } from "./services/guards/generalauthorized.guard";
import { ReportComponent } from "./components/report/report.component";
import { MonitoringDetailComponent } from "./components/monitoring/monitoringdetail/monitoringdetail.component";
import { BranchComponent } from "./components/branch/branch.component";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";
import { SalvageListComponent } from "./components/salvage/salvage.list/salvage.list.component";
import { SalvageDetailComponent } from "./components/salvage/salvage.detail/salvage.detail.component";
import { SalvageInsertComponent } from "./components/salvage/salvage.insert/salvage.insert.component";
import { SurveyonlineComponent } from "./components/surveyonline/surveyonline.component";

//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "home",
		redirectTo: "home/dashboard/dashboard",
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "dashboard",
				children:
				[
					{
						path: "motor-vehicle",
						loadChildren: () => import("./components/dashboards/dashboard.module").then(pageModule => pageModule.DashboardModule)
					},
				],
			},
			{
				path: "ticket",
				loadChildren: () => import("./components/ticket/ticket.module").then(pageModule => pageModule.TicketModule)
			},
			{
				path: "submission",
				loadChildren: () => import("./components/submission/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
			{
				path: "claim",
				loadChildren: () => import("./components/claim/claim.module").then(pageModule => pageModule.ClaimModule)
			},
			{
				path: "approval",
				loadChildren: () => import("./components/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
			},
			{
				path: "monitoring",
				loadChildren: () => import("./components/monitoring/monitoring.module").then(pageModule => pageModule.MonitoringModule)
			},
			{
				path: "survey",
				loadChildren: () => import("./components/survey/survey.module").then(pageModule => pageModule.SurveyModule)
			},
			{
				path: "surveyonline",
				component: SurveyonlineComponent
			},
			{
				path: "certificate",
				loadChildren: () => import("./components/certificate/certificate.module").then(pageModule => pageModule.CertificateModule)
			},
			{
				path: "notification",
				loadChildren: () => import("./components/notification/notification.module").then(pageModule => pageModule.NotificationModule)
			},
			{
				path: "adjuster",
				loadChildren: () => import("./components/adjusters/adjusters.module").then(pageModule => pageModule.AdjusterModule)
			},
			{
				path: "correction",
				loadChildren: () => import("./components/correction/correction.module").then(pageModule => pageModule.CorrectionModule)
			},
			{
				path: "reporting",
				children:
				[
					{
						path: "list",
						component: ReportComponent
					},
					{
						path: "detail/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
						component: MonitoringDetailComponent
					}
				]
			},
			{
				path: "partnershipworkshop",
				loadChildren: () => import("./components/partnershipworkshop/partnershipworkshop.module").then(pageModule => pageModule.PartnershipworkshopModule)
			},
			{
				path: "reimbursement",
				children:
				[
					{
						path: "approval",
						// eslint-disable-next-line @typescript-eslint/no-unsafe-return
						loadChildren: () => import("./components/approvalreimbursement/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
				]
			},
			{
				path: "about",
				component: AboutComponent
			},
			{
				path: "branch",
				component: BranchComponent
			},
			{
				path: "rcsa",
				children:
				[
					{
						path: "assessment",
						loadChildren: () => import("./components/rcsa/assessment/assessment.module").then(pageModule => pageModule.AssessmentModule)
					},
					{
						path: "approval",
						loadChildren: () => import("./components/rcsa/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
					{
						path: "report",
						loadChildren: () => import("./components/rcsa/report/report.module").then(pageModule => pageModule.ReportModule)
					},
					{
						path: "riskissuerequest",
						loadChildren: () => import("./components/rcsa/riskissuerequest/riskissuerequest.module").then(pageModule => pageModule.RiskissuerequestModule)
					}
				],
			},
			{
				path: "ras",
				children:
				[
					{
						path: "dashboard",
						loadChildren: () => import("./components/ras/dashboardras/dashboardras.module").then(pageModule => pageModule.DashboardrasModule)
					},
					{
						path: "followupplan",
						loadChildren: () => import("./components/ras/followupplan/followupplan.module").then(pageModule => pageModule.FollowupplanModule)
					},
				],
			},
			{
				path: "gcgonline",
				loadChildren: () => import("./components/gcgonline/gcgonline.module").then(pageModule => pageModule.GcgOnlineModule)
			},
			{
				path: "digitalization",
				loadChildren: () => import("./components/digitalization/submissions/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
			{
				path: "ematerai",
				loadChildren: () => import("./components/ematerai/ematerai.module").then(pageModule => pageModule.EmateraiModule)
			},
			{
				path: StringConstant.STRING_ROUTING_MENU_PROTECT,
				loadChildren: () => import("./components/protect/protect.module").then(pageModule => pageModule.ProtectModule)
			},
			{
				path: "ebidding",
				loadChildren: () => import("./components/ebidding/ebidding.module").then(pageModule => pageModule.EbiddingModule)
			},
			{
				path: "salvage",
				children:
				[
					{
						path: "list",
						component: SalvageListComponent
					},
					{
						path: "detail/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
						component: SalvageDetailComponent
					},
					{
						path: "insert",
						component: SalvageInsertComponent
					}
				]
			},
			{
				path: StringConstant.STRING_PATH_ROUTING_APUPPT,
				loadChildren: () => import("./components/apu-ppt/apu-ppt.module").then(pageModule => pageModule.APUPPTModule)
			},
			{
				path: "partnershipworkshop",
				loadChildren: () => import("./components/partnershipworkshop/partnershipworkshop.module").then(pageModule => pageModule.PartnershipworkshopModule)
			},
			{
				path: "brinstax",
				children:
				[
					{
						path: "taxregulation",
						loadChildren: () => import("./components/brinstax/taxregulation/taxregulation.module").then(pageModule => pageModule.TaxRegulationModule)
					},
					{
						path: "taxreport",
						loadChildren: () => import("./components/brinstax/taxreport/taxreport.module").then(pageModule => pageModule.TaxReportModule)
					},
					{
						path: "taxrequest",
						loadChildren: () => import("./components/brinstax/taxrequest/taxrequest.module").then(pageModule => pageModule.TaxRequestModule)
					}
				]
			},
			{
				path: "brinsprime",
				loadChildren: () => import("./components/brinsprime/brinsprime.module").then(pageModule => pageModule.BrinsprimeModule)
			},
			{
				path: StringConstant.STRING_PATH_ROUTING_EWS,
				loadChildren: () => import("./components/earlywarningsignal/earlywarningsignal.module").then(pageModule => pageModule.EarlyWarningSignalModule)
			},
			{
				path: "brinspro",
				loadChildren: () => import("./components/brinspro/brinspro.module").then(pageModule => pageModule.BrinsproModule)
			},
			{
				path: StringConstant.STRING_PATH_ROUTING_EWS,
				loadChildren: () => import("./components/earlywarningsignal/earlywarningsignal.module").then(pageModule => pageModule.EarlyWarningSignalModule)
			}
		]
	},
	{
		path: StringConstant.STRING_ROUTING_MENU_DOWNLOADANOMALYATTACHMENT_PARAM + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AnomalyDownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	}
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy
				})
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion

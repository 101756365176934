//#region IMPORT

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorizationSurveyNetModel } from "src/app/models/thirdparties/primasis/authorizationsurveynet.model";
import { AuthorizationSurveyNetResponseModel } from "src/app/models/thirdparties/primasis/authorizationsurveynetresponse.model";
import { UserSurveyNetModel } from "src/app/models/thirdparties/primasis/usersurveynet.model";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#endregion CLASS

export class PrimasissurveynetService
{
	//#region DECLARATION

	private _stringBaseUrlSurveyNet: string;
	private _objectHTTPOptions = {};

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _clientHTTP: HttpClient)
	{
		this._stringBaseUrlSurveyNet = "https://brins.surveynet.id/api";
		this._objectHTTPOptions =
		{
			headers: new HttpHeaders
			({
				"X-Client-Key" : "95fa367ab8ffacc407c694598e50f5d2"
			})
		};

	}

	//#endregion


	//#region SELECT

	selectTokenAuthorization(): Observable<AuthorizationSurveyNetModel>
	{
		return this._clientHTTP.get<AuthorizationSurveyNetModel>(`${this._stringBaseUrlSurveyNet}/token`, this._objectHTTPOptions);
	}

	selectDomainSurveyNetAutoLogin(modelUserSurveyNet: UserSurveyNetModel, stringXToken: string): Observable<AuthorizationSurveyNetResponseModel>
	{
		this._objectHTTPOptions =
		{
			headers: new HttpHeaders
			({
				"X-Client-Key" : "95fa367ab8ffacc407c694598e50f5d2",
				"X-Token" : stringXToken
			})
		};
		return this._clientHTTP.post<AuthorizationSurveyNetResponseModel>(`${this._stringBaseUrlSurveyNet}/auth`, modelUserSurveyNet, this._objectHTTPOptions);
	}

	//#endregion
}

//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { PolicyGeneralModel } from "../models/policygeneral.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE
@Injectable
(
	{
		providedIn: "root"
	}
)

//#region CLASS

export class PolicyGeneralService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectPolicyGeneralByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTES);
	}

	selectPolicyGeneralByReferenceNumber(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYREFERENCENUMBER);
	}

	selectPolicyGeneralByPolicyNumber(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBER);
	}

	selectPolicyGeneralByPolicyNumberForClaim(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBERFORCLAIM);
	}

	selectPolicyGeneralByAcceptanceNumber(interfaceGeneralService: GeneralServiceInterface, modelPolicyGeneral: PolicyGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyGeneral), WebAddressConstant.STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYACCEPTANCENUMBER);
	}

	selectPolicyGeneralByReferenceNumberForClaim(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYREFERENCENUMBERFORCLAIM);
	}

	selectPolicyGeneralByProfileID(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPROFILEID);
	}

	//#endregion


	//#region VERIFY and SIGNOUT

	//#endregion
}

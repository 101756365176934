/* eslint-disable @typescript-eslint/naming-convention */
//#region CLASS

export class SurveyOnlineCloudMessagingDataModel
{
	LINK_AGENT?: string;
	TICKET_NUMBER?: string;
	NUMBER_AGENT?: string;
	SCHEDULED_TIME?: Date;
}

//#endregion
//#region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ClaimProposalModel } from "src/app/models/claimproposal.model";
import { ResponseModel } from "src/app/models/response.model";
import { ClaimProposalService } from "src/app/services/claimproposal.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { TableControlIncludeComponent } from "src/app/components/includes/tablecontrol.include/tablecontrol.include.component";
import { ENUM_CLAIMPROPOSALSURVEYSTATUS, ENUM_CLAIMPROPOSAL_STATUS, ENUM_CLAIM_CHANNEL, ENUM_RESPONSE_STATE, ENUM_SURVEY_STATUS } from "src/app/constants/enum.constant";
import * as XLSX from "xlsx";
import { StringConstant } from "src/app/constants/string.constant";
import { LicensePlatePrefixService } from "src/app/services/licenseplateprefix.service";
import { LicensePlatePrefixModel } from "src/app/models/licenseplateprefix.model";
import { NumberConstant } from "src/app/constants/number.constant";
import { ExportFunction } from "src/app/functions/export.function";
import { ClaimProposalReportModel } from "src/app/models/dashboardreportclaimproposal.model";
import { DashboardClaimProposalModel } from "src/app/models/dashboardclaimproposal.model";
import { SurveyModel } from "src/app/models/survey.model";
import { CurrencyValue } from "src/app/functions/pipes/converter.pipe";
import { DatePipe } from "@angular/common";
import { UserModel } from "src/app/models/user.model";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-report",
		templateUrl: "./report.component.html",
		styleUrls: ["./report.component.sass"],
		providers: [DatePipe, CurrencyValue]
	}
)

//#endregion


//#region CLASS

export class ReportComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;
	public _modelClaimProposal: ClaimProposalModel;
	public _modelTable: TableModel = new TableModel();
	public _modelClaimProposalReport: ClaimProposalReportModel;
	public _modelClaimProposalForExport: ClaimProposalModel;
	public _modelDashboardClaimProposal: DashboardClaimProposalModel;

	public _arrayModelClaimProposal: Array<ClaimProposalModel>;
	public _arrayModelClaimProposalForExport: Array<ClaimProposalModel>;
	public _arrayModelLicensePlatePrefix: Array<LicensePlatePrefixModel>;

	public _enumClaimProposalStatus = ENUM_CLAIMPROPOSAL_STATUS;
	public _enumClaimProposalChannel = ENUM_CLAIM_CHANNEL;
	public _enumClaimProposalSurveyStatus = ENUM_CLAIMPROPOSALSURVEYSTATUS;
	public _enumSurveyStatus = ENUM_SURVEY_STATUS;

	public _numberClaimProposalSurveyStatus: number | undefined;

	public _stringConstant = StringConstant;

	public _exportToExcell: ExportFunction;
	public _matrixExportExcel: any = [];

	//#endregion


	//#region  CONSTRUCTOR

	constructor(private _serviceClaimProposal: ClaimProposalService, private _serviceLicensePlatePrefix: LicensePlatePrefixService, serviceSession: SessionService, public router: Router, private _datePipe: DatePipe, private _currencyValue: CurrencyValue)
	{
		super(serviceSession, router);
		this._modelClaimProposal = new ClaimProposalModel();
		this._modelClaimProposalReport = new ClaimProposalReportModel();
		this._modelClaimProposalForExport = new ClaimProposalModel();
		this._modelDashboardClaimProposal = new DashboardClaimProposalModel();
		this._arrayModelClaimProposal = [];
		this._arrayModelClaimProposalForExport = [];
		this._arrayModelLicensePlatePrefix = [];
		this._numberClaimProposalSurveyStatus = undefined;
		this._exportToExcell = new ExportFunction();
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this.callSelectLicensePlatePrefix(this);
	}

	//#endregion


	//#region FUNCTION

	getProposalChannel(modelClaimProposal: ClaimProposalModel): string
	{
		if (modelClaimProposal.Channel == null || modelClaimProposal.Channel === undefined)
		{
			return "-";
		}
		else
		{
			if (modelClaimProposal.Channel === ENUM_CLAIM_CHANNEL.BranchAdmin)
			{
				return "Branch Admin";
			}
			else if (modelClaimProposal.Channel === ENUM_CLAIM_CHANNEL.Claim)
			{
				return "Claim";
			}
			else if (modelClaimProposal.Channel === ENUM_CLAIM_CHANNEL.CustomerWebsite)
			{
				return "Website Customer";
			}
			else if (modelClaimProposal.Channel === ENUM_CLAIM_CHANNEL.OperationAndSupport)
			{
				return "Operation And Support";
			}
			else if (modelClaimProposal.Channel === ENUM_CLAIM_CHANNEL.RelationshipManager)
			{
				return "Relationship Manager";
			}
			else if (modelClaimProposal.Channel === ENUM_CLAIM_CHANNEL.CustomerMobile)
			{
				return "Mobile Customer";
			}
			else if (modelClaimProposal.Channel === ENUM_CLAIM_CHANNEL.CustomerSelfServiceMonitor)
			{
				return "KIOSK";
			}
			else
			{
				return "-";
			}
		}
	}


	convertEnumClaimChannelToString(enumClaimChannel: ENUM_CLAIM_CHANNEL | undefined): string
	{
		if (enumClaimChannel == null || enumClaimChannel === undefined)
		{
			return "-";
		}
		else
		{
			if (enumClaimChannel === ENUM_CLAIM_CHANNEL.BranchAdmin)
			{
				return "Klaim Cabang";
			}
			else if (enumClaimChannel === ENUM_CLAIM_CHANNEL.Claim)
			{
				return "Brinesia Klaim";
			}
			else if (enumClaimChannel === ENUM_CLAIM_CHANNEL.CustomerWebsite)
			{
				return "BRINS Website";
			}
			else if (enumClaimChannel === ENUM_CLAIM_CHANNEL.OperationAndSupport)
			{
				return "Call Center";
			}
			else if (enumClaimChannel === ENUM_CLAIM_CHANNEL.RelationshipManager)
			{
				return "BRINSMART";
			}
			else if (enumClaimChannel === ENUM_CLAIM_CHANNEL.CustomerMobile)
			{
				return "BRINS Mobile";
			}
			else if(enumClaimChannel === ENUM_CLAIM_CHANNEL.CustomerSelfServiceMonitor)
			{
				return "Self Service KIOSK";
			}
			else
			{
				return "-";
			}
		}
	}



	private convertDateToDateMedium(dateConvert: Date): string
	{
		if(dateConvert !== null && dateConvert !== undefined)
		{
			let dateFirstTimeTemp: Date | undefined = new Date();
			dateFirstTimeTemp = dateConvert;
			const stringDateFirstTimeTemp = JSON.stringify(dateFirstTimeTemp);

			const arrayDateValue = stringDateFirstTimeTemp.split("T");
			const stringUpdatedOn = arrayDateValue[0].replace(/(\d{2})-(\d{2})-(\d{4})/, "$1/$2/$3");

			const stringUpdatedOnDate = this._datePipe.transform(stringUpdatedOn,"dd/MM/yyyy");

			if(stringUpdatedOnDate)
			{
				return stringUpdatedOnDate;
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	private convertStringToDateMedium(stringDate: string): string
	{
		if(stringDate !== null && stringDate !== undefined && stringDate !== "" )
		{
			const stringDateFirstTimeTemp = JSON.stringify(stringDate);

			const arrayDateValue = stringDateFirstTimeTemp.split("T");
			const stringUpdatedOn = arrayDateValue[0].replace(/(\d{2})-(\d{2})-(\d{4})/, "$1/$2/$3");

			const stringUpdatedOnDate = this._datePipe.transform(stringUpdatedOn,"dd/MM/yyyy");

			if(stringUpdatedOnDate)
			{
				return stringUpdatedOnDate;
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	private convertStringToDateLong(stringDate: string): string
	{
		if(stringDate !== null && stringDate !== undefined && stringDate !== "" )
		{
			const stringDateTemp: Date = new Date(stringDate).initiateWithoutUTC();

			const stringUpdatedOnDate = this._datePipe.transform(stringDateTemp,"dd/MM/yyyy, H:mm");

			if(stringUpdatedOnDate)
			{
				return stringUpdatedOnDate;
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	private convertNumberToCurrancy(numberCurrency: number): any
	{
		if(numberCurrency !== null && numberCurrency !== undefined )
		{
			return this._currencyValue.transform(numberCurrency);
		}
		else
		{
			return NumberConstant.NUMBER_VALUE_GENERAL_ZERO;
		}
	}

	private convertEnumStatusClaimProposalToString(enumClaimProposal: ENUM_CLAIMPROPOSAL_STATUS | undefined, stringBranchName: string | undefined, stringSurveyNearestBranchName: string | undefined): string
	{
		if(enumClaimProposal !== undefined)
		{
			if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.Verified)
			{
				return "Found | Pending Verification " + stringBranchName;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.NotVerified)
			{
				return "Not Found | Pending Verification " + this._stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.RejectedByBranch)
			{
				return "Rejected By Branch " + stringBranchName;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.PendingForHeadOffice)
			{
				return "Pending Verification Adjustment " + this._stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.PendingForCheckerHeadOffice)
			{
				return "Pending Verification Supervisor " + this._stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.PendingForSignerHeadOffice)
			{
				return "Pending Verification Manager " + this._stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.RejectedByHeadOffice)
			{
				return "Rejected By " + this._stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.PendingForSurvey)
			{
				return "Pending For Survey " + stringSurveyNearestBranchName;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.CompletedWithSurvey || enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.CompletedWithoutSurvey)
			{
				return "Waiting For Approval By " + this._stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.Approved)
			{
				return "Approved By " + this._stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.Rejected)
			{
				return "Rejected By " + this._stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.PendingForApprove)
			{
				return "Pending For Approve Deputy Branch Head";
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.Submitted)
			{
				return "Submitted";
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrection)
			{
				return "Pending For Correction " + stringBranchName;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrectionChecker)
			{
				return "Pending For Correction Checker " + stringBranchName;
			}
			else if(enumClaimProposal === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrectionSigner)
			{
				return "Pending For Correction Signer " + stringBranchName;
			}
			else
			{
				return this._stringConstant.STRING_CHARACTER_DASH;
			}
		}
		else
		{
			return this._stringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion


	//#region WEBSERVICE

	public callSelectClaimProposalForReport(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		componentCurrent.setStatus();
		componentCurrent._modelTable.Search = JSON.stringify(componentCurrent._modelClaimProposal);

		componentCurrent._arrayModelClaimProposal = [];

		const modelResponseValidation = componentCurrent._modelClaimProposal.validateSearchClaimProposalAttributes();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceClaimProposal.selectClaimProposalClaimCenter
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTable.setModelFromString(modelResponse.Data);

							if (componentCurrent._modelTable.Result !== undefined)
							{
								let arrayModelClaimProposal: Array<ClaimProposalModel> = JSON.parse(componentCurrent._modelTable.Result);

								arrayModelClaimProposal.forEach(modelClaimProposalLoop =>
								{
									const stringModelClaimProposal: string = JSON.stringify(modelClaimProposalLoop);
									const modelClaimProposalTemp: ClaimProposalModel = new ClaimProposalModel();
									modelClaimProposalTemp.setModelFromString(stringModelClaimProposal);

									if(modelClaimProposalTemp.modelUser !== undefined)
									{
										const stringModelUser: string = JSON.stringify(modelClaimProposalLoop.modelUser);
										modelClaimProposalTemp.modelUser = new UserModel();
										modelClaimProposalTemp.modelUser.setModelFromString(stringModelUser);
									}

									componentCurrent._arrayModelClaimProposal.push(modelClaimProposalTemp);
								});


								arrayModelClaimProposal = [];
								componentCurrent._modelTable.Result = undefined;
								componentCurrent._componentTableControlInclude.setButtonState();
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectClaimProposalForReport(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, componentCurrent._modelTable);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	private callSelectLicensePlatePrefix(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = NumberConstant.NUMBER_VALUE_ROWPERPAGE_FOR_SELECTLICENSEPLATEPREFIX;

		componentCurrent._serviceLicensePlatePrefix.selectLicensePlatePrefix
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						modelTable.setModelFromString(modelResponse.Data);

						if (modelTable.Result !== undefined)
						{

							componentCurrent._arrayModelLicensePlatePrefix = JSON.parse(modelTable.Result);

							modelTable.Result = undefined;
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent.callSelectClaimProposalForReport(componentCurrent);

						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectLicensePlatePrefix(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	public callSelectClaimProposalForDownloadReport(componentCurrent: this): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		componentCurrent._modelClaimProposalForExport = componentCurrent._modelClaimProposal;
		componentCurrent._modelClaimProposalForExport.cleanListModel();

		this._serviceClaimProposal.selectClaimProposalByAttributesForMonitoringExcel
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._arrayModelClaimProposalForExport = JSON.parse(modelResponse.Data);
						modelResponse.Data = undefined;



						if(componentCurrent._arrayModelClaimProposalForExport !== null || componentCurrent._arrayModelClaimProposalForExport !== undefined)
						{

							let numberIndexTotal = componentCurrent._arrayModelClaimProposalForExport.length;

							for(let numberIndexRow = 0; numberIndexRow <= numberIndexTotal+1; numberIndexRow++)
							{

								if(numberIndexRow === 0)
								{
									componentCurrent._matrixExportExcel[numberIndexRow] = [];
									componentCurrent._matrixExportExcel[numberIndexRow].push("NO");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NO TICKET");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NOMOR POLIS");
									componentCurrent._matrixExportExcel[numberIndexRow].push("DATE OF LOSS");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NAMA TERTANGGUNG");
									componentCurrent._matrixExportExcel[numberIndexRow].push("PRIVILEGE");
									componentCurrent._matrixExportExcel[numberIndexRow].push("STATUS");
									componentCurrent._matrixExportExcel[numberIndexRow].push("PERIODE AWAL");
									componentCurrent._matrixExportExcel[numberIndexRow].push("PERIODE AKHIR");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NO POLISI");
									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL PEMBAYARAN PREMI");
									componentCurrent._matrixExportExcel[numberIndexRow].push("KRONOLOGIS");
									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL REGISTER BRINESIA");

									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL SURVEY 1");
									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL SURVEY 2");
									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL SURVEY 3");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NOTE SURVEY");

									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL VERIFY CABANG");
									componentCurrent._matrixExportExcel[numberIndexRow].push("USER VERIFY CABANG");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NOTE VERIFY CABANG");

									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL VERIFY ADJUSTER");
									componentCurrent._matrixExportExcel[numberIndexRow].push("USER VERIFY ADJUSTER");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NOTE VERIFY ADJUSTER");

									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL VERIFY SPV");
									componentCurrent._matrixExportExcel[numberIndexRow].push("USER VERIFY SPV");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NOTE VERIFY SPV");

									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL APPROVAL MANAGER");
									componentCurrent._matrixExportExcel[numberIndexRow].push("USER APPROVAL MANAGER");
									componentCurrent._matrixExportExcel[numberIndexRow].push("NOTE APPROVAL MANAGER");

									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL APPROVE SPK AWAL");
									componentCurrent._matrixExportExcel[numberIndexRow].push("ESTIMASI HARGA");
									componentCurrent._matrixExportExcel[numberIndexRow].push("DEDUCTIBLE");

									componentCurrent._matrixExportExcel[numberIndexRow].push("ADDITIONAL COST");
									componentCurrent._matrixExportExcel[numberIndexRow].push("SALVAGE");
									componentCurrent._matrixExportExcel[numberIndexRow].push("CLAIM NETT SPK AWAL");
									componentCurrent._matrixExportExcel[numberIndexRow].push("CABANG SURVEY");
									componentCurrent._matrixExportExcel[numberIndexRow].push("TANGGAL SUBMIT SURVEY");
									componentCurrent._matrixExportExcel[numberIndexRow].push("CHANNEL");

									numberIndexTotal++;
								}

								if(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow])
								{
									componentCurrent._matrixExportExcel[numberIndexRow+1] = [];
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(numberIndexRow+1);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].TicketNumber);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].PolicyNumber);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertDateToDateMedium(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].IncidentDate as Date));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].InsuredName);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].PolicyPrivilegeType);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertEnumStatusClaimProposalToString(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].Status, componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].BranchName, componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SurveyNearestBranchName));

									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertDateToDateMedium(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].PolicyStartDate as Date));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertDateToDateMedium(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].PolicyEndDate as Date));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].VehicleLicensePlate);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertDateToDateMedium(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].PolicyPaymentDate as Date));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].IncidentChronology);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertDateToDateMedium(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].CreatedOn as Date));

									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertDateToDateMedium(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SurveyDateFirst as Date));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertDateToDateMedium(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SurveyDateSecond as Date));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertDateToDateMedium(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SurveyDateThird as Date));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SurveyNote);

									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertStringToDateLong(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].BranchReviewedOn as string));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].modelUserBranchReviewed?.FullName);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].BranchReviewedNote);

									const modelClaimProposal: ClaimProposalModel = componentCurrent._arrayModelClaimProposalForExport[numberIndexRow];
									let modelSurvey: SurveyModel = new SurveyModel();
									let booleanValidation: boolean = false;

									if(modelClaimProposal.modelSurvey !== undefined)
									{
										modelSurvey = modelClaimProposal.modelSurvey;
										booleanValidation = true;
									}

									if(booleanValidation)
									{
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertStringToDateLong(modelSurvey.VerifiedOn as string));
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(modelSurvey.modelUserVerified?.FullName);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(modelSurvey.VerifyNote);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertStringToDateLong(modelSurvey.ApprovedOn as string));
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(modelSurvey.modelUserApproved?.FullName);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(modelSurvey.ApprovedNote);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._stringConstant.STRING_CHARACTER_EMPTY);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._stringConstant.STRING_CHARACTER_EMPTY);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._stringConstant.STRING_CHARACTER_EMPTY);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._stringConstant.STRING_CHARACTER_EMPTY);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._stringConstant.STRING_CHARACTER_EMPTY);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._stringConstant.STRING_CHARACTER_EMPTY);

									}


									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertStringToDateLong(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SignerHeadOfficeReviewedOn as string));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].modelUserSignerHeadOfficeReviewed?.FullName);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SignerHeadOfficeReviewedNote);

									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertStringToDateLong(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SignerHeadOfficeReviewedOn as string));

									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertNumberToCurrancy(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SumCostEstimationAmount as number));


									if(booleanValidation)
									{
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertNumberToCurrancy(modelSurvey.OwnRetentionAmount as number));
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertNumberToCurrancy(modelSurvey.AdditionalCost as number));
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._stringConstant.STRING_CHARACTER_EMPTY);
										componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._stringConstant.STRING_CHARACTER_EMPTY);
									}

									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertNumberToCurrancy(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SumSalvageEstimationAmount as number));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertNumberToCurrancy(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].TotalCost as number));

									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].BranchName);
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertStringToDateLong(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].SurveyReviewedOn as string));
									componentCurrent._matrixExportExcel[numberIndexRow+1].push(componentCurrent.convertEnumClaimChannelToString(componentCurrent._arrayModelClaimProposalForExport[numberIndexRow].Channel));
								}

							}

							/* generate worksheet */
							const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

							/* generate workbook and add the worksheet */
							const workBook: XLSX.WorkBook = XLSX.utils.book_new();
							XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");

							/* save to file */
							XLSX.writeFile(workBook, "Klaim Reporting.xlsx");

							const modelResponseExcel: ResponseModel = new ResponseModel();
							modelResponseExcel.MessageTitle = "Export Excel";
							modelResponseExcel.MessageContent = "File excel download success! Please wait.";
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectClaimProposalForDownloadReport(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelClaimProposalForExport);
	}

	//#endregion


	//#region NAVIGATION

	goToDetail(stringToken?: string | null): void
	{
		if (stringToken == null || stringToken === undefined || stringToken === "")
		{
			this._router.navigate(["home", "reporting", "list"]);
		}
		else
		{
			this._router.navigate(["home", "reporting", "detail", stringToken]);
		}
	}

	//#endregion


	//#region SETTER

	getReportedStartDate(event: any): void
	{
		this.setReportedStartDate(event.value);
	}

	private setReportedStartDate(dateStart?: Date): void
	{
		if (dateStart != null && dateStart !== undefined)
		{
			this._modelClaimProposal.ReportedStartDate = dateStart.clearUTC();
		}
		else
		{

		}
	}

	getReportedEndDate(event: any): void
	{
		this.setReportedEndDate(event.value);
	}

	private setReportedEndDate(dateStart?: Date): void
	{
		if (dateStart != null && dateStart !== undefined)
		{
			this._modelClaimProposal.ReportedEndDate = dateStart.clearUTC();
		}
		else
		{

		}
	}

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;

		if (this._modelUserSignIn.BranchCode === this._stringConstant.STRING_FIELD_BRANCHCODE_HEADOFFICE)
		{
			this.callSelectClaimProposalForReport(this);
		}
	}

	//#endregion


	//#region FUNCTION

	private setStatus(): void
	{
		const modelSurvey: SurveyModel = new SurveyModel();
		if(this._numberClaimProposalSurveyStatus !== undefined && this._numberClaimProposalSurveyStatus !== null)
		{
			if(this._numberClaimProposalSurveyStatus <= 19)
			{
				this._modelClaimProposal.Status = this._numberClaimProposalSurveyStatus;
				this._modelClaimProposal.modelSurvey = undefined;
			}
			else
			{
				if(this._numberClaimProposalSurveyStatus === this._enumClaimProposalSurveyStatus.WaitingApproveByAdjustment)
				{
					modelSurvey.Status = this._enumSurveyStatus.Surveyed;
				}
				else if(this._modelClaimProposal.modelSurvey !== undefined && this._numberClaimProposalSurveyStatus === this._enumClaimProposalSurveyStatus.WaitingApproveBySupervisor)
				{
					modelSurvey.Status = this._enumSurveyStatus.Verify;
				}
				else if(this._modelClaimProposal.modelSurvey !== undefined && this._numberClaimProposalSurveyStatus === this._enumClaimProposalSurveyStatus.WaitingApproveByManager)
				{
					modelSurvey.Status = this._enumSurveyStatus.Approved;
				}

				this._modelClaimProposal.Status = this._enumClaimProposalStatus.CompletedWithSurvey;
				this._modelClaimProposal.modelSurvey = modelSurvey;
			}
		}
		else
		{
			this._modelClaimProposal.modelSurvey = undefined;
		}
	}

	//#endregion

}

//#endregion

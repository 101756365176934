<div id="divBodyClaimProposal" class="DivBodyGeneral">
	<header class="HeaderGeneral">
		<h2>{{ _stringTitle }} - {{ _stringTitle }} Detail</h2>
		<p>Detail of claims</p>
	</header>

	<div id="divMainClaimProposalDetailApproval" class="DivMainAsideSearch">
		<div class="DivDynamicContainer">
			<div class="DivListControl">
				<div class="DivNavigationControlButton">
					<input type="radio" id="inputPolicyholder" name="inputRadio" class="inputNavigationApproval"
						(click)="booleanPolicyHolder(true)" checked=[booleanCustomer]>
					<label for="inputPolicyholder">Policy</label>
					<input type="radio" id="inputProduct" name="inputRadio" class="inputNavigationApproval"
						(click)="booleanCustomer(true)">
					<label for="inputProduct">Customer</label>
					<input type="radio" id="inputInsured" name="inputRadio" class="inputNavigationApproval"
						(click)="booleanSubmission(true)">
					<label for="inputInsured">Submission</label>
					<input *ngIf="!_modelClaimProposal.checkClaimProposalDirectToMantle()" type="radio" id="inputSurvey"
						name="inputRadio" class="inputNavigationApproval" (click)="booleanSurvey(true)">
					<label *ngIf="!_modelClaimProposal.checkClaimProposalDirectToMantle()"
						for="inputSurvey">Survey</label>
					<input *ngIf="!_modelClaimProposal.checkClaimProposalDirectToMantle()" type="radio"
						id="inputApproval" name="inputRadio" class="inputNavigationApproval"
						(click)="booleanApproval(true)">
					<label *ngIf="!_modelClaimProposal.checkClaimProposalDirectToMantle()"
						for="inputApproval">Approval</label>
					<input type="radio" id="inputHistory" name="inputRadio" class="inputNavigationHistory"
						(click)="booleanHistory(true)">
					<label for="inputHistory">History</label>
					<input *ngIf="_booleanMV" type="radio" id="inputTracking" name="inputRadio" class="inputNavigationTracking"
						(click)="booleanTracking(true)">
					<label *ngIf="_booleanMV" for="inputTracking">Tracking</label>
				</div>
			</div>

			<form *ngIf="_booleanCustomer">
				<div class="DivForm">
					<H3>Customer Information</H3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="spanCustomerFirstName">First Name</label>
								<span id="spanCustomerFirstName" name="modelCustomerFirstName">{{
									_modelCustomer.FirstName |
									convertEmptyToDash }}</span>

								<label for="spanCustomerMiddleName">Middle Name</label>
								<span id="spanCustomerMiddleName" name="modelCustomerMiddleName">{{
									_modelCustomer.MiddleName |
									convertEmptyToDash }}</span>

								<label for="spanCustomerLastName">Last Name</label>
								<span id="spanCustomerLastName" name="modelCustomerLastName">{{ _modelCustomer.LastName
									|
									convertEmptyToDash }}</span>

								<label for="spanCustomerEmail">Email</label>
								<span id="spanCustomerEmail" name="modelCustomerEmail">{{ _modelCustomer.Email |
									convertEmptyToDash
									}}</span>

								<label for="spanCustomerCityName">City</label>
								<span id="spanCustomerCityName" name="modelCustomerCityName">{{ _modelCustomer.CityName
									|
									convertEmptyToDash }}</span>

								<label for="spanCustomerAddress">Address</label>
								<span id="spanCustomerAddress" name="modelCustomerAddress">{{ _modelCustomer.Address |
									convertEmptyToDash }}</span>

							</div>
							<div class="DivForm2Column">

								<label for="spanCustomerBirthDate">Birthdate</label>
								<span id="spanCustomerBirthDate" name="modelCustomerBirthDate">{{
									_modelCustomer.BirthDate |
									initiateWithoutUTC | convertToDateShort | convertEmptyToDash }}</span>

								<label for="spanCustomerBirthPlace">Birthplace</label>
								<span id="spanCustomerBirthPlace" name="modelCustomerBirthPlace">{{
									_modelCustomer.BirthPlace |
									convertEmptyToDash }}</span>

								<label for="spanCustomerTelephone">Phone</label>
								<span id="spanCustomerTelephone" name="modelCustomerTelephone">{{
									_modelCustomer.Telephone |
									convertEmptyToDash }}</span>

								<label for="spanCustomerMobilePhone">Mobile Phone</label>
								<span id="spanCustomerMobilePhone" name="modelCustomerMobilePhone">{{
									_modelCustomer.MobilePhone |
									convertEmptyToDash }}</span>

								<label for="spanCustomerGender">Gender</label>
								<span *ngIf="_modelCustomer.Gender === _enumGender.Female" id="spanCustomerGender"
									name="modelCustomerGender">Perempuan</span>
								<span *ngIf="_modelCustomer.Gender === _enumGender.Male" id="spanCustomerGender"
									name="modelCustomerGender">Laki-laki</span>

							</div>
						</div>
					</fieldset>
				</div>
			</form>


			<form *ngIf="_booleanCustomer">
                <div class="DivForm">
                    <h3>Document Policy</h3>
                    <fieldset>
                        <div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductOto">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductOto.listModelProductOtoGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductOtoModel(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

						<div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductOtoSyariah">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductOtoSyariah.listModelProductOtoSyariahGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductOtoSyariahModel(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

						<div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductIndonesianMotorVehicleInsuranceStandardPolicy">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductIndonesianMotorVehicleInsuranceStandardPolicy.listModelProductIndonesianMotorVehicleInsuranceStandardPolicyGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductIndonesianMotorVehicleInsuranceStandardPolicyModel(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

						<div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductIndonesianMotorVehicleInsuranceStandardPolicySyariah.listModelProductIndonesianMotorVehicleInsuranceStandardPolicySyariahGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductIndonesianMotorVehicleInsuranceStandardPolicySyariahModel(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

						<div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductMotorcycle">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductMotorcycle.listModelProductMotorcycleGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductMotorcycle(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

						<div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductMotorcycleSyariah">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductMotorcycleSyariah.listModelProductMotorcycleSyariahGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductMotorcycleSyariah(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

						<div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductMotorVehicleCredit">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductMotorVehicleCredit.listModelProductMotorVehicleCreditGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductMotorVehicleCredit(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

						<div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductMotorVehicleCreditSyariah">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductMotorVehicleCreditSyariah.listModelProductMotorVehicleCreditSyariahGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductMotorVehicleCreditSyariah(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

						<div class="DivFormHorizontalContainer" *ngIf="_modelProductGeneral.modelProductOtoMaximumProtection">
                            <ng-container *ngFor="let modelProductOtoGallery of this._modelProductGeneral.modelProductOtoMaximumProtection.listModelProductOtoMaximumProtectionGallery; let numberIndex = index">
                                <div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelProductOtoGallery.Type && modelProductOtoGallery.URL !== undefined ">
                                    <label for="spanIndentificationNumber">
                                        {{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}
                                    </label>
                                    <div class="DivImageCapture">
                                    	<img *ngIf="modelProductOtoGallery.Extension !== _stringFormatDocumentMP4 && modelProductOtoGallery.URL !== undefined" src="{{ getImageProduct(modelProductOtoGallery.URL) }}" alt="{{ _enumPhotoType[modelProductOtoGallery.Type]  | convertSeparateEnumSpace }}" (dblclick)="showImageListProductOtoMaximumProtection(modelProductOtoGallery);">
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </fieldset>
                </div>
            </form>

			<form *ngIf="_booleanPolicyHolder">
				<div class="DivForm">
					<h3>Policy Information</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">

								<label for="spanPolicyGeneralPolicyholderName">Policy Holder Name</label>
								<span id="spanPolicyGeneralPolicyholderName"
									name="modelPolicyGeneralPolicyholderName">{{
									_modelPolicyGeneral.getPolicyholderName() | convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralPolicyNumber">Policy Number</label>
								<span id="spanPolicyGeneralPolicyNumber" name="numberPolicyGeneralPolicyNumber">{{
									_modelPolicyGeneral.checkPolicyNumberWithCertificateNumber()| convertEmptyToDash
									}}</span>

								<label for="spanPolicyGeneralReferenceNumber">Reference Number</label>
								<span id="spanPolicyGeneralReferenceNumber" name="numberPolicyGeneralReferenceNumber">{{ _modelPolicyGeneral.ReferenceNumber | convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralProductName">Product</label>
								<span id="spanPolicyGeneralProductName" name="modelPolicyGeneralProductName">{{
									_modelPolicyGeneral.ProductName | convertEmptyToDash }}</span>

								<label for="spanClaimProposalPrivilege">Privilege</label>
								<span id="spanClaimProposalPrivilege" name="modelClaimProposalPrivilege">{{ _modelClaimProposal.PolicyPrivilegeType | convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralBranchName">Branch</label>
								<span id="spanPolicyGeneralBranchName" name="modelPolicyGeneralBranchName">{{
									_modelPolicyGeneral.BranchName | convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralRelationshipManagerFirstName">Relationship Manager</label>
								<span id="spanPolicyGeneralRelationshipManagerFirstName"
									name="modelPolicyGeneralRelationshipManagerFirstName">{{
									_modelPolicyGeneral.RelationshipManagerFirstName | convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralPolicyStartDate">Start Date</label>
								<span id="spanPolicyGeneralPolicyStartDate" name="modelPolicyGeneralPolicyStartDate">{{
									_modelPolicyGeneral.PolicyStartDate | initiateWithoutUTC | convertToDateShort
									}}</span>

								<label for="spanPolicyGeneralPolicyEndDate">End Date</label>
								<span id="spanPolicyGeneralPolicyEndDate" name="modelPolicyGeneralPolicyEndDate">{{
									_modelPolicyGeneral.PolicyEndDate | initiateWithoutUTC | convertToDateShort
									}}</span>

								<label for="spanPolicyGeneralPolicyPaymentDate">Policy Payment Date</label>
								<span id="spanPolicyGeneralPolicyPaymentDate"
									name="modelPolicyGeneralPolicyPaymentDate">{{
									_modelPolicyGeneral.PolicyPaymentDate | initiateWithoutUTC | convertToDateShort
									}}</span>

							</div>
							<div class="DivForm2Column">

								<label for="spanPolicyGeneralHistoryPolicy">History Policy</label>
								<span id="spanPolicyGeneralHistoryPolicy" name="modelPolicyGeneralHistoryPolicy">{{
									_modelPolicyGeneral.HistoryPolicy | convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralPolicyStatus">Status</label>
								<span class="SpanActiveStatus" id="spanPolicyGeneralPolicyStatus"
									[ngClass]="{ 'StatusActive' : _modelPolicyGeneral.PolicyStatus === 'Active', 'StatusNotActive' : _modelPolicyGeneral.PolicyStatus === 'Non Active'}">{{
									_modelPolicyGeneral.PolicyStatus | convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralPolicyActiveStatus">Policy Active Status</label>
								<span class="SpanActiveStatus" id="spanPolicyGeneralPolicyActiveStatus"
									[ngClass]="{ 'StatusActive' : _modelPolicyGeneral.setBooleanToStringActive(_modelPolicyGeneral.PolicyActiveStatus) === 'Active', 'StatusNotActive' : _modelPolicyGeneral.setBooleanToStringActive(_modelPolicyGeneral.PolicyActiveStatus) === 'Non Active'}">{{
									_modelPolicyGeneral.setBooleanToStringActive(_modelPolicyGeneral.PolicyActiveStatus)
									|
									convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralPolicyPeriodeStatus">Policy Periode Status</label>
								<span class="SpanActiveStatus" id="spanPolicyGeneralPolicyPeriodeStatus"
									[ngClass]="{ 'StatusActive' : _modelPolicyGeneral.setBooleanToStringPeriode(_modelPolicyGeneral.PolicyPeriodeStatus) === 'On Periode', 'StatusNotActive' : _modelPolicyGeneral.setBooleanToStringPeriode(_modelPolicyGeneral.PolicyPeriodeStatus) === 'Off Periode'}">{{
									_modelPolicyGeneral.setBooleanToStringPeriode(_modelPolicyGeneral.PolicyPeriodeStatus)
									|
									convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralPolicyPaymentStatus">Policy Payment Status</label>
								<span class="SpanActiveStatus" id="spanPolicyGeneralPolicyPaymentStatus"
									[ngClass]="{ 'StatusActive' : _modelPolicyGeneral.setBooleanToStringPayment(_modelPolicyGeneral.PolicyPaymentStatus) === 'Paid', 'StatusNotActive' : _modelPolicyGeneral.setBooleanToStringPayment(_modelPolicyGeneral.PolicyPaymentStatus) === 'Unpaid'}">{{
									_modelPolicyGeneral.setBooleanToStringPayment(_modelPolicyGeneral.PolicyPaymentStatus)
									|
									convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralPolicyGracePeriodeStatus">Policy Grace Periode
									Status</label>
								<span class="SpanActiveStatus" id="spanPolicyGeneralPolicyPaymentStatus"
									[ngClass]="{ 'StatusActive' : _modelPolicyGeneral.setBooleanToStringGracePeriode(_modelPolicyGeneral.PolicyGracePeriodeStatus) === 'Yes', 'StatusNotActive' : _modelPolicyGeneral.setBooleanToStringGracePeriode(_modelPolicyGeneral.PolicyGracePeriodeStatus) === 'No'}">{{
									_modelPolicyGeneral.setBooleanToStringGracePeriode(_modelPolicyGeneral.PolicyGracePeriodeStatus)
									|
									convertEmptyToDash }}</span>

								<label for="spanPolicyGeneralPremiumAmount">Premium</label>
								<span id="spanPolicyGeneralPremiumAmount" name="modelPolicyGeneralPremiumAmount">{{
									_booleanMV ?
									_stringConstant.STRING_CURRENCY_IDR_PREFIX : _modelPolicyGeneral.PremiumCurrency }}
									{{
									_modelPolicyGeneral.PremiumAmount | currencyValue | convertEmptyToDash }}</span>

							</div>
						</div>
					</fieldset>
				</div>
			</form>

			<form *ngIf="_booleanPolicyHolder">
				<div class="DivForm">
					<h3>Coverage Information</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="radioButtonPolicyholderRiskCoverage">List Coverage</label>
								<ng-container *ngIf="_booleanMV">
									<div class="DivRadioButtonContainer">
										<div class="DivRadioButton">
											<input type="radio" id="radioButtonPolicyholderRiskCoverage"
												name="booleanPolicyholder" [(ngModel)]="_numberClaimCoverage"
												[value]="_enumClaimCoverage.Rcover">
											<label for="radioButtonPolicyholderRiskCoverage"></label>
										</div>
										<label for="radioButtonPolicyholderRiskCoverage">Risk Coverage</label>
									</div>
								</ng-container>
								<div class="DivRadioButtonContainer">
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonPolicyholderSumInsured"
											name="booleanPolicyholder" [(ngModel)]="_numberClaimCoverage"
											[value]="_enumClaimCoverage.Icover">
										<label for="radioButtonPolicyholderSumInsured"></label>
									</div>
									<label for="radioButtonPolicyholderSumInsured">Sum Insured</label>
								</div>
								<div class="DivRadioButtonContainer">
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonPolicyholderClauses"
											name="booleanPolicyholder" [(ngModel)]="_numberClaimCoverage"
											[value]="_enumClaimCoverage.Acccla">
										<label for="radioButtonPolicyholderClauses"></label>
									</div>
									<label for="radioButtonPolicyholderClauses">Clauses</label>
								</div>
								<div class="DivRadioButtonContainer">
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonPolicyholderDeductible"
											name="booleanPolicyholder" [(ngModel)]="_numberClaimCoverage"
											[value]="_enumClaimCoverage.Accded">
										<label for="radioButtonPolicyholderDeductible"></label>
									</div>
									<label for="radioButtonPolicyholderDeductible">Deductible</label>
								</div>
							</div>
							<div class="DivForm2Column">
								<ng-container *ngIf="_numberClaimCoverage === _enumClaimCoverage.Rcover && _booleanMV">
									<label for="spanPolicyGeneralCoverageName">Risk Coverage</label>
									<span name="spanPolicyGeneralListModelIcover" id="spanPolicyGeneralListModelIcover"
										*ngFor="let listModelRcover of _modelPolicyGeneral.listModelRcover"
										id="spanPolicyGeneralCoverageName" name="modelPolicyGeneralListModelRcover">{{
										listModelRcover.Remark }}</span>
								</ng-container>

								<ng-container *ngIf="_numberClaimCoverage === _enumClaimCoverage.Icover">
									<label for="spanPolicyGeneralListModelIcover">Sum Insured</label>
									<span name="spanPolicyGeneralListModelIcover" id="spanPolicyGeneralListModelIcover"
										*ngFor="let listModelIcover of _modelPolicyGeneral.listModelIcover"
										id="spanPolicyGeneralListModelIcover"
										name="modelPolicyGeneralListModelIcover">{{
										listModelIcover.Toi }} | {{ listModelIcover.Remark }} | {{ _booleanMV ?
										_stringConstant.STRING_CURRENCY_IDR_PREFIX : _modelPolicyGeneral.PremiumCurrency
										}} {{
										listModelIcover.Si | currencyValue | convertEmptyToDash}}</span>
								</ng-container>

								<ng-container *ngIf="_numberClaimCoverage === _enumClaimCoverage.Acccla">
									<label for="selectPolicyGeneralSupportingClause">Clauses</label>
									<span name="selectPolicyGeneralSupportingClause"
										id="selectPolicyGeneralSupportingClause"
										*ngFor="let listModelAcccla of _modelPolicyGeneral.listModelAcccla">{{
										listModelAcccla.Shortdesc
										}}</span>
								</ng-container>

								<ng-container *ngIf="_numberClaimCoverage === _enumClaimCoverage.Accded">
									<label for="selectPolicyGeneralSupportingDeductible">Deductible</label>
									<span name="selectPolicyGeneralSupportingDeductible"
										id="selectPolicyGeneralSupportingDeductible"
										*ngFor="let listModelAccded of _modelPolicyGeneral.listModelAccded">{{
										listModelAccded.Remarks }} |
										{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ listModelAccded.Fixedmin |
										currencyValue |
										convertEmptyToDash }}</span>
								</ng-container>
							</div>
						</div>
					</fieldset>
				</div>
			</form>

			<!-- <form *ngIf="_booleanPolicyHolder && !_booleanMV"> -->
			<form *ngIf="_booleanPolicyHolder">
				<div class="DivForm">
					<fieldset>
						<h3>Risk Coverage</h3>
						<table class="TableCheckList">
							<thead>
								<tr>
									<td>Coverage</td>
									<td>Date Periode</td>
									<td *ngFor="let numberPolicyYear of _arrayNumberPolicyYear">{{numberPolicyYear}}
									</td>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let listModelRcover of _modelPolicyGeneral.listModelRcover">
									<td>
										{{ listModelRcover.Remark}}
									</td>
									<td>
										{{listModelRcover.Sdate | initiateWithoutUTC | convertToDateShort}} s.d.
										{{listModelRcover.Edate |
										initiateWithoutUTC | convertToDateShort}}
									</td>
									<td *ngFor="let numberPolicyYear of _arrayNumberPolicyYear">
										<div *ngIf="numberPolicyYear >=(listModelRcover.Sdate | convertDateToYear) && numberPolicyYear <= (listModelRcover.Edate | convertDateToYear)"
											class="DivCheck"></div>
									</td>
								</tr>
							</tbody>
						</table>
					</fieldset>
				</div>
			</form>

			<div *ngIf="!_booleanMV && _booleanPolicyHolder">
				<form>
					<div class="DivForm">
						<fieldset>
							<h3>Payment</h3>
						</fieldset>
					</div>
				</form>
				<div class="DivTableContainer">
					<app-tablecontrolinclude [_modelTableForm]="_modelTablePayment"
						(_eventEmitterSelect)="setEventEmitterSelectPayment($event);"></app-tablecontrolinclude>
					<table class="TableSpreadingOfLoss">
						<thead>
							<tr>
								<th>Policy Number</th>
								<th>Total Premium</th>
								<th>Outstanding</th>
								<th>Last Paid</th>
								<th>Installment</th>
							</tr>
						</thead>

						<tbody>
							<tr *ngFor="let modelPayment of _arrayModelPayment">
								<td>{{ modelPayment.PolicyNo }}</td>
								<td>{{ modelPayment.PremiumAmountDue | number: "1.0-0" }}</td>
								<td>{{ modelPayment.Outstanding | number: "1.0-0" }}</td>
								<td>{{ modelPayment.LastPayment | initiateWithoutUTC | convertToDateShort |
									convertEmptyToDash }}</td>
								<td>{{ modelPayment.Installment }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div *ngIf="!_booleanMV && _booleanPolicyHolder">
				<form>
					<div class="DivForm">
						<fieldset>
							<h3>Spreading Of Loss</h3>
						</fieldset>
					</div>
				</form>
				<div class="DivTableContainer">
					<table class="TableSpreadingOfLoss">
						<thead>
							<tr>
								<th>Treaty Type</th>
								<th>Our Share</th>
							</tr>
						</thead>

						<tbody>
							<tr *ngFor="let modelSpreedingOfLoss of _modelPolicyGeneral.listModelSpreadingOfLoss">
								<td *ngIf="modelSpreedingOfLoss.TreatyType">{{modelSpreedingOfLoss.TreatyType}}</td>
								<td *ngIf="modelSpreedingOfLoss.Percentage">{{ modelSpreedingOfLoss.Percentage | number : '1.2-2'}}{{_stringPercentage}}</td>
							</tr>
							<tr
								*ngIf="_modelPolicyGeneral.listModelSpreadingOfLoss === undefined || _modelPolicyGeneral.listModelSpreadingOfLoss === null || _modelPolicyGeneral.listModelSpreadingOfLoss.length <= 0">
								<td>-</td>
								<td>-</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<form *ngIf="_modelPolicyGeneral.modelProfileReinsurance && _booleanPolicyHolder && _booleanMV">
				<div class="DivForm">
					<fieldset>
						<h3>Facultative</h3>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="spanPolicyGeneralProfileReinsuranceStatus">Status</label>
								<span id="spanPolicyGeneralProfileReinsuranceStatus"
									name="modelPolicyGeneralProfileReinsuranceStatus">Yes</span>
							</div>
							<div class="DivForm2Column">
								<label for="spanPolicyGeneralProfileReinsuranceName">Name</label>
								<span id="spanPolicyGeneralProfileReinsuranceName"
									name="modelPolicyGeneralProfileReinsuranceName">{{
									_modelPolicyGeneral.modelProfileReinsurance.Name | convertEmptyToDash }}</span>
							</div>
						</div>
					</fieldset>
				</div>
			</form>
			<form *ngIf="_booleanPolicyHolder">
				<div class="DivForm">
					<fieldset>
						<ng-container *ngIf="_booleanMV">
							<h3>Vehicle Information</h3>
							<div class="DivFormHorizontalContainer">
								<div class="DivForm2Column">
									<label for="spanPolicyGeneralInsuredVehicleBrand">Vehicle Brand</label>
									<span id="spanPolicyGeneralInsuredVehicleBrand"
										name="modelPolicyGeneralInsuredVehicleBrand">{{
										_modelPolicyGeneral.InsuredVehicleBrand | convertEmptyToDash }}</span>

									<label for="spanPolicyGeneralInsuredVehicleType">Vehicle Model</label>
									<span id="spanPolicyGeneralInsuredVehicleType"
										name="modelPolicyGeneralInsuredVehicleType">{{
										_modelPolicyGeneral.InsuredVehicleModel | convertEmptyToDash }}</span>

									<label for="spanPolicyGeneralInsuredVehicleColor">Vehicle Color</label>
									<span id="spanPolicyGeneralInsuredVehicleColor"
										name="modelPolicyGeneralInsuredVehicleColor">{{
										_modelPolicyGeneral.InsuredVehicleColor | convertEmptyToDash }}</span>

									<label for="spanPolicyGeneralInsuredVehicleYear">Vehicle Year</label>
									<span id="spanPolicyGeneralInsuredVehicleYear"
										name="modelPolicyGeneralInsuredVehicleYear">{{
										_modelPolicyGeneral.InsuredVehicleYear | convertEmptyToDash }}</span>
								</div>
								<div class="DivForm2Column">
									<label for="spanPolicyGeneralLicensePlate">License Plate</label>
									<span id="spanPolicyGeneralLicensePlate" name="modelPolicyGeneralLicensePlate">{{
										_modelPolicyGeneral.InsuredLicensePlate | convertEmptyToDash }}</span>

									<label for="spanPolicyGeneralVehicleChasisNumber">Vehicle Chasis Number</label>
									<span id="spanPolicyGeneralVehicleChasisNumber"
										name="modelPolicyGeneralVehicleChasisNumber">{{
										_modelPolicyGeneral.InsuredVehicleChasisNumber | convertEmptyToDash }}</span>

									<label for="spanPolicyGeneralVehicleEngineNumber">Vehicle Engine Number</label>
									<span id="spanPolicyGeneralVehicleEngineNumber"
										name="modelPolicyGeneralVehicleEngineNumber">{{
										_modelPolicyGeneral.InsuredVehicleEngineNumber | convertEmptyToDash }}</span>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="!_booleanMV">
							<h3>Object Information</h3>
							<div class="DivFormHorizontalContainer">
								<div class="DivForm2Column">
									<label for="spanPolicyGeneralConstructionClass">{{
										_modelPolicyGeneral.ObjectInformationHeaderFirst |
										convertEmptyToDash }}</label>
									<span id="spanPolicyGeneralConstructionClass"
										name="modelPolicyGeneralConstructionClass">{{
										_modelPolicyGeneral.ObjectInformationValueFirst | convertEmptyToDash }}</span>

									<label for="spanPolicyGeneralRiskOccupation">{{
										_modelPolicyGeneral.ObjectInformationHeaderSecond |
										convertEmptyToDash }}</label>
									<span id="spanPolicyGeneralRiskOccupation"
										name="modelPolicyGeneralRiskOccupation">{{
										_modelPolicyGeneral.ObjectInformationValueSecond | convertEmptyToDash }}</span>

									<label for="spanPolicyGeneralZipCode">{{
										_modelPolicyGeneral.ObjectInformationHeaderThird |
										convertEmptyToDash }}</label>
									<span id="spanPolicyGeneralZipCode" name="modelPolicyGeneralZipCode">{{
										_modelPolicyGeneral.ObjectInformationValueThird | convertEmptyToDash }}</span>
								</div>

								<div class="DivForm2Column">
									<label for="spanPolicyGeneralRiskCatagory">{{
										_modelPolicyGeneral.ObjectInformationHeaderFourth |
										convertEmptyToDash }}</label>
									<span id="spanPolicyGeneralRiskCatagory" name="modelPolicyGeneralRiskCatagory">{{
										_modelPolicyGeneral.ObjectInformationValueFourth | convertEmptyToDash }}</span>

									<label for="spanPolicyGeneralRiskLocation">{{
										_modelPolicyGeneral.ObjectInformationHeaderFifth |
										convertEmptyToDash }}</label>
									<span id="spanPolicyGeneralRiskLocation" name="modelPolicyGeneralRiskLocation">{{
										_modelPolicyGeneral.ObjectInformationValueFifth | convertEmptyToDash }}</span>
								</div>
							</div>
						</ng-container>
					</fieldset>
				</div>
			</form>

			<form *ngIf="_booleanSubmission">
				<div class="DivForm">
					<h3>Submission Information</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="spanTicketNumber">Ticket Number</label>
								<span id="spanTicketNumber" name="modelClaimProposalTicketNumber">{{
									_modelClaimProposal.TicketNumber |
									convertEmptyToDash }}</span>

								<label for="spanClaimNumber">Claim Number</label>
								<span id="spanClaimNumber" name="modelClaimProposalClaimNumber">{{
									_modelClaimProposal.ClaimNumber |
									convertEmptyToDash }}</span>
							</div>
							<div class="DivForm2Column">
								<ng-container *ngIf="_booleanMV">
									<label for="spanRegisterNumber">Register Number</label>
								</ng-container>
								<ng-container *ngIf="!_booleanMV">
									<label for="spanRegisterNumber">Register Number Head Office</label>
								</ng-container>
								<span id="spanRegisterNumber" name="modelClaimProposalRegisterNumber">{{
									_modelClaimProposal.modelClaimProposalRegister?.RegisterNumber | convertEmptyToDash
									}}</span>

								<ng-container *ngIf="!_booleanMV">
									<label for="spanClaimRegisterNumber">Notification Loss Advice Number</label>
									<span id="spanClaimRegisterNumber" name="modelClaimProposalClaimRegisterNumber">{{
										_modelClaimProposal.ClaimRegisterNumber | convertEmptyToDash }}</span>
								</ng-container>
							</div>
						</div>
					</fieldset>

					<h3>Reporter Information</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">

								<label for="spanReporterFirstName">Reporter First Name</label>
								<span id="spanReporterFirstName" name="modelClaimProposalReporterFirstName">{{
									_modelClaimProposal.ReporterFirstName | convertEmptyToDash }}</span>

								<label for="spanReporterMiddleName">Reporter Middle Name</label>
								<span id="spanReporterMiddleName" name="modelClaimProposalReporterMiddleName">{{
									_modelClaimProposal.ReporterMiddleName | convertEmptyToDash }}</span>

								<label for="spanReporterLastName">Reporter Last Name</label>
								<span id="spanReporterLastName" name="modelClaimProposalReporterLastName">{{
									_modelClaimProposal.ReporterLastName | convertEmptyToDash }}</span>

								<ng-container *ngIf="_booleanMV">
									<label for="spanReporterEmail">Reporter Email</label>
									<span id="spanReporterEmail" name="modelClaimProposalReporterEmail">{{
										_modelClaimProposal.ReporterEmail | convertEmptyToDash }}</span>
								</ng-container>

								<ng-container *ngIf="!_booleanMV">
									<label for="spanReporterEmail">Reporter Email Primary</label>
									<span id="spanReporterEmail" name="modelClaimProposalReporterEmail">{{
										_modelClaimProposal.ReporterEmail | convertEmptyToDash }}</span>
									<label for="spanReporterEmailSecondary">Reporter Email Secondary</label>
									<span id="spanReporterEmailSecondary"
										name="modelClaimProposalReporterEmailSecondary">{{
										_modelClaimProposal.ReporterEmailSecondary | convertEmptyToDash }}</span>
								</ng-container>


							</div>

							<div class="DivForm2Column">

								<label for="dateReporterDate">Reported On</label>
								<span id="textReporterDate" name="modelClaimProposalReporterDate">{{
									_modelClaimProposal.CreatedOn |
									initiateWithoutUTC | convertToDateShort | convertEmptyToDash }}</span>

								<ng-container *ngIf="_booleanMV">
									<label for="spanReporterAddress">Address Pelapor</label>
									<span id="spanReporterAddress" name="modelClaimProposalReporterAddress">{{
										_modelClaimProposal.ReporterAddress | convertEmptyToDash }}</span>
								</ng-container>

								<ng-container *ngIf="_booleanMV">
									<label for="spanReporterMobilePhone">Reporter Mobile</label>
									<span id="spanReporterMobilePhone" name="modelClaimProposalReporterMobilePhone">{{
										_modelClaimProposal.ReporterMobilePhone | convertEmptyToDash }}</span>
								</ng-container>

								<ng-container *ngIf="!_booleanMV">
									<label for="spanReporterMobilePhone">Reporter Mobile Primary</label>
									<span id="spanReporterMobilePhone" name="modelClaimProposalReporterMobilePhone">{{
										_modelClaimProposal.ReporterMobilePhone | convertEmptyToDash }}</span>
									<label for="spanReporterMobilePhoneSecondary">Reporter Mobile Secondary</label>
									<span id="spanReporterMobilePhoneSecondary"
										name="modelClaimProposalReporterMobilePhoneSecondary">{{
										_modelClaimProposal.ReporterMobilePhoneSecondary | convertEmptyToDash }}</span>
								</ng-container>

								<label for="spanReporterRelationship">Reporter Relationship</label>
								<span id="spanReporterRelationship" name="modelClaimProposalReporterRelationship">{{
									_modelClaimProposal.ReporterRelationship | convertEmptyToDash }}</span>

							</div>
						</div>
					</fieldset>

					<ng-container *ngIf="_booleanMV">
						<h3>Accident Information</h3>
						<fieldset>
							<div class="DivFormHorizontalContainer">
								<div class="DivForm2Column">
									<label for="spanIncidentType">Accident Type</label>
									<span id="spanIncidentType" *ngIf="_modelClaimProposal.IncidentType !== undefined"
										name="modelClaimProposalIncidentType">{{
										_enumIncidentType[_modelClaimProposal.IncidentType] |
										convertSeparateEnumSpace }}</span>

									<label for="spanIncidentLocation">Accident Address</label>
									<span id="spanIncidentLocation" name="modelClaimProposalIncidentLocation">{{
										_modelClaimProposal.IncidentLocation | convertEmptyToDash }}</span>

									<label for="spanIncidentChronology">Accident Chronology</label>
									<span id="spanIncidentChronology" name="modelClaimProposalIncidentChronology">{{
										_modelClaimProposal.IncidentChronology | convertEmptyToDash }}</span>

									<label for="spanIncidentDamageDescription">Accident Damage Type</label>
									<span id="spanIncidentDamageDescription"
										name="modelClaimProposalIncidentDamageDescription">{{
										_modelClaimProposal.IncidentDamageDescription | convertEmptyToDash }}</span>

									<label for="spanIncidentDate">Accident Date</label>
									<span id="spanIncidentDate" name="modelClaimProposalIncidentDate">{{
										_modelClaimProposal.IncidentDate
										| initiateWithoutUTC | convertToDateShort | convertEmptyToDash }}</span>

									<label for="spanIncidentTime">Accident Time</label>
									<span id="spanIncidentTime" name="modelClaimProposalIncidentTime">{{
										_modelClaimProposal.IncidentTime
										| convertEmptyToDash }}</span>

									<label for="spanIncidentNote">Accident Note</label>
									<span id="spanIncidentNote" name="modelClaimProposalIncidentNote">{{
										_modelClaimProposal.IncidentNote
										| convertEmptyToDash }}</span>

									<ng-container
										*ngIf="_modelClaimProposal.booleanCheckThirdPartyLiability(_modelClaimProposal.LegalResponsibility!)">
										<label for="spanLegalResponsibility">TPL</label>
										<span id="spanLegalResponsibility" name="modelClaimProposalPAPassanger">{{
											_modelClaimProposal.checkThirdPartyLiability(_modelClaimProposal.LegalResponsibility!)
											}}</span>
									</ng-container>

									<ng-container
										*ngIf="_modelClaimProposal.booleanCheckThirdPartyLiability(_modelClaimProposal.PersonalAccidentPassenger!)">
										<label for="spanPAPassanger">PA Passanger</label>
										<span id="spanPAPassanger" name="modelClaimProposalPAPassanger">{{
											_modelClaimProposal.checkThirdPartyLiability(_modelClaimProposal.PersonalAccidentPassenger!)}}</span>
									</ng-container>

									<ng-container
										*ngIf="_modelClaimProposal.booleanCheckThirdPartyLiability(_modelClaimProposal.PersonalAccidentDriver!)">
										<label for="spanPADriver">PA Driver</label>
										<span id="spanPADriver" name="modelClaimProposalPADriver">{{
											_modelClaimProposal.checkThirdPartyLiability(_modelClaimProposal.PersonalAccidentDriver!)}}</span>
									</ng-container>
								</div>

								<div class="DivForm2Column">

									<label for="spanSurveyAddress">Survey Branch</label>
									<span id="spanSurveyAddress" name="modelClaimProposalSurveyAddress">{{
										_modelClaimProposal.SurveyNearestBranchName | convertEmptyToDash }}</span>

									<label for="spanSurveyLocation">Survey Address</label>
									<span id="spanSurveyLocation" name="modelClaimProposalSurveyLocation">{{
										_modelClaimProposal.SurveyAddress | convertEmptyToDash }}</span>

									<label for="spanSurveyDate">Survey Date</label>
									<span id="spanSurveyDate" name="modelClaimProposalSurveyDate">{{
										_modelClaimProposal.SurveyDate |
										initiateWithoutUTC | convertToDateShort | convertEmptyToDash }}</span>

									<ng-container *ngIf="_booleanMV">
										<label for="spanDriverName">Driver Name</label>
										<span id="spanDriverName" name="modelClaimProposalDriverName">{{
											_modelClaimProposal.DriverName |
											convertEmptyToDash }}</span>

										<label for="spanDrivingLicenseNumber">Driving License Number</label>
										<span id="spanDrivingLicenseNumber"
											name="modelClaimProposalDrivingLicenseNumber">{{
											_modelClaimProposal.DriverLicenseNumber | convertEmptyToDash }}</span>

										<label for="spanVehicleSpeed">Vehicle Speed</label>
										<span id="spanVehicleSpeed" name="modelClaimProposalVehicleSpeed">{{
											_modelClaimProposal.VehicleSpeed | convertEmptyToDash }} {{
											_stringKilometersPerHour }}</span>

										<label for="spanVehicleLocation">Vehicle Location</label>
										<span id="spanVehicleLocation" name="modelClaimProposalVehicleLocation">{{
											_modelClaimProposal.VehicleLocationName | convertEmptyToDash }} </span>

									</ng-container>
								</div>
							</div>
						</fieldset>
					</ng-container>

					<ng-container *ngIf="!_booleanMV">
						<h3>Loss Information</h3>
						<fieldset>
							<div class="DivFormHorizontalContainer">
								<div class="DivForm2Column">
									<label for="spanIncidentLocation">Loss Location</label>
									<span id="spanIncidentLocation" name="modelClaimProposalIncidentLocation">{{
										_modelClaimProposal.IncidentLocation | convertEmptyToDash }}</span>

									<label for="spanIncidentDetailLocation">Detail Loss Location</label>
									<span id="spanIncidentDetailLocation" name="modelClaimProposalIncidentLocation">{{
										_modelClaimProposal.IncidentDetailLocation | convertEmptyToDash }}</span>

									<label for="spanClaimProposalAccidentChornology">Accident Chronology</label>
									<span id="spanClaimProposalAccidentChornology"
										name="modelClaimProposalAccidentChornology">{{
										_modelClaimProposal.IncidentChronology | convertEmptyToDash }}</span>

									<label for="spanEstimateClaimValue">Estimate Claim Value</label>
									<span id="spanEstimateClaimValue" name="modelEstimateClaimValue">{{
										_modelClaimProposal.Currency |
										convertEmptyToDash }} {{ _modelClaimProposal.Amount | currencyValue |
										convertEmptyToDash }}</span>

									<label for="spanIncidentDate">Date Of Loss</label>
									<span id="spanIncidentDate" name="modelClaimProposalIncidentDate">{{
										_modelClaimProposal.IncidentDate
										| initiateWithoutUTC | convertToDateShort | convertEmptyToDash }}</span>

									<label for="spanIncidentTime">Time Of Loss</label>
									<span id="spanIncidentTime" name="modelClaimProposalIncidentTime">{{
										_modelClaimProposal.IncidentTime
										| convertEmptyToDash }}</span>
								</div>

								<div class="DivForm2Column">
									<label for="spanSurveyAddress">Notify to Head Office</label>
									<span class="SpanActiveStatus" id="spanSurveyAddress"
										name="modelClaimProposalSurveyAddress"
										[ngClass]="_modelClaimProposal.NotifyToHeadOffice ? 'Verified' : 'NotVerified'">{{
										_modelClaimProposal.NotifyToHeadOffice ? 'Yes' : 'No' }}</span>

									<label for="spanSurveyAddress">Adjuster</label>
									<span class="SpanActiveStatus" id="spanSurveyAddress"
										name="modelClaimProposalSurveyAddress"
										[ngClass]="_modelClaimProposal.Adjuster ? 'Verified' : 'NotVerified'">{{
										_modelClaimProposal.Adjuster ? 'Yes' : 'No' }}</span>

									<ng-container
										*ngIf="_modelClaimProposal.booleanCheckThirdPartyLiability(_modelClaimProposal.LegalResponsibility!)">
										<label for="spanLegalResponsibility">TPL</label>
										<span id="spanLegalResponsibility" name="modelClaimProposalPAPassanger">{{
											_modelClaimProposal.checkThirdPartyLiability(_modelClaimProposal.LegalResponsibility!)
											}}</span>
									</ng-container>

									<ng-container
										*ngIf="_modelClaimProposal.booleanCheckThirdPartyLiability(_modelClaimProposal.PersonalAccidentPassenger!)">
										<label for="spanPAPassanger">PA Passanger</label>
										<span id="spanPAPassanger" name="modelClaimProposalPAPassanger">{{
											_modelClaimProposal.checkThirdPartyLiability(_modelClaimProposal.PersonalAccidentPassenger!)}}</span>
									</ng-container>

									<ng-container
										*ngIf="_modelClaimProposal.booleanCheckThirdPartyLiability(_modelClaimProposal.PersonalAccidentDriver!)">
										<label for="spanPADriver">PA Driver</label>
										<span id="spanPADriver" name="modelClaimProposalPADriver">{{
											_modelClaimProposal.checkThirdPartyLiability(_modelClaimProposal.PersonalAccidentDriver!)}}</span>
									</ng-container>
								</div>
							</div>

						</fieldset>
					</ng-container>
				</div>
			</form>

			<form
				*ngIf="_booleanSubmission && this._modelClaimProposal.listModelClaimProposalGallery!.length !== 0 && _booleanMV">
				<div class="DivForm">
					<h3>Supporting Submission</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<ng-container
								*ngFor="let modelListClaimProposalGallery of this._modelClaimProposal.listModelClaimProposalGallery; let numberIndex = index">
								<div class="DivForm2Column DivForm2ColumnNoMargin"
									*ngIf="modelListClaimProposalGallery.Type && modelListClaimProposalGallery.URL !== undefined ">
									<label for="spanIndentificationNumber">
										{{ _enumPhotoType[modelListClaimProposalGallery.Type] | convertSeparateEnumSpace
										}}
									</label>
									<div class="DivImageCapture">
										<img *ngIf="modelListClaimProposalGallery.Extension !== _stringFormatDocumentMP4"
											src="{{ getImage(modelListClaimProposalGallery.URL) }}"
											alt="{{ _enumPhotoType[modelListClaimProposalGallery.Type]  | convertSeparateEnumSpace }}"
											(dblclick)="showImageListClaimProposalGallery(modelListClaimProposalGallery);">
									</div>
								</div>
							</ng-container>
						</div>
					</fieldset>
				</div>
			</form>

			<div *ngIf="_booleanSubmission && this._modelClaimProposal.listModelClaimProposalGallery !== undefined && this._modelClaimProposal.listModelClaimProposalGallery.length !== 0 && !_booleanMV"
				class="DivTableContainer">
				<h3>Supporting Document </h3>
				<table class="TableListDetailsAttachment">
					<tbody>
						<ng-container
							*ngFor="let modelClaimProposalGallery of this._modelClaimProposal.listModelClaimProposalGallery; let numberIndex = index">
							<tr>
								<td>
									<a *ngIf="modelClaimProposalGallery.Type !== undefined ">
										{{ _enumPhotoType[modelClaimProposalGallery.Type] | convertSeparateEnumSpace }}
									</a>
								</td>
								<td>
									<a>
										{{ modelClaimProposalGallery.Name }}.
										{{ modelClaimProposalGallery.Extension }}
									</a>
								</td>
								<td>
									<label class="LabelAttachment">
										<input type="button" class="ButtonDownload"
											(click)="showImage(modelClaimProposalGallery.Type!);">
									</label>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>

			<form *ngIf="_booleanSubmission && _booleanMV">
				<div class="DivForm" *ngIf="_modelClaimProposal.BranchReviewedStatus !== undefined">
					<H3>Note Branch</H3>
					<fieldset>
						<div class="DivFormHorizontalContainer DivFormReviewedOn">
							<label for="textBranchReviewedNote">Note</label>
							<label for="textBranchReviewedOn">
								{{ _modelClaimProposal.BranchReviewedOn | initiateWithoutUTC | convertToDateMedium |
								convertEmptyToDash
								}} |
								{{ _modelClaimProposal.modelUserBranchReviewed?.getName() | convertEmptyToDash }} |
								<i class="IActiveStatus" [ngClass]="{
								'NotVerified' :  _modelClaimProposal.BranchReviewedStatus === _enumReviewState.Rejected,
								'Verified' : _modelClaimProposal.BranchReviewedStatus === _enumReviewState.Verified}">
									{{ _enumReviewState[_modelClaimProposal.BranchReviewedStatus] }}
								</i>
							</label>
						</div>
						<span type="text" name="modelClaimProposalBranchReviewedNote" id="textBranchReviewedNote"
							placeholder="input note">{{ _modelClaimProposal.BranchReviewedNote }}</span>
					</fieldset>
				</div>
				<div class="DivTableContainer" *ngIf="_modelClaimProposal.BranchReviewedStatus !== undefined">
					<h3>Branch Document </h3>
					<table class="TableListDetailsAttachment">
						<tbody>
							<tr
								*ngFor="let modelClaimProposalGallery of this._modelClaimProposal.listModelClaimProposalSupportingGallery; let numberIndex = index">
								<td>

									<ng-container>
										<label for="fileAttachmentHeadOfficeReview">{{
											_enumPhotoType[modelClaimProposalGallery.Type!] |
											convertSeparateEnumSpace }}</label>
									</ng-container>
								</td>
								<td>
									<a>
										{{ modelClaimProposalGallery.Name }}.
										{{ modelClaimProposalGallery.Extension }}
									</a>
								</td>
								<td>
									<label class="LabelAttachment"
										*ngIf="this._modelClaimProposal.listModelClaimProposalSupportingGallery?.length ?? 0 > 0">
										<input type="button" class="ButtonDownload"
											(click)="showImageSupportingDocument(modelClaimProposalGallery.Type!)">
									</label>
								</td>

							</tr>
						</tbody>
					</table>
				</div>

				<ng-container *ngIf="_modelClaimProposal.checkListClaimProposalAppealNotEmpty()">
					<ng-container
						*ngFor="let modelClaimProposalAppeal of _modelClaimProposal.listModelClaimProposalAppeal; let numberIndex = index">
						<div id="divMainDashboard" class="DivMainGeneral">
							<form>
								<div class="DivForm">
									<h3>Branch Appeal {{ numberIndex+1 }}</h3>
									<fieldset>
										<div class="DivFormHorizontalContainer DivFormReviewedOn">
											<label for="textBranchReviewedNote"> Note </label>
											<span for="textBranchReviewedNote">{{ modelClaimProposalAppeal.UpdatedOn |
												initiateWithoutUTC |
												convertToDateShort | convertEmptyToDash }}
											</span>
										</div>
										<span name="modelClaimProposalBranchReviewedNote" id="textBranchReviewedNote">{{
											modelClaimProposalAppeal.Note | convertEmptyToDash }}</span>
									</fieldset>
								</div>
							</form>
						</div>

						<div class="DivMainAsideSearch"
							*ngIf="this._modelClaimProposal.listModelClaimProposalAppeal !== undefined && this._modelClaimProposal.listModelClaimProposalAppeal.length > 0">
							<div class="DivTableContainer">

								<h3>Branch Appeal Document {{ numberIndex+1 }}</h3>
								<table class="TableListDetailsAttachment">
									<tbody>
										<tr
											*ngFor="let modelClaimProposalAppealGallery of modelClaimProposalAppeal.listModelClaimProposalAppealGallery">
											<td>
												<a *ngIf="modelClaimProposalAppealGallery.Type !== undefined ">
													{{ _enumPhotoType[modelClaimProposalAppealGallery.Type] |
													convertSeparateEnumSpace }}
												</a>
											</td>
											<td>
												<a>
													{{ modelClaimProposalAppealGallery.Name }}.
													{{ modelClaimProposalAppealGallery.Extension }}
												</a>
											</td>
											<td>
												<label class="LabelAttachment"
													*ngIf="modelClaimProposalAppealGallery.ID !== undefined">
													<input type="button" class="ButtonDownload"
														(click)="showImageForAppeal(modelClaimProposalAppealGallery);">
												</label>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<div id="divClaimProposalVerifiedByMakerHeadOffice" class="DivMainGeneral"
					*ngIf="_modelClaimProposal.MakerHeadOfficeReviewedOn !== undefined">
					<form>
						<div class="DivForm">
							<H3>Note HeadOffice Staff</H3>
							<fieldset>
								<div class="DivFormHorizontalContainer DivFormReviewedOn">
									<label for="textHeadOfficeReviewedNote">Note</label>
									<label *ngIf="_modelClaimProposal.MakerHeadOfficeReviewedStatus !== undefined"
										for="textMakerHeadOfficeReviewedOn">
										{{ _modelClaimProposal.MakerHeadOfficeReviewedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }} |
										{{ _modelClaimProposal.modelUserMakerHeadOfficeReviewed?.getName() |
										convertEmptyToDash }} |
										<i class="IActiveStatus" [ngClass]="{
										'NotVerified' :  _modelClaimProposal.MakerHeadOfficeReviewedStatus === _enumReviewState.Rejected,
										'Verified' : _modelClaimProposal.MakerHeadOfficeReviewedStatus === _enumReviewState.Verified}">
											{{ _enumReviewState[_modelClaimProposal.MakerHeadOfficeReviewedStatus] }}
										</i>
									</label>
								</div>
								<!-- <input type="text" name="modelClaimProposalHeadOfficeReviewedNote" id="textHeadOfficeReviewedNote" placeholder="input note" [(ngModel)]="_modelClaimProposal.HeadOfficeReviewedNote" [disabled]="_booleanReadOnly"> -->
								<span type="text" name="modelClaimProposalHeadOfficeReviewedNote"
									id="textHeadOfficeRevieweddNote" placeholder="input note">{{
									_modelClaimProposal.MakerHeadOfficeReviewedNote }}</span>
							</fieldset>
						</div>
					</form>
				</div>

				<div id="divClaimProposalVerifiedByCheckerHeadOffice" class="DivMainGeneral"
					*ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedOn !== undefined">
					<form>
						<div class="DivForm">
							<H3>Note HeadOffice SPV</H3>
							<fieldset>
								<div class="DivFormHorizontalContainer DivFormReviewedOn">
									<label for="textCheckerHeadOfficeNote">Note</label>
									<label *ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedStatus !== undefined"
										for="textCheckerHeadOfficeReviewedOn">
										{{ _modelClaimProposal.CheckerHeadOfficeReviewedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }} |
										{{ _modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.getName() |
										convertEmptyToDash }} |
										<i class="IActiveStatus" [ngClass]="{
										'NotVerified' :  _modelClaimProposal.CheckerHeadOfficeReviewedStatus === _enumReviewState.Rejected,
										'Verified' : _modelClaimProposal.CheckerHeadOfficeReviewedStatus === _enumReviewState.Verified}">
											{{ _enumReviewState[_modelClaimProposal.CheckerHeadOfficeReviewedStatus] }}
										</i>
									</label>
								</div>
								<!-- <input type="text" name="modelClaimProposalHeadOfficeReviewedNote" id="textHeadOfficeReviewedNote" placeholder="input note" [(ngModel)]="_modelClaimProposal.HeadOfficeReviewedNote" [disabled]="_booleanReadOnly"> -->
								<span type="text" name="modelClaimProposalBranchReviewedNote"
									id="textCheckerHeadOfficeReviewedNote" placeholder="input note">{{
									_modelClaimProposal.CheckerHeadOfficeReviewedNote }}</span>
							</fieldset>
						</div>
					</form>
				</div>

				<div id="divClaimProposalVerifiedBySignerHeadOffice" class="DivMainGeneral"
					*ngIf="_modelClaimProposal.HeadOfficeReviewedOn !== undefined">
					<form>
						<div class="DivForm">
							<H3>Note HeadOffice Manager</H3>
							<fieldset>
								<div class="DivFormHorizontalContainer DivFormReviewedOn">
									<label for="textSignerHeadOfficeReviewedOn">Note</label>
									<label *ngIf="_modelClaimProposal.HeadOfficeReviewedStatus !== undefined"
										for="textSignerHeadOfficeReviewedOn">
										{{ _modelClaimProposal.HeadOfficeReviewedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }} |
										{{ _modelClaimProposal.modelUserHeadOfficeReviewed?.getName() |
										convertEmptyToDash }} |
										<i class="IActiveStatus" [ngClass]="{
										'NotVerified' :  _modelClaimProposal.HeadOfficeReviewedStatus === _enumReviewState.Rejected,
										'Verified' : _modelClaimProposal.HeadOfficeReviewedStatus === _enumReviewState.Approved}">
											{{ _enumReviewState[_modelClaimProposal.HeadOfficeReviewedStatus] }}
										</i>
									</label>
								</div>
								<!-- <input type="text" name="modelClaimProposalHeadOfficeReviewedNote" id="textHeadOfficeReviewedNote" placeholder="input note" [(ngModel)]="_modelClaimProposal.HeadOfficeReviewedNote" [disabled]="_booleanReadOnly"> -->
								<span type="text" name="modelClaimProposalHeadOfficeReviewedNote"
									id="textSignerHeadOfficeReviewedOn" placeholder="input note">{{
									_modelClaimProposal.HeadOfficeReviewedNote }}</span>
							</fieldset>
						</div>
					</form>
				</div>
			</form>

			<form *ngIf="_booleanSurvey">
				<ng-container *ngIf="_booleanMV">
					<div class="DivForm"
						*ngFor="let modelListSurvey of this._modelClaimProposal.listModelSurvey; let numberIndex = index">
						<div class="DivFormListSurveyModel">
							<h3>Policyholder Information</h3>
							<fieldset>
								<div class="DivFormHorizontalContainer">
									<div class="DivForm2Column">

										<label for="spanSurveySurveyWorkshopName">Workshop Name</label>
										<span id="spanSurveySurveyWorkshopName"
											name="modelClaimProposalListSurveyModelListSurveyWorkshopName">{{
											modelListSurvey.WorkshopName |
											convertEmptyToDash }}</span>

										<label for="spanSurveySurveyWorkshopDate">Workshop Date</label>
										<span id="spanSurveySurveyWorkshopDate"
											name="modelClaimProposalListSurveyModelListSurveyWorkshopDate">{{
											modelListSurvey.WorkshopVisitOn
											| initiateWithoutUTC | convertToDateShort | convertEmptyToDash }}</span>

										<label for="spanSurveySurveyWorkshopNote">Workshop Note</label>
										<span id="spanSurveySurveyWorkshopNote"
											name="modelClaimProposalListSurveyModelListSurveyWorkshopNote">{{
											modelListSurvey.WorkshopNote |
											convertEmptyToDash }}</span>

										<label for="spanSurveyVehicleBrandName">Vehicle Brand Name</label>
										<span id="spanSurveyVehicleBrandName" name="modelListSurveyVehicleBrandName">{{
											modelListSurvey.VehicleBrandName | convertEmptyToDash }}</span>

										<label for="spanSurveyVehicleModelName">Vehicle Model Name</label>
										<span id="spanSurveyVehicleModelName" name="modelListSurveyVehicleModelName">{{
											modelListSurvey.VehicleModelName | convertEmptyToDash }}</span>

										<label for="spanSurveyVehicleColorName">Vehicle Color Name</label>
										<span id="spanSurveyVehicleColorName" name="modelListSurveyVehicleColorName">{{
											modelListSurvey.VehicleColorName | convertEmptyToDash }}</span>
									</div>
									<div class="DivForm2Column">

										<label for="spanSurveySurveyDate">Survey Date</label>
										<span id="spanSurveySurveyDate" name="modelListSurveySurveyDate">{{
											_modelClaimProposal.SurveyDate |
											initiateWithoutUTC | convertToDateShort | convertEmptyToDash }}</span>

										<label for="spanSurveyVehicleCreatedOn">Survey Address</label>
										<span id="spanSurveyVehicleCreatedOn" name="modelListSurveyCreatedOn">{{
											_modelClaimProposal.SurveyAddress | convertEmptyToDash }}</span>

										<table class="TableOwnRetention">
											<tr>
												<td class="TdOwnRetentionnFirst"><span>Service</span></td>
												<td class="TdOwnRetentionnSecond"></td>
												<td class="TdOwnRetentionnTotal"><span>{{
														_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
														modelListSurvey.ServiceAmount | currencyValue |
														convertEmptyToDash }}</span></td>
											</tr>
											<tr>
												<td class="TdOwnRetentionnFirst"> <span>Part</span> </td>
												<td class="TdOwnRetentionnSecond"> <span></span> </td>
												<td class="TdOwnRetentionnTotal"> <span>{{
														_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
														modelListSurvey.PartAmount | currencyValue | convertEmptyToDash
														}}</span>
												</td>
											</tr>
											<tr>
												<td class="TdOwnRetentionnFirst"> <span> PPN </span> </td>
												<td class="TdOwnRetentionnSecond"> <span> {{
														modelListSurvey.setNumberToPercentage(modelListSurvey.TaxPercentage!)
														}}{{
														_stringConstant.STRING_CHARACTER_SEPARATOR_PERCENTAGE }} </span>
												</td>
												<td class="TdOwnRetentionnTotal"> <span>{{
														_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
														modelListSurvey.TaxAmount | currencyValue | convertEmptyToDash
														}}</span> </td>
											</tr>
											<tr>
												<td class="TdOwnRetentionnFirst"> <span>Salvage</span> </td>
												<td class="TdOwnRetentionnSecond"> <span></span> </td>
												<td class="TdOwnRetentionnTotal"> <span>{{
														_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
														modelListSurvey.SalvageAmount | currencyValue |
														convertEmptyToDash }}</span>
												</td>
											</tr>
											<tr>
												<td class="TdOwnRetentionnFirst"><span>Total Casco</span></td>
												<td class="TdOwnRetentionnSecond"></td>
												<td class="TdOwnRetentionnTotal"><span>{{
														_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
														modelListSurvey.CascoAmount | currencyValue | convertEmptyToDash
														}}</span></td>
											</tr>
										</table>
									</div>
								</div>
							</fieldset>

							<h3 *ngIf="modelListSurvey.listModelSurveyGallery!.length > 0">Items</h3>
							<fieldset *ngIf="modelListSurvey.listModelSurveyGallery!.length > 0">
								<div class="DivFormHorizontalContainer">
									<ng-container
										*ngFor="let modelListModelSurveyGallery of modelListSurvey.listModelSurveyGallery; let numberIndex = index">
										<div class="DivForm2Column DivForm2ColumnNoMargin"
											*ngIf="modelListModelSurveyGallery.Type && ( modelListModelSurveyGallery.Type >= _enumPhotoType.FrontBumperLeft && modelListModelSurveyGallery.Type <= _enumPhotoType.VelFrontRight || modelListModelSurveyGallery.Type >= _enumPhotoType.EngineHood ) || modelListModelSurveyGallery.Type === _enumPhotoType.Other">
											<div class="DivImageCapture">
												<h4 for="spanIndentificationNumber">
													{{ _enumPhotoType[modelListModelSurveyGallery.Type] |
													convertSeparateEnumSpace }}
												</h4>
												<div class="DivDescriptionItems">
													<div>
														<div *ngIf="!checkSalvageValue(modelListModelSurveyGallery.ClaimItemType!, _enumClaimItemType.Replace)">
															<label for="spanSurveyCostEstimationAmount">{{ _enumClaimItemType[modelListModelSurveyGallery.ClaimItemType!] }}</label>
															<span id="spanSurveyCostEstimationAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListModelSurveyGallery.CostEstimationAmount | currencyValue | convertEmptyToDash }}</span>
														</div>
														<div *ngIf="checkSalvageValue(modelListModelSurveyGallery.ClaimItemType!, _enumClaimItemType.Replace)">
															<label for="spanSurvey">{{ _enumClaimItemType[1] }}</label>
															<span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListModelSurveyGallery.CostEstimationAmount | currencyValue | convertEmptyToDash }}</span>
		
															<label>{{ _enumClaimItemType[modelListModelSurveyGallery.ClaimItemType!] }}</label>
															<span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListModelSurveyGallery.SalvageEstimationAmount | currencyValue | convertEmptyToDash }}</span>
														 </div>
														<div>
															<span class="SpanActiveStatus SpanStatusImage" [ngClass]="{ 'StatusApprove' : modelListModelSurveyGallery.ActionType === _enumClaimItemActionType.Approve, 'StatusReject' :  modelListModelSurveyGallery.ActionType === _enumClaimItemActionType.Reject, 'Pending' : modelListModelSurveyGallery.ActionType === undefined ||  modelListModelSurveyGallery.ActionType === null }">{{ _modelClaimProposal.setStatusImageItems(modelListModelSurveyGallery) }}</span>
														</div>
													</div>
	
												</div>

												<!-- <table class="TableOwnRetention"
													*ngIf="!checkSalvageValue(modelListModelSurveyGallery.ClaimItemType!, _enumClaimItemType.Replace)">
													<tr>
														<td> <span>{{
																_enumClaimItemType[modelListModelSurveyGallery.ClaimItemType!]
																}}</span> </td>
														<td> <span> </span> </td>
														<td> <span>{{_stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{
																modelListModelSurveyGallery.CostEstimationAmount |
																currencyValue | convertEmptyToDash
																}}</span> </td>
													</tr>
												</table>
												<table class="TableOwnRetention"
													*ngIf="checkSalvageValue(modelListModelSurveyGallery.ClaimItemType!, _enumClaimItemType.Replace)">
													<tr>
														<td> <span>{{ _enumClaimItemType[1] }}</span> </td>
														<td> <span> </span> </td>
														<td> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
																modelListModelSurveyGallery.CostEstimationAmount |
																currencyValue | convertEmptyToDash
																}}</span> </td>
													</tr>
													<tr>
														<td> <span>{{
																_enumClaimItemType[modelListModelSurveyGallery.ClaimItemType!]
																}}</span> </td>
														<td> <span> </span> </td>
														<td> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
																modelListModelSurveyGallery.SalvageEstimationAmount |
																currencyValue | convertEmptyToDash
																}}</span> </td>
													</tr>
												</table> -->
												<img *ngIf="modelListModelSurveyGallery.Extension !== _stringFormatDocumentMP4 && modelListModelSurveyGallery.URL !== undefined"
													src="{{ getImage(modelListModelSurveyGallery.URL) }}"
													alt="{{ _enumPhotoType[modelListModelSurveyGallery.Type]  | convertSeparateEnumSpace }}"
													(dblclick)="showImageListSurveyGallery(modelListModelSurveyGallery);">
												<label for="spanIndentificationNumber">Note</label>
												<span id="spanIndentificationNumber">{{ modelListModelSurveyGallery.Note
													| convertEmptyToDash
													}}</span>
											</div>
										</div>
									</ng-container>
								</div>
							</fieldset>

							<h3 *ngIf="modelListSurvey.listModelSurveyGallery!.length > 0 ">Supporting Survey</h3>
							<fieldset *ngIf="modelListSurvey.listModelSurveyGallery!.length > 0">
								<div class="DivFormHorizontalContainer">
									<ng-container
										*ngFor="let modelListSurveyGallery of modelListSurvey.listModelSurveyGallery; let numberIndex = index">
										<div class="DivForm2Column DivForm2ColumnNoMargin"
											*ngIf="modelListSurveyGallery.Type && ((modelListSurveyGallery.Type >= _enumPhotoType.BuildingFront && modelListSurveyGallery.Type <= _enumPhotoType.FrontBumperLeft) || modelListSurveyGallery.Type === _enumPhotoType.SupportingDocument) && modelListSurveyGallery.URL !== undefined && modelListSurveyGallery.Type !== _enumPhotoType.PoliceReportLetter">
											<label for="spanIndentificationNumber">
												{{ _enumPhotoType[modelListSurveyGallery.Type] |
												convertSeparateEnumSpace }}
											</label>
											<div class="DivImageCapture">
												<img *ngIf="modelListSurveyGallery.Extension !== _stringFormatDocumentMP4 && modelListSurveyGallery.URL !== undefined"
													src="{{ getImage(modelListSurveyGallery.URL) }}"
													alt="{{ _enumPhotoType[modelListSurveyGallery.Type]  | convertSeparateEnumSpace }}"
													(dblclick)="showImageListSurveyGallery(modelListSurveyGallery);">
												<!-- <label class="LabelForNote" for="labelForImageSurvey">Note</label>
												<span class="SpanForNote" id="labelForImageSurvey">{{ modelListSurveyGallery.Note | convertEmptyToDash }}</span> -->
											</div>
										</div>
									</ng-container>
								</div>
							</fieldset>

							<ng-container
								*ngFor="let modelListSurvey of this._modelClaimProposal.listModelSurvey; let numberIndex = index">
								<ng-container
									*ngFor="let modelListSurveyGallery of modelListSurvey.listModelSurveyGallery; let numberIndex = index">
									<h3 *ngIf="modelListSurveyGallery.Type === _enumPhotoType.SurveyVideo">Video Survey
									</h3>
									<fieldset *ngIf="modelListSurveyGallery.Type === _enumPhotoType.SurveyVideo">
										<div class="DivFormHorizontalContainer">
											<div class="DivForm2Column DivForm2ColumnNoMargin">
												<h4 for="spanIndentificationNumber">
													{{ _enumPhotoType[modelListSurveyGallery.Type] |
													convertSeparateEnumSpace }}
												</h4>

												<label for="spanClaimType"> Claim Type </label>
												<span id="spanClaimType">{{
													_enumClaimItemType[modelListSurveyGallery.ClaimItemType!] |
													convertEmptyToDash }}</span>

												<div class="DivImageCapture">
													<video class="img" controls>
														<source src="{{ getImage(modelListSurveyGallery.URL) }}"
															type='video/mp4' />
													</video>
												</div>
											</div>
										</div>
									</fieldset>
								</ng-container>
							</ng-container>

							<h3 *ngIf="modelListSurvey.listModelSurveyGallery">Claim Letter</h3>
							<fieldset>
								<div class="DivFormHorizontalContainer">
									<div class="DivForm2Column DivForm2ColumnNoMargin">
										<div *ngFor="let arrayTypeClaimLetter of _arrayNumberTypeClaimLetter">
											<label class="LabelClaimLetterStatus"
												(dblclick)="showImageListSurveyGalleryModel(modelListSurvey.listModelSurveyGallery!, arrayTypeClaimLetter);">
												{{ _enumPhotoType[arrayTypeClaimLetter] | convertSeparateEnumSpace }}
												<a
													[ngClass]="
											{
												'NotVerified' : getClaimProposalGalleryModelClaimLetter(modelListSurvey.listModelSurveyGallery!, arrayTypeClaimLetter) === 'NotVerified',
												'Verified' : getClaimProposalGalleryModelClaimLetter(modelListSurvey.listModelSurveyGallery!,arrayTypeClaimLetter) === 'Verified'}">
													{{
													getClaimProposalGalleryModelClaimLetter(modelListSurvey.listModelSurveyGallery!,arrayTypeClaimLetter)
													| convertSeparateEnumSpace }}

												</a>
											</label>
										</div>
									</div>
								</div>
							</fieldset>

							<h3>Survey Information</h3>
							<fieldset>
								<div class="DivFormHorizontalContainer">
									<div class="DivForm2Column DivForm2ColumnNoMargin">
										<label class="LabelClaimSurveyStatus"> Status
											<a [ngClass]="
										{
											'Verified' : modelListSurvey.Status! === _enumSurveyStatus.Approved,
											'Verify' : modelListSurvey.Status! === _enumSurveyStatus.Verify,
											'Pending' : modelListSurvey.Status! === _enumSurveyStatus.Pending,
											'NotVerified' : modelListSurvey.Status! === _enumSurveyStatus.Rejected,
											'Resurvey' : modelListSurvey.Status! === _enumSurveyStatus.Resurvey,
											'Surveyed' : modelListSurvey.Status! === _enumSurveyStatus.Surveyed
										}">
												{{ _enumSurveyStatus[modelListSurvey.Status!] | convertSeparateEnumSpace
												}}
											</a>
										</label>

										<ng-container *ngIf="_modelClaimProposal.listModelSurveyUserHistory">
											<ng-container *ngFor="let modelSurveyUserHistory of _modelClaimProposal.listModelSurveyUserHistory">
												<ng-container *ngIf="modelSurveyUserHistory.Status === _enumHistorySurvey.Resurveyed || modelSurveyUserHistory.Status === _enumHistorySurvey.Surveyed">
													<label for="spanSurveyNote">Note
														<i *ngIf="modelSurveyUserHistory.ActionOn">
															{{ modelSurveyUserHistory.ActionOn | initiateWithoutUTC | convertToDateMedium |
															convertEmptyToDash }} |
															{{ modelSurveyUserHistory.FirstName }} {{ modelSurveyUserHistory.MiddleName }} {{ modelSurveyUserHistory.LastName }}
														</i>
													</label>
													<span id="spanSurveyNote" name="spanSurveyNote">{{ modelSurveyUserHistory.ActionNote |
													convertEmptyToDash
													}}</span>
												</ng-container>
											</ng-container>
										</ng-container>

										<ng-container *ngIf="!_modelClaimProposal.listModelSurveyUserHistory">
											<label for="spanSurveyNote">Note
												<i *ngIf="modelListSurvey.NotedOn">
													{{ modelListSurvey.NotedOn | initiateWithoutUTC | convertToDateMedium |
													convertEmptyToDash }} |
													{{ modelListSurvey.modelUserNoted?.FirstName }} {{ modelListSurvey.modelUserNoted?.MiddleName }} {{ modelListSurvey.modelUserNoted?.LastName }}
												</i>
											</label>
											<span id="spanSurveyNote" name="spanSurveyNote">{{ modelListSurvey.Note |
											convertEmptyToDash
											}}</span>
										</ng-container>


										<h3>Approval Note</h3>

										<ng-container *ngIf="_modelClaimProposal.listModelSurveyUserHistory">
											<ng-container *ngFor="let modelSurveyUserHistory of _modelClaimProposal.listModelSurveyUserHistory">
												<ng-container *ngIf="modelSurveyUserHistory.Status === _enumHistorySurvey.Verified">
													<label for="spanSurveyVerifyNote">Check Note
														<i *ngIf="modelSurveyUserHistory.ActionOn">
															{{ modelSurveyUserHistory.ActionOn | initiateWithoutUTC |
															convertToDateMedium | convertEmptyToDash
															}} |
															{{ modelSurveyUserHistory?.FirstName }} {{ modelSurveyUserHistory?.MiddleName }} {{ modelSurveyUserHistory?.LastName }}
														</i>
													</label>
													<span id="spanSurveyVerifyNote" name="spanSurveyVerifyNote">{{
														modelSurveyUserHistory.ActionNote |
														convertEmptyToDash }}</span>
												</ng-container>
												<ng-container *ngIf="modelSurveyUserHistory.Status === _enumHistorySurvey.Resurvey">
													<label for="spanSurveyResurveyNote">Resurvey Note
														<i *ngIf="modelSurveyUserHistory.ActionOn">
															{{ modelSurveyUserHistory.ActionOn | initiateWithoutUTC |
															convertToDateMedium |
															convertEmptyToDash }} |
															{{ modelSurveyUserHistory?.FirstName }} {{ modelSurveyUserHistory?.MiddleName }} {{ modelSurveyUserHistory?.LastName }}
														</i>
													</label>
													<span id="spanSurveyVerifyNote" name="spanSurveyVerifyNote">{{
														modelSurveyUserHistory.ActionNote |
														convertEmptyToDash }}</span>
												</ng-container>
												<ng-container *ngIf="modelSurveyUserHistory.Status === _enumHistorySurvey.ApprovedChecker">
													<label for="spanSurveyApprovedNote">Verify Note
														<i *ngIf="modelSurveyUserHistory.ActionOn">
															{{ modelSurveyUserHistory.ActionOn | initiateWithoutUTC |
															convertToDateMedium | convertEmptyToDash
															}} |
															{{ modelSurveyUserHistory?.FirstName }} {{ modelSurveyUserHistory?.MiddleName }} {{ modelSurveyUserHistory?.LastName }}
														</i>
													</label>
													<span id="spanSurveyVerifyNote" name="spanSurveyVerifyNote">{{
														modelSurveyUserHistory.ActionNote |
														convertEmptyToDash }}</span>
												</ng-container>
												<ng-container *ngIf="modelSurveyUserHistory.Status === _enumHistorySurvey.Rejected">
													<label for="spanSurveyRejectedNote">Rejected Note
														<i *ngIf="modelSurveyUserHistory.ActionOn">
															{{ modelSurveyUserHistory.ActionOn | initiateWithoutUTC |
															convertToDateMedium | convertEmptyToDash
															}} |
															{{ modelSurveyUserHistory?.FirstName }} {{ modelSurveyUserHistory?.MiddleName }} {{ modelSurveyUserHistory?.LastName }}
														</i>
													</label>
													<span id="spanSurveyVerifyNote" name="spanSurveyVerifyNote">{{
														modelSurveyUserHistory.ActionNote |
														convertEmptyToDash }}</span>
												</ng-container>
											</ng-container>
										</ng-container>

										<ng-container *ngIf="!_modelClaimProposal.listModelSurveyUserHistory">
											<ng-container *ngIf="modelListSurvey.VerifiedOn !== undefined">
												<label for="spanSurveyVerifyNote">Check Note
													<i *ngIf="modelListSurvey.VerifiedOn">
														{{ modelListSurvey.VerifiedOn | initiateWithoutUTC |
														convertToDateMedium | convertEmptyToDash
														}} |
														{{ modelListSurvey.modelUserVerified?.FirstName }} {{ modelListSurvey.modelUserVerified?.MiddleName }} {{ modelListSurvey.modelUserVerified?.LastName }}
													</i>
												</label>
												<span id="spanSurveyVerifyNote" name="spanSurveyVerifyNote">{{
													modelListSurvey.VerifyNote |
													convertEmptyToDash }}</span>
											</ng-container>
											<ng-container *ngIf="modelListSurvey.ResurveyedOn !== undefined">
												<label for="spanSurveyResurveyNote">Resurvey Note
													<i *ngIf="modelListSurvey.ResurveyedOn">
														{{ modelListSurvey.ResurveyedOn | initiateWithoutUTC |
														convertToDateMedium |
														convertEmptyToDash }} |
														{{ modelListSurvey.modelUserResurveyed?.FirstName }} {{ modelListSurvey.modelUserResurveyed?.MiddleName }} {{ modelListSurvey.modelUserResurveyed?.LastName }}
													</i>
												</label>
												<span id="spanSurveyVerifyNote" name="spanSurveyVerifyNote">{{
													modelListSurvey.ResurveyNote |
													convertEmptyToDash }}</span>
											</ng-container>
											<ng-container *ngIf="modelListSurvey.ApprovedOn !== undefined">
												<label for="spanSurveyApprovedNote">Verify Note
													<i *ngIf="modelListSurvey.ApprovedOn">
														{{ modelListSurvey.ApprovedOn | initiateWithoutUTC |
														convertToDateMedium | convertEmptyToDash
														}} |
														{{ modelListSurvey.modelUserApproved?.FirstName }} {{ modelListSurvey.modelUserApproved?.MiddleName }} {{ modelListSurvey.modelUserApproved?.LastName }}
													</i>
												</label>
												<span id="spanSurveyVerifyNote" name="spanSurveyVerifyNote">{{
													modelListSurvey.ApprovedNote |
													convertEmptyToDash }}</span>
											</ng-container>
											<ng-container *ngIf="modelListSurvey.RejectedOn !== undefined">
												<label for="spanSurveyRejectedNote">Rejected Note
													<i *ngIf="modelListSurvey.RejectedOn">
														{{ modelListSurvey.RejectedOn | initiateWithoutUTC |
														convertToDateMedium | convertEmptyToDash
														}} |
														{{ modelListSurvey.modelUserRejected?.FirstName }} {{ modelListSurvey.modelUserRejected?.MiddleName }} {{ modelListSurvey.modelUserRejected?.LastName }}
													</i>
												</label>
												<span id="spanSurveyVerifyNote" name="spanSurveyVerifyNote">{{
													modelListSurvey.RejectedNote |
													convertEmptyToDash }}</span>
											</ng-container>
										</ng-container>
										<!-- <span *ngIf="modelListSurvey.Status === _enumSurveyStatus.Resurvey" id="spanSurveyNote" name="spanSurveyNote">{{ modelListSurvey.ResurveyNote | convertEmptyToDash }}</span>
									<span *ngIf="modelListSurvey.Status === _enumSurveyStatus.Approved" id="spanSurveyNote" name="spanSurveyNote">{{ modelListSurvey.ApprovedNote | convertEmptyToDash }}</span> -->

									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="!_booleanMV">
					<div class="DivForm">
						<div class="DivFormListSurveyModel">
							<h3>Survey</h3>
							<fieldset>
								<div class="DivFormHorizontalContainer">
									<div class="DivForm2Column">
										<label for="spanSurveyAddress">Survey Branch</label>
										<span id="spanSurveyAddress" name="modelClaimProposalSurveyAddress">{{
											_modelClaimProposal.SurveyNearestBranchName | convertEmptyToDash }}</span>

										<label for="spanSurveyLocation">Survey Address</label>
										<span id="spanSurveyLocation" name="modelClaimProposalSurveyLocation">{{
											_modelClaimProposal.SurveyAddress | convertEmptyToDash }}</span>

										<label for="spanSurveyDate">Survey Date</label>
										<span id="spanSurveyDate" name="modelClaimProposalSurveyDate">{{
											_modelClaimProposal.SurveyDate |
											initiateWithoutUTC | convertToDateShort | convertEmptyToDash }}</span>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</ng-container>
			</form>

			<form *ngIf="_booleanSurvey">
				<div class="DivForm"
					*ngFor="let modelListSurveyThirdPartyLiability of this._modelClaimProposal.listModelSurveyThirdPartyLiability; let numberIndex = index">
					<div class="DivFormListSurveyThirdPartyLiability">
						<h3>ThirdParty Liability {{ numberIndex+1 }}</h3>
						<fieldset>
							<div *ngIf="modelListSurveyThirdPartyLiability.LegalResponsbilityLiability === _enumLegalResponsibilityLiabilityType.MotorVehicle"
								class="DivFormHorizontalContainer">
								<div class="DivForm2Column">

									<label for="spanSurveyThirdPartyLiabilitySurveyWorkshopName">Workshop Name</label>
									<span id="spanSurveyThirdPartyLiabilityWorkshopName"
										name="modelClaimProposalListSurveyModelListSurveyWorkshopName">{{
										modelListSurveyThirdPartyLiability.WorkshopName | convertEmptyToDash }}</span>

									<label for="spanSurveyThirdPartyLiabilityWorkshopDate">Workshop Date</label>
									<span id="spanSurveyThirdPartyLiabilityWorkshopDate"
										name="modelClaimProposalListSurveyModelListSurveyWorkshopDate">{{
										modelListSurveyThirdPartyLiability.WorkshopVisitOn | initiateWithoutUTC |
										convertToDateShort |
										convertEmptyToDash }}</span>

									<label for="spanSurveyThirdPartyLiabilityWorkshopNote">Workshop Note</label>
									<span id="spanSurveyThirdPartyLiabilityWorkshopNote"
										name="modelClaimProposalListSurveyModelListSurveyWorkshopNote">{{
										modelListSurveyThirdPartyLiability.WorkshopNote | convertEmptyToDash }}</span>

									<label for="spanSurveyThirdPartyLiabilityLegalResponsibilityLiability">Legal
										Responsibility
										Liability</label>
									<span id="spanSurveyThirdPartyLiabilityLegalResponsibilityLiability"
										name="modelClaimProposalLegalResponsibilityLiability">{{
										_enumLegalResponsibilityLiabilityType[modelListSurveyThirdPartyLiability.LegalResponsbilityLiability!]
										| convertEmptyToDash }}</span>

									<label for="spanSurveyThirdPartyLiabilityContactPersonName">Name</label>
									<span id="spanSurveyThirdPartyLiabilityContactPersonName"
										name="modelListSurveyThirdPartyLiabilityContactPersonName">{{
										modelListSurveyThirdPartyLiability.ContactPersonName | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityContactPersonMobilePhone">Mobile
										Phone</label>
									<span id="spanSurveyThirdPartyLiabilityContactPersonMobilePhone"
										name="modelListSurveyThirdPartyLiabilityContactPersonMobilePhone">{{
										modelListSurveyThirdPartyLiability.ContactPersonMobilePhone | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityContactPersonEmail">Email</label>
									<span id="spanSurveyThirdPartyLiabilityContactPersonEmail"
										name="modelListSurveyThirdPartyLiabilityContactPersonEmail">{{
										modelListSurveyThirdPartyLiability.ContactPersonEmail | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityContactPersonAddress">Address</label>
									<span id="spanSurveyThirdPartyLiabilityContactPersonAddress"
										name="modelListSurveyThirdPartyLiabilityContactPersonAddress">{{
										modelListSurveyThirdPartyLiability.ContactPersonAddress | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilitySurveyDate">Survey Date</label>
									<span id="spanSurveyThirdPartyLiabilitySurveyDate"
										name="modelClaimProposalListSurveyModelListSurveyCreatedOn">{{
										modelListSurveyThirdPartyLiability.CreatedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }}</span>

									<label for="spanSumEstimation">Sum Estimation</label>
									<span id="spanSumEstimation"
										name="modelListSurveyThirdPartyLiabilitySumEstimation">{{
										_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
										setCountSumEstimationForThirdParty() | currencyValue
										| convertEmptyToDash }}</span>

								</div>
								<div class="DivForm2Column">
									<label for="spanSurveyThirdPartyLiabilityVehicleBrandName">Vehicle Brand
										Name</label>
									<span id="spanSurveyThirdPartyLiabilityVehicleBrandName"
										name="modelListSurveyVehicleBrandName">{{
										modelListSurveyThirdPartyLiability.VehicleBrandName | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityVehicleModelName">Vehicle Model
										Name</label>
									<span id="spanVSurveyThirdPartyLiabilityehicleModelName"
										name="modelListSurveyVehicleModelName">{{
										modelListSurveyThirdPartyLiability.VehicleModelName | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityVehicleColorName">Vehicle Color
										Name</label>
									<span id="spanSurveyThirdPartyLiabilityVehicleColorName"
										name="modelListSurveyVehicleColorName">{{
										modelListSurveyThirdPartyLiability.VehicleColorName | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityLicensePlate">License Plate Number</label>
									<span id="spanSurveyThirdPartyLiabilityLicensePlate"
										name="modelListSurveyLicensePlate">{{
										modelListSurveyThirdPartyLiability.LicensePlatePrefixCode | convertEmptyToDash
										}} {{
										modelListSurveyThirdPartyLiability.LicensePlateInfix | convertEmptyToDash }} {{
										modelListSurveyThirdPartyLiability.LicensePlateSuffix | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilitySurveyWorkshopName">Workshop Name</label>
									<span id="spanSurveyThirdPartyLiabilitySurveyWorkshopName"
										name="modelClaimProposalListSurveyModelListSurveyWorkshopName">{{
										modelListSurveyThirdPartyLiability.WorkshopName | convertEmptyToDash }}</span>

									<label for="spanSurveyThirdPartyLiabilitySurveyWorkshopDate">Workshop Date</label>
									<span id="spanSurveyThirdPartyLiabilitySurveyWorkshopDate"
										name="modelClaimProposalListSurveyModelListSurveyWorkshopDate">{{
										modelListSurveyThirdPartyLiability.WorkshopVisitOn | initiateWithoutUTC |
										convertToDateShort |
										convertEmptyToDash }}</span>

									<table class="TableOwnRetention">
										<tr>
											<td class="TdOwnRetentionnFirst"><span>Service</span></td>
											<td class="TdOwnRetentionnSecond"></td>
											<td class="TdOwnRetentionnTotal"><span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.ServiceAmount | currencyValue |
													convertEmptyToDash
													}}</span></td>
										</tr>
										<tr>
											<td class="TdOwnRetentionnFirst"> <span>Part</span> </td>
											<td class="TdOwnRetentionnSecond"> <span></span> </td>
											<td class="TdOwnRetentionnTotal"> <span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.PartAmount | currencyValue |
													convertEmptyToDash }}</span>
											</td>
										</tr>
										<tr>
											<td class="TdOwnRetentionnFirst"> <span> PPN </span> </td>
											<td class="TdOwnRetentionnSecond"> <span> {{
													modelListSurveyThirdPartyLiability.setNumberToPercentage(modelListSurveyThirdPartyLiability.TaxPercentage!)
													}}{{ _stringConstant.STRING_CHARACTER_SEPARATOR_PERCENTAGE }}</span>
											</td>
											<td class="TdOwnRetentionnTotal"> <span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.TaxAmount | currencyValue |
													convertEmptyToDash }}</span>
											</td>
										</tr>
										<tr>
											<td class="TdOwnRetentionnFirst"> <span>Salvage</span> </td>
											<td class="TdOwnRetentionnSecond"> <span></span> </td>
											<td class="TdOwnRetentionnTotal"> <span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.SalvageAmount | currencyValue |
													convertEmptyToDash
													}}</span>
											</td>
										</tr>
										<tr>
											<td class="TdOwnRetentionnFirst"><span>Total TPL {{ numberIndex+1 }}</span>
											</td>
											<td class="TdOwnRetentionnSecond"></td>
											<td class="TdOwnRetentionnTotal"><span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.TPLAmount | currencyValue |
													convertEmptyToDash }}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>

							<div *ngIf="modelListSurveyThirdPartyLiability.LegalResponsbilityLiability !== _enumLegalResponsibilityLiabilityType.MotorVehicle"
								class="DivFormHorizontalContainer">
								<div class="DivForm2Column">
									<label for="spanSurveyThirdPartyLiabilityLegalResponsibilityLiability">Legal
										Responsibility
										Liability</label>
									<span id="spanSurveyThirdPartyLiabilityLegalResponsibilityLiability"
										name="modelClaimProposalLegalResponsibilityLiability">{{modelListSurveyThirdPartyLiability.LegalResponsbilityLiability
										| convertEmptyToDash}} {{_enumLegalResponsibilityLiabilityType.MotorVehicle |
										convertEmptyToDash}}
										{{
										_enumLegalResponsibilityLiabilityType[modelListSurveyThirdPartyLiability.LegalResponsbilityLiability!]
										| convertEmptyToDash }}</span>

									<label for="spanSurveyThirdPartyLiabilityContactPersonName">Name</label>
									<span id="spanSurveyThirdPartyLiabilityContactPersonName"
										name="modelListSurveyContactPersonName">{{
										modelListSurveyThirdPartyLiability.ContactPersonName | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityContactPersonAddress">Address</label>
									<span id="spanSurveyThirdPartyLiabilityContactPersonAddress"
										name="modelListSurveyContactPersonAddress">{{
										modelListSurveyThirdPartyLiability.ContactPersonAddress | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityContactPersonEmail">Email</label>
									<span id="spanSurveyThirdPartyLiabilityContactPersonEmail"
										name="modelListSurveyContactPersonEmail">{{
										modelListSurveyThirdPartyLiability.ContactPersonEmail | convertEmptyToDash
										}}</span>

									<label for="spanSurveyThirdPartyLiabilityContactPersonMobilePhone">Mobile
										Phone</label>
									<span id="spanSurveyThirdPartyLiabilityContactPersonMobilePhone"
										name="modelClaimProposalContactPersonMobilePhone">{{
										modelListSurveyThirdPartyLiability.ContactPersonMobilePhone | convertEmptyToDash
										}}</span>
								</div>
								<div class="DivForm2Column">
									<label for="spanSurveyThirdPartyLiabilityDamageDescription">Damage
										Description</label>
									<span id="spanSurveyThirdPartyLiabilityDamageDescription"
										name="modelClaimProposalListSurveyModelListSurveyDamageDescription">{{
										modelListSurveyThirdPartyLiability.DamageDescription | convertEmptyToDash
										}}</span>

									<table class="TableOwnRetention">
										<tr>
											<td class="TdOwnRetentionnFirst"><span>Service</span></td>
											<td class="TdOwnRetentionnSecond"></td>
											<td class="TdOwnRetentionnTotal"><span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.ServiceAmount | currencyValue |
													convertEmptyToDash
													}}</span></td>
										</tr>
										<tr>
											<td class="TdOwnRetentionnFirst"> <span>Part</span> </td>
											<td class="TdOwnRetentionnSecond"> <span></span> </td>
											<td class="TdOwnRetentionnTotal"> <span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.PartAmount | currencyValue |
													convertEmptyToDash }}</span>
											</td>
										</tr>
										<tr>
											<td class="TdOwnRetentionnFirst"> <span> PPN </span> </td>
											<td class="TdOwnRetentionnSecond"> <span> {{
													modelListSurveyThirdPartyLiability.setNumberToPercentage(modelListSurveyThirdPartyLiability.TaxPercentage!)
													}}{{ _stringConstant.STRING_CHARACTER_SEPARATOR_PERCENTAGE }}
												</span> </td>
											<td class="TdOwnRetentionnTotal"> <span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.TaxAmount | currencyValue |
													convertEmptyToDash }}</span>
											</td>
										</tr>
										<tr>
											<td class="TdOwnRetentionnFirst"><span>Total TPL {{ numberIndex + 1
													}}</span></td>
											<td class="TdOwnRetentionnSecond"></td>
											<td class="TdOwnRetentionnTotal"><span>{{
													_stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
													modelListSurveyThirdPartyLiability.TPLAmount | currencyValue |
													convertEmptyToDash }}</span>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</fieldset>

						<ng-container
							*ngIf="modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery !== undefined">

							<h3
								*ngIf="modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery!.length > 0">
								Items
							</h3>
							<fieldset
								*ngIf="modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery!.length > 0">
								<div class="DivFormHorizontalContainer">
									<ng-container
										*ngFor="let modelListSurveyThirdPartyLiabilityGallery of modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery; let numberIndex = index">
										<div class="DivForm2Column DivForm2ColumnNoMargin"
											*ngIf="modelListSurveyThirdPartyLiabilityGallery.Type && (modelListSurveyThirdPartyLiabilityGallery.Type >= _enumPhotoType.FrontBumperLeft && modelListSurveyThirdPartyLiabilityGallery.Type <= _enumPhotoType.VelFrontRight || modelListSurveyThirdPartyLiabilityGallery.Type >= _enumPhotoType.EngineHood ) || modelListSurveyThirdPartyLiabilityGallery.Type === _enumPhotoType.Other">
											<div class="DivImageCapture">
												<h3 for="spanIndentificationNumber">
													{{ _enumPhotoType[modelListSurveyThirdPartyLiabilityGallery.Type] |
													convertSeparateEnumSpace
													}}
												</h3>

												<div class="DivDescriptionItems">
													<div>
														<div *ngIf="!checkSalvageValue(modelListSurveyThirdPartyLiabilityGallery.ClaimItemType!, _enumClaimItemType.Replace)">
															<label for="spanSurveyCostEstimationAmountTpl">{{ _enumClaimItemType[modelListSurveyThirdPartyLiabilityGallery.ClaimItemType!] }}</label>
															<span id="spanSurveyCostEstimationAmountTpl">{{_stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{ modelListSurveyThirdPartyLiabilityGallery.CostEstimationAmount | currencyValue | convertEmptyToDash }}</span>
														</div>
														<div *ngIf="checkSalvageValue(modelListSurveyThirdPartyLiabilityGallery.ClaimItemType!, _enumClaimItemType.Replace)">
															<label for="spanSurveyCostEstimationAmountTpl2">{{ _enumClaimItemType[1] }}</label>
															<span id="spanSurveyCostEstimationAmountTpl2">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurveyThirdPartyLiabilityGallery.CostEstimationAmount | currencyValue | convertEmptyToDash }}</span>
	
															<label for="spanSalvageEstimationAmountTpl">{{ _enumClaimItemType[modelListSurveyThirdPartyLiabilityGallery.ClaimItemType!] }}</label>
															<span id="spanSalvageEstimationAmountTpl">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurveyThirdPartyLiabilityGallery.SalvageEstimationAmount | currencyValue | convertEmptyToDash }}</span>
														</div>
														<div>
															<span class="SpanActiveStatus SpanStatusImage" [ngClass]="{ 'StatusApprove' : modelListSurveyThirdPartyLiabilityGallery.ActionType === _enumClaimItemActionType.Approve, 'StatusReject' :  modelListSurveyThirdPartyLiabilityGallery.ActionType === _enumClaimItemActionType.Reject, 'Pending' : modelListSurveyThirdPartyLiabilityGallery.ActionType === undefined ||  modelListSurveyThirdPartyLiabilityGallery.ActionType === null }">{{ _modelClaimProposal.setStatusImageItemsThirtparty(modelListSurveyThirdPartyLiabilityGallery!) }}</span>
														</div>
													</div>
	
													<!-- <div class="DivButtonForImage">
														<input type="button" class="ButtonPositive ButtonPositiveImage" [disabled]="setButtonForThirdParty(modelListSurveyThirdPartyLiability)" value="Approve" (click)="callApproveItemsSurveyThirdPartyLiabilityGalleryHeadOffice(this, modelListSurveyThirdPartyLiability.Token!, modelListSurveyThirdPartyLiabilityGallery.Token!)">
														<input type="button" class="ButtonNegative ButtonPositiveImage" [disabled]="setButtonForThirdParty(modelListSurveyThirdPartyLiability)" value="Reject" (click)="callRejectItemsSurveyThirdPartyLiabilityGalleryHeadOffice(this, modelListSurveyThirdPartyLiability.Token!, modelListSurveyThirdPartyLiabilityGallery.Token!)">
													</div> -->
	
												</div>

												<!-- <table class="TableOwnRetention"
													*ngIf="!checkSalvageValue(modelListSurveyThirdPartyLiabilityGallery.ClaimItemType!, _enumClaimItemType.Replace)">
													<tr>
														<td> <span>{{
																_enumClaimItemType[modelListSurveyThirdPartyLiabilityGallery.ClaimItemType!]
																}}</span> </td>
														<td> <span> </span> </td>
														<td> <span>{{_stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{
																modelListSurveyThirdPartyLiabilityGallery.CostEstimationAmount
																| currencyValue |
																convertEmptyToDash }}</span> </td>
													</tr>
												</table>
												<table class="TableOwnRetention"
													*ngIf="checkSalvageValue(modelListSurveyThirdPartyLiabilityGallery.ClaimItemType!, _enumClaimItemType.Replace)">
													<tr>
														<td> <span>{{ _enumClaimItemType[1] }}</span> </td>
														<td> <span> </span> </td>
														<td> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
																modelListSurveyThirdPartyLiabilityGallery.CostEstimationAmount
																| currencyValue |
																convertEmptyToDash }}</span> </td>
													</tr>
													<tr>
														<td> <span>{{
																_enumClaimItemType[modelListSurveyThirdPartyLiabilityGallery.ClaimItemType!]
																}}</span> </td>
														<td> <span> </span> </td>
														<td> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
																modelListSurveyThirdPartyLiabilityGallery.SalvageEstimationAmount
																| currencyValue |
																convertEmptyToDash }}</span> </td>
													</tr>
												</table> -->

												<img *ngIf="modelListSurveyThirdPartyLiabilityGallery.Extension !== _stringFormatDocumentMP4 && modelListSurveyThirdPartyLiabilityGallery.URL !== undefined"
													src="{{ getImage(modelListSurveyThirdPartyLiabilityGallery.URL) }}"
													alt="{{ _enumPhotoType[modelListSurveyThirdPartyLiabilityGallery.Type]  | convertSeparateEnumSpace }}"
													(dblclick)="showImageListSurveyThirdPartyLiabilityGalleryModel(modelListSurveyThirdPartyLiabilityGallery);">
												<label for="spanIndentificationNumber">Note</label>
												<span id="spanIndentificationNumber">{{
													modelListSurveyThirdPartyLiabilityGallery.Note |
													convertEmptyToDash }}</span>
											</div>
										</div>
									</ng-container>
								</div>
							</fieldset>

							<ng-container
								*ngFor="let modelListSurveyThirdPartyGallery of modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery; let numberIndex = index">
								<h3 *ngIf="modelListSurveyThirdPartyGallery.Type === _enumPhotoType.SurveyVideo">Video
									Survey</h3>
								<fieldset *ngIf="modelListSurveyThirdPartyGallery.Type === _enumPhotoType.SurveyVideo">
									<div class="DivFormHorizontalContainer">
										<div class="DivForm2Column DivForm2ColumnNoMargin"
											*ngIf="modelListSurveyThirdPartyGallery.Type">
											<label for="spanIndentificationNumber">
												{{ _enumPhotoType[modelListSurveyThirdPartyGallery.Type] |
												convertSeparateEnumSpace }}
											</label>
											<div class="DivImageCapture">
												<video class="img" controls>
													<source src="{{ getImage(modelListSurveyThirdPartyGallery.URL) }}"
														type='video/mp4' />
												</video>
											</div>
										</div>
									</div>
								</fieldset>
							</ng-container>

							<!-- <ng-container *ngIf="modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery !== undefined">
								<h3 *ngIf="modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery!.length > 0 ">Supporting Survey</h3>
								<fieldset *ngIf="modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery!.length > 0">
									<div class="DivFormHorizontalContainer">
										<ng-container
											*ngFor="let modelListSurveyThirdPartyLiabilitySupportingDocument of getListSurveyThirdPartyLiabilitySupportingDocument(modelListSurveyThirdPartyLiability); let numberIndex = index">
											<div class="DivForm2Column DivForm2ColumnNoMargin" *ngIf="modelListSurveyThirdPartyLiabilitySupportingDocument.Type !== undefined && (modelListSurveyThirdPartyLiabilitySupportingDocument.Type === _enumPhotoType.SupportingDocument)">
												<label for="spanIndentificationNumber">
													{{ numberIndex + 1}}.  {{ _enumPhotoType[modelListSurveyThirdPartyLiabilitySupportingDocument.Type] |
													convertSeparateEnumSpace }}
												</label>
												<div class="DivImageCapture">
													<img *ngIf="modelListSurveyThirdPartyLiabilitySupportingDocument.Extension !== _stringFormatDocumentMP4 && modelListSurveyThirdPartyLiabilitySupportingDocument.URL !== undefined"
														src="{{ getImage(modelListSurveyThirdPartyLiabilitySupportingDocument.URL) }}"
														alt="{{ _enumPhotoType[modelListSurveyThirdPartyLiabilitySupportingDocument.Type]  | convertSeparateEnumSpace }}"
														(dblclick)="showImageListSurveyGallery(modelListSurveyThirdPartyLiabilitySupportingDocument);">
													<label class="LabelForNote" for="labelForImageSurvey">Note</label>
													<span class="SpanForNote" id="labelForImageSurvey">{{ modelListSurveyThirdPartyLiabilitySupportingDocument.Note | convertEmptyToDash }}</span>
												</div>
											</div>
										</ng-container>
									</div>
								</fieldset>
							</ng-container> -->

							<h3
								*ngIf="modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery !== undefined">
								Claim Letter</h3>
							<fieldset>
								<div class="DivFormHorizontalContainer">
									<div class="DivForm2Column DivForm2ColumnNoMargin">
										<div
											*ngFor="let arrayTypeClaimLetter of _arrayNumberTypeClaimLetterThirdPartyLiability">
											<label class="LabelClaimLetterStatus"
												(dblclick)="showImageListSurveyThirdPartyLiabilityModel(modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery!,arrayTypeClaimLetter)">
												{{ _enumPhotoType[arrayTypeClaimLetter] | convertSeparateEnumSpace }}
												<a [ngClass]="
												{
													'NotVerified' : getClaimProposalGalleryModelClaimLetterThirdParty(modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery!,arrayTypeClaimLetter) === 'NotVerified',
													'Verified' : getClaimProposalGalleryModelClaimLetterThirdParty(modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery!,arrayTypeClaimLetter) === 'Verified'
												}">
													{{
													getClaimProposalGalleryModelClaimLetterThirdParty(modelListSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery!,arrayTypeClaimLetter)
													| convertSeparateEnumSpace }}
												</a>
											</label>
										</div>
									</div>
								</div>
							</fieldset>

							<h3>Survey Information</h3>
							<fieldset>
								<div class="DivFormHorizontalContainer">
									<div class="DivForm2Column DivForm2ColumnNoMargin">
										<label class="LabelClaimSurveyStatus"> Status
											<a [ngClass]="
										{
											'Verified' : modelListSurveyThirdPartyLiability.Status! === _enumSurveyStatus.Approved,
											'Verify' : modelListSurveyThirdPartyLiability.Status! === _enumSurveyStatus.Verify,
											'Pending' : modelListSurveyThirdPartyLiability.Status! === _enumSurveyStatus.Pending,
											'Surveyed' : modelListSurveyThirdPartyLiability.Status! === _enumSurveyStatus.Surveyed,
											'NotVerified' : modelListSurveyThirdPartyLiability.Status! === _enumSurveyStatus.Rejected,
											'Resurvey' : modelListSurveyThirdPartyLiability.Status! === _enumSurveyStatus.Resurvey
										}">
												{{ _enumSurveyStatus[modelListSurveyThirdPartyLiability.Status!] |
												convertSeparateEnumSpace }}
											</a>
										</label>

										<label for="spanSurveyNote">Note
											<i *ngIf="modelListSurveyThirdPartyLiability.NotedOn">
												{{ modelListSurveyThirdPartyLiability.NotedOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} |
												{{ modelListSurveyThirdPartyLiability.modelUserNoted?.FirstName }}
											</i>
										</label>
										<span id="spanSurveyNote" name="spanSurveyNote">{{
											modelListSurveyThirdPartyLiability.Note |
											convertEmptyToDash }}</span>

										<h3>Approval Note</h3>

										<ng-container
											*ngIf="modelListSurveyThirdPartyLiability.VerifyNote !== undefined">
											<label for="spanSurveyVerifyNote">Note Verify
												<i *ngIf="modelListSurveyThirdPartyLiability.VerifiedOn">
													{{ modelListSurveyThirdPartyLiability.VerifiedOn |
													initiateWithoutUTC | convertToDateMedium |
													convertEmptyToDash }} |
													{{ modelListSurveyThirdPartyLiability.modelUserVerified?.FirstName
													}}
												</i>
											</label>
											<span id="spanSurveyThirdPartyLiabilityVerifyNote"
												name="spanSurveyThirdPartyLiabilityVerifyNote">{{
												modelListSurveyThirdPartyLiability.VerifyNote
												| convertEmptyToDash }}</span>
										</ng-container>

										<ng-container
											*ngIf="modelListSurveyThirdPartyLiability.ResurveyNote !== undefined">
											<label for="spanSurveyResurveyNote">Note Resurvey
												<i *ngIf="modelListSurveyThirdPartyLiability.ResurveyOn">
													{{ modelListSurveyThirdPartyLiability.ResurveyOn |
													initiateWithoutUTC | convertToDateMedium |
													convertEmptyToDash }} |
													{{ modelListSurveyThirdPartyLiability.modelUserResurvey?.FirstName
													}}
												</i>
											</label>
											<span id="spanSurveyThirdPartyLiabilityResurveyNote"
												name="spanSurveyThirdPartyLiabilityResurveyNote">{{
												modelListSurveyThirdPartyLiability.ResurveyNote | convertEmptyToDash
												}}</span>
										</ng-container>

										<ng-container
											*ngIf="modelListSurveyThirdPartyLiability.ApprovedNote !== undefined">
											<label for="spanSurveyThirdPartyLiabilityApprovedNote">Approved Note
												<i *ngIf="modelListSurveyThirdPartyLiability.ApprovedOn">
													{{ modelListSurveyThirdPartyLiability.ApprovedOn |
													initiateWithoutUTC | convertToDateMedium |
													convertEmptyToDash }} |
													{{ modelListSurveyThirdPartyLiability.modelUserApproved?.FirstName
													}}
												</i>
											</label>
											<span id="spanSurveyThirdPartyLiabilityApprovedNote"
												name="spanSurveyThirdPartyLiabilityApprovedNote">{{
												modelListSurveyThirdPartyLiability.ApprovedNote | convertEmptyToDash
												}}</span>
										</ng-container>

										<ng-container
											*ngIf="modelListSurveyThirdPartyLiability.RejectedNote !== undefined">
											<label for="spanSurveyThirdPartyLiabilityRejectedNote">Rejected Note
												<i *ngIf="modelListSurveyThirdPartyLiability.RejectedOn">
													{{ modelListSurveyThirdPartyLiability.RejectedOn |
													initiateWithoutUTC | convertToDateMedium |
													convertEmptyToDash }} |
													{{ modelListSurveyThirdPartyLiability.modelUserRejected?.FirstName
													}}
												</i>
											</label>
											<span id="spanSurveyThirdPartyLiabilityRejectedNote"
												name="spanSurveyThirdPartyLiabilityRejectedNote">{{
												modelListSurveyThirdPartyLiability.RejectedNote | convertEmptyToDash
												}}</span>
										</ng-container>
										<!-- <span *ngIf="modelListSurveyThirdPartyLiability.Status === _enumSurveyStatus.Rejected" id="spanSurveyNote" name="spanSurveyNote">{{ modelListSurveyThirdPartyLiability.RejectedNote | convertEmptyToDash }}</span>
									<span *ngIf="modelListSurveyThirdPartyLiability.Status === _enumSurveyStatus.Resurvey" id="spanSurveyNote" name="spanSurveyNote">{{ modelListSurveyThirdPartyLiability.ResurveyNote | convertEmptyToDash }}</span>
									<span *ngIf="modelListSurveyThirdPartyLiability.Status === _enumSurveyStatus.Approved" id="spanSurveyNote" name="spanSurveyNote">{{ modelListSurveyThirdPartyLiability.ApprovedNote | convertEmptyToDash }}</span> -->
									</div>
								</div>
							</fieldset>
						</ng-container>
					</div>
				</div>
			</form>

			<form *ngIf="_booleanApproval && _booleanMV">
				<div class="DivForm">
					<h3>Casco</h3>
					<fieldset>
                        <div class="DivFormHorizontalContainer" *ngFor="let modelListSurvey of this._modelClaimProposal.listModelSurvey; let numberIndex = index">
                            <div class="DivForm2Column">
                                <div>
									<label for="spanSurveyServiceAmount">Service</label>
									<span id="spanSurveyServiceAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.ServiceAmount | currencyValue | convertEmptyToDash }}</span>

									<label for="spanSurveyPartAmount">Part</label>
									<span id="spanSurveyPartAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.PartAmount | currencyValue | convertEmptyToDash }}</span>

									<label for="spanSurveyPPNAPercentage">PPN (%)</label>
									<span id="spanSurveyPPNAPercentage">{{ modelListSurvey.setNumberToPercentage(modelListSurvey.TaxPercentage!) }}{{ _stringConstant.STRING_CHARACTER_SEPARATOR_PERCENTAGE }}</span>
                                    <!-- <h4>Casco</h4> -->
                                    <!-- <table class="TableOwnRetention">
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"><span>Service</span></td>
                                            <td class="TdOwnRetentionnSecond"></td>
                                            <td class="TdOwnRetentionnTotal"><span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.ServiceAmount | currencyValue | convertEmptyToDash }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"> <span>Part</span> </td>
                                            <td class="TdOwnRetentionnSecond"> <span></span> </td>
                                            <td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.PartAmount | currencyValue | convertEmptyToDash }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"> <span> PPN </span> </td>
                                            <td class="TdOwnRetentionnSecond"> <span> {{ modelListSurvey.setNumberToPercentage(modelListSurvey.TaxPercentage!) }}{{ _stringConstant.STRING_CHARACTER_SEPARATOR_PERCENTAGE }} </span> </td>
                                            <td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.TaxAmount | currencyValue | convertEmptyToDash }}</span> </td>
                                        </tr>
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"> <span>Salvage</span> </td>
                                            <td class="TdOwnRetentionnSecond"> <span></span> </td>
                                            <td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}-{{ modelListSurvey.SalvageAmount | currencyValue | convertEmptyToDash }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"><span>Total Casco</span></td>
                                            <td class="TdOwnRetentionnSecond"></td>
                                            <td class="TdOwnRetentionnTotal"><span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.CascoAmount | currencyValue | convertEmptyToDash }}</span></td>
                                        </tr>
                                    </table> -->
                                </div>

                                <div>
                                    <h4 *ngIf="this._modelClaimProposal.listModelSurveyThirdPartyLiability!.length > 0">TPL</h4>
                                    <table class="TableOwnRetention" *ngFor="let modelListSurveyThirdPartyLiability of this._modelClaimProposal.listModelSurveyThirdPartyLiability; let numberIndex = index">
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"><span>Service</span></td>
                                            <td class="TdOwnRetentionnSecond"></td>
                                            <td class="TdOwnRetentionnTotal"><span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurveyThirdPartyLiability.ServiceAmount | currencyValue | convertEmptyToDash }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"> <span>Part</span> </td>
                                            <td class="TdOwnRetentionnSecond"> <span></span> </td>
                                            <td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurveyThirdPartyLiability.PartAmount | currencyValue | convertEmptyToDash }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"> <span> PPN </span> </td>
                                            <td class="TdOwnRetentionnSecond"> <span> {{ modelListSurveyThirdPartyLiability.setNumberToPercentage(modelListSurveyThirdPartyLiability.TaxPercentage!) }}{{ _stringConstant.STRING_CHARACTER_SEPARATOR_PERCENTAGE }}</span> </td>
                                            <td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurveyThirdPartyLiability.TaxAmount | currencyValue | convertEmptyToDash }}</span> </td>
                                        </tr>
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"> <span>Salvage</span> </td>
                                            <td class="TdOwnRetentionnSecond"> <span></span> </td>
                                            <td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}-{{ modelListSurveyThirdPartyLiability.SalvageAmount | currencyValue | convertEmptyToDash }}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="TdOwnRetentionnFirst"><span>Total TPL {{ numberIndex+1 }}</span></td>
                                            <td class="TdOwnRetentionnSecond"></td>
                                            <td class="TdOwnRetentionnTotal"><span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurveyThirdPartyLiability.TPLAmount | currencyValue | convertEmptyToDash }}</span></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="DivForm2Column">
								<label for="spanSurveyPPNAmount">PPN (Nominal)</label>
								<span id="spanSurveyPPNAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.TaxAmount | currencyValue | convertEmptyToDash }}</span>

								<label for="spanSurveySalvageAmount">Salvage</label>
								<span id="spanSurveySalvageAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}-{{ modelListSurvey.SalvageAmount | currencyValue | convertEmptyToDash }}</span>

								<label for="spanSurveySalvageAmount">Total Casco</label>
								<span id="spanSurveySalvageAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.CascoAmount | currencyValue | convertEmptyToDash }}</span>

								<!-- <table class="TableOwnRetention">
									<tr>
										<td class="TdOwnRetentionnFirst"><span>Service</span></td>
										<td class="TdOwnRetentionnSecond"></td>
										<td class="TdOwnRetentionnTotal"><span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.ServiceAmount | currencyValue | convertEmptyToDash }}</span></td>
									</tr>
									<tr>
										<td class="TdOwnRetentionnFirst"> <span>Part</span> </td>
										<td class="TdOwnRetentionnSecond"> <span></span> </td>
										<td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.PartAmount | currencyValue | convertEmptyToDash }}</span>
										</td>
									</tr>
									<tr>
										<td class="TdOwnRetentionnFirst"> <span> PPN (Nominal)</span> </td>
										<td class="TdOwnRetentionnSecond"> <span></span> </td>
										<td class="TdOwnRetentionnSecond"> <span> {{ modelListSurvey.setNumberToPercentage(modelListSurvey.TaxPercentage!) }}{{ _stringConstant.STRING_CHARACTER_SEPARATOR_PERCENTAGE }} </span> </td>
										<td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.TaxAmount | currencyValue | convertEmptyToDash }}</span> </td>
									</tr>
									<tr>
										<td class="TdOwnRetentionnFirst"> <span>Salvage</span> </td>
										<td class="TdOwnRetentionnSecond"> <span></span> </td>
										<td class="TdOwnRetentionnTotal"> <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}-{{ modelListSurvey.SalvageAmount | currencyValue | convertEmptyToDash }}</span>
										</td>
									</tr>
									<tr>
										<td class="TdOwnRetentionnFirst"><span>Total Casco</span></td>
										<td class="TdOwnRetentionnSecond"></td>
										<td class="TdOwnRetentionnTotal"><span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.CascoAmount | currencyValue | convertEmptyToDash }}</span></td>
									</tr>
								</table> -->
                            </div>
                        </div>

                    </fieldset>
					<!-- <h3>Total Cost</h3>
					<fieldset>
						<div class="DivForm2Column">
							<div class="DivFormHorizontalContainer">
								<table class="TableOwnRetention">
									<tr *ngFor="let modelListSurvey of this._modelClaimProposal.listModelSurvey">
										<td><span>Total Casco</span></td>
										<td>
											<span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
												modelListSurvey.CascoAmount |
												currencyValue | convertEmptyToDash }}</span>
										</td>
									</tr>
									<tr
										*ngFor="let modelListSurveyThirdPartyLiability of this._modelClaimProposal.listModelSurveyThirdPartyLiability; let numberIndex = index">
										<td><span>Total TPL {{numberIndex+1}}</span></td>
										<td>
											<span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{
												modelListSurveyThirdPartyLiability.TPLAmount | currencyValue |
												convertEmptyToDash }}</span>
										</td>
									</tr>
									<tr>
										<td>
											<span>Total Additional Cost</span>
										</td>
										<td>
											<span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}} {{
												this._numberTowingAmount! +
												this._numberVehicleReplacementAmount! +
												this._numberPersonalAccidentDriverAmount! +
												this._numberPersonalAccidentPassengerAmount! -
												this._numberOwnRetentionAmount! | currencyValue |
												convertEmptyToDash }}</span>
										</td>
									</tr>
									<tr>
										<td>
											<h4>Total Cost</h4>
										</td>
										<td>
											<h4>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{ this.getTotalCost() |
												currencyValue |
												convertEmptyToDash }}</h4>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</fieldset> -->
				</div>
				<div class="DivForm">
					<h3>Additional Cost</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer" *ngFor="let modelListSurvey of this._modelClaimProposal.listModelSurvey; let numberIndex = index">
							<div class="DivForm2Column">
								<label for="spanSurveyVehicleReplacementAmount">Rental Costs</label>
								<span id="spanSurveyVehicleReplacementAmount" name="modelListSurveyVehicleReplacementAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{ modelListSurvey.VehicleReplacementAmount | currencyValue | convertEmptyToDash }}</span>

								<label for="spanSurveyTowingAmount">Towing Cost</label>
								<span id="spanSurveyTowingAmount" name="modelListSurveyTowingAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{ modelListSurvey.TowingAmount | currencyValue | convertEmptyToDash }}</span>

								<label for="spanSurveyPersonalAccidentDriverAmount">PA Driver Cost</label>
								<span id="spanSurveyPersonalAccidentDriverAmount" name="modelListSurveyPersonalAccidentDriverAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{ modelListSurvey.PersonalAccidentDriverAmount | currencyValue | convertEmptyToDash }}</span>

								<label for="spanSurveyPersonalAccidentPassengerAmount">PA Passenger Cost</label>
								<span id="spanSurveyPersonalAccidentPassengerAmount" name="modelListSurveyPersonalAccidentPassengerAmount">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{ modelListSurvey.PersonalAccidentPassengerAmount | currencyValue | convertEmptyToDash }}</span>
							</div>
							<div class="DivForm2Column">
								<label for="spanSurveyPersonalOwnRetention">Own Retention Branch Office</label>
								<span id="spanSurveyPersonalOwnRetention" name="modelListSurveyOwnRetention">{{ modelListSurvey.OwnRetention | currencyValue | convertEmptyToDash }} X {{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{ modelListSurvey.setOwnRetentionValue() | currencyValue | convertEmptyToDash }} = {{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}} -{{ modelListSurvey.OwnRetentionAmount | currencyValue | convertEmptyToDash }}</span>

								<label for="inputSurveyPersonalOwnRetentionHeadOffice">Own Retention Head Office</label>
								<div class="DivForm3ColumnOwnRetention">
									<input type="text" name="modelSurveyPersonalOwnRetentionHeadOffice" class="inputOwnRetentionHeadOffice" mask="separator.2" disabled thousandSeparator="." inputmode="numeric" [(ngModel)]="_modelSurvey.OwnRetentionHeadOffice">
									<label>X</label>
									<input type="text" name="modelSurveyPersonalOwnRetentionValueHeadOffice" class="inputOwnRetentionAmountHeadOffice" mask="separator.2" thousandSeparator="." inputmode="numeric" [(ngModel)]="_numberOwnRetentionValue" disabled>
									<label>=</label>
									<input type="text" name="modelSurveyPersonalOwnRetentionAmountHeadOffice" class="inputOwnRetentionAmountHeadOffice" mask="separator.2" thousandSeparator="." inputmode="numeric" [(ngModel)]="_modelSurvey.OwnRetentionAmountHeadOffice" disabled>
								</div>
								<div class="DivCheckBoxContainer">
									<div class="DivCheckBox">
										<input type="checkbox" disabled id="checkboxForOwnRetention">
										<label for="checkboxForOwnRetention"></label>
									</div>
									<label for="checkboxForOwnRetention">Same as branch office</label>
								</div>

								<label for="spanSurveyPersonalAdditionalCost">Total Additional Cost</label>
								<span id="spanSurveyPersonalAdditionalCost" name="modelListSurveyAdditionalCost">{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}} {{ this._numberTowingAmount! + this._numberVehicleReplacementAmount! + this._numberPersonalAccidentDriverAmount! + this._numberPersonalAccidentPassengerAmount! - this._numberOwnRetentionAmount! | currencyValue | convertEmptyToDash }}</span>
							</div>
						</div>
					</fieldset>
				</div>

				<div class="DivForm">
					<h3>Total Cost</h3>
					<fieldset>
                        <div class="DivForm2Column">
                            <div class="DivFormHorizontalContainer">
                                <table class="TableOwnRetention" >
                                    <tr *ngFor="let modelListSurvey of this._modelClaimProposal.listModelSurvey">
                                        <td><span>Total Casco</span></td>
                                        <td>
                                            <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurvey.CascoAmount | currencyValue | convertEmptyToDash }}</span>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let modelListSurveyThirdPartyLiability of this._modelClaimProposal.listModelSurveyThirdPartyLiability; let numberIndex = index">
                                        <td><span>Total TPL {{numberIndex+1}}</span></td>
                                        <td>
                                            <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX }}{{ modelListSurveyThirdPartyLiability.TPLAmount | currencyValue | convertEmptyToDash }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>Total Additional Cost</span>
                                        </td>
                                        <td>
                                            <span>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}} {{ this._numberTowingAmount! + this._numberVehicleReplacementAmount! + this._numberPersonalAccidentDriverAmount! + this._numberPersonalAccidentPassengerAmount! - this._numberOwnRetentionAmount! | currencyValue | convertEmptyToDash }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><h4>Total Cost</h4></td>
                                        <td><h4>{{ _stringConstant.STRING_CURRENCY_IDR_PREFIX}}{{ this.getTotalCost() | currencyValue | convertEmptyToDash }}</h4></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </fieldset>
				</div>

				<div class="DivForm">
					<h3>Approval</h3>
					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<ng-container
									*ngFor="let modelListSurvey of this._modelClaimProposal.listModelSurvey; let numberIndex = index">
									<label for="spanModelListSurveyVerifiedNote"
										*ngIf="modelListSurvey.VerifiedOn !== undefined">
										Verify Note
										<i>
											{{ modelListSurvey.VerifiedOn | initiateWithoutUTC | convertToDateMedium |
											convertEmptyToDash }} |
											{{ modelListSurvey.modelUserVerified?.FirstName }} {{ modelListSurvey.modelUserVerified?.MiddleName }} {{ modelListSurvey.modelUserVerified?.LastName }}
										</i>
									</label>
									<span *ngIf="modelListSurvey.VerifyNote" name="modelListSurveyVerifiedNote"
										id="spanModelListSurveyVerifiedNote" placeholder="input note approval">{{
										modelListSurvey.VerifyNote
										| convertEmptyToDash }}</span>

									<label for="spanModelListSurveyApprovedNote"
										*ngIf="modelListSurvey.ApprovedOn !== undefined">
										Verify Note
										<i>
											{{ modelListSurvey.ApprovedOn | initiateWithoutUTC | convertToDateMedium |
											convertEmptyToDash }} |
											{{ modelListSurvey.modelUserApproved?.FirstName }} {{ modelListSurvey.modelUserApproved?.MiddleName }} {{ modelListSurvey.modelUserApproved?.LastName }}
										</i>
									</label>
									<span *ngIf="modelListSurvey.ApprovedNote" name="modelListSurveyApprovedNote"
										id="spanModelListSurveyApprovedNote" placeholder="input note approval">{{
										modelListSurvey.ApprovedNote | convertEmptyToDash }}</span>
								</ng-container>
								<label for="spanCommentApproval"
									*ngIf="_modelClaimProposal.SignerHeadOfficeReviewedNote !== undefined">
									Note approval
									<i *ngIf="_modelClaimProposal.SignerHeadOfficeReviewedOn">
										{{ _modelClaimProposal.SignerHeadOfficeReviewedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }} |
										{{ _modelClaimProposal.modelUserSignerHeadOfficeReviewed?.FirstName }} {{ _modelClaimProposal.modelUserSignerHeadOfficeReviewed?.MiddleName }} {{ _modelClaimProposal.modelUserSignerHeadOfficeReviewed?.LastName }}
									</i>
								</label>
								<span name="modelClaimProposalNote" id="spanCommentApproval"
									placeholder="input note approval">{{
									_modelClaimProposal.SignerHeadOfficeReviewedNote | convertEmptyToDash }}</span>
							</div>
						</div>
					</fieldset>
				</div>
			</form>

			<form *ngIf="_booleanHistory && _booleanMV">
				<div class="DivForm DivFormListSurveyModel"
					*ngIf="_modelClaimProposal.Status && (_modelClaimProposal.Status >= _enumClaimProposalStatus.Approved) && !_modelClaimProposal.checkClaimProposalDirectToMantle()">
					<h3>History SPK</h3>
					<table class="TableGeneralDetail">
						<tr>
							<td class="TdGeneralDetailFirst">
								<p>{{ _modelClaimProposal.SignerHeadOfficeReviewedOn | initiateWithoutUTC |
									convertToDateMedium |
									convertEmptyToDash }}</p>
							</td>
							<td class="TdGeneralDetailSecond">
								<p>SPK Terkirim Ke Nasabah</p>
							</td>
							<td class="TdGeneralDetailTotal">
								<p>{{ _modelClaimProposal.ReporterEmail | convertEmptyToDash }}</p>
							</td>
						</tr>
						<tr>
							<td class="TdGeneralDetailFirst">
								<p>{{ _modelClaimProposal.SignerHeadOfficeReviewedOn | initiateWithoutUTC |
									convertToDateMedium |
									convertEmptyToDash }}</p>
							</td>
							<td class="TdGeneralDetailSecond">
								<p>SPK Terkirim Ke Cabang</p>
							</td>
							<td class="TdGeneralDetailTotal">
								<p>{{ _modelPolicyGeneral.Email | convertEmptyToDash }}</p>
							</td>
						</tr>
						<tr>
							<td class="TdGeneralDetailFirst">
								<p>{{ _modelClaimProposal.SignerHeadOfficeReviewedOn | initiateWithoutUTC |
									convertToDateMedium |
									convertEmptyToDash }}</p>
							</td>
							<td class="TdGeneralDetailSecond">
								<p>SPK Terkirim Ke Bengkel</p>
							</td>
							<td class="TdGeneralDetailTotal">
								<p>{{ _modelSurveyForEmail.WorkshopEmail | convertEmptyToDash }}</p>
							</td>
						</tr>
						<tr>
							<td class="TdGeneralDetailFirst">
								<p>Email Bengkel</p>
							</td>
							<td class="TdGeneralDetailSecond">
								<input type="text" name="modelSurveyWorkshopEmail" id="modelSurveyWorkshopEmail"
									placeholder="input email workshop" [(ngModel)]="_modelSurvey.WorkshopEmail">
							</td>
							<td class="TdGeneralDetailFirst">
								<input type="button" name="buttonSubmit" value="Kirim"
									(click)="callSendEmailClaimProposalByClaimCenter(this)">
							</td>
						</tr>
					</table>
				</div>
				<div id="divHistoryProgressStepper" class="DivForm">
					<h3>History Process Klaim</h3>
					<fieldset>
						<table class="TableStepHistory">

							<!-- Survey - START -->
							<ng-container *ngFor="let modelListSurvey of _modelClaimProposal.listModelSurvey">

								<tr class="TableRowProgress"
									*ngIf="(_modelClaimProposal.Status) && (_modelClaimProposal.Status >= _enumClaimProposalStatus.Approved && _modelClaimProposal.Status !== _enumClaimProposalStatus.Rejected && _modelClaimProposal.Status !== _enumClaimProposalStatus.Direct)">
									<td class="TableDataProgressDate">
										<p>{{ _modelClaimProposal.SignerHeadOfficeReviewedOn | initiateWithoutUTC |
											convertToDateMedium |
											convertEmptyToDash }} </p>
									</td>
									<td class="TableDataProgressDetail">
										<p class="ParagraphHistory">SPK Awal Terbit</p>
									</td>
								</tr>

								<tr class="TableRowProgress"
									*ngIf="(_modelClaimProposal.Status) && (_modelClaimProposal.Status >= _enumClaimProposalStatus.Approved && _modelClaimProposal.Status !== _enumClaimProposalStatus.Rejected && _modelClaimProposal.Status !== _enumClaimProposalStatus.Direct)">
									<td class="TableDataProgressDate">
										<p>{{ _modelClaimProposal.SignerHeadOfficeReviewedOn | initiateWithoutUTC |
											convertToDateMedium |
											convertEmptyToDash }} </p>
									</td>
									<td class="TableDataProgressDetail">
										<p class="ParagraphHistoryApproved">Approved survei oleh Manager {{
											_modelClaimProposal.modelUserSigner?.FirstName  }}
											{{ _modelClaimProposal.modelUserSigner?.MiddleName }}
											{{ _modelClaimProposal.modelUserSigner?.LastName }}</p>
										<p class="ParagraphNote">{{ _modelClaimProposal.SignerHeadOfficeReviewedNote |
											convertEmptyToDash }}
										</p>
									</td>
								</tr>

								<tr class="TableRowProgress" *ngIf="modelListSurvey.modelUserApproved">
									<td class="TableDataProgressDate">
										<p>{{ modelListSurvey.ApprovedOn | initiateWithoutUTC | convertToDateMedium |
											convertEmptyToDash }}
										</p>
									</td>
									<td class="TableDataProgressDetail">
										<p class="ParagraphHistoryVerification">Review survei oleh SPV
											{{ modelListSurvey.modelUserApproved.FirstName }}
											{{ modelListSurvey.modelUserApproved.MiddleName }}
											{{ modelListSurvey.modelUserApproved.LastName }}</p>
										<p class="ParagraphNote">{{ modelListSurvey.ApprovedNote | convertEmptyToDash }}
										</p>
									</td>
								</tr>

								<tr class="TableRowProgress" *ngIf="_modelClaimProposal.modelUserRejected">
									<td class="TableDataProgressDate">
										<p>{{ modelListSurvey.RejectedOn | initiateWithoutUTC | convertToDateMedium |
											convertEmptyToDash }}
										</p>
									</td>
									<td class="TableDataProgressDetail">
										<p class="ParagraphHistoryRejected">Rejected survei oleh
											{{ modelListSurvey.modelUserRejected?.FirstName }}
											{{ modelListSurvey.modelUserRejected?.MiddleName }}
											{{ modelListSurvey.modelUserRejected?.LastName }}</p>
										<p class="ParagraphNote">{{ modelListSurvey.RejectedNote | convertEmptyToDash }}
										</p>
									</td>
								</tr>

								<tr class="TableRowProgress" *ngIf="modelListSurvey.modelUserVerified">
									<td class="TableDataProgressDate">
										<p>{{ modelListSurvey.VerifiedOn | initiateWithoutUTC | convertToDateMedium |
											convertEmptyToDash }}
										</p>
									</td>
									<td class="TableDataProgressDetail">
										<p class="ParagraphHistoryVerification">Verifikasi survei oleh Staff
											{{ modelListSurvey.modelUserVerified.FirstName }}
											{{ modelListSurvey.modelUserVerified.MiddleName }}
											{{ modelListSurvey.modelUserVerified.LastName }}</p>
										<p class="ParagraphNote">{{ modelListSurvey.VerifyNote | convertEmptyToDash }}
										</p>
									</td>
								</tr>

								<tr class="TableRowProgress" *ngIf="_modelClaimProposal.modelUserSubmitSurvey">
									<td class="TableDataProgressDate">
										<p>{{ modelListSurvey.CreatedOn | initiateWithoutUTC | convertToDateMedium |
											convertEmptyToDash }}
										</p>
									</td>
									<td class="TableDataProgressDetail">
										<p class="ParagraphHistory">Submit hasil survei oleh {{
											_modelClaimProposal.SurveyNearestBranchName
											| convertEmptyToDash }} |
											{{ _modelClaimProposal.modelUserSubmitSurvey.FirstName }}
											{{ _modelClaimProposal.modelUserSubmitSurvey.MiddleName }}
											{{ _modelClaimProposal.modelUserSubmitSurvey.LastName }}</p>
										<p class="ParagraphNote">{{ modelListSurvey.Note | convertEmptyToDash }}</p>
									</td>
								</tr>

							</ng-container>

							<!-- Survey - END -->

							<!-- Verification Head Office - START -->

							<tr class="TableRowProgress" *ngIf="_modelClaimProposal.HeadOfficeReviewedOn">
								<td class="TableDataProgressDate">
									<p>{{ _modelClaimProposal.HeadOfficeReviewedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }} </p>
								</td>
								<td class="TableDataProgressDetail">
									<ng-container
										*ngIf="_modelClaimProposal.HeadOfficeReviewedStatus === _enumReviewState.Approved">
										<p class="ParagraphHistoryVerification"> Approve verifikasi oleh Manager {{
											_modelClaimProposal.modelUserHeadOfficeReviewed?.FirstName }}
											{{ _modelClaimProposal.modelUserHeadOfficeReviewed?.MiddleName }}
											{{ _modelClaimProposal.modelUserHeadOfficeReviewed?.LastName }}</p>
									</ng-container>
									<ng-container
										*ngIf="_modelClaimProposal.HeadOfficeReviewedStatus === _enumReviewState.Rejected">
										<p class="ParagraphHistoryRejected"> Rejected verifikasi oleh Manager
											{{ _modelClaimProposal.modelUserHeadOfficeReviewed?.FirstName }}
											{{ _modelClaimProposal.modelUserHeadOfficeReviewed?.MiddleName }}
											{{ _modelClaimProposal.modelUserHeadOfficeReviewed?.LastName }}</p>
									</ng-container>
									<p class="ParagraphNote">{{ _modelClaimProposal.HeadOfficeReviewedNote |
										convertEmptyToDash }}</p>
								</td>
							</tr>

							<tr class="TableRowProgress" *ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedOn">
								<td class="TableDataProgressDate">
									<p>{{ _modelClaimProposal.CheckerHeadOfficeReviewedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }} </p>
								</td>
								<td class="TableDataProgressDetail">
									<ng-container
										*ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedStatus === _enumReviewState.Verified">
										<p class="ParagraphHistoryVerification"> Verifikasi klaim oleh SPV
											{{ _modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.FirstName }}
											{{ _modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.MiddleName }}
											{{ _modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.LastName }}</p>
									</ng-container>
									<ng-container
										*ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedStatus === _enumReviewState.Rejected">
										<p class="ParagraphHistoryRejected"> Rejected klaim oleh SPV
											{{ _modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.FirstName }}
											{{ _modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.MiddleName }}
											{{ _modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.LastName }}</p>
									</ng-container>
									<p class="ParagraphNote">{{ _modelClaimProposal.CheckerHeadOfficeReviewedNote |
										convertEmptyToDash }}
									</p>
								</td>
							</tr>

							<tr class="TableRowProgress" *ngIf="_modelClaimProposal.MakerHeadOfficeReviewedOn">
								<td class="TableDataProgressDate">
									<p>{{ _modelClaimProposal.MakerHeadOfficeReviewedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }} </p>
								</td>
								<td class="TableDataProgressDetail">
									<ng-container
										*ngIf="_modelClaimProposal.MakerHeadOfficeReviewedStatus === _enumReviewState.Verified">
										<p class="ParagraphHistoryVerification"> Verifikasi klaim oleh Staff
											{{ _modelClaimProposal.modelUserMakerHeadOfficeReviewed?.FirstName }}
											{{ _modelClaimProposal.modelUserMakerHeadOfficeReviewed?.MiddleName }}
											{{ _modelClaimProposal.modelUserMakerHeadOfficeReviewed?.LastName }}</p>
									</ng-container>
									<ng-container
										*ngIf="_modelClaimProposal.MakerHeadOfficeReviewedStatus === _enumReviewState.Rejected">
										<p class="ParagraphHistoryRejected"> Rejected klaim oleh Staff
											{{ _modelClaimProposal.modelUserMakerHeadOfficeReviewed?.FirstName }}
											{{ _modelClaimProposal.modelUserMakerHeadOfficeReviewed?.MiddleName }}
											{{ _modelClaimProposal.modelUserMakerHeadOfficeReviewed?.LastName }}</p>
									</ng-container>
									<p class="ParagraphNote">{{ _modelClaimProposal.MakerHeadOfficeReviewedNote |
										convertEmptyToDash }}
									</p>
								</td>
							</tr>

							<!-- Verification Head Office - END -->

							<!-- Verification Branch - START -->

							<tr class="TableRowProgress" *ngIf="_modelClaimProposal.BranchReviewedOn">
								<td class="TableDataProgressDate">
									<p>{{ _modelClaimProposal.BranchReviewedOn | initiateWithoutUTC |
										convertToDateMedium |
										convertEmptyToDash }} </p>
								</td>
								<td class="TableDataProgressDetail">
									<p class="ParagraphHistory">Verifikasi klaim oleh Cabang {{
										_modelClaimProposal.BranchName |
										convertEmptyToDash}} |
										{{ _modelClaimProposal.modelUserBranchReviewed?.FirstName }}
										{{ _modelClaimProposal.modelUserBranchReviewed?.MiddleName }}
										{{ _modelClaimProposal.modelUserBranchReviewed?.LastName }}</p>
									<p class="ParagraphNote">{{ _modelClaimProposal.BranchReviewedNote |
										convertEmptyToDash }}</p>
								</td>
							</tr>

							<!-- Verification Branch - END -->

							<!-- Submit - START -->

							<tr class="TableRowProgress"
								*ngIf="_modelClaimProposal && _modelClaimProposal.checkClaimProposalDirectToMantle()">
								<td class="TableDataProgressDate">
									<p>{{ _modelClaimProposal.CreatedOn | initiateWithoutUTC | convertToDateMedium |
										convertEmptyToDash }}
									</p>
								</td>
								<td class="TableDataProgressDetail">
									<p class="ParagraphHistoryApproved">Submit to Mantle oleh {{
										_modelClaimProposal.getClaimProposalChannel() | convertEmptyToDash}} |
										{{ _modelClaimProposal.modelUserSubmit?.FirstName }}
										{{ _modelClaimProposal.modelUserSubmit?.MiddleName }}
										{{ _modelClaimProposal.modelUserSubmit?.LastName }}</p>
								</td>
							</tr>

							<tr class="TableRowProgress"
								*ngIf="_modelClaimProposal !== null && _modelClaimProposal !== undefined">
								<td class="TableDataProgressDate">
									<p>{{ _modelClaimProposal.CreatedOn | initiateWithoutUTC | convertToDateMedium |
										convertEmptyToDash }}
									</p>
								</td>
								<td class="TableDataProgressDetail">
									<p class="ParagraphHistory">Register klaim awal oleh {{
										_modelClaimProposal.getClaimProposalChannel()
										| convertEmptyToDash}} |
										{{ _modelClaimProposal.modelUserSubmit?.FirstName }}
										{{ _modelClaimProposal.modelUserSubmit?.MiddleName }}
										{{ _modelClaimProposal.modelUserSubmit?.LastName }}
									</p>
								</td>
							</tr>

							<!-- Submit - END -->

						</table>
					</fieldset>
				</div>
			</form>

			<form *ngIf="_booleanHistory && !_booleanMV">
				<div id="divHistoryProgressStepper" class="DivForm">
					<h3>History Process Klaim</h3>
					<fieldset>
						<section class="SectionStepHistory">

							<!-- Report Claim - START -->
							<div
								*ngIf="_modelClaimProposal.CheckerBranchCorrectionOn !== null && _modelClaimProposal.CheckerBranchCorrectionOn !== undefined || _modelClaimProposal.SignerBranchCorrectionOn !== null && _modelClaimProposal.SignerBranchCorrectionOn !== undefined || _modelClaimProposal.CreatedOn !== null && _modelClaimProposal.CreatedOn!== undefined || _modelClaimProposal.MakerBranchCorrectionOn !== null && _modelClaimProposal.MakerBranchCorrectionOn !== undefined || _modelClaimProposal.CreatedOn !== null && _modelClaimProposal.CreatedOn !== undefined">
								<span>Report Claim</span>
								<table class="TableStepHistory">

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.CreatedOn !== null && _modelClaimProposal.CreatedOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.CreatedOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<p class="ParagraphHistory"
												*ngIf="!_modelClaimProposal.checkChannelFromCustomer()"> Pelapor Klaim
												oleh {{ _modelClaimProposal.modelUserSubmit?.FirstName }} {{
												_modelClaimProposal.modelUserSubmit?.MiddleName }} {{
												_modelClaimProposal.modelUserSubmit?.LastName }}</p>
											<p class="ParagraphHistory"
												*ngIf="_modelClaimProposal.checkChannelFromCustomer()"> Pelapor Klaim
												oleh {{ _enumClaimChannel[_modelClaimProposal.Channel!] |
												convertSeparateEnumSpace }}</p>
										</td>
									</tr>

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.MakerBranchCorrectionOn !== null && _modelClaimProposal.MakerBranchCorrectionOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.MakerBranchCorrectionOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<p class="ParagraphHistory"> Confirmation Klaim oleh {{
												_modelClaimProposal.modelUserMakerBranchCorrection?.FirstName }} {{
												_modelClaimProposal.modelUserMakerBranchCorrection?.MiddleName }} {{
												_modelClaimProposal.modelUserMakerBranchCorrection?.LastName }}</p>
										</td>
									</tr>

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.CheckerBranchCorrectionOn !== null && _modelClaimProposal.CheckerBranchCorrectionOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.CheckerBranchCorrectionOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<ng-container
												*ngIf="_modelClaimProposal.CheckerBranchCorrectionStatus === _enumReviewState.Approved">
												<p class="ParagraphHistoryVerification"> Approval Checker Correction
													Klaim oleh {{
													_modelClaimProposal.modelUserCheckerBranchCorrection?.FirstName }}
													{{
													_modelClaimProposal.modelUserCheckerBranchCorrection?.MiddleName }}
													{{
													_modelClaimProposal.modelUserCheckerBranchCorrection?.LastName }}
												</p>
											</ng-container>
											<ng-container
												*ngIf="_modelClaimProposal.CheckerBranchCorrectionStatus !== _enumReviewState.Approved">
												<p class="ParagraphHistoryRejected"> Rejected Checker Correction Klaim
													oleh {{
													_modelClaimProposal.modelUserCheckerBranchCorrection?.FirstName }}
													{{
													_modelClaimProposal.modelUserCheckerBranchCorrection?.MiddleName }}
													{{
													_modelClaimProposal.modelUserCheckerBranchCorrection?.LastName }}
												</p>
											</ng-container>
											<p>{{ _modelClaimProposal.CheckerBranchCorrectionNote | convertEmptyToDash
												}} </p>
										</td>
									</tr>

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.SignerBranchCorrectionOn !== null && _modelClaimProposal.SignerBranchCorrectionOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.SignerBranchCorrectionOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<ng-container
												*ngIf="_modelClaimProposal.SignerBranchCorrectionStatus === _enumReviewState.Approved">
												<p class="ParagraphHistoryVerification"> Approval Signer Correction
													Klaim oleh {{
													_modelClaimProposal.modelUserSignerBranchCorrection?.FirstName }} {{
													_modelClaimProposal.modelUserSignerBranchCorrection?.MiddleName }}
													{{
													_modelClaimProposal.modelUserSignerBranchCorrection?.LastName }}</p>
											</ng-container>
											<ng-container
												*ngIf="_modelClaimProposal.SignerBranchCorrectionStatus !== _enumReviewState.Approved">
												<p class="ParagraphHistoryRejected"> Rejected Signer Correction Klaim
													oleh {{
													_modelClaimProposal.modelUserSignerBranchCorrection?.FirstName }} {{
													_modelClaimProposal.modelUserSignerBranchCorrection?.MiddleName }}
													{{
													_modelClaimProposal.modelUserSignerBranchCorrection?.LastName }}</p>
											</ng-container>
											<p>{{ _modelClaimProposal.SignerBranchCorrectionNote | convertEmptyToDash }}
											</p>
										</td>
									</tr>

								</table>
							</div>
							<!-- Report Claim - END -->

							<!-- Notification Loss Advice - START -->
							<div
								*ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedOn !== null && _modelClaimProposal.CheckerHeadOfficeReviewedOn !== undefined || _modelClaimProposal.SignerHeadOfficeReviewedOn !== null && _modelClaimProposal.SignerHeadOfficeReviewedOn!== undefined || _modelClaimProposal.MakerHeadOfficeReviewedOn !== null && _modelClaimProposal.MakerHeadOfficeReviewedOn !== undefined">
								<span>Notification Loss Advice</span>
								<table class="TableStepHistory">

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.MakerHeadOfficeReviewedOn !== null && _modelClaimProposal.MakerHeadOfficeReviewedOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.MakerHeadOfficeReviewedOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<p class="ParagraphHistory"> Pembuat NLA oleh {{
												_modelClaimProposal.modelUserMakerHeadOfficeReviewed?.FirstName }} {{
												_modelClaimProposal.modelUserMakerHeadOfficeReviewed?.MiddleName }} {{
												_modelClaimProposal.modelUserMakerHeadOfficeReviewed?.LastName }}</p>
										</td>
									</tr>

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedOn !== null && _modelClaimProposal.CheckerHeadOfficeReviewedOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.CheckerHeadOfficeReviewedOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<ng-container
												*ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedStatus === _enumReviewState.Approved">
												<p class="ParagraphHistoryVerification"> Approval Checker NLA oleh {{
													_modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.FirstName }}
													{{
													_modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.MiddleName
													}} {{
													_modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.LastName }}
												</p>
											</ng-container>
											<ng-container
												*ngIf="_modelClaimProposal.CheckerHeadOfficeReviewedStatus !== _enumReviewState.Approved">
												<p class="ParagraphHistoryRejected"> Rejected Checker NLA oleh {{
													_modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.FirstName }}
													{{
													_modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.MiddleName
													}} {{
													_modelClaimProposal.modelUserCheckerHeadOfficeReviewed?.LastName }}
												</p>
											</ng-container>
											<p class="ParagraphNote">{{
												_modelClaimProposal.CheckerHeadOfficeReviewedNote | convertEmptyToDash
												}}</p>
										</td>
									</tr>

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.SignerHeadOfficeReviewedOn !== null && _modelClaimProposal.SignerHeadOfficeReviewedOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.SignerHeadOfficeReviewedOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<ng-container
												*ngIf="_modelClaimProposal.SignerHeadOfficeReviewedStatus === _enumReviewState.Approved">
												<p class="ParagraphHistoryVerification"> Approval Signer NLA oleh {{
													_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.FirstName }}
													{{
													_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.MiddleName }}
													{{
													_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.LastName }}
												</p>
											</ng-container>
											<ng-container
												*ngIf="_modelClaimProposal.SignerHeadOfficeReviewedStatus !== _enumReviewState.Approved">
												<p class="ParagraphHistoryRejected"> Rejected Signer NLA oleh {{
													_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.FirstName }}
													{{
													_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.MiddleName }}
													{{
													_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.LastName }}
												</p>
											</ng-container>
											<p class="ParagraphNote">{{ _modelClaimProposal.SignerHeadOfficeReviewedNote
												| convertEmptyToDash
												}}</p>
										</td>
									</tr>
								</table>
							</div>
							<!-- Notification Loss Advice - END -->

							<!-- Adjuster - START -->
							<div
								*ngIf="_modelClaimProposal.CheckerBranchAdjusterOn !== null && _modelClaimProposal.CheckerBranchAdjusterOn !== undefined || _modelClaimProposal.SignerBranchAdjusterOn !== null && _modelClaimProposal.SignerBranchAdjusterOn !== undefined || _modelClaimProposal.MakerHeadOfficeAdjusterOn !== null && _modelClaimProposal.MakerHeadOfficeAdjusterOn !== undefined || _modelClaimProposal.MakerBranchAdjusterOn !== null && _modelClaimProposal.MakerBranchAdjusterOn !== undefined">
								<span>Adjuster</span>
								<table class="TableStepHistory">
									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.MakerHeadOfficeAdjusterOn !== null && _modelClaimProposal.MakerHeadOfficeAdjusterOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.MakerHeadOfficeAdjusterOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<p class="ParagraphHistory"> Pembuat Adjuster Kantor Pusat oleh {{
												_modelClaimProposal.modelUserMakerHeadOfficeAdjuster?.FirstName }} {{
												_modelClaimProposal.modelUserMakerHeadOfficeAdjuster?.MiddleName }} {{
												_modelClaimProposal.modelUserMakerHeadOfficeAdjuster?.LastName }}</p>
										</td>
									</tr>

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.MakerBranchAdjusterOn !== null && _modelClaimProposal.MakerBranchAdjusterOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.MakerBranchAdjusterOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<p class="ParagraphHistory"> Pembuat Adjuster Cabang Penerbit oleh {{
												_modelClaimProposal.modelUserMakerBranchAdjuster?.FirstName }} {{
												_modelClaimProposal.modelUserMakerBranchAdjuster?.MiddleName }} {{
												_modelClaimProposal.modelUserMakerBranchAdjuster?.LastName }}</p>
										</td>
									</tr>

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.CheckerBranchAdjusterOn !== null && _modelClaimProposal.CheckerBranchAdjusterOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.CheckerBranchAdjusterOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<p class="ParagraphHistoryVerification"> Verifikasi Checker Adjuster oleh {{
												_modelClaimProposal.modelUserCheckerBranchAdjuster?.FirstName }} {{
												_modelClaimProposal.modelUserCheckerBranchAdjuster?.MiddleName }} {{
												_modelClaimProposal.modelUserCheckerBranchAdjuster?.LastName }}</p>
											<p>{{ _modelClaimProposal.CheckerBranchAdjusterNote | convertEmptyToDash }}
											</p>
										</td>
									</tr>

									<tr class="TableRowProgress"
										*ngIf="_modelClaimProposal.SignerBranchAdjusterOn !== null && _modelClaimProposal.SignerBranchAdjusterOn !== undefined">
										<td class="TableDataProgressDate">
											<p>{{ _modelClaimProposal.SignerBranchAdjusterOn | initiateWithoutUTC |
												convertToDateMedium |
												convertEmptyToDash }} </p>
										</td>
										<td class="TableDataProgressDetail">
											<ng-container
												*ngIf="_modelClaimProposal.SignerBranchAdjusterStatus === _enumReviewState.Approved">
												<p class="ParagraphHistoryVerification"> Approval Signer Adjuster oleh
													{{
													_modelClaimProposal.modelUserBranchAdjusterBy?.FirstName }} {{
													_modelClaimProposal.modelUserBranchAdjusterBy?.MiddleName }} {{
													_modelClaimProposal.modelUserBranchAdjusterBy?.LastName }}</p>
											</ng-container>
											<ng-container
												*ngIf="_modelClaimProposal.SignerBranchAdjusterStatus !== _enumReviewState.Approved">
												<p class="ParagraphHistoryRejected"> Rejected Signer Adjuster oleh {{
													_modelClaimProposal.modelUserBranchAdjusterBy?.FirstName }} {{
													_modelClaimProposal.modelUserBranchAdjusterBy?.MiddleName }} {{
													_modelClaimProposal.modelUserBranchAdjusterBy?.LastName }}</p>
											</ng-container>
											<p class="ParagraphNote">{{ _modelClaimProposal.SignerBranchAdjusterNote |
												convertEmptyToDash }}
											</p>
										</td>
									</tr>
								</table>
							</div>
							<!-- Adjuster - END -->

						</section>
					</fieldset>
				</div>
			</form>

			<!-- .SATISFACTION SURVEY START -->
			<form *ngIf="_booleanSatisfactionSurvey && _booleanMV">
				<div class="DivFormContainerForSatisfactionSurvey">
					<div class="DivForm">
						<fieldset>
							<h3>Survei Kepuasan Pelanggan</h3>

							<!-- .SATISFACTION QUESTION FOR BRINS START -->
							<h2>Penilaian Pelanggan Terhadap Pelayanan Claim Center BRINS</h2>
							<div class="DivContainerSurvey">
								<div class="DivWrapperSurvey"
									*ngFor="let modelClaimProposalSatisfactionForBRINS of _arrayModelClaimProposalSatisfactionQuestionForBRINS">
									<label
										*ngIf="_booleanForInsertSatisfactionSurvey === false">{{modelClaimProposalSatisfactionForBRINS?.QuestionBahasa}}</label>
									<label
										*ngIf="_booleanForInsertSatisfactionSurvey === true">{{modelClaimProposalSatisfactionForBRINS.modelClaimProposalSatisfactionQuestion.QuestionBahasa}}</label>

									<ng-container *ngIf="_booleanForInsertSatisfactionSurvey === false">
										<div class="DivContainerHorizontalRadioButton"
											*ngIf="modelClaimProposalSatisfactionForBRINS.QuestionBahasa === 'Kemudahan Pelaporan'">
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionOne1"
														name="booleanClaimCenterQuestionOne"
														[(ngModel)]="_numberBRINSQuestionOne" [value]="1"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionOne1"></label>
												</div>
												<label for="radioButtonBRINSQuestionOne1">Tidak Baik</label>
											</div>

											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionOne2"
														name="booleanClaimCenterQuestionOne"
														[(ngModel)]="_numberBRINSQuestionOne" [value]="2"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionOne2"></label>
												</div>
												<label for="radioButtonBRINSQuestionOne2">Kurang Baik</label>
											</div>

											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionOne3"
														name="booleanClaimCenterQuestionOne"
														[(ngModel)]="_numberBRINSQuestionOne" [value]="3"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionOne3"></label>
												</div>
												<label for="radioButtonBRINSQuestionOne3">Cukup  Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionOne4"
														name="booleanClaimCenterQuestionOne"
														[(ngModel)]="_numberBRINSQuestionOne" [value]="4"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionOne4"></label>
												</div>
												<label for="radioButtonBRINSQuestionOne4">Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionOne5"
														name="booleanClaimCenterQuestionOne"
														[(ngModel)]="_numberBRINSQuestionOne" [value]="5"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionOne5"></label>
												</div>
												<label for="radioButtonBRINSQuestionOne5">Sangat Baik</label>
											</div>
										</div>
										<div class="DivContainerHorizontalRadioButton"
											*ngIf="modelClaimProposalSatisfactionForBRINS.QuestionBahasa === 'Kecepatan Respon'">
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionTwo1"
														name="booleanClaimCenterQuestionTwo"
														[(ngModel)]="_numberBRINSQuestionTwo" [value]="1"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionTwo1"></label>
												</div>
												<label for="radioButtonBRINSQuestionTwo1">Tidak Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionTwo2"
														name="booleanClaimCenterQuestionTwo"
														[(ngModel)]="_numberBRINSQuestionTwo" [value]="2"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionTwo2"></label>
												</div>
												<label for="radioButtonBRINSQuestionTwo2">Kurang Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionTwo3"
														name="booleanClaimCenterQuestionTwo"
														[(ngModel)]="_numberBRINSQuestionTwo" [value]="3"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionTwo3"></label>
												</div>
												<label for="radioButtonBRINSQuestionTwo3">Cukup  Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionTwo4"
														name="booleanClaimCenterQuestionTwo"
														[(ngModel)]="_numberBRINSQuestionTwo" [value]="4"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionTwo4"></label>
												</div>
												<label for="radioButtonBRINSQuestionTwo4">Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionTwo5"
														name="booleanClaimCenterQuestionTwo"
														[(ngModel)]="_numberBRINSQuestionTwo" [value]="5"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionTwo5"></label>
												</div>
												<label for="radioButtonBRINSQuestionTwo5">Sangat Baik</label>
											</div>
										</div>
										<div class="DivContainerHorizontalRadioButton"
											*ngIf="modelClaimProposalSatisfactionForBRINS.QuestionBahasa === 'Layanan Klaim'">
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionThree1"
														name="booleanClaimCenterQuestionThree"
														[(ngModel)]="_numberBRINSQuestionThree" [value]="1"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionThree1"></label>
												</div>
												<label for="radioButtonBRINSQuestionThree1">Tidak Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionThree2"
														name="booleanClaimCenterQuestionThree"
														[(ngModel)]="_numberBRINSQuestionThree" [value]="2"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionThree2"></label>
												</div>
												<label for="radioButtonBRINSQuestionThree2">Kurang Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionThree3"
														name="booleanClaimCenterQuestionThree"
														[(ngModel)]="_numberBRINSQuestionThree" [value]="3"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionThree3"></label>
												</div>
												<label for="radioButtonBRINSQuestionThree3">Cukup  Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionThree4"
														name="booleanClaimCenterQuestionThree"
														[(ngModel)]="_numberBRINSQuestionThree" [value]="4"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionThree4"></label>
												</div>
												<label for="radioButtonBRINSQuestionThree4">Baik</label>
											</div>
											<div class="DivRadioButtonContainer">
												<div class="DivRadioButton">
													<input type="radio" id="radioButtonBRINSQuestionThree5"
														name="booleanClaimCenterQuestionThree"
														[(ngModel)]="_numberBRINSQuestionThree" [value]="5"
														(change)="getRating(modelClaimProposalSatisfactionForBRINS)">
													<label for="radioButtonBRINSQuestionThree5"></label>
												</div>
												<label for="radioButtonBRINSQuestionThree5">Sangat Baik</label>
											</div>
										</div>
									</ng-container>

									<div class="DivContainerRatingDetail"
										*ngIf="_booleanForInsertSatisfactionSurvey === true">
										<label *ngIf="modelClaimProposalSatisfactionForBRINS.Rating === 1">Kurang Baik</label>
										<label *ngIf="modelClaimProposalSatisfactionForBRINS.Rating === 2">Kurang Baik</label>
										<label *ngIf="modelClaimProposalSatisfactionForBRINS.Rating === 3">Cukup  Baik</label>
										<label *ngIf="modelClaimProposalSatisfactionForBRINS.Rating === 4">Baik</label>
										<label *ngIf="modelClaimProposalSatisfactionForBRINS.Rating === 5">Sangat
											Baik</label>
									</div>
								</div>
							</div>
							<fieldset>
								<textarea class="TextareaDisabledGrey" name="workshopServiceNotesForBrins" placeholder="Kritik dan Saran" [disabled]="_booleanForInsertSatisfactionSurvey === true"
									[(ngModel)]="_modelClaimProposalSatisfactionSurveyBRINSFeedback.Feedback">
								</textarea>
							</fieldset>
							<!-- .SATISFACTION QUESTION FOR BRINS END -->


							<!-- .SATISFACTION QUESTION FOR WORKSHOP START -->
							<ng-container
							*ngIf="_booleanTLO">
								<h2>Penilaian Pelanggan Terhadap Pelayanan Bengkel</h2>
								<div class="DivContainerSurvey">
									<div class="DivWrapperSurvey"
										*ngFor="let modelClaimProposalSatisfactionForWorkshop of _arrayModelClaimProposalSatisfactionQuestionForWorkshop">
										<label
											*ngIf="_booleanForInsertSatisfactionSurvey === false">{{modelClaimProposalSatisfactionForWorkshop.QuestionBahasa}}</label>
										<label
											*ngIf="_booleanForInsertSatisfactionSurvey === true">{{modelClaimProposalSatisfactionForWorkshop.modelClaimProposalSatisfactionQuestion.QuestionBahasa}}</label>

										<ng-container *ngIf="_booleanForInsertSatisfactionSurvey === false">

											<div class="DivContainerHorizontalRadioButton"
												*ngIf="modelClaimProposalSatisfactionForWorkshop.QuestionBahasa === 'Kualitas Perbaikan'">
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionOne1"
															name="booleanWorkshopQuestionOne"
															[(ngModel)]="_numberWorkshopQuestionOne" [value]="1"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionOne1"></label>
													</div>
													<label for="radioButtonWorkshopQuestionOne1">Tidak Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionOne2"
															name="booleanWorkshopQuestionOne"
															[(ngModel)]="_numberWorkshopQuestionOne" [value]="2"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionOne2"></label>
													</div>
													<label for="radioButtonWorkshopQuestionOne2">Kurang Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionOne3"
															name="booleanWorkshopQuestionOne"
															[(ngModel)]="_numberWorkshopQuestionOne" [value]="3"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionOne3"></label>
													</div>
													<label for="radioButtonWorkshopQuestionOne3">Cukup  Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionOne4"
															name="booleanWorkshopQuestionOne"
															[(ngModel)]="_numberWorkshopQuestionOne" [value]="4"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionOne4"></label>
													</div>
													<label for="radioButtonWorkshopQuestionOne4">Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionOne5"
															name="booleanWorkshopQuestionOne"
															[(ngModel)]="_numberWorkshopQuestionOne" [value]="5"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionOne5"></label>
													</div>
													<label for="radioButtonWorkshopQuestionOne5">Sangat Baik</label>
												</div>
											</div>
											<div class="DivContainerHorizontalRadioButton"
												*ngIf="modelClaimProposalSatisfactionForWorkshop.QuestionBahasa === 'Kecepatan Perbaikan'">
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionTwo1"
															name="booleanWorkshopQuestionTwo"
															[(ngModel)]="_numberWorkshopQuestionTwo" [value]="1"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionTwo1"></label>
													</div>
													<label for="radioButtonWorkshopQuestionTwo1">Tidak Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionTwo2"
															name="booleanWorkshopQuestionTwo"
															[(ngModel)]="_numberWorkshopQuestionTwo" [value]="2"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionTwo2"></label>
													</div>
													<label for="radioButtonWorkshopQuestionTwo2">Kurang Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionTwo3"
															name="booleanWorkshopQuestionTwo"
															[(ngModel)]="_numberWorkshopQuestionTwo" [value]="3"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionTwo3"></label>
													</div>
													<label for="radioButtonWorkshopQuestionTwo3">Cukup  Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionTwo4"
															name="booleanWorkshopQuestionTwo"
															[(ngModel)]="_numberWorkshopQuestionTwo" [value]="4"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionTwo4"></label>
													</div>
													<label for="radioButtonWorkshopQuestionTwo4">Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionTwo5"
															name="booleanWorkshopQuestionTwo"
															[(ngModel)]="_numberWorkshopQuestionTwo" [value]="5"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionTwo5"></label>
													</div>
													<label for="radioButtonWorkshopQuestionTwo5">Sangat Baik</label>
												</div>
											</div>
											<div class="DivContainerHorizontalRadioButton"
												*ngIf="modelClaimProposalSatisfactionForWorkshop.QuestionBahasa === 'Kualitas Layanan'">
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionThree1"
															name="booleanWorkshopQuestionThree"
															[(ngModel)]="_numberWorkshopQuestionThree" [value]="1"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionThree1"></label>
													</div>
													<label for="radioButtonWorkshopQuestionThree1">Tidak Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionThree2"
															name="booleanWorkshopQuestionThree"
															[(ngModel)]="_numberWorkshopQuestionThree" [value]="2"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionThree2"></label>
													</div>
													<label for="radioButtonWorkshopQuestionThree2">Kurang Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionThree3"
															name="booleanWorkshopQuestionThree"
															[(ngModel)]="_numberWorkshopQuestionThree" [value]="3"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionThree3"></label>
													</div>
													<label for="radioButtonWorkshopQuestionThree3">Cukup  Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionThree4"
															name="booleanWorkshopQuestionThree"
															[(ngModel)]="_numberWorkshopQuestionThree" [value]="4"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionThree4"></label>
													</div>
													<label for="radioButtonWorkshopQuestionThree4">Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionThree5"
															name="booleanWorkshopQuestionThree"
															[(ngModel)]="_numberWorkshopQuestionThree" [value]="5"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionThree5"></label>
													</div>
													<label for="radioButtonWorkshopQuestionThree5">Sangat Baik</label>
												</div>
											</div>
											<div class="DivContainerHorizontalRadioButton"
												*ngIf="modelClaimProposalSatisfactionForWorkshop.QuestionBahasa === 'Fasilitas Bengkel'">
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionFour1"
															name="booleanWorkshopQuestionFour"
															[(ngModel)]="_numberWorkshopQuestionFour" [value]="1"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionFour1"></label>
													</div>
													<label for="radioButtonWorkshopQuestionFour1">Tidak Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionFour2"
															name="booleanWorkshopQuestionFour"
															[(ngModel)]="_numberWorkshopQuestionFour" [value]="2"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionFour2"></label>
													</div>
													<label for="radioButtonWorkshopQuestionFour2">Kurang Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionFour3"
															name="booleanWorkshopQuestionFour"
															[(ngModel)]="_numberWorkshopQuestionFour" [value]="3"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionFour3"></label>
													</div>
													<label for="radioButtonWorkshopQuestionFour3">Cukup  Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionFour4"
															name="booleanWorkshopQuestionFour"
															[(ngModel)]="_numberWorkshopQuestionFour" [value]="4"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionFour4"></label>
													</div>
													<label for="radioButtonWorkshopQuestionFour4">Baik</label>
												</div>
												<div class="DivRadioButtonContainer">
													<div class="DivRadioButton">
														<input type="radio" id="radioButtonWorkshopQuestionFour5"
															name="booleanWorkshopQuestionFour"
															[(ngModel)]="_numberWorkshopQuestionFour" [value]="5"
															(change)="getRating(modelClaimProposalSatisfactionForWorkshop)">
														<label for="radioButtonWorkshopQuestionFour5"></label>
													</div>
													<label for="radioButtonWorkshopQuestionFour5">Sangat Baik</label>
												</div>
											</div>
										</ng-container>
										<div class="DivContainerRatingDetail"
											*ngIf="_booleanForInsertSatisfactionSurvey === true">
											<label *ngIf="modelClaimProposalSatisfactionForWorkshop.Rating === 1">Sangat
												Kurang Baik</label>
											<label
												*ngIf="modelClaimProposalSatisfactionForWorkshop.Rating === 2">Kurang Baik</label>
											<label
												*ngIf="modelClaimProposalSatisfactionForWorkshop.Rating === 3">Cukup  Baik</label>
											<label
												*ngIf="modelClaimProposalSatisfactionForWorkshop.Rating === 4">Baik</label>
											<label *ngIf="modelClaimProposalSatisfactionForWorkshop.Rating === 5">Sangat
												Baik</label>
										</div>
									</div>
								</div>
								<fieldset>
									<textarea class="TextareaDisabledGrey" name="workshopServiceNotesForWorkshop" placeholder="Kritik dan Saran" [disabled]="_booleanForInsertSatisfactionSurvey === true"
										[(ngModel)]="_modelClaimProposalSatisfactionSurveyWorkshopFeedback.Feedback"></textarea>
								</fieldset>
							</ng-container>
							<h2 *ngIf="_booleanForInsertSatisfactionSurvey === false">{{ _modelClaimProposalSatisfactionQuestionForRecomendationFeedback.QuestionBahasa }}</h2>
							<h2 *ngIf="_booleanForInsertSatisfactionSurvey === true">{{ _modelClaimProposalSatisfactionSurveyRecomendationFeedback.modelClaimProposalSatisfactionQuestion?.QuestionBahasa }}</h2>
							<label class="LabelSatisfactionSurvey" *ngIf="_booleanForInsertSatisfactionSurvey === true &&  _modelClaimProposalSatisfactionSurveyRecomendationFeedback.Feedback === 'YES'">Ya</label>
							<label class="LabelSatisfactionSurvey" *ngIf="_booleanForInsertSatisfactionSurvey === true &&  _modelClaimProposalSatisfactionSurveyRecomendationFeedback.Feedback === 'NO'">Tidak</label>
							<div class="DivContainerHorizontalRadioButton">
								<div class="DivRadioButtonContainer" *ngIf="_booleanForInsertSatisfactionSurvey === false">
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonForRecomendation"
											name="booleanRecomendationTrue" [value]="true" [(ngModel)]="_booleanRecomendation" (change)="getFeedbackRecomendation(_modelClaimProposalSatisfactionQuestionForRecomendationFeedback, _booleanRecomendation)">
										<label for="radioButtonForRecomendation"></label>
									</div>
									<label class="LabelSatisfactionSurveyQuestion" for="radioButtonForRecomendation">Ya</label>
									<div class="DivRadioButton">
										<input type="radio" id="radioButtonForNonRecomendation"
											name="booleanRecomendationFalse" [value]="false" [(ngModel)]="_booleanRecomendation" (change)="getFeedbackRecomendation(_modelClaimProposalSatisfactionQuestionForRecomendationFeedback, _booleanRecomendation)">
										<label for="radioButtonForNonRecomendation"></label>
									</div>
									<label class="LabelSatisfactionSurveyQuestion" for="radioButtonForNonRecomendation">Tidak</label>
								</div>
							</div>

							<ng-container *ngIf="_booleanForInsertSatisfactionSurvey === false && _booleanRecomendationItem === true">
								<div class="DivContainerVertikalCheckbox" *ngFor="let modelClaimProposalSatisfactionQuestionForRecomendationItem of _arrayModelClaimProposalSatisfactionQuestionForRecomendationItem">
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa === 'Kemudahan Lapor'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForEasyReporting" (change)="getFeedbackRecomendationItem(modelClaimProposalSatisfactionQuestionForRecomendationItem, $event.target)">
											<label for="checkboxForEasyReporting"></label>
										</div>
										<label for="checkboxForEasyReporting">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa === 'Kecepatan Respon Petugas'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForFastResponse" (change)="getFeedbackRecomendationItem(modelClaimProposalSatisfactionQuestionForRecomendationItem, $event.target)">
											<label for="checkboxForFastResponse"></label>
										</div>
										<label for="checkboxForFastResponse">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa === 'Layanan'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForService" (change)="getFeedbackRecomendationItem(modelClaimProposalSatisfactionQuestionForRecomendationItem, $event.target)">
											<label for="checkboxForService"></label>
										</div>
										<label for="checkboxForService">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa === 'Kecepatan Perbaikan'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForRepairSpeed" (change)="getFeedbackRecomendationItem(modelClaimProposalSatisfactionQuestionForRecomendationItem, $event.target)">
											<label for="checkboxForRepairSpeed"></label>
										</div>
										<label for="checkboxForRepairSpeed">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa === 'Kualitas Perbaikan'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForRepairQuality" (change)="getFeedbackRecomendationItem(modelClaimProposalSatisfactionQuestionForRecomendationItem, $event.target)">
											<label for="checkboxForRepairQuality"></label>
										</div>
										<label for="checkboxForRepairQuality">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa === 'Lainnya'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForMore" (change)="getFeedbackRecomendationItem(modelClaimProposalSatisfactionQuestionForRecomendationItem, $event.target)">
											<label for="checkboxForMore"></label>
										</div>
										<label for="checkboxForMore">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.QuestionBahasa }}
										</label>
									</div>
								</div>
								<textarea *ngIf="_booleanTextareaForOther === true" [(ngModel)]="this._modelClaimProposalSatisfactionSurveyForRecomendationItemOther.Feedback" class="TextareaDisabledGrey" name="RecomendationItemMoreNote" placeholder="Jelaskan"  (input)="setBooleanForNotes()"></textarea>
							</ng-container>

							<ng-container *ngIf="_booleanForInsertSatisfactionSurvey === true">
								<div class="DivContainerVertikalCheckbox" *ngFor="let modelClaimProposalSatisfactionQuestionForRecomendationItem of _arrayModelClaimProposalSatisfactionSurveyRecomendationItem">
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa === 'Kemudahan Lapor'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForEasyReporting" [checked]="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'" disabled="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'">
											<label for="checkboxForEasyReporting"></label>
										</div>
										<label for="checkboxForEasyReporting">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa === 'Kecepatan Respon Petugas'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForFastResponse" [checked]="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'" disabled="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'">
											<label for="checkboxForFastResponse"></label>
										</div>
										<label for="checkboxForFastResponse">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa === 'Layanan'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForService" [checked]="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'" disabled="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'">
											<label for="checkboxForService"></label>
										</div>
										<label for="checkboxForService">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa === 'Kecepatan Perbaikan'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForRepairSpeed" [checked]="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'" disabled="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'">
											<label for="checkboxForRepairSpeed"></label>
										</div>
										<label for="checkboxForRepairSpeed">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa }}
										</label>
									</div>
									<div class="DivCheckBoxContainer" *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa === 'Kualitas Perbaikan'">
										<div class="DivCheckBox">
											<input type="checkbox" id="checkboxForRepairQuality" [checked]="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'" disabled="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback === 'YES'">
											<label for="checkboxForRepairQuality"></label>
										</div>
										<label for="checkboxForRepairQuality">
											{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa }}
										</label>
									</div>
									<ng-container *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa === 'Lainnya'">
										<div class="DivCheckBoxContainer">
											<div class="DivCheckBox">
												<input type="checkbox" id="checkboxForMore" [checked]="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback !== undefined" disabled="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback !== undefined">
												<label for="checkboxForMore"></label>
											</div>
											<label for="checkboxForMore">
												{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.modelClaimProposalSatisfactionQuestion?.QuestionBahasa }}
											</label>
										</div>
										<textarea *ngIf="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback !== undefined" disabled="modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback !== undefined" class="TextareaDisabledGrey" name="RecomendationItemMoreNote">{{ modelClaimProposalSatisfactionQuestionForRecomendationItem.Feedback }}</textarea>
									</ng-container>
								</div>
							</ng-container>

							<!-- .SATISFACTION QUESTION FOR WORKSHOP END -->
						</fieldset>
					</div>
				</div>
				<div class="DivButtonTracking">
					<input *ngIf="_booleanForInsertSatisfactionSurvey === false" type="button" value="Submit" [disabled]="_booleanForInsertSatisfactionSurvey || _booleanReadOnly"
						(click)="callInsertClaimProposalSatisfaction(this)">
					<input type="button" value="Back" (click)="booleanTrackingAfterSatisfaction(true)">
				</div>
			</form>
			<!-- .SATISFACTION SURVEY END -->


			<!-- .CLAIM TRACKING START -->
			<form *ngIf="_booleanTracking && _booleanMV">
				<div id="divTrackingProgressStepper" class="DivForm">
					<h3>Claim Tracking Process</h3>
					<div class="DivTrackingStepperContainer">
						<ul>
							<li class="LiStepperHorizontal">
								<div class="DivContainerContentStepper">
									<span>Tahap Pelaporan</span>
									<div class="DivTitleStepper">
										<ul>
											<li class="LiStepperHorizontalForChild"
												*ngIf="_modelClaimProposal !== null && _modelClaimProposal !== undefined">
												<div class="DivContainerSteperContent">
													<div class="DivTitleStepperContent">
														<label *ngIf="_modelClaimProposal.modelUserSubmit">{{_modelClaimProposal.CreatedOn | initiateWithoutUTC |
															convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Register klaim
															awal oleh {{ _modelClaimProposal.getClaimProposalChannel()
															| convertEmptyToDash}} | {{
															_modelClaimProposal.modelUserSubmit.FirstName |
															convertEmptyToDash }} {{
															_modelClaimProposal.modelUserSubmit.MiddleName }} {{
															_modelClaimProposal.modelUserSubmit.LastName }}
														</label>
														<label *ngIf="!_modelClaimProposal.modelUserSubmit">{{_modelClaimProposal.CreatedOn | initiateWithoutUTC |
															convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Register klaim
															awal oleh {{ _modelClaimProposal.getClaimProposalChannel()
															| convertEmptyToDash}} | {{
															_modelClaimProposal.ReporterFirstName |
															convertEmptyToDash }} {{
															_modelClaimProposal.ReporterMiddleName }} {{
															_modelClaimProposal.ReporterLastName }}
														</label>
													</div>
												</div>
											</li>
											<ng-container *ngIf="_modelClaimProposal.listModelSurveyUserHistory">
												<li class="LiStepperHorizontalForChild" *ngFor="let modelUserHistoryResurveyed of _modelClaimProposal.listModelSurveyUserHistory">
													<div class="DivContainerSteperContent">
														<div class="DivTitleStepperContent">
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.VerifiedBranch">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Verifikasi
																oleh {{ modelUserHistoryResurveyed.BranchName
																	| convertEmptyToDash }} | {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.VerifiedStaff">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Verifikasi
																oleh Staff {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.ReviewSPV">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Review
																oleh SPV {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.ApproveManager">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Approve
																oleh Manager {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.RejectManager">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Reject
																oleh Manager {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.Surveyed">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Submit hasil
																survei oleh {{ modelUserHistoryResurveyed.BranchName
																	| convertEmptyToDash }} | {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.Resurveyed">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Submit hasil
																resurvei oleh {{ modelUserHistoryResurveyed.BranchName
																	| convertEmptyToDash }} | {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.Verified">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Verifikasi
																survei oleh Staff {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.Resurvey">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Resurvey
																oleh {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.ApprovedChecker">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Review
																survei oleh SPV {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.Rejected">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Rejected survei
																oleh {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
															<label *ngIf="modelUserHistoryResurveyed.Status === _enumHistorySurvey.ApprovedSigner">{{ modelUserHistoryResurveyed.ActionOn| initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | Approved survei oleh Manager {{
																modelUserHistoryResurveyed.FirstName |
																convertEmptyToDash }} {{
																modelUserHistoryResurveyed.MiddleName }} {{
																modelUserHistoryResurveyed.LastName }}
															</label>
														</div>
													</div>
												</li>
											</ng-container>

											<ng-container *ngIf="!_modelClaimProposal.listModelSurveyUserHistory">
												<li class="LiStepperHorizontalForChild"
													*ngFor="let modelListSurvey of _modelClaimProposal.listModelSurvey">
													<div class="DivContainerSteperContent"
														*ngIf="_modelClaimProposal.modelUserSubmitSurvey">
														<div class="DivTitleStepperContent">
															<label class="labelDate">
																{{modelListSurvey.CreatedOn | initiateWithoutUTC |
																convertToDateMedium | convertEmptyToDash}} {{
																modelListSurvey.CreatedOn ?
																_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA : "" }}
																| Submit hasil survei oleh {{
																_modelClaimProposal.SurveyNearestBranchName
																| convertEmptyToDash }} | {{
																_modelClaimProposal.modelUserSubmitSurvey.FirstName |
																convertEmptyToDash }} {{
																_modelClaimProposal.modelUserSubmitSurvey.MiddleName }}
																{{ _modelClaimProposal.modelUserSubmitSurvey.LastName }}
															</label>
														</div>
														<div class="DivSubTitleStepper">
														</div>
													</div>
												</li>

												<ng-container
													*ngFor="let modelListSurvey of _modelClaimProposal.listModelSurvey">
													<li class="LiStepperHorizontalForChild"
														*ngIf="modelListSurvey.modelUserResurveyed">
														<div class="DivContainerSteperContent">
															<div class="DivTitleStepperContent">
																<label class="labelDate">
																	{{ modelListSurvey.ResurveyedOn | initiateWithoutUTC
																	| convertToDateMedium | convertEmptyToDash}} {{
																	modelListSurvey.ResurveyedOn ?
																	_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA :
																	"" }} | Resurvey oleh {{
																	modelListSurvey.modelUserResurveyed.FirstName |
																	convertEmptyToDash }} {{
																	modelListSurvey.modelUserResurveyed.MiddleName }} {{
																	modelListSurvey.modelUserResurveyed.LastName }}
																</label>
															</div>
															<div class="DivSubTitleStepper">
															</div>
														</div>
													</li>
												</ng-container>

												<ng-container
													*ngFor="let modelListSurvey of _modelClaimProposal.listModelSurvey">
													<li class="LiStepperHorizontalForChild"
														*ngIf="modelListSurvey.modelUserVerified !== undefined">
														<div class="DivContainerSteperContent">
															<div class="DivTitleStepperContent">
																<label class="labelDate">
																	{{ modelListSurvey.VerifiedOn | initiateWithoutUTC |
																	convertToDateMedium | convertEmptyToDash }} {{
																	modelListSurvey.VerifiedOn ?
																	_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA :
																	"" }} | Verifikasi survei oleh Staff {{
																	modelListSurvey.modelUserVerified.FirstName |
																	convertEmptyToDash }} {{
																	modelListSurvey.modelUserVerified.MiddleName }} {{
																	modelListSurvey.modelUserVerified.LastName }}
																</label>
															</div>
															<div class="DivSubTitleStepper">
															</div>
														</div>
													</li>
												</ng-container>
												<ng-container
													*ngFor="let modelListSurvey of _modelClaimProposal.listModelSurvey">
													<li class="LiStepperHorizontalForChild"
														*ngIf="_modelClaimProposal.modelUserRejected">
														<div class="DivContainerSteperContent">
															<div class="DivTitleStepperContent">
																<label class="labelDate">
																	{{ modelListSurvey.RejectedOn | initiateWithoutUTC |
																	convertToDateMedium | convertEmptyToDash }} {{
																	modelListSurvey.RejectedOn ?
																	_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA :
																	"" }} | Rejected survei oleh {{
																	modelListSurvey.modelUserRejected?.FirstName |
																	convertEmptyToDash }} {{
																	modelListSurvey.modelUserRejected?.MiddleName }}
																	{{ modelListSurvey.modelUserRejected?.LastName }}
																</label>
															</div>
															<div class="DivSubTitleStepper">
															</div>
														</div>
													</li>
												</ng-container>
												<ng-container
													*ngFor="let modelListSurvey of _modelClaimProposal.listModelSurvey">
													<li class="LiStepperHorizontalForChild"
														*ngIf="modelListSurvey.modelUserApproved">
														<div class="DivContainerSteperContent">
															<div class="DivTitleStepperContent">
																<label class="labelDate">
																	{{ modelListSurvey.ApprovedOn | initiateWithoutUTC |
																	convertToDateMedium | convertEmptyToDash }} {{
																	modelListSurvey.ApprovedOn ?
																	_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA :
																	"" }} | Review survei oleh SPV {{
																	modelListSurvey.modelUserApproved.FirstName |
																	convertEmptyToDash }} {{
																	modelListSurvey.modelUserApproved.MiddleName }} {{
																	modelListSurvey.modelUserApproved.LastName }}
																</label>
															</div>
															<div class="DivSubTitleStepper">
															</div>
														</div>
													</li>
												</ng-container>
												<ng-container
													*ngFor="let modelListSurvey of _modelClaimProposal.listModelSurvey">
													<li class="LiStepperHorizontalForChild"
														*ngIf="(_modelClaimProposal.Status) && (_modelClaimProposal.Status >= _enumClaimProposalStatus.Approved && _modelClaimProposal.Status !== _enumClaimProposalStatus.Rejected && _modelClaimProposal.Status !== _enumClaimProposalStatus.Direct)">
														<div class="DivContainerSteperContent">
															<div class="DivTitleStepperContent">
																<label class="labelDate">
																	{{ _modelClaimProposal.SignerHeadOfficeReviewedOn |
																	initiateWithoutUTC | convertToDateMedium |
																	convertEmptyToDash }} {{
																	_modelClaimProposal.SignerHeadOfficeReviewedOn ?
																	_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA :
																	"" }} | Approved survei oleh Manager {{
																	_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.FirstName
																	| convertEmptyToDash }} {{
																	_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.MiddleName
																	}}
																	{{
																	_modelClaimProposal.modelUserSignerHeadOfficeReviewed?.LastName
																	}}
																</label>
															</div>
															<div class="DivSubTitleStepper">
															</div>
														</div>
													</li>
												</ng-container>
											</ng-container>
											<ng-container
												*ngFor="let modelListSurvey of _modelClaimProposal.listModelSurvey">
												<li class="LiStepperHorizontalForChildNoLine"
													*ngIf="(_modelClaimProposal.Status) && (_modelClaimProposal.Status >= _enumClaimProposalStatus.Approved && _modelClaimProposal.Status !== _enumClaimProposalStatus.Rejected && _modelClaimProposal.Status !== _enumClaimProposalStatus.Direct)">
													<div class="DivContainerSteperContent">
														<div class="DivTitleStepperContent">
															<label class="labelDate">
																{{ _modelClaimProposal.SignerHeadOfficeReviewedOn |
																initiateWithoutUTC | convertToDateMedium |
																convertEmptyToDash }}
																{{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} |
																SPK Awal Terbit
															</label>
														</div>
													</div>
												</li>
											</ng-container>
										</ul>
									</div>
								</div>
							</li>
							<li class="LiStepperHorizontal" [ngClass]="_arrayClaimProposalTrackingModelPhaseOne !== undefined && _arrayClaimProposalTrackingModelPhaseOne.length > 0 ? 'LiStepperHorizontal' : 'LiStepperHorizontalDisable'">
								<div class="DivContainerContentStepper">
									<span>Tahap Mantle</span>
									<div class="DivTitleStepper">
										<ul>
											<li [ngClass]="last ? 'LiStepperHorizontalForChildNoLine' : 'LiStepperHorizontalForChild'"
												*ngFor="let modelClaimProposalTrackingPhase1 of _arrayClaimProposalTrackingModelPhaseOne; let last = last">
												<div class="DivContainerSteperContent">
													<div class="DivTitleStepperContent">
														<label> {{modelClaimProposalTrackingPhase1.ActionDate | initiateWithoutUTC |
															convertToDateMedium }} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} |
															{{modelClaimProposalTrackingPhase1.Action}} oleh {{ modelClaimProposalTrackingPhase1.ActionUser }}
														</label>
													</div>
													<div class="DivSubTitleStepper"
														*ngIf="modelClaimProposalTrackingPhase1.SubAction !== undefined">
														<label>{{modelClaimProposalTrackingPhase1.SubAction}}</label>
													</div>

													<div class="DivSubTitleStepperForSurvey DivSubTitleStepperForSurveyTlo"
													*ngIf="_booleanFormSatisfactionSurveyTrackingClaim === true && modelClaimProposalTrackingPhase1.Action === 'Konfirmasi Claim TLO'">
														<div class="DivContainerForRating">
															<div class="DivWrapperForRating">
																<span>Rata-Rata Penilaian Kepuasan BRINS</span>
																<label
																	>{{_numberTotalRatingForBRINS}} / 5</label>
															</div>
															<div class="DivWrapperForRating" *ngIf="_booleanTLO">
																<span>Rata-Rata Penilaian Kepuasan Bengkel</span>
																<label
																	class="LabelForRating">{{_numberTotalRatingForWorkshop}} / 5</label>
															</div>
														</div>
													<input type="button" value="Lihat survei kepuasan"
														(click)="booleanSatisfactionSurvey(true)">
												</div>
												<div class="DivSubTitleStepperForSurveyForInsert"
													*ngIf="_booleanFormSatisfactionSurveyTrackingClaim === false && modelClaimProposalTrackingPhase1.Action === 'Konfirmasi Claim TLO' && _booleanHeadOffice">
													<input type="button" value="Isi survei kepuasan"
														(click)="booleanSatisfactionSurvey(true)">
												</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</li>
							<li class="LiStepperHorizontal" [ngClass]="_arrayClaimProposalTrackingModelPhaseTwo !== undefined && _arrayClaimProposalTrackingModelPhaseTwo.length > 0 ? 'LiStepperHorizontal' : 'LiStepperHorizontalDisable'"
								*ngIf="_modelClaimProposal.IncidentDamageType !== _stringConstant.STRING_TRACKING_CLAIM_DAMAGETYPE_TOTALLOSSONLY">
								<div class="DivContainerContentStepper">
									<span>Proses Pengerjaan</span>
									<div class="DivTitleStepper">
										<ul>
											<li [ngClass]="last ? 'LiStepperHorizontalForChildNoLine' : 'LiStepperHorizontalForChild'"
												*ngFor="let modelClaimProposalTrackingPhase2 of _arrayClaimProposalTrackingModelPhaseTwo; let last = last">
												<div class="DivContainerSteperContent">
													<div class="DivTitleStepperContent" *ngIf="_booleanNonMantle === false">
														<label>{{modelClaimProposalTrackingPhase2.ActionDate | initiateWithoutUTC | convertToDateMedium }} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} |
															{{ modelClaimProposalTrackingPhase2.Action }}
														</label>
													</div>
													<div class="DivTitleStepperContent" *ngIf="modelClaimProposalTrackingPhase2.Action === 'Mulai perbaikan kendaraan' && modelClaimProposalTrackingPhase2.UpdatedBy !== 0 && _booleanNonMantle === true">
														<label>{{modelClaimProposalTrackingPhase2.ActionDate | initiateWithoutUTC | convertToDateMedium }} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} |
															{{ modelClaimProposalTrackingPhase2.Action }}
														</label>
													</div>
													<!-- <div class="DivTitleStepperContent"
															*ngIf="_booleanCanEditForTrackingClaim === true && modelClaimProposalTrackingPhase2.UpdateBy === 0">
															<label>-- / -- / ---- | -- : -- : -- | Mulai Perbaikan Kendaraan</label>
													</div> -->
													<div class="DivTitleStepperContent" *ngIf="modelClaimProposalTrackingPhase2.Action === 'Mulai perbaikan kendaraan' && modelClaimProposalTrackingPhase2.UpdatedBy === 0 && _booleanNonMantle === true">
														<label>-- / -- / ---- | -- : -- : -- | Mulai Perbaikan Kendaraan</label>
													</div>
													<div class="DivSubTitleStepper"
														*ngIf="modelClaimProposalTrackingPhase2.SubAction !== undefined">
														<label>{{modelClaimProposalTrackingPhase2.SubAction}}</label>
													</div>

													<div class="DivContainerForImage" *ngIf="modelClaimProposalTrackingPhase2.listModelClaimProposalTrackingGallery!.length > 0">
														<div class="DivWrapperImg"
															*ngFor="let modelClaimProposalTrackingPhase2TrackingGallery of modelClaimProposalTrackingPhase2.listModelClaimProposalTrackingGallery">
															<div class="DivImg" *ngIf="modelClaimProposalTrackingPhase2TrackingGallery.Extension === 'jpg' || modelClaimProposalTrackingPhase2TrackingGallery.Extension === 'jpeg' || modelClaimProposalTrackingPhase2TrackingGallery.Extension === 'png'">
																<img src="{{ getImage(modelClaimProposalTrackingPhase2TrackingGallery.URL) }}"
																	(dblclick)="showImageListTrackingGallery(modelClaimProposalTrackingPhase2TrackingGallery);">
																<label>{{modelClaimProposalTrackingPhase2TrackingGallery.Name}}</label>
															</div>
															<div class="DivImg" *ngIf="modelClaimProposalTrackingPhase2TrackingGallery.Extension === 'pdf'">
																<img src="{{ modelClaimProposalTrackingPhase2TrackingGallery.Extension | convertExtensionToIcon }}"
																alt="{{ modelClaimProposalTrackingPhase2TrackingGallery.Name }}" (click)="downloadFile(modelClaimProposalTrackingPhase2TrackingGallery.URL!)">
																<label>{{modelClaimProposalTrackingPhase2TrackingGallery.Name}}</label>
															</div>
														</div>
													</div>

													<ng-container
														*ngIf=" _booleanNonMantle === true && modelClaimProposalTrackingPhase2.Action === 'Mulai perbaikan kendaraan'">
														<div class="DivContainerDateTimePicker" *ngIf="_booleanCanEditForTrackingClaimPhaseTwo === true && _booleanHeadOffice">
															<mat-form-field appearance="fill">
																<mat-label>Pilih Tanggal</mat-label>
																<input id="dateIncidentDate" matInput
																	[matDatepicker]="dpIncident"
																	(dateChange)="getActionDate($event)"
																	[max]="_dateDateNow">
																<mat-datepicker-toggle matSuffix
																	[for]="dpIncident"></mat-datepicker-toggle>
																<mat-datepicker #dpIncident startView="month"
																	panelClass="month-picker">
																</mat-datepicker>
															</mat-form-field>
															<div class="DivInputHorizontalContainer DivTimer">
																<select name="modelIncidentDatePrefix"
																	id="selectIncidentDatePrefix"
																	class="SelectTimePrefix"
																	[(ngModel)]="_stringTimePrefixPhase2">
																	<option
																		*ngFor="let stringTimeHours of getTimeHours()"
																		[value]="stringTimeHours">{{ stringTimeHours
																		}}</option>
																</select>
																<select name="modelIncidentDateSuffix"
																	id="selectIncidentDateSuffix"
																	class="SelectTimeSuffix"
																	[(ngModel)]="_stringTimeSuffixPhase2">
																	<option
																		*ngFor="let stringTimeMinutes of getTimeMinutes()"
																		[value]="stringTimeMinutes">{{
																		stringTimeMinutes }}</option>
																</select>
															</div>
															<input type="button" [value]="_stringSaveOrUpdatePhaseTwo" *ngIf="_booleanCanEditForTrackingClaimPhaseTwo === true"
																(click)="callUpdateClaimProposalTrackingForPayment(modelClaimProposalTrackingPhase2)">
														</div>
														<ng-container *ngIf="this._booleanHeadOffice">
															<div class="DivContainerForUpload">
																<input #fileAttachment type="file" multiple
																	[accept]="_stringFileTypeAllowedImage"
																	(change)="listenFileUpload($event, modelClaimProposalTrackingPhase2)" [disabled]="this._booleanDisableAttachmentVehicleIn === false">
																<ng-container>
																	<label for="fileAttachment" class="LabelAttachment" [ngClass]="this._booleanDisableAttachmentVehicleIn !== false ? 'LabelAttachmentActive' : 'LabelAttachmentDisable'">
																		<input type="button" class="ButtonAttachment"
																			(click)="fileAttachment.click();">
																	</label>
																	<div class="DivContainerForAttachment">
																		<p>Upload gambar maksimal 10 Mb</p>
																		<label>Jenis file ( jpg, jpeg, png, pdf )</label>
																	</div>
																</ng-container>
															</div>
															<div class="DivContainerForImage">
																<div class="DivWrapperImg"
																	*ngFor="let modelClaimProposalTrackingTrackingGallery of this._arrayModelClaimProposalTrackingGalleryForVehicleIn; let numberIndex = index">
																	<div class="DivImg" *ngIf="modelClaimProposalTrackingTrackingGallery.Extension === 'jpg' || modelClaimProposalTrackingTrackingGallery.Extension === 'jpeg' || modelClaimProposalTrackingTrackingGallery.Extension === 'png'">
																		<img
																			[ngStyle]="{'background-image': 'url(' + modelClaimProposalTrackingTrackingGallery.Data + ')'}">
																		<div class="DivDescription">
																			<label>{{ modelClaimProposalTrackingTrackingGallery.Name }}</label>
																			<input type="button" class="ButtonRemoveImg" (click)="deleteModelClaimProposalTrackingGallery(numberIndex, modelClaimProposalTrackingPhase2)">
																		</div>
																	</div>
																	<div class="DivImg" *ngIf="modelClaimProposalTrackingTrackingGallery.Extension === 'pdf'">
																		<img src="{{ modelClaimProposalTrackingTrackingGallery.Extension | convertExtensionToIcon }}"
																		alt="{{ modelClaimProposalTrackingTrackingGallery.Name }}">
																		<div class="DivDescription">
																			<label>{{ modelClaimProposalTrackingTrackingGallery.Name }}</label>
																			<input type="button" class="ButtonRemoveImg" (click)="deleteModelClaimProposalTrackingGallery(numberIndex, modelClaimProposalTrackingPhase2)">
																		</div>
																	</div>
																</div>
															</div>
															<input
																*ngIf="_arrayModelClaimProposalTrackingGalleryForVehicleIn!.length > 0"
																class="ButtonUpload" type="button" value="Upload"
																(click)="callUploadClaimProposalTracking(modelClaimProposalTrackingPhase2)">
														</ng-container>
													</ng-container>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</li>
							<li class="LiStepperHorizontal" [ngClass]="_modelClaimProposalQualityControl.ControlDate !== undefined ? 'LiStepperHorizontal' : 'LiStepperHorizontalDisable'">
								<div class="DivContainerContentStepper">
									<span>Kontrol Kualitas</span>
									<div class="DivTitleStepper">
										<ul>
											<li class="LiStepperHorizontalForChild" *ngIf="_modelClaimProposalQualityControl.ControlDate !== undefined">
												<div class="DivContainerSteperContent">
													<div class="DivTitleStepperContent">
														<label class="labelDate">
															{{ _modelClaimProposalQualityControl.ControlDate | initiateWithoutUTC |
															convertToDateMedium | convertEmptyToDash }} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} | QC
															oleh {{ _modelClaimProposalQualityControl.modelUserSurveyor?.getName() | convertEmptyToDash }}
														</label>
													</div>
													<div class="DivContainerForImage" *ngIf="_modelClaimProposalQualityControl.listModelClaimProposalQualityControlGallery && _modelClaimProposalQualityControl.listModelClaimProposalQualityControlGallery!.length > 0">
														<div class="DivWrapperImg"
															*ngFor="let modelClaimProposalQualityControlGallery of _modelClaimProposalQualityControl.listModelClaimProposalQualityControlGallery">
															<div class="DivImg" *ngIf="modelClaimProposalQualityControlGallery.Extension === 'jpg' || modelClaimProposalQualityControlGallery.Extension === 'jpeg' || modelClaimProposalQualityControlGallery.Extension === 'png'">
																<img src="{{ getImage(modelClaimProposalQualityControlGallery.URL) }}"
																	(dblclick)="showImageListQualityQontrolGallery(modelClaimProposalQualityControlGallery);">
																<label>{{modelClaimProposalQualityControlGallery.Name}}</label>
															</div>
															<div class="DivImg" *ngIf="modelClaimProposalQualityControlGallery.Extension === 'pdf'">
																<img src="{{ modelClaimProposalQualityControlGallery.Extension | convertExtensionToIcon }}"
																alt="{{ modelClaimProposalQualityControlGallery.Name }}" (click)="downloadFile(modelClaimProposalQualityControlGallery.URL!)">
																<label>{{modelClaimProposalQualityControlGallery.Name}}</label>
															</div>
														</div>
													</div>
												</div>
											</li>
											<li class="LiStepperHorizontalForChildNoLine" *ngIf="_modelClaimProposalQualityControl.ControlDate !== undefined">
												<div class="DivContainerSteperContent">
													<div class="DivTitleStepperContent">
														<label class="labelDate">
															Catatan Surveyor
														</label>
													</div>

													<div class="DivTitleStepperContent">
														<label>{{ _modelClaimProposalQualityControl.Note | convertEmptyToDash }}</label>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</li>
							<li class="LiStepperHorizontal" [ngClass]="_arrayClaimProposalTrackingModelPhaseThree !== undefined && _arrayClaimProposalTrackingModelPhaseThree.length > 0 && _arrayClaimProposalTrackingModelPhaseFour !== undefined ? 'LiStepperHorizontal' : 'LiStepperHorizontalDisable'"
								*ngIf="_modelClaimProposal.IncidentDamageType !== _stringConstant.STRING_TRACKING_CLAIM_DAMAGETYPE_TOTALLOSSONLY">
								<div class="DivContainerContentStepper">
									<span>Penyelesaian Pekerjaan</span>
									<div class="DivTitleStepper">
										<ul *ngIf="_arrayClaimProposalTrackingModelPhaseThree !== undefined && _arrayClaimProposalTrackingModelPhaseThree.length > 0 && ((_arrayClaimProposalTrackingModelPhaseThree[0].Action !== undefined && _booleanHeadOffice) || (_arrayClaimProposalTrackingModelPhaseThree[0].Action !== undefined && !_booleanHeadOffice))">
											<li [ngClass]="last ? 'LiStepperHorizontalForChildNoLine' : 'LiStepperHorizontalForChild'" *ngFor="let modelClaimProposalTrackingPhaseThree of _arrayClaimProposalTrackingModelPhaseThree; let last = last">
												<div class="DivContainerSteperContent">

													<div class="DivTitleStepperContent" *ngIf="modelClaimProposalTrackingPhaseThree.Action === 'Kendaraan keluar' && modelClaimProposalTrackingPhaseThree.UpdatedBy === 0 && _booleanNonMantle === true">
														<label>-- / -- / ---- | -- : -- : -- | Kendaraan keluar</label>
													</div>

													<div class="DivTitleStepperContent" *ngIf="modelClaimProposalTrackingPhaseThree.Action === 'Kendaraan keluar' && modelClaimProposalTrackingPhaseThree.UpdatedBy !== 0 && _booleanNonMantle === true">
														<label>{{modelClaimProposalTrackingPhaseThree.ActionDate | initiateWithoutUTC | convertToDateMedium | convertEmptyToDash }} {{ modelClaimProposalTrackingPhaseThree.ActionDate ? _stringConstant.STRING_VALUE_TIME_WEST_INDONESIA : "" }} |
															{{modelClaimProposalTrackingPhaseThree.Action | convertEmptyToDash }}
														</label>
													</div>

													<div class="DivTitleStepperContent" *ngIf="modelClaimProposalTrackingPhaseThree.Action === 'Kendaraan keluar' && _booleanNonMantle === false">
														<label>{{modelClaimProposalTrackingPhaseThree.ActionDate | initiateWithoutUTC | convertToDateMedium | convertEmptyToDash }} {{ modelClaimProposalTrackingPhaseThree.ActionDate ? _stringConstant.STRING_VALUE_TIME_WEST_INDONESIA : "" }} |
															{{modelClaimProposalTrackingPhaseThree.Action | convertEmptyToDash }}
														</label>
													</div>

													<div class="DivTitleStepperContent" *ngIf="modelClaimProposalTrackingPhaseThree.Action !== 'Konfirmasi penyelesaian' && modelClaimProposalTrackingPhaseThree.Action !== 'Kendaraan keluar'">
														<label>{{modelClaimProposalTrackingPhaseThree.ActionDate | initiateWithoutUTC | convertToDateMedium | convertEmptyToDash }} {{ modelClaimProposalTrackingPhaseThree.ActionDate ? _stringConstant.STRING_VALUE_TIME_WEST_INDONESIA : "" }} |
															{{modelClaimProposalTrackingPhaseThree.Action | convertEmptyToDash }}
														</label>
													</div>
													<div class="DivTitleStepperContent" *ngIf="modelClaimProposalTrackingPhaseThree.Action === 'Konfirmasi penyelesaian'">
														<label>{{modelClaimProposalTrackingPhaseThree.ActionDate | initiateWithoutUTC | convertToDateMedium | convertEmptyToDash }}  {{ modelClaimProposalTrackingPhaseThree.ActionDate ? _stringConstant.STRING_VALUE_TIME_WEST_INDONESIA : "" }} |
															{{ modelClaimProposalTrackingPhaseThree.Action | convertEmptyToDash }} oleh {{ modelClaimProposalTrackingPhaseThree.ActionUser | convertEmptyToDash }}
														</label>
													</div>

													<div class="DivSubTitleStepper"
														*ngIf="modelClaimProposalTrackingPhaseThree.SubAction !== undefined">
														<label>{{modelClaimProposalTrackingPhaseThree.SubAction}}</label>
													</div>

													<ng-container
														*ngIf="modelClaimProposalTrackingPhaseThree.Action === 'Form survei kepuasan' || modelClaimProposalTrackingPhaseThree.Action === 'Form survei kepuasaan'">
														<div class="DivSubTitleStepperForSurvey"
															*ngIf="_booleanFormSatisfactionSurveyTrackingClaim === true">
															<div class="DivContainerForRating">
																<div class="DivWrapperForRating">
																	<span>Rata-Rata Penilaian Kepuasan BRINS</span>
																	<label
																		class="LabelForRating">{{_numberTotalRatingForBRINS}} / 5</label>
																</div>
																<div class="DivWrapperForRating" *ngIf="_booleanTLO">
																	<span>Rata-Rata Penilaian Kepuasan Bengkel</span>
																	<label
																		class="LabelForRating">{{_numberTotalRatingForWorkshop}} / 5</label>
																</div>
															</div>
															<input type="button" value="Lihat survei kepuasan"
																(click)="booleanSatisfactionSurvey(true)">
														</div>
														<div class="DivSubTitleStepperForSurveyForInsert"
															*ngIf="_booleanFormSatisfactionSurveyTrackingClaim === false && _booleanHeadOffice">
															<input type="button" value="Isi survei kepuasan"
																(click)="booleanSatisfactionSurvey(true)">
														</div>
													</ng-container>

													<div class="DivContainerForImage"
														*ngIf="modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery!.length > 0">
														<div class="DivWrapperImg"
															*ngFor="let modelClaimProposalTrackingPhaseThreeTrackingGallery of modelClaimProposalTrackingPhaseThree.listModelClaimProposalTrackingGallery">
															<div class="DivImg" *ngIf="modelClaimProposalTrackingPhaseThreeTrackingGallery.Extension === 'jpg' || modelClaimProposalTrackingPhaseThreeTrackingGallery.Extension === 'jpeg' || modelClaimProposalTrackingPhaseThreeTrackingGallery.Extension === 'png'">
																<img src="{{ getImage(modelClaimProposalTrackingPhaseThreeTrackingGallery.URL) }}"
																	(dblclick)="showImageListTrackingGallery(modelClaimProposalTrackingPhaseThreeTrackingGallery);">
																<label>{{modelClaimProposalTrackingPhaseThreeTrackingGallery.Name}}</label>
															</div>
															<div class="DivImg" *ngIf="modelClaimProposalTrackingPhaseThreeTrackingGallery.Extension === 'pdf'">
																<img src="{{ modelClaimProposalTrackingPhaseThreeTrackingGallery.Extension | convertExtensionToIcon }}"
																alt="{{ modelClaimProposalTrackingPhaseThreeTrackingGallery.Name }}" (click)="downloadFile(modelClaimProposalTrackingPhaseThreeTrackingGallery.URL!)">
																<label>{{modelClaimProposalTrackingPhaseThreeTrackingGallery.Name}}</label>
															</div>
														</div>
													</div>
													<ng-container
														*ngIf=" _booleanCanEditForTrackingClaimPhaseThreeSatisfactionSurvey === true && _booleanNonMantle === true && modelClaimProposalTrackingPhaseThree.Action === 'Surat puas' && _booleanHeadOffice">
														<div class="DivContainerForUpload">
															<input #fileAttachment type="file" multiple
																[accept]="_stringFileTypeAllowedImage"
																(change)="listenFileUpload($event, modelClaimProposalTrackingPhaseThree)" [disabled]="this._booleanDisableAttachmentSatisfactionLetter === false">
															<ng-container>
																<label for="fileAttachment" class="LabelAttachment" [ngClass]="this._booleanDisableAttachmentSatisfactionLetter !== false ? 'LabelAttachmentActive' : 'LabelAttachmentDisable'">
																	<input type="button" class="ButtonAttachment"
																		(click)="fileAttachment.click();">
																</label>
																<div class="DivContainerForAttachment">
																	<p>Upload gambar maksimal 10 Mb</p>
																	<label>Jenis file ( jpg, jpeg, png, pdf )</label>
																</div>
															</ng-container>
														</div>

														<ng-container
															*ngIf="modelClaimProposalTrackingPhaseThree.Action === 'Surat puas'">
															<div class="DivContainerForImage">
																<div class="DivWrapperImg"
																	*ngFor="let modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter of this._arrayModelClaimProposalTrackingGalleryForSatisfactionLetter; let numberIndex = index">
																	<div class="DivImg" *ngIf="modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension === 'jpg' || modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension === 'jpeg' || modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension === 'png'">
																		<img
																			[ngStyle]="{'background-image': 'url(' + modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Data + ')'}">
																		<div class="DivDescription">
																			<label>{{modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Name}}</label>
																			<input type="button" class="ButtonRemoveImg" (click)="deleteModelClaimProposalTrackingGallery(numberIndex, modelClaimProposalTrackingPhaseThree)">
																		</div>
																	</div>
																	<div class="DivImg" *ngIf="modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension === 'pdf'">
																		<img src="{{ modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension | convertExtensionToIcon }}"
																		alt="{{ modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Name }}">
																		<div class="DivDescription">
																			<label>{{ modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Name }}</label>
																			<input type="button" class="ButtonRemoveImg" (click)="deleteModelClaimProposalTrackingGallery(numberIndex, modelClaimProposalTrackingPhaseThree)">
																		</div>
																	</div>

																</div>
															</div>
															<input
																*ngIf="_arrayModelClaimProposalTrackingGalleryForSatisfactionLetter!.length > 0"
																class="ButtonUpload" type="button" value="Upload"
																(click)="callUploadClaimProposalTracking(modelClaimProposalTrackingPhaseThree)">
														</ng-container>
													</ng-container>

													<ng-container
														*ngIf="_booleanNonMantle === true && modelClaimProposalTrackingPhaseThree.Action === 'Kendaraan keluar' && _booleanHeadOffice">
														<ng-container *ngIf="_booleanCanEditForTrackingClaimPhaseThreeVehicleOut === true">
															<div class="DivContainerDateTimePicker">
																<mat-form-field appearance="fill">
																	<mat-label>Pilih Tanggal</mat-label>
																	<input id="dateIncidentDate" matInput
																		[matDatepicker]="dpIncident"
																		(dateChange)="getActionDate($event)"
																		[max]="_dateDateNow">
																	<mat-datepicker-toggle matSuffix
																		[for]="dpIncident"></mat-datepicker-toggle>
																	<mat-datepicker #dpIncident startView="month"
																		panelClass="month-picker">
																	</mat-datepicker>
																</mat-form-field>
																<div class="DivInputHorizontalContainer DivTimer">
																	<select name="modelIncidentDatePrefix"
																		id="selectIncidentDatePrefix"
																		class="SelectTimePrefix"
																		[(ngModel)]="_stringTimePrefixPhase3">
																		<option
																			*ngFor="let stringTimeHours of getTimeHours()"
																			>{{ stringTimeHours
																			}}</option>
																	</select>
																	<select name="modelIncidentDateSuffix"
																		id="selectIncidentDateSuffix"
																		class="SelectTimeSuffix"
																		[(ngModel)]="_stringTimeSuffixPhase3">
																		<option
																			*ngFor="let stringTimeMinutes of getTimeMinutes()"
																			>{{
																			stringTimeMinutes }}</option>
																	</select>
																</div>
																<input type="button" [value]="_stringSaveOrUpdatePhaseThree" *ngIf="_booleanCanEditForTrackingClaimPhaseThreeVehicleOut === true"
																	(click)="callUpdateClaimProposalTrackingForPayment(modelClaimProposalTrackingPhaseThree)">
															</div>
														</ng-container>
														<div class="DivContainerForUpload">
															<input #fileAttachment type="file" multiple
																[accept]="_stringFileTypeAllowedImage"
																(change)="listenFileUpload($event, modelClaimProposalTrackingPhaseThree)" [disabled]="this._booleanDisableAttachmentVehicleOut === false">
															<ng-container>
																<label for="fileAttachment" class="LabelAttachment" [ngClass]="this._booleanDisableAttachmentVehicleOut !== false ? 'LabelAttachmentActive' : 'LabelAttachmentDisable'">
																	<input type="button" class="ButtonAttachment"
																		(click)="fileAttachment.click();">
																</label>
																<div class="DivContainerForAttachment">
																	<p>Upload gambar maksimal 10 Mb</p>
																	<label>Jenis file ( jpg, jpeg, png, pdf )</label>
																</div>
															</ng-container>
														</div>

														<div class="DivContainerForImage">
															<div class="DivWrapperImg"
																*ngFor="let modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter of this._arrayModelClaimProposalTrackingGalleryForVehicleOut; let numberIndex = index">
																<div class="DivImg" *ngIf="modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension === 'jpg' || modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension === 'jpeg' || modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension === 'png'">
																	<img
																		[ngStyle]="{'background-image': 'url(' + modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Data + ')'}">
																	<div class="DivDescription">
																		<label>{{modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Name}}</label>
																		<input type="button" class="ButtonRemoveImg"
																	(click)="deleteModelClaimProposalTrackingGallery(numberIndex, modelClaimProposalTrackingPhaseThree)">
																	</div>
																</div>

																<div class="DivImg" *ngIf="modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension === 'pdf'">
																	<img src="{{ modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Extension | convertExtensionToIcon }}"
																	alt="{{ modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Name }}">
																	<div class="DivDescription">
																		<label>{{ modelClaimProposalTrackingTrackingGalleryForSatisfactionLetter.Name }}</label>
																		<input type="button" class="ButtonRemoveImg" (click)="deleteModelClaimProposalTrackingGallery(numberIndex, modelClaimProposalTrackingPhaseThree)">
																	</div>
																</div>
															</div>
														</div>
														<input
															*ngIf="_arrayModelClaimProposalTrackingGalleryForVehicleOut!.length > 0"
															class="ButtonUpload" type="button" value="Upload"
															(click)="callUploadClaimProposalTracking(modelClaimProposalTrackingPhaseThree)">
													</ng-container>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</li>
							<li class="LiStepperHorizontal" [ngClass]="_arrayClaimProposalTrackingModelPhaseFour !== undefined && _arrayClaimProposalTrackingModelPhaseFour.length > 0 ? 'LiStepperHorizontal' : 'LiStepperHorizontalDisable'">
								<div class="DivContainerContentStepper">
									<span>Proses Administrasi Akhir Klaim</span>
									<div class="DivTitleStepper">
										<ul>
											<li [ngClass]="last ? 'LiStepperHorizontalForChildNoLine' : 'LiStepperHorizontalForChild'"
												*ngFor="let modelClaimProposalTrackingPhase4 of _arrayClaimProposalTrackingModelPhaseFour; let last = last">
												<div class="DivContainerSteperContent">
													<div class="DivTitleStepperContent">
														<label
															*ngIf="modelClaimProposalTrackingPhase4.ActionUser !== undefined">{{modelClaimProposalTrackingPhase4.ActionDate | initiateWithoutUTC
															| convertToDateMedium }} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} |
															{{modelClaimProposalTrackingPhase4.Action}} oleh
															{{modelClaimProposalTrackingPhase4.ActionUser}}
														</label>
														<label
															*ngIf="modelClaimProposalTrackingPhase4.ActionUser === undefined">{{modelClaimProposalTrackingPhase4.ActionDate | initiateWithoutUTC
															| convertToDateMedium }} {{_stringConstant.STRING_VALUE_TIME_WEST_INDONESIA}} |
															{{modelClaimProposalTrackingPhase4.Action}}
														</label>
													</div>
													<div class="DivSubTitleStepper"
														*ngIf="modelClaimProposalTrackingPhase4.SubAction !== undefined">
														<label>{{modelClaimProposalTrackingPhase4.SubAction}}</label>
													</div>
													<div class="DivSubTitleStepper">
														<ng-container *ngIf="modelClaimProposalTrackingPhase4.Action === 'Proses penerbitan DLA'">
															<label class="LabelCheck">Kuitansi</label>
															<label class="LabelCheck">SPK</label>
															<label class="LabelCheck">Surat Puas</label>
															<label class="LabelCheck">Form Survei Kepuasan</label>
															<label class="LabelCheck">Foto epoksi</label>
															<label class="LabelCheck">Foto sanding</label>
															<label class="LabelCheck">Gesek nomor rangka</label>
															<label class="LabelCheck">Foto kendaraan setelah perbaikan</label>
														</ng-container>
													</div>
													<ng-container
														*ngIf="modelClaimProposalTrackingPhase4.Action === 'Form survei kepuasan'">
														<div class="DivSubTitleStepperForSurvey"
															*ngIf="_booleanFormSatisfactionSurveyTrackingClaim === true">
															<div class="DivContainerForRating">
																<div class="DivWrapperForRating">
																	<span>Rata-Rata Penilaian Kepuasan BRINS</span>
																	<label
																		class="LabelForRating">{{_numberTotalRatingForBRINS}} / 5</label>
																</div>
																<div class="DivWrapperForRating" *ngIf="_booleanTLO">
																	<span>Rata-Rata Penilaian Kepuasan Bengkel</span>
																	<label
																		class="LabelForRating">{{_numberTotalRatingForWorkshop}} / 5</label>
																</div>
															</div>
															<input type="button" value="Lihat survei kepuasan"
																(click)="booleanSatisfactionSurvey(true)">
														</div>
														<div class="DivSubTitleStepperForSurveyForInsert"
															*ngIf="_booleanFormSatisfactionSurveyTrackingClaim === false && _booleanHeadOffice">
															<input type="button" value="Isi survei kepuasan"
																(click)="booleanSatisfactionSurvey(true)">
														</div>
													</ng-container>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</li>

							<!-- <li class="LiStepperHorizontal" [ngClass]=" _booleanStepperPhaseFive ? 'LiStepperHorizontal' : 'LiStepperHorizontalDisable'"> -->
							<li class="LiStepperHorizontal" [ngClass]=" _modelStoredClaimDetail.Payment !== undefined && _modelStoredClaimDetail.Payment.length !== 0 ? 'LiStepperHorizontal' : 'LiStepperHorizontalDisable'">
								<div class="DivContainerContentStepper">
									<span>Proses Pembayaran Klaim</span>
									<div class="DivTitleStepper">
										<ul>
											<ng-container *ngIf="_modelStoredClaimDetail.Payment">
												<li class="LiStepperHorizontalForChildNoLine">
													<!-- <div class="DivContainerSteperContent">
														<div class="DivTitleStepperContent"
															*ngIf="modelClaimProposalTrackingPhaseFive.ActionDate !== undefined">
															<label>{{modelClaimProposalTrackingPhaseFive.ActionDate | initiateWithoutUTC
																| convertToDateMedium }} {{ _stringConstant.STRING_VALUE_TIME_WEST_INDONESIA }} |
																{{modelClaimProposalTrackingPhaseFive.Action}}
															</label>
														</div>
													</div> -->
													<!-- <div class="DivContainerSteperContent" *ngIf="_booleanCanEditForTrackingClaimPhaseFive"> -->
													<div *ngFor="let modelStoredClaimPayment of _modelStoredClaimDetail.Payment">
														<div class="DivSubTitleStepperForDetailInfo">
															<label class="LabelCheck">Voucher</label>
															<label class="LabelInformation">:</label>
															<label class="LabelInformation">{{ modelStoredClaimPayment.Voucher }}</label>
														</div>
														<div class="DivSubTitleStepperForDetailInfo">
															<label class="LabelCheck">Date</label>
															<label class="LabelInformation">:</label>
															<label class="LabelInformation">{{ modelStoredClaimPayment.Date_Due | convertToDateShort }}</label>
														</div>
														<div class="DivSubTitleStepperForDetailInfo">
															<label class="LabelCheck">Payment Ref. Data</label>
															<label class="LabelInformation">:</label>
															<label class="LabelInformation">{{ modelStoredClaimPayment.Last_Paid | convertToDateShort }}</label>
														</div>
														<div class="DivSubTitleStepperForDetailInfo">
															<label class="LabelCheck">Name</label>
															<label class="LabelInformation">:</label>
															<label class="LabelInformation">{{  _modelSurvey.WorkshopName }}</label>
														</div>
														<div class="DivSubTitleStepperForDetailInfo">
															<label class="LabelCheck">Claim NotedOn</label>
															<label class="LabelInformation">:</label>
															<label class="LabelInformation">{{ _modelClaimProposal.ClaimNumber }}</label>
														</div>
														<div class="DivSubTitleStepperForDetailInfo">
															<label class="LabelCheck">Nominal</label>
															<label class="LabelInformation">:</label>
															<label class="LabelInformation">{{ modelStoredClaimPayment.Total_Paid }}</label>
														</div>
													</div>
												</li>
											</ng-container>
											<!-- <ng-container *ngIf="_booleanCanEditForTrackingClaimPhaseFive && _booleanStepperPhaseFive === false && _booleanHeadOffice">
												<li class="LiStepperHorizontalForChildNoLine LiStepperHorizontalForChildNoLineDisable">
													<div class="DivContainerSteperContent">
														<div class="DivTitleStepperContent">
															<label>-- / -- / ---- | -- : -- : -- | Surat Permintaan Pembayaran</label>
														</div>
													</div>
													<div class="DivContainerSteperContent"
														*ngIf="_booleanUpdateDatetimeForPaymentAKU">
														<div class="DivContainerDateTimePicker">
															<mat-form-field appearance="fill">
																<mat-label>Pilih Tanggal</mat-label>
																<input id="dateIncidentDate" matInput
																	[matDatepicker]="dpIncident"
																	(dateChange)="getActionDate($event)"
																	[max]="_dateDateNow">
																	<mat-datepicker-toggle matSuffix
																	[for]="dpIncident"></mat-datepicker-toggle>
																<mat-datepicker #dpIncident startView="month"
																	panelClass="month-picker">
																</mat-datepicker>
															</mat-form-field>
															<div class="DivInputHorizontalContainer DivTimer">
																<select name="modelIncidentDatePrefix"
																	id="selectIncidentDatePrefix"
																	class="SelectTimePrefix"
																	[(ngModel)]="_stringTimePrefixPhase5">
																	<option
																		*ngFor="let stringTimeHours5 of getTimeHours()"
																		>{{ stringTimeHours5 }}
																	</option>
																</select>
																<select name="modelIncidentDateSuffix"
																	id="selectIncidentDateSuffix"
																	class="SelectTimeSuffix"
																	[(ngModel)]="_stringTimeSuffixPhase5">
																	<option
																		*ngFor="let stringTimeMinutes5 of getTimeMinutes()"
																		>{{ stringTimeMinutes5
																		}}</option>
																</select>
															</div>
															<input type="button" value="simpan"(click)="callInsertClaimProposalTracking(this._enumClaimProposalTrackingPhase.PaymentAKU)">
														</div>
													</div>
												</li>
											</ng-container> -->
										</ul>
									</div>
								</div>
							</li>
							<li class="LiStepperHorizontal" [ngClass]="_modelStoredClaimDetail.Payment !== undefined && _modelStoredClaimDetail.Payment.length !== 0 ? 'LiStepperHorizontal' : 'LiStepperHorizontalDisable'">
								<div class="DivContainerContentStepper">
									<span>Pembayaran ke {{_stringWorkshop}}</span>
									<div class="DivTitleStepper">
										<ul>
											<ng-container *ngIf="_modelStoredClaimDetail.Payment">
												<li class="LiStepperHorizontalForChildNoLine">
													<!-- <div class="DivContainerSteperContent">
														<div class="DivTitleStepperContent"
															*ngIf="modelClaimProposalTrackingPhaseSix.ActionDate !== undefined">
															<label>{{ modelClaimProposalTrackingPhaseSix.ActionDate | initiateWithoutUTC
																| convertToDateMedium }} {{ _stringConstant.STRING_VALUE_TIME_WEST_INDONESIA }} |
																{{ modelClaimProposalTrackingPhaseSix.Action }}
															</label>
														</div>
													</div> -->
													<div class="DivContainerSteperContent">
														<div *ngFor="let modelStoredClaimPayment of _modelStoredClaimDetail.Payment">
															<div class="DivSubTitleStepperForDetailInfo">
																<label class="LabelCheck">Voucher</label>
																<label class="LabelInformation">:</label>
																<label class="LabelInformation">{{ modelStoredClaimPayment.Voucher | convertEmptyToDash }}</label>
															</div>
															<div class="DivSubTitleStepperForDetailInfo">
																<label class="LabelCheck">Date</label>
																<label class="LabelInformation">:</label>
																<label class="LabelInformation">{{ modelStoredClaimPayment.Date_Due | convertToDateShort }}</label>
															</div>
															<div class="DivSubTitleStepperForDetailInfo">
																<label class="LabelCheck">Payment Ref. Data</label>
																<label class="LabelInformation">:</label>
																<label class="LabelInformation">{{ modelStoredClaimPayment.Last_Paid | convertToDateShort }}</label>
															</div>
															<div class="DivSubTitleStepperForDetailInfo">
																<label class="LabelCheck">Name</label>
																<label class="LabelInformation">:</label>
																<label class="LabelInformation">{{ _modelSurvey.WorkshopName | convertEmptyToDash }}</label>
															</div>
															<div class="DivSubTitleStepperForDetailInfo">
																<label class="LabelCheck">Claim Note</label>
																<label class="LabelInformation">:</label>
																<label class="LabelInformation">{{ _modelClaimProposal.TicketNumber | convertEmptyToDash }}</label>
															</div>
															<div class="DivSubTitleStepperForDetailInfo">
																<label class="LabelCheck">Nominal</label>
																<label class="LabelInformation">:</label>
																<label class="LabelInformation">{{ modelStoredClaimPayment.Total_Paid | convertEmptyToDash }}</label>
															</div>
														</div>
													</div>
												</li>
											</ng-container>
											<!-- <ng-container *ngIf="_booleanStepperPhaseSeven === true">
												<li class="LiStepperHorizontalForChildNoLine"
													*ngFor="let modelClaimProposalTrackingPhaseSeven of _arrayClaimProposalTrackingModelPhaseSeven">
													<div class="DivContainerSteperContent">
														<div class="DivTitleStepperContent"
															*ngIf="modelClaimProposalTrackingPhaseSeven.ActionDate !== undefined">
															<label>{{ modelClaimProposalTrackingPhaseSeven.ActionDate | initiateWithoutUTC
																| convertToDateMedium }} {{ _stringConstant.STRING_VALUE_TIME_WEST_INDONESIA }} |
																{{ modelClaimProposalTrackingPhaseSeven.Action }}
															</label>
														</div>
													</div>
													<div class="DivContainerSteperContent" *ngIf="_booleanCanEditForTrackingClaimPhaseSeven === true && this._booleanHeadOffice">
														<div class="DivContainerDateTimePicker">
															<mat-form-field appearance="fill">
																<mat-label>Pilih Tanggal</mat-label>
																<input id="dateIncidentDate" matInput
																	[matDatepicker]="dpIncident"
																	(dateChange)="getActionDate($event)"
																	[max]="_dateDateNow">
																<mat-datepicker-toggle matSuffix
																	[for]="dpIncident"></mat-datepicker-toggle>
																<mat-datepicker #dpIncident startView="month"
																	panelClass="month-picker">
																</mat-datepicker>
															</mat-form-field>
															<div class="DivInputHorizontalContainer DivTimer">
																<select name="modelIncidentDatePrefix"
																	id="selectIncidentDatePrefix"
																	class="SelectTimePrefix"
																	[(ngModel)]="_stringTimePrefixPhase7">
																	<option
																		*ngFor="let stringTimeHours7 of getTimeHours()"
																		>{{ stringTimeHours7 }}
																	</option>
																</select>
																<select name="modelIncidentDateSuffix"
																	id="selectIncidentDateSuffix"
																	class="SelectTimeSuffix"
																	[(ngModel)]="_stringTimeSuffixPhase7">
																	<option
																		*ngFor="let stringTimeMinutes7 of getTimeMinutes()"
																		>{{ stringTimeMinutes7
																		}}</option>
																</select>
															</div>
															<input type="button" value="Ubah"
																(click)="callUpdateClaimProposalTrackingForPayment(modelClaimProposalTrackingPhaseSeven)">
														</div>
													</div>
												</li>
											</ng-container> -->

											<!-- <ng-container *ngIf="_booleanCanEditForTrackingClaimPhaseSix && _booleanStepperPhaseSix === false && _booleanTLO === true && _booleanHeadOffice">
												<li class="LiStepperHorizontalForChildNoLine LiStepperHorizontalForChildNoLineDisable">
													<div class="DivContainerSteperContent">
														<div class="DivTitleStepperContent">
															<label>-- / -- / ---- | -- : -- : -- | Bagian Keuangan Melakukan Pembayaran ke {{_stringWorkshop}}</label>
														</div>
													</div>
													<div class="DivContainerSteperContent">
														<div class="DivContainerDateTimePicker">
															<mat-form-field appearance="fill">
																<mat-label>Pilih Tanggal</mat-label>
																<input id="dateIncidentDate" matInput
																	[matDatepicker]="dpIncident"
																	(dateChange)="getActionDate($event)"
																	[max]="_dateDateNow">
																	<mat-datepicker-toggle matSuffix
																	[for]="dpIncident"></mat-datepicker-toggle>
																<mat-datepicker #dpIncident startView="month"
																	panelClass="month-picker">
																</mat-datepicker>
															</mat-form-field>
															<div class="DivInputHorizontalContainer DivTimer">
																<select name="modelIncidentDatePrefix"
																	id="selectIncidentDatePrefix"
																	class="SelectTimePrefix"
																	[(ngModel)]="_stringTimePrefixPhase6">
																	<option
																		*ngFor="let stringTimeHours6 of getTimeHours()"
																		>{{ stringTimeHours6 }}
																	</option>
																</select>
																<select name="modelIncidentDateSuffix"
																	id="selectIncidentDateSuffix"
																	class="SelectTimeSuffix"
																	[(ngModel)]="_stringTimeSuffixPhase6">
																	<option
																		*ngFor="let stringTimeMinutes6 of getTimeMinutes()"
																		>{{ stringTimeMinutes6
																		}}</option>
																</select>
															</div>
															<input type="button" value="simpan" *ngIf="_booleanTLO === true"
																(click)="callInsertClaimProposalTracking(this._enumClaimProposalTrackingPhase.PaymentWorkshop)">
														</div>
													</div>
												</li>
											</ng-container>
											<ng-container *ngIf="_booleanCanEditForTrackingClaimPhaseSeven && _booleanStepperPhaseSeven === false && _booleanTLO === false && _booleanHeadOffice">
												<li class="LiStepperHorizontalForChildNoLine LiStepperHorizontalForChildNoLineDisable">
													<div class="DivContainerSteperContent">
														<div class="DivTitleStepperContent">
															<label>-- / -- / ---- | -- : -- : -- | Bagian Keuangan Melakukan Pembayaran ke {{_stringWorkshop}}</label>
														</div>
													</div>
													<div class="DivContainerSteperContent">
														<div class="DivContainerDateTimePicker">
															<mat-form-field appearance="fill">
																<mat-label>Pilih Tanggal</mat-label>
																<input id="dateIncidentDate" matInput
																	[matDatepicker]="dpIncident"
																	(dateChange)="getActionDate($event)"
																	[max]="_dateDateNow">
																	<mat-datepicker-toggle matSuffix
																	[for]="dpIncident"></mat-datepicker-toggle>
																<mat-datepicker #dpIncident startView="month"
																	panelClass="month-picker">
																</mat-datepicker>
															</mat-form-field>
															<div class="DivInputHorizontalContainer DivTimer">
																<select name="modelIncidentDatePrefix"
																	id="selectIncidentDatePrefix"
																	class="SelectTimePrefix"
																	[(ngModel)]="_stringTimePrefixPhase7">
																	<option
																		*ngFor="let stringTimeHours7 of getTimeHours()"
																		>{{ stringTimeHours7 }}
																	</option>
																</select>
																<select name="modelIncidentDateSuffix"
																	id="selectIncidentDateSuffix"
																	class="SelectTimeSuffix"
																	[(ngModel)]="_stringTimeSuffixPhase7">
																	<option
																		*ngFor="let stringTimeMinutes7 of getTimeMinutes()"
																		>{{ stringTimeMinutes7
																		}}</option>
																</select>
															</div>
															<input type="button" value="simpan" *ngIf="_booleanTLO === false"
																(click)="callInsertClaimProposalTracking(this._enumClaimProposalTrackingPhase.PaymentInsured)">
														</div>
													</div>
												</li>
											</ng-container> -->
										</ul>
									</div>
								</div>
							</li>
							<li class="LiStepperHorizontalForParentNoLine" [ngClass]=" _booleanStepperPhaseSix === true || _booleanStepperPhaseSeven === true  ? 'LiStepperHorizontalForParentNoLine' : 'LiStepperHorizontalForParentNoLineDisable'">
								<div class="DivContainerContentStepper">
									<span>Selesai</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="DivButtonTracking">
					<input type="button" value="Ekspor PDF"
						(click)="callDownloadClaimProposalTrackingByClaimProposalTokenForClaim(this)">
				</div>
			</form>
			<!-- .CLAIM TRACKING END -->
		</div>
	</div>
</div>

//#region IMPORT

import { ENUM_BIDDING_TYPE, ENUM_CLAIMPAID_STATUS } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { SalvageGalleryModel } from "./salvagegallery.model";
import { SalvageItemModel } from "./salvageitem.model";

//#endregion


//#region CLASS

export class SalvageModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute update for salvage.
		Author: Ibrahim Aziz.
		Created on : Thursday, 10 August 2023. 				Updated on : Wednesday, 30 August 2023.
		Created by : Ibrahim Aiz.							Updated by : Ibrahim Aziz.
		Version : 1.0:3.
	*/

	ID?: number;
	ClaimPaidStatus?: ENUM_CLAIMPAID_STATUS;
	SalvageLocation?: string;
	BiddingType?: ENUM_BIDDING_TYPE;
	AdditionalCost?: number;
	ClaimPaidDate?: Date;
	Token?: string;
	TicketNumber?: string;
	PolicyNumber?: string;
	ClaimNumber?: string;
	arrayModelSalvageGallery?: Array<SalvageGalleryModel>;
	arrayModelSalvageItem?: Array<SalvageItemModel>;

	/* Attribute - END */


	//#region FUNCTION

	getArrayModelSalvageItemEstimatedSalvageValue(): number
	{
		if (this.arrayModelSalvageItem != null && this.arrayModelSalvageItem !== undefined)
		{
			let numberEsitmatedSalvageValue: number = 0;

			this.arrayModelSalvageItem.forEach((modelSalvageItem) =>
			{
				numberEsitmatedSalvageValue += (modelSalvageItem.EstimatedSalvageValue?.valueOf() as number) ?? (0 as number);
			});

			return numberEsitmatedSalvageValue;
		}
		else
		{
			return 0;
		}
	}

	//#endregion
}

//#endregion
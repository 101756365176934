//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { BeneficiariesPersonalAccidentModel } from "./beneficiariespersonalaccident.model";
import { ProductGeneralModel } from "./productgeneral.model";

//#endregion


//#region CLASS

export class ProductPersonalAccidentSyariahModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for product personal accident model, used for SPPA and policy.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;

	Disclaimer?: boolean;
	Note?: string;
	Token?: string;

	ReferenceNumber?: string;
	Status?: ENUM_SPPA_STATUS;

	/* POLICY DETAIL - END */

	/* INSURED - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;

	/* INSURED - END */

	/* INSURED - START */

	InsuredFirstName?: string;
	InsuredMiddleName?: string;
	InsuredLastName?: string;
	InsuredBirthDate?: Date;
	InsuredBirthPlace?: string;
	InsuredGender?: ENUM_GENDER_TYPE;
	InsuredTelephone?: string;
	InsuredMobilePhone?: string;
	InsuredEmail?: string;

	InsuredIdentificationNumber?: string;
	InsuredIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	InsuredAddress?: string;
	InsuredCountryID?: number;
	InsuredProvinceID?: number;
	InsuredCityID?: number;
	InsuredSubDistrictID?: number;
	InsuredVillageID?: number;
	InsuredHamletCode?: string;
	InsuredNeighbourhoodCode?: string;
	InsuredPostalID?: number;
	InsuredLatitude?: number;
	InsuredLongitude?: number;

	InsuredOccupationID?: number;
	InsuredOccupationName?: string;
	InsuredHobby?: string;
	InsuranceOther?: boolean;
	InsuranceRejected?: boolean;
	DangerousSport?: boolean;
	DisturbedSenses?: boolean;
	TreatmentOrSurgery?: boolean;

	/* INSURED - END */

	/* PREMIUM - START */

	PremiumTotalAmount?: number;

	/* PREMIUN - END */

	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	listModelBeneficiariesPersonalAccident?: Array<BeneficiariesPersonalAccidentModel>;
	listModelProductPersonalAccidentGallery?: Array<ProductBaseGalleryModel>;
	modelProductPersonalAccidentPayment?: ProductBaseGalleryModel;

	stringBirthDate?: string;
	/* NOT MAPPED - END */

	/* Attribute - END */
}

//#endregion
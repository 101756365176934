//#region IMPORT

import { ENUM_HTTP_STATUS, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { ResponseCodeConstant } from "../constants/responsecode.constant";
import { StringConstant } from "../constants/string.constant";
import { GeneralModel } from "./bases/general.model";
import { ClaimProposalModel } from "./claimproposal.model";
import { ClaimProposalSalvageSubmissionModel } from "./claimproposalsalvagesubmission.model";

//endregion


//region CLASS

export class ResponseModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for response model.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 Decemeber 2020.			    Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						    Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	HTTPResponseCode?: ENUM_HTTP_STATUS;
	ServiceResponseCode?: string;
	MessageTitle?: string;
	MessageContent?: string;
	Data?: string;
	State?: ENUM_RESPONSE_STATE;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.State = ENUM_RESPONSE_STATE.Nothing;
	}

	//#endregion


	//#region SETTER

	setServiceTooLarge(): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = ResponseCodeConstant.STRING_RESPONSECODE_CASE_GENERAL_SERVICETOOLARGE;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setSessionExpired(): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = ResponseCodeConstant.STRING_RESPONSECODE_CASE_GENERAL_SESSIONEXPIRED;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setWebsiteServiceException(stringMessageError: string): void
	{
		this.MessageTitle = ResponseCodeConstant.STRING_RESPONSECODE_MODULE_GENERAL;
		this.MessageContent = stringMessageError;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setForValidation(stringMessageTitle: string): void
	{
		this.MessageTitle = stringMessageTitle;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setForANONotFound(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_ANO_NOTFOUND;
		this.State = ENUM_RESPONSE_STATE.Fail;
	}

	setForResurveyDataSuccess(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_RESURVEY_SUCCESS;
	}

	setForResurveyDataFailed(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_RESURVEY_FAILED;
	}

	setForRejectDataSuccess(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_REJECT_SUCCESS;
	}

	setForRejectDataFailed(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_REJECT_FAILED;
	}

	setForApproveDataSuccess(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_APPROVE_SUCCESS;
	}

	setForVerifyDataSuccess(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_VERIFY_SUCCESS;
	}

	setForApproveDataFailed(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_APPROVE_FAILED;
	}

	setForInsertDataSuccess(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_INSERTDATA_SUCCESS;
	}

	setForInsertDataFailed(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_INSERTDATA_FAILED;
	}

	setForUpdateDataSuccess(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_UPDATEDATA_SUCCESS;
	}

	setForUpdateDataFailed(): void
	{
		this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
		this.MessageContent = StringConstant.STRING_MODULE_CLAIMPROPOSAL_UPDATEDATA_FAILED;
	}

	setForSubmitClaimProposal(stringTicketNumber: string): void
	{
		this.MessageContent = this.MessageContent + stringTicketNumber;
	}

	setReturnContentWithTicketNumberClaimProposalModel(): void
	{
		if (this.Data != null || this.Data !== undefined)
		{
			const modeClaimProposal: ClaimProposalModel = new ClaimProposalModel();

			modeClaimProposal.setModelFromString(this.Data);
			this.MessageTitle = StringConstant.STRING_MODULE_CLAIMPROPOSAL;
			this.MessageContent = this.MessageContent+" "+modeClaimProposal.TicketNumber;
		}
	}

	setReturnTicketNumberForClaimProposal(stringTicketNumber: string): void
	{
		if (stringTicketNumber != null || stringTicketNumber !== undefined)
		{
			this.MessageContent = this.MessageContent+" "+stringTicketNumber;
		}
	}

	setReturnNotFoundForPolicyGeneral(): void
	{
		this.MessageContent = StringConstant.STRING_MODULE_POLICYGENERAL_NOTFOUND;
	}

	setReturnContentWithTicketNumberSubmissionSalvageModel(): void
	{
		if (this.Data != null && this.Data !== undefined)
		{
			const modelClaimProposalSalvageSubmission: ClaimProposalSalvageSubmissionModel = new ClaimProposalSalvageSubmissionModel();

			modelClaimProposalSalvageSubmission.setModelFromString(this.Data);
			this.MessageTitle = StringConstant.STRING_SUBMISSION_SALVAGE;
			this.MessageContent = this.MessageContent+" "+modelClaimProposalSalvageSubmission.SubmissionNumber;
		}
	}

	setForInsertSupplier(): void
	{
		this.MessageTitle = StringConstant.STRING_SUPPLIER;
		this.MessageContent = StringConstant.STRING_SUBMITSUPPLIER_SUCCESS;
	}

	setForUpdateSupplier(): void
	{
		this.MessageTitle = StringConstant.STRING_SUPPLIER;
		this.MessageContent = StringConstant.STRING_UPDATESUPPLIER_SUCCESS;
	}

	setForInsertPartnershipWorkshop(): void
	{
		this.MessageTitle = StringConstant.STRING_PARTNERSHIPWORKSHOP;
		this.MessageContent = StringConstant.STRING_SUBMITPARTNERSHIPWORKSHOP_SUCCESS;
	}

	setForUpdatePartnershipWorkshop(): void
	{
		this.MessageTitle = StringConstant.STRING_PARTNERSHIPWORKSHOP;
		this.MessageContent = StringConstant.STRING_UPDATEPARTNERSHIPWORKSHOP_SUCCESS;
	}

	setForInsertKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Insert Key Performance Success";
	}

	setForUpdateKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Update Key Performance Success";
	}

	setForSubmitKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Submit Key Performance Success";
	}

	setForApproveKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Approval Key Performance Success";
	}

	setForRejectKeyPerformance(): void
	{
		this.MessageTitle = "Key Performance";
		this.MessageContent = "Reject Key Performance Success";
	}

	//#endregion
}

//#endregion
//#region ENVIRONMENT

export const environment =
{
	CHARACTER_SEPARATOR_PORT: ":",


	//#region SCHEMA

	SCHEMA_HTTP: "http://",
	SCHEMA_HTTPSECURE: "https://",
	SCHEMA_WEBSOCKET: "ws://",
	SCHEMA_WEBSOCKETSECURE: "wss://",

	//#endregion


	//#region HOST

	HOST_USER_API_DEVELOPMENT: "development.user.api.brinesia.app",
	HOST_PRODUCT_API_DEVELOPMENT: "development.product.api.brinesia.app",
	HOST_POLICY_API_DEVELOPMENT: "development.policy.api.brinesia.app",
	HOST_BROADCAST_API_DEVELOPMENT: "development.broadcast.api.brinesia.app",
	HOST_CLAIM_API_DEVELOPMENT: "development.claim.api.brinesia.app",
	HOST_INTERNAL_WEB_DEVELOPMENT: "development.klaim.web.brinesia.app",

	HOST_USER_API_STAGING: "staging.user.api.brinesia.app",
	HOST_PRODUCT_API_STAGING: "staging.product.api.brinesia.app",
	HOST_POLICY_API_STAGING: "staging.policy.api.brinesia.app",
	HOST_BRODCAST_API_STAGING: "staging.broadcast.api.brinesia.app",
	HOST_CLAIM_API_STAGING: "staging.claim.api.brinesia.app",
	HOST_INTERNAL_WEB_STAGING: "staging.klaim.web.brinesia.app",

	HOST_USER_API_PRODUCTION: "user.api.brinesia.app",
	HOST_PRODUCT_API_PRODUCTION: "product.api.brinesia.app",
	HOST_POLICY_API_PRODUCTION: "policy.api.brinesia.app",
	HOST_BROADCAST_API_PRODUCTION: "broadcast.api.brinesia.app",
	HOST_CLAIM_API_PRODUCTION: "claim.api.brinesia.app",
	HOST_INTERNAL_WEB_PRODUCTION: "klaim.web.brinesia.app",

	CONFIG_FIREBASE_ACCESSAPI: "AIzaSyDmLkI6uC481zad7xRgqcDGT9f3wP1CPDk",
	CONFIG_FIREBASE_AUTHDOMAIN: "brins-brinesia-widura.firebaseapp.com",
	CONFIG_FIREBASE_PROJECTID: "brins-brinesia-widura",
	CONFIG_FIREBASE_STORAGEBUCKET: "brins-brinesia-widura.appspot.com",
	CONFIG_FIREBASE_MESSAGINGSENDERID: "1081095509300",
	CONFIG_FIREBASE_APPID: "1:1081095509300:web:67d560c222fb39f4614161",
	CONFIG_FIREBASE_MEASUREMENTID: "G-Q1V7DWD879",
	CONFIG_FIREBASE_ACCESSVAPID: "BM55l9YhE6C4MUY3lyAmkFyFRfImo8Mg3Pfo9DrOg-Tnf1aaBBU0swgED6LGW1_-N0Zg6XWNdciQTmzw1HEPAO0",

	//#endregion


	//#region PORT

	PORT_DEVELOPMENT_PUBLIC: "443",
	PORT_PRODUCTION_PUBLIC: "443",
	PORT_STAGING_PUBLIC: "443",
	PORT_BROADCAST_PUBLIC: "443",
	PORT_CLAIM_PUBLIC: "443",
	PORT_INTERNALL_PUBLIC: "443",

	//#endregion
};

//#endregion
//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { GeneralModel } from "./bases/general.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { ProductGeneralModel } from "./productgeneral.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class ProductAsriModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for product asri model, used for SPPA and policy.
		Author: Ibrahim Aziz.
		Created on : Monday, 19 October 2020.			Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:6.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	CoverageCode?: string;
	CoverageName?: string;
	ReferenceNumber?: string;
	Policy?: string;

	Disclaimer?: boolean;
	SurveyAsriID?: number;
	Note?: string;
	Token?: string;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderBirthDate?: Date;

	/* POLICYHOLEDR - END */

	/* INSURED - START */

	BuildingAddress?: string;
	BuildingCountryID?: number;
	BuildingCountryName?: string;
	BuildingProvinceID?: number;
	BuildingProvinceName?: string;
	BuildingCityID?: number;
	BuildingCityName?: string;
	BuildingSubDistrictID?: number;
	BuildingVillageID?: number;
	BuildingHamletCode?: string;
	BuildingNeighbourhoodCode?: string;
	BuildingPostalID?: number;
	BuildingPostalName?: string;
	BuildingPostalCode?: string;
	BuildingLatitude?: number;
	BuildingLongitude?: number;

	BuildingPrice?: number;
	InteriorPrice?: number;
	numbereriorPrice?: number;
	BuildingNearMarket?: boolean;
	BuildingWorkPlace?: boolean;

	Construction?: string;
	RiskOccupation?: number;
	RiskCategory?: string;
	ZoneCategory?: string;
	FloodZone?: string;

	/* INSURED - END */

	/* PREMIUM - START */

	PremiumTotalAmount?: number;
	Status?: ENUM_SPPA_STATUS;

	/* PREMIUM - END */

	/* NOT MAPPED - START */

	modelProductGeneral?: ProductGeneralModel;
	listModelProductAsriGallery?: Array<ProductBaseGalleryModel>;
	modelProductAsriPayment?: ProductBaseGalleryModel;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region VALIDATE

	validateForCoverage(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.CoverageCode == null || this.CoverageCode === undefined || this.CoverageCode === "")
		{
			modelResponse.MessageContent = "Coverage can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateForBuildingPrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.BuildingPrice == null || this.BuildingPrice === undefined || this.BuildingPrice === 0)
		{
			modelResponse.MessageContent = "Building Price can't be empty.";
			return modelResponse;
		}
		else if (this.BuildingPrice > 3000000000)
		{
			modelResponse.MessageContent = "Building Price must be less 3 Billion";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateForInteriorPricePrice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.InteriorPrice == null || this.InteriorPrice === undefined || this.InteriorPrice === 0)
		{
			modelResponse.MessageContent = "Interior Price can't be empty.";
			return modelResponse;
		}
		else if (this.InteriorPrice < 100000)
		{
			modelResponse.MessageContent = "Interior Price must be less Thousand";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validateCheckForCalculatePremiumAsri(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateForCoverage();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateForBuildingPrice();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateForInteriorPricePrice();
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion

}

//#endregion
//#region IMPORT

import { BaseModel } from "./bases/base.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class ClaimProposalReportModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for dashboard claim proposal model, used for export data claim proposal.
		Author: Eka Saputra.
		Created on : Sunday, 20 March 2022. 				Updated on : Sunday, 20 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	TicketNumber?: string;
	PolicyNumber?: string;
	InsuredName?: string;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	VehicleLicensePlate?: string;
	IncidentChronology?: string;
	CreatedOn?: Date;
	SurveyDate1?: Date;
	SurveyDate2?: Date;
	SurveyDate3?: Date;
	SurveyNote?: string;

	SurveyReviewedOn?: Date;
	SurveyReviewedBy?: number;
	modelUserSurveyReviewed?: UserModel;
	SurveyReviewedNote?: string;

	CheckerHeadOfficeReviewedOn?: Date;
	CheckerHeadOfficeReviewedBy?: number;
	modelUserCheckerHeadOfficeReviewed?: UserModel;
	CheckerHeadOfficeReviewedNote?: string;

	SignerHeadOfficeReviewedOn?: Date;
	SignerHeadOfficeReviewedBy?: number;
	modelUserSignerHeadOfficeReviewed?: UserModel;
	SignerHeadOfficeReviewedNote?: string;

	CostEstimationAmount?: string;
	Deductible?: string;


	/* Attribute - END */
}

//#endregion
//#region CLASS

export class ChartItemSingleModel
{
	/* ATTRIBUTE - START */

	name?: string;
	value?: number;

	Name?: string;
	Value?: number;

	/* ATTRIBUTE - END */
}

//#endregion
//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class CommissionBiggerThanPremiumModel extends BaseModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyGeneralID?: number;
	ANO?: number;
	PolicyNumber?: string;
	CertificateNumber?: string;
	TransactionType?: string;
	ProductCode?: string;
	ProductName?: string;
	Premium?: number;
	Commission?: number;
	PremiumPaidDate?: Date;
	CommissionPaidDate?: Date;
	CreateUser?: string;
	CreateDate?: Date;
	InforceDate?: Date;
	InsuredObject?: string;
	Occupation?: string;
	Reinsurance?: number;
	ReinsurancePaidDate?: Date;
	TotalSumInsured?: number;
	BusinessSource?: string;
	RelationshipManager?: string;
	Information?: string;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion

	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
		// this.modelAuditFindingWorkPaper = undefined;
	}

	cleanList(): void
	{

	}

	//#endregion
}

//#endregion
//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ClaimProposalModel } from "../models/claimproposal.model";
import { UserModel } from "../models/user.model";
import { ClaimModel } from "../models/claim.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE
@Injectable
(
	{
		providedIn: "root"
	}
)

//#region CLASS

export class ClaimService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectClaimByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CLAIM_SELECTCLAIMBYEMAIL);
	}

	selectClaimByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_SELECTCLAIMBYATTRIBUTES);
	}

	selectClaimByToken(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_SELECTCLAIMBYTOKEN);
	}

	selectClaimByClaimNumber(interfaceGeneralService: GeneralServiceInterface, modelClaim: ClaimModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaim), WebAddressConstant.STRING_URL_CLAIM_SELECTCLAIMBYCLAIMNUMBER);
	}


	//#endregion


	//#region VERIFY and SIGNOUT

	verifyClaimByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CLAIM_VERIFYCLAIMBYEMAIL);
	}

	signOut(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_SIGNOUT);
	}

	//#endregion


	//#region CHECKER

	selectCheckerByBranchCode(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_NOTIFICATION_SELECTCHECKERBYBRANCHCODE);
	}

	selectClaimByProfileID(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_SELECTCLAIMBYPROFILEID);
	}

	//#endregion


	//#region SIGNER

	selectSignerByBranchCode(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_NOTIFICATION_SELECTSIGNERBYBRANCHCODE);
	}

	//#endregion
}

//#region CLASS

export class UserSurveyNetModel
{
	/*
		Attribute - START
		Description : Attribute for user surveynet model, used for package survey online.
		Author: Tri Aji Ramadhan and Ibrahim Aziz.
		Created on : Wednesday, 03 April 2024. 				Updated on : Wednesday, 03 April 2024.
		Created by : Tri Aji Ramadhan.						Updated by : Tri Aji Ramadhan.
		Version : 1.0:0.
	*/

	PhoneNumber?: string;
	Password?: string;
	UserName?: string;
	Email?: string;

	/* Attribute - END */
}

//#endregion
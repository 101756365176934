//#region IMPORT

import { ENUM_CLAIMITEM_ACTIONSTATUS, ENUM_CLAIMITEM_ACTIONTYPE, ENUM_CLAIMITEM_TYPE, ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class SurveyGalleryModel extends BaseModel
{
	ID?: number;
	SurveyID?: number;
	ClaimProposalID?: number;
	Token?: string;
	ProductOtoSyariahID?: number;
	Name?: string;
	URL?: string;
	Type?: ENUM_PHOTO_TYPE;
	Data?: string;
	Order?: number;
	Width?: number;
	Height?: number;
	Format?: string;
	Note?: string;
	Size?: number;
	Extension?: string;
	CostEstimationCurrency?: string;
	CostEstimationAmount?: number;
	ClaimItemType?: ENUM_CLAIMITEM_TYPE;
	SalvageEstimationAmount?: number;
	ActionType?: ENUM_CLAIMITEM_ACTIONTYPE;
	ActionStatus?: ENUM_CLAIMITEM_ACTIONSTATUS;
	ActionBy?: number;
	ActionOn?: Date;
	ItemNumber?: number;

	constructor()
	{
    	super();
	}

	//#region FUNCTION

	validateItemsSurvey(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Survey Gallery";

		if(this.Token === undefined || this.Token === null || this.Token === "")
		{
			modelResponse.MessageContent = "Token can't be empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else if(this.ActionStatus === undefined || this.ActionStatus === null)
		{
			modelResponse.MessageContent = "Action Status can't be empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	//#endregion


}

//#endregion
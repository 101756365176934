<div id="divBodyClaimProposal" class="DivBodyGeneral">
	<header class="HeaderGeneral">
		<h2>Salvage - List</h2>
		<p>All list of salvage</p>
	</header>

	<div id="divMainClaimProposalListMonitoring" class="DivMainAsideSearch">
		<form>
			<div class="DivForm">
				<h3>Search</h3>
				<fieldset>
					<label for="textClaimNumber">Ticket number</label>
					<div class="DivInputHorizontalContainer">
						<label class="LabelPrefix" for="textClaimNumber">BNCL-</label>
						<input type="text" name="modelClaimProposalTicketNumber" id="textClaimNumber" placeholder="input claim number" [(ngModel)]="_modelClaimProposal.TicketNumber">
					</div>

					<label for="textClaimNumber">Claim number</label>
					<input type="text" name="modelClaimProposalClaimNumber" id="textClaimNumber" placeholder="input claim number" [(ngModel)]="_modelClaimProposal.ClaimNumber">

					<label for="numberPolicyNumber">Policy number</label>
					<input type="text" name="modelClaimProposalPolicyNumber" id="numberPolicyNumber" placeholder="input policy number" [(ngModel)]="_modelClaimProposal.PolicyNumber">

					<label for="textInsuredName">Insured name</label>
					<input type="text" name="modelClaimProposalReporterFirstName" id="textInsuredName" placeholder="input Name" [(ngModel)]="_modelClaimProposal.InsuredName">

					<label for="dateOfLoss">Date of loss</label>
					<mat-form-field appearance="fill">
						<mat-label>Date</mat-label>
						<input matInput [matDatepicker]="dpDateOfLoss" [value]="_modelClaimProposal.IncidentDate" (dateChange)="getStartDateLoss($event)">
						<mat-datepicker-toggle matSuffix [for]="dpDateOfLoss"></mat-datepicker-toggle>
						<mat-datepicker #dpDateOfLoss startView="month" panelClass="month-picker">
						</mat-datepicker>
					</mat-form-field>

					<label for="selectStatus">Status</label>
					<select name="selectStatus" id="selectStatus" [(ngModel)]="_modelClaimProposal.Status">
						<option [ngValue]="null">ALL</option>
						<option [ngValue]="_enumClaimProposalStatus.Verified">Found | Pending Verification Branch</option>
						<option [ngValue]="_enumClaimProposalStatus.NotVerified">Not Found | Pending Verification {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.RejectedByBranch">Rejected By Branch Branch</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForHeadOffice">Pending Verification Adjustment {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForCheckerHeadOffice">Pending Verification Supervisor {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForSignerHeadOffice">Pending Verification Manager {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForApprove">Pending For Approve Deputy Branch Head</option>
						<option [ngValue]="_enumClaimProposalStatus.RejectedByHeadOffice">Rejected By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForSurvey">Pending For Survey Branch</option>
						<option [ngValue]="_enumClaimProposalStatus.CompletedWithSurvey">Waiting For Approval By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.CompletedWithoutSurvey">Waiting For Approval By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.Approved">Approved By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.Rejected">Rejected By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForCorrection">Pending For Correction</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForCorrectionChecker">Pending For Correction Checker</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForCorrectionSigner">Pending For Correction Signer</option>
						<option [ngValue]="_enumClaimProposalStatus.Submitted">Submitted {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
					</select>

					<label for="selectBranch">Branch</label>
					<select name="selectBranch" id="selectBranch" [(ngModel)]="_modelClaimProposal.BranchCode">
						<option [ngValue]="null">ALL</option>
						<option [ngValue]="_enumClaimProposalStatus.Verified">Found | Pending Verification Branch</option>
						<option [ngValue]="_enumClaimProposalStatus.NotVerified">Not Found | Pending Verification {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.RejectedByBranch">Rejected By Branch Branch</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForHeadOffice">Pending Verification Adjustment {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForCheckerHeadOffice">Pending Verification Supervisor {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForSignerHeadOffice">Pending Verification Manager {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForApprove">Pending For Approve Deputy Branch Head</option>
						<option [ngValue]="_enumClaimProposalStatus.RejectedByHeadOffice">Rejected By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForSurvey">Pending For Survey Branch</option>
						<option [ngValue]="_enumClaimProposalStatus.CompletedWithSurvey">Waiting For Approval By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.CompletedWithoutSurvey">Waiting For Approval By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.Approved">Approved By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.Rejected">Rejected By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForCorrection">Pending For Correction</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForCorrectionChecker">Pending For Correction Checker</option>
						<option [ngValue]="_enumClaimProposalStatus.PendingForCorrectionSigner">Pending For Correction Signer</option>
						<option [ngValue]="_enumClaimProposalStatus.Submitted">Submitted {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</option>
					</select>
				</fieldset>
			</div>
			<input type="button" name="buttonSelectClaimProposal" value="Search" (click)="callSelectSalvage(this)">
		</form>

		<div class="DivTableContainer">
			<app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>

			<table>
				<thead>
					<tr>
						<th>No</th>
						<th>Ticket number</th>
						<th>Claim number</th>
						<th>Name</th>
						<th>Product name</th>
						<th>Bidding type</th>
						<th>Status</th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let modelClaimProposal of _arrayModelClaimProposal" (click)="goToDetail(modelClaimProposal.Token)">
						<td>1</td>
						<td>{{ modelClaimProposal.TicketNumber }}</td>
						<td>{{ modelClaimProposal.ClaimNumber }}</td>
						<td>{{ modelClaimProposal.ReporterFirstName }} {{ modelClaimProposal.ReporterMiddleName }} {{ modelClaimProposal.ReporterLastName }}</td>
						<td>{{ modelClaimProposal.ProductName }}</td>
						<td>{{ modelClaimProposal.BiddingType }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.Verified">Found | Pending Verification {{ modelClaimProposal.BranchName }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.NotVerified">Not Found | Pending Verification {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.RejectedByBranch">Rejected By Branch {{ modelClaimProposal.BranchName }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.PendingForHeadOffice">Pending Verification Adjustment {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.PendingForCheckerHeadOffice">Pending Verification Supervisor {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.PendingForSignerHeadOffice">Pending Verification Manager {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.RejectedByHeadOffice">Rejected By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.PendingForSurvey">Pending For Survey {{ modelClaimProposal.SurveyNearestBranchName }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.CompletedWithSurvey">Waiting For Approval By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.CompletedWithoutSurvey">Waiting For Approval By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.Approved">Approved By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.Rejected">Rejected By {{ _stringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.Direct">Direct to Mantle</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.PendingForApprove">Pending For Approve Deputy Branch Head</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.Submitted">Submitted</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.PendingForCorrection">Pending For Correction {{ modelClaimProposal.BranchName }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.PendingForCorrectionChecker">Pending For Correction Checker {{ modelClaimProposal.BranchName }}</td>
						<td *ngIf="modelClaimProposal.Status === _enumClaimProposalStatus.PendingForCorrectionSigner">Pending For Correction Signer {{ modelClaimProposal.BranchName }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

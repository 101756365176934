<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>About</h2>
		<p>Find out about Bisnis Website and how to get help</p>
    </header>

	<div id="divMainDashboard">
		<div class="DivContainer">
			<form>
				<div class="DivForm">
					<h3>KLAIM</h3>

					<img id="imageLogoAbout" src="../../assets/logos/sakina/logo_launcher_claim.svg" alt="BRI Insurance - Logo Header">

					<label >Version</label>
					<label>{{ _enumStringConstant.STRING_VALUE_APPLICATION_VERSION }} | {{ _environmentBuild }}</label>

					<label>Description</label>
					<label>BRINESIA Claim is an website based application that is part of BRINESIA (BRI Insurance Enterprise Architecture). This website for the process of submitting Motor vehicle (MV) claims,  verification process, document validation and checking vehicle damage.</label>

					<label>Help</label>
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_FIELD_EMAIL_TSI_BRINS }}" (click)="selectByEmailTSI()">

					<h3>Contact</h3>
					<p>PT. BRI Asuransi Indonesia</p>
					<label>Jl. Mampang Prapatan Raya No.18 South Jakarta 12790, Indonesia</label>

					<label>Telephone</label>
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA + _enumStringConstant.STRING_VALUE_TELEPHONE_BRINS_PRIMARY }}" (click)="selectByTelephonePrimary()">
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA + _enumStringConstant.STRING_VALUE_TELEPHONE_BRINS_SECONDARY }}" (click)="selectByTelephoneSecondary()">

					<label for>Email</label>
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_FIELD_EMAIL_INFO_BRINS }}" (click)="selectByEmailInfo()">

					<label for>Call Center</label>
					<input type="button" class="inputAbout" value="{{ _enumStringConstant.STRING_VALUE_TELEPHONE_CALLCENTER }}" (click)="selectByCallCenter()">
				</div>
			</form>
		</div>
	</div>
</div>

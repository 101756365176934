//#region IMPORT

import { ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ResponseModel } from "../response.model";
import { BaseModel } from "./base.model";

//#endregion


//#region CLASS

export class BaseGalleryModel extends BaseModel
{
	/*
        Attributes
        Description: Attributes for BaseGallery model
        Author: Andrew
        Created on: 20220511            Updated on: 20220511
        Created by: Andrew              Updated by: Andrew
        Version: 1.0:1
    */

	Name?: string;
	URL?: string;
	Type?: ENUM_PHOTO_TYPE;
	Order?: number;
	Width?: number;
	Height?: number;
	Format?: string;
	Extension?: string;
	Note?: string;

	Latitude?: number;
	Longitude?: number;

	/* Attribute - END */


	//#region VALIDATION

	validationDocument(): ResponseModel

	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Upload Document");

		if(this.validateEmpty(this.Size))
		{
			modelResponse.MessageContent = "Document size cannot be empty.";
		}
		else if(this.Size && this.Size >= 20000000)
		{
			modelResponse.MessageContent = "Document size cannot be higher than 20mb.";
		}
		else if(this.validateEmpty(this.Name))
		{
			modelResponse.MessageContent = "Document nam cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion
/* eslint-disable @typescript-eslint/no-non-null-assertion */
//#region IMPORT

import ArrayBranchWithoutSurveyData from "src/assets/json/branchwithoutsurvey.json";
import { ENUM_CLAIMITEM_ACTIONSTATUS, ENUM_CLAIMITEM_ACTIONTYPE, ENUM_CLAIMPROPOSAL_STATUS, ENUM_CLAIMPROPOSAL_STATUS_ADJUSTER, ENUM_CLAIMPROPOSAL_STATUS_NOTIFICATIONLOSSADVICE, ENUM_CLAIM_CHANNEL, ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_INCIDENT_TYPE, ENUM_PHOTO_TYPE, ENUM_POLICY_STATUS, ENUM_POLICY_TYPE, ENUM_RESPONSE_STATE, ENUM_REVIEW_STATE, ENUM_SURVEY_STATUS } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ClaimProposalAdjusterDocumentModel } from "./claimproposaladjusterdocument.model";
import { ClaimProposalAppealModel } from "./claimproposalappeal.model";
import { ClaimProposalGalleryModel } from "./claimproposalgallery.model";
import { ClaimProposalItemLossModel } from "./claimproposalitemoss.model";
import { ClaimProposalNotificationModel } from "./claimproposalnotification.model";
import { ClaimProposalSupportingGalleryModel } from "./claimproposalsupportinggallery.model";
import { CurrencyModel } from "./currency.model";
import { ResponseModel } from "./response.model";
import { SurveyModel } from "./survey.model";
import { SurveyThirdPartyLiabilityModel } from "./surveythirdpartyliability.model";
import { UserModel } from "./user.model";
import { ClaimProposalSatisfactionModel } from "./claimproposalsatisfaction.model";
import { ClaimProposalQualityControlModel } from "./claimproposalqualitycontrol.model";
import { SurveyGalleryModel } from "./surveygallery.model";
import { SurveyThirdPartyLiabilityGalleryModel } from "./surveythirdpartyliabilitygallery.model";

//#endregion


//#region CLASS

export class ClaimProposalModel extends BaseModel
{

	ID?: number;
	BranchCreatorCode?: string;
	BranchCreatorName?: string;
	BranchCode?: string;
	BranchName?: string;
	CertificateNumber?: string;
	TicketNumber?: string;
	Token?: string;
	PolicyNumber?: string;
	ANO?: string;
	ReferenceNumber?: string;
	PolicyStatus?: ENUM_POLICY_STATUS;
	ClaimStatus?: ENUM_CLAIMPROPOSAL_STATUS;
	Channel?: ENUM_CLAIM_CHANNEL;
	LicensePlatePrefixCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;
	ChasisNumber?: string;
	Email?: string;
	ProductCode?: string;
	ProductName?: string;
	Currency?: string;
	Amount?: number;
	AdjusterAmount?: number;
	IsSatisfactionSurvey?: boolean;
	isQualityControl?: boolean;
	PolicyPrivilegeType?: string;
	SurveyReviewedBy?: number;
	ClaimNumber?: string;

	ClaimRegisterNumber?: string;

	DriverName?: string;
	DriverLicenseNumber?: string;

	ReporterName?: string;
	ReportedOn?: Date;

	IncidentCauseOfLoss?: string;
	IncidentDate?: Date;
	IncidentDamageType?: string;
	IncidentDamageDescription?: string;
	IncidentDetailLocation?: string;
	IncidentType?: ENUM_INCIDENT_TYPE;
	IncidentChronology?: string;
	IncidentNote?: string;
	IncidentLocation?: string;
	IncidentLocationCode?: string;
	IncidentLongitude?: number;
	IncidentLatitude?: number;
	IncidentTime?: string;

	VehicleSpeed?: number;
	VehicleLocation?: string;
	VehicleLocationName?: string;
	VehicleYear?: string;
	InsuredLocation?: string;
	InsuredName?: string;

	VehicleBrandID?: number;
	VehicleBrandCode?: number;
	VehicleBrandName?: string;

	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: string;

	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;
	VehicleChasisNumber?: string;

	InsuredSurveyLocation?: string;
	InsuredVehicleChasisNumber?: string;
	InsuredLicensePlate?: string;

	LegalResponsibility?: boolean;
	PersonalAccidentPassenger?: boolean;
	PersonalAccidentDriver?: boolean;

	Status?: ENUM_CLAIMPROPOSAL_STATUS;
	VIPStatus?: boolean;

	PolicyActiveStatus?: boolean;
	PolicyPeriodeStatus?: boolean;
	PolicyPaymentStatus?: boolean;
	PolicyGracePeriodeStatus?: boolean;
	NotifyToHeadOffice?: boolean;
	Adjuster?: boolean;

	modelUserSubmit?: UserModel;
	modelUserConfirmationBranch?: UserModel;
	modelUserSubmitSurvey?: UserModel;

	BranchReviewedNote?: string;
	BranchReviewedBy?: number;
	BranchReviewedOn?: string;
	BranchReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserBranchReviewed?: UserModel;

	MakerHeadOfficeReviewedNote?: string;
	MakerHeadOfficeReviewedBy?: number;
	MakerHeadOfficeReviewedOn?: string;
	MakerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserMakerHeadOfficeReviewed?: UserModel;

	CheckerHeadOfficeReviewedNote?: string;
	CheckerHeadOfficeReviewedBy?: number;
	CheckerHeadOfficeReviewedOn?: string;
	CheckerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerHeadOfficeReviewed?: UserModel;

	HeadOfficeReviewedNote?: string;
	HeadOfficeReviewedBy?: number;
	HeadOfficeReviewedOn?: string;
	HeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserHeadOfficeReviewed?: UserModel;

	SignerHeadOfficeReviewedNote?: string;
	SignerHeadOfficeReviewedBy?: number;
	SignerHeadOfficeReviewedOn?: string;
	SignerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserSigner?: UserModel;
	modelUserSignerHeadOfficeReviewed?: UserModel;

	ReporterSameAsPolicyholder?: boolean;
	InsuredLocationSameAsPolicyholder?: boolean;
	DriverSameAsPolicyholder?: boolean;

	AdjusterStatus?: ENUM_CLAIMPROPOSAL_STATUS_ADJUSTER;
	NotificationLossAdviceStatus?: ENUM_CLAIMPROPOSAL_STATUS_NOTIFICATIONLOSSADVICE;

	listModelClaimProposalNotification?: Array<ClaimProposalNotificationModel>;
	listModelClaimProposalSatisfaction?: Array<ClaimProposalSatisfactionModel>;

	//#region CORRECTION

	MakerBranchCorrectionNote?: string;
	MakerBranchCorrectionBy?: number;
	MakerBranchCorrectionOn?: Date;
	MakerBranchCorrectionStatus?: ENUM_REVIEW_STATE;
	modelUserMakerBranchCorrection?: UserModel;

	CheckerBranchCorrectionNote?: string;
	CheckerBranchCorrectionBy?: number;
	CheckerBranchCorrectionOn?: Date;
	CheckerBranchCorrectionStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerBranchCorrection?: UserModel;

	SignerBranchCorrectionNote?: string;
	SignerBranchCorrectionBy?: number;
	SignerBranchCorrectionOn?: Date;
	SignerBranchCorrectionStatus?: ENUM_REVIEW_STATE;
	modelUserSignerBranchCorrection?: UserModel;

	//#endregion


	//#region ADJUSTER

	MakerBranchAdjusterNote?: string;
	MakerBranchAdjusterBy?: number;
	MakerBranchAdjusterOn?: Date;
	MakerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserMakerBranchAdjuster?: UserModel;

	MakerHeadOfficeAdjusterBy?: number;
	MakerHeadOfficeAdjusterOn?: Date;
	modelUserMakerHeadOfficeAdjuster?: UserModel;


	CheckerBranchAdjusterNote?: string;
	CheckerBranchAdjusterBy?: number;
	CheckerBranchAdjusterOn?: Date;
	CheckerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerBranchAdjuster?: UserModel;

	SignerBranchAdjusterNote?: string;
	SignerBranchAdjusterBy?: number;
	SignerBranchAdjusterOn?: Date;
	SignerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserSignerBranchAdjuster?: UserModel;

	//#endregion

	//#region REPORTER

	ReporterFirstName?: string;
	ReporterMiddleName?: string;
	ReporterLastName?: string;
	ReporterRelationship?: string;

	ReporterAddress?: string;
	ReporterLocation?: string;
	ReporterCountryID?: number;
	ReporterCountryCode?: string;
	ReporterCountryName?: string;
	ReporterCountryNameGeolocation?: string;
	ReporterProvinceID?: number;
	ReporterProvinceCode?: string;
	ReporterProvinceName?: string;
	ReporterProvinceNameGeolocation?: string;
	ReporterCityID?: number;
	ReporterCityCode?: string;
	ReporterCityName?: string;
	ReporterCityNameGeolocation?: string;
	ReporterSubDistrictID?: number;
	ReporterSubDistrictName?: string;
	ReporterSubDistrictCode?: string;
	ReporterSubDistrictNameGeolocation?: string;
	ReporterVillageID?: number;
	ReporterVillageCode?: string;
	ReporterVillageName?: string;
	ReporterVillageNameGeolocation?: string;
	ReporterHamletCode?: string;
	ReporterNeighbourhoodCode?: string;
	ReporterPostalID?: number;
	ReporterPostalCode?: string;
	ReporterPostalName?: string;
	ReporterPostalCodeGeolocation?: string;
	ReporterLongitude?: string;
	ReporterLatitude?: string;

	ReporterTelephone?: string;
	ReporterFax?: string;
	ReporterMobilePhonePrefix?: string;
	ReporterMobilePhone?: string;
	ReporterMobilePhoneSecondary?: string;
	ReporterEmail?: string;
	ReporterEmailSecondary?: string;

	Note?: string;

	//#endregion REPORTER


	//#region SURVEY

	SurveyAddress?: string;
	SurveyApprovalNote?: string;
	SurveyAddressSameAsPolicyholder?: boolean;
	SurveyDate?: Date;
	SurveyCountryID?: number;
	SurveyCountryCode?: string;
	SurveyCountryName?: string;
	SurveyCountryNameGeolocation?: string;
	SurveyLocation?: string;
	SurveyLongitude?: string;
	SurveyLatitude?: string;
	SurveyProvinceID?: number;
	SurveyProvinceCode?: string;
	SurveyProvinceName?: string;
	SurveyProvinceNameGeolocation?: string;
	SurveyCityID?: number;
	SurveyCityCode?: string;
	SurveyCityName?: string;
	SurveyCityNameGeolocation?: string;
	SurveySubDistrictID?: number;
	SurveySubDistrictName?: string;
	SurveySubDistrictCode?: string;
	SurveySubDistrictNameGeolocation?: string;
	SurveyVillageID?: number;
	SurveyVillageCode?: string;
	SurveyVillageName?: string;
	SurveyVillageNameGeolocation?: string;
	SurveyHamletCode?: string;
	SurveyNeighbourhoodCode?: string;
	SurveyPostalID?: number;
	SurveyPostalCode?: string;
	SurveyPostalName?: string;
	SurveyPostalCodeGeolocation?: string;
	SurveyNote?: string;
	SurveyReviewedOn?: string;

	SurveyNearestBranchCode?: string;
	SurveyNearestBranchName?: string;

	//#endregion SURVEY


	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderFullName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;
	PolicyholderIdentificationNumber?: number;

	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	PolicyPaymentDate?: Date;
	HistoryPolicy?: string;
	PremiumAmount?: number;

	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderCountryCode?: number;
	PolicyholderCountryName?: number;
	PolicyholderProvinceID?: number;
	PolicyholderProvinceCode?: number;
	PolicyholderProvinceName?: number;
	PolicyholderCityID?: number;
	PolicyholderCityCode?: number;
	PolicyholderCityName?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: number;
	PolicyholderPostalName?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	/* POLICYHOLDER - END */


	/* EMAIL NON MOTORVEHICLE - START */

	listEmailNotification?: Array<string>;
	EmailSubject?: string;
	EmailTitle?: string;
	NotificationLossAdvanceNumber?: string;
	Reserve?: string;
	ShareInLoss?: string;
	SumInsured?: number;
	Interest?: string;
	Remarks?: string;

	/* EMAIL NON MOTORVEHICLE - END */


	/* MONITORING EXCEL - START */

	SurveyDateFirst?: Date;
	SurveyDateSecond?: Date;
	SurveyDateThird?: Date;
	SumCostEstimationAmount?: number;
	SumSalvageEstimationAmount?: number;
	TotalCost?: number;
	ReportedStartDate?: Date;
	ReportedEndDate?: Date;

	/* MONITORING EXCEL - END */


	//#region MODEL

	modelUser?: UserModel;
	modelClaimProposalAppeal?: ClaimProposalAppealModel;
	modelCurrency?: CurrencyModel;
	modelClaimProposalItemLoss?: ClaimProposalItemLossModel;
	modelSurvey?: SurveyModel;
	modelClaimProposalSatisfaction?: ClaimProposalSatisfactionModel;


	//#endregion


	//#region LIST MODEL

	listModelClaimProposalGallery?: Array<ClaimProposalGalleryModel>;
	listModelClaimProposalSupportingGallery?: Array<ClaimProposalSupportingGalleryModel>;
	listModelClaimProposalAppeal?: Array<ClaimProposalAppealModel>;
	listModelSurvey?: Array<SurveyModel>;
	listModelClaimProposalItemLoss?: Array<ClaimProposalItemLossModel>;
	listModelSurveyThirdPartyLiability?: Array<SurveyThirdPartyLiabilityModel>;
	listModelClaimProposalAdjusterDocument?: Array<ClaimProposalAdjusterDocumentModel>;
	listModelClaimProposalQualityControl?: Array<ClaimProposalQualityControlModel>;

	//#endregion

	//#region CHECKER SIGNER

	FirstCheckerBy?: number;
	FirstCheckerOn?: Date;
	FirstCheckerURL?: string;
	SecondCheckerBy?: number;
	SecondCheckerOn?: Date;
	SecondCheckerURL?: string;
	FirstSignerBy?: number;
	FirstSignerOn?: Date;
	FirstSignerURL?: string;
	SecondSignerBy?: number;
	SecondSignerOn?: Date;
	SecondSignerURL?: string;
	modelUserChecker?: UserModel;

	//#endregion


	constructor()
	{
		super();
		this.listModelClaimProposalGallery = [];
		this.listModelClaimProposalSupportingGallery = [];
		this.listModelClaimProposalAppeal = [];
		this.listModelSurvey = [];
		this.listModelClaimProposalItemLoss = [];
		this.listModelSurveyThirdPartyLiability = [];
		this.listModelClaimProposalQualityControl = [];
	}

	//#region GETTER

	setInsuredLicensePlate(stringInsuredLicensePlate: string): void
	{

		if(stringInsuredLicensePlate === null || stringInsuredLicensePlate === undefined || stringInsuredLicensePlate === "")
		{

		}
		else if(stringInsuredLicensePlate === StringConstant.STRING_LICENSEPLATE_TBA)
		{
			this.LicensePlatePrefixCode = stringInsuredLicensePlate;
		}
		else
		{
			const arrayInsuredLicensePlate = stringInsuredLicensePlate.match(/[^\d]+|\d+/g) || Array<string>();
			if(arrayInsuredLicensePlate[0])
			{
				this.LicensePlatePrefixCode = arrayInsuredLicensePlate[0];
			}
			else
			{
			}

			if(arrayInsuredLicensePlate[1])
			{
				this.LicensePlateInfix = arrayInsuredLicensePlate[1];
			}
			else
			{
			}

			if(arrayInsuredLicensePlate[2])
			{
				this.LicensePlateSuffix = arrayInsuredLicensePlate[2];
			}
			else
			{
			}
		}

	}

	//#endregion


	//#region GETTER

	setModelUser(): void
	{
		const stringModelUser: string = JSON.stringify(this.modelUser);
		this.modelUser = new UserModel();
		this.modelUser.setModelFromString(stringModelUser);
	}

	//#endregion


	//#region FUNCTION

	getFullName(): string
	{

		let stringName: string = "";

		if (this.ReporterFirstName == null || this.ReporterFirstName === undefined || this.ReporterFirstName === "")
		{
			stringName += StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_EMPTY + this.ReporterFirstName;
		}

		if (this.ReporterMiddleName == null || this.ReporterMiddleName === undefined || this.ReporterMiddleName === "")
		{

		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_EMPTY + this.ReporterMiddleName;
		}

		if (this.ReporterLastName == null || this.ReporterLastName === undefined || this.ReporterLastName === "")
		{

		}
		else
		{
			stringName += StringConstant.STRING_CHARACTER_EMPTY + this.ReporterLastName;
		}

		return stringName;
	}

	validateDataMV(): ResponseModel
	{
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (
			(this.PolicyNumber == null || this.PolicyholderEmail === null || this.PolicyNumber === "") &&
			(
				(this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === "") &&
				(this.LicensePlateInfix == null || this.LicensePlateInfix === null || this.LicensePlateInfix === "") &&
				(this.LicensePlateSuffix == null || this.LicensePlateSuffix === null || this.LicensePlateSuffix === "")
			)
		)
		{
			modelResponse.MessageContent = "Please fill in the policy number or license plate.";
		}
		else if (this.ReporterFirstName == null || this.ReporterFirstName === undefined || this.ReporterFirstName === "")
		{
			modelResponse.MessageContent = "Reporter\'s first name cannot be empty.";
		}
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterFirstName == null || this.ReporterFirstName === undefined || this.ReporterFirstName === ""))
		{
			modelResponse.MessageContent = "Reporter\'s first name is in incorrect format.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterFirstName?.length > RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name maximum contain "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM+" characters.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterFirstName?.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name minimum contain "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM+" characters.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterMiddleName!) && (this.ReporterMiddleName != null || this.ReporterMiddleName !== undefined || this.ReporterMiddleName !== "") && !regExAphabetSpace.test(this.ReporterMiddleName))
		{
			modelResponse.MessageContent = "Reporter\'s middle name is in incorrect format.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterLastName!) && (this.ReporterLastName != null || this.ReporterLastName !== undefined || this.ReporterLastName !== "" ) && !regExAphabetSpace.test(this.ReporterLastName))
		{
			modelResponse.MessageContent = "Reporter\'s last name is in incorrect format.";
		}
		else if (!this.ReporterSameAsPolicyholder && (this.ReporterMobilePhonePrefix === null || this.ReporterMobilePhonePrefix === undefined || this.ReporterMobilePhonePrefix === ""))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone prefix cannot be empty.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterMobilePhone === null || this.ReporterMobilePhone === undefined || this.ReporterMobilePhone === "")
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone cannot be empty.";
		}
		else if (!this.ReporterSameAsPolicyholder && !regExNumeric.test(this.ReporterMobilePhone))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone can only contain numeric.";
		}
		else if ((this.ReporterMobilePhone.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone minimum contain "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM+" characters.";
		}
		else if ((this.ReporterMobilePhone.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone maximum contain "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM+" characters.";
		}
		else if (this.ReporterRelationship == null || this.ReporterRelationship === undefined)
		{
			modelResponse.MessageContent = "Reporter\'s relationship can not be empty.";
		}
		else if (this.ReporterAddress == null || this.ReporterAddress === undefined || this.ReporterAddress === "")
		{
			modelResponse.MessageContent = "Reporter\s address can not be empty.";
		}
		else if (this.ReporterAddress.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\s address minimum "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM+" digits characters.";
		}
		else if(this.checkBranchWithoutSurvey(this.BranchCode!, this.SurveyNearestBranchCode!) && (this.modelSurvey?.INO == null || this.modelSurvey?.INO === undefined))
		{
			modelResponse.MessageContent = "Claim Type can not be empty.";
		}
		else if (this.IncidentType == null || this.IncidentType === undefined)
		{
			modelResponse.MessageContent = "Accident type can not be empty.";
		}
		else if (this.IncidentLocation == null || this.IncidentLocation === undefined || this.IncidentLocation === "")
		{
			modelResponse.MessageContent = "Accident Address can not be empty.";
		}
		else if (this.IncidentChronology == null || this.IncidentChronology === undefined || this.IncidentChronology === "")
		{
			modelResponse.MessageContent = "Accident chronology can not be empty.";
		}
		else if (this.IncidentChronology.length < RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Accident chronology minimum "+RuleConstant.NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM+" digits characters.";
		}
		else if (this.IncidentDamageType == null || this.IncidentDamageType === undefined || this.IncidentDamageType === "")
		{
			modelResponse.MessageContent = "Damage type can not be empty.";
		}
		else if (this.IncidentCauseOfLoss == null || this.IncidentCauseOfLoss === undefined || this.IncidentCauseOfLoss === "")
		{
			modelResponse.MessageContent = "Damage cause of loss can not be empty.";
		}
		else if (this.IncidentDate == null || this.IncidentDate === undefined)
		{
			modelResponse.MessageContent = "Accident date can not be empty.";
		}
		else if (this.IncidentTime == null || this.IncidentTime === undefined || this.IncidentTime === "")
		{
			modelResponse.MessageContent = "Accident time can not be empty.";
		}
		else if (this.LegalResponsibility == null || this.LegalResponsibility === undefined)
		{
			modelResponse.MessageContent = "TPL can not be empty.";
		}
		else if (this.SurveyNearestBranchName == null || this.SurveyNearestBranchName === undefined || this.SurveyNearestBranchName === "")
		{
			modelResponse.MessageContent = "Survey Branch name can not be empty.";
		}
		else if (this.SurveyNearestBranchCode == null || this.SurveyNearestBranchCode === undefined || this.SurveyNearestBranchCode === "")
		{
			modelResponse.MessageContent = "Survey Branch can not be empty.";
		}
		else if (this.SurveyAddress == null || this.SurveyAddress === undefined || this.SurveyAddress === "")
		{
			modelResponse.MessageContent = "Survey Address can not be empty.";
		}
		else if (this.SurveyDate == null || this.SurveyDate === undefined)
		{
			modelResponse.MessageContent = "Survey date can not be empty.";
		}
		else if ((!this.DriverSameAsPolicyholder) && (this.DriverName == null || this.DriverName === undefined || this.DriverName === "") && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver name cannot be empty.";
		}
		else if ((!this.DriverSameAsPolicyholder) && (this.DriverName) && (!regExAphabetSpace.test(this.DriverName)) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver name can only contain alphabet.";
		}
		else if((!this.DriverSameAsPolicyholder) && (this.DriverName) && (this.DriverName.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver name need to consist of minimum "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM+" characters.";
		}
		else if((!this.DriverSameAsPolicyholder) && (this.DriverName) && (this.DriverName.length > RuleConstant.NUMBER_NAME_MAXIMUM) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driver name need to consist of maximum "+RuleConstant.NUMBER_NAME_MAXIMUM+" characters.";
		}
		else if ((this.DriverLicenseNumber == null || this.DriverLicenseNumber === undefined || this.DriverLicenseNumber === "") && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driving License number cannot be empty.";
		}
		else if ((this.DriverLicenseNumber) && (!regExNumeric.test(this.DriverLicenseNumber)) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driving License number can only contain numeric.";
		}
		else if((this.DriverLicenseNumber) && (this.DriverLicenseNumber.length < RuleConstant.NUMBER_DRIVINGLICENSENUMBER_NUMBERMINIMUM) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driving License number need to consist of minimum "+RuleConstant.NUMBER_DRIVINGLICENSENUMBER_NUMBERMINIMUM+" characters.";
		}
		else if((this.DriverLicenseNumber) && (this.DriverLicenseNumber?.length > RuleConstant.NUMBER_DRIVINGLICENSENUMBER_NUMBERMAXIMUM) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Driving License number need to consist of maximum "+RuleConstant.NUMBER_DRIVINGLICENSENUMBER_NUMBERMAXIMUM+" characters.";
		}
		else if((this.VehicleSpeed == null || this.VehicleSpeed === undefined) && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		{
			modelResponse.MessageContent = "Vehicle speed can not be empty.";
		}
		// else if((this.VehicleSpeed) && this.VehicleSpeed >= RuleConstant.NUMBER_VEHICLESPEED_VALUEMAXIMUM && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		// {
		// 	modelResponse.MessageContent = "Vehicle speed neet to consist of maximum "+ RuleConstant.NUMBER_VEHICLESPEED_VALUEMAXIMUM + StringConstant.STRING_CURRENCY_KILOMETERSPERHOUR;
		// }
		// else if((this.VehicleSpeed) && this.VehicleSpeed <= RuleConstant.NUMBER_VEHICLESPEED_VALUEMINIMUM && ( this.IncidentType === ENUM_INCIDENT_TYPE.Crash || this.IncidentType === ENUM_INCIDENT_TYPE.ReverseOrFell))
		// {
		// 	modelResponse.MessageContent = "Vehicle speed neet to consist of minimum "+ RuleConstant.NUMBER_VEHICLESPEED_VALUEMINIMUM + StringConstant.STRING_CURRENCY_KILOMETERSPERHOUR;
		// }
		else if (this.VehicleLocation == null || this.VehicleLocation === undefined || this.VehicleLocation === "")
		{
			modelResponse.MessageContent = "Vehicle location can not be empty.";
		}
		else
		{
			if (this.ReporterEmail == null || this.ReporterEmail === undefined || this.ReporterEmail === "")
			{
				modelResponse.MessageContent = "Reporter\'s email cannot be empty";
			}
			else if (regularExpression.test(this.ReporterEmail))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else if (!regularExpression.test(this.ReporterEmail))
			{
				modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
			}
			if (this.ReporterEmail != null && this.ReporterEmail !== undefined && this.ReporterEmail !== "")
			{
				modelResponse.MessageContent = "Reporter\'s email cannot be empty";
				if (regularExpression.test(this.ReporterEmail))
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else if (!regularExpression.test(this.ReporterEmail))
				{
					modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
				}
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateDataNotificationLossAdvice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		if (this.ProductName === null || this.ProductName === undefined || this.ProductName === "" )
		{
			modelResponse.MessageContent = "Interest cannot be empty";
		}
		else if (this.PolicyNumber === null || this.PolicyNumber === undefined)
		{
			modelResponse.MessageContent = "Policy can not be empty";
		}
		else if(this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty";
		}
		else if(this.CheckerHeadOfficeReviewedBy === undefined || this.CheckerHeadOfficeReviewedBy === null)
		{
			modelResponse.MessageContent = "Checker cannot be empty";
		}
		else if(this.SignerHeadOfficeReviewedBy === undefined || this.SignerHeadOfficeReviewedBy === null)
		{
			modelResponse.MessageContent = "Signer cannot be empty";
		}
		else if (this.listModelClaimProposalNotification === undefined || this.listModelClaimProposalNotification === null || this.listModelClaimProposalNotification.length <= 0)
		{
			modelResponse.MessageContent = "Email can not be empty";
		}
		else if (this.listModelClaimProposalNotification[0].PolicyNumber!.length > RuleConstant.NUMBER_POLICY_LENGTH)
		{
			modelResponse.MessageContent = "Policy Number lenght must "+RuleConstant.NUMBER_POLICY_LENGTH;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateDataNonMV(): ResponseModel
	{
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.PolicyNumber == null || this.PolicyholderEmail === null || this.PolicyNumber === "")
		{
			if(this.ReferenceNumber !== undefined && this.ReferenceNumber !== null && this.ReferenceNumber !== "")
			{

			}
			else
			{
				modelResponse.MessageContent = "Please fill in the policy number, reference number or license plate.";
			}
		}
		else
		{

		}

		if (this.ReporterFirstName == null || this.ReporterFirstName === undefined || this.ReporterFirstName === "")
		{
			modelResponse.MessageContent = "Reporter\'s first name cannot be empty.";
		}
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterFirstName != null || this.ReporterFirstName !== undefined || this.ReporterFirstName !== "") && !regExAphabet.test(this.ReporterFirstName))
		{
			modelResponse.MessageContent = "Reporter\'s first name is in incorrect format.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterFirstName?.length > RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name maximum contain "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM+" characters.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterFirstName?.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name minimum contain "+RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM+" characters.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterMiddleName!) && (this.ReporterMiddleName != null || this.ReporterMiddleName !== undefined || this.ReporterMiddleName !== "") && !regExAphabetSpace.test(this.ReporterMiddleName))
		{
			modelResponse.MessageContent = "Reporter\'s middle name is in incorrect format.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if((!this.ReporterSameAsPolicyholder) && (this.ReporterLastName!) && (this.ReporterLastName != null || this.ReporterLastName !== undefined || this.ReporterLastName !== "" ) && !regExAphabetSpace.test(this.ReporterLastName))
		{
			modelResponse.MessageContent = "Reporter\'s last name is in incorrect format.";
		}
		else if (!this.ReporterSameAsPolicyholder && (this.ReporterMobilePhonePrefix === null || this.ReporterMobilePhonePrefix === undefined || this.ReporterMobilePhonePrefix === ""))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone prefix cannot be empty.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterMobilePhone === null || this.ReporterMobilePhone === undefined || this.ReporterMobilePhone === "")
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone cannot be empty.";
		}
		else if (!this.ReporterSameAsPolicyholder && !regExNumeric.test(this.ReporterMobilePhone))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone can only contain numeric.";
		}
		else if ((this.ReporterMobilePhone.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone minimum contain "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM+" characters.";
		}
		else if ((this.ReporterMobilePhone.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone maximum contain "+RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM+" characters.";
		}
		else if (this.ReporterRelationship == null || this.ReporterRelationship === undefined)
		{
			modelResponse.MessageContent = "Reporter\'s relationship can not be empty.";
		}
		else if (this.IncidentChronology == null || this.IncidentChronology === undefined || this.IncidentChronology === "")
		{
			modelResponse.MessageContent = "Accident Chronology can not be empty.";
		}
		else if (this.IncidentLocation == null || this.IncidentLocation === undefined || this.IncidentLocation === "")
		{
			modelResponse.MessageContent = "Loss Location can not be empty.";
		}
		else if (this.IncidentDetailLocation == null || this.IncidentDetailLocation === undefined || this.IncidentDetailLocation === "")
		{
			modelResponse.MessageContent = "Loss Detail Location can not be empty.";
		}
		else if (this.IncidentDate == null || this.IncidentDate === undefined)
		{
			modelResponse.MessageContent = "Date of Loss can not be empty.";
		}
		else if (this.IncidentTime == null || this.IncidentTime === undefined || this.IncidentTime === "")
		{
			modelResponse.MessageContent = "Accident time can not be empty.";
		}
		else if (this.LegalResponsibility == null || this.LegalResponsibility === undefined)
		{
			modelResponse.MessageContent = "TPL can not be empty.";
		}
		else if (!this.Currency && this.Amount)
		{
			modelResponse.MessageContent = "Currency can not be empty.";
		}
		else if (this.ReporterMobilePhoneSecondary !== null && this.ReporterMobilePhoneSecondary !== undefined && this.ReporterMobilePhoneSecondary !== "" && this.ReporterMobilePhoneSecondary.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone secondary contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM + " characters.";
		}
		else if (this.ReporterMobilePhoneSecondary !== null && this.ReporterMobilePhoneSecondary !== undefined && this.ReporterMobilePhoneSecondary !== "" && this.ReporterMobilePhoneSecondary.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone secondary contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM + " characters.";
		}
		else if (this.ReporterEmail !== undefined && this.ReporterEmail !== null && this.ReporterEmail !== "" && !regularExpression.test(this.ReporterEmail))
		{
			modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
		}
		else if (this.ReporterEmailSecondary !== undefined && this.ReporterEmailSecondary !== null && this.ReporterEmailSecondary !== "" && !regularExpression.test(this.ReporterEmailSecondary))
		{
			modelResponse.MessageContent = "Reporter\'s email secondary format is incorrect.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateDataAdjuster(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.listModelClaimProposalItemLoss === undefined || this.listModelClaimProposalItemLoss === null || this.listModelClaimProposalItemLoss.length<=0)
		{
			modelResponse.MessageContent = "Item loss can not be Empty.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpdateDataNonMV(): ResponseModel
	{
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.PolicyNumber == null || this.PolicyholderEmail === null || this.PolicyNumber === "")
		{
			modelResponse.MessageContent = "Please fill in the policy number or license plate.";
		}
		// else if (this.listModelClaimProposalItemLoss === undefined || this.listModelClaimProposalItemLoss === null || this.listModelClaimProposalItemLoss.length === 0)
		// {
		// 	modelResponse.MessageContent = "List Proposal Item Loss can not be empty.";
		// }
		else if (this.IncidentChronology == null || this.IncidentChronology === undefined || this.IncidentChronology === "")
		{
			modelResponse.MessageContent = "Accident Chronology can not be empty.";
		}
		// Cause of Loss temporarily disabled because of PNM
		// else if (this.IncidentCauseOfLoss === undefined || this.IncidentCauseOfLoss === null || this.IncidentCauseOfLoss === "")
		// {
		// 	modelResponse.MessageContent = "Cause Of Loss can not be empty.";
		// }
		else if (this.IncidentLocation == null || this.IncidentLocation === undefined || this.IncidentLocation === "" || this.IncidentLocationCode == null || this.IncidentLocationCode === undefined || this.IncidentLocationCode === "")
		{
			modelResponse.MessageContent = "Loss Location can not be empty.";
		}
		else if (this.IncidentDetailLocation == null || this.IncidentDetailLocation === undefined || this.IncidentDetailLocation === "")
		{
			modelResponse.MessageContent = "Loss Detail Location can not be empty.";
		}
		else if (this.IncidentDate == null || this.IncidentDate === undefined)
		{
			modelResponse.MessageContent = "Date Of Loss can not be empty.";
		}
		else if (this.IncidentTime == null || this.IncidentTime === undefined || this.IncidentTime === "")
		{
			modelResponse.MessageContent = "Accident time can not be empty.";
		}
		// else if (this.LegalResponsibility == null || this.LegalResponsibility === undefined)
		// {
		// 	modelResponse.MessageContent = "TPL can not be empty.";
		// }
		else if (this.CheckerBranchCorrectionBy === undefined || this.CheckerBranchCorrectionBy === null)
		{
			modelResponse.MessageContent = "Checker can not be empty.";
		}
		else if (this.SignerBranchCorrectionBy === undefined || this.SignerBranchCorrectionBy === null)
		{
			modelResponse.MessageContent = "Signer can not be empty.";
		}
		else
		{
			if(this.ReporterEmail && this.ReporterEmail !== "")
			{
				if (regularExpression.test(this.ReporterEmail))
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else if (!regularExpression.test(this.ReporterEmail))
				{
					modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else if(this.ReporterEmailSecondary && this.ReporterEmailSecondary !== "")
			{
				if (regularExpression.test(this.ReporterEmailSecondary))
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else if (!regularExpression.test(this.ReporterEmailSecondary))
				{
					modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateSurveyNonMV(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Survey");

		if (this.SurveyNearestBranchName == null || this.SurveyNearestBranchName === undefined || this.SurveyNearestBranchName === "")
		{
			modelResponse.MessageContent = "Survey Branch name can not be empty.";
		}
		else if (this.SurveyNearestBranchCode == null || this.SurveyNearestBranchCode === undefined || this.SurveyNearestBranchCode === "")
		{
			modelResponse.MessageContent = "Survey Branch can not be empty.";
		}
		else if (this.SurveyAddress == null || this.SurveyAddress === undefined || this.SurveyAddress === "")
		{
			modelResponse.MessageContent = "Survey Address can not be empty.";
		}
		else if (this.SurveyDate == null || this.SurveyDate === undefined)
		{
			modelResponse.MessageContent = "Survey date can not be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success ;
		}

		return modelResponse;
	}

	validateDataNotFound(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");
		const regExpAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExpEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		if
		(
			(this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" ) &&
			(this.LicensePlateInfix == null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" ) &&
			(this.LicensePlateSuffix == null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
		)
		{
			modelResponse.MessageContent = "License plate cannot be empty.";
		}
		else if ( this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "" )
		{
			if (this.PolicyNumber?.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber?.length !== RuleConstant.NUMBER_POLICY_LENGTH)
			{
				modelResponse.MessageContent = "Policy number must be contain 16 digit numbers or 20 digit numbers.";
			}
			else if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}
		else if
		(
			(this.LicensePlatePrefixCode !== null && this.LicensePlatePrefixCode !== undefined && this.LicensePlatePrefixCode !== "" ) ||
			(this.LicensePlateInfix !== null && this.LicensePlateInfix !== undefined && this.LicensePlateInfix !== "" ) ||
			(this.LicensePlateSuffix !== null && this.LicensePlateSuffix !== undefined && this.LicensePlateSuffix !== "" )
		)
		{
			if (this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" )
			{
				modelResponse.MessageContent = "License Plate Prefix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateInfix === null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" )
			{
				modelResponse.MessageContent = "License Plate Infix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateSuffix === null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
			{
				modelResponse.MessageContent = "License Plate Suffix can’t be empty, please fill this field.";
			}
			else if (!regExpAphabet.test(this.LicensePlateSuffix))
			{
				modelResponse.MessageContent = "License Plate Suffix format incorrect.";
			}
			else
			{
				this.InsuredLicensePlate = this.LicensePlatePrefixCode.replace(/\s/g, "") +""+ this.LicensePlateInfix +""+ this.LicensePlateSuffix;
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.InsuredVehicleChasisNumber !== null && this.InsuredVehicleChasisNumber !== undefined && this.InsuredVehicleChasisNumber !== "" )
		{
			modelResponse.MessageContent = "Chasis Number correct.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else if (this.PolicyholderEmail !== null && this.PolicyholderEmail !== undefined && this.PolicyholderEmail !== "")
		{
			if (regExpEmail.test(this.PolicyholderEmail))
			{
				modelResponse.MessageContent = "Email correct.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Email incorrect.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}
		else
		{
			modelResponse.MessageContent = "Attributes search empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}

		return modelResponse;
	}

	validateAddSubmissionDocumentLenght(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelClaimProposalGallery?.length === 0)
		{
			modelResponse.MessageContent = "Document empty.";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddSubmissionDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
			modelResponse.State = ENUM_RESPONSE_STATE.Warning;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddSubmissionDocumentByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddAdjusterDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Adjuster");

		if(this.listModelClaimProposalAdjusterDocument === null || this.listModelClaimProposalAdjusterDocument === undefined || this.listModelClaimProposalAdjusterDocument.length <= 0)
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
			modelResponse.State = ENUM_RESPONSE_STATE.Warning;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddAdjusterDocumentLenght(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Adjuster");

		if(this.listModelClaimProposalAdjusterDocument?.length === 0)
		{
			modelResponse.MessageContent = "Document empty.";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddSubmissionDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelClaimProposalGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Document can’t be empty, please fill this field.";
			}
		}
		else
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDeclarationDocument: ResponseModel;
			let numberSize: number = 0;

			for (const modelSubmissionDocument of this.listModelClaimProposalGallery ?? [])
			{
				modelResponseDeclarationDocument = modelSubmissionDocument.validateAdd();

				if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationDocument;
				}
				else
				{
					numberSize += modelSubmissionDocument.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment is " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL);
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateUploadAdjusterDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddAdjusterDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelClaimProposalAdjusterDocument?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Document can’t be empty, please fill this field.";
			}
		}
		else
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDeclarationDocument: ResponseModel;
			let numberSize: number = 0;

			for (const modelAdjusterDocument of this.listModelClaimProposalAdjusterDocument ?? [])
			{
				modelResponseDeclarationDocument = modelAdjusterDocument.validateAdd();

				if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationDocument;
				}
				else
				{
					numberSize += modelAdjusterDocument.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment is " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL);
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	// validateUploadNonMotoVehicle(arrayNumberGallery: Array<number>): ResponseModel
	// {
	// 	const modelResponse: ResponseModel = this.validateAddSubmissionDocument();

	// 	if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
	// 	{
	// 		modelResponse.State = ENUM_RESPONSE_STATE.Fail;

	// 		if (this.listModelClaimProposalGallery?.length ?? 0 > 0)
	// 		{
	// 			modelResponse.State = ENUM_RESPONSE_STATE.Success;
	// 		}
	// 		else
	// 		{
	// 			modelResponse.MessageContent = "Document can’t be empty, please fill this field.";
	// 		}
	// 	}
	// 	else
	// 	{
	// 		modelResponse.MessageContent = "An error occurred in the attachment list.";
	// 	}

	// 	if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
	// 	{
	// 		modelResponse.State = ENUM_RESPONSE_STATE.Fail;
	// 		let modelResponseDeclarationDocument: ResponseModel;
	// 		let numberSize: number = 0;

	// 		for (const modelSubmissionDocument of this.listModelClaimProposalGallery ?? [])
	// 		{
	// 			modelResponseDeclarationDocument = modelSubmissionDocument.validateAdd();

	// 			if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
	// 			{
	// 				return modelResponseDeclarationDocument;
	// 			}
	// 			else
	// 			{
	// 				numberSize += modelSubmissionDocument.Size ?? 0;
	// 			}
	// 		}

	// 		if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL)
	// 		{
	// 			const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

	// 			modelResponse.MessageContent = "Maksimum total size attachment is " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL);
	// 		}
	// 		else
	// 		{
	// 			modelResponse.State = ENUM_RESPONSE_STATE.Success;
	// 		}
	// 	}
	// 	else
	// 	{

	// 	}

	// 	return modelResponse;
	// }

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateForApproval(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if(this.SignerHeadOfficeReviewedNote == null || this.SignerHeadOfficeReviewedNote === undefined || this.SignerHeadOfficeReviewedNote === "")
		{
			modelResponse.State = modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateImageItemsForManager(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle= "Claim Proposal";
		let booleanActionStatusSurvey: boolean = false;
		let booleanActionStatusSurveyTpl: boolean = false;
		let numberItemSurvey: number = 1;
		let numberThirdParty: number = 1;
		let numberItemSurveyTpl: number = 1;

		if(this.listModelSurvey && this.listModelSurvey.length !== 0)
		{
			for(const modelListSurvey of this.listModelSurvey)
			{
				if(modelListSurvey.listModelSurveyGallery)
				{
					for(const modelSurveyGallery of modelListSurvey.listModelSurveyGallery)
					{
						if (modelSurveyGallery.Type && ( modelSurveyGallery.Type >= ENUM_PHOTO_TYPE.FrontBumperLeft && modelSurveyGallery.Type <= ENUM_PHOTO_TYPE.VelFrontRight || modelSurveyGallery.Type >= ENUM_PHOTO_TYPE.EngineHood ) || modelSurveyGallery.Type === ENUM_PHOTO_TYPE.Other)
						{
							if(modelSurveyGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Manager)
							{
								booleanActionStatusSurvey = true;
							}
							else
							{
								booleanActionStatusSurvey = false;
								break;
							}
							numberItemSurvey++;
						}
					}
				}
				else
				{
				}
			}
		}

		if(this.listModelSurveyThirdPartyLiability && this.listModelSurveyThirdPartyLiability?.length !== 0)
		{
			for(const modelSurveyThirdPartyLiability of this.listModelSurveyThirdPartyLiability)
			{
				if(modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery)
				{
					numberItemSurveyTpl = 1;
					for(const modelSurveyThirdPartyLiabilityGallery of modelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery)
					{
						if(modelSurveyThirdPartyLiabilityGallery.Type && ( modelSurveyThirdPartyLiabilityGallery.Type >= ENUM_PHOTO_TYPE.FrontBumperLeft && modelSurveyThirdPartyLiabilityGallery.Type <= ENUM_PHOTO_TYPE.VelFrontRight || modelSurveyThirdPartyLiabilityGallery.Type >= ENUM_PHOTO_TYPE.EngineHood ) || modelSurveyThirdPartyLiabilityGallery.Type === ENUM_PHOTO_TYPE.Other)
						{
							if(modelSurveyThirdPartyLiabilityGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Manager)
							{
								booleanActionStatusSurveyTpl = true;
							}
							else
							{
								booleanActionStatusSurveyTpl = false;
								break;
							}
							numberItemSurveyTpl++;
						}
					}
				}
				if(booleanActionStatusSurveyTpl === false)
				{
					break;
				}
				else
				{
				}
				numberThirdParty++;
			}
		}
		else
		{
			booleanActionStatusSurveyTpl = true;
		}

		if(booleanActionStatusSurvey === false)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Photo items number "+numberItemSurvey+" must be approved or rejected";
		}
		else if(booleanActionStatusSurveyTpl === false)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Photo items TPl "+numberThirdParty +" number "+numberItemSurveyTpl + " must be approved or rejected";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;

	}

	validateRejectedApproval(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token cannot be empty.";
		}
		else if (this.Note == null || this.Note === undefined || this.Note === "")
		{
			modelResponse.MessageContent = "Note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelClaimProposalGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateAddForUploadByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelClaimProposalSupportingGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	setListModelClaimProposalDocument(arrayClaimProposalDocument?: Array<ClaimProposalGalleryModel>): void
	{
		if (arrayClaimProposalDocument == null || arrayClaimProposalDocument === undefined)
		{

		}
		else
		{
			if (arrayClaimProposalDocument.length > 0)
			{
				let modelClaimProposalDocument: ClaimProposalGalleryModel;

				for (const modelClaimProposalDocumentTemp of arrayClaimProposalDocument)
				{
					modelClaimProposalDocument = new ClaimProposalGalleryModel();
					modelClaimProposalDocument.setModelFromObject(modelClaimProposalDocumentTemp);
					this.listModelClaimProposalGallery?.push(modelClaimProposalDocument);

				}
			}
			else
			{

			}

		}
	}

	validateDataByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.BranchReviewedNote == null || this.BranchReviewedNote === undefined || this.BranchReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateDataVerifyByMakerHeadOffice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.MakerHeadOfficeReviewedNote == null || this.MakerHeadOfficeReviewedNote === undefined || this.MakerHeadOfficeReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateDataVerifyByCheckerHeadOffice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.CheckerHeadOfficeReviewedNote == null || this.CheckerHeadOfficeReviewedNote === undefined || this.CheckerHeadOfficeReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateDataVerifyBySignerHeadOffice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.SignerHeadOfficeReviewedNote === null || this.SignerHeadOfficeReviewedNote === undefined || this.SignerHeadOfficeReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateUploadByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddSubmissionDocumentByBranch();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelClaimProposalSupportingGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Document can’t be empty, please fill this field.";
			}
		}
		else
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDeclarationDocument: ResponseModel;
			let numberSize: number = 0;

			for (const modelSubmissionDocument of this.listModelClaimProposalSupportingGallery ?? [])
			{
				modelResponseDeclarationDocument = modelSubmissionDocument.validateAdd();

				if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationDocument;
				}
				else
				{
					numberSize += modelSubmissionDocument.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL_SUPPORTINGDOCUMENT)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL_SUPPORTINGDOCUMENT);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let numberDocumentSuccess: number = 0;
			let numberDocumentFailed: number = 0;

			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			const arrayGalleryModel = this.setArrayGalleryValidationBranchDocument();
			for (let numberIndex = arrayGalleryModel.length; numberIndex >= 0; numberIndex--)
			{
				const arrayDocument = this.listModelClaimProposalSupportingGallery?.filter(x => x.Type === arrayGalleryModel[numberIndex]);
				if(arrayDocument?.length !== 0)
				{
					numberDocumentSuccess = numberDocumentSuccess + 1;
				}
				else
				{
					modelResponse.MessageContent = "Document "+ ENUM_PHOTO_TYPE[arrayGalleryModel[numberIndex]] +" cannot be empty.";
					numberDocumentFailed = numberDocumentFailed + 1;
				}
			}

			if(numberDocumentSuccess === arrayGalleryModel.length)
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}

		return modelResponse;
	}

	validateAdjusterMakerForBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if ( this.CheckerBranchAdjusterBy === undefined || this.CheckerBranchAdjusterBy ===null)
		{
			modelResponse.MessageContent = "Checker can't be empty";
		}
		else if ( this.SignerBranchAdjusterBy === undefined || this.SignerBranchAdjusterBy ===null)
		{
			modelResponse.MessageContent = "Signer can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCheckModelClaimProposalDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelProposalDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseAdjusterDocument: ResponseModel;
			let modelAdjusterDocumentCheck: ClaimProposalAdjusterDocumentModel;

			for (const modelAdjusterDocument of this.listModelClaimProposalAdjusterDocument ?? [])
			{
				modelAdjusterDocumentCheck = new ClaimProposalAdjusterDocumentModel();
				modelAdjusterDocumentCheck.setModelFromObject(modelAdjusterDocument);
				modelResponseAdjusterDocument = modelAdjusterDocumentCheck.validateCheck();

				if (modelResponseAdjusterDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseAdjusterDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckListModelProposalDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Adjuster Document");

		if(this.listModelClaimProposalAdjusterDocument == null || this.listModelClaimProposalAdjusterDocument === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAdjusterChecker(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.CheckerBranchAdjusterNote === undefined || this.CheckerBranchAdjusterNote === null || this.CheckerBranchAdjusterNote === "")
		{
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAdjusterSigner(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.SignerBranchAdjusterNote === undefined || this.SignerBranchAdjusterNote === null || this.SignerBranchAdjusterNote === "")
		{
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	setArrayGalleryValidationBranchDocument(): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayBranchSupportingDocument: Array<number> =
		[
			enumPhotoType.SupportingDocument,
		];

		return arrayBranchSupportingDocument;
	}

	validateAddModelProposalItemLossDetail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Item Loss");

		if(this.listModelProposalItemLoss === null || this.listModelProposalItemLoss === undefined || this.listModelProposalItemLoss.length === 0)
		{
			modelResponse.MessageContent = "Loss item Proposal be empty, please fill this field.";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateDataByHeadOffice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.HeadOfficeReviewedNote == null || this.HeadOfficeReviewedNote === undefined || this.HeadOfficeReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	setInterfaceForMotorVehicle(b): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		let arrayModelGalleryModel: Array<number> = Array();
		if(b === true)
		{
			arrayModelGalleryModel =
			[
				enumPhotoType.KTP,
				enumPhotoType.DriverLicense,
				enumPhotoType.STNK,
				enumPhotoType.KIRReport,
				enumPhotoType.VehicleFront,
				enumPhotoType.VehicleLeft,
				enumPhotoType.VehicleRight,
				enumPhotoType.VehicleBack,
			];
		}
		else
		{
			arrayModelGalleryModel =
			[
				enumPhotoType.KTP,
				enumPhotoType.ClaimThirdPartyLetter,
			];
		}

		return arrayModelGalleryModel;
	}

	checkTypePolicyNumber(stringTOC: string): number
	{

		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		const stringTypeTOC: string = stringTOC;
		let numberTypePolicy: number | undefined = 0;

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_FIRE.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.Fire;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_ENGINEERING.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.Engineering;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_MV.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.MotorVehicle;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_VARIA.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.Varia;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if(modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_FINANSIALLINE.forEach(function(stringValue)
			{
				if(stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.FinansialLine;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		return numberTypePolicy;
	}

	validateHistoryPolicyMV(stringProductCode: string): boolean
	{
		let booleanMV: boolean = false;
		const stringTypeTOC: string = stringProductCode;

		StringConstant.ARRAY_VALUE_TOC_MV.forEach(function(stringValue)
		{
			if(stringTypeTOC === stringValue)
			{
				booleanMV = true;
			}
		});

		return booleanMV;
	}

	setInterfaceDocument(b): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		let arrayModelGalleryModel: Array<number> = Array();
		if(b === 0 || b === 1 || b === 3 || b === 4)
		{
			arrayModelGalleryModel =
			[
				enumPhotoType.KTP,
				enumPhotoType.ClaimLetter,
				enumPhotoType.ObjectOfLoss,
				enumPhotoType.Other,
			];
		}
		else if (b === 2 )
		{
			arrayModelGalleryModel =
			[
				enumPhotoType.KTP,
				enumPhotoType.DriverLicense,
				enumPhotoType.STNK,
				enumPhotoType.KIRReport,
				enumPhotoType.VehicleFront,
				enumPhotoType.VehicleLeft,
				enumPhotoType.VehicleRight,
				enumPhotoType.VehicleBack
			];
		}
		else
		{

		}

		return arrayModelGalleryModel;
	}

	setInterface(b): boolean
	{
		let booleanMV: boolean = false;
		if(b === 0 || b === 1 || b === 3 || b === 4)
		{
			booleanMV = false;
		}
		else if (b === 2 )
		{
			booleanMV = true;
		}
		else
		{

		}

		return booleanMV;
	}

	setInterfaceValidationDocument(b): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		let arrayModelGalleryModel: Array<number> = Array();
		if(b === 0 || b === 1 || b === 3 || b === 4)
		{
			arrayModelGalleryModel =
			[
				enumPhotoType.KTP,
				enumPhotoType.ClaimLetter,
				enumPhotoType.Other
			];
		}
		else if (b === 2 )
		{
			arrayModelGalleryModel =
			[
				enumPhotoType.KTP,
				enumPhotoType.DriverLicense,
				enumPhotoType.STNK,
			];
		}
		else
		{

		}

		return arrayModelGalleryModel;
	}

	setIntervaceValidationBranchDocument(): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		const arrayBranchSupportingDocument: Array<number> =
		[
			enumPhotoType.ApealLetter,
			enumPhotoType.SupportingDocument,
			enumPhotoType.SupportingDocument2,
			enumPhotoType.SupportingDocument3,
			enumPhotoType.SupportingDocument4,
			enumPhotoType.SupportingDocument5
		];

		return arrayBranchSupportingDocument;
	}

	validatePolicyNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		modelResponse.setForValidation("Policy General");


		if ( this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "" )
		{
			modelResponse.MessageContent = "Policy number can’t be empty, please fill this field.";
		}
		else
		{
			if (this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_LENGTH)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number must be contain 16 digit numbers or 20 digit numbers.";
			}
			else if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}

		return modelResponse;
	}

	validateSearchClaimProposalAttributes(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		const regExpAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);


		modelResponse.setForValidation("Claim Proposal");

		if (this.TicketNumber !== null && this.TicketNumber !== undefined && this.TicketNumber !== "")
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else if (this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "")
		{

			if (this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_LENGTH)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number must be contain 16 digit numbers or 20 digit numbers.";
			}
			else if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}
		else if
		(
			(this.LicensePlatePrefixCode !== null && this.LicensePlatePrefixCode !== undefined && this.LicensePlatePrefixCode !== "" ) ||
			(this.LicensePlateInfix !== null && this.LicensePlateInfix !== undefined && this.LicensePlateInfix !== "" ) ||
			(this.LicensePlateSuffix !== null && this.LicensePlateSuffix !== undefined && this.LicensePlateSuffix !== "" )
		)
		{
			if (this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" )
			{
				modelResponse.MessageContent = "License Plate Prefix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateInfix === null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" )
			{
				modelResponse.MessageContent = "License Plate Infix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateSuffix === null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
			{
				modelResponse.MessageContent = "License Plate Suffix can’t be empty, please fill this field.";
			}
			else if (!regExpAphabet.test(this.LicensePlateSuffix))
			{
				modelResponse.MessageContent = "License Plate Suffix format incorrect.";
			}
			else
			{
				this.InsuredLicensePlate = this.LicensePlatePrefixCode.replace(/\s/g, "") +""+ this.LicensePlateInfix +""+ this.LicensePlateSuffix;
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.InsuredName !== null && this.InsuredName !== undefined && this.InsuredName !== "")
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else if (this.CreatedOn !== null && this.CreatedOn !== undefined)
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateKirReport(): boolean
	{
		if(this.LegalResponsibility)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	validateClaimLetterThirdPartyLiability(arrayModelSurveyThirdPartyLiability: SurveyThirdPartyLiabilityModel): boolean
	{
		let booleanClaimThirdPartyLetter: boolean = false;
		let booleanPeaceAgreementLetter: boolean = false;
		let booleanIdentityThirdParty: boolean = false;

		arrayModelSurveyThirdPartyLiability.listModelSurveyThirdPartyLiabilityGallery?.forEach
		(
			element =>
			{
				if(element.Type === ENUM_PHOTO_TYPE.ClaimThirdPartyLetter)
				{
					booleanClaimThirdPartyLetter = true;
				}
				if(element.Type === ENUM_PHOTO_TYPE.PeaceAgreementLetter)
				{
					booleanPeaceAgreementLetter = true;
				}
				if(element.Type === ENUM_PHOTO_TYPE.IdentityThirdParty)
				{
					booleanIdentityThirdParty = true;
				}
			}
		);

		if( booleanClaimThirdPartyLetter && booleanPeaceAgreementLetter && booleanIdentityThirdParty )
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	validateSurveyVideoThirdPartyLiability(arrayModelSurveyThirdPartyLiability: SurveyThirdPartyLiabilityModel): boolean
	{
		let booleanSurveyVideo: boolean = false;
		arrayModelSurveyThirdPartyLiability.listModelSurveyGallery?.forEach
		(
			element =>
			{
				if(element.Type === ENUM_PHOTO_TYPE.SurveyVideo)
				{
					booleanSurveyVideo = true;
				}
			}
		);

		if(booleanSurveyVideo)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	validateClaimLetter(arraySurvey: SurveyModel): boolean
	{
		let booleanClaimThirdPartyLetter: boolean = false;
		let booleanPeaceAgreementLetter: boolean = false;
		let booleanIdentityThirdParty: boolean = false;

		arraySurvey.listModelSurveyGallery?.forEach
		(
			element =>
			{
				if(element.Type === ENUM_PHOTO_TYPE.ClaimThirdPartyLetter)
				{
					booleanClaimThirdPartyLetter = true;
				}
				if(element.Type === ENUM_PHOTO_TYPE.PeaceAgreementLetter)
				{
					booleanPeaceAgreementLetter = true;
				}
				if(element.Type === ENUM_PHOTO_TYPE.IdentityThirdParty)
				{
					booleanIdentityThirdParty = true;
				}
			}
		);

		if( booleanClaimThirdPartyLetter && booleanPeaceAgreementLetter && booleanIdentityThirdParty )
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	validateModelClaimProposalAdjusterDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Adjuster");

		if(this.listModelClaimProposalAdjusterDocument == null || this.listModelClaimProposalAdjusterDocument === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Pengisian Sudah Benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateModelClaimProposalSupportingDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Supporting Document");

		if(this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Pengisian Sudah Benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateSurveyVideo(arrayModelSurvey: SurveyModel): boolean
	{
		let booleanSurveyVideo: boolean = false;
		arrayModelSurvey.listModelSurveyGallery?.forEach
		(
			element =>
			{
				if(element.Type === ENUM_PHOTO_TYPE.SurveyVideo)
				{
					booleanSurveyVideo = true;
				}
			}
		);

		if(booleanSurveyVideo)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	checkHeadOfficeReviewedStatus(): string
	{
		let stringNotes: string;

		if(this.HeadOfficeReviewedStatus === ENUM_REVIEW_STATE.Approved || this.HeadOfficeReviewedStatus === ENUM_REVIEW_STATE.Rejected )
		{
			stringNotes = "Note";
		}
		else
		{
			stringNotes = "Note*";
		}

		return stringNotes;
	}

	checkBranchReviewedStatus(): string
	{
		let stringNotes: string;

		if(this.BranchReviewedStatus === ENUM_REVIEW_STATE.Approved || this.BranchReviewedStatus === ENUM_REVIEW_STATE.Rejected )
		{
			stringNotes = "Note";
		}
		else
		{
			stringNotes = "Note*";
		}

		return stringNotes;
	}

	checkNotifyToHeadOffice(booleanHeadOffice: boolean): string
	{
		if(booleanHeadOffice === true)
		{
			return "Yes";
		}
		else if(booleanHeadOffice === false)
		{
			return "No";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	checkAdjuster(booleanAdjuster: boolean): string
	{
		if(booleanAdjuster === true)
		{
			return "Yes";
		}
		else if(booleanAdjuster === false)
		{
			return "No";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	checkThirdPartyLiability(booleanThirdParty: boolean): string
	{
		if(booleanThirdParty === true)
		{
			return "Yes";
		}
		else if(booleanThirdParty === false)
		{
			return "No";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	booleanCheckThirdPartyLiability(booleanThirdParty: boolean): boolean
	{
		if(booleanThirdParty === true || booleanThirdParty === false)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	validateUpdateBranchSurveyor(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.SurveyNearestBranchCode == null || this.SurveyNearestBranchCode === undefined || this.SurveyNearestBranchCode === "")
		{
			modelResponse.MessageContent = "Survey branch cannot be empty.";
		}
		else if (this.SurveyNearestBranchName == null || this.SurveyNearestBranchName === undefined || this.SurveyNearestBranchName === "")
		{
			modelResponse.MessageContent = "Survey branch name cannot be empty.";
		}
		else if (this.SurveyAddress == null || this.SurveyAddress === undefined || this.SurveyAddress === "")
		{
			modelResponse.MessageContent = "Survey address cannot be empty.";
		}
		else if (this.SurveyNote == null || this.SurveyNote === undefined || this.SurveyNote === "")
		{
			modelResponse.MessageContent = "Survey note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	selectListModelSupportingGalleryByType(n): boolean
	{
		if(this.listModelClaimProposalSupportingGallery !== undefined)
		{
			const items = this.listModelClaimProposalSupportingGallery.find(i => i.Type === n);
			if(items !== undefined)
			{
				if(items.Type !== undefined)
				{
					if (items.Type === n)
					{
						return true;
					}
					else
					{
						return false;
					}
				}
				else
				{
					return false;
				}
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	selectListModelAppealGalleryByType(n): boolean
	{
		if(this.listModelClaimProposalAppealGallery !== undefined)
		{
			const items = this.listModelClaimProposalAppealGallery.find(i => i.Type === n);
			if(items !== undefined)
			{
				if(items.Type !== undefined)
				{
					if (items.Type === n)
					{
						return true;
					}
					else
					{
						return false;
					}
				}
				else
				{
					return false;
				}
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	deleteListModelSupportingGalleryByType(numberDocumentType: number): void
	{
		if(this.listModelClaimProposalSupportingGallery !== undefined)
		{
			for (const arrayClaimProposalGallery of this.listModelClaimProposalSupportingGallery)
			{
				if (numberDocumentType === arrayClaimProposalGallery.Type)
				{
					this.listModelClaimProposalSupportingGallery.splice(this.listModelClaimProposalSupportingGallery.indexOf(arrayClaimProposalGallery), 1);
					break;
				}
			}
		}
	}


	validateFormForBranchAppeal(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal");

		if (this.Note == null || this.Note === undefined || this.Note === "")
		{
			modelResponse.MessageContent = "Note cannot be empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	checkStatusRejected(): boolean
	{
		const enumClaimProposalStatus = ENUM_CLAIMPROPOSAL_STATUS;
		if(this.Status === enumClaimProposalStatus.RejectedByHeadOffice || this.Status === enumClaimProposalStatus.Rejected)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	checkListClaimProposalAppealNotEmpty(): boolean
	{
		if(this.listModelClaimProposalAppeal === undefined || this.listModelClaimProposalAppeal.length === 0)
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	checkListClaimProposalAppeal(): boolean
	{
		if(this.listModelClaimProposalAppeal === undefined || this.listModelClaimProposalAppeal.length < 3)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	getClaimProposalChannel(): string
	{
		if(this.Channel === ENUM_CLAIM_CHANNEL.BranchAdmin)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_ADMINBRANCH;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.Claim)
		{
			if(this.BranchCreatorName)
			{
				return this.BranchCreatorName;
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.CustomerWebsite)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_BRINSWEBSITE;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.OperationAndSupport)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_OPERATIONANDSUPPORT;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.RelationshipManager)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_RELATIONSHIPMANAGER;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.CustomerMobile)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_BRINSMOBILE;
		}
		else if(this.Channel === ENUM_CLAIM_CHANNEL.CustomerSelfServiceMonitor)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_CUATOMERSELFSERVICEMONITOR;
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	getClaimProposalStatus(): string
	{
		const modelSurvey: SurveyModel = this.listModelSurvey?.find(listModelSurveyFind => listModelSurveyFind.Status !== undefined) || new SurveyModel();
		if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.Verified)
		{
			if(this.BranchName)
			{
				return "Found | Pending Verification " + this.BranchName;
			}
			else
			{
				return "Found | Pending Verification " + StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.NotVerified)
		{
			return "Not Found | Pending Verification " + StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.RejectedByBranch)
		{
			if(this.BranchName)
			{
				return "Rejected By Branch " + this.BranchName;
			}
			else
			{
				return "Rejected By Branch "+ StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForHeadOffice)
		{
			return "Pending Verification Adjustment "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForCheckerHeadOffice)
		{
			return "Pending Verification Supervisor "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForSignerHeadOffice)
		{
			return "Pending Verification Manager "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.RejectedByHeadOffice)
		{
			return "Rejected By "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForSurvey)
		{
			if(this.SurveyNearestBranchName)
			{
				return "Pending For Survey "+ this.SurveyNearestBranchName;
			}
			else
			{
				return "Pending For Survey "+ StringConstant.STRING_CHARACTER_DASH;
			}
		}
		// else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.CompletedWithSurvey || this.Status === ENUM_CLAIMPROPOSAL_STATUS.CompletedWithoutSurvey)
		// {
		// 	return "Waiting For Approval By "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		// }
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.CompletedWithSurvey && modelSurvey.Status === ENUM_SURVEY_STATUS.Surveyed)
		{
			return "Waiting For Approval By Adjustment";
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.CompletedWithSurvey && modelSurvey.Status === ENUM_SURVEY_STATUS.Verify)
		{
			return "Waiting For Approval By Supervisor";
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.CompletedWithSurvey && modelSurvey.Status === ENUM_SURVEY_STATUS.Approved)
		{
			return "Waiting For Approval By Manager";
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.Approved)
		{
			return "Approved By "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.Rejected)
		{
			return "Rejected By "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.Direct)
		{
			return "Direct to Mantle ";
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForApprove)
		{
			return "Pending For Approve Deputy Branch Head ";
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.Submitted)
		{
			return "Submitted ";
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrection)
		{
			if(this.BranchName)
			{
				return "Pending For Correction "+ this.BranchName;
			}
			else
			{
				return "Pending For Correction "+ StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrectionChecker)
		{
			if(this.BranchName)
			{
				return "Pending For Correction Checker "+ this.BranchName;
			}
			else
			{
				return "Pending For Correction Checker "+ StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.PendingForCorrectionSigner)
		{
			if(this.BranchName)
			{
				return "Pending For Correction Signer "+ this.BranchName;
			}
			else
			{
				return "Pending For Correction Signer "+ StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.ApproveNotVerified)
		{
			return "Approved Not Verified By "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.RejectNotVerified)
		{
			return "Rejected Not Verified By "+ StringConstant.STRING_FIELD_BRANCHNAME_HEADOFFICE;
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	validateCheckModelClaimProposalSupportingDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelProposalSupprotingDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseSupportingDocument: ResponseModel;
			let modelGaleryDocumentCheck: ClaimProposalGalleryModel;

			for (const modelGaleryDocument of this.listModelClaimProposalGallery ?? [])
			{
				modelGaleryDocumentCheck = new ClaimProposalGalleryModel();
				modelGaleryDocumentCheck.setModelFromObject(modelGaleryDocument);
				modelResponseSupportingDocument = modelGaleryDocumentCheck.validateCheck();

				if (modelResponseSupportingDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseSupportingDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckListModelProposalSupprotingDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Supporting Document");

		if (this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCorrectionChecker(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal");

		if (this.CheckerBranchCorrectionNote === null || this.CheckerBranchCorrectionNote === undefined || this.CheckerBranchCorrectionNote === "")
		{
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	checkChannelFromCustomer(): boolean
	{
		if(this.Channel === ENUM_CLAIM_CHANNEL.CustomerWebsite || this.Channel === ENUM_CLAIM_CHANNEL.CustomerMobile || this.Channel === ENUM_CLAIM_CHANNEL.CustomerSelfServiceMonitor || this.Channel === ENUM_CLAIM_CHANNEL.OperationAndSupport)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	public setStatusImageItems(modelSurveyGallery: SurveyGalleryModel): string
	{
		let stringStatus: string = "Pending";

		// if(modelSurvey.Status === ENUM_SURVEY_STATUS.Surveyed)
		// {
		// 	stringStatus = StringConstant.STRING_CLAIMPROPOSAL_ADJUSTER;
		// }
		// else if(modelSurvey.Status === ENUM_SURVEY_STATUS.Verify)
		// {
		// 	stringStatus = StringConstant.STRING_CLAIMPROPOSAL_SUPERVISOR;
		// }
		// else if(modelSurvey.Status === ENUM_SURVEY_STATUS.Approved)
		// {
		// 	stringStatus = StringConstant.STRING_CLAIMPROPOSAL_MANAGER;
		// }

		if(modelSurveyGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Adjuster)
		{
			stringStatus = StringConstant.STRING_CLAIMPROPOSAL_ADJUSTER;
		}
		else if(modelSurveyGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Supervisor)
		{
			stringStatus = StringConstant.STRING_CLAIMPROPOSAL_SUPERVISOR;
		}
		else if(modelSurveyGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Manager)
		{
			stringStatus = StringConstant.STRING_CLAIMPROPOSAL_MANAGER;
		}

		if(modelSurveyGallery.ActionType !== undefined && modelSurveyGallery.ActionType !== null)
		{
			if(modelSurveyGallery.ActionType === ENUM_CLAIMITEM_ACTIONTYPE.Approve)
			{
				stringStatus = "Approved by " + stringStatus;
			}
			else if(modelSurveyGallery.ActionType === ENUM_CLAIMITEM_ACTIONTYPE.Reject)
			{
				stringStatus = "Rejected by " + stringStatus;
			}
			else
			{
			}
		}
		else
		{
			stringStatus = "Pending";
		}

		return stringStatus;
	}


	public setStatusImageItemsThirtparty(modelSurveyThirdPartyLiabilityGallery: SurveyThirdPartyLiabilityGalleryModel): string
	{
		let stringStatus: string = "Pending";
		if(modelSurveyThirdPartyLiabilityGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Adjuster)
		{
			stringStatus = StringConstant.STRING_CLAIMPROPOSAL_ADJUSTER;
		}
		else if(modelSurveyThirdPartyLiabilityGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Supervisor)
		{
			stringStatus = StringConstant.STRING_CLAIMPROPOSAL_SUPERVISOR;
		}
		else if(modelSurveyThirdPartyLiabilityGallery.ActionStatus === ENUM_CLAIMITEM_ACTIONSTATUS.Manager)
		{
			stringStatus = StringConstant.STRING_CLAIMPROPOSAL_MANAGER;
		}


		if(modelSurveyThirdPartyLiabilityGallery.ActionType !== undefined && modelSurveyThirdPartyLiabilityGallery.ActionType !== null)
		{
			if(modelSurveyThirdPartyLiabilityGallery.ActionType === ENUM_CLAIMITEM_ACTIONTYPE.Approve)
			{
				stringStatus = "Approve by " + stringStatus;
			}
			else if(modelSurveyThirdPartyLiabilityGallery.ActionType === ENUM_CLAIMITEM_ACTIONTYPE.Reject)
			{
				stringStatus = "Reject by " + stringStatus;
			}
			else
			{
			}
		}
		else
		{
			stringStatus = "Pending";
		}

		return stringStatus;
	}
	//#region clean

	cleanListModel(): void
	{
		this.listModelClaimProposalGallery = undefined;
		this.listModelClaimProposalSupportingGallery = undefined;
		this.listModelClaimProposalAppeal = undefined;
		this.listModelSurvey = undefined;
		this.listModelClaimProposalItemLoss = undefined;
		this.listModelSurveyThirdPartyLiability = undefined;
	}

	//#endregion


	//#region CHECK

	checkBranchWithoutSurvey(stringPolicyBranchCode: string, stringUserBranchCode): boolean
	{
		const arrayBranchCodeWithoutSurvey: Array<string> = ArrayBranchWithoutSurveyData;

		if(arrayBranchCodeWithoutSurvey.includes(stringPolicyBranchCode) || arrayBranchCodeWithoutSurvey.includes(stringUserBranchCode))
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	checkClaimProposalDirectToMantle(): boolean
	{
		if(this.Status === ENUM_CLAIMPROPOSAL_STATUS.Direct)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion

}

//#endregion

//#region IMPORT

import { ENUM_CLAIMPROPOSAL_STATUS } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ClaimProposalReportModel } from "./dashboardreportclaimproposal.model";

//#endregion


//#region CLASS

export class DashboardClaimProposalModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for dashboard claim proposal model, used for export data claim proposal.
		Author: Eka Saputra.
		Created on : Sunday, 20 March 2022. 				Updated on : Sunday, 20 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	TicketNumber?: string;
	PolicyNumber?: string;
	ReportedOn?: Date;
	LicensePlatePrefixCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;
	ReporterFirstName?: string;
	Status?: ENUM_CLAIMPROPOSAL_STATUS;
	listModelClaimProposalReport?: Array<ClaimProposalReportModel>;


	/* Attribute - END */
}

//#endregion
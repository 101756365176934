//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ClaimProposalModel } from "../models/claimproposal.model";
import { ClaimProposalAdjusterDocumentModel } from "../models/claimproposaladjusterdocument.model";
import { SurveyModel } from "../models/survey.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE
@Injectable
(
	{
		providedIn: "root"
	}
)

//#region CLASS

export class ClaimProposalService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectClaimProposalVerifiedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALVERIFIEDBYATTRIBUTES);
	}

	selectClaimProposalNotVerifiedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDBYATTRIBUTES);
	}

	selectClaimProposalByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYTOKEN);
	}

	selectClaimProposalSubmittedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSUBMITTEDBYATTRIBUTES);
	}

	selectClaimProposalConfirmedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTES);
	}

	selectClaimProposalNotVerifiedConfirmedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDCONFIRMEDBYATTRIBUTES);
	}

	selectClaimProposalApprovalByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVALBYATTRIBUTES);
	}

	selectClaimProposalApprovedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVEDBYATTRIBUTES);
	}

	selectClaimProposalConfirmedByAttributesForHeadOffice(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTESFORHEADOFFICE);
	}

	selectClaimProposalForHeadOffice(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORHEADOFFICE);
	}

	selectClaimByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMBYATTRIBUTES);
	}

	selectClaimProposalClaimCenter(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCLAIMCENTER);
	}

	selectClaimProposalForMonitoring(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORMONITORING);
	}

	selectClaimProposalSurveyedByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSURVEYEDBYATTRIBUTES);
	}

	selectClaimProposalPendingForMakerHeadOfficeByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALPENDINGFORMAKERHEADOFFICEBYATTRIBUTES);
	}

	selectClaimProposalPendingForCheckerHeadOfficeByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALPENDINGFORCHECKERHEADOFFICEBYATTRIBUTES);
	}

	selectClaimProposalForAdjusterByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORADJUSTERBYATTRIBUTES);
	}

	selectClaimProposalForAdjusterReportByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORADJUSTERREPORTBYATTRIBUTES);
	}

	selectClaimProposalForAdjusterBranchByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORADJUSTERBRANCHBYATTRIBUTES);
	}

	selectClaimProposalForAdjusterCheckerBranchByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORCHECKERADJUSTERBRANCHBYATTRIBUTES);
	}

	selectClaimProposalForAdjusterSignerBranchByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORSIGNERADJUSTERBRANCHBYATTRIBUTES);
	}

	selectSignClaimProposalForAdjusterByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SIGNCLAIMPROPOSALFORADJUSTERBYTOKEN);
	}

	selectCheckerClaimProposalForAdjusterByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_CHECKCLAIMPROPOSALFORADJUSTERBYTOKEN);
	}

	selectClaimProposalByAttributesForMonitoringExcel(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYATTRIBUTESFORMONITORINGEXCEL);
	}
	selectClaimProposalCorrectionForCheckerByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCORRECTIONFORCHECKERBYATTRIBUTES);
	}

	selectClaimProposalForSurveyByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORSURVEYBYATTRIBUTES);
	}

	//#endregion


	//#region INSERT

	insertClaimProposalMV(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSAL);
	}

	insertClaimProposalDirectMV(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALDIRECT);
	}

	insertClaimProposalNonMotorVehicle(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLE);
	}

	insertClaimProposalNonMotorVehicleByReferenceNumber(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEBYREFERENCENUMBER);
	}

	submitClaimProposal(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SUBMITCLAIMPROPOSAL);
	}

	//#endregion


	//#region UPDATE

	updateClaimProposalCorrectionByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALCORRECTIONBYTOKEN);
	}

	updateClaimProposalSurveyByAttributes(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALSURVEYBYATTRIBUTES);
	}

	updateNotificationLossAdviceByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPDATENOTIFICATIONLOSSADVICEBYTOKEN);
	}

	updateClaimProposalAdjusterForHeadOfficeByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALADJUSTERFORHEADOFFICEBYTOKEN);
	}

	updateClaimProposalAdjusterForBranchByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALADJUSTERFORBRANCHBYTOKEN);
	}

	updateClaimProposalMotorVehicleForOwnRetentionHeadOffice(interfaceGeneralService: GeneralServiceInterface, modelSurvey: SurveyModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelSurvey), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALMOTORVEHICLEFOROWNRETENTIONHEADOFFICE);
	}

	//#endregion


	//#region APPROVE

	approveClaimProposalByHeadOfficeWithToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYHEADOFFICEWITHTOKEN);
	}

	approveClaimProposalByClaimCenterWithToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYCLAIMCENTERWITHTOKEN);
	}

	approveClaimProposalSurveyPolicyholderByClaimCenterWithToken(interfaceGeneralService: GeneralServiceInterface, modelSurvey: SurveyModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelSurvey), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYCLAIMCENTERWITHTOKEN);
	}

	checkClaimProposalForAdjusterByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_CHECKCLAIMPROPOSALFORADJUSTERBYTOKEN);
	}

	signClaimProposalForAdjusterByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SIGNCLAIMPROPOSALFORADJUSTERBYTOKEN);
	}

	checkClaimProposalCorrectionByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_CHECKCLAIMPROPOSALCORRECTIONBYTOKEN);
	}


	//#endregion


	//#region REJECT

	rejectClaimProposalByHeadOfficeWithToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYHEADOFFICEWITHTOKEN);
	}

	rejectClaimProposalByClaimCenterWithToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYCLAIMCENTERWITHTOKEN);
	}

	rejectClaimProposalSurveyPolicyholderByClaimCenterWithToken(interfaceGeneralService: GeneralServiceInterface, modelSurvey: SurveyModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelSurvey), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYCLAIMCENTERWITHTOKEN);
	}

	rejectClaimProposalForAdjusterByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALFORADJUSTERBYTOKEN);
	}

	rejectClaimProposalCorrectionByToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALCORRECTIONBYTOKEN);
	}

	//#endregion


	//#region VERIFY

	verifyClaimProposalByBranchWithToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_VERIFYCLAIMPROPOSALBYBRANCHWITHTOKEN);
	}

	verifyClaimProposalByMakerHeadOfficeWithToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_VERIFYCLAIMPROPOSALBYMAKERHEADOFFICEWITHTOKEN);
	}

	verifyClaimProposalByCheckerHeadOfficeWithToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_VERIFYCLAIMPROPOSALBYCHECKERHEADOFFICEWITHTOKEN);
	}

	//#endregion


	//#region UPLOAD

	uploadClaimProposal(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSAL);
	}

	uploadClaimProposalForNonMotorVehicle(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALFORNONMOTORVEHICLE);
	}

	uploadClaimProposalSupportingGallery(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALSUPPORTINGGALLERY);
	}

	uploadForAdjusterBranchHead(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_UPLOADFORADJUSTERBRANCHHEAD);
	}

	//#endregion


	//#region DOWNLOAD

	downloadSTK(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_DOWNLOADSTK);
	}

	downloadKMK(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_DOWNLOADKMK);
	}

	downloadClaimProposalForAdjusterDocumentToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposelAdjusterDocument: ClaimProposalAdjusterDocumentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposelAdjusterDocument), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALFORADJUSTERDOCUMENTTOKEN);
	}

	//#endregion


	//#region SEND

	sendEmailClaimProposalByClaimCenter(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SENDEMAILCLAIMPROPOSALBYCLAIMCENTER);
	}

	//#endregion


	//#endregion
}

//#region IMPORT

import { ENUM_QUESTION_FOR } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class ClaimProposalSatisfactionQuestionModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for claim satisfaction survey question, used for satisfaction survey.
		Author: Anjar Kusworo.
		Created on : Monday, 04 April 2023. 			Updated on : -.
		Created by : Andryana Baros.					Updated by : -.
		Version : 1.0:3.
	*/

	ID?: number;
	Token?: string;
	QuestionEnglish?: number;
	QuestionBahasa?: string;
	Rating?: number;
	QuestionFor?: ENUM_QUESTION_FOR;

	/* Attribute - END */


	//#region VALIDATION

	//#endregion
}

//#endregion

//#region IMPORT

import { CloudMessagingNotificationModel } from "./cloudmessagingnotification.model";
import { SurveyOnlineCloudMessagingDataModel } from "./surveyonlinecloudmessagingdata.model";

//#endregion


//#region CLASS

export class CloudMessagingModel
{
	collapseKey?: string;
	data?: SurveyOnlineCloudMessagingDataModel;
	from?: string;
	messageId?: string;
	notification?: CloudMessagingNotificationModel;

	//#region CONSTRUCTOR

	constructor()
	{
		this.notification= new CloudMessagingNotificationModel();
		this.data = new SurveyOnlineCloudMessagingDataModel();
	}

	//#endregion
}

//#endregion
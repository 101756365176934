//#region IMPORT

import { CdkStepperModule } from "@angular/cdk/stepper";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMap, GoogleMapsModule } from "@angular/google-maps";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxEchartsModule } from "ngx-echarts";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { environment } from "src/environments/environment";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { TaxRequestListComponent } from "./components/brinstax/taxrequest/taxrequestlist/taxrequestlist.component";
import { TaxRequestAddComponent } from "./components/brinstax/taxrequest/taxrequestadd/taxrequestadd.component";
import { TaxRequestDetailComponent } from "./components/brinstax/taxrequest/taxrequestdetail/taxrequestdetail.component";
import { AppRoutingModule } from "./app-routing.module";
import { AdjusteddetailComponent } from "./components/adjusters/adjusteddetail/adjusteddetail.component";
import { AdjustedlistComponent } from "./components/adjusters/adjustedlist/adjustedlist.component";
import { AdjusterdetailComponent } from "./components/adjusters/adjusterdetail/adjusterdetail.component";
import { AdjusterlistComponent } from "./components/adjusters/adjusterlist/adjusterlist.component";
import { AdjustermakerforbranchdetailComponent } from "./components/adjusters/adjustermakerforbranchdetail/adjustermakerforbranchdetail.component";
import { AdjustermakerforbranchlistComponent } from "./components/adjusters/adjustermakerforbranchlist/adjustermakerforbranchlist.component";
import { ApprovaladjustercheckerforbranchdetailComponent } from "./components/adjusters/approvaladjustercheckerforbranch/approvaladjustercheckerforbranchdetail/approvaladjustercheckerforbranchdetail.component";
import { ApprovaladjustercheckerforbranchlistComponent } from "./components/adjusters/approvaladjustercheckerforbranch/approvaladjustercheckerforbranchlist/approvaladjustercheckerforbranchlist.component";
import { ApprovaladjustersignerforbranchdetailComponent } from "./components/adjusters/approvaladjustersignerforbranch/approvaladjustersignerforbranchdetail/approvaladjustersignerforbranchdetail.component";
import { ApprovaladjustersignerforbranchlistComponent } from "./components/adjusters/approvaladjustersignerforbranch/approvaladjustersignerforbranchlist/approvaladjustersignerforbranchlist.component";
import { ApprovalApprovalDetailComponent } from "./components/approval/approvalapproval/approvalapprovaldetail/approvalapprovaldetail.component";
import { ApprovalapprovalInformationComponent } from "./components/approval/approvalapproval/approvalapprovalinformation/approvalapprovalinformation.component";
import { ApprovalApprovalListComponent } from "./components/approval/approvalapproval/approvalapprovallist/approvalapprovallist.component";
import { ApprovalApprovadDetailComponent } from "./components/approval/approvalapproved/approvalapproveddetail/approvalapproveddetail.component";
import { ApprovalApprovedListComponent } from "./components/approval/approvalapproved/approvalapprovedlist/approvalapprovedlist.component";
import { ApprovalConfirmationDetailComponent } from "./components/approval/approvalconfirmation/approvalconfirmationdetail/approvalconfirmationdetail.component";
import { ApprovalConfirmationListComponent } from "./components/approval/approvalconfirmation/approvalconfirmationlist/approvalconfirmationlist.component";
import { ApprovalConfirmedDetailComponent } from "./components/approval/approvalconfirmed/approvalconfirmeddetail/approvalconfirmeddetail.component";
import { ApprovalConfirmedListComponent } from "./components/approval/approvalconfirmed/approvalconfirmedlist/approvalconfirmedlist.component";
import { ApprovalConfirmedNotVerifiedDetailComponent } from "./components/approval/approvalconfirmednotverified/approvalconfirmednotverifieddetail/approvalconfirmednotverifieddetail.component";
import { ApprovalConfirmedNotVerifiedListComponent } from "./components/approval/approvalconfirmednotverified/approvalconfirmednotverifiedlist/approvalconfirmednotverifiedlist.component";
import { ApprovalNotVerifiedDetailComponent } from "./components/approval/approvalnotverified/approvalnotverifieddetail/approvalnotverifieddetail.component";
import { ApprovalNotVerifiedListComponent } from "./components/approval/approvalnotverified/approvalnotverifiedlist/approvalnotverifiedlist.component";
import { ApprovalReimbursementListComponent } from "./components/approvalreimbursement/approvalreimbursementlist/approvalreimbursementlist.component";
import { DentalreimbursementdetailComponent } from "./components/approvalreimbursement/dentalreimbursementdetail/dentalreimbursementdetail.component";
import { MaternityreimbursementdetailComponent } from "./components/approvalreimbursement/maternityreimbursementdetail/maternityreimbursementdetail.component";
import { OpticreimbursementdetailComponent } from "./components/approvalreimbursement/opticreimbursementdetail/opticreimbursementdetail.component";
import { PostmaternityreimbursementdetailComponent } from "./components/approvalreimbursement/postmaternityreimbursementdetail/postmaternityreimbursementdetail.component";
import { PrematernityreimbursementdetailComponent } from "./components/approvalreimbursement/prematernityreimbursementdetail/prematernityreimbursementdetail.component";
import { BranchComponent } from "./components/branch/branch.component";
import { CertificateApprovedDetailComponent } from "./components/certificate/approved/certificateapproveddetail/certificateapproveddetail.component";
import { CertificateApprovedListComponent } from "./components/certificate/approved/certificateapprovedlist/certificateapprovedlist.component";
import { CertificateRequestDetailComponent } from "./components/certificate/request/certificaterequestdetail/certificaterequestdetail.component";
import { CertificateRequestInsertComponent } from "./components/certificate/request/certificaterequestinsert/certificaterequestinsert.component";
import { CertificateRequestListComponent } from "./components/certificate/request/certificaterequestlist/certificaterequestlist.component";
import { ClaimDetailComponent } from "./components/claim/claimdetail/claimdetail.component";
import { ClaimListComponent } from "./components/claim/claimlist/claimlist.component";
import { ApprovalcorrectionforcheckerdetailComponent } from "./components/correction/approvalcorrectionforcheckerdetail/approvalcorrectionforcheckerdetail.component";
import { ApprovalcorrectionforcheckerlistComponent } from "./components/correction/approvalcorrectionforcheckerlist/approvalcorrectionforcheckerlist.component";
import { SubmissionDetailComponent } from "./components/digitalization/submissions/submissiondetail/submissiondetail.component";
import { SubmissionInsertComponent } from "./components/digitalization/submissions/submissioninsert/submissioninsert.component";
import { SubmissionListComponent } from "./components/digitalization/submissions/submissionlist/submissionlist.component";
import { DownloadComponent } from "./components/download/download.component";
import { HomeComponent } from "./components/home/home.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { BarchartIncludeComponent } from "./components/includes/barchart.include/barchart.include.component";
import { GroupbarchartIncludeComponent } from "./components/includes/groupbarchart.include/groupbarchart.include.component";
import { PiechartIncludeComponent } from "./components/includes/piechart.include/piechart.include.component";
import { MonitoringInformationComponent } from "./components/monitoring/monitoringinformation/monitoringinformation.component";
import { MonitoringListComponent } from "./components/monitoring/monitoringlist/monitoringlist.component";
import { ApprovalnotificationlossadvicedetailforcheckerComponent } from "./components/notification/approvalnotificationlossadvice/approvalnotificationlossadvicedetailforchecker/approvalnotificationlossadvicedetailforchecker.component";
import { ApprovalnotificationlossadvicedetailforsignerComponent } from "./components/notification/approvalnotificationlossadvice/approvalnotificationlossadvicedetailforsigner/approvalnotificationlossadvicedetailforsigner.component";
import { ApprovalnotificationlossadvicelistforcheckerComponent } from "./components/notification/approvalnotificationlossadvice/approvalnotificationlossadvicelistforchecker/approvalnotificationlossadvicelistforchecker.component";
import { ApprovalnotificationlossadvicelistforsignerComponent } from "./components/notification/approvalnotificationlossadvice/approvalnotificationlossadvicelistforsigner/approvalnotificationlossadvicelistforsigner.component";
import { HistorynotificationdetailComponent } from "./components/notification/historynotification/historynotificationdetail/historynotificationdetail.component";
import { HistorynotificationlistComponent } from "./components/notification/historynotification/historynotificationlist/historynotificationlist.component";
import { NotificationLossAdviceDetailComponent } from "./components/notification/notificationlossadvice/notificationlossadvicedetail/notificationlossadvicedetail.component";
import { NotificationLossAdviceListComponent } from "./components/notification/notificationlossadvice/notificationlossadvicelist/notificationlossadvicelist.component";
// import { PartnershipWorkshopComponent } from "./components/partnershipworkshop/partnershipworkshop/partnershipworkshop.component";
import { ApprovalrcsadetailComponent } from "./components/rcsa/approval/approvalrcsadetail/approvalrcsadetail.component";
import { ApprovalrcsalistComponent } from "./components/rcsa/approval/approvalrcsalist/approvalrcsalist.component";
import { AssessmentdetailComponent } from "./components/rcsa/assessment/assessmentdetail/assessmentdetail.component";
import { AssessmentlistComponent } from "./components/rcsa/assessment/assessmentlist/assessmentlist.component";
import { ReportdetailComponent } from "./components/rcsa/report/reportdetail/reportdetail.component";
import { ReportrcsalistComponent } from "./components/rcsa/report/reportrcsalist/reportrcsalist.component";
import { RiskissuerequestdetailComponent } from "./components/rcsa/riskissuerequest/riskissuerequestdetail/riskissuerequestdetail.component";
import { RiskissuerequestlistComponent } from "./components/rcsa/riskissuerequest/riskissuerequestlist/riskissuerequestlist.component";
import { ReportComponent } from "./components/report/report.component";
import { AppComponent } from "./components/root/app.component";
import { SigninComponent } from "./components/signin/signin.component";
import { SubmissionSubmitReportDetailComponent } from "./components/submission/submissionsubmit/submissionsubmitreportdetail/submissionsubmitreportdetail.component";
import { SubmissionSubmitReportListComponent } from "./components/submission/submissionsubmit/submissionsubmitreportlist/submissionsubmitreportlist.component";
import { SubmissionSubmittedDetailComponent } from "./components/submission/submissionsubmitted/submissionsubmitteddetail/submissionsubmitteddetail.component";
import { SubmissionSubmittedListComponent } from "./components/submission/submissionsubmitted/submissionsubmittedlist/submissionsubmittedlist.component";
import { SurveyorDetailComponent } from "./components/survey/surveyor/surveyordetail/surveyordetail.component";
import { SurveyorListComponent } from "./components/survey/surveyor/surveyorlist/surveyorlist.component";
import { WorkshopDetailComponent } from "./components/survey/workshop/workshopdetail/workshopdetail.component";
import { WorkshopListComponent } from "./components/survey/workshop/workshoplist/workshoplist.component";
import { TicketdetailComponent } from "./components/ticket/ticketdetail/ticketdetail.component";
import { TicketlistinboxComponent } from "./components/ticket/ticketlistinbox/ticketlistinbox.component";
import { TicketlistoutboxComponent } from "./components/ticket/ticketlistoutbox/ticketlistoutbox.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { PwaService } from "./services/pwa.service";
import { AnomalyListComponent } from "./components/protect/anomaly/anomalylist/anomalylist.component";
import { AnomalyInsertComponent } from "./components/protect/anomaly/anomalyinsert/anomalyinsert.component";
import { AnomalyDetailComponent } from "./components/protect/anomaly/anomalydetail/anomalydetail.component";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";
import { MonitoringDetailComponent } from "./components/monitoring/monitoringdetail/monitoringdetail.component";
import { SalvageListComponent } from "./components/salvage/salvage.list/salvage.list.component";
import { SalvageInsertComponent } from "./components/salvage/salvage.insert/salvage.insert.component";
import { SalvageDetailComponent } from "./components/salvage/salvage.detail/salvage.detail.component";
import { CheckDevToolsDirective } from "./components/bases/basecheckdevtools.directive";
import { SalvagecheckerDetailComponent } from "./components/salvage/salvagechecker.detail/salvagechecker.detail.component";
import { SalvagecheckerListComponent } from "./components/salvage/salvagechecker.list/salvagechecker.list.component";
import { SalvagesignerDetailComponent } from "./components/salvage/salvagesigner.detail/salvagesigner.detail.component";
import { SalvagesignerListComponent } from "./components/salvage/salvagesigner.list/salvagesigner.list.component";
import { FollowupplandetailComponent } from "./components/ras/followupplan/followupplandetail/followupplandetail.component";
import { FollowupplanlistComponent } from "./components/ras/followupplan/followupplanlist/followupplanlist.component";
import { SubmissionsalvagelistComponent } from "./components/ebidding/submissionsalvagelist/submissionsalvagelist.component";
import { MonitoringsalvagelistComponent } from "./components/ebidding/monitoringsalvagelist/monitoringsalvagelist.component";
import { ApprovalsalvagelistComponent } from "./components/ebidding/approvalsalvagelist/approvalsalvagelist.component";
import { SalvagebuyerlistComponent } from "./components/ebidding/salvagebuyerlist/salvagebuyerlist.component";
import { SalvagebuyerdetailComponent } from "./components/ebidding/salvagebuyerdetail/salvagebuyerdetail.component";
import { SubmissionsalvagedetailComponent } from "./components/ebidding/submissionsalvagedetail/submissionsalvagedetail.component";
import { SubmissionebiddinglistComponent } from "./components/ebidding/submissionebiddinglist/submissionebiddinglist.component";
import { EmateraicheckersignerlistComponent } from "./components/ematerai/emateraicheckersignerlist/emateraicheckersignerlist.component";
import { EmateraicheckersignerdetailComponent } from "./components/ematerai/emateraicheckersignerdetail/emateraicheckersignerdetail.component";
import { EmateraimakerlistComponent } from "./components/ematerai/emateraimakerlist/emateraimakerlist.component";
import { EmateraimakerdetailComponent } from "./components/ematerai/emateraimakerdetail/emateraimakerdetail.component";
import { AnomalyNotificationComponent } from "./components/protect/anomalynotification/anomalynotification.component";
import { DashboardrasdetailComponent } from "./components/ras/dashboardras/dashboardrasdetail/dashboardrasdetail.component";
import { DashboardraslistComponent } from "./components/ras/dashboardras/dashboardraslist/dashboardraslist.component";
import { TicketinsertcustomerComponent } from "./components/ticket/ticketinsertcustomer/ticketinsertcustomer.component";
import { TicketSatisfactionComponent } from "./components/ticket/ticketsatisfaction/ticketsatisfaction.component";
import { TicketReportComponent } from "./components/ticket/ticketreport/ticketreport/ticketreport.component";
import { TicketReportSatisfactionComponent } from "./components/ticket/ticketreport/ticketreportsatisfaction/ticketreportsatisfaction.component";
import { TicketServiceInsertComponent } from "./components/ticket/ticketservice/ticketserviceinsert/ticketserviceinsert.component";
import { TicketServiceListComponent } from "./components/ticket/ticketservice/ticketservicelist/ticketservicelist.component";
import { TicketServiceUpdateComponent } from "./components/ticket/ticketservice/ticketserviceupdate/ticketserviceupdate.component";
import { TicketServiceAgentInsertComponent } from "./components/ticket/ticketserviceagent/ticketserviceagentinsert/ticketserviceagentinsert.component";
import { TicketServiceAgentListComponent } from "./components/ticket/ticketserviceagent/ticketserviceagentlist/ticketserviceagentlist.component";
import { AnnualdisclosurecheckersignerdetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerdetail/annualdisclosurecheckersignerdetail.component";
import { AnnualdisclosurecheckersignerlistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerlist/annualdisclosurecheckersignerlist.component";
import { AnnualdisclosuredetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuredetail/annualdisclosuredetail.component";
import { AnnualdisclosureinsertComponent } from "./components/gcgonline/annualdisclosure/annualdisclosureinsert/annualdisclosureinsert.component";
import { AnnualdisclosurelistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurelist/annualdisclosurelist.component";
import { CodeofethicscheckersignerdetailComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerdetail/codeofethicscheckersignerdetail.component";
import { CodeofethicscheckersignerlistComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerlist/codeofethicscheckersignerlist.component";
import { CodeofethicsdetailComponent } from "./components/gcgonline/codeofethics/codeofethicsdetail/codeofethicsdetail.component";
import { CodeofethicsinsertComponent } from "./components/gcgonline/codeofethics/codeofethicsinsert/codeofethicsinsert.component";
import { CodeofethicslistComponent } from "./components/gcgonline/codeofethics/codeofethicslist/codeofethicslist.component";
import { GratificationdetailComponent } from "./components/gcgonline/gratification/gratificationdetail/gratificationdetail.component";
import { GratificationlistComponent } from "./components/gcgonline/gratification/gratificationlist/gratificationlist.component";
import { IntegritypactdetailComponent } from "./components/gcgonline/integritypact/integritypactdetail/integritypactdetail.component";
import { IntegritypactinsertComponent } from "./components/gcgonline/integritypact/integritypactinsert/integritypactinsert.component";
import { IntegritypactlistComponent } from "./components/gcgonline/integritypact/integritypactlist/integritypactlist.component";
import { ProfilegcgonlineComponent } from "./components/gcgonline/profilegcgonline/profilegcgonline.component";
import { PartnershipWorkshopListComponent } from "./components/partnershipworkshop/workshoplist/workshoplist.component";
import { PartnershipWorkshopDetailComponent } from "./components/partnershipworkshop/workshopdetail/workshopdetail.component";
import { SupplierListComponent } from "./components/partnershipworkshop/supplierlist/supplierlist.component";
import { SupplierdetailComponent } from "./components/partnershipworkshop/supplierdetail/supplierdetail.component";
import { SurveyonlineComponent } from "./components/surveyonline/surveyonline.component";
import { DashboardClaimComponent } from "./components/dashboards/dashboardclaim/dashboardclaim.component";
import { CustomerdataComponent } from "./components/apu-ppt/customerdata/customerdata.component";
import { RiskbasedassessmentComponent } from "./components/apu-ppt/riskbasedassessment/riskbasedassessment.component";
import { TransactiondetailComponent } from "./components/apu-ppt/transaction/transactiondetail/transactiondetail.component";
import { TransactionlistComponent } from "./components/apu-ppt/transaction/transactionlist/transactionlist.component";
// import { PartnershipWorkshopListComponent } from "./components/partnershipworkshop/workshoplist/workshoplist.component";
// import { PartnershipWorkshopDetailComponent } from "./components/partnershipworkshop/workshopdetail/workshopdetail.component";
// import { SupplierListComponent } from "./components/partnershipworkshop/supplierlist/supplierlist.component";
import { PartnershipWorkshopComponent } from "./components/partnershipworkshop/partnershipworkshop/partnershipworkshop.component";
import { TaxRegulationListComponent } from "./components/brinstax/taxregulation/taxregulationlist/taxregulationlist.component";
import { TaxRegulationDetailComponent } from "./components/brinstax/taxregulation/taxregulationdetail/taxregulationdetail.component";
import { TaxReportListComponent } from "./components/brinstax/taxreport/taxreportlist/taxreportlist.component";
import { TaxReportDetailComponent } from "./components/brinstax/taxreport/taxreportdetail/taxreportdetail.component";
import { ProjectmanagementlistComponent } from "./components/brinspro/projectmanagement/projectmanagementlist/projectmanagementlist.component";
import { ProjectmanagementdetailComponent } from "./components/brinspro/projectmanagement/projectmanagementdetail/projectmanagementdetail.component";
import { ProjectmanagementdashboardComponent } from "./components/brinspro/projectmanagementdashboard/projectmanagementdashboard.component";
import { ProjectmanagementdocumentdetailComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentdetail/projectmanagementdocumentdetail.component";
import { ProjectmanagementdocumentlistComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentlist/projectmanagementdocumentlist.component";
import { DashboardewslistComponent } from "./components/earlywarningsignal/dashboardews/dashboardewslist/dashboardewslist.component";
import { DashboardewsdetailComponent } from "./components/earlywarningsignal/dashboardews/dashboardewsdetail/dashboardewsdetail.component";
import { FollowupplanewsinsertComponent } from "./components/earlywarningsignal/followupplanews/followupplanewsinsert/followupplanewsinsert.component";
import { FollowupplanewsdetailComponent } from "./components/earlywarningsignal/followupplanews/followupplanewsdetail/followupplanewsdetail.component";
import { FollowupplanmonitoringewslistComponent } from "./components/earlywarningsignal/followupplanews/followupplanmonitoringews/followupplanmonitoringewslist/followupplanmonitoringewslist.component";
import { FollowupplanmonitoringewsdetailComponent } from "./components/earlywarningsignal/followupplanews/followupplanmonitoringews/followupplanmonitoringewsdetail/followupplanmonitoringewsdetail.component";
import { ReportewsComponent } from "./components/earlywarningsignal/reportews/reportews.component";
import { DashboardsmartComponent } from "./components/dashboards/dashboardsmart/dashboardsmart.component";
import { DashboardTicketComponent } from "./components/dashboards/dashboardticket/dashboardticket.component";
// import { SupplierdetailComponent } from "./components/partnershipworkshop/supplierdetail/supplierdetail.component";
import { KeyperformanceapprovallistComponent } from "./components/brinsprime/keyperformancebranch/keyperformanceapprovallist/keyperformanceapprovallist.component";
import { KeyperformanceapprovaldetailComponent } from "./components/brinsprime/keyperformancebranch/keyperformanceapprovaldetail/keyperformanceapprovaldetail.component";
import { KeyperformancemonitoringlistComponent } from "./components/brinsprime/keyperformancemonitoringlist/keyperformancemonitoringlist.component";
import { FollowupplanewslistComponent } from "./components/earlywarningsignal/followupplanews/followupplanewslist/followupplanewslist.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();
const maskConfig: Partial<IConfig> = {validation: false,};


@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			TableControlIncludeComponent,
			BarchartIncludeComponent,
			GroupbarchartIncludeComponent,
			PiechartIncludeComponent,
			ClaimListComponent,
			ClaimDetailComponent,
			SubmissionSubmitReportListComponent,
			SubmissionSubmitReportDetailComponent,
			SubmissionSubmittedListComponent,
			SubmissionSubmittedDetailComponent,
			ApprovalConfirmationListComponent,
			ApprovalConfirmationDetailComponent,
			ApprovalConfirmedListComponent,
			ApprovalConfirmedDetailComponent,
			ApprovalNotVerifiedListComponent,
			ApprovalNotVerifiedDetailComponent,
			ApprovalConfirmedNotVerifiedDetailComponent,
			ApprovalConfirmedNotVerifiedListComponent,
			ApprovalApprovalListComponent,
			ApprovalApprovalDetailComponent,
			ApprovalapprovalInformationComponent,
			ApprovalApprovadDetailComponent,
			ApprovalApprovedListComponent,
			BranchComponent,
			TicketdetailComponent,
			TicketlistinboxComponent,
			TicketlistoutboxComponent,
			TicketinsertcustomerComponent,
			TicketServiceInsertComponent,
			TicketServiceListComponent,
			TicketServiceUpdateComponent,
			TicketServiceAgentListComponent,
			TicketServiceAgentInsertComponent,
			TicketSatisfactionComponent,
			TicketReportComponent,
			TicketReportSatisfactionComponent,
			MonitoringListComponent,
			MonitoringDetailComponent,
			MonitoringInformationComponent,
			// PartnershipWorkshopComponent,
			WorkshopListComponent,
			WorkshopDetailComponent,
			SurveyorListComponent,
			SurveyorDetailComponent,
			CertificateRequestDetailComponent,
			CertificateRequestListComponent,
			CertificateRequestInsertComponent,
			CertificateApprovedListComponent,
			CertificateApprovedDetailComponent,
			DownloadComponent,
			NotificationLossAdviceListComponent,
			NotificationLossAdviceDetailComponent,
			AdjusterlistComponent,
			AdjusterdetailComponent,
			ApprovalnotificationlossadvicelistforcheckerComponent,
			ApprovalnotificationlossadvicedetailforcheckerComponent,
			ApprovalnotificationlossadvicelistforsignerComponent,
			ApprovalnotificationlossadvicedetailforsignerComponent,
			AdjustermakerforbranchlistComponent,
			AdjustermakerforbranchdetailComponent,
			ApprovaladjustercheckerforbranchlistComponent,
			ApprovaladjustercheckerforbranchdetailComponent,
			ApprovaladjustersignerforbranchdetailComponent,
			ApprovaladjustersignerforbranchlistComponent,
			AdjustedlistComponent,
			AdjusteddetailComponent,
			HistorynotificationlistComponent,
			HistorynotificationdetailComponent,
			ReportComponent,
			ApprovalcorrectionforcheckerdetailComponent,
			ApprovalcorrectionforcheckerlistComponent,
			ApprovalReimbursementListComponent,
			DentalreimbursementdetailComponent,
			OpticreimbursementdetailComponent,
			PostmaternityreimbursementdetailComponent,
			PrematernityreimbursementdetailComponent,
			MaternityreimbursementdetailComponent,
			AssessmentlistComponent,
			AssessmentdetailComponent,
			ApprovalrcsalistComponent,
			ApprovalrcsadetailComponent,
			ReportdetailComponent,
			ReportrcsalistComponent,
			RiskissuerequestlistComponent,
			RiskissuerequestdetailComponent,
			SubmissionInsertComponent,
			SubmissionListComponent,
			SubmissionDetailComponent,
			AnomalyListComponent,
			AnomalyInsertComponent,
			AnomalyDetailComponent,
			AnomalyDownloadComponent,
			AnomalyNotificationComponent,
			SalvageListComponent,
			SalvageInsertComponent,
			SalvageDetailComponent,
			CheckDevToolsDirective,
			SalvagecheckerDetailComponent,
			SalvagecheckerListComponent,
			SalvagesignerDetailComponent,
			SalvagesignerListComponent,
			DashboardraslistComponent,
			DashboardrasdetailComponent,
			FollowupplanlistComponent,
			FollowupplandetailComponent,
			SubmissionsalvagelistComponent,
			MonitoringsalvagelistComponent,
			ApprovalsalvagelistComponent,
			SalvagebuyerlistComponent,
			SalvagebuyerdetailComponent,
			SubmissionsalvagedetailComponent,
			SubmissionebiddinglistComponent,
			EmateraicheckersignerlistComponent,
			EmateraicheckersignerdetailComponent,
			EmateraimakerlistComponent,
			EmateraimakerdetailComponent,
			ProfilegcgonlineComponent,
			GratificationlistComponent,
			GratificationdetailComponent,
			IntegritypactlistComponent,
			IntegritypactinsertComponent,
			IntegritypactdetailComponent,
			CodeofethicslistComponent,
			CodeofethicsinsertComponent,
			CodeofethicsdetailComponent,
			CodeofethicscheckersignerlistComponent,
			CodeofethicscheckersignerdetailComponent,
			AnnualdisclosurelistComponent,
			AnnualdisclosureinsertComponent,
			AnnualdisclosuredetailComponent,
			AnnualdisclosurecheckersignerlistComponent,
			AnnualdisclosurecheckersignerdetailComponent,
			PartnershipWorkshopComponent,
			PartnershipWorkshopListComponent,
			PartnershipWorkshopDetailComponent,
			SupplierListComponent,
			SupplierdetailComponent,
			SurveyonlineComponent,
			DashboardClaimComponent,
			BarchartIncludeComponent,
			CustomerdataComponent,
			RiskbasedassessmentComponent,
			TransactiondetailComponent,
			TransactionlistComponent,
			PartnershipWorkshopListComponent,
			PartnershipWorkshopDetailComponent,
			SupplierListComponent,
			SupplierdetailComponent,
			SurveyonlineComponent,
			TaxRegulationListComponent,
			TaxRegulationDetailComponent,
			TaxReportListComponent,
			TaxReportDetailComponent,
			KeyperformanceapprovallistComponent,
			KeyperformanceapprovaldetailComponent,
			KeyperformancemonitoringlistComponent,
			ProjectmanagementlistComponent,
			ProjectmanagementdetailComponent,
			ProjectmanagementdashboardComponent,
			ProjectmanagementdocumentdetailComponent,
			ProjectmanagementdocumentlistComponent,
			BarchartIncludeComponent,
			GroupbarchartIncludeComponent,
			PiechartIncludeComponent,
			DashboardewslistComponent,
			DashboardewsdetailComponent,
			FollowupplanewsinsertComponent,
			FollowupplanewsdetailComponent,
			FollowupplanewslistComponent,
			FollowupplanmonitoringewslistComponent,
			FollowupplanmonitoringewsdetailComponent,
			ReportewsComponent,
			DashboardsmartComponent,
			DashboardTicketComponent,
			TaxRequestListComponent,
			TaxRequestAddComponent,
			TaxRequestDetailComponent,
			KeyperformanceapprovallistComponent,
			KeyperformanceapprovaldetailComponent,
			KeyperformancemonitoringlistComponent
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			ReactiveFormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			MatInputModule,
			MatChipsModule,
			MatStepperModule,
			MatNativeDateModule,
			MatDatepickerModule,
			CdkStepperModule,
			NgSelectModule,
			NgxMaskDirective,
			NgxMaskPipe,
			GoogleMapsModule,
			PdfViewerModule,
			DragDropModule,
			NgxEchartsModule.forRoot
			(
				{
					echarts: () => import("echarts")
				}
			),
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: environment.serviceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			}),
			NgxEchartsModule.forRoot
			(
				{
					echarts: () => import("echarts")
				}
			),
		],
		providers:
		[
			GoogleMap,
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			},
			provideEnvironmentNgxMask(maskConfig)
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion

//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;
	static STRING_URL_INTERNAL_WEB: string = environment.URL_INTERNAL_WEB;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORCLAIM: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForClaim`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";
	static 	STRING_URL_DASHBOARD_SELECTDASHBOARDSUMMARYFORCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardSummaryForClaim`;
	static 	STRING_URL_DASHBOARD_SELECTINCOMINGCLAIMBYRANK: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectIncomingClaimByRank`;
	static 	STRING_URL_DASHBOARD_SELECTINITIALWORKORDERBYRANK: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectInitialWorkOrderByRank`;
	static 	STRING_URL_DASHBOARD_SELECTMOSTINSUREDNAME: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMostInsuredName`;
	static 	STRING_URL_DASHBOARD_SELECTMOSTVEHICLEMODEL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMostVehicleModel`;
	static 	STRING_URL_DASHBOARD_SELECTMOSTPANEL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMostPanel`;
	static 	STRING_URL_DASHBOARD_SELECTMOSTPARTNERSHIPWORKSHOP: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMostPartnershipWorkshop`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILINCOMINGCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailIncomingClaim`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILVERIFICATIONCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailVerificationClaim`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILSURVEYCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailSurveyClaim`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILADJUSTMENTCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailAdjustmentClaim`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILREJECTCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailRejectClaim`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILMOSTINSUREDCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailMostInsuredClaim`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILMOSTVEHICLECLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailMostVehicleClaim`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILMOSTPANELCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailMostPanelClaim`;
	static 	STRING_URL_DASHBOARD_SELECTDETAILMOSTPARTNERSHIPWORKSHOPCLAIM: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDetailMostPartnershipWorkshopClaim`;

	static STRING_URL_DASHBOARD_SELECTPRODUCTFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForAccountingAndFinance`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTBRANCHPREMIUMREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectBranchPremiumRevenue`;
	static STRING_URL_DASHBOARD_SELECTREVENUEANDPROPORTIONALTARGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRevenueAndProportionalTarget`;
	static STRING_URL_DASHBOARD_SELECTMONEYNONMONEY: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMoneyNonMoney`;
	static STRING_URL_DASHBOARD_SELECTMICROPOLICYAMOUNT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMicroPolicyAmount`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARRELATIONSHIPMANAGERREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRelationshipManagerRevenue`;
	static STRING_URL_DASHBOARD_SELECTGENERALPOSTREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectGeneralPostRevenue`;
	static STRING_URL_DASHBOARD_SELECTDAILYBRANCHPOSEMMOVEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDailyBranchPosemMovement`;
	static STRING_URL_DASHBOARD_SELECTPAIDPOSEM: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPaidPosem`;
	static STRING_URL_DASHBOARD_SELECTPOLICYRENEWAL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPolicyRenewal`;
	static STRING_URL_DASHBOARD_SELECTUNDERWRITINGACHIEVEMENTRESULT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectUnderwritingAchievementResult`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRevenue`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARREVENUEBYSEGMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRevenueBySegment`;
	static STRING_URL_DASHBOARD_SELECTPOSEMDETAILBYBRANCH: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPosemDetailByBranch`;
	static STRING_URL_DASHBOARD_SELECTPOSEMDETAILBYDAY: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPosemDetailByDay`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDTICKETTRAFFIC: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardTicketTraffic`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDTICKETTREND: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardTicketTrend`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDTICKETSERVICELEVELAGREEMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardTicketServiceLevelAgreement`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDTICKETCUSTOMERSATISFACTION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardTicketCustomerSatisfaction`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDTICKETDISTRIBUTIONBYWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardTicketDistributionByWorkingUnit`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDTICKETDISTRIBUTIONBYSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardTicketDistributionByServiceAgent`;

	static STRING_URL_DASHBOAD_SELECTDASHBOARDDAILYFOROPERATIONANDSUPPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDailyForOperationAndSupportByAttributes`;
	static STRING_URL_DASHBOAD_SELECTDASHBOARDDAILYFOROPERATIONANDSUPPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDailyForOperationAndSupportByToken`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDocumentStampCollectorSummaryByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORDASHBOARD: string = "/DocumentStampCollectorDashboard";

	static 	STRING_URL_DOCUMENTSTAMPCOLLECTORDASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORDASHBOARD}/selectDocumentStampCollectorDashboardByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP ENTERPRISE DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEDASHBOARD: string = "/DocumentStampEnterpriseDashboard";

	static 	STRING_URL_DOCUMENTSTAMPENTERPRISEDASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEDASHBOARD}/selectDocumentStampEnterpriseDashboardByAttributes`;

	//#endregion


	//#region CONTROLLER UPDATE DOCUMENT STAMP COLLECTOR STATIC

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORSTATIC: string = "/DocumentStampCollectorStatic";

	static 	STRING_URL_DOCUMENTSTAMPCOLLECTORSTATIC_UPDATEDASHBOARDDOCUMENTSTAMPCOLLECTORSTATICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORSTATIC}/updateDocumentStampCollectorStaticByAttributes`;

	//#endregion


	//#region CONTROLLER UPDATE DOCUMENT STAMP ENTERPRISE STATIC

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISESTATIC: string = "/DocumentStampEnterpriseStatic";

	static 	STRING_URL_DOCUMENTSTAMPENTERPRISESTATIC_SELECTDASHBOARDDOCUMENTSTAMPENTERPRISESTATICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISESTATIC}/updateDocumentStampEnterpriseStaticByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT: string = "/DocumentStampCollectorReport";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributesForExcel`;

	//#endregion


	// //#region LOGISTIC CONTROLLER

	// static STRING_PATH_CONTROLLER_LOGISTIC: string = "/Logistic";

	// static STRING_URL_LOGISTIC_SELECTLOGISTICBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/selectLogisticByEmail`;
	// static STRING_URL_LOGISTIC_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/selectChecker`;
	// static STRING_URL_LOGISTIC_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/selectSigner`;
	// static STRING_URL_LOGISTIC_SELECTLOGISTICBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/selectLogisticByToken`;
	// static STRING_URL_LOGISTIC_VERIFYLOGISTICBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/verifyLogisticByEmail`;
	// static STRING_URL_LOGISTIC_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LOGISTIC}/signOut`;

	// //#endregion



	//#region CLAIM CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM: string = "/Claim";

	static STRING_URL_CLAIM_SELECTCLAIMBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByEmail`;
	static STRING_URL_CLAIM_SELECTCLAIMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByAttributes`;
	static STRING_URL_CLAIM_SELECTCLAIMBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByToken`;
	static STRING_URL_CLAIM_VERIFYCLAIMBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/verifyClaimByEmail`;
	static STRING_URL_CLAIM_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/signOut`;
	static STRING_URL_SELECTCHECKERBYCLAIMRESPONSIBILITY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectCheckerByClaimResponsibility`;
	static STRING_URL_SELECTSIGNERBYCLAIMRESPONSIBILITY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectSignerByClaimResponsibility`;
	static STRING_URL_NOTIFICATION_SELECTCHECKERBYBRANCHCODE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectCheckerByBranchCode`;
	static STRING_URL_NOTIFICATION_SELECTSIGNERBYBRANCHCODE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectSignerByBranchCode`;
	static STRING_URL_CLAIM_SELECTCLAIMBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByProfileID`;
	static STRING_URL_CLAIM_SELECTCLAIMBYCLAIMNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByClaimNumber`;


	//#endregion

	//#region CLAIM PAYMENT CONTROLLER

	static STRING_PATH_CONTROLLER_PAYMENT: string = "/Payment";

	static STRING_URL_CLAIM_SELECTPAYMENTBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentByProfileID`;

	static STRING_URL_CLAIM_SELECTPAYMENTDETAILBYANO: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentDetailByANO`;

	static STRING_URL_CLAIM_SELECTPAYMENTBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentByPolicyNumber`;


	//#endregion



	//#region CLAIM PROPOSAL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL: string = "/ClaimProposal";

	//#region SELECT

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALVERIFIEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalVerifiedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalNotVerifiedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSUBMITTEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalSubmittedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalConfirmedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDCONFIRMEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalNotVerifiedConfirmedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalApprovalByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalApprovedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTESFORHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalConfirmedByAttributesForHeadOffice`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForHeadOffice`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCLAIMCENTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalClaimCenter`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORMONITORING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForMonitoring`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSURVEYEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalSurveyedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALPENDINGFORMAKERHEADOFFICEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalPendingForMakerHeadOfficeByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALPENDINGFORCHECKERHEADOFFICEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalPendingForCheckerHeadOfficeByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMNOTIFICATIONLOSSADVICEBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectNotificationLossAdviceByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SENDEMAILNOTIFICATIONLOSSADVICENONMOTORVEHICLE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/sendEmailNotificationLossAdviceNonMotorVehicle`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTNOTIFICATIONLOSSADVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectNotificationLossAdviceByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORADJUSTERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForAdjusterByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORADJUSTERREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForAdjusterReportByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORADJUSTERBRANCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForAdjusterBranchByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORCHECKERADJUSTERBRANCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForCheckerAdjusterBranchByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORSIGNERADJUSTERBRANCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForSignerAdjusterBranchByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYATTRIBUTESFORMONITORINGEXCEL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByAttributesForMonitoringExcel`;
	static STRING_URL_CLAIM_PROPOSAL_CHECKCLAIMPROPOSALFORADJUSTERBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/checkClaimProposalForAdjusterByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SIGNCLAIMPROPOSALFORADJUSTERBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/signClaimProposalForAdjusterByToken`;
	static STRING_URL_CLAIM_PROPOSAL_CHECKCLAIMPROPOSALCORRECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/checkClaimProposalCorrectionByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCORRECTIONFORCHECKERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalCorrectionForCheckerByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORSURVEYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForSurveyByAttributes`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTNOTIFICATIONLOSSADVICEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectNotificationLossAdviceByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTNOTIFICATIONLOSSADVICEREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectNotificationLossAdviceReportByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTNOTIFICATIONLOSSADVICEBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectNotificationLossAdviceByAttributesForChecker`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTNOTIFICATIONLOSSADVICEBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectNotificationLossAdviceByAttributesForSigner`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTNOTIFICATIONLOSSADVICEBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectNotificationLossAdviceByTokenForChecker`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTNOTIFICATIONLOSSADVICEBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectNotificationLossAdviceByTokenForSigner`;
	static STRING_URL_CLAIM_PROPOSAL_CHECKNOTIFICATIONLOSSADVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/checkNotificationLossAdviceByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SIGNNOTIFICATIONLOSSADVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/signNotificationLossAdviceByToken`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEBYREFERENCENUMBER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicleByReferenceNumber`;

	//#endregion


	//#region CLAIM PROPOSA TRACKINGL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL: string = "/ClaimProposalTracking";

	//#region SELECT

	static STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALTRACKINGBYCLAIMPROPOSALTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/selectClaimProposalTrackingByClaimProposalToken`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_INSERTCLAIMPROPOSALTRACKING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/insertClaimProposalTracking`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_UPLOADPROPOSALTRACKING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/uploadClaimProposalTracking`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_UPDATE_CLAIM_PROPOSAL_TRACKING_BYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/updateClaimProposalTrackingByToken`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_DOWNLOAD_CLAIM_PROPOSAL_TRACKING_BYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/downloadClaimProposalTrackingByClaimProposalTokenForClaim`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_DELETEGALERRYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/deleteGalleryByToken`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALTRACKINGPAYMENT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/selectClaimProposalTrackingPayment`;

	//#endregion

	//#region CLAIM PROPOSAL QUALITY CONTROL

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_QUALITY_CONTROL: string = "/ClaimProposalQualityControl";

	static STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALQUALITYCONTROLBYCLAIMPROPOSALTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_QUALITY_CONTROL}/selectClaimProposalQualityControlByClaimProposalToken`;


	//#endregion


	//#region CLAIM PROPOSAL SATISFACTION CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION: string = "/ClaimProposalSatisfaction";

	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_INSERTCLAIMPROPOSALSATISFACTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION}/insertClaimProposalSatisfaction`;
	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_SELECTCLAIMPROPOSALSATISFACTIONBYCLAIMPROPOSALTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION}/selectClaimProposalSatisfactionByClaimProposalToken`;

	//#endregion


	//#region CLAIM PROPOSAL SATISFACTION CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION_QUESTION: string = "/ClaimProposalSatisfactionQuestion";

	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_SELECT_CLAIMPROPOSAL_SATISFACTION_QUESTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION_QUESTION}/selectClaimProposalSatisfactionQuestion`;

	//#endregion

	//#region APPROVE

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/approveClaimProposalByHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYCLAIMCENTERWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/approveClaimProposalByClaimCenterWithToken`;

	//#endregion


	//#region REJECT

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalByHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYCLAIMCENTERWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalByClaimCenterWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALFORADJUSTERBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalForAdjusterByToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALCORRECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalCorrectionByToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTNOTIFICATIONLOSSADVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectNotificationLossAdviceByToken`;

	//#endregion


	//#region VERIFY

	static STRING_URL_CLAIM_PROPOSAL_VERIFYCLAIMPROPOSALBYBRANCHWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/verifyClaimProposalByBranchWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_VERIFYCLAIMPROPOSALBYMAKERHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/verifyClaimProposalByMakerHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_VERIFYCLAIMPROPOSALBYCHECKERHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/verifyClaimProposalByCheckerHeadOfficeWithToken`;

	//#endregion


	//#region INSERT

	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposal`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALDIRECT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalDirect`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicle`;
	static STRING_URL_CLAIM_PROPOSAL_SUBMITCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/submitClaimProposal`;

	//#endregion


	//#region UPDATE

	static STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALCORRECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/updateClaimProposalCorrectionByToken`;
	static STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALSURVEYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/updateClaimProposalSurveyByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_UPDATENOTIFICATIONLOSSADVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/updateNotificationLossAdviceByToken`;
	static STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALADJUSTERFORHEADOFFICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/updateClaimProposalAdjusterForHeadOfficeByToken`;
	static STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALADJUSTERFORBRANCHBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/updateClaimProposalAdjusterForBranchByToken`;

	//#endregion


	//#region UPLOAD

	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposal`;

	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALFORNONMOTORVEHICLE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalForNonMotorVehicle`;

	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALSUPPORTINGGALLERY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalSupportingGallery`;

	static STRING_URL_CLAIM_PROPOSAL_UPLOADFORADJUSTERBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadForAdjusterBranchHead`;

	//#endregion


	//#region DOWNLOAD

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADKMK: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/downloadKMK`;
	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADSTK: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/downloadSTK`;

	//#endregion


	//#region DOWNLOAD

	static STRING_URL_CLAIM_PROPOSAL_SENDEMAILCLAIMPROPOSALBYCLAIMCENTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/sendEmailClaimProposalByClaimCenter`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALFORADJUSTERDOCUMENTTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/downloadClaimProposalForAdjusterDocumentToken`;

	//#endregion


	//#endregion


	//#region RELATIONSHIP DAMAGE TYPE

	static STRING_PATH_CONTROLLER_DAMAGETYPE: string = "/DamageType";
	static STRING_URL_DAMAGETYPE_SELECTDAMAGETYPE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DAMAGETYPE}/selectDamageType`;

	//#endregion


	//#region SURVEY

	static STRING_PATH_CONTROLLER_SURVEY: string = "/Survey";
	static STRING_URL_SURVEY_APPROVESURVEYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/approveSurveyByToken`;
	static STRING_URL_SURVEY_RESURVEYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/resurveyByToken`;
	static STRING_URL_SURVEY_REJECTSURVEYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/rejectSurveyByToken`;
	static STRING_URL_SURVEY_VERIFYSURVEYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/verifySurveyByToken`;
	static STRING_URL_SURVEY_UPDATESURVEYWORKSHOPBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/updateSurveyWorkshopByToken`;
	static STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALMOTORVEHICLEFOROWNRETENTIONHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/updateClaimProposalMotorVehicleForOwnRetentionHeadOffice`;

	static STRING_URL_CLAIM_APPROVEITEMSSURVEYHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/approveItemsSurveyHeadOffice`;
	static STRING_URL_CLAIM_REJECTITEMSSURVEYHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/rejectItemsSurveyHeadOffice`;
	static STRING_URL_CLAIM_SELECTUSERSURVEYORBYBRANCHCODE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEY}/selectUserSurveyorByBranchCode`;

	//#endregion


	//#region SURVEY THIRDPARTY LIABILITY

	static STRING_PATH_CONTROLLER_SURVEYTHIRDPARTYLIABILITY: string = "/SurveyThirdPartyLiability";
	static STRING_URL_SURVEYTHIRDPARTYLIABILITY_APPROVESURVEYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEYTHIRDPARTYLIABILITY}/approveSurveyByToken`;
	static STRING_URL_SURVEYTHIRDPARTYLIABILITY_RESURVEYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEYTHIRDPARTYLIABILITY}/resurveyByToken`;
	static STRING_URL_SURVEYTHIRDPARTYLIABILITY_REJECTSURVEYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEYTHIRDPARTYLIABILITY}/rejectSurveyByToken`;
	static STRING_URL_SURVEYTHIRDPARTYLIABILITY_VERIFYSURVEYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEYTHIRDPARTYLIABILITY}/verifySurveyByToken`;
	static STRING_URL_SURVEYTHIRDPARTYLIABILITY_APPROVEITEMSSURVEYTHIRDPARTYLIABILITYGALLERYHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEYTHIRDPARTYLIABILITY}/approveItemsSurveyThirdPartyLiabilityGalleryHeadOffice`;
	static STRING_URL_SURVEYTHIRDPARTYLIABILITY_REJECTITEMSSURVEYTHIRDPARTYLIABILITYGALLERYHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SURVEYTHIRDPARTYLIABILITY}/rejectItemsSurveyThirdPartyLiabilityGalleryHeadOffice`;

	//#endregion


	//#region CLAIM PROPOSAL APPEAL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALAPPEAL: string = "/ClaimProposalAppeal";

	static STRING_URL_CLAIMPROPOSALAPPEAL_INSERTCLAIMPROPOSALAPPEAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALAPPEAL}/insertClaimProposalAppeal`;
	static STRING_URL_CLAIMPROPOSALAPPEAL_UPLOADCLAIMPROPOSALAPPEALGALLERY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALAPPEAL}/uploadClaimProposalAppealGallery`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE SUBMISSION LIST CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGESUBMISSIONLIST: string = "/ClaimProposalSalvageSubmissionList";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGESUBMISSIONLIST: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGESUBMISSIONLIST}/selectClaimProposalSalvageSubmissionListForEBiddingBranchOfficeMaker`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE SUBMISSION DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGESUBMISSIONDETAIL: string = "/ClaimProposalSalvageSubmissionDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGESUBMISSIONDETAIL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGESUBMISSIONDETAIL}/selectClaimProposalSalvageSubmissionDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SUBMITCLAIMPROPOSALSALVAGESUBMISSIONDETAIL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGESUBMISSIONDETAIL}/submitClaimProposalSalvageSubmissionDetailForEBiddingBranchOfficeMaker`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE OPERATIONAL GUIDLINE SUBMISSION DETAIL

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEOPERATIONALGUIDLINESSUBMISSIONDETAIL: string = "/ClaimProposalSalvageOperationalGuidelinesSubmissionDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEOPERATIONALGUIDLINE_SUBMISSIONDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEOPERATIONALGUIDLINESSUBMISSIONDETAIL}/selectClaimProposalSalvageOperationalGuidelinesSubmissionDetailForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEOPERATIONALGUIDLINE_SUBMISSIONDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEOPERATIONALGUIDLINESSUBMISSIONDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SUBMITCLAIMPROPOSALSALVAGEOPERATIONALGUIDLINE_SUBMISSIONDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEOPERATIONALGUIDLINESSUBMISSIONDETAIL}/submitClaimProposalSalvageOperationalGuidelinesSubmissionDetailForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGEOPERATIONALGUIDLINE_SUBMISSIONDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEOPERATIONALGUIDLINESSUBMISSIONDETAIL}/rejectClaimProposalSalvageOperationalGuidelinesSubmissionDetailForEBiddingHeadOfficeMaker`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE INVITATION SUBMISSION DETAIL

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONSUBMISSIONDETAIL: string = "/ClaimProposalSalvageInvitationSubmissionDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEINVITATION_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONSUBMISSIONDETAIL}/selectClaimProposalSalvageInvitationSubmissionDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEINVITATION_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONSUBMISSIONDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEINVITATIONGUIDLINE_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONSUBMISSIONDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SUBMISSIONCLAIMPROPOSALSALVAGEINVITATION_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONSUBMISSIONDETAIL}/submitClaimProposalSalvageInvitationSubmissionDetailForEBiddingBranchOfficeMaker`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE GUIDLINES AND INVITATION SUBMISSION DETAIL

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEGUIDLINEANDINVITATIONSUBMISSIONDETAIL: string = "/ClaimProposalSalvageOperationalGuidelinesAndInvitationSubmissionDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEINVITATIONANDGUIDLINE_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEGUIDLINEANDINVITATIONSUBMISSIONDETAIL}/selectClaimProposalSalvageOperationalGuidelinesAndInvitationSubmissionDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEINVITATIONANDGUIDLINE_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEGUIDLINEANDINVITATIONSUBMISSIONDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SUBMISSIONCLAIMPROPOSALSALVAGEGUIDLINEANDINVITATION_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEGUIDLINEANDINVITATIONSUBMISSIONDETAIL}/submitClaimProposalSalvageOperationalGuidelinesAndInvitationSubmissionDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SUBMISSIONCLAIMPROPOSALSALVAGEGUIDLINEANDINVITATION_SUBMITSALVAGEBUYER_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEGUIDLINEANDINVITATIONSUBMISSIONDETAIL}/submitClaimProposalSalvageBuyerForEBiddingBranchOfficeMaker`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE WINNER DETERMINATION DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONSUBMISSIONDETAIL: string = "/ClaimProposalSalvageWinnerDeterminationSubmissionDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEWINNERDETERMINATION_SUBMISSIONDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONSUBMISSIONDETAIL}/selectClaimProposalSalvageWinnerDeterminationSubmissionDetailForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEWINNERDETERMINATION_SUBMISSIONDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONSUBMISSIONDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGEWINNERDETERMINATION_SUBMISSIONDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONSUBMISSIONDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTBUYERCLAIMPROPOSALSALVAGEWINNERDETERMINATION_SUBMISSIONDETAIL_HEADHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONSUBMISSIONDETAIL}/selectBuyerForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SUBMISSIONCLAIMPROPOSALSALVAGEWINNERDETERMINATION_SUBMISSIONDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONSUBMISSIONDETAIL}/submitClaimProposalSalvageWinnerDeterminationSubmissionDetailForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_CANCELCLAIMPROPOSALSALVAGE_WINNERDETERMINATIONSUBMISSIONDETAIL_FOREBIDDING_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONSUBMISSIONDETAIL}/cancelSalvageForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_CANCELOPERATIONALGUIDELINES_WINNERDETERMINATIONANDLETTER_SUBMISSIONDETAIL_FOREBIDDING_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONSUBMISSIONDETAIL}/cancelWinnerDeterminationForEBiddingHeadOfficeMaker`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE WINNER DETERMINATION AND WINNER LETTER SUBMISSION DETAIL

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTERSUBMISSIONDETAIL: string = "/ClaimProposalSalvageWinnerDeterminationAndWinnerLetterSubmissionDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTERSUBMISSIONDETAIL}/selectClaimProposalSalvageWinnerDeterminationAndWinnerLetterSubmissionDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDLETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTERSUBMISSIONDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDLETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTERSUBMISSIONDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTBUYERCLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDLETTER_SUBMISSIONDETAIL_BRANCHHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTERSUBMISSIONDETAIL}/selectBuyerForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SUBMISSIONCLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDLETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTERSUBMISSIONDETAIL}/submitClaimProposalSalvageWinnerDeterminationAndWinnerLetterSubmissionDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_CANCELCLAIMPROPOSALSALVAGE_WINNERDETERMINATIONANDLETTER_SUBMISSIONDETAIL_FOREBIDDING_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTERSUBMISSIONDETAIL}/cancelSalvageForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_CANCELOPERATIONALGUIDELINES_WINNERDETERMINATIONANDLETTER_SUBMISSIONDETAIL_FOREBIDDING_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONANDWINNERLETTERSUBMISSIONDETAIL}/cancelWinnerDeterminationForEBiddingBranchOfficeMaker`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE WINNER DETERMINATION AND WINNER LETTER SUBMISSION DETAIL

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTERSUBMISSIONDETAIL: string = "/ClaimProposalSalvageWinnerLetterSubmissionDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEWINNERLETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTERSUBMISSIONDETAIL}/selectClaimProposalSalvageWinnerLetterSubmissionDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEWINNERETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTERSUBMISSIONDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGEWINNERLETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTERSUBMISSIONDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERDOCUMENTGUIDLINECLAIMPROPOSALSALVAGEWINNERLETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTERSUBMISSIONDETAIL}/downloadWinnerSupportingDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SUBMISSIONCLAIMPROPOSALSALVAGEWINNERLETTER_SUBMISSIONDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTERSUBMISSIONDETAIL}/submitClaimProposalSalvageWinnerLetterSubmissionDetailForEBiddingBranchOfficeMaker`;


	//#endregion


	//#region CLAIM PROPOSAL SALVAGE WINNER DETERMINATION APPROVAL DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL: string = "/ClaimProposalSalvageWinnerDeterminationApprovalDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/selectClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERSUPPORTINGDOCPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadWinnerSupportingDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_WINNERDETERMINATIONAPPROVALDETAIL_FOREBIDDING_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/approveClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_WINNERDETERMINATIONAPPROVALDETAIL_FOREBIDDING_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/rejectClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/selectClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERSUPPORTINGDOCPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadWinnerSupportingDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_WINNERDETERMINATIONAPPROVALDETAIL_FOREBIDDING_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/approveClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_WINNERDETERMINATIONAPPROVALDETAIL_FOREBIDDING_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/rejectClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/selectClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERSUPPORTINGDOCPROPOSALSALVAGEWINNERDETERMINATION_APPROVALDETAIL_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/downloadWinnerSupportingDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_WINNERDETERMINATIONAPPROVALDETAIL_FOREBIDDING_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/approveClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_WINNERDETERMINATIONAPPROVALDETAIL_FOREBIDDING_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERDETERMINATIONAPPROVALDETAIL}/rejectClaimProposalSalvageWinnerDeterminationApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE MONITORING LIST CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST: string = "/ClaimProposalSalvageMonitoringList";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_MONITORINGLIST_FOREBIDDINGBRANCHMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEMONITORINGLISTCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_MONITORINGLIST_FOREBIDDINGHEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_MONITORINGLIST_FOREBIDDINGHEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingHeadOfficeChecker`;

	static	STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_MONITORINGLIST_FOREBIDDINGHEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingHeadOfficeFirstSigner`;

	static	STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_MONITORINGLIST_FOREBIDDINGHEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingHeadOfficeSecondSigner`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE MONITORING DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL: string = "/ClaimProposalSalvageMonitoringDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEMONITORINGDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/selectClaimProposalSalvageMonitoringDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADINVITATIONPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadInvitationDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERSUPPORTINGDOCPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadWinnerSupportingDocumentForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEMONITORINGDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/selectClaimProposalSalvageMonitoringDetailForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADINVITATIONPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadInvitationDocumentForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERSUPPORTINGDOCPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadWinnerSupportingDocumentForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEMONITORINGDETAIL_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/selectClaimProposalSalvageMonitoringDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADINVITATIONPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadInvitationDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERSUPPORTINGDOCPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadWinnerSupportingDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEMONITORINGDETAIL_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/selectClaimProposalSalvageMonitoringDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADINVITATIONPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadInvitationDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERSUPPORTINGDOCPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadWinnerSupportingDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGEMONITORINGDETAIL_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/selectClaimProposalSalvageMonitoringDetailForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADOPERATIONALGUIDLINECLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADINVITATIONPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadInvitationDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADWINNERSUPPORTINGDOCPROPOSALSALVAGEWINNERDETERMINATION_MONITORINGDETAIL_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadWinnerSupportingDocumentForEBiddingHeadOfficeSecondSigner`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE APPROVAL LIST CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST: string = "/ClaimProposalSalvageApprovalList";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_APPROVALLIST_FOREBIDDING_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST}/selectClaimProposalSalvageApprovalListForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_APPROVALLIST_FOREBIDDING_HEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST}/selectClaimProposalSalvageApprovalListForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_APPROVALLIST_FOREBIDDING_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST}/selectClaimProposalSalvageApprovalListForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_APPROVALLIST_FOREBIDDING_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST}/selectClaimProposalSalvageApprovalListForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_APPROVALLIST_FOREBIDDING_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST}/selectClaimProposalSalvageApprovalListForEBiddingHeadOfficeSecondSigner`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE APPROVAL DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL: string = "/ClaimProposalSalvageApprovalDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_APPROVALDETAIL_FOREBIDDING_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/selectClaimProposalSalvageApprovalDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_REVISECLAIMPROPOSALSALVAGE_APPROVALDETAIL_FOREBIDDING_BRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/reviseClaimProposalSalvageApprovalDetailForEBiddingBranchOfficeMaker`;


	//#endregion


	//#region CLAIM PROPOSAL SALVAGE OPERATIONAL GUIDLINE APPROVAL DETAIL
	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL: string = "/ClaimProposalSalvageOperationalGuidelinesApprovalDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/selectClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_APPROVALDETAIL_FOREBIDDING_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/approveClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/rejectClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/selectClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_APPROVALDETAIL_FOREBIDDING_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/approveClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/rejectClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/selectClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_APPROVALDETAIL_FOREBIDDING_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/approveClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL_FOREBIDDING_HEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGE_OPERATIONALGUIDLINEAPPROVALDETAIL}/rejectClaimProposalSalvageOperationalGuidelinesApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	//#endregion


	//#region CLAIM PROPOSAL MASTER DATA SALVAGE BUYER LIST CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST: string = "/ClaimProposalSalvageBuyerList";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/selectClaimProposalSalvageBuyerListForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/downloadApprovalDocumentForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DEACTIVECLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/deactivateClaimProposalSalvageBuyerListForEBiddingHeadOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_DEACTIVECLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICESECONDMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/deactivateClaimProposalSalvageBuyerListForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/selectClaimProposalSalvageBuyerListForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/downloadApprovalDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/selectClaimProposalSalvageBuyerListForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/downloadApprovalDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/selectClaimProposalSalvageBuyerListForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_BUYERLIST_FORHEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERLIST}/downloadApprovalDocumentForEBiddingHeadOfficeSecondSigner`;
	//#endregion


	//#region CLAIM PROPOSAL MASTER DATA SALVAGE BUYER SUBMISSION DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERSUBMISSIONDETAIL: string = "/ClaimProposalSalvageBuyerSubmissionDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_BUYERSUBMISSIONDETAIL_FORHEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERSUBMISSIONDETAIL}/submitClaimProposalSalvageBuyerSubmissionDetailForEBiddingHeadOfficeMaker`;

	//#endregion


	//#region CLAIM PROPOSAL MASTER DATA SALVAGE BUYER DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL: string = "/ClaimProposalSalvageBuyerApprovalDetail";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/selectClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/downloadApprovalDocumentForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/approveClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/rejectClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/selectClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/downloadApprovalDocumentForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/approveClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/rejectClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/selectClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/downloadApprovalDocumentForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/approveClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALSALVAGE_BUYERDETAIL_FORHEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEBUYERDETAIL}/rejectClaimProposalSalvageBuyerApprovalDetailForEBiddingHeadOfficeSecondSigner`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE INPUT DLA CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINPUTDLA: string = "/ClaimProposalSalvegeInputDLA";

	static STRING_URL_SELECTCLAIMPROPOSALSALVAGECSTYPE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINPUTDLA}/selectClaimProposalSalvageCSType`;
	static STRING_URL_SELECTCLAIMPROPOSALSALVAGEINTEREST: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINPUTDLA}/selectClaimProposalSalvageInterest`;
	static STRING_URL_SELECTCLAIMPROPOSALSALVAGEPAIDBY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINPUTDLA}/selectClaimProposalSalvagePaidBy`;
	static STRING_URL_SUBMITCLAIMPROPOSALSALVAGEFLAFOREBIDDINGHEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINPUTDLA}/submitClaimProposalSalvageDLAForEBiddingHeadOfficeMaker`;
	static STRING_URL_SELECTCLAIMPROPOSALSALVAGEDETAILDLAFOREBIDDINGBRANCHMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINPUTDLA}/selectClaimProposalSalvageDetailDLAForEBiddingBranchMaker`;
	static STRING_URL_DOWNLOADCLAIMPROPOSALSALVAGEDLADOCUMENTFOREBIDDING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINPUTDLA}/downloadClaimProposalSalvageDLADocumentForEBidding`;

	//#endregion


	//#region LICENSE PLATE PREFIX

	static STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX: string = "/LicensePlatePrefix";
	static STRING_URL_LICENSEPLATEPREFIX_SELECTLICENSEPLATEPREFIX: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX}/selectLicensePlatePrefix`;

	//#endregion


	//#region RELATIONSHIP CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIP: string = "/Relationship";
	static STRING_URL_RELATIONSHIP_SELECTRELATIONSHIP: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIP}/selectRelationship`;

	//#endregion


	//#region POLICY GENERAL

	static STRING_PATH_CONTROLLER_POLICYGENERAL: string = "/PolicyGeneral";
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByRelationshipManager`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYACCEPTANCENUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAcceptanceNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBERFORCLAIM: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyNumberForClaim`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYREFERENCENUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByReferenceNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYREFERENCENUMBERFORCLAIM: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByReferenceNumberForClaim`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByProfileID`;

	//#endregion


	//#region CUSTOMER CONTROLLER

	static STRING_PATH_CONTROLLER_CUSTOMER: string = "/Customer";

	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByProfileID`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByPolicyNumber`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByLicensePlate`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYHOLDERATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByPolicyholderAttributes`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerByEmail`;
	static STRING_URL_CUSTOMER_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/signOut`;

	//#endregion


	//#region VEHICLE LOCATION

	static STRING_PATH_CONTROLLER_VEHICLELOCATION: string = "/VehicleLocation";
	static STRING_URL_VEHICLELOCATION_SELECTVEHICLELOCATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocation`;
	static STRING_URL_VEHICLELOCATION_SELECTVEHICLELOCATIONFORCLAIM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocationForClaim`;

	//#endregion


	//#region DECLARATION

	static STRING_PATH_CONTROLLER_DECLARATION: string = "/Declaration";

	static STRING_URL_DECLARATION_INSERTDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/insertDeclaration`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForMaker`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForChecker`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForSigner`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByToken`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationQRByToken`;
	static STRING_URL_DECLARATION_DELETEDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/deleteDeclarationByToken`;
	static STRING_URL_DECLARATION_UPDATEDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/updateDeclarationByToken`;
	static STRING_URL_DECLARATION_REJECTDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/rejectDeclarationByToken`;
	static STRING_URL_DECLARATION_CHECKDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/checkDeclarationByToken`;
	static STRING_URL_DECLARATION_SIGNDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/signDeclarationByToken`;
	static STRING_URL_DECLARATION_UPLOADDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/uploadDeclaration`;

	//#endregion


	//#region SUBMISSION

	static STRING_PATH_CONTROLLER_SUBMISSION: string = "/Submission";

	static STRING_URL_SUBMISSION_INSERTSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/insertSubmission`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForMaker`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForChecker`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForSigner`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByToken`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionQRByToken`;
	static STRING_URL_SUBMISSION_DELETESUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/deleteSubmissionByToken`;
	static STRING_URL_SUBMISSION_UPDATESUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/updateSubmissionByToken`;
	static STRING_URL_SUBMISSION_REJECTSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/rejectSubmissionByToken`;
	static STRING_URL_SUBMISSION_CHECKSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/checkSubmissionByToken`;
	static STRING_URL_SUBMISSION_SIGNSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/signSubmissionByToken`;
	static STRING_URL_SUBMISSION_UPLOADSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/uploadSubmission`;

	//#endregion


	//#region BANK ACCOUNT

	static STRING_PATH_CONTROLLER_BANKACCOUNT: string = "/BankAccount";

	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_INSERTBANKACCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/insertBankAccount`;
	static STRING_URL_BANKACCOUNT_UPDATEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/updateBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_DELETEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/deleteBankAccountByToken`;

	//#endregion


	//#region ACCOUNT CHARGE

	static STRING_PATH_CONTROLLER_ACCOUNTCHARGE: string = "/AccountCharge";

	static STRING_URL_ACCOUNTCHARGE_SELECTACCOUNTCHARGEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTCHARGE}/selectAccountChargeByAttributes`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHFORTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForTicketByAttributes`;
	static STRING_URL_BRANCH_SELECTBRANCHFORSURVEY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForSurvey`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocation`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATIONFORSURVEY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocationForSurvey`;
	static STRING_URL_BRANCH_SELECTBRANCHCONVENTIONALWITHHEADOFFICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchConventionalWithHeadOffice`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGBRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingBranchOfficeMaker`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGBRANCHOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingBranchOfficeChecker`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGHEADOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingHeadOfficeMaker`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGHEADOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingHeadOfficeChecker`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGHEADOFFICEFIRSTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingHeadOfficeFirstSigner`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGHEADOFFICESECONDSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingHeadOfficeSecondSigner`;

	static STRING_URL_BRANCH_SELECTBRANCHFORSUPPLIER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForSupplier`;

	static STRING_URL_BRANCH_SELECTBRANCHFORPARTNERSHIPWORKSHOP: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForPartnershipWorkshop`;

	//#endregion


	//#region PARTNERSHIP WORSKSHOP CONTROLLER

	static STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP: string = "/PartnershipWorkshop";

	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOP: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshop`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPFORSURVEY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopForSurvey`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPFORLIST: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopList`;

	static STRING_URL_PARTNERSHIPWORKSHOP_INSERTPARTNERSHIPWORKSHOP: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/insertPartnershipWorkshop`;
	static STRING_URL_PARTNESHHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopByToken`;
	static STRING_URL_PARTNERSHIPWORKSHOP_UPDATEPARTNERSHIPWORKSHOP: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/updatePartnershipWorkshop`;

	//#endregion


	//#region BRANCH COVERAGE CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCHCOVERAGE: string = "/BranchCoverage";

	static STRING_URL_BRANCHCOVERAGE_SELECTBRANCHCOVERAGE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHCOVERAGE}/selectBranchCoverage`;
	static STRING_URL_BRANCHCOVERAGE_SELECTBRANCHCOVERAGEFORNONMOTORVEHICLE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHCOVERAGE}/selectBranchCoverageForNonMotorVehicle`;

	//#endregion


	//#region BRI POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_BRIPOLICY: string = "/BRIPolicy";

	static STRING_URL_BRIPOLICY_CHECKBRIPOLICYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIPOLICY}/checkBRIPolicyByAttributes`;

	//#endregion


	//#region BRI ENDORSEMENT POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY: string = "/BRIEndorsementPolicy";

	static STRING_URL_BRIENDORSEMENTPOLICY_INSERTENDORSEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/insertEndorsement`;
	static STRING_URL_BRIENDORSEMENTPOLICY_UPLOADENDORSEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/uploadEndorsement`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementByAttributes`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementByToken`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTAPPROVAL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementApproval`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTFORVERIFY: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementForVerify`;
	static STRING_URL_BRIENDORSEMENTPOLICY_APPROVEENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/approveEndorsementByToken`;
	static STRING_URL_BRIENDORSEMENTPOLICY_REJECTENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/rejectEndorsementByToken`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region TICKET CONTROLLER

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_SELECTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByToken`;
	static STRING_URL_TICKET_SELECTTICKETPUBLICBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketPublicByToken`;
	static STRING_URL_TICKET_SELECTTICKETINBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketReceivedByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETOUTBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketSentByAttributes`;
	static STRING_URL_TICKET_SELECTREPORTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectReportTicketByAttributes`;
	static STRING_URL_TICKET_INSERTTICKET: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicket`;
	static STRING_URL_TICKET_INSERTTICKETFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForDivision`;
	static STRING_URL_TICKET_INSERTTICKETFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForCustomer`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByToken`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKENFORCLOSEWITHOUTCONFIRMATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByTokenForCloseWithoutConfirmation`;
	static STRING_URL_TICKET_SELECTREPORTTICKETDISTRIBUTIONBYWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectReportTicketDistributionByWorkingUnit`;
	static STRING_URL_TICKET_SELECTREPORTTICKETDISTRIBUTIONBYSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectReportTicketDistributionByServiceAgent`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETCONVERSATION: string = "/TicketConversation";

	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForDivision`;
	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForCustomer`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONPUBLICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationPublicByAttributes`;
	static STRING_URL_TICKET_UPDATETICKETCONVERSATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/updateTicketConversationByToken`;
	static STRING_URL_TICKET_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/uploadConversation`;

	//#endregion


	//#region TICKET DOCUMENT CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETDOCUMENT: string = "/TicketDocument";

	static STRING_URL_TICKET_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETDOCUMENT}/downloadConversationByDocumentToken`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region CAUSEOFLOSS

	static STRING_PATH_CONTROLLER_CAUSEOFLOSS: string = "/CauseOfLoss";

	static STRING_URL_CAUSEOFLOSS_SELECTCAUSEOFLOSS: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CAUSEOFLOSS}/selectCauseOfLoss`;

	//#endregion


	//#region REIMBURSEMENT GENERAL

	static STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL: string = "/reimbursementgeneral";

	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForChecker`;
	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForSigner`;

	//#endregion


	//#region DENTAL REIMBURSEMENT

	static STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT: string = "/dentalreimbursement";

	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForMaker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForChecker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForSigner`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SIGNDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/signDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REJECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/rejectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REVISEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/reviseDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_UPDATEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/updateDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementPlafondByUserID`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadLogisticDeclarationByDocumentToken?stringHandshakeToken=[HandshakeToken]&stringDeclarationDocumentToken=[DocumentToken]`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadDeclarationDocumentByTokenForLogistic?stringHandshakeToken=[HandshakeToken]&stringLetterGeneralToken=[DocumentToken]`;

	//#endregion


	//#region OPTIC REIMBURSEMENT

	static STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT: string = "/OpticReimbursement";

	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForMaker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForChecker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForSigner`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SIGNOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/signOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REJECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/rejectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REVISEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/reviseOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_UPDATEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/updateOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementPlafondByUserID`;
	static STRING_URL_OPTICREIMBURSEMENT_DOWNLOADOPTICREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/downloadOpticReimbursementGalleryByToken`;

	//#endregion


	//#region PREMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT: string = "/PreMaternityReimbursement";

	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SIGNPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/signPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REJECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/rejectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REVISEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/revisePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_UPDATEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/updatePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementPlafondByUserID`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_DOWNLOADPREMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/downloadPreMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region POSTMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT: string = "/PostMaternityReimbursement";

	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SIGNPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/signPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REJECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/rejectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REVISEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/revisePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_UPDATEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/updatePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementPlafondByUserID`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_DOWNLOADPOSTMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/downloadPostMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region MATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT: string = "/MaternityReimbursement";

	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SIGNMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/signMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REJECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/rejectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REVISEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/reviseMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_UPDATEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/updateMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementPlafondByUserID`;
	static STRING_URL_MATERNITYREIMBURSEMENT_DOWNLOADMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/downloadMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region RISK LIBRARY ACTIVITY

	static STRING_PATH_CONTROLLER_RISKACTIVITY: string = "/RiskActivity";

	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByAttributes`;
	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByToken`;

	//#endregion


	//#region RISK ASSIGN

	static STRING_PATH_CONTROLLER_RISKASSIGN: string = "/RiskAssign";

	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForApproval`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGN_SAVEALLRISKASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/saveAllRiskAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByTokenForApproval`;
	static STRING_URL_RISKASSIGN_APPROVERISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/approveRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_REJECTRISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/rejectRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByAttributes`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONSOLIDATIONREPORTBYPERIOD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignForConsolidationReportByPeriod`;

	//#endregion


	//#region RISK PLAN

	static STRING_PATH_CONTROLLER_RISKPLAN: string = "/RiskPlan";

	static STRING_URL_RISKPLAN_SELECTRISKPLANBYRISKASSIGNCONTENTTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/selectRiskPlanByRiskAssignContentToken`;
	static STRING_URL_RISKPLAN_INSERTRISKPLANBYRISKPLANLIST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/insertRiskPlanByRiskPlanList`;

	//#endregion


	//#region RISK ASSIGN CONTENT

	static STRING_PATH_CONTROLLER_RISKASSIGNCONTENT: string = "/RiskAssignContent";

	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByToken`;
	static STRING_URL_RISKASSIGNCONTENT_CALCULATERISKASSIGNCONTENTSCORE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/calculateRiskAssignContentScore`;
	static STRING_URL_RISKASSIGNCONTENT_SAVERISKASSIGNCONTENTFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/saveRiskAssignContentForAssessment`;

	//#endregion


	//#region RISK ASSESSMENT GUIDE DOCUMENT

	static STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT: string = "/RiskAssessmentGuideDocument";

	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_SELECTRISKASSESSMENTGUIDEDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/selectRiskAssessmentGuideDocumentByAttributes`;
	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_DOWNLOADRISKASSESSMENTGUIDEDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/downloadRiskAssessmentGuideDocumentByToken`;

	//#endregion


	//#region RISK REQUEST

	static STRING_PATH_CONTROLLER_RISKREQUEST: string = "/RiskRequest";

	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByAttributes`;
	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SUBMITRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/submitRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SAVEALLREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/saveAllRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_INSERTRISKREQUEST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/insertRiskRequest`;
	static STRING_URL_RISKREQUEST_SENTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/sentRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_DELETERISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/deleteRiskRequestByToken`;

	//#endregion


	//#region RISK APPETITE STATEMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK: string = "/RiskAppetiteStatementRisk";
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementByAttributes`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORFOLLOWUPPLANDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForFollowUpPlanDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORFOLLOWUPPLANAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForFollowUpPlanApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORDASHBOARDDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForDashboardDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREPORTDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForReportDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONMONITORINGDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationMonitoringDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTRISKBYATTRIBUTESFORREALIZATIONCONFIRMATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTRISK}/selectRiskAppetiteStatementRiskByAttributesForRealizationConfirmationDropdown`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER: string = "/RiskAppetiteStatementParameter";

	static STRING_URL_RISKAPPETITESTATEMENTRISKPARAMETER_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORFOLLOWUPPLANAPPROVALDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForFollowUpPlanApprovalDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORFOLLOWUPPLANDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForFollowUpPlanDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORDASHBOARDDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForDashboardDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForDashboard`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYTOKENFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByTokenForDashboard`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREPORTDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForReportDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONMONITORINGDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationMonitoringDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTPARAMETERBYATTRIBUTESFORREALIZATIONCONFIRMATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETER}/selectRiskAppetiteStatementParameterByAttributesForRealizationConfirmationDropdown`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION: string = "/RiskAppetiteStatementParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/insertRiskAppetiteStatementParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/updateRiskAppetiteStatementParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/submitRiskAppetiteStatementParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/reviseRiskAppetiteStatementParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/approveRiskAppetiteStatementParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/rejectRiskAppetiteStatementParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByAttributesForMonitoring`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONBYTOKENFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATION}/selectRiskAppetiteStatementParameterRealizationByTokenForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN: string = "/RiskAppetiteStatementParameterFollowUpPlan";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SUBMITRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/submitRiskAppetiteStatementParameterFollowUpPlanForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_REVISERISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/reviseRiskAppetiteStatementParameterFollowUpPlanForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_APPROVERISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/approveRiskAppetiteStatementParameterFollowUpPlanForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_REJECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/rejectRiskAppetiteStatementParameterFollowUpPlanForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYATTRIBUTESFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByAttributesForReviewer`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLAN_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANBYTOKENFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN}/selectRiskAppetiteStatementParameterFollowUpPlanByTokenForReviewer`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN ITEM

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM: string = "/RiskAppetiteStatementParameterFollowUpPlanItem";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANITEM_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEMBYATTRIBUTESFORREVIEWER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANITEM}/selectRiskAppetiteStatementParameterFollowUpPlanItemByAttributesForReviewer`;

	//#endregion


	//#region RISK APPETITE STATEMENT PARAMETER REALIZATION FOLLOW UP PLAN APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL: string = "/RiskAppetiteStatementParameterFollowUpPlanApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANAPPROVAL_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVALBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL}/selectRiskAppetiteStatementParameterFollowUpPlanApprovalByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONFOLLOWUPPLANAPPROVAL_SELECTRISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVALBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL}/selectRiskAppetiteStatementParameterFollowUpPlanApprovalByAttributesForSigner`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER: string = "/RiskAppetiteStatementSubParameter";

	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETER}/selectRiskAppetiteStatementSubParameterByAttributesForDashboard`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = "/RiskAppetiteStatementSubParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/insertRiskAppetiteStatementSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/updateRiskAppetiteStatementSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/submitRiskAppetiteStatementSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/reviseRiskAppetiteStatementSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/approveRiskAppetiteStatementSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/rejectRiskAppetiteStatementSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubParameterRealizationByAttributesForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementSubParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementSubParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementSubParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementSubParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER: string = "/RiskAppetiteStatementSubSubParameter";

	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORREALIZATIONDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForRealizationDropdown`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByToken`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForRealization`;
	static STRING_URL_RISKAPPETITESTATEMENT_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETER}/selectRiskAppetiteStatementSubSubParameterByAttributesForDashboard`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = "/RiskAppetiteStatementSubSubParameterRealization";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_INSERTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/insertRiskAppetiteStatementSubSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_UPDATERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/updateRiskAppetiteStatementSubSubParameterRealization`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SUBMITRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/submitRiskAppetiteStatementSubSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYTOKENFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByTokenForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByTokenForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REVISERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/reviseRiskAppetiteStatementSubSubParameterRealizationForMaker`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_APPROVERISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/approveRiskAppetiteStatementSubSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_REJECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/rejectRiskAppetiteStatementSubSubParameterRealizationForSigner`;
	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONBYATTRIBUTESFORMONITORING: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION}/selectRiskAppetiteStatementSubSubParameterRealizationByAttributesForMonitoring`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION DOCUMENT

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENT: string = "/RiskAppetiteStatementSubSubParameterRealizationDocument";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATIONDOCUMENT_DOWNLOADRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONDOCUMENT}/downloadRiskAppetiteStatementSubSubParameterRealizationDocumentByAttributes`;

	//#endregion


	//#region RISK APPETITE STATEMENT SUB SUB PARAMETER REALIZATION APPROVAL

	static STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL: string = "/RiskAppetiteStatementSubSubParameterRealizationApproval";

	static STRING_URL_RISKAPPETITESTATEMENTREALIZATION_SELECTRISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL}/selectRiskAppetiteStatementSubSubParameterRealizationApprovalByAttributes`;

	//#endregion


	//#region LEGAL

	static STRING_PATH_CONTROLLER_LEGAL: string = "/Legal";

	static STRING_URL_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectChecker`;
	static STRING_URL_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectSigner`;

	//#endregion


	//#region DOCUMENT APPLICATION CONTROLLER

	static STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION: string = "/DocumentApplication";

	static STRING_URL_DOCUMENTAPPLICATION_INSERTDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/insertDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SAVEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/saveDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SUBMITDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/submitDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_REJECTDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/rejectDocumentApplicationByTokenForDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForSignerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForCheckerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_DELETEDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/deleteDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForMaker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForChecker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForSigner`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributes`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_DOWNLOADDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/downloadDocumentRequestAttachmentByToken`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE: string = "/DocumentStampCollectorType";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORTYPE_SELECTDOCUMENTSTAMPCONTROLLERTYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE}/selectDocumentStampCollectorTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISETYPE: string = "/DocumentStampEnterpriseType";

	static STRING_URL_DOCUMENTSTAMPENTERPRISETYPE_SELECTDOCUMENTSTAMPENTERPRISETYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISETYPE}/selectDocumentStampEnterpriseTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR: string = "/DocumentStampCollector";

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_UPLOADDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/uploadDocumentStampCollector`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_DOWNLOADDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/downloadDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/signDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_RESIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/resignDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_CANCELRESIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/cancelResignDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SIGNDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/signDocumentStampCollector`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCOLLECTORDOCUMENTTYPE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorDocumentType`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORREPORTING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForReporting`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORREPORTINGEXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForReportingExcel`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForMaker`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForSigner`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SUBMITDOCUMENTSTAMPCONTROLLERFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/submitDocumentStampCollectorForMaker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REVISEDOCUMENTSTAMPCOLLECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/reviseDocumentStampCollectorForMaker`;

	//#region CHECKER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForChecker`;

	//#endregion


	//#region SIGNER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForSigner`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForSigner`;

	//#endregion

	//#endregion


	//#region ANOMALY BATCH

	static STRING_PATH_CONTROLLER_ANOMALYBATCH: string = "/AnomalyBatch";

	static STRING_URL_ANOMALYBATCH_INSERTANOMALYBATCH: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/insertAnomalyBatch`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByToken`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerAuditee`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerAuditee`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerAuditee`;
	static STRING_URL_ANOMALYBATCH_UPDATEASSIGNEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAssignee`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditor`;
	static STRING_URL_ANOMALYBATCH_UPDATEFOLLOWUPANALYSIS: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateFollowUpAnalysis`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITORFORAPPROVE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditorForApprove`;
	static STRING_URL_ANOMALYBATCH_UPDATEFOLLOWUPANALYSISFORAPPROVE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateFollowUpAnalysisForApprove`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerASQ`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerASQ`;
	static STRING_URL_ANOMALYBATCH_REJECTASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectASQ`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerAuditor`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerAuditor`;
	static STRING_URL_ANOMALYBATCH_REJECTAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectAuditor`;
	static STRING_URL_ANOMALYBATCH_UPLOADAUDITORFEEDBACK: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/uploadAuditorFeedback`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerAuditee`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerAuditee`;
	static STRING_URL_ANOMALYBATCH_REJECTAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectAuditee`;
	static STRING_URL_ANOMALYBATCH_DOWNLOADANOMALYDOCUMENTAUDITORFEEDBACKBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/downloadAnomalyDocumentAuditorFeedbackByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_ANOMALYGENERAL: string = "/AnomalyGeneral";

	static STRING_URL_ANOMALYGENERAL_SELECTANOMALYGENERALBYANOMALYBATCH: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/selectAnomalyGeneralByAnomalyBatch`;
	static STRING_URL_ANOMALYGENERAL_UPLOADFOLLOWUP: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/uploadFollowUp`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUP: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadFollowUp`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUPEXPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadFollowUpExport`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADANOMALYGENERALSPREADSHEET: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadAnomalyGeneralSpreadsheet`;

	//#endregion


	//#region AUDIT TEAM

	static STRING_PATH_CONTROLLER_AUDITTEAM: string = "/AuditTeam";

	static STRING_URL_AUDITTEAM_SELECTAUDITTEAMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAM}/selectAuditTeamByAttributes`;

	//#endregion


	//#region AUDIT TEAM MEMBER

	static STRING_PATH_CONTROLLER_AUDITTEAMMEMBER: string = "/AuditTeamMember";

	static STRING_URL_AUDITTEAM_SELECTAUDITTEAMMEMBERBYUSERID: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamMemberByUserID`;
	static STRING_URL_AUDITTEAMMEMBER_SELECTAUDITTEAMLEADERBYAUDITGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamLeaderByAuditGeneralToken`;
	static STRING_URL_AUDITTEAMMEMBER_SELECTAUDITTEAMMEMBERBYAUDITGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamMemberByAuditGeneralToken`;

	//#endregion


	//#region ACCOUNTING AND FINANCE CONTROLLER

	static STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE: string = "/AccountingAndFinance";

	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByEmail`;
	static STRING_URL_ACCOUNTINGANDFINANCE_VERIFYACCOUNTINGANDFINANCEBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/verifyAccountingAndFinanceByEmail`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/signOut`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByToken`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectChecker`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectSigner`;

	//#endregion


	//#region ANOMALYNOTIFICATION

	static STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION: string = "/AnomalyNotification";

	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationByAttributes`;
	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationByToken`;
	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationCount`;

	//#endregion


	//#region TICKET SERVICE

	static STRING_PATH_CONTROLLER_TICKETSERVICE: string = "/TicketService";

	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByAttributes`;
	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_INSERTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertTicketService`;
	static STRING_URL_TICKETSERVICE_INSERTLISTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertListTicketService`;
	static STRING_URL_TICKETSERVICE_DELETETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/deleteTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_UPDATETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/updateTicketServiceByToken`;

	//#endregion


	//#region TICKET SERVICE AGENT

	static STRING_PATH_CONTROLLER_TICKETSERVICEAGENT: string = "/TicketServiceAgent";

	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByAttributes`;
	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTLISTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertListTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_DELETETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/deleteTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_UPDATETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/updateTicketServiceAgentByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_EMPLOYEE: string = "/Employee";

	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByAttributes`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByBranch`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByDivison`;

	//#endregion


	//#region FORMATION

	static STRING_PATH_CONTROLLER_FORMATION: string = "/Formation";

	static STRING_URL_FORMATION_SELECTFORMATIONBYDIVISIONID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FORMATION}/selectFormationByDivisionID`;

	//#endregion


	//#region CUSTOMER SATISFACTION

	static STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION: string = "/CustomerSatisfaction";

	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByToken`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTREPORTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectReportCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_INSERTCUSTOMERSATISFACTION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/insertCustomerSatisfaction`;
	static STRING_URL_CUSTOMERSATISFACTION_DELETECUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/deleteCustomerSatisfactionByToken`;

	//#endregion


	//#region GCG ONLINE

	/* PROFILE COMPONENT - START */

	static STRING_PATH_CONTROLLER_GCGPROFILEPAGE: string = "/GCGProfilePage";

	static STRING_URL_GCGONLINEPROFILEPAGE_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GCGPROFILEPAGE}/SelectForEmployee`;

	/* PROFILE COMPONENT - END */

	/* GRATIFICATION LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE: string = "/GratificationListPage";

	static STRING_URL_GCGONLINEGRATIFICATIONLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE}/SelectForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONEXCELPAGE: string = "/GratificationExcelPage";

	/* GRATIFICATION LIST COMPONENT - END */

	/* GRATIFICATION DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE: string = "/GratificationSubmissionPage";

	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE: string = "/GratificationDetailPage";

	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SUBMITREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SubmitReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTDOCUMENTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectDocumentForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/DownloadForEmployee`;

	/* GRATIFICATION DETAIL COMPONENT - END */

	/* INTEGRITY PACT LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE: string = "/IntegrityPactListPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/DownloadForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTEXCELPAGE: string = "/IntegrityPactExcelPage";

	/* INTEGRITY PACT LIST COMPONENT - END */

	/* INTEGRITY PACT DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE: string = "/IntegrityPactSubmissionPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE: string = "/IntegrityPactDetailPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE}/SelectForEmployee`;

	/* INTEGRITY PACT DETAIL COMPONENT - END */

	/* CODE OF ETHICS LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE: string = "/CodeOfEthicsListPage";

	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSEXCELPAGE: string = "/CodeOfEthicsExcelPage";

	/* CODE OF ETHICS LIST COMPONENT - END */

	/* CODE OF ETHICS DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE: string = "/CodeOfEthicsSubmissionPage";

	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE: string = "/CodeOfEthicsDetailPage";

	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE: string = "/CodeOfEthicsApprovalPage";

	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGSigner`;

	/* CODE OF ETHICS DETAIL COMPONENT - END */

	/* ANNUAL DISCLOSURE LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE: string = "/AnnualDisclosureListPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREEXCELPAGE: string = "/AnnualDisclosureExcelPage";

	/* ANNUAL DISCLOSURE LIST COMPONENT - END */

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE: string = "/AnnualDisclosureSubmissionPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE: string = "/AnnualDisclosureDetailPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE: string = "/AnnualDisclosureApprovalPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGSigner`;

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - END */

	//#endregion
	//#region PRODUCT GENERAL

	static STRING_PATH_CONTROLLER_PRODUCTGNERAL: string = "/ProductGeneral";

	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTGENERALBYPOLICY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGNERAL}/selectProductGeneralByPolicy`;

	//#endregion

	//#region SUPPLIER

	static STRING_PATH_CONTROLLER_SUPPLIER: string = "/Supplier";

	static STRING_URL_SUPPLIER_SELECTSUPPLIERLIST: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPPLIER}/selectSupplierList`;
	static STRING_URL_SUPPLIER_INSERTSUPPLIER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPPLIER}/insertSupplier`;
	static STRING_URL_SUPPLIER_SELECTSUPPLIERBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPPLIER}/selectSupplierByToken`;
	static STRING_URL_SUPPLIER_UPDATESUPPLIERBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUPPLIER}/updateSupplier`;

	//#endregion


	//#region PROVINCE

	static STRING_PATH_CONTROLLER_PROVINCE: string = "/Province";

	static STRING_URL_PROVINCE_SELECTPROVINCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PROVINCE}/selectProvince`;


	//#endregion



	//#region RISK PRODUCT SCORING

	static STRING_PATH_CONTROLLER_RISKCUSTOMERDATA: string = "/RiskCustomerData";

	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYCIPNUMBER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByCIPNumber`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskBasedAssessmentByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTJOBPROFILECUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectJobProfileCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTGEOGRAPHICCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectGeographicCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTPRODUCTCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectProductCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTDISTRIBUTIONNETWORKBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectDistributionNetworkByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_DOWNLOADRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/downloadRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_DOWNLOADRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/downloadRiskBasedAssessmentByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SENDRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/sendRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SENDRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/sendRiskBasedAssessmentByAttributes`;

	//#endregion


	//#region BRINSPRIME

	static STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL: string = "/KeyPerformanceApproval";

	static STRING_URL_KEYPERFORMANCEAPPROVAL_SELECTKEYPERFORMANCELISTHEADOFFICEFORADJUSTER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/selectKeyPerformanceListHeadOfficeForAdjuster`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_SELECTKEYPERFORMANCELISTHEADOFFICEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/selectKeyPerformanceListHeadOfficeForChecker`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_SELECTKEYPERFORMANCELISTHEADOFFICEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/selectKeyPerformanceListHeadOfficeForSigner`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_SELECTKEYPERFORMANCEAPPROVALDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/selectKeyPerformanceApprovalDivision`;

	static STRING_URL_KEYPERFORMANCEAPPROVAL_SELECTKEYPERFORMANCEBRANCHHEADOFFICEFORADJUSTER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/selectKeyPerformanceListBranchHeadOfficeForAdjuster`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_SELECTKEYPERFORMANCEBRANCHHEADOFFICEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/selectKeyPerformanceListBranchHeadOfficeForChecker`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_SELECTKEYPERFORMANCEBRANCHHEADOFFICEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/selectKeyPerformanceListBranchHeadOfficeForSigner`;

	static STRING_URL_KEYPERFORMANCEAPPROVAL_APPROVEKEYPERFORMANCEHEADOFFICEFORADJUSTER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/approveKeyPerformanceHeadOfficeForAdjuster`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_APPROVEKEYPERFORMANCEHEADOFFICEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/approveKeyPerformanceHeadOfficeForChecker`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_APPROVEKEYPERFORMANCEHEADOFFICEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/approveKeyPerformanceHeadOfficeForSigner`;

	static STRING_URL_KEYPERFORMANCEAPPROVAL_REJECTKEYPERFORMANCEHEADOFFICEFORADJUSTER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/rejectKeyPerformanceHeadOfficeForAdjuster`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_REJECTKEYPERFORMANCEHEADOFFICEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/rejectKeyPerformanceHeadOfficeForChecker`;
	static STRING_URL_KEYPERFORMANCEAPPROVAL_REJECTKEYPERFORMANCEHEADOFFICEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEAPPROVAL}/rejectKeyPerformanceHeadOfficeForSigner`;

	static STRING_PATH_CONTROLLER_KEYPERFORMANCEMONITORING: string = "/KeyPerformanceMonitoring";

	static STRING_URL_KEYPERFORMANCEMONITORING_SELECTKEYPERFORMANCELISTBRANCHHEADOFFICEFORDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEMONITORING}/selectKeyPerformanceListBranchHeadOfficeForDivision`;
	static STRING_URL_KEYPERFORMANCEMONITORING_SELECTKEYPERFORMANCEHEADOFFICEFORDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_KEYPERFORMANCEMONITORING}/selectKeyPerformanceHeadOfficeForDivision`;

	static STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION: string = "/KeyPerformanceSubmission";

	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/selectKeyPerformanceCorporateForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_INSERTKEYPERFORMANCECORPORATEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/insertKeyPerformanceCorporateForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEFORMAKERBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/selectKeyPerformanceCorporateForMakerByToken`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_UPDATEKEYPERFORMANCECORPORATEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/updateKeyPerformanceCorporateForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SUBMITKEYPERFORMANCECORPORATEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMISSION}/submitKeyPerformanceCorporateForMaker`;

	static STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL: string = "/KeyPerformanceCorporateApproval";

	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/selectKeyPerformanceCorporateForChecker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_APPROVEKEYPERFORMANCECORPORATEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/approveKeyPerformanceCorporateForChecker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_REJECTKEYPERFORMANCECORPORATEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/rejectKeyPerformanceCorporateForChecker`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/selectKeyPerformanceCorporateForSigner`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_REJECTKEYPERFORMANCECORPORATEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/rejectKeyPerformanceCorporateForSigner`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_APPROVEKEYPERFORMANCECORPORATEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/approveKeyPerformanceCorporateForSigner`;
	static STRING_URL_KEYPERFORMANCESUBMISSION_SELECTKEYPERFORMANCECORPORATEAPPROVALFORDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEAPPROVAL}/selectKeyPerformanceCorporateApprovalForDivision`;

	static STRING_URL_CONTROLLER_KEYPERFORMANCESUBMIT: string = "/KeyPerformanceSubmit";

	static STRING_URL_KEYPERFORMANCESUBMIT_SELECTKEYPERFORMANCECORPORATESUBMITFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMIT}/selectKeyPerformanceCorporateSubmitForMaker`;
	static STRING_URL_KEYPERFORMANCESUBMIT_UPDATEKEYPERFORMANCECORPORATESUBMITFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCESUBMIT}/updateKeyPerformanceCorporateSubmitForMaker`;


	static STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEMONITORING: string = "/KeyPerformanceCorporateMonitoring";

	static STRING_URL_KEYPERFORMANCESUBMIT_SELECTKEYPERFORMANCECORPORATEMONITORINGFORDIVISION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_URL_CONTROLLER_KEYPERFORMANCECORPORATEMONITORING}/selectKeyPerformanceCorporateMonitoringForDivision`;



	//#endregion


	//#region TAX REGULATION

	static STRING_PATH_CONTROLLER_TAXREGULATION: string = "/TaxRegulation";

	static STRING_URL_TAXREGULATION_SELECTTAXREGULATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/selectTaxRegulationByToken`;
	static STRING_URL_TAXREGULATION_SELECTTAXREGULATIOBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/selectTaxRegulationsByAttribute`;
	static STRING_URL_TAXREGULATION_INSERTTAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/insertTaxRegulation`;
	static STRING_URL_TAXREGULATION_UPDATETAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/updateTaxRegulation`;
	static STRING_URL_TAXREGULATION_DOWNLOADTAXREGULATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/downloadTaxRegulationByToken`;
	static STRING_URL_TAXREGULATION_UPDATESTATETAXREGULATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREGULATION}/updateStateTaxRegulation`;

	//#endregion

	//#region TAX REPORT

	static STRING_PATH_CONTROLLER_TAXREPORT: string = "/TaxReport";
	static STRING_URL_TAXREPORT_SELECTTAXREPORTFORMCSBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectTaxReportForMCSByAttribute`;
	static STRING_URL_TAXREPORT_SELECTTAXREPORTFORPICBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectTaxReportForPICByAttribute`;
	static STRING_URL_TAXREPORT_INSERTTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/insertTaxReport`;
	static STRING_URL_TAXREPORT_SELECTTAXREPORTBYTOKENFORPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectTaxReportByTokenForPIC`;
	static STRING_URL_TAXREPORT_UPDATETAXREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/updateTaxReportByToken`;
	static STRING_URL_TAXREPORT_SELECTLISTCHECKERTAXREPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectListCheckerTaxReport`;
	static STRING_URL_TAXREPORT_SELECTLISTSIGNERTAXREPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectListSignerTaxReport`;
	static STRING_URL_TAXREPORT_SELECTRECEIVERTYPEBYPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectReceiverTypeByPIC`;
	static STRING_URL_TAXREPORT_SELECTCUSTOMERTAXBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/selectCustomerTaxByAttribute`;
	static STRING_URL_TAXREPORT_APPROVECHECKERSTATUSTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/approveCheckerStatusTaxReport`;
	static STRING_URL_TAXREPORT_APPROVESIGNERSTATUSTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/approveSignerStatusTaxReport`;
	static STRING_URL_TAXREPORT_REJECTCHECKERSTATUSTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/rejectCheckerStatusTaxReport`;
	static STRING_URL_TAXREPORT_REJECTSIGNERSTATUSTAXREPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/rejectSignerStatusTaxReport`;
	static STRING_URL_TAXREPORT_DOWNLOADTAXREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREPORT}/downloadTaxReportByToken`;

	//#endregion

	//#region TAX REQUEST

	static STRING_PATH_CONTROLLER_TAXREQUEST: string = "/TaxRequest";

	static STRING_URL_TAXREQUEST_SELECTTAXREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/selectTaxRequestByToken`;
	static STRING_URL_TAXREQUEST_SELECTTAXREQUESTBYATTRIBUTEFORPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/selectTaxRequestByAttributeForPIC`;
	static STRING_URL_TAXREQUEST_SELECTRECEIVERTYPEBYPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/selectReceiverTypeByPIC`;
	static STRING_URL_TAXREQUEST_SELECTCUSTOMERTAXBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/selectCustomerTaxByAttribute`;
	static STRING_URL_TAXREQUEST_INSERTTAXREQUEST: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/insertTaxRequest`;
	static STRING_URL_TAXREQUEST_UPDATETAXREQUESTFORPIC: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/updateTaxRequestForPIC`;
	static STRING_URL_TAXREQUEST_DOWNLOADTAXREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/downloadTaxRequestByToken`;
	static STRING_URL_TAXREQUEST_UPDATEDOCUMENTFORAFN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TAXREQUEST}/updateDocumentForAFN`;

	//#endregion


	//#region BRINSPRO

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENT: string = "/ProjectManagement";

	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/selectProjectManagementListForMakerForDivision`;
	static STRING_URL_SELECTPROJECTMANAGEMENTLBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/selectProjectManagementByToken`;

	static STRING_URL_INSERTPROJECTMANAGEMENTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/insertProjectManagementForMaker`;
	static STRING_URL_UPDATEPROJECTMANAGEMENTFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENT}/updateProjectManagementForMaker`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL: string = "/ProjectManagementApproval";

	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementListForChecker`;
	static STRING_URL_SELECTPROJECTMANAGEMENTLISTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementListForSigner`;
	static STRING_URL_APPROVEPROJECTMANAGEMENTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/approveProjectManagementForChecker`;
	static STRING_URL_REVICEPROJECTMANAGEMENTFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/reviseProjectManagementForChecker`;
	static STRING_URL_APPROVEPROJECTMANAGEMENTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/approveProjectManagementForSigner`;
	static STRING_URL_REVICEPROJECTMANAGEMENTFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/reviseProjectManagementForSigner`;
	static STRING_URL_SELECTPROJECTMANAGEMENTAPPROVALBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTAPPROVAL}/selectProjectManagementApprovalByToken`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT: string = "/ProjectManagementDocument";

	static STRING_URL_SELECTPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/selectProjectManagementDocumentList`;
	static STRING_URL_SELECTPROJECTMANAGEMENTDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/selectProjectManagementDocumentByToken`;
	static STRING_URL_INSERTPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/insertProjectManagementDocument`;
	static STRING_URL_UPDATEPROJECTMANAGEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/updateProjectManagementDocument`;
	static STRING_URL_DOWNLOADPROJECTMANAGEMENTDOCUMENTDETAILBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDOCUMENT}/downloadProjectManagementDocumentDetailByToken`;

	static STRING_PATH_CONTROLLERPROJECTMANAGEMENTDASHBOARD: string = "/ProjectManagementDashboard";

	static STRING_URL_SELECTPROJECTMANAGEMENTDASHBOARD: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLERPROJECTMANAGEMENTDASHBOARD}/selectProjectManagementDashboard`;

	//#endregion


	//#region EARLY WARNING SIGNAL RISK

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALRISK: string = "/EarlyWarningSignalRisk";

	static STRING_URL_EARLYWARNINGSIGNALRISK_SELECTEARLYWARNINGSIGNALRISKBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALRISK}/selectEarlyWarningSignalRiskByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALRISK_SELECTEARLYWARNINGSIGNALRISKBYATTRIBUTESFORDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALRISK}/selectEarlyWarningSignalRiskByAttributesForDropdown`;

	//#endregion


	//#region EARLY WARNING SIGNAL PARAMETER

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALPARAMETER: string = "/EarlyWarningSignalParameter";

	static STRING_URL_EARLYWARNINGSIGNALPARAMETER_SELECTEARLYWARNINGSIGNALPARAMETERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALPARAMETER}/selectEarlyWarningSignalParameterByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALPARAMETER_SELECTEARLYWARNINGSIGNALPARAMETERBYATTRIBUTESFORDROPDOWN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALPARAMETER}/selectEarlyWarningSignalParameterByAttributesForDropdown`;
	static STRING_URL_EARLYWARNINGSIGNALPARAMETER_SELECTEARLYWARNINGSIGNALPARAMETERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALPARAMETER}/selectEarlyWarningSignalParameterByToken`;

	//#endregion


	//#region EARLY WARNING SIGNAL LIMIT

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT: string = "/EarlyWarningSignalLimit";

	static STRING_URL_EARLYWARNINGSIGNALLIMIT_SELECTEARLYWARNINGSIGNALLIMITBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT}/selectEarlyWarningSignalLimitByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALLIMIT_SELECTEARLYWARNINGSIGNALLIMITBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT}/selectEarlyWarningSignalLimitByToken`;
	static STRING_URL_EARLYWARNINGSIGNALLIMIT_INSERTEARLYWARNINGSIGNALLIMIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT}/insertEarlyWarningSignalLimit`;
	static STRING_URL_EARLYWARNINGSIGNALLIMIT_REVISEEARLYWARNINGSIGNALLIMITBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALLIMIT}/reviseEarlyWarningSignalLimitByToken`;

	//#endregion


	//#region EARLY WARNING FOLLOW UP PLAN

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN: string = "/EarlyWarningSignalFollowUpPlan";

	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForApproval`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORWORKINGUNITAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForWorkingUnitApproval`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORWORKINGUNIT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForWorkingUnit`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByAttributesForDashboard`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/selectEarlyWarningSignalFollowUpPlanByToken`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLAN_SUBMITEARLYWARNINGSIGNALFOLLOWUPPLANBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLAN}/submitEarlyWarningSignalFollowUpPlanByToken`;

	//#endregion


	//#region EARLY WARNING FOLLOW UP PLAN ITEM

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANITEM: string = "/EarlyWarningSignalFollowUpPlanItem";

	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANITEM_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANITEMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANITEM}/selectEarlyWarningSignalFollowUpPlanItemByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANITEM_INSERTEARLYWARNINGSIGNALFOLLOWUPPLANITEM: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANITEM}/insertEarlyWarningSignalFollowUpPlanItem`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANITEM_UPDATEEARLYWARNINGSIGNALFOLLOWUPPLANITEMINACTIVEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANITEM}/updateEarlyWarningSignalFollowUpPlanItemInactiveByToken`;

	//#endregion


	//#region EARLY WARNING FOLLOW UP PLAN APPROVAL

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL: string = "/EarlyWarningSignalFollowUpPlanApproval";

	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL}/selectEarlyWarningSignalFollowUpPlanApprovalByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL_APPROVEEARLYWARNINGSIGNALFOLLOWUPPLANAPPROVALBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL}/approveEarlyWarningSignalFollowUpPlanApprovalByToken`;
	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL_REJECTEARLYWARNINGSIGNALFOLLOWUPPLANAPPROVALBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANAPPROVAL}/rejectEarlyWarningSignalFollowUpPlanApprovalByToken`;

	//#endregion


	//#region EARLY WARNING FOLLOW UP PLAN SUB ISSUE

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANSUBISSUE: string = "/EarlyWarningSignalFollowUpPlanSubIssue";

	static STRING_URL_EARLYWARNINGSIGNALFOLLOWUPPLANSUBISSUE_SELECTEARLYWARNINGSIGNALFOLLOWUPPLANSUBISSUEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALFOLLOWUPPLANSUBISSUE}/selectEarlyWarningSignalFollowUpPlanSubIssueByAttributes`;

	//#endregion


	//#region EARLY WARNING SIGNAL ISSUE

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALISSUE: string = "/EarlyWarningSignalIssue";

	static STRING_URL_EARLYWARNINGSIGNALISSUE_SELECTEARLYWARNINGSIGNALISSUEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALISSUE}/selectEarlyWarningSignalIssueByToken`;
	static STRING_URL_EARLYWARNINGSIGNALISSUE_SELECTEARLYWARNINGSIGNALISSUEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALISSUE}/selectEarlyWarningSignalIssueByAttributes`;
	static STRING_URL_EARLYWARNINGSIGNALISSUE_SELECTEARLYWARNINGSIGNALISSUEBYATTRIBUTESFORDASHBOARD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALISSUE}/selectEarlyWarningSignalIssueByAttributesForDashboard`;

	//#endregion


	//#region EARLY WARNING SIGNAL SUB ISSUE

	static STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALSUBISSUE: string = "/EarlyWarningSignalSubIssue";

	static STRING_URL_EARLYWARNINGSIGNALSUBISSUE_SELECTEARLYWARNINGSIGNALSUBISSUEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EARLYWARNINGSIGNALSUBISSUE}/selectEarlyWarningSignalSubIssueByAttributes`;

	//#endregion


	//#region USER

	static STRING_PATH_CONTROLLER_USER: string = "/User";

	static STRING_URL_USER_SELECTUSERBYATTRIBUTESFORSIGNEREARLYWARNINGSIGNAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_USER}/selectUserByAttributesForSignerEarlyWarningSignal`;

	//#endregion


	//#region RELATIONSHIP MANAGER CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER: string = "/RelationshipManager";

	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYATTRIBUTESFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByAttributesForOperationAndSupport`;
	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByToken`;
	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByBranch`;

	//#endregion


	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCT: string = "/Product";

	static STRING_URL_PRODUCT_SELECTPRODUCTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByAttributes`;
	static STRING_URL_PRODUCT_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByToken`;

	//#endregion


	//#region VEHICLE MODEL CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEMODEL: string = "/VehicleModel";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMODELBYVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelByVehicleBrand`;

	//#endregion


	//#region VEHICLE BRAND CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEBRAND: string = "/VehicleBrand";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrand`;

	//#endregion


	//#region PRODUCT PERSONAL ACCIDENT SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH: string = "/ProductPersonalAccidentSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMPERSONALACCIDENTSYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMPERSONALACCIDENTSYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT PERSONAL ACCIDENT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT: string = "/ProductPersonalAccident";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMPERSONALACCIDENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMPERSONALACCIDENTBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT OTO SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH: string = "/ProductOtoSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTOSYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOSYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT OTO CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTO: string = "/ProductOto";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTO: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT ASRI SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH: string = "/ProductAsriSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMASRISYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMASRISYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT ASRI CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRI: string = "/ProductAsri";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMASRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMASRIBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion

}

//#endregion

//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_PROJECT_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS, ENUM_VIRTUALACCOUNT_TYPE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { IcoverModel } from "./icover.model";
import { RcoverModel } from "./rcover.model";
import { ResponseModel } from "./response.model";
import { SpreadingOfLossModel } from "./spreadingofloss.model";
// import { LeadModel } from "./lead.model";
// import { LetterHeaderModel } from "./letterheader.model";
// import { PolicyAsriModel } from "./policyasri.model";
// import { PolicyPersonalAccidentModel } from "./policypersonalaccident.model";
// import { ProductAsriModel } from "./productasri.model";
// import { ProductOtoModel } from "./productoto.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class PolicyGeneralModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for policy general model, attributes are stamped, used for contain general information to cover specific policy.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:4.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	ProductCode?: string;
	ProductName?: string;
	BranchCode?: string;
	BranchName?: string;
	CoverageCode?: string;
	CoverageName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	CertificateNumber?: string;
	PolicyID?: number;
	Status?: ENUM_SPPA_STATUS;
	PolicyStatus?: string;
	listModelIcover?: Array<IcoverModel>;
	Email?: string;
	VehicleChasisNumber?: string;

	PolicyActiveStatus?: boolean;
	PolicyPeriodeStatus?: boolean;
	PolicyPaymentStatus?: boolean;
	PolicyGracePeriodeStatus?: boolean ;
	PolicyPaymentDate?: Date;
	listModelRcover?: Array<RcoverModel>;

	InsuredName?: string;
	InsuredVehicleBrandID?: number;
	InsuredVehicleBrandCode?: number;
	InsuredVehicleBrandName?: string;

	InsuredVehicleTypeID?: number;
	InsuredVehicleTypeCode?: number;
	InsuredVehicleTypeName?: string;

	InsuredVehicleColorID?: number;
	InsuredVehicleColorCode?: string;
	InsuredVehicleColorName?: string;

	InsuredVehicleBrand?: string;
	InsuredVehicleModel?: string;
	InsuredVehicleColor?: string;

	InsuredVehicleYear?: string;

	LicensePlate?: string;

	LicensePlatePrefixCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;

	CustomerID?: number;
	Token?: string;

	Information?: string;
	Disclaimer?: boolean;
	Note?: string;
	ProjectSource?: ENUM_PROJECT_TYPE;

	/* POLICY DETAIL - END */

	/* RELATIONSHIP MANAGER - START */

	RelationshipManagerID?: number;
	RelationshipManagerCoreID?: string;

	RelationshipManagerFirstName?: string;
	RelationshipManagerMiddleName?: string;
	RelationshipManagerLastName?: string;
	RelationshipManagerBirthDate?: Date;
	RelationshipManagerBirthPlace?: string;
	RelationshipManagerGender?: ENUM_GENDER_TYPE;
	RelationshipManagerTelephone?: string;
	RelationshipManagerMobilePhone?: string;
	RelationshipManagerEmail?: string;

	RelationshipManagerIdentificationNumber?: string;
	RelationshipManagerIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	RelationshipManagerAddress?: string;
	RelationshipManagerCountryID?: number;
	RelationshipManagerCountryCode?: number;
	RelationshipManagerCountryName?: number;
	RelationshipManagerProvinceID?: number;
	RelationshipManagerProvinceCode?: number;
	RelationshipManagerProvinceName?: number;
	RelationshipManagerCityID?: number;
	RelationshipManagerCityCode?: number;
	RelationshipManagerCityName?: number;
	RelationshipManagerSubDistrictID?: number;
	RelationshipManagerVillageID?: number;
	RelationshipManagerHamletCode?: string;
	RelationshipManagerNeighbourhoodCode?: string;
	RelationshipManagerPostalID?: number;
	RelationshipManagerPostalCode?: number;
	RelationshipManagerPostalName?: number;
	RelationshipManagerLatitude?: number;
	RelationshipManagerLongitude?: number;

	/* RELATIONSHIP MANAGER - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderFullName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderCountryCode?: number;
	PolicyholderCountryName?: number;
	PolicyholderProvinceID?: number;
	PolicyholderProvinceCode?: number;
	PolicyholderProvinceName?: number;
	PolicyholderCityID?: number;
	PolicyholderCityCode?: number;
	PolicyholderCityName?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: number;
	PolicyholderPostalName?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	/* POLICYHOLDER - END */


	/* INSURED - START */

	InsuredLicensePlate?: string;
	InsuredVehicleChasisNumber?: string;
	InsuredVehicleEngineNumber?: string;

	/* INSURED - END */

	/* OBJECT INFORMASTION - START */

	ObjectInformationHeaderFirst?: string;
	ObjectInformationHeaderSecond?: string;
	ObjectInformationHeaderThird?: string;
	ObjectInformationHeaderFourth?: string;
	ObjectInformationHeaderFifth?: string;
	ObjectInformationValueFirst?: string;
	ObjectInformationValueSecond?: string;
	ObjectInformationValueThird?: string;
	ObjectInformationValueFourth?: string;
	ObjectInformationValueFifth?: string;


	/* OBJECT INFORMASTION - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: number;

	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: string;
	DiscountAmount?: number;

	CommissionCode?: string;
	CommissionPercent?: number;
	CommissionCurrency?: string;
	CommmisionAmount?: number;

	AdministrationAmount?: number;
	StampAmount?: number;
	PremiumTotalAmount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	PaymentAccountID?: number;

	/* PAYMENT - END */

	/* TRACKING - START */

	SubmittedBy?: number;
	SubmittedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	InforceDate?: Date;
	BookingDate?: Date;
	EffectiveDate?: Date;
	PolicyDueDate?: Date;

	/* TRACKING - END */

	/* VIRTUAL ACCOUNT - START */

	VirtualAccountID?: number;
	VirtualAccountType?: ENUM_VIRTUALACCOUNT_TYPE;
	VirtualAccountName?: string;
	VirtualAccountExpiredDate?: Date;
	VirtualAccountActiveDate?: Date;
	VirtualAccountNumber?: string;
	BRIVANumber?: string;

	/* VIRTUAL ACCOUNT - END */

	/* BANK - START */

	BankAccountID?: number;
	BankAccountHolder?: string;
	BankID?: number;
	BankCode?: string;
	BankName?: string;
	BankAccountNumber?: string;

	/* BANK - END */

	PaidStatus?: boolean;

	/* CARE TECH - START */

	ANO?: string;
	INO?: string;
	OANO?: string;
	BusinessSourceID?: string;
	AgentID?: string;
	AcceptanceType?: string;
	Remarks?: string;
	DetailState?: string;
	ExclusivePeriodFlag?: string;
	ProfileID?: string;
	AStatus?: string;

	/* CARE TECH - END */

	/* POLICY BRI - START */

	Certificate?: boolean;
	BRIPolicy?: boolean;
	COB?: string;

	/* POLICY BRI - END */

	/* NOT MAPPED - START */

	modelUser?: UserModel;
	RenewalAge?: number;
	OutstandingAge?: number;

	modelSpreadingOfLoss?: SpreadingOfLossModel;
	listModelSpreadingOfLoss?: Array<SpreadingOfLossModel>;

	/* NOT MAPPED - END */

	/* Attribute - END */


	constructor()
	{
		super();
		this.listModelAcccla = [];
		this.listModelIcover = [];
	}

	//#region GETTER

	getPolicyholderName(): string
	{
		if (this.PolicyholderFullName !== null && this.PolicyholderFullName !== undefined && this.PolicyholderFullName !== "")
		{
			return this.PolicyholderFullName;
		}
		else
		{
			let stringName: string = "";

			if (this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined || this.PolicyholderFirstName === "")
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY;
			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.PolicyholderFirstName;
			}

			if (this.PolicyholderMiddleName == null || this.PolicyholderMiddleName === undefined || this.PolicyholderMiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.PolicyholderMiddleName;
			}

			if (this.PolicyholderLastName == null || this.PolicyholderLastName === undefined || this.PolicyholderLastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_EMPTY + this.PolicyholderLastName;
			}

			return stringName;
		}
	}

	getPolicyholderFirstName(): string
	{
		if(this.PolicyholderFirstName === null || this.PolicyholderFirstName === undefined || this.PolicyholderFirstName === "")
		{
			return "";
		}
		else
		{
			return this.PolicyholderFirstName;
		}
	}

	getPolicyholderMiddleName(): string
	{
		if(this.PolicyholderMiddleName === null || this.PolicyholderMiddleName === undefined || this.PolicyholderMiddleName === "")
		{
			return "";
		}
		else
		{
			return this.PolicyholderMiddleName;
		}
	}

	getPolicyholderLastName(): string
	{
		if(this.PolicyholderLastName === null || this.PolicyholderLastName === undefined || this.PolicyholderLastName === "")
		{
			return "";
		}
		else
		{
			return this.PolicyholderLastName;
		}
	}

	//#endregion


	//#region FUNCTION

	setValidateInputNumber(): ResponseModel
	{
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Policy General");

		if (this.PolicyNumber == null || this.PolicyNumber === undefined || this.PolicyNumber === "" )
		{
			if (this.InsuredLicensePlate == null || this.InsuredLicensePlate === undefined)
			{
				if(this.VehicleChasisNumber == null || this.VehicleChasisNumber === undefined)
				{
					if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined || this.PolicyholderEmail === "")
					{
						modelResponse.MessageContent = "Nomor Referensi tidak boleh kosong!";
					}
					else if (regularExpression.test(this.PolicyholderEmail))
					{
						modelResponse.MessageContent = "Form is filled correctly.";
						modelResponse.State = ENUM_RESPONSE_STATE.Success;
					}
					else
					{
						modelResponse.MessageContent = "Masukan email dengan benar!";
					}
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	setValidateInsuredLicensePlate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Policy General");

		if(this.LicensePlateInfix === null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "")
		{
			modelResponse.MessageContent = "License Plate number can't be empty, please fill this field.!";
		}
		else if(this.LicensePlateSuffix === null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "")
		{
			modelResponse.MessageContent = "License Plate suffix can't be empty, please fill this field.!";
		}
		else
		{
			this.InsuredLicensePlate = this. LicensePlatePrefixCode + this. LicensePlateInfix + this. LicensePlateSuffix;
			this.LicensePlatePrefixCode = undefined;
			this.LicensePlateInfix = undefined;
			this.LicensePlateSuffix = undefined;
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	setValidateInsuredSearch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Policy General");


		if(this.PolicyholderEmail !== null || this.PolicyholderEmail !== undefined || this.PolicyholderEmail !== "" && this.VehicleChasisNumber !== null || this.VehicleChasisNumber !== undefined || this.VehicleChasisNumber !== "")
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Please fill this field.!";
		}

		return modelResponse;
	}

	validatePolicyNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const regExpAphabetNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);
		modelResponse.setForValidation("Policy General");


		this.PolicyNumber = this.PolicyNumber?.replace(StringConstant.STRING_REGULAREXPRESSION_SPACE, "");

		if ( this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "" )
		{
			modelResponse.MessageContent = "Policy number can't be empty, please fill this field.";
		}
		else if ( this.Certificate )
		{

			if(this.InsuredVehicleEngineNumber === null || this.InsuredVehicleEngineNumber === undefined || this.InsuredVehicleEngineNumber === "")
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number can't be empty, please fill this field.";
			}
			else if (!regExpAphabetNumeric.test(this.InsuredVehicleEngineNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number format incorrect.";
			}
			else if (this.InsuredVehicleEngineNumber.length < RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMINIMUM)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number minimum contain "+RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMINIMUM+" digits number.";
			}
			else if (this.InsuredVehicleEngineNumber.length > RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMAXIMUM)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number maximum contain "+RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMAXIMUM+" digits number.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}

		}
		else
		{
			if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else if (this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_LENGTH)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number must be contain "+RuleConstant.NUMBER_POLICY_NUMBER_LENGTH+" digit numbers or "+RuleConstant.NUMBER_POLICY_LENGTH+" digit numbers." ;
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}

		return modelResponse;
	}

	validatePolicyAttributes(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regExpAlphabetWhiteSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExpEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const regExpAlphabetNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);
		modelResponse.setForValidation("Policy General");

		if
		(
			(this.LicensePlatePrefixCode !== null && this.LicensePlatePrefixCode !== undefined && this.LicensePlatePrefixCode !== "" ) ||
			(this.LicensePlateInfix !== null && this.LicensePlateInfix !== undefined && this.LicensePlateInfix !== "" ) ||
			(this.LicensePlateSuffix !== null && this.LicensePlateSuffix !== undefined && this.LicensePlateSuffix !== "" )
		)
		{
			if (this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" )
			{
				modelResponse.MessageContent = "License Plate Prefix can't be empty, please fill this field.";
			}
			else if (this.LicensePlateInfix === null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" )
			{
				modelResponse.MessageContent = "License Plate Infix can't be empty, please fill this field.";
			}
			else if (this.LicensePlateSuffix === null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
			{
				modelResponse.MessageContent = "License Plate Suffix can't be empty, please fill this field.";
			}
			else if (!regExpAlphabetWhiteSpace.test(this.LicensePlateSuffix))
			{
				modelResponse.MessageContent = "License Plate Suffix format incorrect.";
			}
			else
			{
				this.InsuredLicensePlate = this.LicensePlatePrefixCode.replace(/\s/g, "") +""+ this.LicensePlateInfix +""+ this.LicensePlateSuffix;
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.InsuredVehicleChasisNumber !== null && this.InsuredVehicleChasisNumber !== undefined && this.InsuredVehicleChasisNumber !== "" )
		{
			if (this.InsuredVehicleChasisNumber.length < RuleConstant.NUMBER_CHASIS_NUMBER_LENGTHMINIMUM)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Chasis number minimum contain "+RuleConstant.NUMBER_CHASIS_NUMBER_LENGTHMINIMUM+" digits number.";
			}
			else if (this.InsuredVehicleChasisNumber.length > RuleConstant.NUMBER_CHASIS_NUMBER_LENGTHMAXIMUM)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Chasis number maximum contain "+RuleConstant.NUMBER_CHASIS_NUMBER_LENGTHMAXIMUM+" digits number.";
			}
			else if (regExpAlphabetNumeric.test(this.InsuredVehicleChasisNumber))
			{
				modelResponse.MessageContent = "Chasis number correct.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Chasis number incorrect.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}
		else if (this.InsuredVehicleEngineNumber !== null && this.InsuredVehicleEngineNumber !== undefined && this.InsuredVehicleEngineNumber !== "" )
		{
			if (this.InsuredVehicleEngineNumber.length < RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMINIMUM)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number minimum contain "+RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMINIMUM+" digits number.";
			}
			else if (this.InsuredVehicleEngineNumber.length > RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMAXIMUM)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number maximum contain "+RuleConstant.NUMBER_ENGINE_NUMBER_LENGTHMAXIMUM+" digits number.";
			}
			else if (!regExpAlphabetNumeric.test(this.InsuredVehicleEngineNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Engine number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}
		else if (this.PolicyholderEmail !== null && this.PolicyholderEmail !== undefined && this.PolicyholderEmail !== "")
		{
			if (regExpEmail.test(this.PolicyholderEmail))
			{
				modelResponse.MessageContent = "Email correct.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Email incorrect.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}
		else if (this.ReferenceNumber !== null && this.ReferenceNumber !== undefined && this.ReferenceNumber !== "")
		{
			if (!regExpAlphabetNumeric.test(this.ReferenceNumber))
			{
				modelResponse.MessageContent = "Reference Number can only contain alpha numeric.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			else if (this.ReferenceNumber.length < RuleConstant.NUMBER_POLICY_IDENTIFICATIONANDREFERENCE_NUMBER)
			{
				modelResponse.MessageContent = "Reference Number minimum contain " + RuleConstant.NUMBER_POLICY_IDENTIFICATIONANDREFERENCE_NUMBER + " characters.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			else
			{
				modelResponse.MessageContent = "Reference Number.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.PolicyholderIdentificationNumber !== null && this.PolicyholderIdentificationNumber !== undefined && this.PolicyholderIdentificationNumber !== "")
		{
			if (!regExNumeric.test(this.PolicyholderIdentificationNumber))
			{
				modelResponse.MessageContent = "Identification Number can only contain numeric.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			// else if (this.PolicyholderIdentificationNumber.length < RuleConstant.NUMBER_POLICY_IDENTIFICATIONANDREFERENCE_NUMBER)
			// {
			// 	modelResponse.MessageContent = "Identification Number minimum contain "+RuleConstant.NUMBER_POLICY_IDENTIFICATION_NUMBER+" characters.";
			// 	modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			// }
			else
			{
				modelResponse.MessageContent = "Identification Number.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.InsuredName !== null && this.InsuredName !== undefined && this.InsuredName !== "")
		{
			modelResponse.MessageContent = "Insured name correct.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Attributes search empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}

		return modelResponse;
	}

	validateAcceptanceNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Policy General";
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		if (this.ANO === null || this.ANO === undefined || this.ANO === "")
		{
			modelResponse.MessageContent = "Acceptance Number Empty";
		}
		else
		{
			if (this.ANO.length < StringConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM)
			{
				modelResponse.MessageContent = "Acceptance Number length less than "+StringConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM;
			}
			else if (this.ANO.length > StringConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMAXIMUM)
			{
				modelResponse.MessageContent = "Acceptance Number length more than "+StringConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM;
			}
			else if (!regExpNumeric.test(this.ANO))
			{
				modelResponse.MessageContent = "Acceptance Number format incorrect";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateReferenceNumber(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Policy General";
		const regExpAlphaNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.ReferenceNumber === null || this.ReferenceNumber === undefined || this.ReferenceNumber === "")
		{
			modelResponse.MessageContent = "Reference Number Empty";
		}
		else
		{
			if (this.ReferenceNumber.length < StringConstant.NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM)
			{
				modelResponse.MessageContent = "Reference Number length less than " + StringConstant.NUMBER_POLICY_REFERENCENUMBER_LENGTHMINIMUM;
			}
			else if (!regExpAlphaNumeric.test(this.ReferenceNumber))
			{
				modelResponse.MessageContent = "Reference Number format incorrect";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	validateSearchPolicyNumberWithCertificate(): boolean
	{
		if( this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "" && this.InsuredVehicleEngineNumber === null || this.InsuredVehicleEngineNumber === undefined || this.InsuredVehicleEngineNumber === "")
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	validateSearchByPolicyNumber(booleanSearchByPolicyNumber: boolean): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Policy General";

		if (booleanSearchByPolicyNumber)
		{
			if (this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "")
			{
				modelResponse.MessageContent = "Policy Number cannot be empty";
			}
			else if (this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH_SECOND)
			{
				modelResponse.MessageContent = "Policy number must be contain 16 or 20 digit numbers.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
			if (this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "" && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH_SECOND)
			{
				modelResponse.MessageContent = "Policy number must be contain 16 or 20 digit numbers.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	setBooleanToStringActive(booleanState): string
	{
		if(booleanState)
		{
			return "Active";
		}
		else
		{
			return "Non Active";
		}
	}

	setBooleanToStringPayment(booleanState): string
	{
		if(booleanState)
		{
			return "Paid";
		}
		else
		{
			return "Unpaid";
		}
	}

	setBooleanToStringPeriode(booleanState): string
	{
		if(booleanState)
		{
			return "On Periode";
		}
		else
		{
			return "Off Periode";
		}
	}

	setBooleanToStringGracePeriode(booleanState): string
	{
		if(booleanState)
		{
			return "Yes";
		}
		else
		{
			return "No";
		}
	}

	checkPolicyNumberWithCertificateNumber(): string
	{
		let stringPolicyNumberWithCertificateNumber: string = "";
		if(this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "")
		{

		}
		else if(this.CertificateNumber === null || this.CertificateNumber === undefined || this.CertificateNumber === "")
		{
			stringPolicyNumberWithCertificateNumber = this.PolicyNumber;
		}
		else
		{
			stringPolicyNumberWithCertificateNumber = this.PolicyNumber+" ("+this.CertificateNumber+")";
		}
		return stringPolicyNumberWithCertificateNumber;
	}

	checkCertificate(): boolean
	{
		if(this.CertificateNumber === null || this.CertificateNumber === undefined || this.CertificateNumber === "")
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	setInterfaceClaim(): boolean
	{
		//Start - Hotfix Production MV

		this.COB = this.ProductCode?.slice(0, 2);

		//Start - Hotfix Production MV

		if(this.COB == null || this.COB === undefined || this.COB === "")
		{
			return false;
		}
		else
		{
			if ((this.ProductCode === StringConstant.STRING_PRODUCTCODE_HEAVYNONMV) || (this.COB !== StringConstant.STRING_COB_MOTOVEHICLE))
			{
				return false;
			}
			else
			{
				return true;
			}
		}
	}

	//#endregion
}

//#endregion

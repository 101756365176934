//#region IMPORT

import { ENUM_EMPLOYMENT_GRADE, ENUM_EMPLOYMENT_TYPE, ENUM_LETTER_ROLE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { DivisionModel } from "./division.model";
import { FormationModel } from "./formation.model";
import { LetterRoleModel } from "./letterrole.model";
import { PositionModel } from "./position.model";


//#endregion


//#region CLASS

export class LogisticModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for Logistic model, used for user who has Logistic role.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:3.
	*/

	ID?: number;
	UserID?: number;
	EmploymentType?: ENUM_EMPLOYMENT_TYPE;
	EmploymentGrade?: ENUM_EMPLOYMENT_GRADE;
	Token?: string;
	BranchCode?: string;
	DeviceID?: string;
	Culture?: string;

	PhotoURL?: string;
	PhotoFileName?: string;
	PhotoOrder?: number;
	PhotoWidth?: number;
	PhotoHeight?: number;
	PhotoFormat?: string;
	PhotoNote?: string;

	DivisionID?: number;
	FormationID?: number;
	PositionID?: number;

	modelDivision?: DivisionModel;
	modelFormation?: FormationModel;
	modelPosition?: PositionModel;
	modelLetterRole?: LetterRoleModel;

	constructor()
	{
		super();
		this.modelPosition = new PositionModel();
	}

	/* Attribute - END */


	//#region GETTER

	getPositionName(): string
	{
		const stringPositionName: string = StringConstant.STRING_CHARACTER_EMPTY;

		if (this.modelPosition == null || this.modelPosition === undefined)
		{
			return stringPositionName;
		}
		else
		{
			if (this.modelPosition.Name == null || this.modelPosition.Name === undefined || this.modelPosition.Name === "")
			{
				return stringPositionName;
			}
			else
			{
				return this.modelPosition.Name;
			}
		}
	}

	getLRoleName(): string
	{
		let stringRoleName: string = StringConstant.STRING_CHARACTER_EMPTY;

		if (this.modelLetterRole == null || this.modelLetterRole === undefined)
		{
			return stringRoleName;
		}
		else
		{
			if (this.modelLetterRole.Role === ENUM_LETTER_ROLE.Maker)
			{
				stringRoleName = "Maker";
				return stringRoleName;
			}
			else if (this.modelLetterRole.Role === ENUM_LETTER_ROLE.Checker)
			{
				stringRoleName = "Checker";
				return stringRoleName;
			}
			else
			{
				stringRoleName = "Signer";
				return stringRoleName;
			}
		}
	}

	//#endregion


	//#region SETTER

	setModelPosition(): void
	{
		const stringModelPosition: string = JSON.stringify(this.modelPosition);
		this.modelPosition = new PositionModel();
		this.modelPosition.setModelFromString(stringModelPosition);
	}

	//#endregion
}

//#endregion
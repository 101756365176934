//#region IMPORT

import { NgModule } from "@angular/core";
import { ConvertToBroadcastURL, ConvertToPolicyURL, ConvertToProductURL, ConvertToUserURL } from "./hyperlink.pipe";
import { ConvertByteForPreview, ConvertDashToGuest, ConvertDashToGuestInitial, ConvertDateToDay, ConvertDateToMonth, ConvertEmptyNumberToDash, convertEmptyToDash, ConvertEmtpyNumberToZero, ConvertExtensionToIcon, ConvertNoDivisionToCustomer, ConvertNumberFormatPipe, ConvertPointToComma, ConvertSeparateEnumSpace, ConvertToDateMedium, ConvertToDateShort, ConvertToMonth, ConvertToTime, CurrencyValue, CurrencyValueMaximumDigits, CurrencyValueWithNull, InitiateWithoutUTC, StripHtmlPipe } from "./converter.pipe";
import { TrimShort, TrimShortest } from "./trim.pipe";
import { CustomCurrency } from "./customcurrency.pipe";
import { ConvertDateToYear } from "./converter.pipe";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			ConvertToProductURL,
			ConvertToPolicyURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			convertEmptyToDash,
			ConvertToMonth,
			ConvertEmtpyNumberToZero,
			ConvertEmptyNumberToDash,
			TrimShort,
			TrimShortest,
			InitiateWithoutUTC,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			ConvertToUserURL,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToBroadcastURL,
			ConvertDashToGuest,
			ConvertDashToGuestInitial,
			CurrencyValue,
			CustomCurrency,
			ConvertDateToYear,
			ConvertToTime,
			CurrencyValueWithNull,
			ConvertDateToDay,
			StripHtmlPipe,
			ConvertToMonth,
			CurrencyValueMaximumDigits,
			ConvertPointToComma,
			ConvertDateToMonth,
			ConvertNumberFormatPipe,
			ConvertDateToMonth,
			CurrencyValueMaximumDigits,
			ConvertPointToComma
		],
		exports:
		[
			ConvertToProductURL,
			ConvertToPolicyURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			convertEmptyToDash,
			ConvertToMonth,
			ConvertEmtpyNumberToZero,
			ConvertEmptyNumberToDash,
			TrimShort,
			TrimShortest,
			InitiateWithoutUTC,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			ConvertToUserURL,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			CurrencyValue,
			ConvertDashToGuest,
			CustomCurrency,
			ConvertToBroadcastURL,
			ConvertDashToGuestInitial,
			ConvertDateToYear,
			ConvertToTime,
			CurrencyValueWithNull,
			ConvertDateToDay,
			StripHtmlPipe,
			ConvertToMonth,
			CurrencyValueMaximumDigits,
			ConvertPointToComma,
			ConvertDateToMonth,
			ConvertNumberFormatPipe,
			ConvertDateToMonth,
			CurrencyValueMaximumDigits,
			ConvertPointToComma
		]
	}
)

//#endregion


//#region CLASS

export class PipeModule { }

//#endregion

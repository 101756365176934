//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class SatisfactionsurveyquestionService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT RENEWAL

	selectClaimProposalSatisfactionQuestion(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SATISFACTION_SELECT_CLAIMPROPOSAL_SATISFACTION_QUESTION);
	}

	//#endregion
}

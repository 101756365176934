//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ClaimProposalModel } from "../models/claimproposal.model";
import { ClaimProposalSatisfactionModel } from "../models/claimproposalsatisfaction.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class SatisfactionsurveyService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT RENEWAL

	insertClaimProposalSatisfactionForWebsite(interfaceGeneralService: GeneralServiceInterface, arrayModelClaimProposalSatisfaction: Array< ClaimProposalSatisfactionModel> ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(arrayModelClaimProposalSatisfaction), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SATISFACTION_INSERTCLAIMPROPOSALSATISFACTION);
	}

	selectClaimProposalSatisfactionByClaimProposalToken(interfaceGeneralService: GeneralServiceInterface, modelClaimProposal: ClaimProposalModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelClaimProposal), WebAddressConstant.STRING_URL_CLAIM_PROPOSAL_SATISFACTION_SELECTCLAIMPROPOSALSATISFACTIONBYCLAIMPROPOSALTOKEN);
	}

	//#endregion
}

<div id="divBodyClaimProposal" class="DivBodyGeneral">
	<header class="HeaderGeneral">
		<h2>Salvage | Detail</h2>
		<p>Monitor your salvage</p>
	</header>

	<div id="divMainClaimProposalDetailSubmitted" class="DivMainGeneral">
		<form>
			<div class="DivForm">
				<H3>Detail submission</H3>
				<fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<label for="spanItemName">Salvage location</label>
							<span id="spanItemName">{{ _modelSalvage.SalvageLocation }}</span>

							<label for="spanEstimatedSalvageValue">Bidding type</label>
							<span id="spanEstimatedSalvageValue">{{ _modelSalvage.BiddingType }}</span>

							<label for="spanTotal">Claim paid status</label>
							<span id="spanTotal">{{ _modelSalvage.ClaimPaidStatus }}</span>
						</div>
						<div class="DivForm2Column">
							<label for="spanSalvationLocation">Claim paid date</label>
							<span id="spanSalvationLocation">{{ _modelSalvage.ClaimPaidDate }}</span>

							<label for="spanAdditionalCost">Additional cost</label>
							<span id="spanAdditionalCost">{{ _modelSalvage.AdditionalCost }}</span>
						</div>
					</div>
				</fieldset>
			</div>

			<div class="DivForm">
				<H3>Claim Information</H3>
				<fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<label for="spanPolicyholderName">Policyholder name</label>
							<span id="spanPolicyholderName">{{ _modelPolicyGeneral.PolicyholderFullName }}</span>

							<label for="spanPolicynumber">Policy number</label>
							<span id="spanPolicynumber">{{ _modelPolicyGeneral.PolicyNumber }}</span>

							<label for="spanProduct">Product</label>
							<span id="spanProduct">{{ _modelPolicyGeneral.ProductName }}</span>

							<label for="spanBranch">Branch</label>
							<span id="spanBranch">{{ _modelPolicyGeneral.BranchName }}</span>

							<label for="spanRelationshipManager">Relationship manager</label>
							<span id="spanRelationshipManager>">{{ _modelPolicyGeneral.RelationshipManagerFirstName }} {{ _modelPolicyGeneral.RelationshipManagerMiddleName }} {{ _modelPolicyGeneral.PolicyholderLastName }}</span>

							<label for="spanStartDate">Start date</label>
							<span id="spanStartDate">{{ _modelPolicyGeneral.PolicyStartDate }}</span>

							<label for="spanEndDate">End date</label>
							<span id="spanTotal">{{ _modelPolicyGeneral.PolicyEndDate }}</span>

							<label for="spanPolicyPaymentDate">Policy payment date</label>
							<span id="spanPolicyPaymentDate">{{ _modelPolicyGeneral.PolicyPaymentDate }}</span>
						</div>
						<div class="DivForm2Column">
							<label for="spanHistoryPolicy">History policy</label>
							<span id="spanHistoryPolicy">{{ _modelPolicyGeneral.Status }}</span>

							<label for="spanStatus">Status</label>
							<span id="spanStatus" class="SpanActiveStatus StatusNotActive">{{ _modelPolicyGeneral.Status }}</span>

							<label for="spanPolicyActiveStatus">Policy active status</label>
							<span id="spanPolicyActiveStatus" class="SpanActiveStatus StatusNotActive">{{ _modelPolicyGeneral.PolicyActiveStatus }}</span>

							<label for="spanPolicyPeriodStatus">Policy period status</label>
							<span id="spanPolicyPeriodStatus" class="SpanActiveStatus StatusActive">{{ _modelPolicyGeneral.PolicyPeriodeStatus }}</span>

							<label for="spanPolicyPaymentStatus">Policy payment status</label>
							<span id="spanPolicyPaymentStatus" class="SpanActiveStatus StatusActive">{{ _modelPolicyGeneral.PolicyPaymentStatus }}</span>

							<label for="spanPolicyGracePeriodStatus">Policy grace period status</label>
							<span id="spanPolicyGracePeriodStatus" class="SpanActiveStatus StatusNotActive">{{ _modelPolicyGeneral.PolicyGracePeriodeStatus }}</span>

							<label for="spanPremium">Premium</label>
							<span id="spanPremium">{{ _modelPolicyGeneral.PremiumAmount }}</span>

							<label for="spanClaimPaymentDate">Claim payment date</label>
							<span id="spanClaimPaymentDate">{{ _modelPolicyGeneral.PolicyPaymentDate }}</span>
						</div>
					</div>
				</fieldset>
			</div>

			<div class="DivTableContainer">
				<h3>Table supporting document</h3>
				<table>
					<tbody>
						<tr *ngFor="let modelSalvageGallery of this._modelSalvage.arrayModelSalvageGallery; index as numberIndex">
							<td>{{ _enumPhotoType[numberIndex + 142] }}</td>
							<td>{{ modelSalvageGallery.Name }}</td>
							<td class="DivButton"><input type="button" class="ButtonDownload"></td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="DivTableContainer">
				<h3>Table item</h3>
				<table>
					<thead>
						<tr>
							<th>No</th>
							<th>Item</th>
							<th>Estimated salvage value</th>
						</tr>
					</thead>

					<tbody>
						<tr *ngFor="let modelSalvageItem of this._modelSalvage.arrayModelSalvageItem; index as numberIndex">
							<td>{{ numberIndex + 1 }}</td>
							<td>{{ modelSalvageItem.Name }}</td>
							<td>{{ modelSalvageItem.EstimatedSalvageValue }}</td>
						</tr>
					</tbody>

					<tfoot>
						<tr>
							<td></td>
							<td>Total</td>
							<td>{{ this._modelSalvage.getArrayModelSalvageItemEstimatedSalvageValue() }}</td>
							<td></td>
						</tr>
					</tfoot>
				</table>
			</div>
		</form>
	</div>
</div>

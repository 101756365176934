//#region IMPORT

import { ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE, ENUM_CLAIMPROPOSALSALVAGEWINNERDOCUMENT_TYPE, ENUM_CLAIMPROPOSALSALVAGE_BIDDINGTYPE, ENUM_CLAIMPROPOSALSALVAGE_CLAIMPAYMENTSTATUS, ENUM_CLAIMPROPOSALSALVAGE_STATUS, ENUM_RESPONSE_STATE} from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ClaimProposalSalvageBuyerModel } from "./claimproposalsalvagebuyer.model";
import { ClaimProposalSalvageHistoryApprovalModel } from "./claimproposalsalvagehistoryapproval.model";
import { ClaimProposalSalvageObjectInformationModel } from "./claimproposalsalvageobjectinformation.model";
import { ClaimProposalSalvageSupportingDocumentModel } from "./claimproposalsalvagesupportingdocument.model";
import { ClaimProposalSalvageTableItemModel } from "./claimproposalsalvagetableitem.model";
import { ClaimProposalSalvageWinnerBiddingModel } from "./claimproposalsalvagewinnerbidding.model";
import { ClaimProposalSalvageWinnerSupportingDocumentModel } from "./claimproposalsalvagewinnersupportingdocument.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class ClaimProposalSalvageSubmissionModel extends BaseModel
{
	/*
        Attributes
        Description: Attributes for ClaimProposalSalvageSubmissionModel
        Author: Muhammad Nur Fauzi
        Created on: 20230927            Updated on: 20230927
        Created by: Muhammad Nur Fauzi              Updated by: Muhammad Nur Fauzi
        Version: 1.0:0
    */

	TicketNumber?: string;
	ClaimNumber?: string;
	PolicyNumber?: string;

	InsuredName?: string;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	ClaimProposalToken?: string;

	SubmissionSalvageNumber?: string;
	ClaimProposalSalvageToken?: string;

	PolicyHolderName?: string;
	Branch?: string;
	DateOfLoss?: Date;
	StartDate?: Date;
	EndDate?: Date;
	DescriptionOfLoss?: string;
	VehicleType?: string;
	LicensePlate?: string;
	PlaceOfLoss?: string;
	ApprovedDate?: Date;
	EngineNumber?: string;
	ChassisNumber?: string;
	ClaimValue?: number;
	Premium?: number;
	BiddingType?: ENUM_CLAIMPROPOSALSALVAGE_BIDDINGTYPE;
	BiddingTypeName?: string;
	ClaimPaymentStatus?: ENUM_CLAIMPROPOSALSALVAGE_CLAIMPAYMENTSTATUS;
	ClaimPaymentDate?: Date;
	SalvageLocation?: string;
	AdditionalCost?: number;
	Status?: ENUM_CLAIMPROPOSALSALVAGE_STATUS;
	StatusName?: string;
	DateOfBidSubmissionDeadline?: Date;
	PaidStatus?: string;
	BiddingValuePaymentDate?: Date;
	EstimatedValue?: number;
	FlagEstimatedValue?: string;
	SubmissionNumber?: string;

	IsPaid?: boolean;
	IsMotorVehicle?: boolean;

	WinnerName?: string;
	Email?: string;
	Address?: string;
	BiddingValue?: number;

	ClaimProposalSalvageBuyerToken?: string;
	ClaimProposalSalvageBuyerEmail?: string;
	ClaimProposalSalvageBuyerAddress?: string;

	ClaimProposalSalvageOperationalGuidelinesDocumentToken?: string;
	ClaimProposalSalvageOperationalGuidelinesDocumentName?: string;
	ClaimProposalSalvageOperationalGuidelinesDocumentFormat?: string;
	ClaimProposalSalvageOperationalGuidelinesDocumentExtension?: string;
	ClaimProposalSalvageOperationalGuidelinesDocumentData?: string;
	ClaimProposalSalvageOperationalGuidelinesDocumentIsAdditional?: boolean;
	ClaimProposalSalvageOperationalGuidelinesDocumentSize?: number;

	ClaimProposalSalvageInvitationDocumentToken?: string;
	ClaimProposalSalvageInvitationDocumentName?: string;
	ClaimProposalSalvageInvitationDocumentFormat?: string;
	ClaimProposalSalvageInvitationDocumentExtension?: string;
	ClaimProposalSalvageInvitationDocumentData?: string;
	ClaimProposalSalvageInvitationDocumentIsAdditional?: boolean;
	ClaimProposalSalvageInvitationDocumentSize?: number;

	ClaimProposalSalvageDocumentToken?: string;
	ClaimProposalSalvageDocumentName?: string;
	ClaimProposalSalvageDocumentFormat?: string;
	ClaimProposalSalvageDocumentExtension?: string;
	ClaimProposalSalvageDocumentData?: string;
	ClaimProposalSalvageDocumentIsAdditional?: boolean;
	ClaimProposalSalvageDocumentSize?: boolean;

	ClaimProposalSalvageWinnerDocumentToken?: string;
	ClaimProposalSalvageWinnerDocumentName?: string;
	ClaimProposalSalvageWinnerDocumentFormat?: string;
	ClaimProposalSalvageWinnerDocumentExtension?: string;
	ClaimProposalSalvageWinnerDocumentData?: string;
	ClaimProposalSalvageWinnerDocumentSize?: string;

	DepositType?: string;
	MinimumDeposit?: number;

	PageDetailHeader?: string;
	PageDetailDescription?: string;

	Percentage?: number;

	SalvageBuyer?: string;

	Remark?: string;

	//#region LIST MODEL

	listModelObjectInformation?: Array<ClaimProposalSalvageObjectInformationModel>;
	ListTableItem?: Array<ClaimProposalSalvageTableItemModel>;
	ListSupportingDocument?: Array<ClaimProposalSalvageSupportingDocumentModel>;
	ListHistoryApproval?: Array<ClaimProposalSalvageHistoryApprovalModel>;
	ListTableBuyer?: Array<ClaimProposalSalvageBuyerModel>;
	ListOperationalGuidelines?: Array<ClaimProposalSalvageSupportingDocumentModel>;
	ListInvitation?: Array<ClaimProposalSalvageSupportingDocumentModel>;
	ListWinnerBidding?: Array<ClaimProposalSalvageWinnerBiddingModel>;
	ListWinnerSupportingDocument?: Array<ClaimProposalSalvageWinnerSupportingDocumentModel>;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.listModelObjectInformation = [];
		this.ListTableItem = [];
		this.ListSupportingDocument = [];
		this.ListHistoryApproval=[];
		this.ListTableBuyer=[];
		this.ListWinnerBidding=[];
		this.ListWinnerSupportingDocument=[];

		// this.PageDetailHeader = StringConstant.STRING_SUBMISSION_SALVAGE_DETAIL_HEADER_SUBMISSION_SALVAGE;
		// this.PageDetailDescription = StringConstant.STRING_SUBMISSION_SALVAGE_DETAIL_DESCRIPTION_SUBMISSION_SALVAGE;
		// this.AdditionalCost = 0;
	}

	//#endregion


	//#region GETTER

	setModelForm(modelClaimProposalSalvageSubmission: ClaimProposalSalvageSubmissionModel): void
	{
		if(modelClaimProposalSalvageSubmission.ClaimProposalToken !== null && modelClaimProposalSalvageSubmission.ClaimProposalToken !== undefined)
		{
			this.ClaimProposalToken = modelClaimProposalSalvageSubmission.ClaimProposalToken;
		}
		else
		{

		}

		if(modelClaimProposalSalvageSubmission.SalvageLocation !== null && modelClaimProposalSalvageSubmission.SalvageLocation !== undefined)
		{
			this.SalvageLocation = modelClaimProposalSalvageSubmission.SalvageLocation;
		}
		else
		{

		}
		if(modelClaimProposalSalvageSubmission.AdditionalCost !== null && modelClaimProposalSalvageSubmission.AdditionalCost !== undefined)
		{
			this.AdditionalCost = modelClaimProposalSalvageSubmission.AdditionalCost;
		}
		else
		{

		}
		if(modelClaimProposalSalvageSubmission.ListSupportingDocument !== null && modelClaimProposalSalvageSubmission.ListSupportingDocument !== undefined)
		{
			this.ListSupportingDocument = modelClaimProposalSalvageSubmission.ListSupportingDocument;
		}
		else
		{

		}
		if(modelClaimProposalSalvageSubmission.ListSupportingDocument !== null && modelClaimProposalSalvageSubmission.ListSupportingDocument !== undefined)
		{
			this.ListTableItem = modelClaimProposalSalvageSubmission.ListTableItem;
		}
		else
		{

		}

		if(modelClaimProposalSalvageSubmission.IsPaid !== null && modelClaimProposalSalvageSubmission.IsPaid !== undefined)
		{
			this.IsPaid = modelClaimProposalSalvageSubmission.IsPaid;
		}
		else
		{

		}

		if(modelClaimProposalSalvageSubmission.IsMotorVehicle !== null && modelClaimProposalSalvageSubmission.IsMotorVehicle !== undefined)
		{
			this.IsMotorVehicle = modelClaimProposalSalvageSubmission.IsMotorVehicle;
		}
		else
		{

		}

		if(modelClaimProposalSalvageSubmission.ClaimPaymentDate !== null && modelClaimProposalSalvageSubmission !== undefined)
		{
			this.ClaimPaymentDate = modelClaimProposalSalvageSubmission.ClaimPaymentDate;
		}
		else
		{

		}
	}

	setModelForList(modelClaimSalvage: ClaimProposalSalvageSubmissionModel): void
	{
		this.ClaimProposalSalvageToken = modelClaimSalvage.ClaimProposalSalvageToken;
		this.SubmissionSalvageNumber = modelClaimSalvage.SubmissionSalvageNumber;
		this.PolicyNumber = modelClaimSalvage.PolicyNumber;
		this.ClaimNumber = modelClaimSalvage.ClaimNumber;
		this.InsuredName = modelClaimSalvage.InsuredName;
		this.BiddingType = modelClaimSalvage.BiddingType;
		this.BiddingTypeName = modelClaimSalvage.BiddingTypeName;
		this.Status = modelClaimSalvage.Status;
		this.StatusName = modelClaimSalvage.StatusName;
		this.EstimatedValue = modelClaimSalvage.EstimatedValue;
	}
	//#endregion


	//#region FUNCTION

	validateData(): ResponseModel
	{
		let modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission Salvage");
		if(this.SalvageLocation === undefined || this.SalvageLocation === null || this.SalvageLocation === "")
		{
			modelResponse.MessageContent = "Salvage location cannot be empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse = this.validateUpload();
		}


		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			if(this.ListTableItem === null || this.ListTableItem === undefined || this.ListTableItem.length === 0)
			{
				modelResponse.MessageContent = "Item cannot be empty";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			else if(this.DepositType === null || this.DepositType === undefined)
			{
				modelResponse.MessageContent = "Deposit type cannot be empty";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			else if(this.DepositType === "1")
			{
				if(this.Percentage === null || this.Percentage === undefined)
				{
					modelResponse.MessageContent = "Percentage cannot be empty";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				}
				else if(this.MinimumDeposit === null || this.MinimumDeposit === undefined)
				{
					modelResponse.MessageContent = "Minimum deposit cannot be empty";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else if(this.DepositType === "0")
			{
				if(this.MinimumDeposit === null || this.MinimumDeposit === undefined)
				{
					modelResponse.MessageContent = "Minimum deposit cannot be empty";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				}
				else
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	removeEmptySupportingDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if(this.ListSupportingDocument)
		{
			this.ListSupportingDocument = this.ListSupportingDocument.filter((modelSupportingDocument)=> Object.keys(modelSupportingDocument).length > 1 );
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Please Upload required document";
		}

		return modelResponse;
	}

	clearAdditionalDocument()
	{
		if(this.ListSupportingDocument)
		{
			this.ListSupportingDocument = this.ListSupportingDocument.filter((modelSupportingDocument)=>modelSupportingDocument.Type !== ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.MiscellaneousDocument);
		}
		else
		{

		}
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission Salvage");

		if(this.ListSupportingDocument)
		{
			if(this.IsMotorVehicle)
			{
				let stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.SalvageReport);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Laporan Salvage can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					return modelResponse;
				}

				stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.SalvageCustodyLetter);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Surat Penitipan Salvage can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					return modelResponse;
				}

				stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.OriginalBPKB);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "BPKB and STNK Asli can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					return modelResponse;
				}

				stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.OriginalVehicleKey);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Kunci Kendaraan Asli can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					return modelResponse;
				}

				stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.ReleaseOfRightsLetter);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Surat Pelepasan Hak can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					return modelResponse;
				}

				stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.TripleStampedReceipt);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Kwitansi Bermaterai Rangkap 3 can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					return modelResponse;
				}

				stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.OriginalVehicleInvoice);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Faktur Kendaraan Asli can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					return modelResponse;
				}

				stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.SalvagePhoto);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Foto Salvage can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					return modelResponse;
				}
			}
			else
			{
				let stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.SalvageReport);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Laporan Salvage can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;

					return modelResponse;
				}

				stringDocumentType = this.ListSupportingDocument.find((item) =>item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.SalvageCustodyLetter);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Surat Penitipan Salvage can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;

					return modelResponse;
				}

				if(!this.IsPaid)
				{
					const stringSalvageBiddingApplicationFormFromTheInsured = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.SalvageBiddingApplicationFormFromTheInsured);

					if(stringSalvageBiddingApplicationFormFromTheInsured)
					{
						modelResponse.State = ENUM_RESPONSE_STATE.Success;
					}
					else
					{
						modelResponse.MessageContent = "Form Permohonan Lelang Salvage dari Tertanggung can’t be empty, please fill this field";
						modelResponse.State = ENUM_RESPONSE_STATE.Fail;

						return modelResponse;
					}
				}
				else
				{

				}

				stringDocumentType = this.ListSupportingDocument.find((item)=> item.Type === ENUM_CLAIMPROPOSALSALVAGEDOCUMENT_TYPE.SalvagePhoto);

				if(stringDocumentType)
				{
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					modelResponse.MessageContent = "Foto Salvage can’t be empty, please fill this field";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;

					return modelResponse;
				}
			}


			if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
			{
				for(const modelSupportingDocument of this.ListSupportingDocument)
				{
					if(modelSupportingDocument.Size)
					{
						if(modelSupportingDocument.Size > 10000000)
						{
							modelResponse.MessageContent = "Document exceed maximum size";
							modelResponse.State = ENUM_RESPONSE_STATE.Fail;
						}
						else
						{

						}
					}
					else
					{
					}
				}
			}
			else
			{

			}
		}
		else
		{
			modelResponse.MessageContent = "Document empty.";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}

		return modelResponse;
	}

	validateApproval(): ResponseModel
	{
		const modelResponse = new ResponseModel();
		modelResponse.setForValidation("Submission Salvage");

		if(this.Remark === null || this.Remark === undefined || this.Remark === "")
		{
			modelResponse.MessageContent = "Note Cannot be Empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateSubmitDoc(): ResponseModel
	{
		const modelResponse = new ResponseModel();
		modelResponse.setForValidation("Submission Salvage Document");

		if(this.ListTableBuyer === undefined || this.ListTableBuyer.length < 1)
		{
			modelResponse.MessageContent = "Salvage Buyer Cannot be Empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		// else if(this.ListSupportingDocument !== undefined && this.ListSupportingDocument.length > 0)
		// {
		// 	for(const modelSupportingDocument of this.ListSupportingDocument)
		// 	{
		// 		if(modelSupportingDocument.IsFileDownloaded === false)
		// 		{
		// 			modelResponse.MessageContent = "Please Download File " + modelSupportingDocument.Name + " Before Approval";
		// 			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		// 			break;
		// 		}
		// 		else
		// 		{
		// 			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		// 		}
		// 	}
		// }
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateInvitation(): ResponseModel
	{
		const modelResponse = new ResponseModel();
		modelResponse.setForValidation("Submission Salvage Document");

		if(this.ListTableBuyer === undefined || this.ListTableBuyer.length < 1)
		{
			modelResponse.MessageContent = "Salvage Buyer Cannot be Empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else if(this.DateOfBidSubmissionDeadline === undefined || this.DateOfBidSubmissionDeadline === null)
		{
			modelResponse.MessageContent = "Bid Deadline Date Cannot be Empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		// else if(this.ListSupportingDocument !== undefined && this.ListSupportingDocument.length > 0)
		// {
		// 	for(const modelSupportingDocument of this.ListSupportingDocument)
		// 	{
		// 		if(modelSupportingDocument.IsFileDownloaded === false)
		// 		{
		// 			modelResponse.MessageContent = "Please Download File " + modelSupportingDocument.Name + " Before Approval";
		// 			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		// 			break;
		// 		}
		// 		else
		// 		{
		// 			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		// 		}
		// 	}
		// }
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		// if(this.ListOperationalGuidelines !== undefined && this.ListOperationalGuidelines.length > 0 && modelResponse.State !== ENUM_RESPONSE_STATE.Fail)
		// {
		// 	for(const modelGuidlinesDoc of this.ListOperationalGuidelines)
		// 	{
		// 		if(modelGuidlinesDoc.IsFileDownloaded === false)
		// 		{
		// 			modelResponse.MessageContent = "Please Download File " + modelGuidlinesDoc.Name + " Before Approval";
		// 			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		// 			break;
		// 		}
		// 		else
		// 		{
		// 			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		// 		}
		// 	}
		// }
		// else
		// {
		// }

		return modelResponse;
	}

	validateToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Upload DLA");

		if(this.ClaimProposalSalvageToken === undefined || this.ClaimProposalSalvageToken === "" || this.ClaimProposalSalvageToken === null)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "ClaimProposalSalvageToken cannot be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	setBiddingTypeAndStatus(): void
	{
		if(this.BiddingType === ENUM_CLAIMPROPOSALSALVAGE_BIDDINGTYPE.MotorVehicle)
		{
			this.BiddingTypeName = "Motor Vehicle";
		}
		else
		{
			this.BiddingTypeName = "Non Motor Vehicle";
		}

		if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageSubmittedByEBiddingBranchOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER0;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageRejectedByEBiddingBranchOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER1;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageApprovedByEBiddingBranchOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER2;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageRejectedByEBiddingBranchOfficeSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER3;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageApprovedByEBiddingBranchOfficeSignerForBranchOffice)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER4;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageApprovedByEBiddingBranchOfficeSignerForHeadOffice)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER5;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.OperationalGuidelinesSubmittedByEBiddingHeadOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER6;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.OperationalGuidelinesRejectedByEBiddingHeadOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER7;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.OperationalGuidelinesApprovedByEBiddingHeadOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER8;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.OperationalGuidelinesRejectedByEBiddingHeadOfficeFirstSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER9;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.OperationalGuidelinesApprovedByEBiddingHeadOfficeFirstSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER10;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.OperationalGuidelinesRejectedByEBiddingHeadOfficeSecondSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER11;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.OperationalGuidelinesApprovedByEBiddingHeadOfficeSecondSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER12;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.InvitationSubmittedByEBiddingBranchOfficeMaker && this.EstimatedValue !== undefined && this.EstimatedValue > 15000000)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER13;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.InvitationSubmittedByEBiddingBranchOfficeMaker && this.EstimatedValue !== undefined && this.EstimatedValue < 15000000)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER13_INVITATIONANDAUCTIONTERM;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.InvitationRejectedByEBiddingBranchOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER14;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.InvitationApprovedByEBiddingBranchOfficeChecker && this.EstimatedValue && this.EstimatedValue > 15000000)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER15;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.InvitationApprovedByEBiddingBranchOfficeChecker && this.EstimatedValue && this.EstimatedValue < 15000000)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER15_INVITATIONANDAUCTIONTERM;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.InvitationRejectedByEBiddingBranchOfficeSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER16;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.InvitationApprovedByEBiddingBranchOfficeSignerForBranchOffice)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER17;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.InvitationApprovedByEBiddingBranchOfficeSignerForHeadOffice)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER18;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationSubmittedByEBiddingHeadOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER19;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationRejectedByEBiddingHeadOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER20;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationApprovedByEBiddingHeadOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER21;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationRejectedByEBiddingHeadOfficeFirstSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER22;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationApprovedByEBiddingHeadOfficeFirstSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER23;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationRejectedByEBiddingHeadOfficeSecondSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER24;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationApprovedByEBiddingHeadOfficeSecondSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER25;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerLetterSubmittedByEBiddingBranchOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER26;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerLetterRejectedByEBiddingBranchOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER27;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerLetterApprovedByEBiddingBranchOfficeChecker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER28;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerLetterRejectedByEBiddingBranchOfficeSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER29;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerLetterApprovedByEBiddingBranchOfficeSigner)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER30;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageCancelledByEBiddingHeadOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER31;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationCancelledByEBiddingHeadOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER32;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageCancelledByEBiddingBranchOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER33;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.WinnerDeterminationCancelledByEBiddingBranchOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER34;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.OperationalGuidelinesRejectedByEBiddingHeadOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER35;
		}
		else if(this.Status === ENUM_CLAIMPROPOSALSALVAGE_STATUS.SalvageInputDLABranchOfficeMaker)
		{
			this.StatusName = StringConstant.STRING_SUBMISSION_SALVAGE_FILTER36;
		}
	}

	validateWinneraDetermination(): ResponseModel
	{
		const modelResponse = new ResponseModel();
		modelResponse.setForValidation("Submission Salvage Document");

		if(this.ClaimProposalSalvageBuyerToken === null || this.ClaimProposalSalvageBuyerToken === undefined || this.ClaimProposalSalvageBuyerToken === "")
		{
			modelResponse.MessageContent = "Please Select Winner";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else if(this.BiddingValue === null || this.BiddingValue === undefined || this.BiddingValue === 0)
		{
			modelResponse.MessageContent = "Bidding value cannot be Empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			if(this.ListWinnerSupportingDocument === null || this.ListWinnerSupportingDocument === undefined)
			{
				modelResponse.MessageContent = "Please Upload All Required Document";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			else
			{
				this.ListWinnerSupportingDocument = this.ListWinnerSupportingDocument.filter((modelSupportingDocument)=> Object.keys(modelSupportingDocument).length > 1);

				if(this.ListWinnerSupportingDocument.length < 1)
				{
					modelResponse.MessageContent = "Please Upload All Required Document";
					modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				}
				else
				{
					for(const modelWinnerSupportingDoc of this.ListWinnerSupportingDocument)
					{
						if(modelWinnerSupportingDoc.Type === ENUM_CLAIMPROPOSALSALVAGEWINNERDOCUMENT_TYPE.EventNews)
						{
							modelResponse.State = ENUM_RESPONSE_STATE.Success;
							break;
						}
						else
						{
							modelResponse.MessageContent = "Please Upload All Required Document";
							modelResponse.state = ENUM_RESPONSE_STATE.Fail;
						}
					}
				}
			}
		}
		else
		{

		}


		return modelResponse;
	}

	validationWinnerDeterminationAndWinnerLetter(): ResponseModel
	{
		const modelResponse = new ResponseModel();
		modelResponse.setForValidation("Submission Salvage Document");

		if(this.ClaimProposalSalvageBuyerToken === null || this.ClaimProposalSalvageBuyerToken === undefined || this.ClaimProposalSalvageBuyerToken === "")
		{
			modelResponse.MessageContent = "Winner name cannot be empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else if(this.BiddingValue === null || this.BiddingValue === undefined || this.BiddingValue === 0)
		{
			modelResponse.MessageContent = "Bidding value cannot be empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else if(this.BiddingValuePaymentDate === null || this.BiddingValuePaymentDate === undefined )
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Bidding value payment date cannot be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			const modelWinnerSupportingDocument: ClaimProposalSalvageWinnerSupportingDocumentModel = this.ListWinnerSupportingDocument?.find(modelWinnerSupportingDocumentFind => modelWinnerSupportingDocumentFind.Type === ENUM_CLAIMPROPOSALSALVAGEWINNERDOCUMENT_TYPE.EventNews) || new ClaimProposalSalvageWinnerSupportingDocumentModel();
			if(modelWinnerSupportingDocument.Data === undefined || modelWinnerSupportingDocument.Data === null)
			{
				modelResponse.MessageContent = "Berita acara cannot be empty, please fill this field";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}


		return modelResponse;
	}

	validateWinnerLetter(): ResponseModel
	{
		const modelResponse = new ResponseModel();
		modelResponse.setForValidation("Winner Letter Submission");

		if(this.BiddingValuePaymentDate === null || this.BiddingValuePaymentDate === undefined )
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageContent = "Bingging Value Payment Date Cannot Be Empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	cleanList(): void
	{
		this.listModelObjectInformation = undefined;
		this.ListTableItem = undefined;
		this.ListSupportingDocument = undefined;
		this.ListHistoryApproval = undefined;
		this.ListTableBuyer = undefined;
		this.ListWinnerBidding = undefined;
		this.ListWinnerSupportingDocument = undefined;
	}
	//#endregion

}

//#endregion

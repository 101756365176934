//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseGalleryModel } from "./bases/basegallery.model";
import { ClaimProposalTrackingModel } from "./claimproposaltracking.model";
import { ResponseModel } from "./response.model";



//#endregion


//#region CLASS

export class ClaimProposalTrackingGalleryModel extends BaseGalleryModel
{
	/*
		Attribute - START
		Description : Attribute for tracking model gallery, used for tracking claim.
		Author: Anjar Kusworo.
		Created on : Monday, 27 February 2023. 			Updated on : -.
		Created by : Andryana Baros.					Updated by : -.
		Version : 1.0:2.
	*/

	ID?: number;
	Token?: string;
	ClaimProposalID?: number;
	ClaimProposalTrackingID?: number;
	Title?: string;
	// Size?: number;
	// Path?: string;
	// Name?: string;
	modelClaimProposalTracking?: ClaimProposalTrackingModel;


	validateToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Tracking");

		if(this.Token === null || this.Token === undefined || this.Token === "" )
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateNameFile(stringFileName: string): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Tracking");

		const stringRegularExpressionFileName = /[./<>[\]|]\:*?"<>|/;

		if(stringRegularExpressionFileName.test(stringFileName))
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "File Name Can't Contain [./<>[\]|]" + "\:*?\"<>|";
		}

		return modelResponse;
	}


	validateAdd(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regexDot: RegExp = /^[^:;\/."<>|*]*$/g;
		modelResponse.setForValidation("Claim Tracking");

		if (this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama file tidak boleh kosong!";
		}
		else if(this.Name !== undefined && !regexDot.test(this.Name))
		{
			modelResponse.MessageContent = "Format file attachment tidak diperbolehkan!";
		}
		else if (this.Format == null || this.Format === undefined)
		{
			modelResponse.MessageContent = "Format attachment tidak boleh kosong!";
		}
		else if (this.Size == null || this.Size === undefined || this.Size <= 0)
		{
			modelResponse.MessageContent = "Ukuran size attachment lebih besar dari pada 0 byte.";
		}
		else if (this.Data == null || this.Data === undefined || this.Data === "")
		{
			modelResponse.MessageContent = "Attachment extension tidak boleh kosong!";
		}
		else if (this.Extension == null || this.Extension === undefined || this.Extension === "")
		{
			modelResponse.MessageContent = "Extension attachment can't be empty!";
		}
		else if (StringConstant.ARRAY_VALUE_FORMAT_FOR_ASSESSMENTGUIDE.indexOf(this.Extension) === -1)
		{
			modelResponse.MessageContent = "Format file attachment tidak diperbolehkan!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			const pipeConverterByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

			if (this.Extension === StringConstant.STRING_FORMAT_COMPRESS_ZIP || this.Extension === StringConstant.STRING_FORMAT_COMPRESS_RAR)
			{
				if (this.Size === null || this.Size === undefined)
				{

				}
				else
				{
					if (this.Size <= RuleConstant.NUMBER_FILE_COMPRESS_SIZEMAXIMUM_FOR_TICKET)
					{
						modelResponse.State = ENUM_RESPONSE_STATE.Success;
					}
					else
					{
						modelResponse.MessageContent = "Maksimum ukuran file dengan tipe kompres adalah " + pipeConverterByteForPreview.transform(RuleConstant.NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM_FOR_TICKET) + ".";
					}
				}
			}
			else
			{
				if (this.Size === null || this.Size === undefined)
				{

				}
				else
				{
					if (this.Size <= RuleConstant.NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM_FOR_CLAIM_TRACKING)
					{
						modelResponse.State = ENUM_RESPONSE_STATE.Success;
					}
					else
					{
						modelResponse.MessageContent = "Maksimum ukuran file dengan tipe non kompres adalah " + pipeConverterByteForPreview.transform(RuleConstant.NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM_FOR_CLAIM_TRACKING) + ".";
					}
				}
			}
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.Extension === StringConstant.STRING_FORMAT_COMPRESS_ZIP || this.Extension === StringConstant.STRING_FORMAT_COMPRESS_RAR)
			{
				modelResponse.MessageContent = "Formulir diisi dengan benar.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				if (this.Format === "")
				{
					modelResponse.MessageContent = "Format tidak boleh kosong.";
				}
				else
				{
					modelResponse.MessageContent = "Formulir diisi dengan benar.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
		}
		else
		{

		}

		return modelResponse;
	}
}

//#endregion
